<template>
    <div>
        <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col" v-bind:style="{cursor:pointer2}">
                    <blog-entries-table
                            title="Blog DBMenos"
                            :data="tableData"
                            :pagination="pagination"
                            v-on:rowSelected="rowSelected"
                            v-on:insertRow="insertRow"
                            v-on:pageSelected="getBlogEntries"
                            v-on:deleteRow="removeBlogEntry"/>
                </div>
            </div>
        </div>

        <div class="container-fluid" style="margin-top:2rem;">
            <div class="row">
                <div class="col" v-bind:style="{cursor:pointer2}">
                    <blog-webinar-table
                            title="Webinars DBenos"
                            :data="tableDataWebinar"
                            :pagination="paginationWebinar"
                            v-on:rowSelectedWebinar="rowSelectedWebinar"
                            v-on:pageSelectedWebinar="getBlogWebinar"
                            v-on:insertRowWebinar="insertRowWebinar"
                            v-on:deleteRowWebinar="removeBlogWebinar"/>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import BlogEntriesTable from './Tables/BlogEntriesTable'
  import BlogWebinarTable from './Tables/BlogWebinarTable'

export default {
      name: 'tables',
      components: {
          BlogEntriesTable,
          BlogWebinarTable
      },
      mounted() {
          this.getBlogEntries(1)
          this.getBlogWebinar(1)
      },
      computed: {
          user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      },
      data() {
          return {
              modal: false,
              selectedConsultant: {},
              tableData: [],
              tableDataWebinar: [],
              pagination: {
                  total: 0,
                  perPage: 5,
                  currentPage: 1
              },
              paginationWebinar: {
                  total: 0,
                  perPage: 5,
                  currentPage: 1
              },
              pointer: 'pointer',
              pointer2: 'default',
              roles: ['admin', 'seller', 'service']
          };
      },
      methods: {
          rowSelected(row) {
              this.$router.push({
                  path: '/blogEntry',
                  query: {id:row.id}
              })
          },
          insertRow() {
              this.$router.push({
                  path: '/blogEntry',
                  query: null
              })
          },
          insertRowWebinar() {
              this.$router.push({
                  path: '/blogWebinarEntry',
                  query: null
              })
          },
          rowSelectedWebinar(row) {
              this.$router.push({
                  path: '/blogWebinarEntry',
                  query: {id:row.id}
              })
          },
          pageSelected(page) {
              this.pagination.currentPage = page
              this.getBlogEntries()
          },
          async removeBlogEntry(entry) {
              let url = this.$v.SERVER + this.$v.MARKETING_OPS.REMOVE_BLOG_ENTRY
              this.$showLoader(true)
              let response = await this.$axios.post(url, {id:entry.id})
              let data = response.data
              if (data[this.$v.API_SUCCESS]) {
                  setTimeout( async() => {
                      this.modal = false
                      await this.getBlogEntries(1)
                      this.$forceUpdate()
                  }, 500)
              } else {
                  this.$showLoader(false)
                  this.$validateSession(data)
              }
          },
           async removeBlogWebinar(entry) {
              let url = this.$v.SERVER + this.$v.MARKETING_OPS.REMOVE_BLOG_WEBINAR
              this.$showLoader(true)
              let response = await this.$axios.post(url, {id:entry.id})
              let data = response.data
              if (data[this.$v.API_SUCCESS]) {
                  setTimeout( async() => {
                      this.modal = false
                      await this.getBlogWebinar(1)
                      this.$forceUpdate()
                  }, 10)
              } else {
                  this.$showLoader(false)
                  this.$validateSession(data)
              }
          },
          async getBlogEntries(page) {
              let limit = this.pagination.perPage
              let url = this.$v.SERVER + this.$v.ADMIN_OPS.GET_BLOG_ENTRIES + "?offset=" + limit*(page - 1) + "&limit=" + limit
              this.$showLoader(true)
              let response = await this.$axios.get(url)
              this.$showLoader(false)
              let data = response.data
              if (data[this.$v.API_SUCCESS]) {
                  let p = data[this.$v.API_ROW];
                  this.pagination.total = p["totalItems"];
                  this.pagination.currentPage = page;
                  this.pagination.perPage = limit;
                  this.tableData = data[this.$v.API_ROWS]
              } else { this.$validateSession(data) }
          },
          async getBlogWebinar(page) {
              let limit = this.paginationWebinar.perPage
              let url = this.$v.SERVER + this.$v.ADMIN_OPS.GET_BLOG_WEBINAR + "?offset=" + limit*(page - 1) + "&limit=" + limit
              this.$showLoader(true)
              let response = await this.$axios.get(url)
              this.$showLoader(false)
              let data = response.data
              if (data[this.$v.API_SUCCESS]) {
                  let p = data[this.$v.API_ROW];
                  this.paginationWebinar.total = p["totalItems"];
                  this.paginationWebinar.currentPage = page;
                  this.paginationWebinar.perPage = limit;
                  this.tableDataWebinar = data[this.$v.API_ROWS]
              } else { this.$validateSession(data) }
          },
      }
  };
</script>
<style></style>
