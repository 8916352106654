<template>
    <div>
        <div class="dropbox">
            <label for="fileInput" slot="upload-label">
                <image-uploader :preview="false" capture="environment" :debug="1" doNotResize="gif" :autoRotate="true" outputFormat="verbose" accept="*/*"
                :className="['fileinput', { 'fileinput--loaded': selectedFile }]" @change="setImage">
                <label for="fileInput" slot="upload-label">
                    <p v-if="!selectedFile">{{selectedFile? selectedFile.name : "Arrastra o da click para seleccionar el archivo"}}</p>
                </label>
                </image-uploader>
                <div v-if="!isImage && selectedFile" :title="text" class="row align-items-center">
                    <div  class="text-center icon"><i class="fas fa-file-upload text-success"></i></div>
                </div>
                <img v-else :src="previewURL" :alt="text" style="width: 100%; max-height: 500px"/>
            </label>
        </div>
        <base-button v-if="!hideButton" style="width: 100%; margin-top: 8px" :disabled="!(selectedFile && type)" v-on:click="requestUploadFile()">{{buttonText ? buttonText : 'Subir'}}</base-button>
    </div>
</template>

<script>
    export default {
        name: "dropbox-resize",
        data() {
            return {
                selectedFile: null,
                isImage: false,
                previewURL: null,
                text: ''
            }
        },
        props: {
            userid: Number,
            type: String,
            files: Array,
            buttonText: String,
            hideButton: Boolean,
        },
        methods: {
            setImage: function(output) {
                //console.log(output)
                if (!output.info){
                    this.text = output.name
                    this.isImage = false;
                    this.selectedFile = output;
                    return
                }
                this.isImage = true;
                this.text = output.info.name
                let blob = this.dataURLtoBlob(output.dataUrl)
                let f = new File([blob], output.info.name,{type: output.info.type})
                //console.log(f)
                this.selectedFile = f
                console.log("bp")
                const reader = new FileReader()
                const c = this
                reader.onload = function (e) {
                    c.previewURL = e.target.result
                }
                reader.readAsDataURL(f)
            },
            dataURLtoBlob (dataURL){
                const BASE64_MARKER = ";base64,";
                let parts = dataURL.split(BASE64_MARKER);
                let contentType = parts[0].split(":")[1];
                let raw = window.atob(parts[1]);
                if (dataURL.indexOf(BASE64_MARKER) == -1){
                    parts = dataURL.split(",");
                    contentType = parts[0].split(":")[1];
                    raw = decodeURIComponent(parts[1]);
                    return new Blob([raw], {type: contentType});
                }
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
                for (let i = 0; i < rawLength; ++i)
                    uInt8Array[i] = raw.charCodeAt(i);
                return new Blob([uInt8Array], {type: contentType});
            },
            requestUploadFile() {
                this.$emit('uploadFile', {
                    type: this.type,
                    file: this.selectedFile,
                    text: this.text
                })
            },
        },
    }
</script>

<style lang="scss">
    .dropbox {
        outline: 2px dashed blue; /* the dash box */
        outline-offset: -10px;
        background: #FFFFFF;
        border-radius: 10px;
        color: #3953D3;
        padding: 10px 10px;
        min-height: 100px; /* minimum height */
        position: relative;
        cursor: pointer;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100px;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: #f7fafc; /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1em;
        text-align: center;
        padding: 25px;
    }

    #fileInput {
        display: none;
    }
</style>