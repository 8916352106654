<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8" />

    <div class="container-fluid mt--7" @click="uploadSelected = null">
      <div class="row">
        <div class="col" v-bind:style="{cursor:pointer2}">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="icon icon-shape bg-warning text-white">
                    <i class="fas fa-users"></i>
                  </div>
                </div>
                <div class="col">
                  <h3 class="mb-0">Proceso Automatico (Leads,Contratos,Cierres)</h3>
                </div>
              </div>
              <div class="dropdown-divider" style="margin-top: 20px"></div>
              <div class="row" style="margin-top: 20px">
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2">
                    <card class="border-2" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h5 class="card-title text-uppercase mb-1">Leads</h5>
                          <h1>{{infoLeads.process}}</h1>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-info text-white">
                            <i class="far fa-user-circle"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2">
                    <card class="border-2" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h5 class="card-title text-uppercase mb-1">Contratos</h5>
                          <h1>{{infoLeads.contracts}}</h1>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-signature"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
				<div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2">
                    <card class="border-2" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h5 class="card-title text-uppercase mb-1">Cierres</h5>
                          <h1>{{infoLeads.cierres}}</h1>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-success text-white">
                            <i class="fas fa-user-check"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sheets-reports",
  components: {},
  data() {
    return {
      uploadSelected: null,
      uploadData: 0,
      pointer: "pointer",
      pointer2: "default",
      massivePN: {
        users: "",
        title: "",
        message: "",
      },
      isAdmin: false,
      isSeller: false,
      isService: false,
      isManagerService: false,
      isAuditor: false,
      infoLeads:{}
    };
  },
   async created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    console.log(this.user.role)
    this.getData();
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
  },
  methods: {
    getData() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.GROWTH_OPS.GET_PROCESS_AUTOMATIC;
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.infoLeads = data.row;
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Error al subir actualización'
                     ));
        }
      });
    }
  },
};
</script>
<style></style>