<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''"
       v-bind:style="{cursor:pointer2}">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data">
        <template slot="columns">
            <th v-for="(item, index) in columns" :key="index">{{item}}</th>
        </template>
        <template slot-scope="{row}">
          <td v-for="(item, index) in Object.values(row)" :key="index">{{item}}</td>
        </template>
        
      </base-table>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'upload-preview-csv-table',
    props: {
      type: {
        type: String
      },
      title: String,
      data: Array,
      columns: Array
    },
    data() {
      return {
        pointer: 'pointer',
        pointer2: 'default',
      }
    },
    methods: {}
  }
</script>
<style>
</style>