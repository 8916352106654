<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col-lg-2">
          <base-dropdown style="width: 100%">
            <base-button slot="title" type="success" outline class="dropdown-toggle" style="width: 100%" size="sm">
              {{validationPaymentFilter===1? 'Validados' : validationPaymentFilter===0? 'No validados': 'Validación'}}
            </base-button>
            <a class="dropdown-item" @click="setValidatedPaymentFilter(null)">Mostrar todos</a>
            <a class="dropdown-item" @click="setValidatedPaymentFilter(1)">Validados</a>
            <a class="dropdown-item" @click="setValidatedPaymentFilter(0)">No validados</a>
          </base-dropdown>
        </div>
        <div class="col-lg-2">
          <base-dropdown style="width: 100%">
            <base-button slot="title" type="warning" outline class="dropdown-toggle" style="width: 100%" size="sm">
                {{methodPaymentFilter===2? 'Transferencia' : methodPaymentFilter===1? 'Efectivo': 'Método pago'}}
            </base-button>
            <a class="dropdown-item" @click="setMethodPaymentFilter(null)">Mostrar todos</a>
            <a class="dropdown-item" @click="setMethodPaymentFilter(1)">Efectivo</a>
            <a class="dropdown-item" @click="setMethodPaymentFilter(2)">Transferencia</a>
          </base-dropdown>
        </div>
        <div class="col-lg-2">
          <base-dropdown style="width: 100%">
            <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
              {{getPeriodFilterText()}}
            </base-button>
            <!--a class="dropdown-item" @click="setPeriodFilter(null)">Mostrar todos</a-->
            <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
            <div v-if="!showSetPeriod" class="dropdown-divider"></div>
            <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
          </base-dropdown>
        </div>
        <div v-if="showSetPeriod" class="col">
          <div class="row justify-content-center">
            <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
            <span class="small text-muted">-</span>
            <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
            <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive" v-bind:style="{cursor:pointer}">
      <base-table class="table align-items-center table-flush text-center"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data"
                  @click="paymentSelected">

        <template slot="columns">
          <th v-bind:style="{cursor:pointer2}">Depósito</th>
          <th v-bind:style="{cursor:pointer2}">Cliente</th>
<!--          <th v-bind:style="{cursor:pointer2}">Nombre</th>-->
          <th v-bind:style="{cursor:pointer2}">Monto</th>
          <th v-bind:style="{cursor:pointer2}">Fecha de pago</th>
<!--          <th v-bind:style="{cursor:pointer2}">Fecha validación</th>-->
<!--          <th v-bind:style="{cursor:pointer2}">Tipo de depósito</th>-->
          <!--th v-bind:style="{cursor:pointer2}">Fecha de ingreso</th>
          <th v-bind:style="{cursor:pointer2}">Meses en el programa</th-->
          <th v-bind:style="{cursor:pointer2}">Referencia incorrecta</th>
          <th v-bind:style="{cursor:pointer2}">STP sin reflejar</th>
          <th v-bind:style="{cursor:pointer2}">Sin localizar</th>
          <th v-bind:style="{cursor:pointer2}">No se visualiza</th>
          <th v-if="!isAuditor" v-bind:style="{cursor:pointer2}">Subir voucher</th>
          <th v-bind:style="{cursor:pointer2}">Comentarios</th>
          <th v-if="isAuditor || isManagerService || isAdmin" v-bind:style="{cursor:pointer2}">Comentarios DBM</th>
          <th v-bind:style="{cursor:pointer2}">Fecha audición</th>
          <th v-bind:style="{cursor:pointer2}">Respuesta FA</th>
<!--          <th v-bind:style="{cursor:pointer2}">Método de depósito</th>-->

        </template>
        <template slot-scope="{row}">
          <td class="text-center">{{row.id}}</td>
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <a :href="'https://admin.dbmenos.com/client?id='+row.user" target="_blank">
                  <span class="name mb-0 text-sm">{{row.user}}<i class="fas fa-user-edit text-primary"></i></span>
                </a>
              </div>
            </div>
          </th>
<!--          <td class="text-center">{{row.name}}</td>-->
          <td class="text-center">{{$formatCurrency(row.amount)}}</td>
          <td class="text-center">{{ $formatDate(row.created)}}</td>
<!--          <td class="text-center">{{row.validated_date? $formatDate(row.validated_date) : '-&#45;&#45;'}}</td>-->
<!--          <td class="text-center">{{row.type_payment}}</td>-->
          <!--td class="text-center">{{ $formatDate(row.first_payment)}}</td>
          <td class="text-center">{{row.months_in_program}}</td-->
          <td class="text-center" @click.stop>
            <base-checkbox :checked="Boolean(row.wrong_reference)" @input="setValue(row.id, row.wrong_reference, 1)"/>
          </td>
          <td class="text-center" @click.stop>
            <base-checkbox :checked="Boolean(row.unreflected_stp)" @input="setValue(row.id, row.unreflected_stp, 2)"/>
          </td>
          <td class="text-center" @click.stop>
            <base-checkbox :checked="Boolean(row.deposit_not_located)" disabled/>
          </td>
          <td class="text-center" @click.stop>
            <base-checkbox :checked="Boolean(row.concept_voucher_error)" disabled/>
          </td>
          <td v-if="!isAuditor" class="text-center">
            <a :href="row.token_update_payment? $v.CLIENT_SERVER+$v.PAYMENT_PATH+'?token='+row.token_update_payment : null" target="_blank">
              <span v-if="row.token_update_payment" class="name mb-0 text-sm"><i class='fas fa-upload text-primary'>Cargar</i></span>
              <span v-else>---</span>
            </a>
          </td>
          <td class="text-center"> {{ row.finamigo_comments ? row.finamigo_comments : '---' }}</td>
          <td v-if="isAuditor || isManagerService || isAdmin" class="text-center"> {{ row.dbm_comments ? row.dbm_comments : '---' }}</td>
          <td class="text-center"> {{ row.audition_date ? $formatDateHrs(row.audition_date) : '---' }}</td>
          <td class="text-center"> {{ row.finamigo_reply_date ? $formatDateHrs(row.finamigo_reply_date) : '---' }}</td>
          <!-- <td class="text-center">{{row.method_payment==2? 'Transferencia' : 'Efectivo'}}</td> -->
        </template>
      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="col">
        <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
          Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} Pagos
        </p>
      </div>
      <base-pagination
          size="sm"
          :total="pagination.total"
          :per-page="pagination.perPage"
          :value="pagination.currentPage"
          v-on:pageSelected="pageSelected"/>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: 'auditor-view-table',
  components: {
    flatPickr
  },
  props: {
    type: {
      type: String
    },
    title: String,
    data: Array,
    pagination: Object,
    period: {
      start: null,
      end: null
    },
  },
  data() {
    return {
      isManagerService: false,
      isAdmin: false,
      isAuditor: false,
      tableData: [],
      consultantFilter: null,
      methodPaymentFilter: null,
      validationPaymentFilter: null,
      showSetPeriod: false,
      periodFilter: null,
      pointer: 'pointer',
      pointer2: 'default',
      table: 1,
    }
  },
  created() {
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.table = this.title === 'Auditoria Pagos Validados FA'?2: this.title === "Auditoria Pagos Nuevos Clientes"?3:1;
    //this.setPeriodFilter(1)
  },
  computed: {
    user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
    customerService() { return this.$store.state.customerService },
    sellers() { return this.$store.state.sellers },
  },
  methods: {
    pageSelected(page) {
      this.$emit("pageSelected", page, this.table);
    },
    paymentSelected(payment) {
      this.$emit("paymentSelected", payment, this.table);
    },
    setPeriod(){
      this.showSetPeriod=true
      this.period.start=null
      this.period.end=null
      this.getPeriodFilterText()
    },
    setMethodPaymentFilter(value){
      this.methodPaymentFilter = value;
      this.$forceUpdate();
      this.$emit("setMethodPaymentFilter", value, this.table);
    },
    setValidatedPaymentFilter(value){
      this.validationPaymentFilter = value;
      this.$forceUpdate();
      this.$emit("setValidatedPaymentFilter", value, this.table);
    },
    setPeriodFilter(value) {
      if(value!=2){
        this.showSetPeriod = false
        this.periodFilter = value
        this.$emit("setPeriodFilter", value, this.table);

      }else if(this.period.start && this.period.end){
        this.periodFilter = value
        this.$emit("setPeriodFilter", value, this.table);
      }
    },
    setValue(id_payment, value, type){
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SERVICE_UPDATE_PAYMENT;
      let request  = {id: id_payment}
      if(type===1) request.wrong_reference = !value;
      else if(type===2) request.unreflected_stp = !value;
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
          location.reload();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    getPeriodFilterText() {
      if(this.showSetPeriod)
        return "Periodo del: "
      else if(this.periodFilter === 1)
        return "Mes actual"
      else return "Fecha"
    },
  }
}
</script>
<style>
</style>