<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-3"  v-bind:style="{cursor:pointer2}">
          <audit-info-dashboard-table v-if="!isSeller"/>
        </div>
        <div class="col" v-bind:style="{cursor:pointer2}">
          <auditor-full-view-table
              title="Auditoria clientes"
              :data="tableData"
              :pagination="pagination"
              :period="period"
              @setPayRollFilter="setPayRollFilter"
              @setHighRiskFilter="setHighRiskFilter"
              @setStatusAuditFilter="setStatusAuditFilter"
              @setPeriodFilter="setPeriodFilter"
              @pageSelected="getData"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuditorFullViewTable from './Tables/AuditorFullViewTable';
import AuditInfoDashboardTable from "./Tables/AuditInfoDashboardTable";
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";

export default {
  name: "AuditorView",
  components: {
    AuditInfoDashboardTable,
    AuditorFullViewTable,
    //flatPickr
  },
  async mounted() {
    this.$root.$on('searchQuery', query => {
      if (query.length > 0){
        this.searchQuery = query
     } else{
        this.searchQuery = null
        this.getData(1)
      }
    })
  },
  computed: {
    user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
    auditorCatalog() { return this.$store.state.auditorCatalog },
  },
  data() {
    return {
      searchQuery: null,
      tableData: [],
      pagination: {
        total: 0,
        perPage: 500,
        currentPage: 1
      },
      periodFilter: null,
      filterHighRisk: false,
      filterStatusAudit: false,
      filterPayroll: false,
      period: {
        start: null,
        end: null
      },
      auditRowSelect: {},
      pointer: 'pointer',
      pointer2: 'default',
      auditModal: false,

      isManagerService: false,
      isManagerSeller: false,
      isAdmin: false,
      isAuditor: false,
      isSeller: false,
      isService: false,
    }
  },
  async created() {
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE;

   await this.getData(1);
    
  },
  methods: {
    async getData(page) {
      let limit = this.pagination.perPage
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_VIEW_AUDITOR
      let request ={
        offset: limit*(page - 1),
        limit: limit,
      }
      request.firstPaymentDate = {
          gte: this.$moment().startOf('month').format('YYYY-MM-DD'),
        }
      //if (this.isManagerSeller) request.team_id = this.user.id
      if (this.isSeller) request.consultant_id = this.user.id
      if (this.periodFilter === 1) {
        request.firstPaymentDate = {gte: this.$moment().startOf('month').format('YYYY-MM-DD')}
      }else if (this.periodFilter === 2){
        request.firstPaymentDate = {
          gte: this.period.start,
          lte: this.period.end
        }
      }
      if(this.filterPayroll) request.payroll = this.filterPayroll
      if(this.filterHighRisk) request.high_risk = this.filterHighRisk
      if(this.filterStatusAudit) request.complete_docs = this.filterStatusAudit


      const lr = request
      if (lr === this.lastRequest) return
      this.lastRequest = lr;

      this.$showLoader(true)
      await this.$axios.post(url,request).then(response => {
        this.$showLoader(false)
        let data = response.data
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW]
          this.tableData = data[this.$v.API_ROWS]
          this.pagination.total = p['totalItems']
          this.pagination.currentPage = page
          this.pagination.perPage = limit
        } else { this.$validateSession(data) }
      })
    },
    setPeriodFilter(value) {
      if(value === 1){
        this.period.start = this.$moment().startOf("month").format('YYYY-MM-DD')
      }
      this.periodFilter = value
      this.getData(1)
    },
    setPayRollFilter(value){
      this.filterPayroll = value;
      this.getData(1)
    },
    setStatusAuditFilter(value){
      this.filterStatusAudit = value;
      this.getData(1)
    },
    setHighRiskFilter(value){
      this.filterHighRisk = value;
      this.getData(1)
    },
  }
}
</script>

<style>
</style>