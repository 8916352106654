<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

    <div class="container-fluid mt--7" @click="uploadSelected = null">
      <div class="row">
        <div class="col" v-bind:style="{cursor:pointer2}">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="icon icon-shape bg-success text-white">
                    <i class="fab fa-google"></i>
                  </div>
                </div>
                <div class="col">
                  <h3 class="mb-0">Reportes de Google Sheets</h3>
                </div>
              </div>
              <div class="dropdown-divider" style="margin-top: 20px"></div>
              <div class="row" style="margin-top: 20px">
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updloadSheetsFacture()">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">CDFI</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-info text-white">
                            <i class="fas fa-clipboard-list"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updloadSheetsNewClients()">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Nuevos Clientes</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-user-plus"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <!--
                <div class="col-xl-4 col-lg-4 col-md-4" v-if="isAdmin">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updloadSheetsCreateCommissions()">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Comisiones</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-success text-white">
                            <i class="fas fa-coins"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                !-->
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updloadSheetsHistoryPayments()">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Historial Pagos</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updloadSheetsDuePayments()">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Pagos Atrasados</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-danger text-white">
                            <i class="fas fa-hand-holding-usd"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updloadSheetsBalances()">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Comparación Saldos</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-warning text-white">
                            <i class="fas fa-balance-scale"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4" v-if="isAdmin || isManagerService">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updloadSheetsCierres()">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Reportes de Cierre SC</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-success text-white">
                            <i class="fas fa-file-excel"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updloadSheetsCreditReports()">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Reporte Burós</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updateReportSheet('sales')">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Resumen de Ventas</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updateReportSheet('calls')">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Llamadas de Asesores</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updateReportSheet('activities')">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Actividades ventas</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updateReportSheet('services')">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Servicio al cliente</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updateReportSheet('all_debts')">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Todas las Deudas</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updateReportSheet('deposits')">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Depósitos</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updateReportSheet('customer_service_tasks')">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Tareas Bienvenidas</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updateReportSheet('client_status_update')">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Actualización de Status Clientes</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updateReportSheet('registered_in_mate')">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Registrados en Mate</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card card-stats mb-4 mb-xl-2" v-on:click="updateReportSheet('acquisition_channels')">
                    <card class="border-2 btn-primary btn-sm" shadow body-classes="py-4">
                      <div class="row">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Actualizar</h6>
                          <span class="h2 font-weight-bold mb-0">Canales de adquisición</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-primary text-white">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sheets-reports",
  components: {},
  data() {
    return {
      uploadSelected: null,
      uploadData: 0,
      pointer: "pointer",
      pointer2: "default",
      massivePN: {
        users: "",
        title: "",
        message: "",
      },
      isAdmin: false,
      isSeller: false,
      isService: false,
      isManagerService: false,
      isAuditor: false,
    };
  },
  async created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    console.log(this.user.role)
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
  },
  methods: {
    updloadSheetsFacture() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.UPDATE_FACTURE;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
              'success',
              'Actualización exitosamente'
          ));
          window.open(
              "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
              "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
              'danger',
              'Algo salió mal, intenta nuevamente'
          ));
        }
      });
    },
    updloadSheetsNewClients() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.UPDATE_NEW_CLIENTS;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
              'success',
              'Actualización exitosamente'
          ));
          window.open(
              "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
              "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
              'danger',
              'Algo salió mal, intenta nuevamente'
          ));
        }
      });
    },
    updloadSheetsBalances() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.UPDATE_BALANCE;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
              'success',
              'Actualización exitosamente'
          ));
          window.open(
              "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
              "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
              'danger',
              'Algo salió mal, intenta nuevamente'
          ));
        }
      });
    },
    updloadSheetsCierres() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.CREATE_REPORTS_CIERRE_SC;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
              'success',
              'Actualización exitosamente'
          ));
          window.open(
              "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
              "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
              'danger',
              'Algo salió mal, intenta nuevamente'
          ));
        }
      });
    },
    updloadSheetsCreditReports() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.UPDATE_CREDIT_REPORTS;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
              'success',
              'Actualización exitosamente'
          ));
          window.open(
              "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
              "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
              'danger',
              'Algo salió mal, intenta nuevamente'
          ));
        }
      });
    },
    urlEndPoint(sheet)
    {
      let url = this.$v.SERVER

      switch (sheet)
      {
        case 'sales':
          url += this.$v.REPORTS_SHEETS.UPDATE_SALES_REPORTS;
          break
        case 'calls':
          url += this.$v.REPORTS_SHEETS.UPDATE_CALLS_ADVISERS_REPORTS;
          break
        case 'activities':
          url += this.$v.REPORTS_SHEETS.UPDATE_ACTIVITIES_REPORTS;
          break
        case 'services':
          url += this.$v.REPORTS_SHEETS.UPDATE_SERVICES_REPORTS;
          break
        case 'all_debts':
          url += this.$v.REPORTS_SHEETS.UPDATE_ALL_DEBTS;
          break
        case 'deposits':
          url += this.$v.REPORTS_SHEETS.UPDATE_DEPOSITS;
          break
        case 'customer_service_tasks':
          url += this.$v.REPORTS_SHEETS.UPDATE_CUST_SERV_TASKS;
          break
        case 'client_status_update':
          url += this.$v.REPORTS_SHEETS.UPDATE_CLIENT_STATUS_UPD;
          break
        case 'registered_in_mate':
          url += this.$v.REPORTS_SHEETS.UPDATE_REGISTERED_IN_MATE;
          break
        case 'acquisition_channels':
          url += this.$v.REPORTS_SHEETS.UPDATE_ACQUISITION_CHANNELS;
          break
      }

      return url;
    },
    updateReportSheet(sheet)
    {
      this.$showLoader(true);

      let url = this.urlEndPoint(sheet)

      this.$axios.post(url, {timeout: 5000}).then((response) => {

        this.$showLoader(false);

        let data = response.data;

        if (data[this.$v.API_SUCCESS]) {

          this.$vs.notification(this.$notification('success', 'Actualización exitosa'));

          window.open("https://docs.google.com/spreadsheets/d/" + data.row.sheetsId, "_blank");

          this.$forceUpdate();

        } else {
          this.$vs.notification(this.$notification('danger', 'Algo salió mal, intenta nuevamente'));
        }
      });
    },
    updloadSheetsHistoryPayments() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.UPDATE_AUTO_PAYMENTS;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
              'success',
              'Actualización exitosamente'
          ));
          window.open(
              "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
              "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
              'danger',
              'Algo salió mal, intenta nuevamente'
          ));
        }
      });
    },
    updloadSheetsDuePayments() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.UPDATE_DUE_PAYMENTS;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
              'success',
              'Actualización exitosamente'
          ));
          window.open(
              "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
              "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
              'danger',
              'Algo salió mal, intenta nuevamente'
          ));
        }
      });
    },
    updloadSheetsCreateCommissions() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.CREATE_COMMISSIONS;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
              'success',
              'Actualización exitosamente'
          ));
          window.open(
              "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
              "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
              'danger',
              'Algo salió mal, intenta nuevamente'
          ));
        }
      });
    },
  },
};
</script>
<style></style>