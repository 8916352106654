<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''" style="font-size:1.5rem;">
                        {{title}}
                    </h3>
                    <h4 v-if="(amountReal<=-0.1) && showData" class="mb-0 text-success" :class="type === 'dark' ? 'text-white': ''">
                        Debe ser: {{$formatCurrency(amountCorrect)}}
                    </h4>
                    <h4 v-if="(amountReal<=-0.1) && showData" class="mb-0 text-danger">
                        Diferencia: {{$formatCurrency(amountReal)}}
                    </h4>
                </div>
                <div class="col-sm-3 text-right" style="width: 100%">
                    <base-button v-if="!showData && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="showMovements"></base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                        <!--@click="movementSelected"-->

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">Fecha</th>
                    <th v-bind:style="{cursor:pointer2}">Tipo</th>
                    <th v-bind:style="{cursor:pointer2}">Debe ser</th>
                    <th v-bind:style="{cursor:pointer2}">Monto Cobrado</th>
                    <th v-bind:style="{cursor:pointer2}">Diferencia</th>
                </template>

                <template v-if="showData" slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{$formatDate(row.date)}}</span>
                            </div>
                        </div>
                    </th>
                    <td>{{getMovType(row.type)}}</td>
                    <td>{{$formatCurrency(row.amount_correct)}}</td>
                    <td>{{$formatCurrency(row.amount)}}</td>
                    <td>{{$formatCurrency(row.amount_real)}}</td>
                </template>
            </base-table>
        </div>

        <div v-if="(pagination.total > pagination.perPage) && showData" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'fee-movements-table',
        props: {
            type: {
                type: String
            },
            title: String,
            amount: Number,
            amountReal: Number,
            amountCorrect:Number,
            data: Array,
            showEye: Boolean,
            pagination: Object
        },
        data() {
            return {
                tableData: [],
                showPayments: false,
                validatedPaymentsFilterText: "",
                movTypeFilterText: "",
                pointer: 'pointer',
                pointer2: 'default',
                isAdmin:false,
                isManagerService:false,
                showData:false,
            }
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
        },
        methods: {
            showMovements(){
                this.showData = true;
                this.$emit("showMovements")
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            formatDate(date) {
               return this.$formatDate(date)
            },
            onSendAccountStatement() {
                this.$emit("onSendAccountStatement")
            },
            getMovType(type){
                return type==='payment'? 'Ahorro Mensual': type==='commission'? 'Comisión Mensual' : type ==='baja_remate' ? 'Baja' : type ==='money_back' ? 'Devolución al Cliente' : 'Liquidación de Deuda'
            },
            setMovTypeFilter(type){
                switch (type) {
                    case 1:
                        this.movTypeFilterText = 'Pagos'
                        type='payment'
                        break;
                    case 2:
                        this.movTypeFilterText = 'Comisión'
                        type="commission"
                        break;
                    case 3:
                        this.movTypeFilterText = 'Liquidación'
                        type='account_payment'
                        break;
                    case 4:
                        this.movTypeFilterText = 'Baja'
                        type='baja_remate'
                        break;
                    case 5:
                        this.movTypeFilterText = 'Devolución al Cliente'
                        type='money_back'
                        break;
                    default:
                        this.movTypeFilterText = ''
                        type=null
                        break;
                }
                this.showPayments = type === 'payment';
                this.$emit("setMovTypeFilter", type);
            },
            setValidatedPaymentsFilter(value){
                this.validatedPaymentsFilterText = value === 0? 'No validados' : value === 1? 'Validados' : ''
                this.$emit("setValidatedPaymentsFilter", value);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            /*onNewItem() {
                this.$emit("onNewItem")
            },
            movementSelected(payment) {
                this.$emit("onMovementSelected", payment)
            }*/
        }
    }
</script>
<style>
</style>
