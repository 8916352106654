<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right" >
                    <base-dropdown v-if="!lecturyAdd">
                        <base-button  slot="title" type="success" class="dropdown-toggle">
                            {{newTrace.type ? newTrace.type : "Nuevo"}}
                        </base-button>
                        <a class="dropdown-item" v-for="(type, index) in typeAddendums" :key="index" v-on:click="onNewItem(type)">
                            {{type.type}}
                        </a>
                    </base-dropdown>
                </div>
                <!--div class="col text-right">
                    <base-button type="primary" size="sm" v-on:click="onNewItem">Nuevo</base-button>
                </div-->
            </div>
        </div>

        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}" >
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">

                <template slot="columns">
                    <!--
                    <th v-bind:style="{cursor:pointer2}">ID</th>
                    <th v-bind:style="{cursor:pointer2}">Propuesta Adendum</th>
                    !-->
                    <th v-bind:style="{cursor:pointer2}">Cuenta</th>
                    <th v-bind:style="{cursor:pointer2}">Tipo Addendum</th>
                    <th v-bind:style="{cursor:pointer2}">Nuevo Ahorro</th>
                    <th v-bind:style="{cursor:pointer2}">Banco</th>
                    <th v-bind:style="{cursor:pointer2}">Número Cuenta</th>
                    <th v-bind:style="{cursor:pointer2}">Tipo Producto</th>
                    <th v-bind:style="{cursor:pointer2}">Monto Deuda</th>
                    <th v-bind:style="{cursor:pointer2}">Último Pago Bancario</th>
                </template>

                <template slot-scope="{row}" >
                    <!--
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{row.id}}</span>
                            </div>
                        </div>
                    </th>
                    <td>{{row.addendum_approach}}</td>
                    !-->
                    <td>{{row.account ?row.account:'- - -'}}</td>
                    <td>{{getTypeAddendum(row.type_addendum)}}</td>
                    <td>{{row.new_saving ?$formatCurrency(row.new_saving):'- - -'}}</td>
                    <td>{{row.bank ?getBank(row.bank):'- - -'}}</td>
                    <td>{{row.number_account ?row.number_account:'- - -'}}</td>
                    <td>{{getProductType(row.product_type)}}</td>
                    <td>{{$formatCurrency(row.total_debt)}}</td>
                    <td>{{row.last_pay_date ? formDate(row.last_pay_date):'Sin información'}}</td>
                </template>

            </base-table>
        </div>

        <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'clients-table',
        props: {
            type: {
                type: String
            },
            title: String,
            amount: Number,
            data: Array,
            lecturyAdd: Boolean,
            pagination: Object
        },
        data() {
            return {
                tableData: [],
                newTrace: {
                    type: null,
                    id: 0
                },
                pointer: 'pointer',
                pointer2: 'default'
            }
        },
        computed: {
            banks() { return this.$store.state.banks },
            typeAddendums() {return this.$store.state.typeAddendums},
            productType() { return this.$store.state.productType },

        },
        async created() { },
        methods: {
            formDate(date) {
      return this.$moment(date).format("DD MMM YYYY");
    },
            getProductType(id) {
                for (let i=0 ; i<this.productType.length ; i++) {
                    if (this.productType[i].id === id) {
                        return this.productType[i].type
                    }
                }
            },
            getBank(id) {
                for (let i = 0; i < this.banks.length; i++)
                if (this.banks[i].id === id) return this.banks[i].name;
            return null;
        },
            getTypeAddendum(id) {
                for (let i=0 ; i<this.typeAddendums.length ; i++) {
                    if (this.typeAddendums[i].id === id) {
                        return this.typeAddendums[i].type
                    }
                }
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            onNewItem(type) {
                this.newTrace.type = type.type;
                this.newTrace.id = type.id;
                this.$emit("onNewItem", type)
            }
        }
    }
</script>
<style>
</style>