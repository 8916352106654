<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col-lg-2">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{validationFilter===0 ? "Sin validar" : validationFilter ? "Validados" : "Validacion pagos"}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setValidationFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setValidationFilter(1)">Validados</a>
                        <a class="dropdown-item" @click="setValidationFilter(0)">Sin validar</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" style="width: 100%" size="sm">
                            {{getCustomerFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setConsultantFilter(null)">Mostrar todos</a>
                        <div class="dropdown-divider"></div>
                        <a v-for="(customerS) in customerService" :key="customerS.id" class="dropdown-item" v-on:click="setConsultantFilter(customerS.id)">{{customerS.name}} {{customerS.lastname_1}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="danger" outline class="dropdown-toggle" style="width: 100%" size="sm">
                            {{getSellerFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setSellerFilter(null)">Mostrar todos</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" v-for="(seller) in sellers" :key="seller.id" v-on:click="setSellerFilter(seller.id)">{{seller.name}} {{seller.lastname_1}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getPeriodFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(1)">{{'Mes actual'}}</a>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(2)">{{'Mes anterior'}}</a>
                        <!--
                        <div v-if="!showSetPeriod" class="dropdown-divider"></div>
                        <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
                        !-->
                    </base-dropdown>
                </div>    
                <!--            
                <div v-if="showSetPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
                        <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
                    </div>
                </div>
                !-->
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">Usuario</th>
                    <th v-bind:style="{cursor:pointer2}">Nombre</th>
                    <th v-bind:style="{cursor:pointer2}">Vendedor</th>
                    <th v-bind:style="{cursor:pointer2}">Ahorro Mensual</th>
                    <th v-bind:style="{cursor:pointer2}">MDI</th>
                    <th v-bind:style="{cursor:pointer2}">Total 1er Ahorro</th>
                    <th v-bind:style="{cursor:pointer2}">Pagos Sin Validar</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha 1er Pago</th>
                    <th v-bind:style="{cursor:pointer2}">Sistema</th>
                    <th v-bind:style="{cursor:pointer2}">ESC</th>
                    <th v-bind:style="{cursor:pointer2}">Genero</th>
                    <th v-bind:style="{cursor:pointer2}">Status</th>
                </template>

                <template slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <a :href="'https://admin.dbmenos.com/client?id='+row.id" target="_blank">
                                    <span class="name mb-0 text-sm">{{row.id}}<i class="fas fa-user-edit text-primary"></i></span>
                                </a>
                            </div>
                        </div>
                    </th>
                    <td>{{row.name}}</td>
                    <td>{{row.seller}}</td>
                    <td class="text-center">{{formatCurrency(row.firstPaymentAmount)}}</td>
                    <td class="text-center">{{formatCurrency(row.MDI)}}</td>
                    <td class="text-center">{{formatCurrency(row.paymentSum)}}</td>
                    <td class="text-center">{{row.notValidatedPaymentCount}}</td>
                    <td>{{formatDate(row.firstPaymentDate)}}</td>
                    <td>{{row.device ? row.device: 'Tradicional'}}</td>
                    <td>{{row.consultant}}</td>
                    <td>{{row.gender == 'M' ? 'Masculino' : 'Femenino'}}</td>
                    <td>{{row.status}}</td>
                </template>
            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} nuevos usuarios
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    //import flatPickr from 'vue-flatpickr-component';
    //import 'flatpickr/dist/flatpickr.css';

    export default {
        name: 'new-users-table',
        components: {
           // flatPickr
        },
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            period: {
                start: null,
                end: null
            },
        },
        data() {
            return {
                isService: false,
                isAuditor: false,
                tableData: [],
                tableNU: null,
                consultantFilter: null,
                sellerFilter:null,
                completePaymentFilter: null,
                validationFilter: null,
                showSetPeriod: false,
                periodFilter: 1,
                pointer: 'pointer',
                pointer2: 'default',
            }
        },
        created() {
            this.isService = this.user.role === this.$v.ROLES.SERVICE || this.user.role === this.$v.ROLES.AUDITOR
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR
            this.tableNU = this.title==='Nuevos usuarios'? true:false;
            //this.setPeriodFilter(1,this.tableNU)
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            customerService() { return this.$store.state.customerService },
            sellers() { return this.$store.state.sellers },
        },
        methods: {
            pageSelected(page) {
                this.$emit("pageSelected", page, this.tableNU);
            },
            formatDate(date) {
            if (date <= "2020-11-10") {
                return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
            } else {
                return this.$moment(date).format("DD MMM YYYY, h:mm a");
            }
            },
            /*showGraphic(){
                this.graphic=!this.graphic
                this.$emit("showGraphic");
            },*/
            setConsultantFilter(value) {
                this.consultantFilter = value
                this.$emit("setConsultantFilter", value, this.tableNU);
            },
            setSellerFilter(value) {
                this.sellerFilter = value
                this.$emit("setSellerFilter", value, this.tableNU);
            },
            getCustomerFilterText() {
                if (this.consultantFilter) return this.getConsultantName(this.consultantFilter).name
                return "Consultores ESC"
            },
            getSellerFilterText() {
                if (this.sellerFilter) return this.getConsultantName(this.sellerFilter).name
                return "Vendedores"
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id){
                        return this.sellers[i]
                    }
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id){
                        return this.customerService[i]
                    }
                return "- - -"
            },
            isServiceConsultant(consultantName) {
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].name+" "+this.customerService[i].lastname_1+" "+this.customerService[i].lastname_2 === consultantName){
                        return true
                    }
                return false
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            setCompletePaymentFilter(value){
                this.completePaymentFilter = value
                this.$emit("setCompletePaymentFilter", value, this.tableNU)
            },
            setValidationFilter(value){
                this.validationFilter = value
                this.$emit("setValidationFilter", value, this.tableNU)
            },
            setPeriod(){
                this.showSetPeriod = true
                this.period.start=null
                this.period.end=null
                this.getPeriodFilterText()
            },
            setPeriodFilter(value) {        
                if(value==1 || value==2){
                    this.showSetPeriod = false
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value, this.tableNU);
                }
            },
            getPeriodFilterText() {
                if(this.showSetPeriod)
                    return "Periodo del: "
                else if(this.periodFilter === 1)
                    return this.title==='Nuevos usuarios'?"Mes actual":"Mes anterior"
                else return "Fecha"
            },
            assignToService(userId, serviceId) {
                let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.ASSIGN_USER_SERVICE
                this.$axios.post(url, {'user': userId, 'consultant': serviceId}).then(response => {
                    let data = response.data
                    if (data[this.$v.API_SUCCESS]) {
                        this.$vs.notification(this.$notification(
                'success',
                 'Asignación realizada exitosamente'
                     ));
                        this.uploadData = data[this.$v.API_ROW]
                        this.uploadSelected=null
                    } else {
                        this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    }
                })
            }
        }
    }
</script>
<style>
</style>