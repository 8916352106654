<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

		<div class="container-fluid mt--7" @click="allNull()">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<div class="card shadow">
						<div class="card-header border-0">
							<div class="row align-items-center">
								<div class="col">
									<h3 class="mb-0">
										Cargas masivas
									</h3>
								</div>
							</div>
							<div class="dropdown-divider" style="margin-top: 20px"></div>
							<div class="row" style="margin-top: 20px">
								<div class="col-xl-4 col-lg-4 col-md-4">
									<div class="card card-stats mb-4 mb-xl-2" @click.stop="uploadSelected = 'commissions'">
									<card :class="uploadSelected=='commissions' ? 'border-2 btn-sm' : 'border-2 btn-primary btn-sm'" shadow body-classes="py-4">
											<div class="row">
												<div class="col">
													<h6 class="card-title text-uppercase mb-1">Cargar</h6>
													<span class="h2 font-weight-bold mb-0">Comisiones</span>
												</div>
												<div class="col-auto">
													<div class="icon icon-shape bg-warning text-white">
														<i class="fas fa-donate"></i>
													</div>
												</div>
											</div>
											<dropbox v-if="uploadSelected=='commissions'" type="csv" buttonText="Cargar" v-on:uploadFile="uploadMassiveFile"/>
											<div v-else class="col-lg2" style="margin-top: 30px">
												<span class="h5 font-weight-bold text-muted">Cargas recientes:</span>
												<h4>{{uploadData.commissions+" Comisiones"}}</h4>
											</div>
										</card>
									</div>
								</div>

								<div class="col-xl-4 col-lg-4 col-md-4">
									<div class="card card-stats mb-4 mb-xl-2" @click.stop="uploadSelected = 'pushNotif'">
									<card :class="uploadSelected=='pushNotif' ? 'border-2 btn-sm' : 'border-2 btn-primary btn-sm'" shadow body-classes="py-4">
											<div class="row">
												<div class="col">
													<h6 class="card-title text-uppercase mb-1">Cargar</h6>
													<span class="h3 font-weight-bold mb-0">Notificaciones push</span>
												</div>
												<div class="col-auto">
													<div class="icon icon-shape bg-primary text-white">
														<i class="fas fa-bell"></i>
													</div>
												</div>
											</div>
											<div v-if="uploadSelected=='pushNotif'" class="col-lg2" style="margin-top: 30px">
												<base-input alternative=""
														placeholder="Users ID separados por coma..."
                                                        label="Destinatarios:"
                                                        input-classes="form-control-alternative"
                                                        v-model="massivePN.users"/>
												<base-input alternative=""
														placeholder="Escribe un titulo..."
                                                        label="Titulo:"
                                                        input-classes="form-control-alternative"
                                                        v-model="massivePN.title"/>
												<base-input alternative=""
														placeholder="Escribe un mensaje..."
                                                        label="Mensaje:"
                                                        input-classes="form-control-alternative"
                                                        v-model="massivePN.message"/>
												<base-button slot="title" type="success" class="btn" size="sm" v-on:click="sendMassivePushNotifications()">
													Enviar
												</base-button>
												<!--span class="h5 font-weight-bold text-muted">Cargas recientes:</span>
												<h4>{{uploadData+" Notificaciones"}}</h4-->
											</div>
											<!--dropbox v-if="uploadSelected=='commissions'" type="csv" buttonText="Cargar" v-on:uploadFile="uploadMassiveFile"/-->
											<div v-else class="col-lg2" style="margin-top: 30px">
												<span class="h5 font-weight-bold text-muted">Cargas recientes:</span>
												<h4>{{uploadData.pushNotif+" Notificaciones"}}</h4>
											</div>
										</card>
									</div>
								</div>

								<div class="col-xl-4 col-lg-4 col-md-4">
									<div class="card card-stats mb-4 mb-xl-2" @click.stop="uploadSelected = 'negotiations'">
									<card :class="uploadSelected=='negotiations' ? 'border-2 btn-sm' : 'border-2 btn-primary btn-sm'" shadow body-classes="py-4">
											<div class="row">
												<div class="col">
													<h6 class="card-title text-uppercase mb-1">Cargar</h6>
													<span class="h2 font-weight-bold mb-0">Negociaciones</span>
												</div>
												<div class="col-auto">
													<div class="icon icon-shape bg-success text-white">
														<i class="fas fa-handshake"></i>
													</div>
												</div>
											</div>
											<dropbox v-if="uploadSelected=='negotiations'" type="csv" buttonText="Vista previa" v-on:uploadFile="showPreviewCSV"/>
											<div v-else class="col-lg2" style="margin-top: 30px">
												<span class="h5 font-weight-bold text-muted">Cargas recientes:</span>
												<h4>{{uploadData.negotiations+" Negociaciones"}}</h4>
											</div>
										</card>
									</div>
								</div>

								<div class="col-xl-4 col-lg-4 col-md-4">
									<div class="card card-stats mb-4 mb-xl-2" @click.stop="uploadSelected = 'incidenciasFA'">
										<card :class="uploadSelected=='incidenciasFA' ? 'border-2 btn-sm' : 'border-2 btn-primary btn-sm'" shadow body-classes="py-4">
											<div class="row">
												<div class="col">
													<h6 class="card-title text-uppercase mb-1">Cargar</h6>
													<span class="h2 font-weight-bold mb-0">CSV</span>
												</div>
												<div class="col-auto">
													<div class="icon icon-shape bg-success text-white">
														<i class="fas fa-file-upload"></i>
													</div>
												</div>
											</div>
											<dropbox v-if="uploadSelected=='incidenciasFA'" type="csv" buttonText="Vista previa" v-on:uploadFile="showPreviewCSV"/>
											<div v-else class="col-lg2" style="margin-top: 30px">
												<span class="h5 font-weight-bold text-muted">Cargas recientes:</span>
												<h4>{{uploadData.incidenciasFA+" Incidencias"}}</h4>
											</div>
										</card>
									</div>
								</div>

								<div class="col-xl-4 col-lg-4 col-md-4" v-if="isDev">
									<div class="card card-stats mb-4 mb-xl-2" @click.stop="selectAssignment()">
									<card :class="uploadSelected=='leadsAssignment' ? 'border-2 btn-sm' : 'border-2 btn-primary btn-sm'" shadow body-classes="py-4">
											<div class="row">
												<div class="col">
													<h6 class="card-title text-uppercase mb-1">Asignar</h6>
													<span class="h3 font-weight-bold mb-0">Leads</span>
												</div>
												<div class="col-auto">
													<div class="icon icon-shape bg-danger text-white">
														<i class="fas fa-hand-point-up"></i>
													</div>
												</div>
											</div>
											<div v-if="uploadSelected=='leadsAssignment'" class="col-lg2" style="margin-top: 30px">
												<div>
													<span class="h5 font-weight mb-0">Consultores</span>
													<br>
													<div class="btn-group btn-group-sm" role="group">
														<button type="button" :class="consultants.all? 'btn btn-primary':'btn btn-secondary'" @click="setFilter('all')">
															Todos
														</button>
														<button type="button" :class="consultants.newConsultant? 'btn btn-primary':'btn btn-secondary'" @click="setFilter('new')">
															Nuevos
														</button>
														<button type="button" :class="consultants.team? 'btn btn-primary':'btn btn-secondary'" @click="setFilter('team')">
															Equipos
														</button>
													</div>
													<br><br>
													<div v-if="consultants.team">
														<span class="h6 font-weight mb-0">Líderes</span>
														<br>
													</div>
													<div v-if="consultants.team">
														<div v-for="(leader, index) in teamsLeaders" :key="index">
															<base-checkbox  @input="setLeaders(leader.id)" :inline="true">
																<span class="text-muted">{{leader.name+' '+leader.lastname_1}}</span>
															</base-checkbox>
														</div>
													</div>
													<br v-if="consultants.team">
													<span class="h6 font-weight mb-0">Asesores ({{showConsultants.length}})</span>
													<br>
													<div>
														<div v-for="(item, index) in showConsultants" :key="index">
															<base-checkbox @input="setSelectedConsultants(item.id)" :checked="selectedConsultants.includes(item.id)">
																<span class="text-muted">{{item.name+' '+item.lastname_1}}</span>
															</base-checkbox>
														</div>
													</div>
												</div>
												<br>
												<div>
													<span class="h5 font-weight mb-0">Tipos Leads</span>
													<br>
													<base-checkbox  @input="setTypeLeads(0)">
															<span class="text-muted">Sin seguimiento</span>
													</base-checkbox>
													<div v-for="(status, index) in leadStatus" :key="index">
														<base-checkbox  @input="setTypeLeads(status.id)">
															<span class="text-muted">{{ status.st }}</span>
														</base-checkbox>
													</div>
													<base-checkbox  @input="setTypeLeads(100)">
														<span class="text-muted">Bucket</span>
													</base-checkbox>
												</div>
												<br>
												<base-input alternative="" input-classes="form-control-alternative"
														placeholder="Número de leads por asignar..."
                            label="Número de leads"
														type="number"
														v-model="totalLeads"/>
												<label class="form-control-label">Fecha de inicio de búsqueda</label>
												<flat-pickr :config="{}" class="form-control form-control-alternative" data-vv-as="Fecha" name="date" style="background-color: #ffffff"
													v-model="dateSearchLeads"/>
													<br>
												<base-button slot="title" type="success" class="btn" size="sm" v-on:click="requestAssignment()">
													Enviar
												</base-button>
											</div>
											<div v-else class="col-lg2" style="margin-top: 30px">
												<span class="h5 font-weight-bold text-muted">Cargas recientes:</span>
												<h4>{{uploadData.pushNotif+" Notificaciones"}}</h4>
											</div>
										</card>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<modal :show.sync="modalPreviewCSV" v-on:close="modalPreviewCSV=false" v-bind:large="true">
			<template slot="header">
				<h2 class="mb-0" style="margin-top: 20px; margin-left: 10px">Vista previa CSV</h2>
			</template>
				<upload-negotiation-table v-if="this.uploadSelected=='negotiations'"
					title="Actividades Negociación"
					:data="previewTableData"/>
				<upload-preview-csv-table v-else-if="this.uploadSelected=='incidenciasFA'"
					title="Incidencias FA"
					:columns="columns"
					:data="previewTableData"/>
			<template slot="footer">
				<base-button class="btn-sm" type="success"
					@click="uploadMassiveFile(csvFile)">Aplicar
				</base-button>
			</template>
		</modal>
	</div>
</template>
<script>
	import UploadNegotiationTable from "./Tables/UploadNegotiationTable";
	import UploadPreviewCsvTable from "./Tables/UploadPreviewCsvTable";
	import Dropbox from '../components/Dropbox';
	import flatPickr from "vue-flatpickr-component";
	import "flatpickr/dist/flatpickr.css";
	export default {
		name: 'massive-loads',
		components: {
			Dropbox,
			UploadNegotiationTable,
			UploadPreviewCsvTable,
			flatPickr
		},
		data() {
			return {
				isDev: false,
				uploadSelected: null,
				uploadData: {
					commissions: 0,
					pushNotif: 0,
					negotiations: 0,
					incidenciasFA: 0
				},
				pointer: 'pointer',
				pointer2: 'default',
				massivePN: {
					users: "",
					title: "",
					message: "",
				},

				//CSV
				previewTableData: [],
				modalPreviewCSV: false,
				columns: [],

				//Leads Assignment
				selectedConsultants: [],
				selectedLeaders: [],
				selectedTypesLead: [],
				showConsultants: [],
				totalLeads: 0,
				dateSearchLeads: null,
				consultants: {
					all: true,
					team: false,
					newConsultant: false,
				}
			};
		},
		computed: {
			user() { return this.$getLS(this.$v.STORE_KEYS.USER); },
			sellers() { return this.$store.state.sellers; },
			leadStatus() { return this.$store.state.leadStatus.filter(item => item.follow==1); },
			teamsLeaders() { return this.$store.state.TeamsLeaders; },
		},
		mounted() {
			this.isDev = this.user.role === this.$v.ROLES.ADMIN && this.user.department === 'dev';
			//this.selectedConsultants = this.sellers.map(function(item) { return item.id });
			this.showConsultants = this.sellers.filter(item => item.seller_receiving_leads==1);
		},
		methods: {
			showPreviewCSV(obj){
				let f = obj.file;
				this.csvFile = obj;
				let csv; let c = this;
				const reader = new FileReader()
				reader.onload = async function(e) {
					csv = e.target.result;
					try {
						c.previewTableData = await c.$csvToJson().fromString(csv);
						if(!c.previewTableData) return
						c.columns=Object.keys(c.previewTableData[0]);
						c.modalPreviewCSV = true;
					} catch (e){
						console.log(e);
					}
				}
				reader.readAsText(f)
			},
			uploadMassiveFile(obj){
				let file = obj.file
				let url = this.$v.SERVER + this.$v.ADMIN_OPS.UPLOAD_CSV + "?filename=" + file.name + "&path=" + this.uploadSelected
				this.$showLoader(true)
				this.$axios.get(url).then(response => {
					this.$showLoader(false)
					if (response.status == 200){
						let dataUrl = response.data
						this.$axios.put(dataUrl, file, {'Content-Type': file.type}).then(putObjectResponse => {
							this.$showLoader(false)
							if (putObjectResponse.status === 200) {
								let typeCSV = '';
								switch (this.uploadSelected) {
									case 'incidenciasFA': typeCSV = this.$v.ADMIN_OPS.APPLY_INCIDENCIAS_CSV; break; 
									case 'negotiations': typeCSV = this.$v.ADMIN_OPS.APPLY_NEGOTIATIONS_CSV; break;
									default: typeCSV = this.$v.ADMIN_OPS.APPLY_COMMISSIONS_CSV; break;
								}
								let applyUrl = this.$v.SERVER + typeCSV
								this.$axios.post(applyUrl, {'filename': file.name}).then(response => {
									let data = response.data
									console.log("data");
									if (data[this.$v.API_SUCCESS]) {
										this.$vs.notification(this.$notification(
                'success',
                 'Carga exitosamente'
                     ));
										switch (this.uploadSelected) {
											case 'commissions': this.uploadData.commissions = data[this.$v.API_ROW]; break;
											case 'negotiations': this.uploadData.negotiations = data[this.$v.API_ROW]; break;
											case 'incidenciasFA': this.uploadData.incidenciasFA = data[this.$v.API_ROW]; break;
										}
										this.modalPreviewCSV = false;
										this.uploadSelected = null;
									} else {
										this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
									}
								})
							} else {
								this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
							}
						}).catch(err => {
							console.log(err)
							this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal, intenta nuevamente'
              ));
						})
					}
				})
			},
			sendMassivePushNotifications() {
				if(!this.massivePN.users || !this.massivePN.title || !this.massivePN.message){
					this.$vs.notification(this.$notification(
                'danger',
                 '¡Debe llenar todos los campos!'
                     ));
					return
				}
                this.$showLoader(true)
                let url = this.$v.SERVER + this.$v.SELLER_OPS.SEND_MASSIVE_PUSH_NOTIFICATION
                let request = {
                    users: JSON.parse("["+this.massivePN.users+"]"),
					title: this.massivePN.title,
					message: this.massivePN.message
				}
				console.log(request);
				this.$axios.post(url, request).then(response => {
                    this.$showLoader(false)
					let data = response.data
					console.log(data);
                    if (data[this.$v.API_SUCCESS]) {
                        this.$vs.notification(this.$notification(
                'success',
                 'Carga exitosamente'
                     ));
						this.uploadSelected=null
                    } else {
                        this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
                    }
                })
      },
			allNull(){
				this.uploadSelected = null;
				this.selectedLeaders = [];
				this.selectedConsultants = [];
				this.selectedLeaders = [];
				this.selectedTypesLead = [];
			},

			//Asignación de leads
			selectAssignment(){
				this.uploadSelected = 'leadsAssignment';
				if(this.consultants.all)
					this.showConsultants = this.sellers.filter(item => item.seller_receiving_leads==1);
			},
			setSelectedConsultants(id){
				let f = this.selectedConsultants.indexOf(id);
				if (f===-1) {
					this.selectedConsultants.push(id);
				}else this.selectedConsultants.splice(f,1)
			},
			setLeaders(idLeader){
				let f = this.selectedLeaders.indexOf(idLeader);
				if (f===-1) {
					this.selectedLeaders.push(idLeader);
				}else this.selectedLeaders.splice(f,1)
				this.setFilter('leader');
			},
			setTypeLeads(idType){
				let f = this.selectedTypesLead.indexOf(idType);
				if (f===-1) {
					this.selectedTypesLead.push(idType);
				}else this.selectedTypesLead.splice(f,1)
			},
			getConsultantsByFilter(){
				let idsConsultant=[];
				if (this.consultants.all){
					idsConsultant = this.sellers;
				}else if(this.selectedLeaders && this.consultants.team){
					idsConsultant = this.sellers.filter(item => 
						this.selectedLeaders.includes(item.leader)
					);
					if(!this.consultants.newConsultant)
						idsConsultant = idsConsultant.filter(item => item.seller_receiving_leads==1)
					else idsConsultant = idsConsultant.filter(item => item.seller_receiving_leads==0)
				}else if(this.consultants.newConsultant)
					idsConsultant = this.sellers.filter(item => item.seller_receiving_leads==0)
				return idsConsultant;
			},
			async setFilter(typeSelected){
				switch (typeSelected) {
					case 'all':
						this.consultants.all=true;
						this.consultants.newConsultant=false;
						this.consultants.team=false;
						break;
					case 'new':
						this.consultants.all=false;
						this.consultants.newConsultant = !this.consultants.newConsultant;
						break;
					case 'team':
						this.consultants.all=false;
						this.consultants.team = !this.consultants.team;
						break;
					case 'leader': break;
				}
				//this.selectedConsultants = this.getConsultantsByFilter().map(function(item) { return item.id });;
				this.selectedConsultants = [];
				this.showConsultants = this.getConsultantsByFilter();
			},
			async requestAssignment(){
				let req = {
					consultants: this.selectedConsultants,
					typeLeads: this.selectedTypesLead,
					startDate: this.dateSearchLeads,
					numberLeads: this.totalLeads
				}
				if(!req.consultants.length || !req.typeLeads.length || !req.startDate || !req.numberLeads) return
				let url = this.$v.SERVER + this.$v.ADMIN_OPS.ASSIGN_MASSIVE_LEADS;
				this.$showLoader(true)
				let response = await this.$axios.post(url, req)
				this.$showLoader(false)
        let data = response.data
        if (data[this.$v.API_SUCCESS]) {
					console.log(data);
					this.$vs.notification(this.$notification(
						'success',
						'Solicitud enviada',
						'Se ha enviado la solicitud de asignación de leads'
          ));
				}else this.$vs.notification(this.$notification('danger'));
			},
		}
	};
</script>
<style></style>