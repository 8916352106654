<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-6" />
    <div class="container-fluid" style="margin-top: 24px">
      <card>
        <div class="row">
          <div class="col-lg-2 text-left">
            <base-button
              tag="a"
              v-on:click="windowsBack()"
              class="mb-3 mb-sm-0 text-white"
              type="danger"
              icon="fas fa-arrow-left"
              >Regresar</base-button
            >
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
              <h1 v-if="edit">Editando Post con ID {{ $route.query.id }}</h1>
              <h1 v-if="!edit">Creando Nuevo Post</h1>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <dropbox-visor
              text="Inserta Imagen"
              :miniURL="miniURL"
              :files="miniature"
              v-on:setMiniature="setMiniature"
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8" style="margin-top: 2rem">
            <base-input
              alternative=""
              title="Título:"
              style="box-shadow: 1px 1px #3953d3"
              placeholder="Ingresa Título"
              input-classes="form-control-alternative"
              v-model="entry.title"
            />
          </div>
          <div class="col-lg-2">
            <flat-pickr
              id="date"
              v-model="entry.date"
              placeholder="Seleccione Fecha"
              class="btn btn-sm"
              type="primary"
              :config="config"
              style="margin-top: 2.5rem; width: 100%; box-shadow: 1px 1px #3953d3"
            />
          </div>
          <div class="col-lg-2">
            <flat-pickr
              id="hour"
              v-model="entry.hour"
              placeholder="Seleccione Hora"
              class="btn btn-sm"
              type="primary"
              :config="configHour"
              style="
                margin-top: 2.5rem;
                width: 100%;
                box-shadow: 1px 1px #3953d3;
              "
            />
          </div>
          <div class="col-lg-4" style="margin-top: 2rem">
            <base-input
              alternative=""
              style="box-shadow: 1px 1px #3953d3"
              placeholder="Duración (ej. 1 hora, 30 minutos)"
              input-classes="form-control-alternative"
              v-model="entry.duration"
            />
          </div>
          <div class="col-lg-8" style="margin-top: 1rem">
            <textarea
              style="box-shadow: 1px 1px #3953d3"
              placeholder="Ingresa Descripción"
              class="form-control form-control-alternative"
              rows="2"
              v-model="entry.description"
            />
          </div>
        </div>
        <div class="row justify-content-center" style="align-items: center">
          <div class="col-lg-10" style="margin-top: 2rem">
            <base-input
              alternative=""
              style="box-shadow: 1px 1px #3953d3"
              placeholder="Insertar Link"
              input-classes="form-control-alternative"
              v-model="entry.link"
            />
          </div>
        </div>
        <div class="row" style="align-items: center">
          <div class="col-10">
            <div class="URLshare">
              <h5>Enlace para compartir:</h5>
              <h2>{{ linkToShare }}</h2>
            </div>
          </div>
          <div
            class="col-2 text-right"
            style="margin-top: 24px; margin-bottom: 24px"
          >
            <base-button
              slot="title"
              type="primary"
              class="btn"
              v-on:click="setBlogEntry()"
            >
              Guardar
            </base-button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import DropboxVisor from "../components/DropboxVisor";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "tables",
  components: {
    DropboxVisor,
    flatPickr,
  },
  created() {
    let id = this.$route.query.id;
    if (id > 0) {
      this.getBlogEntry(id);
      this.edit = true;
    }
    this.getDay();
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    linkToShare() {
      return (
        "https://dbmenos.com/postWebinar/" +
        this.entry.title
          .normalize("NFD")
          .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
          .replace(/[¿?]/gi, "")
          .normalize()
          .split(" ")
          .join("-") +
        ".html"
      );
    },
  },
  data() {
    return {
      modal: false,
      edit: false,
      entry: {
        id: null,
        title: "",
        description: "",
        filename: null,
        link: "",
        date: null,
        duration: "",
        created: null,
      },
      today: "",
      config: {
        dateFormat: "Y-m-d",
      },
      configHour: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      miniature: [],
      og_picture: null,
      miniURL: null,

      pointer: "pointer",
      pointer2: "default",
    };
  },
  methods: {
    getDay() {
      let f = new Date();
      let month = f.getMonth() + 1;
      if (month < 10) month = "0" + month;
      let today = new Date(f.getFullYear(), f.getMonth() - 1, f.getDay());
      this.today = this.$moment(today).format("YYYY-MM-DD");
      //this.today = f.getFullYear() + "-" + month + "-" + f.getDate();
    },
    async getBlogEntry(id) {
      let url =
        this.$v.SERVER + this.$v.ADMIN_OPS.GET_BLOG_WEBINAR + "?id=" + id;
      this.$showLoader(true);
      let response = await this.$axios.get(url);
      this.$showLoader(false);
      let data = response.data;
      if (data[this.$v.API_SUCCESS]) {
        let BlogEntry = data[this.$v.API_ROWS];
        if (BlogEntry) {
          for (let i = 0; i < BlogEntry.length; i++) {
            this.entry = BlogEntry[i];
          }
          if (this.entry.og_picture)
            this.miniURL =
              "https://www.dbmenos.com/img/postWebinar/" + this.entry.og_picture;
          else this.miniURL = null;
          this.$forceUpdate();
        }
      } else {
        this.$router.push("/blogEntries");
      }
    },
    async setBlogEntry() {
      let url = this.$v.SERVER + this.$v.MARKETING_OPS.SET_BLOG_WEBINAR_ENTRY;
      this.$showLoader(true);
      let response = await this.$axios.post(url, this.entry);
      this.$showLoader(false);
      let data = response.data;
      if (data[this.$v.API_SUCCESS]) {
        let signedUrl = data[this.$v.API_ROW]["uploadUrl"];
        await this.$axios.put(signedUrl, this.og_picture, {
          headers: {
            "Content-Type": this.og_picture ? this.og_picture.type : null,
          },
        });
this.$vs.notification(this.$notification(
                'success',
                 'Guardado exitosamente'
                     ));
        
        this.$router.push("/blogEntries");
      } else {
        this.$vs.notification(this.$notification(
                'danger',
                 'Algó salio mal',
                 'Intenta nuevamente'
                     ));
      }
    },
    setMiniature(obj) {
      let file = obj.file;
      this.entry.filename = file.name;
      this.og_picture = file;
    },
    windowsBack() {
      this.$router.push("/blogEntries");
    },
  },
};
</script>
<style>
.URLshare {
  align-items: center;
}
</style>
