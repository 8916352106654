<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col-3" v-bind:style="{ cursor: pointer2 }">
          <base-input
            placeholder="Buscar"
            class="input-group-alternative"
            alternative
            v-model="searchQuery"
          ></base-input>
        </div>
        <div class="col-1 text-right" v-bind:style="{ cursor: pointer2 }"  style="margin-top:-1.5rem;">
          <base-button
            slot="title"
            type="info"
            style="width: 100%"
            size="sm"
            @click="searchData(searchQuery)"
          >
            <i class="fas fa-search"></i></base-button>
        </div>
        <div class="col-2 text-right" v-bind:style="{ cursor: pointer2 }"  style="margin-top:-1.5rem;">
          <base-button
            slot="title"
            type="primary"
            style="width: 100%"
            size="sm"
            @click="createCommissions()"
          >
            <i class="fas fa-cash-register"></i> Cobrar Comisiones
          </base-button>
        </div>
        <div class="col-2 text-right" v-bind:style="{ cursor: pointer2 }"  style="margin-top:-1.5rem;">
          <base-button
            slot="title"
            type="warning"
            style="width: 100%"
            size="sm"
            @click="updloadSheetsFacture()"
          >
            <i class="fas fa-file-csv"></i> CSV Facturama
          </base-button>
        </div>
        <div class="col-2 text-right" v-bind:style="{ cursor: pointer2 }"  style="margin-top:-1.5rem;">
          <base-button
            slot="title"
            type="success"
            style="width: 100%"
            size="sm"
            @click="showReport()"
          >
            <i class="fas fa-file-excel"></i> Ver Reporte
          </base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive text-center">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
      >
        <template slot="columns">
          <th v-bind:style="{ cursor: pointer }" class="text-center">
            ID Cliente
          </th>
          <th v-bind:style="{ cursor: pointer }">Cliente</th>
          <th v-bind:style="{ cursor: pointer }">MDI</th>
          <th v-bind:style="{ cursor: pointer }">Mensualidad</th>
          <th v-bind:style="{ cursor: pointer }">Estatus Cliente</th>
          <th v-bind:style="{ cursor: pointer }" class="text-center">
            % Comisión
          </th>
          <th v-bind:style="{ cursor: pointer }" class="text-center">
            % Comisión Efectiva
          </th>
          <th v-bind:style="{ cursor: pointer }">Saldo</th>
          <th v-bind:style="{ cursor: pointer }">Cuenta Finamigo</th>
          <th v-bind:style="{ cursor: pointer }">Monto de comisión</th>
          <th v-bind:style="{ cursor: pointer }">Cobrar</th>
        </template>

        <template slot-scope="{ row }">
          <th
            class="text-center"
            scope="row"
            v-bind:style="{ cursor: pointer2 }"
          >
            <div class="media align-items-center">
              <div class="media-body">
                <a
                  :href="'https://admin.dbmenos.com/client?id=' + row.id"
                  target="_blank"
                >
                  <i class="fas fa-user-circle text-primary"> </i
                  ><span class="name mb-0 text-sm"> {{ row.id }}</span>
                </a>
              </div>
            </div>
          </th>
          <td v-bind:style="{ cursor: pointer2 }">{{ row.name }}</td>
          <td v-bind:style="{ cursor: pointer2 }">
            {{ $formatCurrency(row.mdi) }}
          </td>
          <td v-bind:style="{ cursor: pointer2 }" class="text-center">
            {{ $formatCurrency(row.monthly_savings) }}
          </td>
          <td v-bind:style="{ cursor: pointer2 }" class="text-center">
            {{ row.inactive_reason ? row.inactive_reason : "Sin Estatus" }}
          </td>
          <td v-bind:style="{ cursor: pointer2 }" class="text-center">
            {{ row.commission_percentage }} %
          </td>
          <td v-bind:style="{ cursor: pointer2 }" class="text-center">
            {{ row.effective_commission }} %
          </td>
          <td v-bind:style="{ cursor: pointer2 }" class="text-center">
            {{ $formatCurrency(row.difference) }}
          </td>
          <td v-bind:style="{ cursor: pointer2 }" class="text-center">
            {{ row.bank_account }}
          </td>
          <td v-bind:style="{ cursor: pointer2 }" class="text-center">
            $
            <input
              class="text-center"
              size="10"
              v-model="row.commissionAmount"
            />
          </td>
          <td v-bind:style="{ cursor: pointer2 }" class="text-center">
            <base-button
              block
              type="success"
              size="sm"
              v-on:click="createCommission(row)"
              ><i class="fas fa-coins"></i
            ></base-button>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="col">
        <p class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          Página {{ pagination.currentPage }} de
          {{ Math.ceil(pagination.total / pagination.perPage) }}<br />{{
            pagination.total
          }}
          clientes
        </p>
      </div>
      <base-pagination
        size="sm"
        :total="pagination.total"
        :per-page="pagination.perPage"
        :value="pagination.currentPage"
        v-on:pageSelected="pageSelected"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "massive-automatic-commissions-table",
  props: {
    type: {
      type: String,
    },
    title: String,
    amount: Number,
    data: Array,
    pagination: Object,
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    banks() {
      return this.$store.state.banks;
    },
    sellers() {
      return this.$store.state.sellers;
    },
    customerService() {
      return this.$store.state.customerService;
    },
  },
  data() {
    return {
      isAdmin: false,
      currentColumnKey: null,
      sortedColumn: 0,
      freezeData: null,
      sortingDesc: true,
      searchQuery: "",
      pointer: "pointer",
      pointer2: "default",
    };
  },
  updated() {
    if (!this.freezeData && this.data) {
      this.freezeData = JSON.parse(JSON.stringify(this.data));
    }
  },
  async created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
  },
  methods: {
    requestFind(e) {
      e.preventDefault();
      this.$root.$emit("searchQueryCommisionesC", this.searchQuery);
    },
    showReport() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.UPDATE_COMMISSIONS;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
          window.open(
          "https://docs.google.com/spreadsheets/d/1XPYChRQzqvko9-SkRNoLFZ_cs5G7U6G2wF24MuNQ3ho/edit?usp=sharing"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    updloadSheetsFacture() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.UPDATE_FACTURE;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
         this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
          window.open(
            "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
            "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    createCommissions() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.CREATE_COMMISSIONS;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Comisiones creadas exitosamente'
                     ));
          window.open(
            "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
            "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    searchData(s){
      this.$emit("searchData", s);
    },
    pageSelected(page) {
      this.$emit("pageSelected", page);
    },
    getCustomerFilterText() {
      if (this.consultantFilter)
        return this.getConsultantName(this.consultantFilter);
      return "Consultores";
    },
    getConsultantFilterText() {
      if (this.selfClients) return "Solo mis clientes";
      return "Clientes sin asignar";
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id) return this.sellers[i].name;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return this.customerService[i].name;
      return "- - -";
    },
    getBank(id) {
      return this.banks.find((b) => b.id === id);
    },
    createCommission(row) {
      this.$showLoader(true);
      if (!row.commissionAmount) {
        row.commissionAmount = (row.effective_commission / 100) * row.mdi * 1.16;
      }
      let url = this.$v.SERVER + this.$v.SELLER_OPS.CREATE_COMMISSION;
      let request = {
        user: row.id,
        date: new Date(),
        amount: row.commissionAmount,
      };
      if (request.user && request.date && request.amount > 0) {
        this.$axios.post(url, request).then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
            window.open(
            "https://docs.google.com/spreadsheets/d/1XPYChRQzqvko9-SkRNoLFZ_cs5G7U6G2wF24MuNQ3ho/edit?usp=sharing"
          );
          } else {
            this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
          }
        });
      } else {
        this.$showLoader(false);
        this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Llenar datos faltantes'
                     ));
      }
    },
  },
};
</script>
<style>
</style>
