<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right" v-if="isDevs">
          <base-button type="primary" size="sm" v-on:click="newLeadTrace"
            >Nuevo</base-button
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush custom-text-wrap"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
      >
        <template slot="columns">
          <th>ID</th>
          <th>Status</th>
          <th>Descripción</th>
          <th>Creado por</th>
          <th>Creación</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.id }}</span>
              </div>
            </div>
          </th>
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{getStatusDesc(row.event)}}</span>
              </div>
            </div>
          </th>
          <td>{{ row.description }}</td>
          <td>{{ getConsultantName(row.created_by) }}</td>
          <td>{{ formatDate(row.created) }}</td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "dev-module-history-table",
  props: {
    type: {
      type: String,
    },
    title: String,
    data: Array,
    pagination: Object,
   
  },

  data() {
    return {
      tableData: [],
       isDevs:false
    };
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    banks() {
      return this.$store.state.banks;
    },
    admins() {
      return this.$store.state.admins;
    },
    managerService() {
      return this.$store.state.managerService;
    },
    devEventsHistory(){
      return this.$store.state.devEventsHistory;
    }
  },
  async created() {
    this.isDevs = this.user.department === this.$v.DEPARTAMENT.DEV;
    console.log(this.user)
  },
  methods: {
    getCustomerFilterText() {
      if (this.consultantFilter)
        return this.getConsultantName(this.consultantFilter);
      return "Consultores";
    },
    getConsultantName(id) {
      for (let i = 0; i < this.admins.length; i++)
        if (this.admins[i].id === id) return this.admins[i].name;
      for (let i = 0; i < this.managerService.length; i++)
        if (this.managerService[i].id === id)
          return this.managerService[i].name;
      return "- - -";
    },
    formatDate(date) {
      if (date <= "2020-11-10") {
        return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
      } else {
        return this.$moment(date).format("DD MMM YYYY, h:mm a");
      }
    },
    newLeadTrace() {
      this.$emit("newLeadTrace");
    },
    getStatusDesc(statusId) {
        for (let i=0 ; i<this.devEventsHistory.length ; i++){
          if (this.devEventsHistory[i].id === statusId)
            return this.devEventsHistory[i].status
          }
      },
  },
};
</script>
<style>
</style>