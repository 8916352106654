<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
            </div>
        </div>

        <div class="table-responsive text-center" v-bind:style="{cursor: pointer}">
            <base-table class="table align-items-center table-flush text-center"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        v-on:click="accountSelected">
                <template slot="columns">
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Banco</th>
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Número de cuenta</th>
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Tipo de producto</th>
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Total adeudado</th>
                    <!--th v-if="false" class="text-center">Acciones</th-->
                </template>

                <template slot-scope="{row}" class="text-center">
                    <th scope="row">
                        <div class="media align-items-center">
                            <a class="avatar avatar-sm rounded-circle" style="background-color: #FFFFFF" data-toggle="tooltip" :data-original-title="row.bank.name">
                                <img :alt="row.bank.name" :src="row.bank.url">
                            </a>
                        </div>
                    </th>
                    <td class="text-center">{{row.account}}</td>
                    <td class="text-center">{{row.product_type ? getProductType(row.product_type.id):'Cuenta'}}</td>
                    <td class="text-center">{{$formatCurrency(row.amount)}}</td>
                </template>

            </base-table>

        </div>

        <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'clients-table',
        props: {
            type: {
                type: String
            },
            addendumType: Number,
            addendumAccFormat: Boolean,
            title: String,
            data: Array,
            pagination: Object
        },
        data() {
            return {
                isSeller: false,
                isManagerSeller:false,
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default'
            }
        },
        computed: {
            banks() { return this.$store.state.banks },
            productType() { return this.$store.state.productType },
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) }
        },
        async created() {
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isService = this.user.role === this.$v.ROLES.SERVICE
            this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
        },
        methods: {
             getProductType(id) {
                for (let i=0 ; i<this.productType.length ; i++) {
                    if (this.productType[i].id === id) {
                        return this.productType[i].type
                    }
                }
            },
            showDetails(acc) {
                this.$emit("showDetails", acc);
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            getBanks(array) {
                let banks = []
                for (let a=0 ; a<=array.length ; a++) {
                    for (let b = 0; b < this.banks.length; b++) {
                        if (this.banks[b].id === array[a]) {
                            banks.push(this.banks[b])
                            break
                        }
                    }
                }
                return banks
            },
            formatDate(date) {
                return this.$moment(date).format("DD MMM YYYY");
            },
            onNewItem() {
                this.$emit("onNewItem")
            },
            requestSignatureNomina(){
                this.$emit("requestSignatureNomina")
            },
            onSave() {
                this.$emit("onSave")
            },
            deleteFile(accId){
                this.$emit("newModification", accId)
            },
            getDateText(acc) {
                return acc ? this.$formatDate(acc) : 'No asignado'
            },
            accountSelected(acc) {
               this.$emit("accountSelected", acc)
            },
            deleteAccount(acc) {
               this.$emit("deleteAccount", acc)
            },
            getIconSettled(settled) {
                if (settled) return "fas fa-medal text-success"
                return "fas fa-piggy-bank text-info"
            }
        }
    }
</script>
<style>
</style>