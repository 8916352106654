<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col" v-bind:style="{cursor:pointer2}">
          <auditor-view-table
              title="Auditoria Pagos Nuevos Clientes"
              :data="tableData3"
              :pagination="pagination3"
              :period="period"
              @setPeriodFilter="setPeriodFilter(3)"
              @setMethodPaymentFilter="setMethodPaymentFilter(3)"
              @setValidatedPaymentFilter="setValidatedPaymentFilter(3)"
              @paymentSelected="paymentSelected"
              @pageSelected="getData"/><br>
          <auditor-view-table
              title="Auditoria Validación Pagos"
              :data="tableData"
              :pagination="pagination"
              :period="period"
              @setPeriodFilter="setPeriodFilter"
              @setMethodPaymentFilter="setMethodPaymentFilter"
              @setValidatedPaymentFilter="setValidatedPaymentFilter"
              @paymentSelected="paymentSelected"
              @pageSelected="getData"/><br>
          <auditor-view-table
              title="Auditoria Pagos Validados FA"
              :data="tableData2"
              :pagination="pagination2"
              :period="period"
              @setPeriodFilter="setPeriodFilter(2)"
              @setMethodPaymentFilter="setMethodPaymentFilter(2)"
              @setValidatedPaymentFilter="setValidatedPaymentFilter(2)"
              @paymentSelected="paymentSelected"
              @pageSelected="getData"/>
        </div>
      </div>
    </div>

    <modal :show.sync="paymentModal">
      <template slot="header">
        <h1 class="modal-title" id="paymentModal"> {{ selectedPayment.name }} </h1>
      </template>
      <div class="row">
        <div class="card-profile-stats d-flex justify-content-around mb-4" style="width: 100%">
          <div class="p-2">
            <span class="heading">{{ selectedPayment.user }}</span>
            <span class="description">Usuario</span>
          </div>
          <div class="p-2">
            <span class="heading">{{ selectedPayment.id }}</span>
            <span class="description">Identificador</span>
          </div>
          <div class="p-2">
            <span class="heading">{{ selectedPayment.method_payment==2? 'SPEI' : 'Depósito'}}</span>
            <span class="description">Método de pago</span>
          </div>
          <div class="p-2">
            <label class="form-control-label">Creada el</label>
            <flat-pickr :config="config" class="form-control form-control-alternative"
                        data-vv-as="Fecha" name="date" style="background-color: #ffffff"
                        v-model="selectedPayment.created"/>
          </div>
          <div class="p-2">
            <base-input alternative input-classes="form-control-alternative"
                        label="Monto depositado"
                        v-model="selectedPayment.amount"/>
          </div>
          <div class="p-2">
            <base-input alternative input-classes="form-control-alternative"
                        label="Referencia FA"
                        v-model="selectedPayment.reference_fa"/>
          </div>
          <div  v-if="user.view_only === 0" class="p-2">
            <base-button slot="title" style="width: 100%; margin-top: 4px" :type="selectedPayment.validated ? 'primary' : 'danger'" class="btn" v-on:click="updatePayment()">
              {{selectedPayment.validated ? "Depósito validado" : "Depósito no validado"}}
            </base-button>
            <span class="description">
              {{selectedPayment.validated_on ? $formatDate(selectedPayment.validated_on) : "No validado"}}
            </span>
          </div>
        </div>
      </div>
      <div class="row" v-if="isAuditor || userValid">
        <div class="col-lg-12">
          <base-input alternative input-classes="form-control-alternative"
            label="Comentarios DBM"
            v-model="selectedPayment.dbm_comments"/>
        </div>
      </div>
      <div class="row">
        <div class="card-profile-stats d-flex justify-content-around mb-4" style="width: 100%">
          <div class="p-2">
            <span class="heading">{{ selectedPayment.bbva }}</span>
            <span class="description">BBVA</span>
          </div>
          <div class="p-2">
            <span class="heading">{{ selectedPayment.clabe }}</span>
            <span class="description">CLABE</span>
          </div>
          <div class="p-2">
            <span class="heading">{{ $formatCurrency(selectedPayment.finamigoBalance)}}</span>
            <span class="description">Saldo Finamigo</span>
          </div>
          <div class="p-2">
            <span class="heading">{{ $formatCurrency(selectedPayment.dbm_balance) }}</span>
            <span class="description">Saldo DBM</span>
          </div>
        </div>
      </div>
      <div class="row" v-if="duplicatePayment">
        <div class="card-profile-stats d-flex justify-content-around mb-4" style="width: 100%">
          <div class="alert alert-danger d-flex align-items-center" role="alert">
            <div>
              Se encontró un pago duplicado validado el {{$formatDate(duplicatePayment.validated_on)}} con ID {{ duplicatePayment.id }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card-profile-stats d-flex justify-content-around mb-4" style="width: 100%">
          <account-summary-table style="margin-top: -30px"
             title="Estado de cuenta FA"
             @itemSelected="summarySelected"
             @getFAStatementAccount="getFAStatementAccount"
             :data="FAStatementAccountData"/>
        </div>
      </div>
      <div v-if="!pdfPayment" class="col-lg-11 text-center" style="margin:1rem;">
        <img :src="selectedPayment.fileurl? selectedPayment.fileurl : getPaymentFile(selectedPayment.id)" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/><br>
        <a v-if="selectedPayment.fileurl" :href="selectedPayment.fileurl" target="_blank">
          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
        </a>
      </div>
      <div v-else class="col-lg-11 text-center" style="margin:2rem;">
        <base-p-d-f-visor :fileurl="selectedPayment.fileurl? selectedPayment.fileurl : getPaymentFile(selectedPayment.id)" :key="forceUpdate"/>
      </div>
      <template slot="footer">
        <base-button class="btn-sm" type="danger" v-on:click="paymentModal = false">
          Cancelar
        </base-button>
        <base-button class="btn-sm" type="primary" v-on:click="updatePayment(true)">
          Guardar cambios
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import AuditorViewTable from './Tables/AuditorViewTable';
import AccountSummaryTable from "./Tables/AccountSummaryTable";
import BasePDFVisor from "../components/BasePDFVisor";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "AuditorView",
  components: {
    BasePDFVisor,
    AuditorViewTable,
    AccountSummaryTable,
    flatPickr
  },
  mounted() {
    this.pdfPayment = false;
    this.getData(1)
    this.getData(1, 2)
    this.getData(1, 3)
    this.$root.$on('searchQuery', query => {
      if (query.length > 0)
        this.searchQuery = query
      else
        this.searchQuery = null
      this.getData(1)
      this.getData(1, 2)
      this.getData(1, 3)
    })
  },
  computed: {
    user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
    auditorCatalog() { return this.$store.state.auditorCatalog },
  },
  data() {
    return {
      config: {
        maxDate: "today",
        dateFormat: "Y-m-d ",
      },
      isAuditor: false,
      pdfPayment:false,
      searchQuery: null,
      tableData: [],
      pagination: {
        total: 0,
        perPage: 1000,
        currentPage: 1
      },
      tableData2: [],
      pagination2: {
        total: 0,
        perPage: 1000,
        currentPage: 1
      },
      tableData3: [],
      pagination3: {
        total: 0,
        perPage: 1000,
        currentPage: 1
      },
      periodFilter: null,
      methodPaymentFilter: null,
      validatedPaymentFilter: null,
      period: {
        start: null,
        end: null
      },
      paymentModal: false,
      selectedPayment: {},
      pointer: 'pointer',
      pointer2: 'default',
      FAStatementAccountData: [],
      duplicatePayment: null,
      //PDFViewer
      forceUpdate: 0,
      userValid: false,
      userEdwin: 31,
    }
  },
  created() {
    this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
    this.userValid = this.user.id === userEdwin;
  },
  methods: {
    getData(page, table=1) {
      let limit = this.pagination.perPage
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_AUDIT_VIEW
      if (this.searchQuery) url += "?find=" + this.searchQuery
      let request ={
        offset: limit*(page - 1),
        limit: limit,
      }
      if (table===1){
        request.deposit_not_located = 0;
        request.concept_voucher_error = 0;
        request.new_user = 0;
      }
      if (table===3){
        request.deposit_not_located = 0;
        request.concept_voucher_error = 0;
        request.new_user = 1;
      }
      if (table===2) request.or = [{deposit_not_located: 1}, {concept_voucher_error: 1}]
      if (this.periodFilter === 1) {
        request.created = {gte: this.period.start}
      }else if (this.periodFilter === 2){
        request.created = {
          gt: this.period.start,
          lte: this.period.end
        }
      }
      if (this.methodPaymentFilter) request.method_payment = this.methodPaymentFilter;
      if (this.validatedPaymentFilter!==null) request.validated = this.validatedPaymentFilter;
      this.$showLoader(true)
      this.$axios.post(url,request).then(response => {
        this.$showLoader(false)
        let data = response.data
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW]
          if (table===1) {
            this.tableData = data[this.$v.API_ROWS]
            this.pagination.total = p['totalItems']
            this.pagination.currentPage = page
            this.pagination.perPage = limit
          }else if (table===2){
            this.tableData2 = data[this.$v.API_ROWS]
            this.pagination2.total = p['totalItems']
            this.pagination2.currentPage = page
            this.pagination2.perPage = limit
          }else if (table===3){
            this.tableData3 = data[this.$v.API_ROWS]
            this.pagination3.total = p['totalItems']
            this.pagination3.currentPage = page
            this.pagination3.perPage = limit
          }
        } else { this.$validateSession(data) }
      })
    },
    updatePayment(setPayment) {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_PAYMENT;
      let request;
      if (setPayment) {
        request = {
          id: this.selectedPayment.id,
          user: this.selectedPayment.user,
          amount: this.selectedPayment.amount,
          reference_fa: this.selectedPayment.reference_fa,
          dbm_comments: this.selectedPayment.dbm_comments,
          date: this.selectedPayment.created,
        };
      } else {
        let numSec = this.verifyPaymentInFASummary(this.FAStatementAccountData, this.selectedPayment);
        numSec = numSec? numSec : this.selectedPayment.reference_fa;
        if (!numSec){
           this.$vs.notification(this.$notification(
                'warning',
                 'Ingrese referencia'
                     ));
          this.$showLoader(false);
          return
        }
        request = {
          user: this.selectedPayment.user,
          id: this.selectedPayment.id,
          validated: !this.selectedPayment.validated,
          reference_fa: numSec,
          dbm_comments: this.selectedPayment.dbm_comments,
        };
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          if (data.reason === 5) {
             this.$vs.notification(this.$notification(
                'warning',
                 'Pago duplicado'
                     ));
            this.duplicatePayment = data.row
          } else {
             this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
            if(!setPayment){
            this.selectedPayment.validated = !this.selectedPayment.validated;
            }
            this.$forceUpdate();
          }
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algó salio mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    verifyPaymentInFASummary(FASummary, payment){
      if (FASummary) {
        for (let j = 0; j < FASummary.length; j++){
          FASummary[j].Monto = (FASummary[j].Monto).replace(/,/g, "");
          let dateSplice=(FASummary[j].Fecha).split('.');
          let dateSplice2=(dateSplice[0]).split(' ');
          let dateSplice3=(dateSplice2[0]).split('/');
          let dateFA=dateSplice3[2]+'-'+dateSplice3[1]+'-'+dateSplice3[0]

          let desc = FASummary[j].Decripcion;
          let f = 'NroSecuencial:'
          let index = desc.indexOf(f)+f.length;
          let NroSecuencial = desc.substr(index,desc.length)

          if (FASummary[j].Tipotrans==='Credito'
              && FASummary[j].Monto == payment.amount && dateFA === payment.created) {
            return NroSecuencial;
          }
        }
      } return null;
    },
    async getFAStatementAccount(startDate, endDate){
      if(!startDate || !endDate){
        startDate = this.$moment().subtract(1, 'month').format('YYYY-MM-DD');
        endDate = this.$moment().format('YYYY-MM-DD')
      }
      let request = {
        user: this.selectedPayment.user,
        startDate: startDate,
        endDate: endDate
      }
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_FINAMIGO_STATEMENT_ACCOUNT;
      this.$showLoader(true);
      await this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.FAStatementAccountData = data[this.$v.API_ROWS].reverse() ;
          this.$forceUpdate();
        }else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algó salio mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    getPaymentFile(id) {
      if (id) {
        let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_PAYMENT_FILE + "?id=" + id;
        this.$axios.get(url).then((response) => {
          this.selectedPayment.fileurl = response.data;
          this.$forceUpdate();
        });
      }
    },
    getFinamigoBalance(user) {
      let url =
        this.$v.SERVER +
        this.$v.OPS.GET_FINAMIGO_BALANCE +
        "?user=" + user;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.selectedPayment.finamigoBalance = data.row.balance;
          this.$forceUpdate();
        }
      });
      this.showFinBalance = true;
    },
    paymentSelected(payment) {
      this.duplicatePayment = null;
      this.pdfPayment=false;
      if(payment.filename){
        let typePayment=[];
        typePayment=payment.filename.split(".pdf");
        if(typePayment.length>1){
          this.pdfPayment = true;
          }
      }
      this.forceUpdate++;
      this.getFinamigoBalance(payment.user)
      this.selectedPayment = payment;
      
      this.selectedPayment.created = this.$moment(this.selectedPayment.created).format('YYYY-MM-DD')
      this.paymentModal = true;
      this.getFAStatementAccount();
    },
    summarySelected(row){
      let desc = row.Decripcion;
      let f = 'NroSecuencial:';
      if (desc.indexOf(f) !== -1) {
        let index = desc.indexOf(f) + f.length;
        let NroSecuencial = desc.substr(index, desc.length);
        if (NroSecuencial) this.selectedPayment.reference_fa = NroSecuencial;
      }
    },
    setPeriodFilter(value, table=1) {
      if(value === 1){
        this.period.start = this.$moment().startOf("month").format('YYYY-MM-DD')
      }
      this.periodFilter = value
      this.getData(1, table)
    },
    setMethodPaymentFilter(value, table=1){
      this.methodPaymentFilter = value;
      this.getData(1, table)
    },
    setValidatedPaymentFilter(value, table=1){
      this.validatedPaymentFilter = value;
      this.getData(1, table)
    },
  }
}
</script>

<style scoped>

</style>