<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>

		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col-12" v-bind:style="{cursor:pointer2}" style="margin-top: 10px;">
					<new-clients-manager-table title="Nuevos usuarios" v-if="!table2"
							:data="tableData"
							:pagination="pagination"
                            :period="period"
							v-on:pageSelected="getNewUsers"
							v-on:setValidationFilter="setValidationFilter"
                            v-on:setPeriodFilter="setPeriodFilter"
							v-on:setConsultantFilter="setConsultantFilter"
                            v-on:setSellerFilter="setSellerFilter"
							v-on:setCompletePaymentFilter="setCompletePaymentFilter"/>
				</div>
			</div>
			<br>
			<graphics-new-users v-if="(isAdmin || isManagerSeller || isManagerService) && graphic"></graphics-new-users>
		</div>
	</div>
  
</template>
<script>
	import NewClientsManagerTable from './Tables/NewClientsManagerTable';
	import GraphicsNewUsers from './Graphics/GraphicNewClients'
	export default {
		name: 'tables',
		components: {
			NewClientsManagerTable,
			GraphicsNewUsers
		},
		computed: {
			user() {return this.$getLS(this.$v.STORE_KEYS.USER);},
		},
		created() {
			this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
			this.isSeller = this.user.role === this.$v.ROLES.SELLER;
			this.isService = this.user.role === this.$v.ROLES.SERVICE || this.user.role === this.$v.ROLES.AUDITOR;
			this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
			this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
			this.setPeriodFilter(1,true)
		},
		mounted() {
			this.$root.$on('searchQuery', query => {
				if (query.length > 0)
					this.searchQuery = query
				else
					this.searchQuery = null
				this.getNewUsers(1,true)
			})
		},
		data() {
			return {
                lastRequest: null,
				searchQuery: null,
				tableData: [],
				tableDataIP: [],
				pagination: {
					total: 0,
					perPage: 1000,
					currentPage: 1
				},
				paginationIP: {
					total: 0,
					perPage: 1000,
					currentPage: 1
				},
				periodFilter: null,
				completePaymentFilter: null,
				validationFilter: null,
				consultantFilter: null,
                sellerFilter: null,
				period: {
					start: null,
					end: null
				},
				pointer: 'pointer',
				pointer2: 'default',
				isAdmin: false,
				isManagerSeller: false,
				isManagerService: false,
				isSeller: false,
				isService: false,
				graphic: false,
				table2: false
			};
		},
		methods: {
			getNewUsers(page, table) {
				let limit = table? this.pagination.perPage:this.paginationIP.perPage
				let url = this.$v.SERVER + this.$v.MANAGER_OPS.GET_VIEW_NEW_CLIENTS_MANAGER // + "?offset=" + limit*(page - 1) + "&limit=" + limit
				if (this.searchQuery) url += "?find=" + this.searchQuery
				let request ={
					offset: limit*(page - 1),
					limit: limit
				}
                console.log(this.periodFilter)
				if (this.periodFilter === 1) {
					let firstPaymentDate = table?{gte: this.period.start}:{lte: this.period.start}
					Object.assign(request, {firstPaymentDate})
				}else if (this.periodFilter === 2){
                    let start = this.$moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD');
                    let end = this.$moment().startOf('month').format('YYYY-MM-DD');
					let firstPaymentDate = {
						gte: start,
						lt: end
					}
					Object.assign(request, {firstPaymentDate})
				}
				if (this.validationFilter === 1) Object.assign(request, {notValidatedPaymentCount: 0})
				if (this.validationFilter === 0) Object.assign(request, {notValidatedPaymentCount: 'notEmpty'})
				if (this.consultantFilter) Object.assign(request, {consultant_id: this.consultantFilter})
                if( this.sellerFilter) Object.assign(request, {seller_id: this.sellerFilter})

                const lr = request
                if (lr === this.lastRequest) return
          this.lastRequest = lr;
				this.$showLoader(true)
				this.$axios.post(url,request).then(response => {
					this.$showLoader(false)
					let data = response.data
					if (data[this.$v.API_SUCCESS]) {
						let p = data[this.$v.API_ROW]
						if(table){
							this.tableData = data[this.$v.API_ROWS]
							this.pagination.total = p['totalItems']
							this.pagination.currentPage = page
							this.pagination.perPage = limit
						}
					} else { this.$validateSession(data) }
				})
			},
			setValidationFilter(value,table) {
				this.validationFilter = value
				//console.log(this.validationFilter);
				this.getNewUsers(1,table)
			},
			setCompletePaymentFilter(value,table) {
				this.completePaymentFilter = value
				//console.log(this.completePaymentFilter);
				this.getNewUsers(1,table)
			},
			setConsultantFilter(value,table) {
				this.consultantFilter = value
				this.getNewUsers(1,table)
			},
            setSellerFilter(value,table) {
				this.sellerFilter = value
				this.getNewUsers(1,table)
			},
      setPeriodFilter(value,table) {
				if(value === 1){
					let date = new Date()
					let month = date.getMonth()+1
					if(month<10) month= "0"+month
					this.period.start = date.getFullYear()+"-"+month+"-"+"01"
				}
				this.periodFilter = value
				this.getNewUsers(1,table)
			},
			showIncompletes(){
				this.table2=!this.table2
				if(this.table2) this.getNewUsers(1,false)
			}
		}
	};
</script>
<style></style>