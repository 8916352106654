<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''"
       v-bind:style="{cursor:pointer2}">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data">
        <template slot="columns">
          <th>Usuario</th>
          <th>ID Deuda</th>
          <th>Tipo de Actividad</th>
          <th>Saldo Actual</th>
          <th>Pago banco</th>
          <th>Fecha Limite de Pago</th>
          <th>Firma</th>
          <th>Teléfono</th>
          <th>Comentarios</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.user}}</span>
              </div>
            </div>
          </th>
          <td>{{row.id_debt}}</td>
          <td>{{row.type_activity}}</td>
          <td>{{$formatCurrency(row.amount)}}</td>
          <td>{{$formatCurrency(row.amount_pay_bank)}}</td>
          <td>{{$formatNormalDate($moment(row.limit_pay_date,'DD-MM-YYYY'))}}</td>
          <td>{{row.firm}}</td>
          <td>{{row.phone}}</td>
          <td :title="row.comments">{{getString(row.comments)}}</td>
        </template>

      </base-table>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'upload-negotiation-table',
    props: {
      type: {
        type: String
      },
      title: String,
      data: Array,
    },
    data() {
      return {
        pointer: 'pointer',
        pointer2: 'default',
      }
    },
    computed: {
      managerService() { return this.$store.state.managerService},
      user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      banks() { return this.$store.state.banks },
      sellers() { return this.$store.state.sellers },
      customerService() { return this.$store.state.customerService },
      debtActivityCatalog() { return this.$store.state.debtActivityCatalog }
    },
    created() {
      this.isSeller = this.user.role === this.$v.ROLES.SELLER;
      this.isService = this.user.role === this.$v.ROLES.SERVICE;
      this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
      this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    },
    methods: {
      pageSelected(page) {
          this.$emit("pageSelected", page);
      },
      getString(s){
        if(!s) return '-'
        let comment = s.slice(0, 30)
        if (s.length>30) comment+='...';
        return comment
      },
    }
  }
</script>
<style>
</style>