<template>
  <div style="width:auto;" class="card-header text-center align-items-center" :class="type === 'dark' ? 'bg-transparent': ''">
    <div class="row" style="margin-left:-2rem">
      <div class="col-6 text-center table-responsive">
        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
          <base-table class="round_table table table-sm align-items-center"
              :class="type === 'dark' ? 'table-dark': ''"
              :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
              tbody-classes="list"
              :data="tableData">

            <template slot="columns">
              <th v-bind:style="{cursor:pointer2}">Neg</th>
              <th v-bind:style="{cursor:pointer2}">En trámite de liquidación</th>
              <th v-bind:style="{cursor:pointer2}">Liquidada/SITE</th>
              <th v-bind:style="{cursor:pointer2}">MDI</th>
              <th v-bind:style="{cursor:pointer2}">Pago banco</th>
              <th v-bind:style="{cursor:pointer2}">% Desc</th>
            </template>

            <template slot-scope="{row}">
              <th scope="row">
                <div class="media align-items-center">
                  <div class="media-body">
                    <span class="name mb-0 text-sm">{{row.consultant}}</span>
                  </div>
                </div>
              </th>
              <th>{{row.totalTL}}</th>
              <td>{{row.totalL}}</td>
              <td>{{$formatCurrency(row.mdi)}}</td>
              <td>{{ $formatCurrency(row.bank_payment)}}</td>
              <td>{{ (row.discount_percent).toFixed(2)+'%'}}</td>
            </template>
          </base-table>
          
        </div>
      </div>
            <div class="col-6 text-center table-responsive">
        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
          <base-table class="round_table table table-sm align-items-center"
              :class="type === 'dark' ? 'table-dark': ''"
              :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
              tbody-classes="list"
              :data="tableDataMethod">

            <template slot="columns">
              <th v-bind:style="{cursor:pointer2}">Método Pago</th>
              <th v-bind:style="{cursor:pointer2}"># Deudas</th>
              <th v-bind:style="{cursor:pointer2}">% Deudas</th>
            </template>

            <template slot-scope="{row}">
              <th scope="row">
                <div class="media align-items-center">
                  <div class="media-body">
                    <span class="name mb-0 text-sm">{{row.method_pay}}</span>
                  </div>
                </div>
              </th>
              <th>{{row.totalM}}</th>
              <td>{{(row.percentageM)+'%'}}</td>
            </template>
          </base-table>
          <base-table class="round_table table table-sm align-items-center"
              :class="type === 'dark' ? 'table-dark': ''"
              :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
              tbody-classes="list"
              :data="tableDataVobo">

            <template slot-scope="{row}">
              <th scope="row">
                <div class="media align-items-center">
                  <div class="media-body">
                    <span class="name mb-0 text-sm">
                    Falta Dinero FA
                    </span>
                  </div>
                </div>
              </th>
              <th>{{row.totalV}}</th>
              <td>{{(row.sumA)+'%'}}</td>
            </template>
          </base-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'settlements-day-dash-table',
    props: {
      type: {
        type: String
      },
      title: String,
      data: Array,
      pagination: Object,
    },
    computed: {
      discount() {
        if(!this.bankDiscounts || !this.selectedBank || !this.selectedDueMonths) return 0
        let d = this.bankDiscounts.find(obj => obj.bank === this.selectedBank.name && obj.month == this.selectedDueMonths);
        return d?d.discount_percent:'0'
      },
      user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      sellers() { return this.$store.state.sellers },
      customerService() { return this.$store.state.customerService },
      banks() { return this.$store.state.banks; },
    },
    data() {
      return {
        tableData: [],
        tableDataMethod:[],
        tableDataVobo:[],
        tsumTL:0,
        tsumL:0,
        pointer: 'pointer',
        pointer2: 'default',
      }
    },
    async created(){
      this.getDiscountBank()
      this.pictureConsultant='https://www.dbmenos.com/img/consultants/' + this.user.picture;
      await this.getData();
      this.getDashVOBO();
      this.getDashMethodPay();
    },
    methods: {
      formatDecPost(val, pos) {
        if (!pos) pos = 2;
        if (val && Number(val)) return Number(val).toFixed(pos);
        return "0.00";
      },
      getIconClass(column) {
        let iconClass = "fas "
        if (column === this.sortedColumn) {
          iconClass += this.sortingDesc ? "fa-arrow-down" : "fa-arrow-up";
          iconClass += " text-danger";
        } else iconClass += "fa-arrows-alt-v";
        return iconClass;
      },
      async getData() {
        let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_VIEW_DEBTS_ALL;
        let tableTL = [];
        let tableL = [];
        let currentMonth = this.$moment().startOf('month').format('YYYY-MM-DD');
        let requestTL = {
          status: 'En trámite de liquidación',
          limit_pay_date: { gte: currentMonth },
          //estimated_settlement_date: {ne: null},
          limit: 500
        }
        let requestL = {
          status: 'Liquidada',
          settlement_paid_date: { gte: currentMonth },
          limit: 500
        }
        this.$showLoader(true)
        await this.$axios.post(url, requestTL).then(response => {
          this.$showLoader(false)
          let data = response.data
          if (data[this.$v.API_SUCCESS]) {
            tableTL = data[this.$v.API_ROWS]
          } else { this.$validateSession(data) }
        })
        await this.$axios.post(url, requestL).then(response => {
          this.$showLoader(false)
          let data = response.data
          if (data[this.$v.API_SUCCESS]) {
            tableL = data[this.$v.API_ROWS]
          } else { this.$validateSession(data) }
        })
        if(tableL && !tableTL)
          this.tableData = this.getTableData(tableL);
        else if (!tableL && tableTL)
          this.tableData = this.getTableData(tableTL)
        else 
        this.tableData = this.getTableData(tableTL.concat(tableL))
        this.tableDataMethod = this.getTableDataMethod(tableTL.concat(tableL));
        this.tableDataVobo=this.getTableDataVobo(tableTL.concat(tableL))

      },
      getTableData(data) {
        let escDebtsData=[]
        for (let k=0; k<this.customerService.length; k++){
          let r = {
            consultant: this.customerService[k].name,
            consultant_id: this.customerService[k].id,
            totalTL: 0,
            totalL: 0,
            mdi: 0,
            bank_payment: 0,
            discount_percent: 0
          }
          escDebtsData.push(r);
        }
        escDebtsData.push({
          consultant: 'Área',
          consultant_id: 0,
          totalTL: 0,
          totalL: 0,
          mdi: 0,
          bank_payment: 0,
          discount_percent: 0
        })
        let l = escDebtsData.length-1;
        for (let i=0; i<data.length;i++){
          for (let j=0; j<escDebtsData.length-1; j++){
            if (data[i].consultant_sc == escDebtsData[j].consultant_id){
              if (data[i].status === "En trámite de liquidación") {
                escDebtsData[j].totalTL++;
                escDebtsData[l].totalTL++;
              }
              if (data[i].status === "Liquidada") {
                escDebtsData[j].totalL++;
                escDebtsData[l].totalL++;
              }
              if (data[i].status === "Liquidada" || data[i].status === "En trámite de liquidación") {
                escDebtsData[j].mdi += Number(data[i].total_amount);
                escDebtsData[l].mdi += Number(data[i].total_amount);
              }
              if (data[i].status === "Liquidada" || data[i].status === "En trámite de liquidación") {
                escDebtsData[j].bank_payment += Number(data[i].amount_pay_bank);
                escDebtsData[l].bank_payment += Number(data[i].amount_pay_bank);
              }
              escDebtsData[j].discount_percent = (1-(Number(escDebtsData[j].bank_payment)/Number(escDebtsData[j].mdi)))*100;
            }
          }
        }
        escDebtsData[l].discount_percent = (1-(Number(escDebtsData[l].bank_payment)/Number(escDebtsData[l].mdi)))*100;
        this.tsumTL=escDebtsData[l].totalTL
        this.tsumL=escDebtsData[l].totalL
        return escDebtsData;
      },
      getTableDataMethod(data) {
        let methodData=[]
        let totalDebts=this.tsumTL + this.tsumL;
        methodData.push({
          method_pay: 'Efectivo',
          totalM: 0,
          percentageM: 0
        })
        methodData.push({
          method_pay: 'Transferencia',
          totalM: 0,
          percentageM: 0
        })
        methodData.push({
          method_pay: 'Total',
          totalM: 0,
          percentageM: 0
        })
        let l = methodData.length-1;
        for (let i=0; i<data.length;i++){
          for (let j=0; j<methodData.length-1; j++){
            if (data[i].method_pay == methodData[j].method_pay){
              if (data[i].method_pay === "Transferencia") {
                methodData[j].totalM++;
                methodData[l].totalM++;
                methodData[j].percentageM = ((1-(Number(methodData[j].totalM)/Number(totalDebts)))*100).toFixed(2);
              }
              if (data[i].method_pay === "Efectivo") {
                methodData[j].totalM++;
                methodData[l].totalM++;
                methodData[j].percentageM = ((1-(Number(methodData[j].totalM)/Number(totalDebts)))*100).toFixed(2);
              }
            }
          }
        }
        methodData[l].percentageM = ((1-(Number(methodData[l].totalM)/Number(totalDebts)))*100).toFixed(2);
        return methodData;
      },
      getTableDataVobo(data) {
        let voboData=[]
        voboData.push({
          totalV: 0,
          sumA: 0
        })
        for (let i=0; i<data.length;i++){
          for (let j=0; j<voboData.length-1; j++){
              if (data[i].vobo_TL == "1") {
                voboData[j].totalV++;
                voboData[j].sumA += (data[i].amount_pay_bank).toFixed(2);
              }
          }
        }
        return voboData;
      },
      getDiscountBank(){
          let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_DISCOUNTS_BANK
          this.$showLoader(true);
          this.$axios.post(url).then((response) => {
              this.$showLoader(false);
              let data = response.data;
              if (data[this.$v.API_SUCCESS]) {
                  this.bankDiscounts = data[this.$v.API_ROW]
                  this.$forceUpdate();
              }
          });
      },
      getDashVOBO() {
          let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_V_DASHBOARD_VOBO
          this.$showLoader(true)
          this.$axios.get(url).then(response => {
              this.$showLoader(false)
              let data = response.data
              if (data[this.$v.API_SUCCESS]) {
                  this.dataVOBO = data[this.$v.API_ROWS]
              } else { this.$validateSession(data) }
          })
      },
      getDashMethodPay() {
            let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_V_DASHBOARD_METHOD_PAY
            this.$showLoader(true)
            this.$axios.post(url).then(response => {
                this.$showLoader(false)
                let data = response.data
                if (data[this.$v.API_SUCCESS]) {
                    this.dataMethodPay = data[this.$v.API_ROWS].reverse()
                    for(let i=0;i<this.dataMethodPay.length;i++){
                        this.sumNoDeudas+=Number(this.dataMethodPay[i].total_method);
                        this.sumDisDeudas+=Number(this.dataMethodPay[i].percentage);
                    }
                    let suma = 0;
                    suma = Number(this.sumTL+this.sumLiq)
                    this.sumDisDeudas = Number((1-(this.sumNoDeudas/suma))*100)
                } else { this.$validateSession(data) }
            })
        },
    }
  }
</script>
<style>
#top
{ position: fixed;  z-index: 999; }

.round_table {
  border-collapse: separate;
  border-spacing: 1;
  border:solid 1px;
  border-color: #d3d3d3;
}

.round_th {
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
}
</style>