<template>
    <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title+' fase '+fase}}
                    </h3>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getStatusFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setStatusFilter(null)">Mostrar todos</a>
                        <a v-if="fase==1" class="dropdown-item" v-on:click="setStatusFilter(-1)">Sin seguimiento</a>
                        <a class="dropdown-item" v-on:click="setStatusFilter(status.id)" v-for="(status, index) in leadStatus" :key="index">{{status.status}}</a>
                    </base-dropdown>
                </div>
                <div v-if="isAdmin || isManagerSeller" class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getSellerFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setSellerFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-for="(seller, index) in sellers" :key="index" v-on:click="setSellerFilter(seller.id)">{{seller.name}} {{seller.lastname_1}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getPeriodFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
                        <div v-if="!showSetPeriod" class="dropdown-divider"></div>
                        <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
                    </base-dropdown>
                </div>                
                <div v-if="showSetPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
                        <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush" :class="type === 'dark' ? 'table-dark': ''" :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list"
                :data="data"
                :period="period"
                v-on:click="leadSelected">

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">ID</th>
                    <th v-bind:style="{cursor:pointer2}">Nombre</th>
                    <th v-bind:style="{cursor:pointer2}">Teléfono</th>
                    <th v-bind:style="{cursor:pointer2}">Total Adeudado</th>
                    <th v-bind:style="{cursor:pointer2}">Datos Bancarios</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha Ingreso</th>
                    <th v-bind:style="{cursor:pointer2}">Status</th>
                    <th v-bind:style="{cursor:pointer2}">Notas</th>
                    <th v-bind:style="{cursor:pointer2}" v-if="isAdmin">Asesor</th>
                </template>

                <template slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-primary text-sm" v-on:click.stop v-on:click="toClient(row)">{{row.id}} <i class="fas fa-user-edit text-primary"></i></span>
                            </div>
                        </div>
                    </th>
                    <td>{{row.name}}</td>
                    <td>
                        <base-button v-on:click="callUser(row)" id="showModal" type="outline-success" class="p-2" size="sm" style="margin-bottom:0.5rem; margin-left:0.5rem;">
                            {{row.phone}}<i :class="row.validated? 'fas fa-phone text-success':'fas fa-phone text-danger'"></i>
                        </base-button>
                    </td>
                    <td v-if="row.calculation && row.calculation.totalDebt && row.calculation.totalDebt != 0">{{$formatCurrency(row.calculation.totalDebt)}}</td>
                    <td v-else>{{$formatCurrency(row.amount)}}</td>
                    <td v-on:click.stop="">
                        <div v-if="row.banks" class="avatar-group">
                            <a v-for="(bank, index) in getBanks(JSON.parse(row.banks))" :key="index" class="avatar avatar-sm rounded-circle" style="background-color: #FFFFFF" data-toggle="tooltip" :data-original-title="bank.name">
                                <img :alt="bank.name" :src="bank.url">
                            </a>
                        </div>
                        <div v-else class="col p-0">
                            Sin datos
                        </div>
                    </td>
                    <td>{{$formatDate(row.created)}}</td>
                    <td>{{row.status? row.status : 'Sin seguimiento'}}</td>
                    <td>{{row.comments? row.comments : '---'}}</td>
                    <td v-if="isAdmin" class="" v-on:click.stop="">
                        <base-dropdown class="dropdown">
                            <a slot="title" class="btn btn-sm" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {{getSellerName(row.seller)}}<i class="fas fa-user-tie"></i>
                            </a>
                            <template>
                                <a v-for="(seller, index) in sellers" :key="index" class="dropdown-item" v-on:click="assignToSeller(row, seller)">{{seller.name}} {{seller.lastname_1}}</a>
                            </template>
                        </base-dropdown>
                    </td>
                </template>

            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end"
                :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} prospectos
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'bucket-leads',
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            fase: Number,
            pagination: Object,
            period: {
                start: null,
                end: null
            },
        },
        data() {
            return {
              isAdmin: false,
              isManagerSeller: false,
              isSeller: false,
              pointer: 'pointer',
              pointer2: 'default',

              //Filtros
              statusFilter: null,
              showSetPeriod:false,
              sellerFilter: null,
              periodFilter: null,
              statusFilter: null,
            }
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            banks() { return this.$store.state.banks },
            leadStatus() { return this.$store.state.leadStatus.filter(item => this.fase==1?[1,2,16].includes(item.id): [5,6,4,7,10,8].includes(item.id)) },
            sellers() { 
                let sellerManagerList = [];
                let allSellers = this.$store.state.sellers
                if (this.isManagerSeller){
                    for(let i=0; i<allSellers.length; i++){
                        if(allSellers[i].leader == this.user.id)
                            sellerManagerList.push(allSellers[i])
                    }return sellerManagerList;
                }else return allSellers;
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
            this.isSeller = this.user.role === this.$v.ROLES.SELLER;
            this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
            await this.pageSelected(1);
        },
        methods: {
            toClient(lead) { this.$emit("toClient", lead); },
            pageSelected(page) {
                this.$emit("pageSelected", page, this.fase);
            },
            leadSelected(lead) {
                this.$emit("leadSelected", lead);
            },
            getSellerName(sellerID) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === sellerID) return this.sellers[i].name
                return "Asignar"
            },
            getBanks(array) {
                let banks = []
                if(array) {
                    for (let a=0 ; a<=array.length ; a++) {
                        for (let b = 0; b < this.banks.length; b++) {
                            if (this.banks[b].id === array[a]) {
                                banks.push(this.banks[b])
                                break
                            }
                        }
                    }
                }
                return banks
            },
            assignToSeller(lead, seller) {
                let url = this.$v.SERVER + this.$v.SELLER_OPS.ASSIGN_LEAD
                this.$showLoader(true)
                this.$axios.post(url, {lead: lead.id, seller: seller.id }).then(response => {
                    this.$showLoader(false)
                    let data = response.data
                    if (data[this.$v.API_SUCCESS]) {
                        lead.seller = seller.id
                        this.$vs.notification(this.$notification(
                            'success',
                            'Asignación realizada exitosamente'
                        ));
                        this.pageSelected(1,this.fase);
                        this.$forceUpdate()
                    }
                });
            },
            callUser(lead){
                let url = this.$v.SERVER + this.$v.SELLER_OPS.SEND_STARTING_CALL_PUSH_NOTIFICATION;
                this.$showLoader(true);
                let request={"lead": lead.id}
                this.$axios.post(url, request).then((response) => {
                    this.$showLoader(false);
                    let data = response.data;
                    if (data.success) {
                        this.$vs.notification(this.$notification(
                            'success',
                            'Llamando al cliente...... '
                        ));
                    }else{
                        this.$vs.notification(this.$notification(
                            'danger',
                            'Algo salió mal',
                            'Intenta nuevamente'
                        ));
                    }
                });
            },

            //Filtros
            setStatusFilter(value) {
                this.statusFilter = value
                this.$emit("setStatusFilter", value, this.fase);
            },
            getStatusFilterText() {
                if(!this.statusFilter) return "Seguimiento"
                if(this.statusFilter==-1) return "Sin seguimiento"
                let status = this.leadStatus.find(item => item.id == this.statusFilter);
                if(status) return status.status
                return this.statusFilter
            },
            getSellerFilterText() {
                if (this.sellerFilter) return this.getSellerName(this.sellerFilter)
                return "Vendedores"
            },
            setSellerFilter(sellerID) {
                this.sellerFilter = sellerID
                this.$emit("setSellerFilter", sellerID, this.fase);
            },
            setPeriod(){
                this.showSetPeriod=true
                this.period.start=null
                this.period.end=null
                this.getPeriodFilterText()                
            },
            setPeriodFilter(value) {        
                if(value!=2){
                    this.showSetPeriod = false
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value, this.fase);
                    
                }else if(this.period.start && this.period.end){
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value, this.fase);
                }
            },
            getPeriodFilterText() {
                if(this.showSetPeriod)
                    return "Periodo del: "
                else if(this.periodFilter === 1)
                    return "Mes actual"
                else return "Fecha"
            },
        }
    }
</script>
<style>
</style>