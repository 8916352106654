<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''"
       v-bind:style="{cursor:pointer2}">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div v-if="buttonTeamLeader" class="col-10 text-left">
          <base-button type="primary" size="sm" @click="newActivity">Actividad TemLeader</base-button>
        </div>
        <div v-if="!alert && buttonSendSettlementLetter" class="col text-left">
          <base-button type="warning" size="sm" @click="completeBalance? sendSettlementLetter() : alert=true ">Enviar carta de liquidación</base-button>
        </div>
        <div v-if="!alert && buttonSendSettlementLetter" class="col text-right">
          <base-button type="info" size="sm" @click="createSettlementLetter()">Ver pre carta de liquidación</base-button>
          <base-button type="primary" size="sm" @click="newActivity">Nuevo</base-button>
        </div>
        <div v-if="alert && !completeBalance" class="alert alert-warning">
          <strong>¡Atención!:</strong> ¡Saldo insuficiente!
          <base-button type="danger" size="sm" @click="alert=false">Cancelar</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data">
        <template slot="columns">
          <th>Fecha de Actividad</th>
          <th>Creado por</th>
          <th>Tipo de Actividad</th>
          <th>Saldo Actual</th>
          <th>Pago banco</th>
          <th>Fecha Limite de Pago</th>
          <th>% Descuento</th>
          <th>Despacho</th>
          <th>Teléfono despacho</th>
          <th>Comentarios</th>
        </template>

        <template slot-scope="{row}">
          <td>{{$formatDate(row.created)}}</td>
          <td>{{row.consultant}}</td>
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.activity}}</span>
              </div>
            </div>
          </th>
          <td>{{$formatCurrency(row.amount)}}</td>
          <td>{{$formatCurrency(row.amount_pay_bank)}}</td>
          <td>{{$formatDate(row.limit_pay_date)}}</td>
          <td>{{row.discount? row.discount.toFixed(2)+"%":'-'}}</td>
          <td>{{row.firm_name}}</td>
          <td>{{row.firm_phone}}</td>
          <td :title="row.comments">{{getString(row.comments)}}</td>
        </template>

      </base-table>
    </div>
    <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

  </div>
</template>
<script>
  export default {
    name: 'negotiation-table',
    props: {
      type: {
        type: String
      },
      title: String,
      data: Array,
      pagination: Object,
      completeBalance: Boolean,
      buttonSendSettlementLetter: Boolean,
      buttonTeamLeader:Boolean
    },
    data() {
      return {
        isAuditor: false,
        isService: false,
        isSeller: false,
        isManagerService: false,
        isAdmin: false,
        tableData: [],
        pointer: 'pointer',
        pointer2: 'default',
        alert: false,
      }
    },
    computed: {
      managerService() { return this.$store.state.managerService},
      user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      banks() { return this.$store.state.banks },
      sellers() { return this.$store.state.sellers },
      customerService() { return this.$store.state.customerService },
      debtActivityCatalog() { return this.$store.state.debtActivityCatalog }
    },
    created() {
      this.isSeller = this.user.role === this.$v.ROLES.SELLER;
      this.isService = this.user.role === this.$v.ROLES.SERVICE;
      this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
      this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    },
    methods: {
      pageSelected(page) {
          this.$emit("pageSelected", page);
      },
      newActivity() {
        this.$emit("newActivity")
      },
      sendSettlementLetter(){
        this.alert=false;
        this.$emit("sendSettlementLetter")
      },
      createSettlementLetter(){
        this.alert=false;
        this.$emit("createSettlementLetter")
      },
      getActivityType(id) {
        for (let i=0 ; i<this.debtActivityCatalog.length ; i++){
          if (this.debtActivityCatalog[i].id === id)
            return this.debtActivityCatalog[i].describe
        }
      },
      getString(s){
        if(!s) return '-'
        let comment = s.slice(0, 30)
        if (s.length>30) comment+='...';
        return comment
      },
      getConsultant(cid) {
        for (let i=0 ; i<this.customerService.length ; i++) {
          if (this.customerService[i].id === cid) {
            return this.customerService[i].name + " " + this.customerService[i].lastname_1
          }
        }
        for (let i=0 ; i<this.sellers.length ; i++) {
          if (this.sellers[i].id === cid) {
            return this.sellers[i].name + " " + this.sellers[i].lastname_1
          }
        }
        for (let i=0 ; i<this.managerService.length ; i++) {
          if (this.managerService[i].id === cid) {
            return this.managerService[i].name + " " + this.managerService[i].lastname_1
          }
        }
      },
    }
  }
</script>
<style>
</style>