<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button v-if="!showData && showEye" title="Mostrar" size="sm" type="info" icon="far fa-eye" @click="downloadData"></base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor: pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">

                <template slot="columns">
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Historia</th>
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Evento</th>
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Dispositivo</th>
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Fecha</th>
                </template>

                <template v-if="showData" slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body text-center">
                                <span class="name mb-0 text-sm">{{row.historyID}}</span>
                            </div>
                        </div>
                    </th>
                    <td>{{row.eventName}}</td>
                    <td class="text-center">{{row.device}}</td>
                    <td>{{formatDate(row.created)}}</td>
                </template>

            </base-table>

        </div>

        <div v-if="(pagination.total > pagination.perPage) && showData" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'history-table',
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            showEye: Boolean,
            pagination: Object
        },
        data() {
            return {
                isAdmin: false,
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                showData:false
            }
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService },
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
        },
        methods: {
            downloadData() {
                let page=1;
                this.showData=true;
                this.$emit("downloadData",page)
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            formatDate(date) {
            if (date <= "2020-11-10") {
                return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
            } else {
                return this.$moment(date).format("DD MMM YYYY, h:mm a");
            }
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            }
        }
    }
</script>
<style>
</style>