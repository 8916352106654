<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>
		<div class="container-fluid mt--7">
			<div class="row" >
				<div class="col-lg-3"  v-bind:style="{cursor:pointer2}">
          <debts-info-service title="Deudas a negociar"/>
        </div>
        <div class="col-lg-9">
          <progress-bar-service title="Deudas a negociar"/>
          <negotiations-table title="Deudas a negociar"
              style="margin-top:-5rem;"
              :data="tableData"
              :pagination="pagination"
              v-on:pageSelected="getDebtsList"
              v-on:debtSelected="debtSelected"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import DebtsInfoService from './Tables/DebtsInfoService'
    import NegotiationsTable from './Tables/NegotiationsTable'
import ProgressBarService from './ProgressBarService.vue';
    export default {
        name: 'tables',
        components: {
            DebtsInfoService,
            NegotiationsTable,
            ProgressBarService
        },
        mounted() {
            this.$root.$on('searchQuery', query => {
				if (query.length > 0)
					this.searchQuery = query
				else
					this.searchQuery = null
				this.getDebtsList(1)
			})
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) }
        },
        data() {
            return {
                isAdmin: false,
                isService: false,
                isAuditor: false,
                isManagerService: false,
                program: null,
                unassigned: null,
                pagination: {
                    total: 0,
                    perPage: 10,
                    currentPage: 1
                },
                /*period: {
                    start: null,
                    end: null
                },*/
                tableData: [],
                searchQuery: null,
                consultantFilter: null,
                pointer: 'pointer',
				pointer2: 'default'
            };
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isService = this.user.role === this.$v.ROLES.SERVICE || this.user.role === this.$v.ROLES.AUDITOR
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
            this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR
            this.getDebtsList(1)
        },
        methods: {
            getDebtsList(page) {
                let limit = this.pagination.perPage;
                let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_VIEW_DEBTS_ALL;
                let request = {
                    offset: limit * (page - 1),
                    limit: limit
                }
                if (this.searchQuery) url += "?find=" + this.searchQuery
                if (this.isService && !this.searchQuery) request.consultant_sc = this.user.id
                this.$showLoader(true)
                this.$axios.post(url, request).then(response => {
                    this.$showLoader(false)
                    let data = response.data
                    if (data[this.$v.API_SUCCESS]) {
                        this.tableData = data[this.$v.API_ROWS]
                        let p = data[this.$v.API_ROW]
                        this.pagination.total = p['totalItems']
                        this.pagination.currentPage = page
                        this.pagination.perPage = limit
                    } else { this.$validateSession(data) }
                })
            },
            debtSelected(debt_id) {
                window.open("https://admin.dbmenos.com/viewDebt?debt="+debt_id, '_blank')
            },
            setConsultantFilter(value) {
                this.consultantFilter = value
                this.getDebtsList(1)
            },
            /*setPeriodFilter(value) {
                if(value === 1){
                    let date = new Date()
                    let month = date.getMonth()+1
                    if(month<10) month= "0"+month
                    this.period.start = date.getFullYear()+"-"+month+"-"+"01"+"T12:00:00.000Z"
                }
                this.periodFilter = value
                this.getDebtsList(1)
            },*/
        }
    };
</script>
<style>
#top 
{ position: fixed;  z-index: 999; }
</style>