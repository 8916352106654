<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''"
       v-bind:style="{cursor:pointer2}">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
          <h5 v-if="!allCommissions && showData" class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{$formatCurrency(amount)}}
          </h5>
        </div>
         <div v-if="allCommissions" class="col-5" v-bind:style="{ cursor: pointer2 }">
          <base-input
            placeholder="Buscar"
            class="input-group-alternative"
            alternative
            v-model="searchQueryComm"
          ></base-input>
        </div>
        <div v-if="allCommissions" class="col-2 text-right" v-bind:style="{ cursor: pointer2 }"  style="margin-top:-1.5rem;">
          <base-button
            slot="title"
            type="info"
            style="width: 100%"
            size="sm"
            @click="searchData(searchQueryComm)"
          >
            <i class="fas fa-search"></i></base-button>
        </div>
        <div v-if="allCommissions" class="col-2 text-right" v-bind:style="{ cursor: pointer2 }"  style="margin-top:-1.5rem;">
          <base-button
            slot="title"
            type="warning"
            style="width: 100%"
            size="sm"
            @click="updloadSheetsFacture()"
          >
            <i class="fas fa-file-csv"></i> CSV Facturama
          </base-button>
        </div>
        <div v-if="allCommissions" class="col-2 text-right" v-bind:style="{ cursor: pointer2 }"  style="margin-top:-1.5rem;">
          <base-button
            slot="title"
            type="success"
            style="width: 100%"
            size="sm"
            @click="showReport()"
          >
            <i class="fas fa-file-excel"></i> Ver Reporte
          </base-button>
        </div>
        <div class="col text-right" v-if="!allCommissions">
          <base-button v-if="!showData && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="showCommission"></base-button>
          <base-button v-if="showData" type="primary" size="sm" v-on:click="onNewItem">Nuevo</base-button>
        </div>
      </div>
    </div>

    <div v-if="!allCommissions" class="table-responsive text-center">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data"
                 >
        <template slot="columns">
          <th>ID Comisión</th>
          <th>Cantidad</th>
          <th>Fecha de pago</th>
          <th>Creado el</th>
        </template>

        <template v-if="showData" slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm"> {{row.id}}</span>
              </div>
            </div>
            </th>
          <td>{{$formatCurrency(row.amount)}}</td>
          <td>{{formatDate(row.date)}}</td>
          <td>{{formatDate(row.created)}}</td>

        </template>

      </base-table>
    </div>

    <div v-if="allCommissions" class="table-responsive text-center">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data"
                   v-on:click="commissionSelected">
        <template slot="columns">
          <th >ID Cliente</th>
          <th>ID Comisión</th>
          <th>Cantidad</th>
          <th>Fecha de pago</th>
          <th>Creado el</th>
          <th>Eliminar</th>

        </template>

        <template slot-scope="{row}">
           <th scope="row">
            <div class="media align-items-center">
              <div class="media-body text-primary">
                <a :href="'https://admin.dbmenos.com/client?id='+row.user" target="_blank">
                <i class="fas fa-user-circle text-primary"> </i><span class="name mb-0 text-sm"> {{row.user}}</span>
                </a>
              </div>
            </div>
          </th>
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm"> {{row.id}}</span>
              </div>
            </div>
            </th>
          <td>{{$formatCurrency(row.amount)}}</td>
          <td>{{formatDate(row.date)}}</td>
          <td>{{formatDate(row.created)}}</td>
          <td class="text-center text-danger"><i class="fas fa-trash" @click.stop="deleteFile(row)"></i></td>


        </template>

      </base-table>
    </div>

    <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination
              size="sm"
              :total="pagination.total"
              :per-page="pagination.perPage"
              :value="pagination.currentPage"
              v-on:pageSelected="pageSelected"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'clients-table',
    props: {
      type: {
        type: String
      },
      title: String,
      amount: Number,
      data: Array,
      showEye: Boolean,
      pagination: Object,
      allCommissions: Boolean,
    },
    data() {
      return {
        tableData: [],
        pointer: 'pointer',
        pointer2: 'default',
        searchQueryComm:null,
        showData:false
      }
    },
    computed: {
      banks() { return this.$store.state.banks }
    },
    async created() { },
    methods: {
    showReport() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.UPDATE_COMMISSIONS;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
          window.open(
          "https://docs.google.com/spreadsheets/d/1XPYChRQzqvko9-SkRNoLFZ_cs5G7U6G2wF24MuNQ3ho/edit?usp=sharing"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    updloadSheetsFacture() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.REPORTS_SHEETS.UPDATE_FACTURE;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
          window.open(
            "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
            "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
      showCommission() {
                this.showData = true;
                this.$emit("showCommission")
            },
      deleteFile(dataM){
        this.$emit("newModification", dataM, this.$v.MODIFICATIONS.COMMISSION)
      },
      commissionSelected(com) {
        this.$emit("commissionSelected", com)
        },
        searchData(s){
          this.$emit("searchData", s);
        },
       requestFindComm(e) {
        e.preventDefault();
      this.$root.$emit("searchQueryComm", this.searchQueryComm);
    },
      pageSelected(page) {
        this.$emit("pageSelected", page);
      },
      getValidatedClass(validated) {
        if (validated) return "text-success"
        return "text-danger"
      },
      formatCurrency(value) {
        return this.$formatCurrency(value)
      },
      getBanks(array) {
        let banks = []
        for (let a=0 ; a<=array.length ; a++) {
          for (let b = 0; b < this.banks.length; b++) {
            if (this.banks[b].id === array[a]) {
              banks.push(this.banks[b])
              break
            }
          }
        }
        return banks
      },
      formatDate(date) {
//        return this.$moment(date).format("DD MMM, YYYY")
         return this.$formatDate(date)
      },
      onNewItem() {
        this.$emit("onNewItem")
      }
    }
  }
</script>
<style>
</style>
