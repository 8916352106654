<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">

                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button type="primary" v-on:click="onNewItem">Nuevo</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        v-on:click="rowSelected">

                <template slot="columns">
                    <th class="text-center" v-on:click="selectColumn(0)" v-bind:style="{cursor:pointer2}">ID<i :class="getIconClass(0)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(1)" v-bind:style="{cursor:pointer2}">Usuario<i :class="getIconClass(1)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(2)" v-bind:style="{cursor:pointer2}">Nombre<i :class="getIconClass(2)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(3)" v-bind:style="{cursor:pointer2}">Primer apellido<i :class="getIconClass(3)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(4)" v-bind:style="{cursor:pointer2}">Segundo apellido<i :class="getIconClass(4)" style="margin-left: 6px"/></th>
                    <th class="text-center" v-bind:style="{cursor:pointer2}">Teléfono</th>
                    <th class="text-center" v-on:click="selectColumn(6)" v-bind:style="{cursor:pointer2}">Rol<i :class="getIconClass(6)" style="margin-left: 6px"/></th>
                    <th class="text-center" v-on:click="selectColumn(7)" v-bind:style="{cursor:pointer2}">Habilitado<i :class="getIconClass(7)" style="margin-left: 6px"/></th>
                    <th class="text-center" v-on:click="selectColumn(8)" v-bind:style="{cursor:pointer2}">Consultor desde el<i :class="getIconClass(8)" style="margin-left: 6px"/></th>
                    <th class="text-center" v-on:click="selectColumn(8)" v-bind:style="{cursor:pointer2}">Registrado el<i :class="getIconClass(8)" style="margin-left: 6px"/></th>

                </template>

                <template slot-scope="{row}">
                    <th class="text-center" scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{row.id}}</span>
                            </div>
                        </div>
                    </th>
                    <td>{{row.username}}</td>
                    <td>{{row.name}}</td>
                    <td>{{row.lastname_1}}</td>
                    <td>{{row.lastname_2}}</td>
                    <td class="text-center">{{row.phone}}</td>
                    <td class="text-center">{{getRoleName(row.role)}}</td>
                    <td class="text-center"><i :class="getEnabledClass(row.enabled === 1)"/></td>
                    <td class="text-center">{{row.admission_date ? $moment(row.admission_date).format("DD MMM, YYYY"):'Sin información'}}</td>
                    <td class="text-center">{{row.created ? $moment(row.created).format("DD MMM, YYYY"):'Sin información'}}</td>
                </template>

            </base-table>
        </div>
        <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} consultores
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'consultants-table',
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            banks() { return this.$store.state.banks },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService },
        },
        data() {
            return {
                isAdmin: false,
                currentColumnKey: null,
                sortedColumn: 0,
                freezeData: null,
                sortingDesc: true,
                pointer: 'pointer',
                pointer2: 'default'
            }
        },
        updated() {
            if (!this.freezeData && this.data) {
                this.freezeData = JSON.parse(JSON.stringify(this.data))
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
        },
        methods: {
             pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            onNewItem() {
                this.$emit("onNewItem");
            },
            rowSelected(row) {
                this.$emit("rowSelected", row);
            },
            insertRow() {
                this.$emit("insertRow");
            },
            uploadUpdates() {
                this.$showLoader(true)
                let toUpload = []
                for (let i=0 ; i<this.data.length ; i++) {
                    let acc = this.data[i]
                    if (this.isUpdated(acc)) toUpload.push(acc)
                }
//                console.log(toUpload)
                let url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_ACCOUNTS
                this.$axios.post(url, { accounts: toUpload}).then(response => {
                    this.$showLoader(false)
                    let data = response.data
                    if (data[this.$v.API_SUCCESS]) {
                        this.freezeData = JSON.parse(JSON.stringify(this.data))
                        
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
                    } else {
                        this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    }
                })
            },
            sort() {
                switch (this.sortedColumn) {
                    case 0: this.currentColumnKey = 'id'; break
                    case 1: this.currentColumnKey = 'username'; break
                    case 2: this.currentColumnKey = 'name'; break
                    case 3: this.currentColumnKey = 'lastname_1'; break
                    case 4: this.currentColumnKey = 'lastname_2'; break
                    case 6: this.currentColumnKey = 'role'; break
                    case 7: this.currentColumnKey = 'enabled'; break
                    case 8: this.currentColumnKey = 'created'; break
                }
//                console.log(this.currentColumnKey)
                this.data.sort((a, b) => {
                    let _a = a[this.currentColumnKey]
                    let _b = b[this.currentColumnKey]
                    if (_a < _b) return this.sortingDesc ? -1 : 1
                    if (_a > _b) return this.sortingDesc ? 1 : -1
                    return 0
                })
//                console.log(this.data[0])
            },
            selectColumn(column) {
//                console.log(column + " selected")
              if (this.sortedColumn === column) {
                  this.sortingDesc = !this.sortingDesc
              } else {
                  this.sortedColumn = column
                  this.sortingDesc = true
              }
              if (this.sortedColumn != null)
                this.sort()
            },
            getIconClass(column) {
                let iconClass = "fas "
                if (column === this.sortedColumn) {
                    iconClass += this.sortingDesc ? "fa-arrow-down" : "fa-arrow-up"
                    iconClass += " text-danger"
                } else {
                    iconClass += "fa-arrows-alt-v"
                }
                return iconClass
            },
            getEnabledClass(enabled) {
                if (enabled) return "fas fa-check text-success"
                return "fas fa-ban text-danger"
            },
            getRoleName(role) {
                switch (role) {
                    case 'admin': return "Administrador"
                    case 'seller': return "Vendedor"
                    case 'service': return "Servicio"
                    case 'finamigo': return "Finamigo"
                    case 'manager_service': return "Gerente de servicio"
                    case 'marketing': return "Marketing"
                    case 'audit': return "Auditor"
                    case 'growth': return "Growth"
                    case 'human_resources': return "Recursos Humanos"
                }
            }
        }
    }
</script>
<style>
</style>
