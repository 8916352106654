<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''"
       v-bind:style="{cursor:pointer2}">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button v-if="!(showData || showisService) && showEye" title="Mostrar" size="sm" type="info" icon="far fa-eye" @click="downloadData"></base-button>

          <base-button v-if="title==='Inactivar programa' && (showData || showisService) && !isService" type="primary" size="sm" v-on:click="newUserStatusTrace">Nuevo</base-button>
          <base-button v-if="title==='Acciones cliente' && (showData || showisService) && (!isSeller)" type="primary" size="sm" v-on:click="newUserStatusTrace">Nuevo</base-button>
          <base-button v-if="title==='Servicio al cliente' && (showData || showisService) && (!isSeller)" type="primary" size="sm" v-on:click="newUserStatusTrace">Nuevo</base-button>
          <base-button v-if="title==='Seguimiento de ventas' && (showData || showisService) && (isSeller || isAdmin) && showNew" type="primary" size="sm" v-on:click="newUserStatusTrace">Nuevo</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data">
        <template slot="columns">
          <th v-if="title==='Inactivar programa'">ID</th>
          <th>Estatus</th>
          <th>Comentarios</th>
          <th v-if="title==='Inactivar programa'">Remanente</th>
          <th>Fecha</th>
          <th v-if="!isAuditor && title!='Perfilamiento'">Consultor</th>
        </template>

        <template v-if="(showData || showisService)" slot-scope="{row}">
          <th v-if="title==='Inactivar programa'" scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.id}}</span>
              </div>
            </div>
          </th>
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{getStatusDesc(row.status?row.status:row.action?row.action:row.value)}}</span>
              </div>
            </div>
          </th>
          <td>{{row.comments}}</td>
          <td v-if="title==='Inactivar programa'">{{$formatCurrency(row.divisa)}}</td>
          <td>{{formatDate(row.created)}}</td>
          <td v-if="!isAuditor && title!='Perfilamiento'">{{getConsultant(row.upload_by)}}</td>
        </template>

      </base-table>
    </div>
    <div v-if="(pagination.total > pagination.perPage) && showData" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

  </div>
</template>
<script>
  export default {
    name: 'userStatusTracing-table',
    props: {
      type: {
        type: String
      },
      showisService: Boolean,
      title: String,
      showEye: Boolean,
      showNew: Boolean,
      existAddress:Boolean,
      data: Array,
      pagination: Object
    },
    data() {
      return {
        isAuditor: false,
        isService: false,
        isSeller: false,
        isManagerService: false,
        isAdmin: false,
        tableData: [],
        pointer: 'pointer',
        pointer2: 'default',
        showData: false,
      }
    },
    computed: {
      managerService() { return this.$store.state.managerService},
      user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      banks() { return this.$store.state.banks },
      userTracingStatus() { return this.$store.state.userTraceStatus },
      sellers() { return this.$store.state.sellers },
      sellersNotEnable() { return this.$store.state.sellerNotEnable },
      customerService() { return this.$store.state.customerService },
      customerServiceNotEnable() { return this.$store.state.customerServiceNotEnable },
      leadStatus() { return this.$store.state.leadStatus },
      userActionsCatalog() { return this.$store.state.userActionsCatalog },
      userInactiveCatalog() { return this.$store.state.userInactiveCatalog },
      admins() { return this.$store.state.admins }
    },
    created() {
      this.isSeller = this.user.role === this.$v.ROLES.SELLER;
      this.isService = this.user.role === this.$v.ROLES.SERVICE;
      this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
      this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
      if(this.isService) this.showData=true;
    },
    methods: {
      downloadData() {
        let page=1
        this.showData=true;
        this.$emit("downloadData",page)
      },
      pageSelected(page) {
          this.$emit("pageSelected", page);
      },
      formatDate(date) {
      if (date <= "2020-11-10") {
        return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
      } else {
        return this.$moment(date).format("DD MMM YYYY, h:mm a");
      }
    },
      newUserStatusTrace() {
        this.$emit("newUserStatusTrace",this.title)
      },
      getStatusDesc(statusId) {
        switch (this.title) {
          case 'Seguimiento de ventas': return statusId;
          case 'Perfilamiento': return statusId;
          case 'Inactivar programa':
            for (let i=0 ; i<this.userInactiveCatalog.length ; i++){
              if (this.userInactiveCatalog[i].id === statusId)
                return this.userInactiveCatalog[i].value
            } break;
          case 'Acciones cliente':
            for (let i=0 ; i<this.userActionsCatalog.length ; i++){
              if (this.userActionsCatalog[i].id === statusId)
                return this.userActionsCatalog[i].value
            } break;
          default:
            for (let i=0 ; i<this.userTracingStatus.length ; i++){
              if (this.userTracingStatus[i].id === statusId)
                return this.userTracingStatus[i].status
            } break;
        }
      },
      getConsultant(cid) {
        for (let i=0 ; i<this.customerService.length ; i++) {
          if (this.customerService[i].id === cid) {
            return this.customerService[i].name + " " + this.customerService[i].lastname_1
          }
        }
        for (let i=0 ; i<this.sellers.length ; i++) {
          if (this.sellers[i].id === cid) {
            return this.sellers[i].name + " " + this.sellers[i].lastname_1
          }
        }
        for (let i=0 ; i<this.managerService.length ; i++) {
          if (this.managerService[i].id === cid) {
            return this.managerService[i].name + " " + this.managerService[i].lastname_1
          }
        }
        for (let i=0 ; i<this.admins.length ; i++) {
          if (this.admins[i].id === cid) {
            return this.admins[i].name + " " + this.admins[i].lastname_1
          }
        }
        for (let i=0 ; i<this.sellersNotEnable.length ; i++) {
          if (this.sellersNotEnable[i].id === cid) {
            return this.sellersNotEnable[i].name + " " + this.sellersNotEnable[i].lastname_1
          }
        }
        for (let i=0 ; i<this.customerServiceNotEnable.length ; i++) {
          if (this.customerServiceNotEnable[i].id === cid) {
            return this.customerServiceNotEnable[i].name + " " + this.customerServiceNotEnable[i].lastname_1
          }
        }
      },
    }
  }
</script>
<style>
</style>