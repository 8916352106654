<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8">
		</base-header>

		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<contract-FA-table title="Sol. Cuenta FA Temp."
							:data="contractsTableData"
							:period="period"
							:pagination="contractsPagination"
							v-on:setStatusFilter="setStatusFilter"
							v-on:pageSelected="getContractsTableData"
							v-on:getData="getContractsTableData"
							v-on:setPeriodFilter="setPeriodFilter"/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import ContractFATable from './Tables/ContractFATable'
	export default {
		name: 'tables',
		components: {
			ContractFATable
		},
		mounted() {
            this.getContractsTableData(1);
			this.$root.$on('searchQuery', query => {
				if (query.length > 0)
					this.searchQuery = query
				else
					this.searchQuery = null
				this.getContractsTableData(1);
			})
		},
		data() {
			return {
				lastRequest: null,
				searchQuery: null,
				activityTableDataS: [],
				activityPaginationS: {
					total: 0,
					perPage: 1000,
					currentPage: 1
				},
				consultantFilterAS: null,
				statusFilter: null,
				contractsTableData: [],
				contractsPagination: {
					total: 0,
					perPage: 10000,
					currentPage: 1
				},
				periodFilter: null,
				period: {
					start: null,
					end: null
				},
				pointer: 'pointer',
				pointer2: 'default',
			};
		},
		methods: {
			setStatusFilter(value){
				this.statusFilter = value
				this.getContractsTableData(1)
			},
			getContractsTableData(page){
				let limit = this.contractsPagination.perPage
				let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_CONTRACTS_FA
				let request = {
					offset: limit*(page - 1),
					limit: limit,
				}
            if (this.periodFilter === 1) {
					let created = {gte: this.period.start}
					Object.assign(request, {created})
				}else if (this.periodFilter === 2){
					let created = {
						gt: this.period.start,//+"T12:00:00.000Z",
						lte: this.period.end//+"T12:00:00.000Z"
					}
					Object.assign(request, {created})
				}
			if (this.searchQuery) url += "?find=" + this.searchQuery
			if (this.statusFilter) Object.assign(request, JSON.parse('{"status": "'+this.statusFilter+'"}'))

					const lr = request
				if (lr === this.lastRequest) return
				this.lastRequest = lr;
				this.$showLoader(true)
				this.$axios.post(url,request).then((response) => {
					this.$showLoader(false)
					let data = response.data
					if (data[this.$v.API_SUCCESS]) {
						this.contractsTableData = data[this.$v.API_ROWS]
						let p = data[this.$v.API_ROW]
						this.contractsPagination.total = p['totalItems']
						this.contractsPagination.currentPage = page
						this.contractsPagination.perPage = limit
					}
				})
			},
			setPeriodFilter(value){
				if(value === 1){
                let date = new Date()
                let month = date.getMonth()+1
                if(month<10) month= "0"+month
                this.period.start = date.getFullYear()+"-"+month+"-"+"01"+"T12:00:00.000Z"
                }
                this.periodFilter = value
                this.getContractsTableData(1)
			}
		}
	};
</script>
<style></style>