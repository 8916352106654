<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">

                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">

                <template slot="columns">
                    <th v-on:click="selectColumn(0)" v-bind:style="{cursor:pointer}" class="text-center">ID Cliente<i :class="getIconClass(0)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(1)" v-bind:style="{cursor:pointer}">Cliente<i :class="getIconClass(1)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(2)" v-bind:style="{cursor:pointer}">Cuenta<i :class="getIconClass(2)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(3)" v-bind:style="{cursor:pointer}">Fecha de creación<i :class="getIconClass(3)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(4)" v-bind:style="{cursor:pointer}" class="text-center">Saldo<i :class="getIconClass(4)" style="margin-left: 6px"/></th>
                </template>

                <template slot-scope="{row}">
                    <th class="text-center" scope="row" v-bind:style="{cursor:pointer2}">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <a :href="'https://admin.dbmenos.com/client?id='+row.id" target="_blank">
                                    <span class="name mb-0 text-sm">{{row.id}}<i class="fas fa-user-edit text-primary"></i></span>
                                </a>
                            </div>
                        </div>
                    </th>
                    <td v-bind:style="{cursor:pointer2}">{{row.name}}</td>
                    <td v-bind:style="{cursor:pointer2}">{{row.account}}</td>
                    <td v-bind:style="{cursor:pointer2}">{{$moment(row.created).format("DD MMM YYYY, h:mm a")}}</td>
                    <td class="text-center">{{$formatCurrency(parseFloat(row.amount))}}</td>
                </template>

            </base-table>
        </div>
        <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} Saldos Finamigo
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'balance-visor-table',
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            banks() { return this.$store.state.banks },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService }
        },
        data() {
            return {
                isAdmin: false,
                currentColumnKey: null,
                sortedColumn: 0,
                freezeData: null,
                sortingDesc: true,
                showReportModal: false,
                pointer: 'pointer',
                pointer2: 'default'
            }
        },
        updated() {
            if (!this.freezeData && this.data) {
                this.freezeData = JSON.parse(JSON.stringify(this.data))
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
        },
        methods: {
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            sort() {
                switch (this.sortedColumn) {
                    case 0:
                        this.currentColumnKey = 'id'
                        break
                    case 1:
                        this.currentColumnKey = 'name'
                        break;
                    case 2:
                        this.currentColumnKey = 'account'
                        break
                    case 3:
                        this.currentColumnKey = 'created'
                        break
                    case 4:
                        this.currentColumnKey = 'amount'
                        break
                }
//                console.log(this.currentColumnKey)
                this.data.sort((a, b) => {
                    let _a = a[this.currentColumnKey]
                    let _b = b[this.currentColumnKey]
                    if (_a < _b) return this.sortingDesc ? -1 : 1
                    if (_a > _b) return this.sortingDesc ? 1 : -1
                    return 0
                })
//                console.log(this.data[0])
            },
            selectColumn(column) {
//                console.log(column + " selected")
              if (this.sortedColumn === column) {
                  this.sortingDesc = !this.sortingDesc
              } else {
                  this.sortedColumn = column
                  this.sortingDesc = true
              }
              if (this.sortedColumn != null)
                this.sort()
            },
            isUpdated(acc) {
                if (this.freezeData === null) return false
                let a = this.freezeData.find(a => a['account_id'] === acc['account_id'])
                if (a && a.discount)
                    return a.discount !== acc.discount
                return false
            },
            getIconClass(column) {
                let iconClass = "fas "
                if (column === this.sortedColumn) {
                    iconClass += this.sortingDesc ? "fa-arrow-down" : "fa-arrow-up"
                    iconClass += " text-danger"
                } else {
                    iconClass += "fa-arrows-alt-v"
                }
                return iconClass
            },
        }
    }
</script>
<style>
</style>
