<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''"
       v-bind:style="{cursor:pointer2}">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <!--div class="col text-right">
          <base-button type="primary" size="sm" @click="newActivity">Nuevo</base-button>
        </div-->
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data">
        <template slot="columns">
          <th>Estado trámite</th>
          <th>Tipo de comprobante de no adeudo</th>
          <th>Fecha del trámite</th>
          <th>Fecha de solicitud finiquito</th>
          <th>Comentarios</th>
          <th>Fecha</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.procedure_status}}</span>
              </div>
            </div>
          </th>
          <td class="text-center">{{row.voucher_type}}</td>
          <td class="text-center">{{$formatNormalDate(row.procedure_date)}}</td>
          <td class="text-center">{{$formatNormalDate(row.settlement_request_date)}}</td>
          <td class="text-center" :title="row.comments">{{row.comments}}</td>
          <td class="text-center">{{$formatDate(row.created)}}</td>
        </template>

      </base-table>
    </div>
    <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

  </div>
</template>
<script>
  export default {
    name: 'negotiation-table',
    props: {
      type: {
        type: String
      },
      title: String,
      data: Array,
      pagination: Object,
      completeBalance: Boolean,
      buttonSendSettlementLetter: Boolean,
    },
    data() {
      return {
        isAuditor: false,
        isService: false,
        isSeller: false,
        isManagerService: false,
        isAdmin: false,
        tableData: [],
        pointer: 'pointer',
        pointer2: 'default',
        alert: false,
      }
    },
    computed: {
      managerService() { return this.$store.state.managerService},
      user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      banks() { return this.$store.state.banks },
      sellers() { return this.$store.state.sellers },
      customerService() { return this.$store.state.customerService },
      debtActivityCatalog() { return this.$store.state.debtActivityCatalog }
    },
    created() {
      this.isSeller = this.user.role === this.$v.ROLES.SELLER;
      this.isService = this.user.role === this.$v.ROLES.SERVICE;
      this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
      this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    },
    methods: {
      pageSelected(page) {
          this.$emit("pageSelected", page);
      },
      /*newActivity() {
        this.$emit("newActivity")
      },*/
      getActivityType(id) {
        for (let i=0 ; i<this.debtActivityCatalog.length ; i++){
          if (this.debtActivityCatalog[i].id === id)
            return this.debtActivityCatalog[i].describe
        }
      },
      getString(s){
        if(!s) return '-'
        let comment = s.slice(0, 30)
        if (s.length>30) comment+='...';
        return comment
      },
      getConsultant(cid) {
        for (let i=0 ; i<this.customerService.length ; i++) {
          if (this.customerService[i].id === cid) {
            return this.customerService[i].name + " " + this.customerService[i].lastname_1
          }
        }
        for (let i=0 ; i<this.sellers.length ; i++) {
          if (this.sellers[i].id === cid) {
            return this.sellers[i].name + " " + this.sellers[i].lastname_1
          }
        }
        for (let i=0 ; i<this.managerService.length ; i++) {
          if (this.managerService[i].id === cid) {
            return this.managerService[i].name + " " + this.managerService[i].lastname_1
          }
        }
      },
    }
  }
</script>
<style>
</style>