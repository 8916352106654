<template>
    <div class="card shadow"
        :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
            </div>
        </div>
    
        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                        <!--:period="period"-->

                <template slot="columns" >
                  <th v-bind:style="{cursor:pointer2}">ID Deuda</th>
                  <th v-bind:style="{cursor:pointer2}">ECS</th>
                  <th v-bind:style="{cursor:pointer2}">Cliente</th>
                  <th v-bind:style="{cursor:pointer2}">Número de cuenta</th>
                  <th v-bind:style="{cursor:pointer2}">Banco</th>
                  <th v-bind:style="{cursor:pointer2}">Fecha límite de pago</th>
                  <th v-bind:style="{cursor:pointer2}">Fecha de liquidación</th>
                  <th v-bind:style="{cursor:pointer2}">Forma de Pago</th>
                  <th v-bind:style="{cursor:pointer2}">Total adeudado</th>
                  <th v-bind:style="{cursor:pointer2}">Pago Banco</th>
                  <th v-bind:style="{cursor:pointer2}">Monto Recibimos de FA</th>
                  <th v-bind:style="{cursor:pointer2}">Diferencia</th>
                </template>

                <template slot-scope="{row}">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span @click="debtSelected(row.id_debt)" class="name mb-0 text-primary text-sm">{{row.id_debt}}</span>
                      </div>
                    </div>
                  </th>
                  <td class="text-center">{{row.consultant_sc_name ? row.consultant_sc_name : 'Sin Asesor'}}</td>
                  <td class="text-center">{{row.name}}</td>
                  <td class="text-center">{{row.account}}</td>
                  <td class="text-center">{{row.bank}}</td>
                  <td class="text-center">{{row.limit_pay_date? $formatDate(row.limit_pay_date) : '---'}}</td>
                  <td class="text-center">{{row.settlement_paid_date? $formatDate(row.settlement_paid_date) : '---'}}</td>
                  <td class="text-center">{{row.method_pay ? row.method_pay : '---'}}</td>
                  <td class="text-center">{{$formatCurrency(row.total_amount)}}</td>
                  <td class="text-center">{{$formatCurrency(row.amount_pay_bank)}}</td>
                  <td class="text-center">{{row.recived_FA_amount ? row.recived_FA_amount : '---'}}</td>
                  <td class="text-center">{{row.difference ? row.difference : '---'}}</td>
                </template>

            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} deudas
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>
    </div>
</template>
<script>
//import flatPickr from 'vue-flatpickr-component';
//import 'flatpickr/dist/flatpickr.css';

export default {
        name: 'debt-settled-day-table',
        components:{
            ///flatPickr
        },
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            unassigned: Boolean,
            period: {
                start: null,
                end: null
            },
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            customerService() { return this.$store.state.customerService }
        },
        data() {
            return {
              isAdmin: false,
              isSeller: false,
              isManagerService: false,
              //currentColumnKey: null,
              //sortedColumn: 0,
              //s
              pointer: 'pointer',
              pointer2: 'default',
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
        },
        methods: {
            pageSelected(page) {
              let table=2;
              this.$emit("pageSelected", page, table);
            },
            debtSelected(client) {
                this.$emit("debtSelected", client);
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            },
        }
    }
</script>
<style>
</style>