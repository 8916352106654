<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8">
		</base-header>

		<div class="container-fluid mt--7" v-if="loader">
			<div class="row">
				<div class="col" v-if="!errorToken">
					<card style="margin-bottom:10px;">
						<h2>Se han aceptado los permisos al Asistente Virtual</h2>
						<p> Para poder ver, editar, compartir y borrar permanentemente todos los calendarios a los que puedes acceder mediante el Calendario de Google.</p>
						<h4> Puedes cerrar la ventana.</h4>
					</card>
			</div>
			<div class="col" v-if="errorToken">
					<card style="margin-bottom:10px;">
						<h2>Algo salio mal al aceptar los permisos al Asistente Virtual</h2>
						<p> Intenta nuevamente dando click en el boton *PERMISOS MATI*.</p>
						<h4> Puedes cerrar la ventana.</h4>
					</card>
			</div>
		</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'auth',
		components: {
		},
		mounted() {
			let code = this.$route.query.code;
			this.getToken(code);
		},
		data() {
			return {
				searchQuery: null,
				pointer: 'pointer',
				pointer2: 'default',
				errorToken:false,
				loader:false,
			};
		},
		methods: {
			getToken(code) {
      let url =
        this.$v.SERVER +
        this.$v.OAUTH_GOOGLE_OPS.GET_AUTH_TOKEN  + "?code=" + code;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
		  this.loader=true;
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_ROW].success) {
          let info = data[this.$v.API_ROW].response;
		  this.errorToken=false;
		  console.log(info)
        }else{
			this.errorToken=true;
		}
      });
    },
		}
	};
</script>
<style></style>