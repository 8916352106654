<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''"
       v-bind:style="{cursor:pointer2}">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data"
                  @click="itemSelected">
        <template slot="columns">
          <th>Usuario</th>
          <th>Consultor</th>
          <th>Duración</th>
          <th>Tipo</th>
          <th>Evento</th>
          <th>Fecha</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.user}}</span>
              </div>
            </div>
          </th>
          <td>{{getConsultant(row.consultant)}}</td>
          <td>{{String(row.duration/60) + " min."}}</td>
          <td>{{getTypeText(row.type)}}</td>
          <td>{{getEventText(row.event)}}</td>
          <td>{{formatDate(row.created)}}</td>
        </template>

      </base-table>
    </div>
    <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination
          size="sm"
          :total="pagination.total"
          :per-page="pagination.perPage"
          :value="pagination.currentPage"
          v-on:pageSelected="pageSelected"/>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'consultants-call-log-table',
    props: {
      type: {
        type: String
      },
      title: String,
      data: Array,
      pagination: Object,
      completeBalance: Boolean,
      buttonSendSettlementLetter: Boolean,
    },
    data() {
      return {
        isAuditor: false,
        isService: false,
        isSeller: false,
        isManagerService: false,
        isAdmin: false,
        pointer: 'pointer',
        pointer2: 'default'
      }
    },
    computed: {
      managerService() { return this.$store.state.managerService},
      user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      banks() { return this.$store.state.banks },
      sellers() { return this.$store.state.sellers },
      customerService() { return this.$store.state.customerService },
      debtActivityCatalog() { return this.$store.state.debtActivityCatalog }
    },
    created() {
      this.isSeller = this.user.role === this.$v.ROLES.SELLER;
      this.isService = this.user.role === this.$v.ROLES.SERVICE;
      this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
      this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    },
    methods: {
      pageSelected(page) {
          this.$emit("pageSelected", page);
      },
      itemSelected(item) {
        this.$emit("itemSelected",item)
      },
      getTypeText(type){
        switch (type){
          case 0: return 'Entrante terminada';
          case 1: return 'Saliente terminada';
          case 2: return 'Perdida';
        }
      },
      getEventText(type){
        switch (type){
          case 0: return 'Entrante';
          case 1: return 'Saliente';
        }
      },
      formatDate(date) {
            if (date <= "2020-11-10") {
                return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
            } else {
                return this.$moment(date).format("DD MMM YYYY, h:mm a");
            }
            },
      getConsultant(cid) {
        for (let i=0 ; i<this.customerService.length ; i++) {
          if (this.customerService[i].id === cid) {
            return this.customerService[i].name + " " + this.customerService[i].lastname_1
          }
        }
        for (let i=0 ; i<this.sellers.length ; i++) {
          if (this.sellers[i].id === cid) {
            return this.sellers[i].name + " " + this.sellers[i].lastname_1
          }
        }
        for (let i=0 ; i<this.managerService.length ; i++) {
          if (this.managerService[i].id === cid) {
            return this.managerService[i].name + " " + this.managerService[i].lastname_1
          }
        }
      },
    }
  }
</script>
<style>
</style>