<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col-lg-2">
          <base-dropdown style="width: 100%">
            <base-button slot="title" type="info" outline class="dropdown-toggle" style="width: 100%" size="sm">
              {{statusAuditFilter ? statusAuditFilter : 'Estatus Auditoría'}}
            </base-button>
            <a class="dropdown-item" @click="setStatusAuditFilter(null)">Mostrar todos</a>
            <a class="dropdown-item" @click="setStatusAuditFilter('SIN AUDITAR')">Sin Auditar</a>
            <a class="dropdown-item" @click="setStatusAuditFilter('PENDIENTE')">Pendiente</a>
            <a class="dropdown-item" @click="setStatusAuditFilter('ACTUALIZADO')">Actualizado</a>
            <a class="dropdown-item" @click="setStatusAuditFilter('COMPLETO')">Completo</a>
          </base-dropdown>
        </div>
        <div class="col-lg-2" >
          <base-dropdown style="width: 100%">
            <base-button slot="title" type="danger" outline class="dropdown-toggle" style="width: 100%" size="sm">
              {{highRiskFilter? 'Clientes alto riesgo' : 'Seleccionar'}}
            </base-button>
            <a class="dropdown-item" @click="setHighRiskFilter(null)">Mostrar todos</a>
            <a class="dropdown-item" @click="setHighRiskFilter(true)">Clientes alto riesgo</a>
          </base-dropdown>
        </div>
        <div class="col-lg-2">
          <base-dropdown style="width: 100%">
            <base-button slot="title" type="info" outline class="dropdown-toggle" style="width: 100%" size="sm">
              {{payRollFilter? 'Con Nómina' : 'Nómina'}}
            </base-button>
            <a class="dropdown-item" @click="setPayRollFilter(null)">Mostrar todos</a>
            <a class="dropdown-item" @click="setPayRollFilter(true)">Con Nómina</a>
          </base-dropdown>
        </div>
        <div class="col-lg-2">
          <base-dropdown style="width: 100%">
            <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
              {{getPeriodFilterText()}}
            </base-button>
            <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
            <div v-if="!showSetPeriod" class="dropdown-divider"></div>
            <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
          </base-dropdown>
        </div>
        <div v-if="showSetPeriod" class="col">
          <div class="row justify-content-center">
            <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
            <span class="small text-muted">-</span>
            <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
            <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive " v-bind:style="{cursor:pointer}">
      <base-table class="table align-items-center table-flush text-center"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data">

        <template slot="columns">
          <th v-bind:style="{cursor:pointer2}">ID</th>
          <th v-bind:style="{cursor:pointer2}">Usuario</th>
          <th v-bind:style="{cursor:pointer2}">Vendedor</th>
          <th v-if="isAdmin || isAuditor" v-bind:style="{cursor:pointer2}">Equipo</th>
          <!--<th v-bind:style="{cursor:pointer2}">MDI</th>!-->
          <!--<th v-bind:style="{cursor:pointer2}">Ahorro Realizado</th>!-->
          <!--th v-bind:style="{cursor:pointer2}">% Fee</th>
          <th v-bind:style="{cursor:pointer2}">Fee</th>
          <th v-bind:style="{cursor:pointer2}">+</th>
          <th v-bind:style="{cursor:pointer2}">Fee nuevo</th-->
          <th v-bind:style="{cursor:pointer2}">Pago</th>
          <th v-bind:style="{cursor:pointer2}">IF</th>
          <th v-bind:style="{cursor:pointer2}">IA</th>
          <th v-bind:style="{cursor:pointer2}">RFC</th>
          <!--<th v-bind:style="{cursor:pointer2}">CD</th>!-->
          <th v-bind:style="{cursor:pointer2}">BC</th>
          <th v-bind:style="{cursor:pointer2}">CURP</th>
          <th v-bind:style="{cursor:pointer2}">PROG</th>
          <th v-bind:style="{cursor:pointer2}">C1</th>
          <!--<th v-bind:style="{cursor:pointer2}">C2</th>
          <th v-bind:style="{cursor:pointer2}">CA</th>!-->
          <th v-bind:style="{cursor:pointer2}">Estatus de Auditoría</th>
          <template v-if="isAdmin || isAuditor">
            <!--<th>Guardar Cambios</th>!-->
            <th>Enviar notas</th>
            <!--th v-bind:style="{cursor:pointer2}">Documentos completos</th-->
            <th v-bind:style="{cursor:pointer2}">Fecha auditoría</th>
            <!--th v-bind:style="{cursor:pointer2}">H</th-->
            <th v-bind:style="{cursor:pointer2}">Fecha solución</th>
            <!--th v-bind:style="{cursor:pointer2}">Comentario</th>
            <th v-bind:style="{cursor:pointer2}">Segunda revisión</th>
            <th v-bind:style="{cursor:pointer2}">Anexo</th-->
          </template>
          <th v-bind:style="{cursor:pointer2}">Lista Nominal</th>
          <th v-bind:style="{cursor:pointer2}">Nómina</th>
          <th v-bind:style="{cursor:pointer2}">Carta</th>
          <th v-bind:style="{cursor:pointer2}">Alto riesgo</th>
          <th v-bind:style="{cursor:pointer2}">Documentos</th>
          <!--th v-bind:style="{cursor:pointer2}">Baja</th>
          <th v-bind:style="{cursor:pointer2}">Asignado</th-->
          <th v-bind:style="{cursor:pointer2}">Bienvenida Pendiente</th>
          <th v-bind:style="{cursor:pointer2}">Fecha</th>
        </template>
        <template slot-scope="{row}">
          <th scope="row" >
            <div class="media align-items-center">
              <div class="media-body static">
                <router-link v-if="isAdmin || isAuditor || isManagerService || isManagerSeller" target="_blank" :to="'/viewAuditProfile'+'?client=' + row.id">
                  <span class="name mb-0 text-sm">{{row.id}}<i class="fas fa-user-edit text-primary"></i></span>
                </router-link>
                <router-link v-if="isSeller" target="_blank" :to="'/client'+'?id=' + row.id">
                  <span class="name mb-0 text-sm">{{row.id}}<i class="fas fa-user-edit text-primary"></i></span>
                </router-link>
              </div>
            </div>
          </th>
          <td class="text-center">{{row.user}}</td>
          <td class="text-center">{{row.consultant ? row.consultant : 'Sin Consultor'}}</td>
          <td v-if="isAdmin || isAuditor" class="text-center">{{row.team ? row.team : 'Sin Team Leader'}}</td>
          <!--<td class="text-center">{{$formatCurrency(row.mdi)}}</td>
          <td class="text-center">{{$formatCurrency(row.contribution)}}</td>
          td class="text-center">{{row.percentage_fee}}</td>
          <td class="text-center">{{$formatCurrency(row.fee)}}</td>
          <td class="text-center">{{$formatCurrency(row.plus)}}</td>
          <td class="text-center">{{$formatCurrency(row.new_fee)}}</td-->
          <td class="text-center">{{row.payment}}</td>
              <td class="text-center" :class="row.if_id == '-1' ? 'text-danger' : ''">{{row.if_id ? getTypeAuditor('IF',row.if_id) : 'SIN AUDITAR'}}</td>
              <td class="text-center" :class="row.ir == '-1'  ? 'text-danger' : ''">{{row.ir ? getTypeAuditor('IR',row.ir) : 'SIN AUDITAR'}}</td>
              <td class="text-center" :class="row.rfc == '-1' ? 'text-danger' : ''">{{row.rfc ? getTypeAuditor('RFC',row.rfc) : 'SIN AUDITAR'}}</td>
              <!--<td class="text-center" :class="row.cd == '-1' ? 'text-danger' : ''">{{row.cd ? getTypeAuditor('CD',row.cd) : 'SIN AUDITAR'}}</td>!-->
              <td class="text-center" :class="row.rc == '-1' ? 'text-danger' : ''">{{row.rc ? getTypeAuditor('RC',row.rc) : 'SIN AUDITAR'}}</td>
              <td class="text-center" :class="row.curp == '-1' ? 'text-danger' : ''">{{row.curp ? getTypeAuditor('CURP',row.curp) : 'SIN AUDITAR'}}</td>
              <td class="text-center" :class="row.prog == '-1' ? 'text-danger' : ''">{{row.prog ? getTypeAuditor('PROG',row.prog) : 'SIN AUDITAR'}}</td>
              <td class="text-center" :class="row.c1 == '-1' ? 'text-danger' : ''">{{row.c1 ? getTypeAuditor('C1',row.c1) : 'SIN AUDITAR'}}</td>
             <!-- <td class="text-center" :class="row.c2 == '-1' ? 'text-danger' : ''">{{row.c2 ? getTypeAuditor('C2',row.c2) : 'SIN AUDITAR'}}</td>
              <td class="text-center" :class="row.ca == '-1' ? 'text-danger' : ''">{{row.ca ? getTypeAuditor('CA',row.ca) : 'SIN AUDITAR'}}</td>!-->
          <td class="text-center">{{row.complete_docs}}</td>
          <template v-if="isAdmin || isAuditor">
            <!--<td>
              <span>
                <base-button type="outline-success" class="p-2" size="sm" style="margin-bottom:0.5rem; margin-left:0.5rem;" v-on:click="applyAudit(row.id)">
                  Aplicar auditoría
                </base-button>
              </span>
            </td>!-->
            <td>
              <span>
                <base-button type="outline-primary" class="p-2" size="sm" style="margin-bottom:0.5rem; margin-left:0.5rem;" v-on:click="sendDocComments(row)">
                  Enviar notas
                </base-button>
              </span>
            </td>
            <!--td class="text-center">{{row.complete_docs}}</td-->
            <td v-if="!row.audit_date" class="text-center">{{"SIN AUDITAR"}}</td>
            <td v-else class="text-center">{{$formatDate(row.audit_date)}}</td>
            <!--td class="text-center">{{row.h}}</td-->
            <td v-if="!row.resolve_date" class="text-center">{{"SIN AUDITAR"}}</td>
            <td v-else class="text-center">{{row.resolve_date == 0 ? 'PENDIENTE' : $formatDate(row.resolve_date)}}</td>
            <!--td class="text-center">{{row.comment}}</td>
            <td class="text-center">{{row.verify2}}</td>
            <td class="text-center">{{row.annexed}}</td-->
          </template>
          <td v-if="row.filename_nominal_list==null" class="text-center">{{"NO TIENE LISTA NOMINAL CARGADA"}}</td>
          <td v-else-if="row.filename_nominal_list">
            <a v-if="row.filename_nominal_list" :href="getFileUrl(row.filename_nominal_list, row.id)" target="_blank">
              <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
            </a>
          </td>
          <td class="text-center" @click.stop>
            <base-checkbox :checked="Boolean(row.payroll)" disabled/>
          </td>
          <td v-if="row.letter==0" class="text-center">{{"NO TIENE LA CARTA DE NOMINA CARGADA EN EL SITE"}}</td>
          <td v-else-if="row.letter">
            <a v-if="row.letter" :href="getFileUrl(row.letter, row.id)" target="_blank">
              <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
            </a>
          </td>
          <td v-else class="text-center">-</td>
          <td class="text-center" @click.stop>
            <base-checkbox :checked="Boolean(row.high_risk)" disabled/>
          </td>
          <td class="text-center">
            <base-dropdown class="dropdown">
              <a slot="title" class="btn btn-sm" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{row.documents? row.documents:'--'}}
              </a>
              <template>
                <a v-for="(option) in auditorCatalog.HIGH" :key="option.id" class="dropdown-item" v-on:click="applyAuditHigh(row,option)">{{option.value}}</a>
              </template>
            </base-dropdown>
          </td>
          <!--td class="text-center" @click.stop>
            <base-checkbox :checked="Boolean(row.leave_a)"/>
          </td-->
          <!--td class="text-center">{{row.assigned}}</td-->
          <td :title="row.welcom_pending">{{getString(row.welcom_pending)}}</td>
          <td class="text-center">{{$formatDate(row.firstPaymentDate)}}</td>

        </template>
      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="col">
        <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
          Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} Clientes
        </p>
      </div>
      <base-pagination
          size="sm"
          :total="pagination.total"
          :per-page="pagination.perPage"
          :value="pagination.currentPage"
          v-on:pageSelected="pageSelected"/>
    </div>

    <modal :show.sync="auditModal">
      <template slot="header">
        <h1 class="modal-title" id="auditModal">{{modalType}}: {{rowEdit.user}} ID:{{rowEdit.id}}</h1>
      </template>
      <div class="row">
        <div class="card-profile-stats d-flex justify-content-around mb-4" style="width: 100%">
          <base-dropdown>
            <base-button slot="title" type="info" class="dropdown-toggle">
              {{ buttonText? buttonText:'Seleccionar' }}
            </base-button>
            <a class="dropdown-item" v-for="(value, index) in catalog" :key="index" v-on:click="setOption(value)">
              {{ value.value }}
            </a>
          </base-dropdown>
        </div>
      </div>
      <div class="text-center" v-if="modalType === 'RFC'">
        <span class="heading">{{$formatNormalDate(rowEdit.birthday)}}</span><br>
        <span class="description">Fecha de Nacimiento</span><br>
        <span class="heading">{{rowEdit.noRFC}}</span><br>
        <span class="description">RFC</span>
      </div>
      <div class="text-center" v-else-if="modalType === 'RC'">
        <div v-if="report">
          <credit-report-table style="margin-top: 20px" v-if="lengthsCreditReports.dbmAccounts>0"
                               title = "Cuentas que se pueden agregar al programa"
                               :data="report && report['dbmAccounts'] ? report['dbmAccounts'] : []"
                               :pagination="CRPagination"/>
          <div v-else class="text-center">
            <div class="card-profile-stats d-flex justify-content-center">
              <h6 class="heading-small text-muted mb-4">No se encontaron cuentas que se puedan agregar al programa</h6>
            </div>
          </div>

          <credit-report-table style="margin-top: 20px" v-if="lengthsCreditReports.otherAccounts>0"
                               title = "Otras cuentas"
                               :data="report && report['otherAccounts'] ? report['otherAccounts'] : []"
                               :pagination="CRPagination"/>
          <div v-else class="text-center">
            <div class="card-profile-stats d-flex justify-content-center">
              <h6 class="heading-small text-muted mb-4">No se encontaron otras cuentas</h6>
            </div>
          </div>
        </div>
        <div v-else class="text-center">
          <div class="card-profile-stats d-flex justify-content-center">
            <div>
              <h6 class="heading-small text-muted mb-4">No se encontró un reporte de crédito</h6>
              <span class="font-weight-bold ml-1 text-warning">{{getMessageReport(messageReport)}}</span><br>
              <span class="description">status</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="img-center text-center">
          <img :src="getFileUrl(fileurl,rowEdit.id)" style="max-height: 700px; max-width: 700px; margin-bottom:10px;"/>
          <br>
          <a v-if="getFileUrl(fileurl, rowEdit.id)" :href="getFileUrl(fileurl, rowEdit.id)" target="_blank">
            <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
          </a>
        </div>
      </div>
      <template slot="footer">
        <base-button class="btn-sm" type="primary" @click="auditModal=false">
          OK
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import CreditReportTable from './CreditReportTable';

export default {
  name: 'auditor-full-view-table',
  components: {
    flatPickr,
    CreditReportTable
  },
  props: {
    type: {
      type: String
    },
    title: String,
    data: Array,
    pagination: Object,
    period: {
      start: null,
      end: null
    },
  },
  data() {
    return {
      isManagerService: false,
      isManagerSeller: false,
      isAdmin: false,
      isAuditor: false,
      isSeller: false,
      isService: false,
      tableData: [],
      consultantFilter: null,
      methodPaymentFilter: null,
      validationPaymentFilter: null,
      showSetPeriod: false,
      periodFilter: null,
      payRollFilter: null,
      statusAuditFilter: null,
      highRiskFilter: null,
      pointer: 'pointer',
      pointer2: 'default',

      rowEdit: {},
      rowUpdate: {},
      auditModal: false,
      modalType: null,
      buttonText: '',
      fileurl: '',
      catalog: null,

      //Credit Report
      report: {},
      messageReport: '',
      reportByBC: false,
      CRPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      lengthsCreditReports:{
        dbmAccounts: null,
        otherAccounts: null
      },
      selectedIF: [],
      selectedIR: [],
      selectedRFC: [],
      selectedCD: [],
      selectedCURP: [],
      selectedRC: [],
      selectedPROG: [],
      selectedC1: [],
      selectedC2: [],
      selectedCA: [],
      binaryArray: [1,2,4,8,16,32,64,128,256,512,1024,2048,4096,8192,16384],
    }

  },
  created() {
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
    this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR
    this.isSeller = this.user.role === this.$v.ROLES.SELLER
    this.isService = this.user.role === this.$v.ROLES.SERVICE
  },
  computed: {
    user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
    customerService() { return this.$store.state.customerService },
    sellers() { return this.$store.state.sellers },
    auditorCatalog() { return this.$store.state.auditorCatalog },
  },
  methods: {
     decimal2Binary(decimal, marks){
    let binary = 1
    for(let i=0; i<marks.length; i++){
        if(decimal & binary)
            marks[i] = binary
        binary *= 2
    }
    return marks
},
        getTypeAuditor(type,id) {
    let marksIF= [0, 0, 0, 0, 0, 0, 0, 0, 0]
    let marksIR = [0, 0, 0, 0]
    let marksRFC =[0, 0, 0, 0]
    let marksCD = [0,0,0,0,0,0,0,0,0,0,0]
    let marksRC = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    let marksCURP = [0, 0, 0, 0]
    let marksPROG = [0, 0, 0]
    let marksC1 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    let marksC2 = [0,0,0,0,0,0,0,0,0,0,0]
    let marksCA = [0,0,0,0,0,0,0,0,0,0]
                let response=" ";
                if(id == "-1") {return 'AUDITAR NUEVAMENTE'}
                if(id == "1") {return 'Ok'}
                switch (type){
                    case 'IF':
                    marksIF=this.decimal2Binary(Number(id), marksIF)
                    for(let i=0;i<marksIF.length;i++){
                    for (let j=0 ; j<this.auditorCatalog.IF.length ; j++){
                      if (this.auditorCatalog.IF[j].value_int == marksIF[i]){
                        response += this.auditorCatalog.IF[j].value + ", "; 
                      } 
                    }
                    }
                    return response;
                    case 'IR':
                    marksIR=this.decimal2Binary(Number(id), marksIR)
                    for(let i=0;i<marksIR.length;i++) {
                    for (let j=0 ; j<this.auditorCatalog.IR.length ; j++){
                    if (this.auditorCatalog.IR[j].value_int == marksIR[i]) {
                          response+=this.auditorCatalog.IR[j].value + ", "; 
                        }
                      }
                    }
                    return response;
                    case 'RFC':
                    marksRFC=this.decimal2Binary(Number(id), marksRFC)
                    for(let i=0;i<marksRFC.length;i++) {
                    for (let j=0 ; j<this.auditorCatalog.RFC.length ; j++){
                    if (this.auditorCatalog.RFC[j].value_int == marksRFC[i]) { 
                      response+=this.auditorCatalog.RFC[j].value + ", "; 
                        }
                      }
                    }
                    return response;
                    case 'CD':
                    marksCD=this.decimal2Binary(Number(id), marksCD)
                    for(let i=0;i<marksCD.length;i++) {
                    for (let j=0 ; j<this.auditorCatalog.CD.length ; j++){
                    if (this.auditorCatalog.CD[j].value_int == marksCD[i]) {
                      response+=this.auditorCatalog.CD[j].value + ", "; 
                    }
                    }
                    }
                    return response;
                    case 'RC':
                    marksRC=this.decimal2Binary(Number(id), marksRC)
                    for(let i=0;i<marksRC.length;i++) {
                    for (let j=0 ; j<this.auditorCatalog.RC.length ; j++){
                    if (this.auditorCatalog.RC[j].value_int == marksRC[i]){
                      response+=this.auditorCatalog.RC[j].value + ", "; 
                    }
                    }
                    }
                    return response;
                    case 'CURP':
                    marksCURP=this.decimal2Binary(Number(id), marksCURP)
                    for(let i=0;i<marksCURP.length;i++) {
                    for (let j=0 ; j<this.auditorCatalog.CURP.length ; j++){
                    if (this.auditorCatalog.CURP[j].value_int == marksCURP[i]){ 
                      response+=this.auditorCatalog.CURP[j].value + ", "; 
                    }
                    }
                    }
                    return response;
                    case 'PROG':
                    marksPROG=this.decimal2Binary(Number(id), marksPROG)
                    for(let i=0;i<marksPROG.length;i++) {
                    for (let j=0 ; j<this.auditorCatalog.PROG.length ; j++){
                    if (this.auditorCatalog.PROG[j].value_int == marksPROG[i]){ 
                      response+=this.auditorCatalog.PROG[j].value + ", "; 
                    }
                    }
                    }
                    return response;
                    case 'C1':
                    marksC1=this.decimal2Binary(Number(id), marksC1)
                    for(let i=0;i<marksC1.length;i++) {
                    for (let j=0 ; j<this.auditorCatalog.C1.length ; j++){
                    if (this.auditorCatalog.C1[j].value_int == marksC1[i]){
                       response+=this.auditorCatalog.C1[j].value + ", "; 
                    }
                    }
                    }
                    return response;
                    case 'C2':
                    marksC2=this.decimal2Binary(Number(id), marksC2)
                    for(let i=0;i<marksC2.length;i++) {
                    for (let j=0 ; j<this.auditorCatalog.C2.length ; j++){
                    if (this.auditorCatalog.C2[j].value_int == marksC2[i]){
                     response+=this.auditorCatalog.C2[j].value + ", "; 
                    }
                    }
                    }
                    return response;
                    case 'CA':
                    marksCA=this.decimal2Binary(Number(id), marksCA)
                    for(let i=0;i<marksCA.length;i++) {
                    for (let j=0 ; j<this.auditorCatalog.CA.length ; j++){
                    if (this.auditorCatalog.CA[j].value_int == marksCA[i]){ 
                      response+=this.auditorCatalog.CA[j].value + ", "; 
                    }
                    }
                    }
                    return response;
                }
            },
    pageSelected(page) {
      this.$emit("pageSelected", page);
    },
    setPeriod(){
      this.showSetPeriod = true
      this.period.start=null
      this.period.end=null
      this.getPeriodFilterText()
    },
    setStatusAuditFilter(value){
      this.statusAuditFilter = value;
      this.$forceUpdate();
      this.$emit("setStatusAuditFilter", value);
    },
    setHighRiskFilter(value){
      this.highRiskFilter = value;
      this.$forceUpdate();
      this.$emit("setHighRiskFilter", Boolean(value));
    },
    setPayRollFilter(value){
      this.payRollFilter = value;
      this.$forceUpdate();
      this.$emit("setPayRollFilter", Boolean(value));
    },
    setPeriodFilter(value) {
      if(value!=2){
        this.showSetPeriod = false
        this.periodFilter = value
        this.$emit("setPeriodFilter", value);

      }else if(this.period.start && this.period.end){
        this.periodFilter = value
        this.$emit("setPeriodFilter", value);
      }
    },
    getPeriodFilterText() {
      if(this.showSetPeriod)
        return "Periodo del: "
      else if(this.periodFilter === 1)
        return "Mes actual"
      else return "Fecha"
    },
    docSelected(row, type) {
      if((row.id !== this.rowEdit.id && this.rowEdit.id)
          || (!this.isAdmin && !this.isAuditor)) return
      this.rowEdit = row;
      this.rowUpdate.user = row.id;
      this.modalType=type;
      this.getModalType();
      this.auditModal=true;
    },
    getString(s){
      if(!s) return '-'
      let comment = s.slice(0, 30)
      if (s.length>30) comment+='...';
      return comment
    },
    sendDocComments(row){
      let listComments = 'Comentarios: '
          +'\nIF: '+(row.if?row.if:'Ok')
          + '\nIR: '+(row.ir?row.ir:'Ok')
          + '\nRFC: '+(row.rfc?row.rfc:'Ok')
          + '\nCD: '+(row.cd?row.cd:'-')
          + '\nCURP: '+(row.curp?row.curp:'Ok')
          + '\nRC: '+(row.rc?row.rc:'-')
          + '\nPROG: '+(row.prog?row.prog:'Ok')
          + '\nC1: '+(row.c1?row.c1:'Ok')
          + '\nC2: '+(row.c2?row.c2:'Ok')
          + '\nCA: '+(row.ca?row.ca:'Ok')

      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.SEND_COMMENTS_AUDIT_SELLERS;
      let request  = {
        consultant: row.consultant_id,
        user: row.id,
        comments: listComments
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    getFileUrl(userFile, user) {
      let session = JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.SESSION))
      if (session) {
        return this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_FILE + "?user=" + user + "&filename=" +
            userFile + "&redirect=true" + "&session=" + session[this.$v.SESSION]
      }
    },
    setOption(v){
      let value = v.value
      this.buttonText = value;
      switch (this.modalType){
        case 'IF': this.rowEdit.if_id = value;
          this.rowUpdate.if_id = v.id;
          break;
        case 'IR': this.rowEdit.ir = value;
          this.rowUpdate.ir = v.id;
          break;
        case 'RFC': this.rowEdit.rfc = value;
          this.rowUpdate.rfc = v.id;
          break;
        case 'CD': this.rowEdit.cd = value;
          this.rowUpdate.cd = v.id;
          break;
        case 'RC': this.rowEdit.rc = value;
          this.rowUpdate.rc = v.id;
          break;
        case 'CURP': this.rowEdit.curp = value;
          this.rowUpdate.curp = v.id;
          break;
        case 'PROG': this.rowEdit.prog = value;
          this.rowUpdate.prog = v.id;
          break;
        case 'C1': this.rowEdit.c1 = value;
          this.rowUpdate.c1 = v.id;
          break;
        case 'C2': this.rowEdit.c2 = value;
          this.rowUpdate.c2 = v.id;
          break;
        case 'CA': this.rowEdit.ca = value;
          this.rowUpdate.ca = v.id;
          break;
      }
    },
    getModalType(){
      switch (this.modalType){
        case 'IF':
          this.catalog = this.auditorCatalog.IF;
          this.buttonText = this.rowEdit.if_id;
          this.fileurl = this.rowEdit.filename_IF;
          break;
        case 'IR':
          this.catalog = this.auditorCatalog.IR;
          this.buttonText = this.rowEdit.ir;
          this.fileurl = this.rowEdit.filename_IR;
          break;
        case 'RFC':
          this.catalog = this.auditorCatalog.RFC;
          this.buttonText = this.rowEdit.rfc;
          this.fileurl = null;
          break;
        case 'CD':
          this.catalog = this.auditorCatalog.CD;
          this.buttonText = this.rowEdit.cd;
          this.fileurl = this.rowEdit.filename_cd;
          break;
        case 'RC':
          this.catalog = this.auditorCatalog.RC;
          this.buttonText = this.rowEdit.rc;
          this.fileurl = null;
          this.getCreditReport(this.rowEdit.id)
          break;
        case 'CURP':
          this.catalog = this.auditorCatalog.CURP;
          this.buttonText = this.rowEdit.curp;
          this.fileurl = this.rowEdit.filename_curp;
          break;
        case 'PROG':
          this.catalog = this.auditorCatalog.PROG;
          this.buttonText = this.rowEdit.prog;
          this.fileurl = this.rowEdit.filename_quote;
          break;
        case 'C1':
          this.catalog = this.auditorCatalog.C1;
          this.buttonText = this.rowEdit.c1;
          this.fileurl = this.rowEdit.filename_contract;
          break;
        case 'C2':
          this.catalog = this.auditorCatalog.C2;
          this.buttonText = this.rowEdit.c2;
          this.fileurl = this.rowEdit.filename_contract_fa;
          break;
        case 'CA':
          this.catalog = this.auditorCatalog.CA;
          this.buttonText = this.rowEdit.ca;
          this.fileurl = this.rowEdit.filename_manifest_fa;
          break;
      }
    },
    getCreditReport(user) {
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_CREDIT_REPORT + "?user=" + user
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.report = data.row.credit_report ? data.row.credit_report : data.row.accounts;
          this.messageReport = data.row.message ? data.row.message: data.row.report.status;
          this.lengthsCreditReports.dbmAccounts = this.report.dbmAccounts.length;
          this.lengthsCreditReports.otherAccounts = this.report.otherAccounts.length;
        }
      });
    },
    getMessageReport(message){
      if(!message) return "-"
      if(message.length>50) return "Contactar con soporte"
      return message
    },
    applyAudit(){
      this.$showLoader(true)
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.SET_AUDITOR_USERS;
      if(!this.rowUpdate.user) {
        
                        this.$vs.notification(this.$notification(
                'warning',
                 'Sin cambios por guardar'
                     ));
        this.$showLoader(false)
      }
      this.$axios.post(url, this.rowUpdate).then((response) => {
      this.$showLoader(false)
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
          this.$forceUpdate();
          this.rowEdit = {};
          this.rowUpdate = {};
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    applyAuditHigh(row,option){
      console.log(option.id)
      let id=row.id
      this.$showLoader(true)
      row.documents = option.value;
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.SET_AUDITOR_USERS;
      console.log(this.rowEdit)
      let request={
        user:id,
        documents:option.id
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false)
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    }
  }
}
</script>
<style>
.static {
  position: absolute;
}
</style>