<template>
  <div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<audit-pending-validated-table
							title="Validaciones pendientes"
							:data="tableData"
							:period="period"
							:pagination="pagination"
              @setPaymentsNotValidatedFilter="setPaymentsNotValidatedFilter"
              @setContractsUpdateMonthFilter="setContractsUpdateMonthFilter"
							v-on:setPeriodFilter="setPeriodFilter"
							v-on:pageSelected="getDataList"/>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import AuditPendingValidatedTable from './Tables/AuditPendingValidatedTable'
	export default {
		name: 'tables',
		components: {
      AuditPendingValidatedTable
		},
		created() {
			this.getDataList(1)
		},
		computed: {
			user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
		},
		data() {
			return {
				pagination: {
					total: 0,
					perPage: 10,
					currentPage: 1
				},
				period: {
					start: null,
					end: null
				},
				periodFilter: null,
        contractsUpdateMonthFilter: 1,
        paymentsNotValidatedFilter: null,
				tableData: [],
				pointer: 'pointer',
				pointer2: 'default'
			};
		},
		methods: {
			getDataList(page) {
				let limit = this.pagination.perPage;
				let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_AUDIT_PENDING_VALIDATED;
				let request = {
					offset: limit * (page - 1),
					limit: limit,
				};
				if (this.periodFilter === 1) {
					request.created = {gte: this.period.start}
				}else if (this.periodFilter === 2){
					request.created = {
						gt: this.period.start,
						lte: this.period.end
					}
				}
				if(this.contractsUpdateMonthFilter)
          request.contract_update = { gte: this.$moment().startOf("month").format('YYYY-MM-DD') }
        if(this.paymentsNotValidatedFilter)
          request.payments_not_validated = {gte: 1}
				this.$showLoader(true);
				this.$axios.post(url, request).then((response) => {
					this.$showLoader(false);
					let data = response.data;
					if (data[this.$v.API_SUCCESS]) {
						let p = data[this.$v.API_ROW];
						this.tableData = data[this.$v.API_ROWS];
						this.pagination.total = p["totalItems"];
						this.pagination.currentPage = page;
						this.pagination.perPage = limit;
					} else this.$validateSession(data)
				});
			},
      setPaymentsNotValidatedFilter(value){
        this.paymentsNotValidatedFilter=value;
        this.getDataList(1)
      },
      setContractsUpdateMonthFilter(value){
        this.contractsUpdateMonthFilter=value;
        this.getDataList(1)
      },
			setPeriodFilter(value) {
        if(value === 1)
          this.period.start = this.$moment().startOf("month").format('YYYY-MM-DD')
        this.periodFilter = value
        this.getDataList(1)
			}
		}
	};
</script>
<style></style>