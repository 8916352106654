<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row" hidden>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Usuarios registrados"
                      type="gradient-red"
                      v-bind:sub-title="String(generalInfo.users)"
                      class="mb-4 mb-xl-0">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i>Pendiente</span>
              <span class="text-nowrap">En el último mes</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Clientes activos"
                      type="gradient-orange"
                      v-bind:sub-title="String(generalInfo.clients)"
                      class="mb-4 mb-xl-0">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i>Pendiente</span>
              <span class="text-nowrap">En el último mes</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Deuda ingresada"
                      type="gradient-info"
                      v-bind:sub-title="this.$formatCurrency(generalInfo.totalDebt)"
                      class="mb-4 mb-xl-0">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> Pendiente</span>
              <span class="text-nowrap">En el último mes</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Deuda negociada"
                      type="gradient-green"
                      v-bind:sub-title="this.$formatCurrency(generalInfo.negotiated)"
                      class="mb-4 mb-xl-0">

            <template slot="footer">
              <span class="text-danger mr-2"><i
                  class="fa fa-arrow-down"></i> {{
                  this.$formatCurrency(generalInfo.totalDebt - generalInfo.negotiated)
                }}</span>
              <span class="text-nowrap">Ahorrados</span>
            </template>
          </stats-card>

        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col" v-bind:style="{cursor:pointer2}" v-if="!isManagerService && !isService">
          <clients-table
              :title="program === 'inactive' ? 'Usuarios DB Menos' : program === 'active' ? 'Clientes activos DB Menos' : unassigned ? 'Clientes sin consultor asignado' : program === 'graduated' ? 'Clientes graduados DB Menos' : 'Todos los usuarios' "
              :data="tableData"
              :period="period"
              :pagination="pagination"
              :unassigned="unassigned"
              v-on:setPeriodFilter="setPeriodFilter"
              v-on:pageSelected="getClientList"
              v-on:clientSelected="clientSelected"
              v-on:typeClients="typeClients"
              v-on:typeClientsStep="typeClientsStep"
              v-on:newClient="clientSelected({})"
              v-on:setConsultantFilter="setConsultantFilter"
              v-on:setSelfClientFilter="setSelfClientFilter"
              v-on:setStatusFilter="setStatusFilter"
              v-on:setNominaFilter="setNominaFilterClient"
              v-on:setFilter="setFilter"/>
        </div>
        <div v-else class="col" v-bind:style="{cursor:pointer2}">
          <clients-table-info-service v-if="isService && !isAuditor" :pagination="pagination" :data="tableData"/>
          <clients-table-service title="Clientes DB Menos"
                                 :data="tableData"
                                 :pagination="pagination"
                                 v-on:pageSelected="getClientList"
                                 v-on:clientSelected="clientSelected"
                                 v-on:setMDIFilter="setMDIFilter"
                                 v-on:setTypeClient="setTypeClient"
                                 v-on:setNominaFilter="setNominaFilter"
                                 v-on:setDueMonthsFilter="setDueMonthsFilter"
                                 v-on:setTraceFilter="setTraceFilter"/>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import ClientsTable from './Tables/ClientsTable'
import ClientsTableInfoService from './Tables/ClientsTableInfoService'
import ClientsTableService from './Tables/ClientsTableService'

export default {
  name: 'tables',
  components: {
    ClientsTable,
    ClientsTableInfoService,
    ClientsTableService
  },
  mounted() {
    this.$root.$on('searchQuery', query => {
      if (query.length > 0) {
        this.searchQuery = query
        this.getClientList(1)
        console.log(this.searchQuery)
      } else {
        this.searchQuery = null
        console.log(this.searchQuery)
      }
    })
    //this.program = this.$route.query.program
    if (this.$route.query.unassigned === "true") {
      this.unassigned = true
    } else {
      this.unassigned = false
    }
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER)
    },
    generalInfo() {
      return this.$store.state.generalInfo
    }
  },
  data() {
    return {
      lastRequest: null,
      isAdmin: false,
      isService: false,
      isAuditor: false,
      isSeller: false,
      isManagerService: false,
      program: null,
      unassigned: null,
      pagination: {
        total: 0,
        perPage: 10,
        currentPage: 1
      },
      period: {
        start: null,
        end: null
      },
      tableData: [],
      searchQuery: null,
      clientFilter: null,
      consultantFilter: null,
      selfClients: true,
      MDIFilter: null,
      NominaFilter: null,
      NominaFilterClients: null,
      dueMonthsFilter: null,
      traceFilter: null,
      statusFilter: null,
      pointer: 'pointer',
      pointer2: 'default',
      programStep: null,
      textTypeClient: null,
    };
  },
  async created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
    this.isService = this.user.role === this.$v.ROLES.SERVICE || this.user.role === this.$v.ROLES.AUDITOR
    this.isSeller = this.user.role === this.$v.ROLES.SELLER
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
    this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR

    this.getClientList(1)
  },
  methods: {
    clientSelected(client) {
      console.log(client)
      let query = null
      if (client.hasOwnProperty('id') && client.id > 0) {
        window.open("https://admin.dbmenos.com/clientSeller?id=" + client.id, '_blank');
      } else {
        if (this.isSeller) {

          this.$router.push({
            name: 'clientSeller',
            query: query
          })
        } else {

          this.$router.push({
            name: 'cliente',
            query: query
          })
        }
      }
    },
    getClientList(page) {
      let limit = this.pagination.perPage
      let endpoint = !this.isManagerService && !this.isService ? this.$v.CONSULTANT_OPS.GET_SELL_PROFILES : this.$v.SERVICE_OPS.GET_V_PROFILE_SC
      let url = this.$v.SERVER + endpoint
      if (this.searchQuery) url += "?find=" + this.searchQuery
      let requestS = {
        offset: limit * (page - 1),
        limit: limit,
      }
      if (this.program !== null) {
        switch (this.program) {
          case 'Prospecto':
            Object.assign(requestS, JSON.parse('{"status": "Prospecto"}'))
            break;
          case 'Activo':
            Object.assign(requestS, JSON.parse('{"status": "Activo"}'))
            break;
          case 'Graduado':
            Object.assign(requestS, JSON.parse('{"status": "Graduado"}'))
            break;
          case 'Baja':
            Object.assign(requestS, JSON.parse('{"status": "Baja"}'))
            break;
          case 'Reactivado':
            Object.assign(requestS, JSON.parse('{"status": "Reactivado"}'))
            break;
        }
      }
      /*if (this.programStep !== null) {
          switch (this.programStep) {
              case 'dont_step':
                  Object.assign(requestS, JSON.parse('{"step":"null"}' ))
                  break;
              case 'step_basic_information':
                  Object.assign(requestS, JSON.parse('{"step":"step_basic_information"}' ))
                  break;
              case 'step_basic_information2':
                  Object.assign(requestS, JSON.parse('{"step":"step_basic_information2"}'))
                  break;
              case 'step_address_information':
                  Object.assign(requestS, JSON.parse('{"step":"step_address_information"}'))
                  break;
              case 'step_official_id':
                  Object.assign(requestS, JSON.parse('{"step":"step_official_id"}'))
                  break;
              case 'step_credit_report_signature':
                  Object.assign(requestS, JSON.parse('{"step":"step_credit_report_signature"}'))
                  break;
              case 'step_credit_report':
                  Object.assign(requestS, JSON.parse('{"step":"step_credit_report"}'))
                  break;
              case 'step_program_calculation':
                  Object.assign(requestS, JSON.parse('{"step":"step_program_calculation"}'))
                  break;
              case 'step_complete_profile':
                  Object.assign(requestS, JSON.parse('{"step":"step_complete_profile"}'))
                  break;
              case 'step_contract':
                  Object.assign(requestS, JSON.parse('{"step":"step_contract"}'))
                  break;
              case 'step_active':
                  Object.assign(requestS, JSON.parse('{"step":"step_active"}'))
                  break;
          }
      }*/
      if (this.periodFilter === 1) {
        let created = {gt: this.period.start}
        Object.assign(requestS, {created})
      }
      if (this.periodFilter === 2) {
        let created = {
          gt: this.period.start + "T12:00:00.000Z",
          lte: this.period.end + "T12:00:00.000Z"
        }
        requestS = Object.assign(requestS, {created})
      }
      if (this.unassigned) requestS = Object.assign(requestS, JSON.parse('{"consultant": "null"}'))
      if (this.NominaFilterClients) requestS = Object.assign(requestS, JSON.parse('{"payroll_same_bank": ' + this.NominaFilterClients + '}'))
      if (this.clientFilter) requestS = Object.assign(requestS, JSON.parse('{"contract": ' + this.clientFilter + '}'))
      if (this.isSeller && this.selfClients) Object.assign(requestS, JSON.parse('{"consultant": ' + this.user.id + '}'))
      else if (this.isSeller && !this.selfClients) requestS.consultant = 'null';
      if (this.consultantFilter && !this.unassigned) Object.assign(requestS, JSON.parse('{"consultant": ' + this.consultantFilter + '}'))

      if (this.statusFilter !== null) {
        if (this.statusFilter === 'Sin seguimiento') {
          Object.assign(requestS, {trace_status: "notEmpty"})
        } else {
          Object.assign(requestS, {trace_status: this.statusFilter})
        }
      }

      const lr = requestS
      if (lr === this.lastRequest) return
      this.lastRequest = lr;

      this.$showLoader(true)
      if (!this.isService && !this.isManagerService) {
        this.$axios.post(url, requestS).then(response => {
          this.$showLoader(false)
          let data = response.data

          if (data[this.$v.API_SUCCESS]) {

            if (this.user.role === this.$v.ROLES.MANAGER_SELLER) {

              let arr = [];

              for (const item of data[this.$v.API_ROWS]) {
                if (item.consultant_name !== 'dbmenos bot') {
                  if (item.consultant !== null) {
                    arr.push(item)
                  }
                }
              }

              this.tableData = arr;
              let p = data[this.$v.API_ROW]
              this.pagination.total = p['totalItems']
              this.pagination.currentPage = page
              this.pagination.perPage = limit

            } else {
              this.tableData = data[this.$v.API_ROWS]
              let p = data[this.$v.API_ROW]
              this.pagination.total = p['totalItems']
              this.pagination.currentPage = page
              this.pagination.perPage = limit
            }

          } else {
            this.$validateSession(data)
          }
        })
      } else {
        limit = 500;
        let request = {
          offset: limit * (page - 1),
          limit: limit
        }
        if (this.isService && !this.searchQuery && !this.isAuditor) Object.assign(request, {consultant: this.user.id})
        if (this.MDIFilter) request = Object.assign(request, JSON.parse(this.MDIFilter))
        if (this.NominaFilter) request = Object.assign(request, JSON.parse(this.NominaFilter))
        if (this.dueMonthsFilter) request = Object.assign(request, JSON.parse(this.dueMonthsFilter))
        if (this.textTypeClient) request = Object.assign(request, JSON.parse(this.textTypeClient))
        if (this.traceFilter) request = Object.assign(request, JSON.parse(this.traceFilter))
        this.$axios.post(url, request).then(response => {
          this.$showLoader(false)
          let data = response.data
          if (data[this.$v.API_SUCCESS]) {
            this.tableData = data[this.$v.API_ROWS]
            let p = data[this.$v.API_ROW]
            this.pagination.total = p['totalItems']
            this.pagination.currentPage = page
            this.pagination.perPage = limit
          } else {
            this.$validateSession(data)
          }
        })
      }
    },
    setFilter(value) {
      this.clientFilter = value
      this.getClientList(1)
    },
    setNominaFilterClient(value) {
      this.NominaFilterClients = value
      this.getClientList(1)
    },
    setConsultantFilter(value) {
      this.consultantFilter = value
      this.getClientList(1)
    },
    setSelfClientFilter(value) {
      this.selfClients = value
      this.getClientList(1)
    },
    typeClients(typeC) {
      this.unassigned = typeC === 'unassigned' ? true : false
      this.program = typeC
      this.getClientList(1)
    },
    typeClientsStep(typeC) {
      this.unassigned = typeC === 'unassigned' ? true : false
      this.programStep = typeC
      this.getClientList(1)
    },
    setStatusFilter(value) {
      this.statusFilter = value
      this.getClientList(1)
    },
    setPeriodFilter(value) {
      if (value === 1) {
        let date = new Date()
        let month = date.getMonth() + 1
        if (month < 10) month = "0" + month
        this.period.start = date.getFullYear() + "-" + month + "-" + "01" + "T12:00:00.000Z"
      }
      this.periodFilter = value
      this.getClientList(1)
    },
    setNominaFilter(json) {
      this.NominaFilter = '{"payroll_same_bank":' + json + '}'
      console.log(this.NominaFilter)
      this.getClientList(1)
    },
    setMDIFilter(json) {
      this.MDIFilter = '{"total_debt":' + json + '}'
      this.getClientList(1)
    },
    setDueMonthsFilter(json) {
      this.dueMonthsFilter = '{"duePaymentCount":' + json + '}'
      this.getClientList(1)
    },
    setTypeClient(json) {
      this.textTypeClient = '{"inactiveReason":' + json + '}';
      this.getClientList(1)
    },
    setTraceFilter(json) {
      this.traceFilter = '{"last_trace":' + json + '}'
      this.getClientList(1)
    }
  }
};
</script>
<style></style>