<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col-lg-3 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" style="width: 100%" size="sm">
                            {{getCustomerFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setConsultantFilter(null)">Mostrar todos</a>
                        <div class="dropdown-divider"></div>
                        <a v-for="(customerS) in customerService" :key="customerS.id" class="dropdown-item" v-on:click="setConsultantFilter(customerS.id)">{{customerS.name}} {{customerS.lastname_1}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-3 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="info" outline class="dropdown-toggle" style="width: 100%" size="sm">
                            {{getTypeAddendums()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setTypeFilter(null)">Mostrar todos</a>
                        <div class="dropdown-divider"></div>
                        <a v-for="(typeA) in typeAddendums" :key="typeA.type" class="dropdown-item" v-on:click="setTypeFilter(typeA.type)">{{typeA.type}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="success" outline class="dropdown-toggle" style="width: 100%" size="sm">
                            {{getStatusAddendums()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setStatusFilter(null)">Mostrar todos</a>
                        <div class="dropdown-divider"></div>
                        <a v-for="(statusA) in statusAddendums" :key="statusA.status" class="dropdown-item" v-on:click="setStatusFilter(statusA.status)">{{statusA.status}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getPeriodFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
                        <div v-if="!showSetPeriod" class="dropdown-divider"></div>
                        <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
                    </base-dropdown>
                </div>                
                <div v-if="showSetPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
                        <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        v-on:click="addendumSelected">

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">ID Addendum</th>
                    <th v-bind:style="{cursor:pointer2}">ID Cliente</th>
                    <th v-bind:style="{cursor:pointer2}">Estatus</th>
                    <th v-bind:style="{cursor:pointer2}">Nombre Cliente</th>
                    <th v-bind:style="{cursor:pointer2}">Asesor DBM</th>
                    <th v-bind:style="{cursor:pointer2}">Motivo del Addendum</th>
                    <th v-bind:style="{cursor:pointer2}">MDI Anterior</th>
                    <th v-bind:style="{cursor:pointer2}">MDI Addendum</th>
                    <th v-bind:style="{cursor:pointer2}">Plazo Anterior</th>
                    <th v-bind:style="{cursor:pointer2}">Plazo Addendum</th>
                    <th v-bind:style="{cursor:pointer2}">%Fee Anterior</th>
                    <th v-bind:style="{cursor:pointer2}">%Fee Addendum</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha de Creación</th>
                    <th v-bind:style="{cursor:pointer2}">Ahorro Mensual Anterior</th>
                    <th v-bind:style="{cursor:pointer2}">Ahorro Mensual Addendum</th>
                    <th v-bind:style="{cursor:pointer2}">Comisión Mensual Anterior</th>
                    <th v-bind:style="{cursor:pointer2}">Comisión Mensual Addendum</th>
                </template>

                <template slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-primary text-sm">{{row.addendum_id}}</span>
                            </div>
                        </div>
                    </th>
                    <td >{{row.user}}</td>
                    <td >{{row.status}}</td>
                    <td >{{row.name}}</td>
                    <td >{{row.consultant_name}}</td>
                    <td >{{row.addendum_details}}</td>
                    <td>{{$formatCurrency(row.last_mdi)}}</td>
                    <td>{{$formatCurrency(row. mdi_new)}}</td>
                    <td >{{row.last_period ? row.last_period + ' meses' : '---'}}</td>
                    <td >{{row.period_new}} meses</td>
                    <td>{{formatDecPost(row.last_commission_percentage)}} %</td>
                    <td>{{formatDecPost(row.commission_percentage_new)}} %</td>
                    <td>{{$formatDate(row.created)}}</td>
                    <td>{{$formatCurrency(row.last_monthly)}}</td>
                    <td>{{$formatCurrency(row. monthly_new)}}</td>
                    <td>{{$formatCurrency(row.last_monthly_commission)}}</td>
                    <td>{{$formatCurrency(row. monthly_commission_new)}}</td>
                </template>

            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} clientes
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    export default {
        name: 'addendums-history-table',
        components:{
            flatPickr
        },
        props: {
            type: {
                type: String
            },
            title: String,
            amount: Number,
            data: Array,
            pagination: Object,
            period: {
                start: null,
                end: null
            },
        },
        data() {
            return {
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                isAdmin: false,
                filter: null,
                consultantFilter: null,
                showSetPeriod:false,
                periodFilter: null,
                selfClients: true,
                typeUsers: '',
                textMDIFilter: "",
                textNominaFilter: "",
                widthProgressBar: null,
                titleProgressBar: "",
                isAuditor: false,
                eventFilter: null,
                typeUserStep:null,
                isManagerSeller:false,
                newTrace: {
                    type: null,
                    id: 0
                },
                typeFilter:null,
                statusFilter:null
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
            this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER
            this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService },
            leadStatus() { return this.$store.state.leadStatus },
            userHistoryEvents() { return this.$store.state.userHistoryEvents },
            typeAddendums() {return this.$store.state.typeAddendums},
            statusAddendums() {return this.$store.state.statusAddendums},
        },
        methods: {
            getCustomerFilterText() {
                if (this.consultantFilter) return this.getConsultantName(this.consultantFilter)
                return "Consultores"
            },
            
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            },
            getTypeAddendums(){
                if(!this.typeFilter) return "Mótivo Addendums"
                else return this.typeFilter
            },
            getStatusAddendums(){
                if(!this.statusFilter) return "Estatus"
                else return this.statusFilter
            },
            setConsultantFilter(value) {
                this.consultantFilter = value
                this.$emit("setConsultantFilter", value);
            },
            setTypeFilter(value) {
                this.typeFilter = value
                this.$emit("setTypeFilter", value);
            },
            setStatusFilter(value) {
                this.statusFilter = value
                this.$emit("setStatusFilter", value);
            },
            getPeriodFilterText() {
                if(this.showSetPeriod)
                    return "Periodo del: "
                else if(this.periodFilter === 1)
                    return "Mes actual"
                else return "Fecha"
            },
             setPeriodFilter(value) {        
                if(value!=2){
                    this.showSetPeriod = false
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                    
                }else if(this.period.start && this.period.end){
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                }
            },
            setPeriod(){
                this.showSetPeriod=true
                this.period.start=null
                this.period.end=null
                this.getPeriodFilterText()                
            },
            formatDecPost(val, pos) {
            if (!pos) pos = 2;
            if (val && Number(val)) return Number(val).toFixed(pos);
            return "0.00";
            },
            addendumSelected(addendum) {
                this.$emit("addendumSelected", addendum);
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            onNewItem() {
                this.$emit("onNewItem")
            }
        }
    }
</script>
<style>
</style>