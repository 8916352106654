<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col" v-bind:style="{cursor:pointer2}">
          <bucket-leads-table title="Bucket Leads"
            :fase="1"
            :period="period"
            :data="tableData"
            :pagination="pagination"
            @pageSelected="getLeadList"
            @leadSelected="leadSelected"
            @toClient="toClient"
            @setPeriodFilter="setPeriodFilter"
            @setSellerFilter="setSellerFilter"
            @setStatusFilter="setStatusFilter"/>
          <br>
          <bucket-leads-table title="Bucket Leads"
            :fase="2"
            :period="period"
            :data="tableData2"
            :pagination="pagination2"
            @pageSelected="getLeadList"
            @leadSelected="leadSelected"
            @toClient="toClient"
            @setPeriodFilter="setPeriodFilter"
            @setSellerFilter="setSellerFilter"
            @setStatusFilter="setStatusFilter"/>
        </div>
      </div>
    </div>

    <modal :show.sync="tracingModal" v-on:close="tracingModal=false" v-bind:large="true">
      <template slot="header">
        <h2 class="mb-0" id="TracingLead" style="margin-top: 20px; margin-left: 10px">{{selectedLead.name}}</h2>
      </template>
      <div>
        <leads-tracing-table title="Seguimiento"
          :disabledNew="true"
          :data="TracingTableData"/>
      </div>
    </modal>
  </div>
</template>
<script>
import BucketLeadsTable from './Tables/BucketLeadsTable'
import LeadsTracingTable from './Tables/LeadsTracingTable'

export default {
  name: 'tables',
  components: {
    BucketLeadsTable,
    LeadsTracingTable
  },
  mounted() {
    this.$root.$on('searchQuery', query => {
      if (query.length > 0)
        this.searchQuery = query
      else
        this.searchQuery = null
      //this.getLeadList(1,'1')
      //this.getLeadList(1,'2')
    })
  },
  computed: {
    user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
  },
  data() {
    return {
      lastRequest: {},
      searchQuery: null,
      pagination: {
        total: 0,
        perPage: 10,
        currentPage: 1
      },
      tableData: [],
      pagination2: {
        total: 0,
        perPage: 10,
        currentPage: 1
      },
      tableData2: [],
      tracingModal: false,
      TracingTableData: [],
      selectedLead: {},
      pointer: 'pointer',
      pointer2: 'default',

      isSeller:false,
      isManagerSeller:false,

      //Filtros
      periodFilter: null,
      period: {
        start: null,
        end: null
      },
      statusFilter: null,
      sellerFilter: null,
    };
  },
  async created() {
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    //this.getLeadList(1,'1');
    //this.getLeadList(1,'2');
  },
  methods: {
    async getLeadList(page, fase=1) {
      let limit = this.pagination.perPage
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_LEADS
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        seller: 998,
        status_code: {in: fase==1?[1,2,16,null]:[5,6,4,7,10,8]}
      }

      if (this.statusFilter !== null) {
        request.status_code = this.statusFilter==-1? 'null' : this.statusFilter;
      } else if (this.followFilter !== null) request.follow = this.followFilter
      if (this.periodFilter === 1) {
        let created = {gte: this.period.start}
        Object.assign(request, {created})
      }
      if (this.periodFilter === 2){
        let created = {
          gte: this.period.start,
          lte: this.period.end
        }
        request = Object.assign(request, {created})
      }
      if (this.searchQuery) request.find = this.searchQuery
      if (this.sellerFilter) request.seller = this.sellerFilter;

      const lr = request
      if (lr === this.lastRequest) return
      this.lastRequest = lr;

      this.$showLoader(true)
      let response = await this.$axios.post(url, request)
      this.$showLoader(false)
      let data = response.data
      if (data[this.$v.API_SUCCESS] && fase==1) {
        this.tableData = data[this.$v.API_ROWS]
        let p = data[this.$v.API_ROW]
        this.pagination.total = p['totalItems']
        this.pagination.currentPage = page
        this.pagination.perPage = limit
        this.$forceUpdate();
      } else if(data[this.$v.API_SUCCESS] && fase==2){
        this.tableData2 = data[this.$v.API_ROWS];
        let p = data[this.$v.API_ROW]
        this.pagination2.total = p['totalItems']
        this.pagination2.currentPage = page
        this.pagination2.perPage = limit
        this.$forceUpdate();
      } else { this.$validateSession(data) }
    },
    leadSelected(lead) {
      this.TracingTableData = []
      this.selectedLead = lead
      this.tracingModal = true
      this.downloadLeadTracing()
    },
    toClient(lead) {
      this.selectedLead = lead;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_FROM_LEAD + "?lead=" + lead.id
      this.$showLoader(true)
      this.$axios.get(url).then(response => {
        this.$showLoader(false)
        let data = response.data
        if (data[this.$v.API_SUCCESS]) {
          let clientID = data[this.$v.API_ROW]
          if (clientID) {
            window.open("https://admin.dbmenos.com/client?id=" + clientID, "_blank");
          }
        } else {
          this.$vs.notification(this.$notification('info', 'El lead no tiene perfil de cliente'))
        }
      })
    },
    downloadLeadTracing() {
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_SELL_TRACING //+ "?user=" + this.selectedLead.id
      let request={
        lead: this.selectedLead.id
      }
      this.$showLoader(true)
      this.$axios.post(url,request).then(response => {
        this.$showLoader(false)
        if (response.data[this.$v.API_SUCCESS]) {
          this.TracingTableData = response.data[this.$v.API_ROWS]
        }
      })
    },
    //Filtros
    setPeriodFilter(value, fase) {
      if(value === 1){
        let date = new Date()
        let month = date.getMonth()+1
        if(month<10) month= "0"+month
        this.period.start = date.getFullYear()+"-"+month+"-"+"01T00:00:00.000"
      }
      this.periodFilter = value
      this.getLeadList(1, fase)
    },
    setSellerFilter(value, fase) {
      this.sellerFilter = value
      this.getLeadList(1, fase)
    },
    setStatusFilter(value, fase) {
      this.statusFilter = value
      this.getLeadList(1, fase)
    },
  }
};
</script>
<style></style>