<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8" />

    <div class="container-fluid mt--8">
      <div class="row">
        <div class="col-12" v-bind:style="{ cursor: pointer2 }">
          <commissions-table
            title="Comisiones Cobradas"
            :allCommissions="true"
            :data="commissionTableData"
            v-on:commissionSelected="commissionSelected"
            @searchData="searchData2"
            @newModification="newModification"
            :pagination="commissionPagination"
            v-on:pageSelected="getAllCommissions"
            style="margin-top: 20px"
          />
        </div>
      </div>
    </div>
    <modal
      :show.sync="commisionModal"
      @close="closeCommissionModal()"
      :large="true"
    >
      <template slot="header">
        <div class="row">
          <div class="col">
            <h2
              class="mb-0"
              id="acountModal"
              style="margin-top: 20px; margin-left: 10px"
            >
              Editar comisión: ID {{ commissionInModal.id }}
            </h2>

            <h2
              class="mb-0"
              id="acountModal"
              style="margin-top: 5px; margin-left: 10px"
            >
              Usuario: ID {{ commissionInModal.user }}
            </h2>
          </div>
        </div>
      </template>
      <div class="row" style="margin-top: -15px; padding: 5px">
        <div class="col">
          <base-input
            alternative
            label="Monto de Comisión"
            input-classes="form-control-alternative"
            v-model="commissionInModal.amount"
          />
        </div>
        <div class="col">
          <label class="form-control-label">Fecha de Cobro</label>
          <flat-pickr
            v-model="commissionInModal.date"
            class="form-control form-control-alternative"
            data-vv-as="Fecha"
            name="date"
            style="background-color: #ffffff"
          />
        </div>
      </div>

      <template slot="footer">
        <div class="align-self-start mr-auto">
          <base-button type="secondary" v-on:click="closeCommissionModal()"
            >Cancelar</base-button
          >
        </div>
        <base-button
          type="primary"
          v-on:click="updateCommission(commissionInModal)"
          >Guardar cambios</base-button
        >
      </template>
    </modal>

    <modal
      :show.sync="modificationModal"
      @close="modificationModal == false"
      :large="true"
    >
      <template slot="header">
        <h2 class="mb-0" style="margin-top: 20px; margin-left: 10px">
          Modificando {{`${modificationType}: ${modificationData.last_id}` }}
        </h2>
      </template>
      <base-input
        alternative
        label="Comentario"
        placeholder="Escribe un comentario..."
        v-model="modificationData.comments"
      />
      <template slot="footer">
        <base-button
          v-if="modificationType != 'Reporte de Crédito'"
          type="primary"
          @click="setDeleteHistory()"
          >Aplicar</base-button
        >
        <base-button
          v-if="modificationType === 'Reporte de Crédito'"
          type="primary"
          @click="setDeleteCreditReport()"
          >Actualizar</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import CommissionsTable from "./Tables/CommissionsTable";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "tables",
  components: {
    CommissionsTable,
    flatPickr,
  },
  mounted() {
    this.$root.$on("searchQuery", (query) => {
      if (query.length > 0) {
        this.searchQuery = query;
        this.getCommissions(1);
        this.getAllCommissions(1);
      } else {
        this.searchQuery = null;
        this.getAllCommissions(1);
        this.getCommissions(1);
      }
    });

    this.getCommissions(1);
    this.getAllCommissions(1);
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
  },
  data() {
    return {
      tableData: [],
      modificationData: {},
      modificationModal: false,
      modificationType: "",
      prevDel: false,
      pointer: "pointer",
      pointer2: "default",
      searchQuery: null,
      commisionModal: false,
      searchQueryCobrar: null,
      searchQueryCommisiones: null,
      commissionTableData: [],
      commissionInModal: {},
      commissionPagination: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
      pagination: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
    };
  },
  methods: {
    newModification(data, typeMod) {
      this.modificationType = typeMod.name;
      this.modificationModal = true;
      this.modificationData.last_id = data.id;
      this.modificationData.account = data.account;
      this.modificationData.type = typeMod.id;
      this.modificationData.user = data.user;
      this.modificationData.file_type = 'COMMISSIONS';
      delete data.id;
      delete data.user;
      this.modificationData.json_details = JSON.parse(JSON.stringify(data));
    },
    setDeleteHistory() {
      let url = this.$v.SERVER + this.$v.MANAGER_OPS.SET_DELETE_HISTORY;
      this.$showLoader(true);
      this.$axios.post(url, this.modificationData).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          switch (this.modificationData.type) {
            case 11:
              this.getAllCommissions(1);
              break;
          }
          this.$vs.notification(this.$notification(
                'success',
                 'Eliminación exitosamente'
                     ));
          this.$forceUpdate();
        }
      });
      this.modificationModal = false;
      this.modoficationData = null;
    },
    updateCommission() {
      let url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_COMMISSION;
      this.$showLoader(true);
      this.$axios.post(url, this.commissionInModal).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Actualizacion exitosamente'
                     ));
          this.getAllCommissions(1);
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        }
      });
      this.commisionModal = false;
      this.commissionInModal = {};
    },
    closeCommissionModal() {
      this.commisionModal = false;
      this.commissionInModal = {};
      this.prevDel = false;
    },
    async commissionSelected(com) {
      this.commisionModal = true;
      this.commissionInModal = {
        id: com.id,
        amount: com.amount,
        user: com.user,
        date: this.$moment(com.date)._d,
      };
    },
    searchData(e2) {
      if (e2) {
        let url =
          this.$v.SERVER +
          this.$v.SELLER_OPS.GET_VIEW_CREATE_COMMISSION +
          "?find=" +
          e2;
        this.$showLoader(true);
        this.$axios.post(url).then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.tableData = data[this.$v.API_ROWS];
          } else {
            this.$validateSession(data);
          }
        });
      } else {
        this.getCommissions(1);
       this.$vs.notification(this.$notification(
                'danger',
                 'Añade lo que deseas buscar'
                     ));
      }
    },
    searchData2(e) {
      if (e) {
        let url =
          this.$v.SERVER +
          this.$v.SELLER_OPS.GET_USER_COMMISSIONS +
          "?find=" +
          e;
        this.$showLoader(true);
        this.$axios.post(url).then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.commissionTableData = data[this.$v.API_ROWS];
          } else {
            this.$validateSession(data);
          }
        });
      } else {
        this.getAllCommissions(1);
        this.$vs.notification(this.$notification(
                'danger',
                 'Añade lo que deseas buscar'
                     ));
      }
    },
    getCommissions(page) {
      let limit = this.pagination.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_VIEW_CREATE_COMMISSION;
      this.$showLoader(true);
      let request = {
        offset: limit * (page - 1),
        limit: limit,
      };
      if (this.searchQuery) url += "?find=" + this.searchQuery;
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.tableData = data[this.$v.API_ROWS];
          this.pagination.total = p["totalItems"];
          this.pagination.currentPage = page;
          this.pagination.perPage = limit;
        } else {
          this.$validateSession(data);
        }
      });
    },
    getAllCommissions(page) {
      let limit = this.commissionPagination.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_COMMISSIONS;
      if (this.searchQuery) url += "?find=" + this.searchQuery;
      let requestC = {
        offset: limit * (page - 1),
        limit: limit,
      };
      let date = {
        gte: this.$moment()
          .startOf("month")
          .add(10, "days")
          .format("YYYY-MM-DD"),
      };
      Object.assign(requestC, { date: date });
      this.$showLoader(true);
      this.$axios.post(url, requestC).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.commissionTableData = data[this.$v.API_ROWS];
          this.commissionPagination.total = p["totalItems"];
          this.commissionPagination.currentPage = page;
          this.commissionPagination.perPage = limit;
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
  },
};
</script>
<style></style>