<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor: pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        v-on:click="accountSelected">
                <template slot="columns">
                    <th class="text-center" v-bind:style="{cursor:pointer2}">Cambio</th>
                    <th class="text-center" v-bind:style="{cursor:pointer2}">Valor anterior</th>
                    <th class="text-center" v-bind:style="{cursor:pointer2}">Nuevo valor</th>
                    <th class="text-center" v-bind:style="{cursor:pointer2}">Actualizado por</th>
                    <th class="text-center" v-bind:style="{cursor:pointer2}">Fecha</th>
                </template>

                <template slot-scope="{row}">
                    <th scope="row">
                        <div class="text-center">
                            {{getTraceStatus(row.status)}}
                        </div>
                    </th>
                    <td>
                        <div class="text-center">
                            {{row.old_value}}
                        </div>
                    </td>
                    <td>
                        <div class="text-center">
                            {{row.new_value}}
                        </div>
                    </td>
                    <td>
                        <div class="text-center">
                            {{getConsultant(row.upload_by)}}
                        </div>
                    </td>
                    <td class="text-center">{{getDateText(row.created)}}</td>
                </template>

            </base-table>

        </div>

        <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'account-trace-table',
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object
        },
        data() {
            return {
                isSeller: false,
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default'
            }
        },
        computed: {
            banks() { return this.$store.state.banks },
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            accountTrace() { return this.$store.state.accountTraceStatus },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService },
            managerService() { return this.$store.state.managerService },
            admins() {return this.$store.state.admins },
        },
        async created() {
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
        },
        methods: {
            getTraceStatus(id) {
                for (let i=0 ; i<this.accountTrace.length ; i++) {
                    if (this.accountTrace[i].id === id) {
                        return this.accountTrace[i].status
                    }
                }
            },
            showDetails(acc) {
                this.$emit("showDetails", acc);
            },
            emitPaidModal(acc) {
                this.$emit("showPaidModal", acc);
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            getBanks(array) {
                let banks = []
                for (let a=0 ; a<=array.length ; a++) {
                    for (let b = 0; b < this.banks.length; b++) {
                        if (this.banks[b].id === array[a]) {
                            banks.push(this.banks[b])
                            break
                        }
                    }
                }
                return banks
            },
            formatDate(date) {
            if (date <= "2020-11-10") {
                return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
            } else {
                return this.$moment(date).format("DD MMM YYYY, h:mm a");
            }
            },
            onNewItem() {
                this.$emit("onNewItem")
            },
            onSave() {
                this.$emit("onSave")
            },
            setEditMode(row, edit) {
                for (let i=0 ; i<this.tableData.length ; i++)
                    this.tableData[i].editMode = false
                row.editMode = edit
                this.$forceUpdate()
            },
            getDateText(acc) {
                return acc ? this.$formatDate(acc) : 'No asignado'
            },
            accountSelected(acc) {
               this.$emit("accountSelected", acc)
            },
            getConsultant(cid) {
                for (let i=0 ; i<this.customerService.length ; i++) {
                    if (this.customerService[i].id === cid) {
                        return this.customerService[i].name + " " + this.customerService[i].lastname_1
                    }
                }
                for (let i=0 ; i<this.managerService.length ; i++) {
                    if (this.managerService[i].id === cid) {
                        return this.managerService[i].name + " " + this.managerService[i].lastname_1
                    }
                }
                for (let i=0 ; i<this.admins.length ; i++) {
                    if (this.admins[i].id === cid) {
                        return this.admins[i].name + " " + this.admins[i].lastname_1
                    }
                }
                for (let i=0 ; i<this.sellers.length ; i++) {
                    if (this.sellers[i].id === cid) {
                        return this.sellers[i].name + " " + this.sellers[i].lastname_1
                    }
                }
            },
        }
    }
</script>
<style>
</style>
