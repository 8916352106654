<template>
    <div class="card-header" :class="type === 'dark' ? 'bg-transparent': ''">
        <div class="row">
            <div class="col-auto" style="border-radius: 50px;">
                <table class="round_table table table-sm align-items-center">
                    <tr>
                        <th colspan="2" rowspan="2" class="bg-info">{{user.name}}</th>
                        <th colspan="3" class="bg-info">Contacto</th>
                    </tr>
                    <tr>
                        <td class="table-success">0 a 37</td>
                        <td class="table-warning">38 a 60</td>
                        <td class="table-danger">> 60</td>
                    </tr>
                    <tr>
                        <th rowspan="3" class="bg-info">Dep</th>
                        <td class="table-success text-center">0 a 37</td>
                        <td class="table-success text-center" title="N1">{{N1}}</td>
                        <td class="table-warning text-center" title="N2A">{{N2A}}</td>
                        <td class="table-danger text-center" title="N3A">{{N3A}}</td>
                    </tr>
                    <tr>
                        <td class="table-warning text-center">38 a 60</td>
                        <td class="table-warning text-center" title="N2B">{{N2B}}</td>
                        <td class="table-warning text-center" title="N2C">{{N2C}}</td>
                        <td class="table-danger text-center" title="N3B">{{N3B}}</td>
                    </tr>
                    <tr>
                        <td class="table-danger text-center">> 60</td>
                        <td class="table-danger text-center" title="N3C">{{N3C}}</td>
                        <td class="table-danger text-center" title="N3D">{{N3D}}</td>
                        <td class="table-danger text-center" title="N3E">{{N3E}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-auto">
                <table class="round_table table table-sm align-items-center">
                    <tr>
                        <th class="bg-info">Tipo</th>
                        <th class="bg-info">Cartera</th>
                        <th class="bg-info">Faltante</th>
                        <th class="bg-info">Meta</th>
                    </tr>
                    <tr>
                        <td class="table-active text-center">N1</td>
                        <td class="table-active text-center">{{carteraN1+"%"}}</td>
                        <td class="table-active text-center">{{(-(serviceMetaN1-carteraN1)/100*totalClients).toFixed()}}</td>
                        <td style="width: 130px;" class="table-active text-center">
                            <div class="row align-items-center">
                                <base-input type="number" style="width: 100px; height: 15px; padding-left: 15px;" v-model="serviceMetaN1"/>%
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-active text-center">N2</td>
                        <td class="table-active text-center">{{carteraN2+"%"}}</td>
                        <td class="table-active text-center">{{(-(serviceMetaN2-carteraN2)/100*totalClients).toFixed()}}</td>
                        <td style="width: 130px;" class="table-active text-center">
                            <div class="row align-items-center">
                                <base-input type="number" style="width: 100px; height: 15px; padding-left: 15px;" v-model="serviceMetaN2"/>%
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-active text-center">N3</td>
                        <td class="table-active text-center">{{carteraN3+"%"}}</td>
                        <td class="table-active text-center">{{(-(serviceMetaN3-carteraN3)/100*totalClients).toFixed()}}</td>
                        <td style="width: 130px;" class="table-active text-center">
                            <div class="row align-items-center">
                                <base-input type="number" style="width: 100px; height: 15px; padding-left: 15px;" v-model="serviceMetaN3"/>%
                            </div>
                        </td>
                    </tr>
                    <!--tr>
                        <td class="table-active text-center">N3Baja</td>
                        <td class="table-active text-center" title="N3C">{{---}}</td>
                        <td class="table-active text-center" title="N3D">{{---}}</td>
                        <td class="table-active text-center" title="N3E">{{---}}</td>
                    </tr-->
                </table>
            </div>
            <div class="col-auto">
                <table class="round_table table table-sm align-items-center">
                    <!--tr>
                        <th class="bg-info">Bienvenida pendiente</th>
                        <td class="table-active">{{"--"}}</td>
                    </tr-->
                    <tr>
                        <th class="bg-info">Contacto hoy</th>
                        <td class="table-active">{{lastTracingToday}}</td>
                    </tr>
                    <tr>
                        <th class="bg-info">Depósito hoy</th>
                        <td class="table-active">{{lastPaymentToday}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-auto">
                <table class="round_table table table-sm align-items-center">
                    <tr>
                        <th class="bg-info">Promesa</th>
                        <th class="bg-info"># de Cts</th>
                    </tr>
                    <tr>
                        <th class="table-active">Confirmar</th>
                        <td class="table-active">{{confirm}}</td>
                    </tr>
                    <tr>
                        <th class="table-active">Vigente</th>
                        <td class="table-active">{{valid}}</td>
                    </tr>
                    <tr>
                        <th class="table-active">Vencida</th>
                        <td class="table-active">{{expired}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'clients-table-info-service',
    computed: {
        user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
        sellers() { return this.$store.state.sellers },
        customerService() { return this.$store.state.customerService },
        data1() { return this.data}
    },
    data() {
        return {
            tableData: [],
            expired: 0,
            confirm: 0,
            serviceMetaN1: 0,
            serviceMetaN2: 0,
            serviceMetaN3: 0,
            totalClients: 0,
            lastTracingToday: 0,
            lastPaymentToday: 0,
            valid: 0,
            carteraN1: 0,
            carteraN2: 0,
            carteraN3: 0,
            N1: 0,
            N2A: 0,
            N3A: 0,
            N2B: 0,
            N2C: 0,
            N3B: 0,
            N3C: 0,
            N3D: 0,
            N3E: 0,
        }
    },
    async created(){
        this.getClientList()
    },
    methods: {
        getClientList() {
            let limit = 500;
            let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_V_PROFILE_SC
            let request = {
                consultant: this.user.id,
                offset: limit * (1 - 1),
                limit: limit
            }
            this.$showLoader(true)
            this.$axios.post(url, request).then(response => {
                this.$showLoader(false)
                let data = response.data
                if (data[this.$v.API_SUCCESS]) {
                    this.tableData = data[this.$v.API_ROWS]
                    let p = data[this.$v.API_ROW]
                    this.totalClients = p['totalItems']
                    this.getData()
                } else { this.$validateSession(data) }
            })
        },
        getData(){
            for(let i=0; i<this.tableData.length;i++){
                switch (this.tableData[i].clientType){
                    case 'N1': this.N1++; break
                    case 'N2A': this.N2A++; break
                    case 'N3A': this.N3A++; break
                    case 'N2B': this.N2B++; break
                    case 'N2C': this.N2C++; break
                    case 'N3B': this.N3B++; break
                    case 'N3C': this.N3C++; break
                    case 'N3D': this.N3D++; break
                    case 'N3E': this.N3E++; break
                }
                if(this.tableData[i].date_promise) this.getStatusPromise(this.tableData[i].date_promise)
                this.getCountLastTodayActivity(this.tableData[i].last_payment_date, this.tableData[i].last_contact_date)
            }
            this.carteraN1=(this.N1/this.totalClients*100).toFixed(2)
            this.carteraN2=((this.N2A+this.N2B+this.N2C)/this.totalClients*100).toFixed(2)
            this.carteraN3=((this.N3A+this.N3B+this.N3C+this.N3D+this.N3E)/this.totalClients*100).toFixed(2)
        },
        getDiffDates(date){
            let businessDay = this.calc5thBDay()
            let today = new Date()
            if(businessDay < today) businessDay.setMonth(businessDay.getMonth()+1)
            let a=this.$moment(businessDay).format("DD MMM YYYY");
            let b=this.$moment(date).format("DD MMM YYYY");
            return this.$moment(a).diff(this.$moment(b), 'days')
        },
        calc5thBDay(){
            let date = new Date()
            let i=0, businessDay=1;
            while(i<5){
                date.setDate(businessDay)
                if(date.getDay() != 6 && date.getDay() != 0) i++;
                businessDay++
            }
            //console.log("5to día hábil: ", date)
            return date;
        },
        getStatusPromise(dateP){
            if (!dateP) return
            dateP = this.$moment(dateP).format("DD MM YYYY")
            let now = this.$moment(new Date()).format("DD MM YYYY")
            if(dateP<now) this.expired++;
            else if(dateP==now) this.confirm++;
            else if(dateP>now) this.valid++;
        },
        getCountLastTodayActivity(lastPayment, lastTrace){
            let today = this.$moment().format("DD MM YYYY")
            if(this.$moment(lastPayment).format("DD MM YYYY") === today)
                this.lastPaymentToday++;
            if(this.$moment(lastTrace).format("DD MM YYYY") === today)
                this.lastTracingToday++;
        }
    }
}
</script>
<style>
 .round_table {
    border-collapse: separate;
    border-spacing: 1;
    border:solid 1px;
    border-color: #d3d3d3;
    border-radius: 30px;
    -moz-border-radius:10px;
    -webkit-border-radius: 5px;
}
</style>