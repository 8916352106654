<template>
    <div class="card shadow"
        :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
            :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
            </div>
        </div>
    
        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                        <!--:period="period"-->

                <template slot="columns" >
                    <th v-on:click="selectColumn(0)" v-bind:style="{cursor:pointer2}">ID Deuda<i :class="getIconClass(0)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(1)">Probabilidad liquidación<i :class="getIconClass(1)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(1)" v-bind:style="{cursor:pointer2}">Cliente<i :class="getIconClass(1)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(2)" v-bind:style="{cursor:pointer2}" class="text-center">Número de cuenta<i :class="getIconClass(2)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(3)" v-bind:style="{cursor:pointer2}" class="text-center">Banco<i :class="getIconClass(3)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(4)" v-bind:style="{cursor:pointer2}" class="text-center">Total adeudado<i :class="getIconClass(4)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(5)" v-bind:style="{cursor:pointer2}" class="text-center">Liquidación esperada sin comisión<i :class="getIconClass(5)" style="margin-left: 6px"/></th>
                    <th class="text-center">Saldo finamigo</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Pago banco por mora</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Descuento por mora %</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Meses mora</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Saldo actual</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Pago Banco</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Fecha última actividad</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Fecha límide de pago</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Despacho</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Último descuento %</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Comentarios</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Estado del Tramite</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Estado Deuda</th>
                    <th v-if="isAdmin || isManagerService" class="text-center">Consultor SC</th>
                </template>

                <template slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span @click="debtSelected(row.id_debt)" class="name mb-0 text-primary text-sm">{{row.id_debt}}</span>
                            </div>
                        </div>
                    </th>
                    <td class="text-center">{{row.prob_liquidation ? row.prob_liquidation : '---'}}</td>
                    <td class="text-center">{{row.name}}</td>
                    <td class="text-center">{{row.account}}</td>
                    <td class="text-center">{{row.bank}}</td>
                    <td class="text-center">{{$formatCurrency(row.total_amount)}}</td>
                    <td class="text-center">{{$formatCurrency(row.amount_no_fee)}}</td>
                    <td class="text-center">{{$formatCurrency(row.balance)}}</td>
                    <td class="text-center">{{$formatCurrency(row.amount_pay_bank_mora)}}</td>
                    <td class="text-center">{{ formatDecPost(row.discount_percent_mora)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }} %</td>
                    <td class="text-center">{{row.month_mora ? row.month_mora : '---'}}</td>
                    <td class="text-center">{{$formatCurrency(row.current_amount)}}</td>
                    <td class="text-center">{{$formatCurrency(row.amount_pay_bank)}}</td>
                    <td class="text-center">{{row.last_activity_date ? $formatDate(row.last_activity_date) : '---'}}</td>
                    <td class="text-center">{{row.last_activity_date ? $formatDate(row.limit_pay_date) : '---'}}</td>
                    <td class="text-center">{{row.firm ? row.firm : '---'}}</td>
                    <td class="text-center">{{ formatDecPost(row.last_discount)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }} %</td>
                    <td class="text-center">{{row.comments ? row.comments : '---'}}</td>
                    <td class="text-center">{{row.procedure_status_name ? row.procedure_status_name : '---'}}</td>
                    <td class="text-center">{{row.status ? row.status : '---'}}</td>
                    <td v-if="isAdmin || isManagerService" class="text-center">{{row.consultant_sc_name ? row.consultant_sc_name : 'Sin Asesor'}}</td>
                </template>

            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} deudas
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>
    </div>
</template>
<script>
    //import flatPickr from 'vue-flatpickr-component';
    //import 'flatpickr/dist/flatpickr.css';
    export default {
        name: 'massive-debts-negotiations-table',
        /*components:{
            flatPickr
        },*/
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            unassigned: Boolean,
            /*period: {
                start: null,
                end: null
            },*/
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            customerService() { return this.$store.state.customerService }
        },
        data() {
            return {
                isAdmin: false,
                tableData: [],
                today: null,
                //consultantFilter: null,
                //showSetPeriod:false,
                textTraceFilter: "",
                textDueMonthsFilter: "",
                textTypeClientFilter: "",
                textMDIFilter: "",
                textNominaFilter: "",
                currentColumnKey: null,
                sortedColumn: 0,
                sortingDesc: true,
                //widthProgressBar: null,
                //titleProgressBar: "",
                pointer: 'pointer',
                pointer2: 'default'
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
            this.today= new Date()
        },
        methods: {
            selectColumn(column) {
              if (this.sortedColumn === column) {
                  this.sortingDesc = !this.sortingDesc
              } else {
                  this.sortedColumn = column
                  this.sortingDesc = true
              }
              if (this.sortedColumn != null)
                this.sort()
            },
            sort() {
                let isNumber
                switch (this.sortedColumn) {
                    case 0:
                        this.currentColumnKey = 'id'
                        break
                    case 1:
                        this.currentColumnKey = 'name'
                        break;
                    case 2:
                        this.currentColumnKey = 'monthly_savings'
                        isNumber=true;
                        break
                    case 3:
                        this.currentColumnKey = 'paymentSum'
                        isNumber=true;
                        break
                    case 4:
                        this.currentColumnKey = 'payday'
                        break
                    case 5:
                        this.currentColumnKey = 'covid'
                        break
                }
                this.data.sort((a, b) => {
                    let _a = isNumber? Number(a[this.currentColumnKey]):a[this.currentColumnKey]
                    let _b = isNumber? Number(b[this.currentColumnKey]):b[this.currentColumnKey]
                    if (_a < _b) return this.sortingDesc ? -1 : 1
                    if (_a > _b) return this.sortingDesc ? 1 : -1
                    return 0
                })
            },
            getIconClass(column) {
                let iconClass = "fas "
                if (column === this.sortedColumn) {
                    iconClass += this.sortingDesc ? "fa-arrow-down" : "fa-arrow-up"
                    iconClass += " text-danger"
                } else {
                    iconClass += "fa-arrows-alt-v"
                }
                return iconClass
            },
            formatDecPost(val, pos) {
      if (!pos) pos = 2;
      if (val && Number(val)) return Number(val).toFixed(pos);
      return "0.00";
    },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            debtSelected(client) {
                this.$emit("debtSelected", client);
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            },
        }
    }
</script>
<style>
</style>