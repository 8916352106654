<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <div class="card-body px-lg-5 py-lg-5">
                        <div class="text-center text-muted mb-4">
                            <small>Ingresa tus datos</small>
                        </div>
                        <form role="form">
                            <base-input class="input-group-alternative mb-3"
                                        placeholder="Usuario"
                                        addon-left-icon="ni ni-email-83"
                                        v-model="login.username"
                                        v-on:keydown.enter.native="mLogin()">
                            </base-input>

                            <base-input class="input-group-alternative"
                                        placeholder="Contraseña"
                                        type="password"
                                        addon-left-icon="ni ni-lock-circle-open"
                                        v-model="login.password"
                                        v-on:keydown.enter.native="mLogin()">
                            </base-input>
                            <div class="text-muted font-italic">
                                <small>
                                    <span class="text-danger font-weight-700">{{loginStatus}}</span>
                                </small>
                            </div>
                            <div class="text-center">
                                <base-button type="primary" class="my-4" v-on:click="mLogin()">Iniciar sesión</base-button>
                            </div>
                        </form>
                    </div>
                </div>
                <!--<div class="row mt-3">
                    <div class="col-6">
                        <a href="#" class="text-light"><small>Forgot password?</small></a>
                    </div>
                    <div class="col-6 text-right">
                        <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
                    </div>
                </div>-->
            </div>
        </div>
</template>
<script>
    export default {
        name: 'login',
        data() {
            return {
                login: {
                    username: "",
                    password: ""
                },
                loginStatus: ""
            }
        },
        methods: {
            mLogin() {
                if (this.login.username.length === 0) {
                    this.loginStatus = "Necesitas ingresar un correo electrónico"
                } else if (this.login.password.length === 0) {
                    this.loginStatus = "Indica tu contraseña"
                } else {
                    this.loginStatus = ""
                    let data = {
                        username: this.login.username,
                        password: this.login.password,
                    }
                    this.$axios.post(this.$v.SERVER + this.$v.SELLER_OPS.LOGIN , data).then(response => {
                        let r = response.data
                        if (r[this.$v.API_SUCCESS]) {
                            let user = r[this.$v.API_ROW]
                            let session = user.session
                            delete user.session
                            if (user.role === this.$v.ROLES.USER) {
                              this.loginStatus = "Esta plataforma solo es para administradores"
                            } else {
                              this.$setLS(this.$v.STORE_KEYS.SESSION, session)
                              this.$setLS(this.$v.STORE_KEYS.USER, user)
                              this.$openSocket();
                              this.$axios.defaults.headers.common[this.$v.SESSION] = session[this.$v.SESSION]
                              this.$store.dispatch('downloadUserActionsCatalog')
                              this.$store.dispatch('downloadPendingsSCCatalog')
                              this.$store.dispatch('downloadMoneyBackCatalog')
                              this.$store.dispatch('downloadAuditorCatalog')
                              this.$store.dispatch('downloadSetupSellerCatalog')
                              this.$store.dispatch('downloadClosingDate')
                              this.$store.dispatch('downloadRelationBeneficiary');
                              if (user.role !== this.$v.ROLES.FINAMIGO && user.role !== this.$v.ROLES.MARKETING && user.role !== this.$v.ROLES.AUDITOR) {
                                this.$store.dispatch('downloadGeneralInformation')
                                this.$store.dispatch('downloadUserHistoryEvents')
                                this.$store.dispatch('downloadUserInactiveCatalog')
                                this.$store.dispatch('downloadDebtActivityCatalog')
                                this.$store.dispatch('downloadDebtFirms')
                                this.$store.dispatch('downloadTypeAddendums')
                                this.$store.dispatch('downloadStatusAddendums')
                                this.$store.dispatch('downloadProductType')
                                this.$store.dispatch('downloadCommissionPercentages')
                              }
                              if (user.role === this.$v.ROLES.SERVICE || user.role === this.$v.ROLES.SELLER || user.role === this.$v.ROLES.ADMIN || user.role === this.$v.ROLES.HUMAN_RESOURCES || user.role === this.$v.ROLES.MANAGER_SERVICE || user.role === this.$v.ROLES.MANAGER_SELLER) {
                                this.$store.dispatch('downloadSellers')
                                this.$store.dispatch('downloadTypeGoalsSCCatalog')
                                this.$store.dispatch('downloadTeamsLeaders')
                                this.$store.dispatch('downloadDevs')
                                this.$store.dispatch('downloadUserInactiveCatalog')
                                this.$store.dispatch('downloadCustomerService')
                                this.$store.dispatch('downloadAdmins')
                                this.$store.dispatch('downloadAudits')
                                this.$store.dispatch('downloadDevEventsHistory')
                                this.$store.dispatch('downloadProductType')
                                this.$store.dispatch('downloadCommissionPercentages')
                              }
                              switch (user.role) {
                                case this.$v.ROLES.SELLER:
                                  this.$router.push(this.$v.ROUTES.LEADS)
                                  break
                                case this.$v.ROLES.SERVICE:
                                  this.$router.push(this.$v.ROUTES.CLIENTS)
                                  break
                                case this.$v.ROLES.MANAGER_SERVICE:
                                  this.$router.push(this.$v.ROUTES.CLIENTS)
                                  break
                                case this.$v.ROLES.MANAGER_SELLER:
                                  this.$router.push(this.$v.ROUTES.CLIENTS)
                                  break
                                case this.$v.ROLES.ADMIN:
                                  this.$router.push(this.$v.ROUTES.CLIENTS)
                                  break
                                case this.$v.ROLES.FINAMIGO:
                                  this.$router.push(this.$v.ROUTES.FINAMIGO)
                                  break
                                case this.$v.ROLES.MARKETING:
                                  this.$router.push(this.$v.ROUTES.MARKETING)
                                  break
                                case this.$v.ROLES.AUDITOR:
                                  this.$router.push(this.$v.ROUTES.AUDITORIA)
                                  break
                                case this.$v.ROLES.GROWTH:
                                  this.$router.push(this.$v.ROUTES.GROWTH)
                                  break
                                case this.$v.ROLES.HUMAN_RESOURCES:
                                  this.$router.push(this.$v.ROUTES.HUMAN_RESOURCES)
                                  break  
                              }
                            }
                        } else {
                            switch (r[this.$v.API_REASON]) {
                                case 1:
                                case 7:
                                    this.loginStatus = "Datos inválidos, intenta nuevamente"
                                    break
                                default:
                                    this.loginStatus = "Algo salió mal, intenta nuevamente"
                                    break
                            }
                        }
                    }, er => {
                        console.log(er)
                    })
                }
            }
        }
    }
</script>
<style>
</style>
