<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>
		<div class="container-fluid">
			<div class="row" >
        <div class="col" v-bind:style="{cursor:pointer2}" style="margin-top: 10px;">
          <settlements-day-dash-table title="Dashboard Liquidaciones al Día" style="margin-top:-5rem;"/>
          <settlements-day-table title="Deudas en Trámite de Liquidación"
            style="margin-top:-1rem;"
            :data="tableData1"
            :period="period"
            :pagination="pagination1"
            v-on:pageSelected="getDebtsListTL"
            v-on:debtSelected="debtSelected"
            v-on:setStatusTramit="setStatusTramit"
            v-on:setMethodPayment="setMethodPayment"
            v-on:setvoboTLFilter="setvoboTLFilter"
            v-on:setPeriodFilter="setPeriodFilter"/>

          <debt-settled-day-table title="Deudas Liquidadas" style="margin-top: 40px"
            :data="tableData2"
            :pagination="pagination2"
            v-on:pageSelected="getDebtsListL"
            v-on:debtSelected="debtSelected"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SettlementsDayTable from './Tables/SettlementsDayTable';
import DebtSettledDayTable from "./Tables/DebtSettledDayTable";
import SettlementsDayDashTable from './Tables/SettlementsDayDashTable';

export default {
    name: 'tables',
    components: {
      DebtSettledDayTable,
      SettlementsDayTable,
      SettlementsDayDashTable
    },
    mounted() {
      this.$root.$on('searchQuery', query => {
        if (query.length > 0)
          this.searchQuery = query
        else
          this.searchQuery = null
        this.getDebtsListTL(1)
        this.getDebtsListL(1)
      })
    },
    computed: {
      user() { return this.$getLS(this.$v.STORE_KEYS.USER) }
    },
    data() {
      return {
        lastRequest: {},
        isAdmin: false,
        isService: false,
        isAuditor: false,
        isManagerService: false,
        program: null,
        unassigned: null,
        //En trámite de liquidación
        tableData1: [],
        pagination1: {
          total: 0,
          perPage: 500,
          currentPage: 1
        },
        //Liquidadas
        tableData2: [],
        pagination2: {
          total: 0,
          perPage: 10,
          currentPage: 1
        },

        period: {
          start: null,
          end: null
        },
        searchQuery: null,
        consultantFilter: null,
        pointer: 'pointer',
        pointer2: 'default',
        statusFilter: null,
        methodPaymentFilter:null,
        voboTLFilter:null,
        periodFilter:null,
      };
    },
    async created() {
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
      this.isService = this.user.role === this.$v.ROLES.SERVICE || this.user.role === this.$v.ROLES.AUDITOR
      this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
      this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR
      await this.getDebtsListTL(1)
      await this.getDebtsListL(1)
    },
    methods: {
      async getDebtsListTL(page) {
        let limit = this.pagination1.perPage;
        let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_VIEW_DEBTS_ALL;
        let request = {
          offset: limit * (page - 1),
          limit: limit,
          //estimated_settlement_date: {ne: null}
        }
        request.status = 'En trámite de liquidación';
        request.specialOrder = 'limit_pay_date';

        if (this.searchQuery) request.find = this.searchQuery
        if (this.statusFilter !== null) {Object.assign(request, {procedure_status_name: this.statusFilter})}
        if (this.methodPaymentFilter !== null) {Object.assign(request, {method_pay: this.methodPaymentFilter})}
        if (this.voboTLFilter !== null) {Object.assign(request, {vobo_TL: this.voboTLFilter})}
        request.limit_pay_date = {
          gte: this.$moment().startOf('month').format('YYYY-MM-DD'),
        }
        if (this.periodFilter)
          delete request.limit_pay_date;
        if (this.periodFilter === 1) {
          request.estimated_settlement_date = {gte: this.period.start}
        }
        else if (this.periodFilter === 2){
          request.estimated_settlement_date = {
            gte: this.period.start,
            lte: this.period.end
          }
        }

          const lr = request
          if (lr === this.lastRequest) return
          this.lastRequest = lr;

        this.$showLoader(true)
        this.$axios.post(url, request).then(response => {
          this.$showLoader(false)
          let data = response.data
          if (data[this.$v.API_SUCCESS]) {
            let p = data[this.$v.API_ROW]
            this.tableData1=data[this.$v.API_ROWS].reverse();
            this.pagination1.total = p['totalItems']
            this.pagination1.currentPage = page
          } else { this.$validateSession(data) }
        })
      },
      async getDebtsListL(page) {
        let limit = this.pagination2.perPage;
        let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_VIEW_DEBTS_ALL;
        let request = {
          offset: limit * (page - 1),
          limit: limit,
        }
        request.status = 'Liquidada';
        let settlement_paid_date = {
            gte: this.$moment().startOf('month').format('YYYY-MM-DD'),
        }
        Object.assign(request, {settlement_paid_date:settlement_paid_date})
        if (this.searchQuery) request.find = this.searchQuery
        
        this.$showLoader(true)
        this.$axios.post(url, request).then(response => {
          this.$showLoader(false)
          let data = response.data
          if (data[this.$v.API_SUCCESS]) {
            let p = data[this.$v.API_ROW]
            this.tableData2 = data[this.$v.API_ROWS].reverse();
            this.pagination2.total = p['totalItems']
            this.pagination2.currentPage = page
            this.pagination2.perPage = limit
            
          } else { this.$validateSession(data) }
        })
      },
      setStatusTramit(value) {
        this.statusFilter = value
        this.getDebtsListTL(1)
      },
      setMethodPayment(value) {
        this.methodPaymentFilter = value
        this.getDebtsListTL(1)
      },
      setvoboTLFilter(value) {
        this.voboTLFilter = value
        this.getDebtsListTL(1)
      },
      debtSelected(debt_id) {
        window.open("https://admin.dbmenos.com/viewDebt?debt="+debt_id, '_blank')
      },
      setConsultantFilter(value) {
        this.consultantFilter = value
        this.getDebtsListTL(1)
      },
      setPeriodFilter(value) {
        if(value === 1){
          this.period.start = this.$moment().startOf("month").format('YYYY-MM-DD')
        }
        this.periodFilter = value
        this.getDebtsListTL(1)
      }
    }
  };
</script>
<style>
#top 
{ position: fixed;  z-index: 999; }
</style>