<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">

                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">

                <template slot="columns">
                    <th v-on:click="selectColumn(0)" v-bind:style="{cursor:pointer}" class="text-center">ID Cliente<i :class="getIconClass(0)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(1)" v-bind:style="{cursor:pointer}">Cliente<i :class="getIconClass(1)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(2)" v-bind:style="{cursor:pointer}">MDI<i :class="getIconClass(2)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(3)" v-bind:style="{cursor:pointer}" class="text-center">% Comisión<i :class="getIconClass(3)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(4)" v-bind:style="{cursor:pointer}">Comisiones pagadas<i :class="getIconClass(4)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(5)" v-bind:style="{cursor:pointer}">Total de comisiones<i :class="getIconClass(5)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(6)" v-bind:style="{cursor:pointer}">Comisión a cobrar<i :class="getIconClass(6)" style="margin-left: 6px"/></th>
                    <th class="text-center" v-bind:style="{cursor:pointer}">Generar</th>
                </template>

                <template slot-scope="{row}">
                    <th class="text-center" scope="row" v-bind:style="{cursor:pointer2}">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <a :href="'https://admin.dbmenos.com/client?id='+row.id" target="_blank">
                                    <span class="name mb-0 text-sm">{{row.id}}<i class="fas fa-user-edit text-primary"></i></span>
                                </a>
                            </div>
                        </div>
                    </th>
                    <td v-bind:style="{cursor:pointer2}">{{row.name}}</td>
                    <td v-bind:style="{cursor:pointer2}">{{$formatCurrency(row.accountDebtSum)}}</td>
                    <td v-bind:style="{cursor:pointer2}" class="text-center">{{row.commission_percentage}}</td>
                    <td v-bind:style="{cursor:pointer2}" class="text-center">{{row.commission_count}}</td>
                    <td v-bind:style="{cursor:pointer2}" class="text-center">{{row.commission_sum}}</td>
                    <td v-if="row.commission_to_charge == null" v-bind:style="{cursor:pointer2}" class="text-center"><input class="text-center" size="10" :value="(row.commission_percentage / 100 * row.accountDebtSum * 1.16)" v-on:change="row.commission_to_charge=$event.target.value"></td>
                    <td v-else v-bind:style="{cursor:pointer2}" class="text-center"><input class="text-center" size="10" v-model="row.commission_to_charge"></td>
                    <td v-bind:style="{cursor:pointer2}" class="text-center"><base-button type="success" size="sm" icon="ni ni-check-bold" v-on:click="createCommission(row)"></base-button></td>
                </template>

            </base-table>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'commission-table',
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            banks() { return this.$store.state.banks },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService }
        },
        data() {
            return {
                isAdmin: false,
                currentColumnKey: null,
                sortedColumn: 0,
                freezeData: null,
                sortingDesc: true,
                pointer: 'pointer',
                pointer2: 'default'
            }
        },
        updated() {
            if (!this.freezeData && this.data) {
                this.freezeData = JSON.parse(JSON.stringify(this.data))
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
        },
        methods: {
            uploadUpdates() {
                this.$showLoader(true)
                let toUpload = []
                for (let i=0 ; i<this.data.length ; i++) {
                    let acc = this.data[i]
                    if (this.isUpdated(acc)) toUpload.push(acc)
                }
                console.log(toUpload)
                let url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_ACCOUNTS
                this.$axios.post(url, { accounts: toUpload}).then(response => {
                    this.$showLoader(false)
                    let data = response.data
                    if (data[this.$v.API_SUCCESS]) {
                        this.freezeData = JSON.parse(JSON.stringify(this.data))
                        
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
                    } else {
                        this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    }
                })
            },
            sort() {
                switch (this.sortedColumn) {
                    case 0:
                        this.currentColumnKey = 'id'
                        break
                    case 1:
                        this.currentColumnKey = 'name'
                        break;
                    case 2:
                        this.currentColumnKey = 'accountDebtSum'
                        break
                    case 3:
                        this.currentColumnKey = 'commission_percentage'
                        break
                    case 4:
                        this.currentColumnKey = 'commission_count'
                        break
                    case 5:
                        this.currentColumnKey = 'commission_sum'
                        break
                    case 6:
                        this.currentColumnKey = 'commission_to_charge'
                        break

                }
                console.log(this.currentColumnKey)
                this.data.sort((a, b) => {
                    let _a = a[this.currentColumnKey]
                    let _b = b[this.currentColumnKey]
                    if (_a < _b) return this.sortingDesc ? -1 : 1
                    if (_a > _b) return this.sortingDesc ? 1 : -1
                    return 0
                })
                console.log(this.data[0])
            },
            selectColumn(column) {
                console.log(column + " selected")
              if (this.sortedColumn === column) {
                  this.sortingDesc = !this.sortingDesc
              } else {
                  this.sortedColumn = column
                  this.sortingDesc = true
              }
              if (this.sortedColumn != null)
                this.sort()
            },
            isUpdated(acc) {
                if (this.freezeData === null) return false
                let a = this.freezeData.find(a => a['account_id'] === acc['account_id'])
                if (a && a.discount)
                    return a.discount !== acc.discount
                return false
            },
            getIconClass(column) {
                let iconClass = "fas "
                if (column === this.sortedColumn) {
                    iconClass += this.sortingDesc ? "fa-arrow-down" : "fa-arrow-up"
                    iconClass += " text-danger"
                } else {
                    iconClass += "fa-arrows-alt-v"
                }
                return iconClass
            },
            getCustomerFilterText() {
                if (this.consultantFilter) return this.getConsultantName(this.consultantFilter)
                return "Consultores"
            },
            getConsultantFilterText() {
                if (this.selfClients) return 'Solo mis clientes'
                return "Clientes sin asignar"
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            },
            getBank(id) {
                return this.banks.find((b) => b.id === id)
            },
            createCommission(row) {
                this.$showLoader(true)
                if(!row.commission_to_charge) {
                    row.commission_to_charge = (row.commission_percentage / 100 * row.accountDebtSum * 1.16)
                }
                let url = this.$v.SERVER + this.$v.SELLER_OPS.CREATE_COMMISSION
                let request = {
                    user: row.id,
                    date: new Date(),
                    amount: row.commission_to_charge,
                }
                if (request.user && request.date && request.amount > 0) {
                    this.$axios.post(url, request).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        if (data[this.$v.API_SUCCESS]) {
                            
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
                            this.newCommissionModal = false
                            this.newCommission = {}
                            this.downloadField(this.$v.TABLES.COMMISSIONS, 1, 5)
                        } else {
                            this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                        }
                    })
                } else {
                    this.$showLoader(false)
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Llenar los datos faltantes'
                     ));
                }
            }
        }
    }
</script>
<style>
</style>
