<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>

		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col-lg-2 text-right" v-if="(isAdmin || isManagerSeller || isManagerService)">
					<base-button class="sm" slot="title" type="warning" style="width: 100%" size="sm" @click="graphic=!graphic">
						{{graphic?'Ocultar gráfica':'Ver gráfica'}}
					</base-button>
				</div>
				<div class="col-lg-2 text-right">
					<base-button class="sm" slot="title" type="info" style="width: 100%" size="sm" @click="showIncompletes()">
						{{table2?'Ocultar incompletos':'Ver incompletos'}}
					</base-button>
				</div>
				<div class="col" v-bind:style="{cursor:pointer2}" style="margin-top: 10px;">
					<new-users-table title="Nuevos usuarios"
							:data="tableData"
							:pagination="pagination"
                            :period="period"
							v-on:pageSelected="getNewUsers"
							v-on:setValidationFilter="setValidationFilter"
                            v-on:setPeriodFilter="setPeriodFilter"
							v-on:setConsultantFilter="setConsultantFilter"
							v-on:setCompletePaymentFilter="setCompletePaymentFilter"/>
					
					<new-users-table title="Usuarios incompletos" v-if="table2"
							:data="tableDataIP"
							:pagination="paginationIP"
                            :period="period"
							v-on:pageSelected="getNewUsers"
							v-on:setValidationFilter="setValidationFilter"
                            v-on:setPeriodFilter="setPeriodFilter"
							v-on:setConsultantFilter="setConsultantFilter"
							v-on:setCompletePaymentFilter="setCompletePaymentFilter"
							style="margin-top: 40px"/>
				</div>
			</div>
			<br>
			<graphics-new-users v-if="(isAdmin || isManagerSeller || isManagerService) && graphic"></graphics-new-users>
		</div>
	</div>
  
</template>
<script>
	import NewUsersTable from './Tables/NewUsersTable';
	import GraphicsNewUsers from './Graphics/GraphicNewClients'
	export default {
		name: 'tables',
		components: {
			NewUsersTable,
			GraphicsNewUsers
		},
		computed: {
			user() {return this.$getLS(this.$v.STORE_KEYS.USER);},
		},
		created() {
			this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
			this.isSeller = this.user.role === this.$v.ROLES.SELLER;
			this.isService = this.user.role === this.$v.ROLES.SERVICE || this.user.role === this.$v.ROLES.AUDITOR;
			this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
			this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
			this.setPeriodFilter(1,true)
		},
		mounted() {
			this.$root.$on('searchQuery', query => {
				if (query.length > 0)
					this.searchQuery = query
				else
					this.searchQuery = null
				this.getNewUsers(1,true)
			})
		},
		data() {
			return {
				searchQuery: null,
				tableData: [],
				tableDataIP: [],
				pagination: {
					total: 0,
					perPage: 500,
					currentPage: 1
				},
				paginationIP: {
					total: 0,
					perPage: 500,
					currentPage: 1
				},
				periodFilter: null,
				completePaymentFilter: null,
				validationFilter: null,
				consultantFilter: null,
				period: {
					start: null,
					end: null
				},
				pointer: 'pointer',
				pointer2: 'default',
				isAdmin: false,
				isManagerSeller: false,
				isManagerService: false,
				isSeller: false,
				isService: false,
				graphic: false,
				table2: false
			};
		},
		methods: {
			getNewUsers(page, table) {
				let limit = table? this.pagination.perPage:this.paginationIP.perPage
				let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_NEW_USER_LIST // + "?offset=" + limit*(page - 1) + "&limit=" + limit
				if (this.searchQuery) url += "?find=" + this.searchQuery
				let request ={
					offset: limit*(page - 1),
					limit: limit
				}
				if (this.periodFilter === 1) {
					let firstPaymentDate = table?{gte: this.period.start}:{lte: this.period.start}
					Object.assign(request, {firstPaymentDate})
				}else if (this.periodFilter === 2){
					let firstPaymentDate = {
						gte: this.period.start,
						lte: this.period.end
					}
					Object.assign(request, {firstPaymentDate})
				}
				if (this.completePaymentFilter !== null) Object.assign(request, {firstPaymentComplete: this.completePaymentFilter})
				if (!table) Object.assign(request, {firstPaymentComplete: 0})
				if (this.validationFilter === 1) Object.assign(request, {notValidatedPaymentCount: 0})
				if (this.validationFilter === 0) Object.assign(request, {notValidatedPaymentCount: 'notEmpty'})
				if (this.consultantFilter) Object.assign(request, {consultant_id: this.consultantFilter})
				this.$showLoader(true)
				this.$axios.post(url,request).then(response => {
					this.$showLoader(false)
					let data = response.data
					if (data[this.$v.API_SUCCESS]) {
						let p = data[this.$v.API_ROW]
						if(table){
							this.tableData = data[this.$v.API_ROWS]
							this.pagination.total = p['totalItems']
							this.pagination.currentPage = page
							this.pagination.perPage = limit
						} else{
							this.tableDataIP = data[this.$v.API_ROWS]
							this.paginationIP.total = p['totalItems']
							this.paginationIP.currentPage = page
							this.paginationIP.perPage = limit
						}
					} else { this.$validateSession(data) }
				})
			},
			setValidationFilter(value,table) {
				this.validationFilter = value
				//console.log(this.validationFilter);
				this.getNewUsers(1,table)
			},
			setCompletePaymentFilter(value,table) {
				this.completePaymentFilter = value
				//console.log(this.completePaymentFilter);
				this.getNewUsers(1,table)
			},
			setConsultantFilter(value,table) {
				this.consultantFilter = value
				this.getNewUsers(1,table)
			},
      setPeriodFilter(value,table) {
				if(value === 1){
					let date = new Date()
					let month = date.getMonth()+1
					if(month<10) month= "0"+month
					this.period.start = date.getFullYear()+"-"+month+"-"+"01"
				}
				this.periodFilter = value
				this.getNewUsers(1,table)
			},
			showIncompletes(){
				this.table2=!this.table2
				if(this.table2) this.getNewUsers(1,false)
			}
		}
	};
</script>
<style></style>