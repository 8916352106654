<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-dbm opacity-8"></span>
    </base-header>
      <section v-if="createCalculation" class="section section-lg pt-lg-0 mt--150">
      <card style="background-color: #3953d3">
        <div v-if="!reportListo" class="container">
          <card v-if="!existCalculator">
           <div v-if="sumAccountsBC">
              <credit-report-table
              v-if="lengthsCreditReports.dbmAccounts > 0"
              title="Cuentas que se pueden agregar al programa"
              :amount="Number(client['total_savings'])"
              :data="
                report && report['dbmAccounts'] ? report['dbmAccounts'] : []
              "
              :pagination="paymentPagination"
              :creditReportAccept="true"
              v-on:onNewItem="newPaymentModal = true"
              v-on:onPaymentSelected="onPaymentSelected"
              @deleteAccount="deleteAccount"
              style="margin-top: 20px"
            />
            <base-button
                              block
                              tag="a"
                              style="margin-top: 20px"
                              v-on:click="m_calculateDiscountNew()"
                              class="mb-3 mb-sm-0 text-white text-center"
                              type="primary"
                              >Calcular Descuento</base-button
                            >
            </div>
             <div v-if="!sumAccountsBC">
            <div class="row justify-content-center" style="margin-top: 10px">
              <div class="col">
                <div class="btn-wrapper">
                  <base-button
                    tag="a"
                    v-on:click="m_appendDebt()"
                    class="mb-3 mb-sm-0 text-white"
                    type="success"
                    icon="fa fa-plus"
                    >Agrega nueva deuda</base-button
                  >
                </div>
              </div>
            </div>
              <credit-report-table
              v-if="lengthsCreditReports.dbmAccounts > 0"
              title="Cuentas que se pueden agregar al programa"
              :amount="Number(client['total_savings'])"
              :data="
                report && report['dbmAccounts'] ? report['dbmAccounts'] : []
              "
              :pagination="paymentPagination"
              :creditReportAccept="true"
              v-on:onNewItem="newPaymentModal = true"
              v-on:onPaymentSelected="onPaymentSelected"
              @deleteAccount="deleteAccount"
              style="margin-top: 20px"
            />
            <div style="margin-top:2rem;">
            <h2  style="width: 100%" class="text-center text-danger"
                          >Añade una o más cuentas para poder completar el monto total de $10,000 y poder generar su propuesta</h2
                        >
            </div>
            <div class="row row-grid justify-content-center">
                  <div
                    class="col-lg-4"
                    v-for="(debt, indexdeb) in debts"
                    :key="indexdeb"
                  >
                    <card
                      class="border-0 text-center"
                      shadow
                      body-classes="py-5"
                      style="margin-top: 15px"
                    >
                      <div v-if="debt.edit">
                        <base-dropdown
                          class="nav-item"
                          menu-classes="dropdown-menu-lg"
                          style="margin-bottom: 20px"
                        >
                          <a
                            slot="title"
                            class="nav-link"
                            data-toggle="dropdown"
                            role="button"
                          >
                            <base-button type="primary">
                              <i class="far fa-credit-card"></i>
                              {{ debt.bank }}
                            </base-button>
                          </a>
                          <div
                            class="dropdown-menu-inner"
                            style="
                              overflow-x: hidden;
                              overflow-y: scroll;
                              height: 250px;
                            "
                          >
                            <ul>
                              <li
                                v-for="(bank, index) in banks"
                                :key="index"
                                style="list-style-type: none"
                              >
                                <a
                                  v-on:click="m_bankSelected(indexdeb, bank)"
                                  style="
                                    margin-left: -12px;
                                    margin-top: 3px;
                                    margin-bottom: 3px;
                                  "
                                  class="media d-flex align-items-center"
                                >
                                  <div
                                    class="icon icon-shape bg-secondary rounded-circle text-white"
                                    style="margin-top: 12px"
                                  >
                                    <div class="g-item">
                                      <img
                                        class="img-responsive"
                                        v-bind:src="bank.url"
                                        style="
                                          height: 100%;
                                          width: 100%;
                                          object-fit: contain;
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="media-body ml-2"
                                    style="margin-top: 10px"
                                  >
                                    <h6 class="heading text-primary mb-md-1">
                                      {{ bank.name }}
                                    </h6>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </base-dropdown>
                      </div>
                      <div v-else>
                        <div class="row">
                          <div class="col-md-9" style="margin-top: 2px">
                            <h3 class="text-primary mb-md-1">
                              {{ debt.bank }}
                            </h3>
                          </div>
                          <div class="col-md-3">
                            <div class="g-item">
                              <img
                                class="img-responsive"
                                v-bind:src="getBank(debt.bankid).url"
                                style="
                                  height: 100%;
                                  width: 100%;
                                  object-fit: contain;
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="debt.edit">
                        <label for="amount" style="width: 100%"
                          >¿Cuánto debes?</label
                        >
                        <base-input
                          id="amount"
                          placeholder="0"
                          v-model="debt.amount"
                        ></base-input>

                        <base-slider
                          :range="{ min: 3000, max: 100000 }"
                          v-model="debt.amount"
                        ></base-slider>

                        <label for="amount" style="width: 100%"
                          >Número de Cuenta</label
                        >
                        <base-input
                          id="account"
                         placeholder="TDC"
                          v-model="debt.account"
                        ></base-input>

                        <label for="last_bank_payment_date" style="width: 100%"
                          >Fecha Último Pago</label
                        >
                        <flat-pickr
                          id="last_bank_payment_date"
                          v-model="debt.last_bank_payment_date"
                          :placeholder="today"
                          class="btn btn-sm"
                          data-vv-as="Fecha"
                          type="primary"
                          name="startPeriod"
                          :config="config"
                          style="
                            margin-top: -0.5rem;
                            margin-bottom: 0.5rem;
                            background-color: #0000;
                            width: 100%;
                          "
                        />
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12">
                              <base-button
                                block
                                v-on:click="m_spliceDebtDebts(indexdeb)"
                                type="danger"
                              >
                                <i class="far fa-trash-alt"></i> Borrar
                              </base-button>
                            </div>
                            <div class="col-12" style="margin-top: 1rem">
                              <base-button
                                block
                                v-on:click="m_editDebtNew(indexdeb, false)"
                                type="success"
                              >
                                <i class="fas fa-plus-circle"></i> Añadir
                              </base-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else style="margin-top: 20px">
                        <h1 id="h6amount" class="text-primary">
                          $
                          {{
                            Number(debt.amount)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h1>
                        <label for="h6amount" style="width: 100%"
                          >Deuda total</label
                        >
                        
                     <label for="h5amount2" style="width: 100%"
                          >Número de Cuenta:
                          <b v-if="debt.account" class="text-primary">
                            {{ debt.account }}
                          </b> <b class="text-primary" v-if="!debt.account">
                            ---
                          </b></label>
                        
                       <label for="h1Lastbant" style="width: 100%"
                          >Último Pago: <b v-if="debt.last_bank_payment_date" class="text-primary">
                            {{ debt.last_bank_payment_date }}
                          </b> <b class="text-primary" v-if="!debt.last_bank_payment_date">
                            ---
                          </b>
                        </label>
                        <div class="row">
                          <div class="col">
                            <base-button
                              v-on:click="m_editDebtNew(indexdeb, true)"
                              block
                              type="primary"
                              class="mt-4"
                              >Editar</base-button
                            >
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
            <base-button
                              block
                              tag="a"
                              style="margin-top: 20px"
                              v-on:click="m_calculateDiscountNew()"
                              class="mb-3 mb-sm-0 text-white text-center"
                              type="primary"
                              >Calcular Descuento</base-button
                            >
            </div>
          </card>
          
          <card v-if="existCalculator" style="margin-top: 10px">
            <div class="row justify-content-center" style="margin-top: 10px">
              <div class="col">
                <div class="btn-wrapper">
                  <base-button
                    tag="a"
                    v-on:click="m_appendDebt()"
                    class="mb-3 mb-sm-0 text-white"
                    type="success"
                    icon="fa fa-plus"
                    >Agrega nueva deuda</base-button
                  >
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-12">
              <credit-report-table
              v-if="lengthsCreditReports.dbmAccounts > 0"
              title="Cuentas que se pueden agregar al programa"
              :amount="Number(client['total_savings'])"
              :data="
                report && report['dbmAccounts'] ? report['dbmAccounts'] : []
              "
              :pagination="paymentPagination"
              v-on:onNewItem="newPaymentModal = true"
              v-on:onPaymentSelected="onPaymentSelected"
              v-on:accountClick="accountClick"
              @deleteAccount="deleteAccount"
              style="margin-top: 20px"
            />
                <div class="row row-grid justify-content-center">
                  <div
                    class="col-lg-4"
                    v-for="(debt, indexdeb) in debts"
                    :key="indexdeb"
                  >
                    <card
                      class="border-0 text-center"
                      shadow
                      body-classes="py-5"
                      style="margin-top: 15px"
                    >
                      <div v-if="debt.edit">
                        <base-dropdown
                          class="nav-item"
                          menu-classes="dropdown-menu-lg"
                          style="margin-bottom: 20px"
                        >
                          <a
                            slot="title"
                            class="nav-link"
                            data-toggle="dropdown"
                            role="button"
                          >
                            <base-button type="primary">
                              <i class="far fa-credit-card"></i>
                              {{ debt.bank }}
                            </base-button>
                          </a>
                          <div
                            class="dropdown-menu-inner"
                            style="
                              overflow-x: hidden;
                              overflow-y: scroll;
                              height: 250px;
                            "
                          >
                            <ul>
                              <li
                                v-for="(bank, index) in banks"
                                :key="index"
                                style="list-style-type: none"
                              >
                                <a
                                  v-on:click="m_bankSelected(indexdeb, bank)"
                                  style="
                                    margin-left: -12px;
                                    margin-top: 3px;
                                    margin-bottom: 3px;
                                  "
                                  class="media d-flex align-items-center"
                                >
                                  <div
                                    class="icon icon-shape bg-secondary rounded-circle text-white"
                                    style="margin-top: 12px"
                                  >
                                    <div class="g-item">
                                      <img
                                        class="img-responsive"
                                        v-bind:src="bank.url"
                                        style="
                                          height: 100%;
                                          width: 100%;
                                          object-fit: contain;
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="media-body ml-2"
                                    style="margin-top: 10px"
                                  >
                                    <h2 class="text-primary">
                                      {{ bank.name }}
                                    </h2>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </base-dropdown>
                      </div>
                      <div v-else>
                        <div class="row">
                          <div class="col-md-9" style="margin-top: 2px">
                            <h3 class="text-primary mb-md-1">
                              {{ debt.bank }}
                            </h3>
                          </div>
                          <div class="col-md-3">
                            <div class="g-item">
                              <img
                                class="img-responsive"
                                v-bind:src="getBank(debt.bankid).url"
                                style="
                                  height: 100%;
                                  width: 100%;
                                  object-fit: contain;
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="debt.edit">
                        <label for="amount" style="width: 100%"
                          >¿Cuánto debes?</label
                        >
                        <base-input
                          id="amount"
                          placeholder="0"
                          v-model="debt.amount"
                        ></base-input>

                        <base-slider
                          :range="{ min: 3000, max: 100000 }"
                          v-model="debt.amount"
                        ></base-slider>
                        <label for="amount" style="width: 100%"
                          >Número de Cuenta</label
                        >
                        <base-input
                          id="account"
                          placeholder="TDC"
                          v-model="debt.account"
                        ></base-input>
                        <label for="last_bank_payment_date" style="width: 100%"
                          >Fecha Último Pago</label
                        >
                        <flat-pickr
                          id="last_bank_payment_date"
                          v-model="debt.last_bank_payment_date"
                          :placeholder="today"
                          class="btn btn-sm"
                          data-vv-as="Fecha"
                          type="primary"
                          name="startPeriod"
                          :config="config"
                          style="
                            margin-top: -0.5rem;
                            margin-bottom: 0.5rem;
                            background-color: #0000;
                            width: 100%;
                          "
                        />
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12">
                              <base-button
                                block
                                v-on:click="m_spliceDebt(indexdeb)"
                                type="danger"
                              >
                                <i class="far fa-trash-alt"></i> Borrar
                              </base-button>
                            </div>
                            <div class="col-12" style="margin-top: 1rem">
                              <base-button
                                block
                                v-on:click="m_editDebtNew(indexdeb, false)"
                                type="success"
                              >
                                <i class="fas fa-plus-circle"></i> Añadir r
                              </base-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else style="margin-top: 20px">
                        <h1 id="h6amount" class="text-primary">
                          $
                          {{
                            Number(debt.amount)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h1>
                        <label for="h6amount" style="width: 100%"
                          >Deuda total</label
                        >
                        
                      <label for="h5amount2" style="width: 100%"
                          >Número de Cuenta:
                          <b v-if="debt.account" class="text-primary">
                            {{ debt.account }}
                          </b> <b class="text-primary" v-if="!debt.account">
                            ---
                          </b></label>
                        <label for="h1Lastbant" style="width: 100%"
                          >Último Pago: <b v-if="debt.last_bank_payment_date" class="text-primary">
                            {{ debt.last_bank_payment_date }}
                          </b> <b class="text-primary" v-if="!debt.last_bank_payment_date">
                            ---
                          </b>
                        </label>
                        <div class="row">
                          <div class="col">
                            <base-button
                              v-on:click="m_editDebtNew(indexdeb, true)"
                              block
                              type="primary"
                              class="mt-4"
                              >Editar</base-button
                            >
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <card style="margin-top: 1rem">
                  <div class="container shape-container d-flex">
                    <div class="col px-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <h1 class="display-4 text-black text-center">
                            <base-button
                              block
                              tag="a"
                              v-on:click="m_calculateDiscountNew()"
                              class="mb-3 mb-sm-0 text-white text-center"
                              type="primary"
                              >Calcular Descuento</base-button
                            >
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </card>
        </div>

        <div v-if="reportListo" class="container">
          <div class="row" style="margin-top: 10px; margin-bottom: 20px">
            <div class="col">
              <div class="btn-wrapper">
                <base-button
                  tag="a"
                  v-on:click="backWindowsNew()"
                  class="mb-3 mb-sm-0 text-white"
                  type="danger"
                  icon="fas fa-arrow-left"
                  >Regresar</base-button
                >
              </div>
           
            </div>
          </div>
          <!--
                    <div class="col">
                        <base-button
                            tag="a"
                            block
                            v-if="bandera"
                            v-on:click="m_calculateDiscount()"
                            class="mb-3 mb-sm-0"
                            type="success"
                        >CONTINUAR</base-button>
                    </div>
                    !-->
          <card
            v-if="reportListo && !notCredit"
            style="margin-top: 1rem; border-radius: 2rem"
          >
            <div
              class="container shape-container d-flex"
              style="margin-top: 10px"
            >
              <div class="col px-0">
                <div class="row">
                  <div class="col-lg-12">
                    <h1 class="display-3 text-black text-center">
                      Programa DB Menos
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="container" style="max-width: 1600px !important">
              <div
                class="row justify-content-center"
                style="margin-bottom: 40px"
              >
                <div class="col-lg-12">
                  <div class="row justify-content-center">
                    <div class="col-lg-12">
                      <card class="border-0">
                        <div class="row justify-content-center">
                          <div class="col text-center">
                            <h1 class="h1 font-weight-bold mb-0 text-success" style="font-size: 2.5rem">
                    {{ periodOptimal }} meses
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                  >
                    Duración del Programa Recomendado
                  </h4>
                            <br />
                            <base-slider
                              :range="{ min: minSlider, max: maxSlider }"
                              :options="{ step: 1 }"
                              v-model.number="period"
                              v-on:input="m_ReturnDiscountNew()"
                            ></base-slider>
                            <h3 class="text-primary text-center">
                              Duración del programa {{ period }} meses
                            </h3>
                          </div>
                        </div>
                      </card>
                    </div>
                  </div>
                </div>
              </div>
               <div
                class="row justify-content-center text-center"
                style="margin-bottom: 40px; margin-top: -40px"
              >
              <div class="col-lg-2"></div>
              <div class="col-lg-4" style="margin-top: 30px;">
                  <h1 class="h1 font-weight-bold mb-0" style="font-size: 2.5rem">
                    ${{ (calculatorDebts.monthlyFee) }}
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                  >
                    Depósito mensual
                  </h4>
                </div>
                 <div class="col-lg-4" style="margin-top: 30px;">
                  <h1 class="h1 font-weight-bold mb-0" style="font-size: 2.5rem">
                    {{
                          formatDecPost(calculatorDebts.discount)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}%
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Descuento
                  </h4>
                <br>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-12" v-if="user.type == 0" style="margin-top: 30px">
                  <h1 class="h1 font-weight-bold mb-0">
                   {{
                     (calculatorDebts.realfee)
                        }}%
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Porcentaje de Comisión
                  </h4>
                <br>
                </div>
                <div class="col-lg-4" v-if="user.type == 1" style="margin-top: 30px">
                  <h1 class="h1 font-weight-bold mb-0">
                   {{
                     (calculatorDebts.realfee)
                        }}%
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Porcentaje Mensual
                  </h4>
                <br>
                </div>
                <div class="col-lg-4" style="margin-top: 30px" v-if="user.type == 1">
                  <h1 class="h1 font-weight-bold mb-0">
                   {{formatDecPost(((realCommision * period))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}} %
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Porcentaje de Total
                  </h4>
                <br>
                </div>
                <div class="col-lg-4"  style="margin-top: 30px" v-if="user.type == 1">
                  <h1 class="h1 font-weight-bold mb-0">
                   $ {{formatDecPost(((calculatorDebts.commission * period))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Monto de Comisión 
                  </h4>
                <br>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-12" v-if="user.type == 1" style="margin-top: 30px">
                  <h1 class="h1 font-weight-bold mb-0">$ 499.00</h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Cuota Inicial
                  </h4>
                <br>
                </div>
                <div class="col-lg-3" style="margin-top: 30px; margin-left:4rem">
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Deuda total
                  </h4>
                  <h1 class="h2 font-weight-bold mb-0" style="font-size: 2rem;">
                    {{ formatCurrency(calculatorDebts.totalDebt) }}
                  </h1>

                  <h4
                    style="margin-top: 1rem"
                    class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                  >
                    Pagara
                  </h4>
                  <h1 class="h2 font-weight-bold mb-0" style="font-size: 2rem">
                    {{ formatCurrency(Math.ceil(calculatorDebts.willPay)) }}
                  </h1>
                </div>

                <div class="col-lg-4">
                  <div
                    class="d-flex justify-content-between margin-yellow"
                    style="
                      width: 70%;
                      height: 2rem;
                      padding: 5px;
                      background: #ffd527;
                      color: black;
                      margin-top: 4rem;
                      text-align: left;
                    "
                  ></div>
                  <div
                    class="d-flex justify-content-between margin-blue"
                    style="
                      width: 10%;
                      height: 2rem;
                      padding: 5px;
                      background: #3953d3;
                      color: black;
                      text-align: left;
                      margin-top: 2.5rem;
                    "
                    :style="{
                      width:
                        calculatorDebts.totalDebt > 0
                          ? Math.ceil(
                              64 /
                                (calculatorDebts.totalDebt /
                                  calculatorDebts.willPay)
                            ) + '%'
                          : '0%',
                    }"
                  ></div>
                </div>

                
              </div>
            </div>
          </card>
          <br>
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="row row-grid justify-content-center">
                <div
                  class="col-lg-3 card-debts"
                  v-for="(debt, id) in calculatorDebts.debts"
                  :key="id"
                >
                  <div v-if="reportListo">
                    <card
                      class="border-0 tam mb-4"
                      shadow
                      body-classes="py-5"
                      style="margin-top: 5px; border-radius: 1rem; height: auto"
                    >
                      <div class="row">
                        <div class="media-body ml-2" style="margin-top: -1rem">
                          <h2 class="text-primary">
                            {{ debt.bank }}
                          </h2>
                        </div>
                        <div class="col-md-3">
                          <div class="g-item" style="margin-top: -1.5rem">
                            <img
                              class="img-responsive"
                              v-bind:src="getBank(debt.bankid).url"
                              style="
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-center" style="margin-top: -10px">
                        <br />
                        <label for="h6amount" style="width: 100%"
                          >Deuda total</label
                        >

                        <h1 id="h6amount" class="text-primary">
                          $
                          {{
                            Number(debt.amount)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h1>
                         <div>
                          <label for="h6amount" style="width: 100%"
                            >Descuento</label
                          >
                          <h1 id="h6amount" class="text-primary">
                            {{
                              formatDecPost(
                              ((1-debt.willPay / debt.amount) * 100)
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }} %
                          </h1>
                        </div>
                        <div>
                          <label for="h6amount" style="width: 100%"
                            >Pagarás</label
                          >
                          <h1 id="h6amount" class="text-primary">
                            $
                            {{
                              formatDecPost(
                              debt.willPay
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}
                          </h1>
                        </div>
                        <label for="h1Lastbant" style="width: 100%"
                          >Último Pago: <b v-if="debt.last_bank_payment_date" class="text-primary">
                            {{ debt.last_bank_payment_date }}
                          </b> <b class="text-primary" v-if="!debt.last_bank_payment_date">
                            ---
                          </b>
                        </label>
                        <div>
                          <label for="h6amount2" style="width: 100%"
                            >Se liquidará en
                            <b class="text-primary">{{ debt.willPayOnMonth }} meses</b> </label
                          >
                        </div>
                        <div v-if="user.type == 1">
                          <label for="h6amount2" style="width: 100%"
                            >Porcentaje total:
                            <b class="text-primary">{{
                              formatDecPost(
                              debt.percentagePerAccount
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }} %</b> </label
                          >
                        </div>
                        <div v-if="user.type == 1"> 
                          <label for="h6amount2" style="width: 100%">Monto total:
                            <b class="text-primary">${{
                              formatDecPost(
                              debt.amountPerAccount
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}</b><br><b class="text-danger">{{debt.amountCouta}}</b></label>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
            <base-button
              block
              v-on:click="saveCalculationNew()"
              style="background-color: #ffd527; border-color: #ffd527; color: black;"
              class="mt-4"
              >Guardar Calculadora
            </base-button>
        </div>
      </card>
    </section>
    <section v-if="edit" class="section section-lg pt-lg-0 mt--150">
      <card style="background-color: #3953d3">
          <div class="row justify-content-center" style="margin-top: 1rem; margin-bottom: 2rem">
            <div class="col">
              <div class="btn-wrapper">
                <base-button
                  tag="a"
                  v-on:click="stop()"
                  class="mb-3 mb-sm-0 text-white"
                  type="danger"
                  icon="far fa-window-close"
                  >Cancelar</base-button
                >
                <base-button
                  tag="a"
                  v-on:click="m_appendDebt_debts()"
                  class="mb-3 mb-sm-0 text-white"
                  type="success"
                  icon="fa fa-plus"
                  >Agrega nueva deuda</base-button
                >
              </div>
            </div>
          </div>
      <div class="container">
        <card>
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="row row-grid justify-content-center">
                <div
                  class="col-lg-4"
                  v-for="(debt, indexdeb) in debts"
                  :key="indexdeb"
                >
                  <card
                    class="border-0 text-center"
                    shadow
                    body-classes="py-5"
                    style="margin-top: 15px"
                  >
                    <div v-if="debt.edit">
                      <base-dropdown
                        class="nav-item"
                        menu-classes="dropdown-menu-lg"
                        style="margin-bottom: 20px"
                      >
                        <a
                          slot="title"
                          class="nav-link"
                          data-toggle="dropdown"
                          role="button"
                        >
                          <base-button type="primary">
                            <i class="far fa-credit-card"></i>
                            {{ debt.bank }}
                          </base-button>
                        </a>
                        <div
                          class="dropdown-menu-inner"
                          style="
                            overflow-x: hidden;
                            overflow-y: scroll;
                            height: 250px;
                          "
                        >
                          <ul>
                            <li
                              v-for="(bank, index) in banks"
                              :key="index"
                              style="list-style-type: none"
                            >
                              <a
                                v-on:click="m_bankSelected(indexdeb, bank)"
                                style="
                                  margin-left: -12px;
                                  margin-top: 3px;
                                  margin-bottom: 3px;
                                "
                                class="media d-flex align-items-center"
                              >
                                <div
                                  class="icon icon-shape bg-secondary rounded-circle text-white"
                                  style="margin-top: 12px"
                                >
                                  <div class="g-item">
                                    <img
                                      class="img-responsive"
                                      v-bind:src="bank.url"
                                      style="
                                        height: 100%;
                                        width: 100%;
                                        object-fit: contain;
                                      "
                                    />
                                  </div>
                                </div>
                                <div
                                  class="media-body ml-2"
                                  style="margin-top: 10px"
                                >
                                  <h6 class="heading text-primary mb-md-1">
                                    {{ bank.name }}
                                  </h6>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </base-dropdown>
                    </div>
                    <div v-else>
                      <div class="row">
                        <div class="col-md-9" style="margin-top: 2px">
                          <h3 class="text-primary mb-md-1">{{ debt.bank }}</h3>
                        </div>
                        <div class="col-md-3">
                          <div class="g-item">
                            <img
                              class="img-responsive"
                              v-bind:src="getBank(debt.bankid).url"
                              style="
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--<icon name="ni ni-check-bold" type="primary" rounded class="mb-4"></icon>-->

                    <div v-if="debt.edit">
                      <label for="amount" style="width: 100%"
                        >¿Cuánto debes?</label
                      >
                      <base-input
                        id="amount"
                        placeholder="0"
                        v-model="debt.amount"
                      ></base-input>
                      <base-slider
                        :range="{ min: 3000, max: 100000 }"
                        v-model="debt.amount"
                      ></base-slider>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <base-button
                              block
                              v-on:click="m_spliceDebt(indexdeb)"
                              type="danger"
                            >
                              <i class="far fa-trash-alt"></i> Borrar
                            </base-button>
                          </div>
                          <div class="col-12" style="margin-top: 1rem">
                            <base-button
                              block
                              v-on:click="m_editDebt(indexdeb, false)"
                              type="success"
                            >
                              <i class="fas fa-plus-circle"></i> Añadir s
                            </base-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else style="margin-top: 20px">
                      <h1 id="h6amount" class="text-primary">
                        $
                        {{
                          Number(debt.amount)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                      </h1>
                      <label for="h6amount" style="width: 100%"
                        >Deuda total</label
                      >
                      <div class="row">
                        <div class="col">
                          <base-button
                            v-on:click="m_editDebt(indexdeb, true)"
                            block
                            type="primary"
                            class="mt-4"
                            >Editar</base-button
                          >
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
            </div>
            <div class="col-12">
              <card style="margin-top: 1rem">
                <div class="container shape-container d-flex">
                  <div class="col px-0">
                    <div class="row">
                      <div class="col-lg-12">
                        <h1 class="display-3 text-black text-center">
                          Programa DB Menos
                        </h1>
                        <h1 class="display-4 text-black text-center">
                          <span>{{ client.name }} {{ client.lastname_1 }}</span>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container" style="max-width: 1440px !important">
                  <div
                    class="row justify-content-center"
                    style="margin-bottom: 40px"
                  >
                    <div class="col-lg-12">
                      <div class="row justify-content-center">
                        <div class="col-lg-6">
                          <card class="border-0" shadow body-classes="py-5">
                            <div class="row justify-content-center">
                              <div class="col">
                                <base-slider
                                  :range="{ min: 6, max: 48 }"
                                  :options="{ step: 1 }"
                                  v-model.number="calcDataPeriod.period"
                                  v-on:input="m_calculateDiscount()"
                                ></base-slider>
                                <h4 class="text-primary text-center">
                                  Duración del programa
                                  {{ calcDataPeriod.period }} meses
                                </h4>
                              </div>
                            </div>
                          </card>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row justify-content-center"
                    style="margin-bottom: 40px"
                  >
                    <div
                      class="col-xl-4 col-lg-4 col-md-4"
                      style="margin-top: 15px"
                    >
                      <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                          <div class="row">
                            <div class="col">
                              <h6
                                class="card-title text-uppercase text-muted mb-0"
                              >
                                Tu total adeudado
                              </h6>
                              <span class="h2 font-weight-bold mb-0"
                                >${{
                                  discount.totalDebt
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }}</span
                              >
                            </div>
                            <div class="col-auto">
                              <div
                                class="icon icon-shape bg-red text-white rounded-circle shadow"
                              >
                                <i class="fas fa-money-bill"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-xl-3 col-lg-3 col-md-3"
                      style="margin-top: 15px"
                    >
                      <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                          <div class="row">
                            <div class="col">
                              <h6
                                class="card-title text-uppercase text-muted mb-0"
                              >
                                Te ahorrarás
                              </h6>
                              <span class="h2 font-weight-bold mb-0"
                                >${{
                                  discount.savings
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }}</span
                              >
                            </div>
                            <div class="col-auto">
                              <div
                                class="icon icon-shape bg-info text-white rounded-circle shadow"
                              >
                                <i class="fas fa-dollar-sign"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-xl-5 col-lg-5 col-md-5"
                      style="margin-top: 15px"
                    >
                      <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                          <div class="row">
                            <div class="col">
                              <h6
                                class="card-title text-uppercase text-muted mb-0"
                              >
                                Duración del programa
                              </h6>
                              <span class="h2 font-weight-bold mb-0"
                                >{{ discount.period }} meses</span
                              >
                            </div>
                            <div class="col-auto">
                              <div
                                class="icon icon-shape bg-info text-white rounded-circle shadow"
                              >
                                <i class="fas fa-clock"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-xl-4 col-lg-4 col-md-6"
                      style="margin-top: 15px"
                    >
                      <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                          <div class="row">
                            <div class="col">
                              <h6
                                class="card-title text-uppercase text-muted mb-0"
                              >
                                Tu depósito mensual
                              </h6>
                              <span class="h2 font-weight-bold mb-0"
                                >${{
                                  Math.ceil(discount.monthlyFee)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }}</span
                              >
                            </div>
                            <div class="col-auto">
                              <div
                                class="icon icon-shape bg-blue text-white rounded-circle shadow"
                              >
                                <i class="fas fa-money-bill"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-xl-4 col-lg-4 col-md-6"
                      style="margin-top: 15px"
                    >
                      <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                          <div class="row">
                            <div class="col">
                              <h6
                                class="card-title text-uppercase text-muted mb-0"
                              >
                                Descuento estimado
                              </h6>
                              <span class="h2 font-weight-bold mb-0"
                                >{{
                                  discount.discount
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }}%</span
                              >
                            </div>
                            <div class="col-auto">
                              <div
                                class="icon icon-shape bg-warning text-white rounded-circle shadow"
                              >
                                <i class="fa fa-percent"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xl-3 col-lg-4"
                      style="margin-top: 15px"
                      hidden
                    >
                      <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                          <div class="row">
                            <div class="col">
                              <h6
                                class="card-title text-uppercase text-muted mb-0"
                              >
                                Tus deudas
                              </h6>
                              <span class="h2 font-weight-bold mb-0">{{
                                discount.debts.length
                              }}</span>
                            </div>
                            <div class="col-auto">
                              <div
                                class="icon icon-shape bg-indigo text-white rounded-circle shadow"
                              >
                                <!--i class="fas fa-check"></i>-->
                              </div>
                            </div>
                          </div>
                          <p class="mt-3 mb-0 text-muted text-sm">
                            <span class="text-nowrap">Puedes agregar más</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container-fluid mt--5">
                  <!-- Table -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card shadow m-5">
                        <div class="card-header border-0">
                          <h3 class="mb-0">Deudas a liquidar</h3>
                        </div>
                        <div class="table-responsive">
                          <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                              <tr>
                                <th class="text-center" scope="col">Deuda</th>
                                <th class="text-center" scope="col">
                                  Acreedor
                                </th>
                                <th class="text-center" scope="col">
                                  Deuda sin dbm
                                </th>
                                <th class="text-center" scope="col">
                                  Valor a pagar con dbm
                                </th>
                                <th class="text-center" scope="col">
                                  Mes a pagar
                                </th>
                                <th class="text-center" scope="col">Avance</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(debt, index) in discount.debts"
                                :key="index"
                              >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">{{ debt.bank }}</td>
                                <td class="text-center">
                                  ${{
                                    debt.amount
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}
                                </td>
                                <td class="text-center">
                                  ${{
                                    debt.willPay
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ Math.ceil(debt.willPayOnMonth) }}
                                </td>
                                <td class="text-center">
                                  {{ debt.o_progress.toFixed(2) }}%
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 text-center">
                      <button
                        class="btn btn-primary"
                        v-on:click="saveCalculationEdit"
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </card>
      </div>
      </card>
    </section>
    <section v-if="editNew" class="section section-lg pt-lg-0 mt--150">
      <card style="background-color: #3953d3">
        <div v-if="!reportListo" class="container">
           <card v-if="!existCalculator">
            <div>
             <base-button
                    tag="a"
                    v-on:click="stopNew()"
                    style="margin-bottom: 20px"
                    class="mb-3 mb-sm-0 text-white"
                    type="danger"
                    icon="far fa-window-close"
                    >Cancelar</base-button
                  >
              <credit-report-table
              v-if="lengthsCreditReports.dbmAccounts > 0"
              title="Cuentas que se pueden agregar al programa"
              :amount="Number(client['total_savings'])"
              :data="
                report && report['dbmAccounts'] ? report['dbmAccounts'] : []
              "
              :pagination="paymentPagination"
              :creditReportAccept="true"
              v-on:onNewItem="newPaymentModal = true"
              v-on:onPaymentSelected="onPaymentSelected"
              v-on:accountClick="accountClick"
              @deleteAccount="deleteAccount"
              style="margin-top: 20px"
            />
            </div>
          
            <base-button
            style="margin-top: 20px"
                              block
                              tag="a"
                              v-on:click="m_calculateDiscountNew()"
                              class="mb-3 mb-sm-0 text-white text-center"
                              type="primary"
                              >Calcular Descuento</base-button
                            >
          </card>
          
          <card v-if="existCalculator" style="margin-top: 10px">
            <div class="row justify-content-center" style="margin-top: 10px">
              <div class="col">
                <div class="btn-wrapper">
                  <base-button
                    tag="a"
                    v-on:click="stopNew()"
                    class="mb-3 mb-sm-0 text-white"
                    type="danger"
                    icon="far fa-window-close"
                    >Cancelar</base-button
                  >
                  <base-button
                    tag="a"
                    v-on:click="m_appendDebt()"
                    class="mb-3 mb-sm-0 text-white"
                    type="success"
                    icon="fa fa-plus"
                    >Agrega nueva deuda</base-button
                  >
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-12">

                  
              <credit-report-table
              v-if="lengthsCreditReports.dbmAccounts > 0"
              title="Cuentas que se pueden agregar al programa"
              :amount="Number(client['total_savings'])"
              :data="
                report && report['dbmAccounts'] ? report['dbmAccounts'] : []
              "
              :pagination="paymentPagination"
              v-on:onNewItem="newPaymentModal = true"
              v-on:onPaymentSelected="onPaymentSelected"
              v-on:accountClick="accountClick"
              @deleteAccount="deleteAccount"
              style="margin-top: 20px"
            />
                <div class="row row-grid justify-content-center">
                  <div
                    class="col-lg-4"
                    v-for="(debt, indexdeb) in debts"
                    :key="indexdeb"
                  >
                    <card
                      class="border-0 text-center"
                      shadow
                      body-classes="py-5"
                      style="margin-top: 15px"
                    >
                      <div v-if="debt.edit">
                        <base-dropdown
                          class="nav-item"
                          menu-classes="dropdown-menu-lg"
                          style="margin-bottom: 20px"
                        >
                          <a
                            slot="title"
                            class="nav-link"
                            data-toggle="dropdown"
                            role="button"
                          >
                            <base-button type="primary">
                              <i class="far fa-credit-card"></i>
                              {{ debt.bank }}
                            </base-button>
                          </a>
                          <div
                            class="dropdown-menu-inner"
                            style="
                              overflow-x: hidden;
                              overflow-y: scroll;
                              height: 250px;
                            "
                          >
                            <ul>
                              <li
                                v-for="(bank, index) in banks"
                                :key="index"
                                style="list-style-type: none"
                              >
                                <a
                                  v-on:click="m_bankSelected(indexdeb, bank)"
                                  style="
                                    margin-left: -12px;
                                    margin-top: 3px;
                                    margin-bottom: 3px;
                                  "
                                  class="media d-flex align-items-center"
                                >
                                  <div
                                    class="icon icon-shape bg-secondary rounded-circle text-white"
                                    style="margin-top: 12px"
                                  >
                                    <div class="g-item">
                                      <img
                                        class="img-responsive"
                                        v-bind:src="bank.url"
                                        style="
                                          height: 100%;
                                          width: 100%;
                                          object-fit: contain;
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="media-body ml-2"
                                    style="margin-top: 10px"
                                  >
                                    <h6 class="heading text-primary mb-md-1">
                                      {{ bank.name }}
                                    </h6>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </base-dropdown>
                      </div>
                      <div v-else>
                        <div class="row">
                          <div class="col-md-9" style="margin-top: 2px">
                            <h3 class="text-primary mb-md-1">
                              {{ debt.bank }}
                            </h3>
                          </div>
                          <div class="col-md-3">
                            <div class="g-item">
                              <img
                                class="img-responsive"
                                v-bind:src="getBank(debt.bankid).url"
                                style="
                                  height: 100%;
                                  width: 100%;
                                  object-fit: contain;
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="debt.edit">
                        <label for="amount" style="width: 100%"
                          >¿Cuánto debes?</label
                        >
                        <base-input
                          id="amount"
                          placeholder="0"
                          v-model="debt.amount"
                        ></base-input>

                        <base-slider
                          :range="{ min: 3000, max: 100000 }"
                          v-model="debt.amount"
                        ></base-slider>

                        <label for="amount" style="width: 100%"
                          >Número de Cuenta</label
                        >
                        <base-input
                          id="account"
                         placeholder="TDC"
                          v-model="debt.account"
                        ></base-input>

                        <label for="last_bank_payment_date" style="width: 100%"
                          >Fecha Último Pago</label
                        >
                        <flat-pickr
                          id="last_bank_payment_date"
                          v-model="debt.last_bank_payment_date"
                          :placeholder="today"
                          class="btn btn-sm"
                          data-vv-as="Fecha"
                          type="primary"
                          name="startPeriod"
                          :config="config"
                          style="
                            margin-top: -0.5rem;
                            margin-bottom: 0.5rem;
                            background-color: #0000;
                            width: 100%;
                          "
                        />
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12">
                              <base-button
                                block
                                v-on:click="m_spliceDebt(indexdeb)"
                                type="danger"
                              >
                                <i class="far fa-trash-alt"></i> Borrar
                              </base-button>
                            </div>
                            <div class="col-12" style="margin-top: 1rem">
                              <base-button
                                block
                                v-on:click="m_editDebtNew(indexdeb, false)"
                                type="success"
                              >
                                <i class="fas fa-plus-circle"></i> Añadir
                              </base-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else style="margin-top: 20px">
                        <h1 id="h6amount" class="text-primary">
                          $
                          {{
                            Number(debt.amount)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h1>
                        <label for="h6amount" style="width: 100%"
                          >Deuda total</label
                        >
                        
                     <label for="h5amount2" style="width: 100%"
                          >Número de Cuenta:
                          <b v-if="debt.account" class="text-primary">
                            {{ debt.account }}
                          </b> <b class="text-primary" v-if="!debt.account">
                            ---
                          </b></label>
                        
                       <label for="h1Lastbant" style="width: 100%"
                          >Último Pago: <b v-if="debt.last_bank_payment_date" class="text-primary">
                            {{ debt.last_bank_payment_date }}
                          </b> <b class="text-primary" v-if="!debt.last_bank_payment_date">
                            ---
                          </b>
                        </label>
                        <div class="row">
                          <div class="col">
                            <base-button
                              v-on:click="m_editDebtNew(indexdeb, true)"
                              block
                              type="primary"
                              class="mt-4"
                              >Editar</base-button
                            >
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <card style="margin-top: 1rem">
                  <div class="container shape-container d-flex">
                    <div class="col px-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <h1 class="display-4 text-black text-center">
                            <base-button
                              block
                              tag="a"
                              v-on:click="m_calculateDiscountNew()"
                              class="mb-3 mb-sm-0 text-white text-center"
                              type="primary"
                              >Calcular Descuento</base-button
                            >
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </card>
        </div>

        <div v-if="reportListo" class="container">
          <div class="row" style="margin-top: 10px; margin-bottom: 20px">
            <div class="col">
              <div class="btn-wrapper">
                <base-button
                  tag="a"
                  v-on:click="backWindowsNew()"
                  class="mb-3 mb-sm-0 text-white"
                  type="danger"
                  icon="fas fa-arrow-left"
                  >Regresar</base-button
                >
              </div>
            </div>
          </div>
          <!--
                    <div class="col">
                        <base-button
                            tag="a"
                            block
                            v-if="bandera"
                            v-on:click="m_calculateDiscount()"
                            class="mb-3 mb-sm-0"
                            type="success"
                        >CONTINUAR</base-button>
                    </div>
                    !-->
          <card
            v-if="reportListo && !notCredit"
            style="margin-top: 1rem; border-radius: 2rem"
          >
            <div
              class="container shape-container d-flex"
              style="margin-top: 10px"
            >
              <div class="col px-0">
                <div class="row">
                  <div class="col-lg-12">
                    <h1 class="display-3 text-black text-center">
                      Programa DB Menos
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="container" style="max-width: 1600px !important">
              <div
                class="row justify-content-center"
                style="margin-bottom: 40px"
              >
                <div class="col-lg-12">
                  <div class="row justify-content-center">
                    <div class="col-lg-12">
                      <card class="border-0">
                        <div class="row justify-content-center">
                          <div class="col text-center">
                            <h1 class="h1 font-weight-bold mb-0 text-success" style="font-size: 2.5rem">
                    {{ periodOptimal }} meses
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                  >
                    Duración del Programa Recomendado
                  </h4>
                            <br />
                            <base-slider
                              :range="{ min: minSlider, max: maxSlider }"
                              :options="{ step: 1 }"
                              v-model.number="period"
                              v-on:input="m_ReturnDiscountNew()"
                            ></base-slider>
                            <h3 class="text-primary text-center">
                              <h1 class="text-primary" style="font-size: 1.5rem">{{ period }} meses</h1>
                             <h4 class="text-primary card-title text-uppercase text-muted mb-0 margin-blue-h6">Duración del Programa</h4>
                            </h3>
                          </div>
                        </div>
                      </card>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center text-center"
                style="margin-bottom: 40px; margin-top: -40px"
              >

              <div class="col-lg-2"></div>
              <div class="col-lg-4" style="margin-top: 30px;">
                  <h1 class="h1 font-weight-bold mb-0" style="font-size: 2.5rem">
                    ${{ (calculatorDebts.monthlyFee) }}
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                  >
                    Depósito mensual
                  </h4>
                </div>
                 <div class="col-lg-4" style="margin-top: 30px;">
                  <h1 class="h1 font-weight-bold mb-0" style="font-size: 2.5rem">
                    {{
                          formatDecPost(calculatorDebts.discount)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}%
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Descuento
                  </h4>
                <br>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-12" v-if="user.type == 0" style="margin-top: 30px">
                  <h1 class="h1 font-weight-bold mb-0">
                   {{
                     (calculatorDebts.realfee)
                        }}%
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Porcentaje de Comisión
                  </h4>
                <br>
                </div>
                <div class="col-lg-4" v-if="user.type == 1" style="margin-top: 30px">
                  <h1 class="h1 font-weight-bold mb-0">
                   {{
                     (calculatorDebts.realfee)
                        }}%
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Porcentaje Mensual
                  </h4>
                <br>
                </div>
                <div class="col-lg-4" style="margin-top: 30px" v-if="user.type == 1">
                  <h1 class="h1 font-weight-bold mb-0">
                   {{formatDecPost(((realCommision * period))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}} %
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Porcentaje de Total
                  </h4>
                <br>
                </div>
                <div class="col-lg-4"  style="margin-top: 30px" v-if="user.type == 1">
                  <h1 class="h1 font-weight-bold mb-0">
                   $ {{formatDecPost(((calculatorDebts.commission * period))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Monto de Comisión 
                  </h4>
                <br>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-12" v-if="user.type == 1" style="margin-top: 30px">
                  <h1 class="h1 font-weight-bold mb-0">$ 499.00</h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Cuota Inicial
                  </h4>
                <br>
                </div>
                <div class="col-lg-3" style="margin-top: 30px; margin-left:4rem">
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Deuda total
                  </h4>
                  <h1 class="h2 font-weight-bold mb-0" style="font-size: 2rem;">
                    {{ formatCurrency(calculatorDebts.totalDebt) }}
                  </h1>

                  <h4
                    style="margin-top: 1rem"
                    class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                  >
                    Pagara
                  </h4>
                  <h1 class="h2 font-weight-bold mb-0" style="font-size: 2rem">
                    {{ formatCurrency(Math.ceil(calculatorDebts.willPay)) }}
                  </h1>
                </div>

                <div class="col-lg-4">
                  <div
                    class="d-flex justify-content-between margin-yellow"
                    style="
                      width: 70%;
                      height: 2rem;
                      padding: 5px;
                      background: #ffd527;
                      color: black;
                      margin-top: 4rem;
                      text-align: left;
                    "
                  ></div>
                  <div
                    class="d-flex justify-content-between margin-blue"
                    style="
                      width: 10%;
                      height: 2rem;
                      padding: 5px;
                      background: #3953d3;
                      color: black;
                      text-align: left;
                      margin-top: 2.5rem;
                    "
                    :style="{
                      width:
                        calculatorDebts.totalDebt > 0
                          ? Math.ceil(
                              64 /
                                (calculatorDebts.totalDebt /
                                  calculatorDebts.willPay)
                            ) + '%'
                          : '0%',
                    }"
                  ></div>
                </div>

                
              </div>
            </div>
          </card>
          <br>
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="row row-grid justify-content-center">
                <div
                  class="col-lg-3 card-debts"
                  v-for="(debt, id) in calculatorDebts.debts"
                  :key="id"
                >
                  <div v-if="reportListo">
                    <card
                      class="border-0 tam mb-4"
                      shadow
                      body-classes="py-5"
                      style="margin-top: 5px; border-radius: 1rem; height: auto"
                    >
                      <div class="row">
                        <div class="media-body ml-2" style="margin-top: -1rem">
                          <h2 class="text-primary">
                            {{ debt.bank }}
                          </h2>
                        </div>
                        <div class="col-md-3">
                          <div class="g-item"  style="margin-top: -1rem">
                            <img
                              class="img-responsive"
                              v-bind:src="getBank(debt.bankid).url"
                              style="
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-center" style="margin-top: -10px">
                        <br />
                        <label for="h6amount" style="width: 100%"
                          >Deuda total</label
                        >

                        <h1 id="h6amount" class="text-primary">
                          $
                          {{
                            Number(debt.amount)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h1>
                         <div>
                          <label for="h6amount" style="width: 100%"
                            >Descuento</label
                          >
                          <h1 id="h6amount" class="text-primary">
                            {{
                              formatDecPost(
                              ((1-debt.willPay / debt.amount) * 100)
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }} %
                          </h1>
                        </div>
                        <div>
                          <label for="h6amount" style="width: 100%"
                            >Pagarás</label
                          >
                          <h1 id="h6amount" class="text-primary">
                            $
                            {{
                              formatDecPost(
                              (Math.ceil(debt.willPay)))
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}
                          </h1>
                        </div>
                        <label for="h1Lastbant" style="width: 100%"
                          >Último Pago: <b v-if="debt.last_bank_payment_date" class="text-primary">
                            {{ debt.last_bank_payment_date }}
                          </b> <b class="text-primary" v-if="!debt.last_bank_payment_date">
                            ---
                          </b>
                        </label>
                        <div>
                          <label for="h6amount2" style="width: 100%"
                            >Se liquidará en
                            <b class="text-primary">{{ debt.willPayOnMonth }} meses</b></label
                          >
                        </div>
                         <div v-if="user.type == 1">
                          <label for="h6amount2" style="width: 100%"
                            >Porcentaje total:
                            <b class="text-primary">{{
                              formatDecPost(
                              debt.percentagePerAccount
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }} %</b> </label
                          >
                        </div>
                        <div v-if="user.type == 1"> 
                          <label for="h6amount2" style="width: 100%">Monto total:
                            <b class="text-primary">${{
                              formatDecPost(
                              debt.amountPerAccount
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}</b><br><b b class="text-danger">{{debt.amountCouta}}</b></label>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          
            <base-button
              block
              v-on:click="saveCalculationNew()"
              style="background-color: #ffd527; border-color: #ffd527; color: black;"
              class="mt-4"
              >Guardar Calculadora
            </base-button>
        </div>
      </card>
    </section>
    <section
      v-if="!createCalculation && (calculos.calculator_new && !editNew)"
      class="container-fluid mt--9"
    >
                <base-button
                  tag="a"
                  v-on:click="refreshCalculator()"
                  class="mb-3 mb-sm-0 text-white"
                  type="warning"
                  v-if="isAdmin || isManagerSeller"
                  icon="fas fa-redo"
                  >Restablecer Calculadora</base-button
                >
      <div class="container">
        <card>
          <div class="row" style="margin-top: 10px; margin-bottom: 20px">
            <div class="col">
              <div class="btn-wrapper">
                <base-button
                  tag="a"
                  v-on:click="backWindows()"
                  class="mb-3 mb-sm-0 text-white"
                  type="danger"
                  icon="fas fa-arrow-left"
                  >Regresar</base-button
                > 
                <base-button
                  tag="a"
                  v-if="calculos.calculator_new && !statusAudit && editarCal"
                  v-on:click="newCalculadoraNew()"
                  class="mb-3 mb-sm-0 text-white"
                  type="info"
                  icon="far fa-edit"
                  >Editar Calculadora</base-button
                >
                <base-button
                  tag="a"
                  v-if="calculos.calculator_new && !statusAudit && editarCal"
                  v-on:click="sendPropositionv2()"
                  class="mb-3 mb-sm-0 text-white"
                  type="success"
                  icon="fas fa-paper-plane"
                  >Enviar Propuesta</base-button
                >
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Total adeudado
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(calculos.totalDebt)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-red text-white rounded-circle shadow"
                      >
                        <i class="fas fa-money-bill"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Ahorros
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(Math.ceil(calculos.savings))
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-success text-white rounded-circle shadow"
                      >
                        <i class="fas fa-dollar-sign"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Duración del programa
                      </h6>
                      <span v-if="calculos.p" class="h2 font-weight-bold mb-0"
                        >{{ calculos.p }} meses</span
                      >
                      <span
                        v-if="calculos.period"
                        class="h2 font-weight-bold mb-0"
                        >{{ calculos.period }} meses</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-info text-white rounded-circle shadow"
                      >
                        <i class="fas fa-clock"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Depósito mensual
                      </h6>
                      <span
                        v-if="calculos.monthlyFee"
                        class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(
                            calculos.monthlyFee
                              ? Math.ceil(calculos.monthlyFee)
                              : Math.ceil(calculos.monthlyFee)
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                      <span
                        v-if="calculos.mfee"
                        class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(
                            calculos.mfee ? calculos.mfee : calculos.mfee
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-blue text-white rounded-circle shadow"
                      >
                        <i class="fas fa-coins"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Descuento estimado
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >{{
                          formatDecPost(calculos.discount)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}%</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-warning text-white rounded-circle shadow"
                      >
                        <i class="fa fa-percent"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Deudas
                      </h6>
                      <span
                        v-if="calculos.debts"
                        class="h2 font-weight-bold mb-0"
                        >{{
                          calculos && calculos.debts
                            ? calculos.debts.length
                            : "0"
                        }}</span
                      >
                      <span
                        v-if="calculos.accounts"
                        class="h2 font-weight-bold mb-0"
                        >{{
                          calculos && calculos.accounts
                            ? calculos.accounts.length
                            : "0"
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-indigo text-white rounded-circle shadow"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="calculos.calculator_new"
            class="row justify-content-center text-center"
          >
            <div class="col-xl-10 col-lg-10 col-md-10">
              <div class="card shadow">
                <div class="card-header border-0">
                  <h3 class="mb-0">Deudas a liquidar</h3>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center" scope="col">Deuda</th>
                        <th class="text-center" scope="col">Acreedor</th>
                        <th class="text-center" scope="col">Deuda sin dbm</th>
                        <th class="text-center" scope="col">
                          Valor a pagar con dbm
                        </th>
                        <th class="text-center" scope="col">Mes a pagar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(debt, index) in calculos.debts"
                        :key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ debt.bank }}</td>
                        <td class="text-center">
                          ${{
                            formatDecPost(debt.amount)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </td>
                        <td class="text-center">
                          ${{
                            formatDecPost(Math.ceil(debt.willPay))
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            debt.willPayOnMonth
                              ? debt.willPayOnMonth
                              : debt.willPayOnMonth
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="calculos.debts && !calculos.calculator_new"
            class="row justify-content-center text-center"
          >
            <div class="col-xl-10 col-lg-10 col-md-10">
              <div class="card shadow">
                <div class="card-header border-0">
                  <h3 class="mb-0">Deudas a liquidar</h3>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center" scope="col">Deuda</th>
                        <th class="text-center" scope="col">Acreedor</th>
                        <th class="text-center" scope="col">Deuda sin dbm</th>
                        <th class="text-center" scope="col">
                          Valor a pagar con dbm
                        </th>
                        <th class="text-center" scope="col">Mes a pagar</th>
                        <th class="text-center" scope="col">Avance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(debt, index) in calculos.debts" :key="index">
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ debt.bank }}</td>
                        <td class="text-center">
                          ${{
                            formatDecPost(debt.amount)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </td>
                        <td class="text-center">
                          ${{
                            debt.willPay
                              ? formatDecPost(Math.ceil(debt.willPay))
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : formatDecPost(Math.ceil(debt.will_pay))
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            debt.willPayOnMonth
                              ? debt.willPayOnMonth
                              : debt.will_pay_on
                          }}
                        </td>
                        <td class="text-center">
                          {{ formatDecPost(debt.o_progress) }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
    <section
      v-if="!createCalculation && (calculos.debts && !edit && !calculos.calculator_new)"
      class="container-fluid mt--9"
    >
                <base-button
                  tag="a"
                  v-on:click="refreshCalculator()"
                  class="mb-3 mb-sm-0 text-white"
                  type="warning"
                  v-if="isAdmin"
                  icon="fas fa-redo"
                  >Restablecer Calculadora</base-button
                >
      <div class="container">
        <card>
          <div class="row" style="margin-top: 10px; margin-bottom: 20px">
            <div class="col">
              <div class="btn-wrapper">
                <base-button
                  tag="a"
                  v-on:click="backWindows()"
                  class="mb-3 mb-sm-0 text-white"
                  type="danger"
                  icon="fas fa-arrow-left"
                  >Regresar</base-button
                >
                <base-button
                  tag="a"
                  v-if="calculos.debts && !statusAudit && editarCal"
                  v-on:click="newCalculadora()"
                  class="mb-3 mb-sm-0 text-white"
                  type="info"
                  icon="far fa-edit"
                  >Editar</base-button
                >
                <base-button
                  tag="a"
                  v-if="calculos.debts && !statusAudit && editarCal"
                  v-on:click="sendProposition()"
                  class="mb-3 mb-sm-0 text-white"
                  type="success"
                  icon="fas fa-paper-plane"
                  >Enviar Propuesta</base-button
                >
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Total adeudado
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(calculos.totalDebt)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-red text-white rounded-circle shadow"
                      >
                        <i class="fas fa-money-bill"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Ahorros
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(Math.ceil(calculos.savings))
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-success text-white rounded-circle shadow"
                      >
                        <i class="fas fa-dollar-sign"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Duración del programa
                      </h6>
                      <span v-if="calculos.p" class="h2 font-weight-bold mb-0"
                        >{{ calculos.p }} meses</span
                      >
                      <span
                        v-if="calculos.period"
                        class="h2 font-weight-bold mb-0"
                        >{{ calculos.period }} meses</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-info text-white rounded-circle shadow"
                      >
                        <i class="fas fa-clock"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Depósito mensual
                      </h6>
                      <span
                        v-if="calculos.monthlyFee"
                        class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(
                            calculos.monthlyFee
                              ? Math.ceil(calculos.monthlyFee)
                              : Math.ceil(calculos.monthlyFee)
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                      <span
                        v-if="calculos.mfee"
                        class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(
                            calculos.mfee ? calculos.mfee : calculos.mfee
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-blue text-white rounded-circle shadow"
                      >
                        <i class="fas fa-coins"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Descuento estimado
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >{{
                          formatDecPost(calculos.discount)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}%</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-warning text-white rounded-circle shadow"
                      >
                        <i class="fa fa-percent"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Deudas
                      </h6>
                      <span
                        v-if="calculos.debts"
                        class="h2 font-weight-bold mb-0"
                        >{{
                          calculos && calculos.debts
                            ? calculos.debts.length
                            : "0"
                        }}</span
                      >
                      <span
                        v-if="calculos.accounts"
                        class="h2 font-weight-bold mb-0"
                        >{{
                          calculos && calculos.accounts
                            ? calculos.accounts.length
                            : "0"
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-indigo text-white rounded-circle shadow"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="calculos.debts"
            class="row justify-content-center text-center"
          >
            <div class="col-xl-10 col-lg-10 col-md-10">
              <div class="card shadow">
                <div class="card-header border-0">
                  <h3 class="mb-0">Deudas a liquidar</h3>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center" scope="col">Deuda</th>
                        <th class="text-center" scope="col">Acreedor</th>
                        <th class="text-center" scope="col">Deuda sin dbm</th>
                        <th class="text-center" scope="col">
                          Valor a pagar con dbm
                        </th>
                        <th class="text-center" scope="col">Mes a pagar</th>
                        <th class="text-center" scope="col">Avance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(debt, index) in calculos.debts" :key="index">
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ debt.bank }}</td>
                        <td class="text-center">
                          ${{
                            formatDecPost(debt.amount)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </td>
                        <td class="text-center">
                          ${{
                            debt.willPay
                              ? formatDecPost(Math.ceil(debt.willPay))
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : formatDecPost(Math.ceil(debt.will_pay))
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            debt.willPayOnMonth
                              ? debt.willPayOnMonth
                              : debt.will_pay_on
                          }}
                        </td>
                        <td class="text-center">
                          {{ formatDecPost(debt.o_progress) }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
       <modal :show.sync="modificationModal" @close="modificationModal" :large="true">
      <template slot="header">
        <h2 class="mb-0" style="margin-top: 20px; margin-left: 10px">
          Modificando {{`${modificationType}: Cliente ${modificationData.user}`}}
        </h2>
      </template>
      <base-input alternative
        label="Comentario"
        placeholder="Escribe un comentario..."
        v-model="modificationData.comments"/>
      <template slot="footer">
        <base-button type="primary" @click="setDeleteHistory()">Aplicar</base-button>
      </template>
    </modal>
     <modal :show.sync="accountDetailsModal" @close="closeAccountModal()" :large="true">
      <template slot="header">
        <div class="row align-items-center">
          <div class="col">
            <h2 class="mb-0" id="acountModal" style="margin-top: 20px; margin-left: 10px">
              Editar cuenta
            </h2>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col">
          <base-input alternative input-classes="form-control-alternative"
            label="Acreedor de la deuda"
            v-model="accountInModal.NombreOtorgante"/>
        </div>
        <div class="col">
          <base-input alternative input-classes="form-control-alternative"
            label="Número de cuenta"
            v-model="accountInModal.NumeroCuentaActual"/>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input alternative input-classes="form-control-alternative"
            label="Total adeudado"
            v-model="accountInModal.MontoPagar"/>
        </div>
        <div class="col">
          <label class="form-control-label">Último pago bancario</label>
          <flat-pickr class="form-control form-control-alternative" data-vv-as="Fecha" name="date" :config="config" style="background-color: #ffffff"
            v-model="accountInModal.FechaUltimoPago"/>
        </div>
      </div>

      <template slot="footer">
        <base-button type="primary" @click="updateAccount(accountInModal)">
          {{accountInModal? 'Agregar':'Guardar'}}
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";
import { V } from "@/V";
 import modal from "@/components/Modal"
import CreditReportTable from "./Tables/CreditReportTable";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "home",
  components: {
    BaseDropdown,
    flatPickr,
    CreditReportTable,
    modal
  },
  async mounted(){
     this.$showLoader(true);
    let userId = this.$route.query.id;
    await this.getClientProfile(userId);
    await this.getProgramCalculation(userId);
    await this.getDay();
     this.$showLoader(false);
  },
  async created() {
    let userId = this.$route.query.id;
    await this.getStatusAudit(userId)
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
  },
  data() {
    return {
      statusAudit: false,
      realCommision:"",
      modificationData: {},
      modificationModal: false,
      modificationType: '',
      reportListo: false,
      sliderRange: "",
      maxSlider: "",
      minSlider: "",
      accountDetailsModal: false,
      debts: [
        {
          bankid: 0,
          bank: "Selecciona el banco",
          amount: 0,
          edit: true,
          last_bank_payment_date: 0,
          account: 0 
        },
      ],
      accounts: [
        {
          account: "",
          addProgram: false,
          amount: "",
          bank: "",
          bankid: "",
          discount: "",
          due_months: "",
          last_bank_payment_date: "",
          m: "",
          will_pay_on_month: "",
        },
      ],
      calculatorDebts: [
        {
          accounts: [
            {
              account: "",
              addProgram: false,
              amount: "",
              bank: "",
              bankid: "",
              discount: "",
              due_months: "",
              last_bank_payment_date: "",
              m: "",
              will_pay_on_month: "",
              monthCommission:"",
              amountPerAccount:"",
              percentagePerAccount:"",          
                },
          ],
          mfee: "",
          p: "",
          savings: "",
          totalDebt: "",
          willPay: ""
        },
      ],
      today: "",
      config: {
        maxDate: "today",
        dateFormat: "Y-m-d ",
      },
      client: {},
      period: "",
      periodOptimal: "",
      overf: "hidden",
      banksmodal: false,
      calcDataPeriod: {
        period: 18,
      },
      clientCalculations: {
        totalDebt: 0,
        savings: 0,
        mfee: 0,
        monthlyFee: 0,
        discount: 0,
        debts: [],
      },
      calculos: {
        totalDebt: 0,
        savings: 0,
        mfee: 0,
        monthlyFee: 0,
        discount: 0,
        debts: [],
      },
      discount: {
        debts: [],
        totalDebt: 0,
        savings: 0,
        monthlyFee: 0,
        mfee: 0,
        discount: 0,
        o_progress: 0,
      },
      createCalculation: false,
      new_calculator:false,
      calculation: "",
      dataCalculation: {},
      edit: false,
      editNew: false,
      notCredit: false,
      willPaySum:[],
      willPayOnMonth:[],
      percentagePerAccount:[],
      amountPerAccount:[],
      amountCouta:"",
      isEspecialCommision: false,
      newDebts: [],
      isManagerSeller:false,
      tokenFCM: null,
      commissionPercentage: "",
      editClientPhone: null,
      checkInactive: false,
      existReport: false,
      creditReportBad: false,
      notAccounts: false,
      isAdmin: false,
      isSeller: false,
      isService: false,
      isManagerService: false,
      isAuditor: false,
      fileType: null,
      accountInModal: {},
      newActivityNegotiation: {},
      curpPDF: null,
      getCovid: null,
      getPromise: null,
      getLastConsultant: null,
      editarCal:false,
      fileTypes: [
        "ID",
        "ID_B",
        "ADDRESS",
        "CREDIT_INFO",
        "CONTRACT",
        "CURP",
        'QUOTE',
        'ADDENDUM',
        'LETTER_DBM',
        "FILE",
      ],
      dateCreatedBC:"",
      creditReport:{},
      typeModification:"",
      report: [],
      messageReport: "",
      lengthsCreditReports: {
        dbmAccounts: null,
        otherAccounts: null,
        toAddAccounts: null,
        dbmAccountsBuro: null,
      },
      creditReportC: [],
      paymentPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      existCalculator:false,
      errorData:false,
      sumAccountsBC:false
    };
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    mxStates() {
      return this.$store.state.mxStates;
    },
    sellers() {
      return this.$store.state.sellers;
    },
    customerService() {
      return this.$store.state.customerService;
    },
    banks() {
      return this.$store.state.banks;
    },
    userTracingStatus() {
      return this.$store.state.userTraceStatus;
    },
    leadStatus() {
      return this.$store.state.leadStatus;
    },
    userActionsCatalog() {
      return this.$store.state.userActionsCatalog;
    },
    userInactiveCatalog() {
      return this.$store.state.userInactiveCatalog;
    },
    economicActivities() {
      let activities = this.$store.state.economicActivities;
      if (!activities) this.$store.dispatch("downloadEconomicActivities");
      return activities;
    },
  },
  methods: {
    formatCurrency(value) {
      return this.$formatCurrency(value);
    },
    getDay() {
      let f = new Date();
      let month = f.getMonth() + 1;
      if (month < 10) month = "0" + month;
      let today = new Date(f.getFullYear(), f.getMonth() - 1, f.getDay());
      this.today = this.$moment(today).format("YYYY-MM-DD");
      //this.today = f.getFullYear() + "-" + month + "-" + f.getDate();
    },
    setCalc(calculos) {
      this.calculos = calculos;
    },
    getStatusAudit(clientId){
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_VIEW_USER_AUDITOR
      this.$showLoader(true);
      this.$axios.post(url, {user: clientId}).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          console.log(data.rows)
          if(data.rows.complete_docs === "COMPLETO" && (this.isSeller || this.isManagerSeller)){
            this.statusAudit = true;
          }else{
            console.log('NO')
          }
          this.$forceUpdate();
        }
      });
    },
    getProgramCalculation(clientId) {
      this.$showLoader(true);
      let url =
        this.$v.SERVER +
        this.$v.SELLER_OPS.GET_PROGRAM_CALCULATION +
        "?user=" +
        clientId;
      this.$axios.get(url).then((response) => {
        let data = response.data;
            this.$showLoader(false);
        if (data[this.$v.API_SUCCESS]) {
          if (data.row["calculation"]) {
            this.dataCalculation = data.row;
            this.clientCalculations = data.row["calculation"];
            this.calculos = this.clientCalculations;
            this.createCalculation = false;
            this.existCalculator = true;
            this.editarCal=true;
            this.getCreditReport();
            this.$forceUpdate();
          }
        } else {
            switch (data[this.$v.API_REASON]) {
              case 1:
                this.clientCalculations = null;
                this.createCalculation = true;
                this.existCalculator = false;
                this.getCreditReport()
                break;
              default:
                this.$vs.notification(this.$notification(
                'danger',
                 'Algó salio mal',
                 'Intenta nuevamente'
                     ));
                break;
            }
          }
      });
    },
    getClientProfile(clientId) {
      this.$showLoader(true);
      return new Promise((resolve) => {
        let url =
          this.$v.SERVER + this.$v.SELLER_OPS.GET_PROFILE + "?id=" + clientId;
        this.$axios.get(url).then((response) => {
            this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.client = response.data[this.$v.API_ROW];
            resolve(true);
          }
          resolve(false);
        });
      });
    },
    refreshCalculator(){
      let data = this.dataCalculation;
      this.newModification(data,{id:9, name:'Calculadora'})
    },
     newModification(data,typeMod){
      this.modificationType = typeMod.name
      this.modificationModal = true;
      this.modificationData.last_id = data.id;
      this.modificationData.account = data.account;
      this.modificationData.type = typeMod.id;
      this.modificationData.user = data.user;
      this.modificationData.file_type = data.type;
      delete data.accountsTableIndex;
      delete data.id;
      delete data.user;
      delete data.type;
      delete data.filename;
      this.modificationData.json_details = JSON.parse(JSON.stringify(data));
    },
   setDeleteHistory(){
     let userId = this.$route.query.id;
      let url = this.$v.SERVER + this.$v.MANAGER_OPS.SET_DELETE_HISTORY;
      this.$showLoader(true);
      this.$axios.post(url, this.modificationData).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          switch (this.modificationData.type){
            case 9: this.getProgramCalculation(userId); break
          }
          this.dataCalculation = []
            this.$vs.notification(this.$notification(
                'success',
                 'Eliminación exitosamente'
                     ));
            this.$forceUpdate();
        }
      });
      this.modificationModal = false;
      this.modoficationData = null;
    },
    sendProposition() {
      let url =
        this.$v.SERVER + this.$v.SELLER_OPS.CREATE_CALC_SELL_PROPOSITION;
      let request = this.dataCalculation;
      request.addr_street = this.client.addr_street;
      request.addr_sublocality = this.client.addr_sublocality;
      request.locality = this.client.locality;
      request.addr_postal_code = this.client.addr_postal_code;
      request.consultant = this.getConsultantName(this.client.consultant);
      request.first_payment = this.client.first_payment;
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$showLoader(false);
          this.$vs.notification(this.$notification(
                'success',
                 'Propuesta enviada exitosamente'
                     ));
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algó salio mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
     sendPropositionv2() {
      let url =
        this.$v.SERVER + this.$v.SELLER_OPS.CREATE_CALC_SELL_PROPOSITION;
      let request = this.dataCalculation;
      request.name = this.client.name;
      request.calculator_new = this.dataCalculation.calculation.calculator_new;
      request.lastname = this.client.lastname_1;
      request.lastname_1 = this.client.lastname_2;
      request.addr_street = this.client.addr_street;
      request.addr_sublocality = this.client.addr_sublocality;
      request.locality = this.client.locality;
      request.addr_postal_code = this.client.addr_postal_code;
      request.consultant = this.getConsultantName(this.client.consultant);
      request.first_payment = this.client.first_payment;
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$showLoader(false);
           this.$vs.notification(this.$notification(
                'success',
                 'Propuesta enviada exitosamente'
                     ));
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algó salio mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    newCalculadora() {
      this.discount = this.clientCalculations;
      this.debts = this.clientCalculations.debts;
      this.m_calculateDiscount();
      this.edit = true;
    },
    newCalculadoraNew() {
      this.discount = this.clientCalculations;
      this.debts = this.clientCalculations.debts;
      this.editNew = true;
    },
    formatDecPost(val, pos) {
      if (!pos) pos = 2;
      if (val && Number(val)) return Number(val).toFixed(2);
      return "0.00";
    },
    m_appendDebt() {
      this.debts.push({
        bankid: 0,
        bank: "Selecciona el banco",
        amount: 0,
        edit: true,
      });
    },
    m_appendDebtBURO(acc) {
      console.log(acc)
      this.debts.push({
        bankid: acc.bank.id,
        bank: acc.bank.name,
        amount: acc.MontoPagar,
        account:acc.NumeroCuentaActual,
        last_bank_payment_date: this.interprateDate(acc.FechaUltimoPago),
        edit: false,
      });
    },
    m_appendDebt_debts() {
      this.clientCalculations.debts.push({
        bankid: 0,
        bank: "Selecciona el banco",
        amount: 0,
        edit: true,
      });
    },
    backWindows() {
      window.history.back();
    },
    backWindowsNew() {
      this.reportListo = false;
    },
    stop() {
      this.edit = false;
    },
    stopNew() {
      this.editNew = false;
    },
    m_spliceDebt(index) {
      console.log(this.discount.debts)
      if (this.discount.debts.length == 1) {
         this.$vs.notification(this.$notification(
                'danger',
                 'Se requiere al menos una cuenta para poder calcular'
                     ));
      } else {
          this.discount.debts.splice(index, 1);
         // this.m_calculateDiscount();
        this.$forceUpdate();
      }
    },
    m_spliceDebtDebts(index) {
      console.log(this.debts)
          this.debts.splice(index, 1);
         // this.m_calculateDiscount();
        this.$forceUpdate();
      
    },
    m_spliceDebtNew(index) {
      console.log(this.discount.debts)
      if (this.discount.debts.length == 1) {
         this.$vs.notification(this.$notification(
                'danger',
                 'Se requiere al menos una cuenta para poder calcular'
                     ));
      } else {
        console.log(index)
        this.discount.debts.splice(index, 1);
        this.$forceUpdate();
      }
    },
    m_bankSelected(debt_index, bank) {
      this.debts[debt_index].bank = bank.name;
      this.debts[debt_index].bankid = bank.id;
    },
    m_editDebt(index, edit) {
      setTimeout(() => {
        this.m_calculateDiscount();
        this.debts[index].edit = edit;
      }, 500);

      this.$forceUpdate();
    },
    m_editDebtNew(index, edit) {
      if(edit){
      setTimeout(() => {
        this.debts[index].edit = edit;
      }, 1);
      this.$forceUpdate();
      }else{
      let account =  document.getElementById("account").value;
      let last_bank_payment_date    = document.getElementById("last_bank_payment_date").value;
      if(this.debts[index].edit == true && (this.debts[index].bankid == 0 || account == "" || this.debts[index].amount == 0 
      || last_bank_payment_date == "")){
         this.$vs.notification(this.$notification(
                'danger',
                 'Faltan datos por ingresar'
                     ));
      }else{
      setTimeout(() => {
        this.debts[index].edit = edit;
      }, 1);

      this.$forceUpdate();
      }
      }
    },
    getBank(id) {
      for (let i = 0; i < this.banks.length; i++)
        if (this.banks[i].id === id) return this.banks[i];
      return null;
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id)
          return this.sellers[i].name + " " + this.sellers[i].lastname_1;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return (
            this.customerService[i].name +
            " " +
            this.customerService[i].lastname_1
          );
      return "- - -";
    },
    m_calculateDiscount() {
      for (let d = 0; d < this.debts.length; d++) {
        let debt = this.debts[d];
        if (debt.bankid === 0) {

         this.$vs.notification(this.$notification(
                'danger',
                 'Necesitas seleccionar el banco correspondiente'
                     ));
          return false;
        } else if (debt.amount < 1500) {
          this.$vs.notification(this.$notification(
                'danger',
                 'Tiene una deuda menor a $1500, solo podemos negociar mayores'
                     ));
          return false;
        } else if (debt.last_bank_payment_date === "") {
          this.$vs.notification(this.$notification(
                'danger',
                 'Necesitas añadir la ultima fecha de pago'
                     ));
          return false;
        }
      }
      let _debts = JSON.parse(JSON.stringify(this.debts));
      for (let d = 0; d < _debts.length; d++) {
        _debts[d].amount = Number(_debts[d].amount);
        delete _debts[d].edit;
      }

      let _data = {
        name: this.client.name,
        lastname: this.client.lastname_1,
        userid: this.client.id,
        period: this.calcDataPeriod.period,
        debts: _debts,
      };

      if (_data.name === undefined) _data.name = "";
      if (_data.lastname_1 === undefined) _data.lastname_1 = "";

      localStorage.setItem("calcData", JSON.stringify(_data));
      this.getDiscount();
      this.$axios.post(V.SERVER + V.OPS.CALCULATE, _data).then(
        (res) => {
          if (res.data.success) {
            let calcdis = res.data.row;
            localStorage.setItem("calckey", JSON.stringify(calcdis));
          } else {
            this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'intenta nuevamente'
                     ));
          }
        },
        (er) => {
           this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'intenta nuevamente'
                     ));
          console.log(er);
        }
      );
    },
     m_ReturnDiscountNew() {
            this.accounts = [];
            this.sliderRange = [];
            this.periodOptimal=this.periodOptimal
            this.period = this.period
            this.realCommision = this.realCommision;
            this.calculatorDebts = this.calculatorDebts;
            this.percentagePerAccount = []
            this.amountPerAccount = []
              this.reportListo = true;
              for (let i = 0; i < this.calculatorDebts.length; i++) {
                for (let p = 0; p < this.calculatorDebts.length; p++) {
                  if (this.period === this.calculatorDebts[p].period) {
                    this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                    this.calculatorDebts.monthlyFee = (Math.ceil(this.calculatorDebts[p].monthlyFee)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    this.calculatorDebts.willPay = (this.calculatorDebts[p].willPay);
                    this.calculatorDebts.commission = this.calculatorDebts[p].commission;
                    this.calculatorDebts.totalDebt = this.calculatorDebts[p].totalDebt;
                    this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                    this.calculatorDebts.realfee = (this.calculatorDebts[p].realfee).toFixed(4)
                  }
                }
              }
              let myArray = this.sliderRange;
              this.maxSlider = Math.max.apply(Math, myArray);
              this.minSlider = Math.min.apply(Math, myArray);
              for (let i=0 ; i<this.calculatorDebts.debts.length ; i++) {
                let a = this.calculatorDebts.debts[i]
                let month = a.willPayOnMonth 
                if (i > 0) 
                    month -= this.calculatorDebts.debts[i-1].willPayOnMonth
                    this.calculatorDebts.debts[i].monthCommission = month
                    this.calculatorDebts.debts[i].percentagePerAccount = (month * this.realCommision)
                    this.calculatorDebts.debts[i].amountPerAccount = (month * this.calculatorDebts.commission)

                    if(this.calculatorDebts.debts[0]){
                      this.calculatorDebts.debts[0].amountCouta = " - $499.00"
                    }
            }
                    
              this.$forceUpdate();
    },
    m_calculateDiscountNew() {
      this.$showLoader(true);
      this.errorData=true;
      let newDebts = new Array();
      if(!this.existCalculator){
        let _debts = null;
        console.log(this.debts.length)
        if(!this.sumAccountsBC){
           _debts = JSON.parse(JSON.stringify(this.debts));
          for (let d = 0; d < _debts.length; d++) {
            _debts[d].amount = Number(_debts[d].amount);
            delete _debts[d].edit;
          }
          newDebts = _debts
           for (let i = 0; i < this.report.dbmAccounts.length; i++) {
            newDebts.push({
              bankid: Number(this.report.dbmAccounts[i].bank.id),
              amount: Number(this.report.dbmAccounts[i].MontoPagar),
              last_bank_payment_date: this.interprateDate(
                this.report.dbmAccounts[i].FechaUltimoPago
              ),
              account:this.report.dbmAccounts[i].NumeroCuentaActual
            });
          }

           console.log(newDebts);
        }else{
           _debts = JSON.parse(JSON.stringify(this.report.dbmAccounts));

      for (let d = 0; d < _debts.length; d++) {
        _debts[d].amount = Number(_debts[d].amount);
        delete _debts[d].edit;
      }
      for (let i = 0; i < _debts.length; i++) {
            newDebts.push({
              bankid: Number(_debts[i].bank.id),
              amount: Number(_debts[i].MontoPagar),
              last_bank_payment_date: this.interprateDate(
                _debts[i].FechaUltimoPago
              ),
              account:_debts[i].NumeroCuentaActual
            });
          }
        }
      }else{
      let _debts = JSON.parse(JSON.stringify(this.debts));
      for (let d = 0; d < _debts.length; d++) {
        _debts[d].amount = Number(_debts[d].amount);
        delete _debts[d].edit;
      }
       newDebts = _debts
      }
      for (let d = 0; d < newDebts.length; d++) {
        if (newDebts[d].bankid == 0) {
          this.$showLoader(false);
           this.$vs.notification(this.$notification(
                'danger',
                 'Necesitas seleccionar el banco correspondiente'
                     ));
                     this.errorData=false;
                     break;
        } else if (newDebts[d].amount < 1500) {
          this.$showLoader(false);
          this.$vs.notification(this.$notification(
                'danger',
                 'Tiene una deuda menor a $1500, solo podemos negociar mayores'
                     ));
                     this.errorData=false;
                     break;
        } else if (newDebts[d].last_bank_payment_date == "") {
          this.$showLoader(false);
          this.$vs.notification(this.$notification(
                'danger',
                 'Necesitas añadir la ultima fecha de pago'
                     ));
                     this.errorData=false;
                     break;
        }else if (newDebts[d].account == 0) {
          this.$showLoader(false);
          this.$vs.notification(this.$notification(
                'danger',
                 'Necesitas añadir el número de cuenta'
                     ));
                     this.errorData=false;
                     break;
        }
      }

        console.log(this.errorData)
        if(this.errorData){
          console.log('Entro')
      let url = V.SERVER + V.SELLER_OPS.GENERATE_PROGRAM_CALCULATION + "?user=" + this.client.id;
      this.$axios
        .post(url,newDebts)
        .then(
          (res) => {
            let data = res.data;
            this.calculatorDebts = [];
            this.accounts = [];
            this.sliderRange = [];
            if(data.rows == 'El Monto Total de Deuda del cliente debe ser mayor a 10k'){
               this.$showLoader(false);
              this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 data.rows
                     ));
            }else if(!data.rows){
              this.$showLoader(false);
              this.$vs.notification(this.$notification(
                              'danger',
                              'Algo salió mal',
                              data.rows
                                  ));

            }else{
            this.period = data.rows.optimalperiod;
             this.realCommision = data.rows.realfee
            console.log(this.realCommision)
            this.periodOptimal = data.rows.optimalperiod;
            this.$showLoader(false);
            if (data.success) {
              this.reportListo = true;
              for (let i = 0; i < data.rows.programs.length; i++) {
                this.sliderRange.push(data.rows.programs[i].period);
                data.rows.programs[i].addProgram = true;
                this.calculatorDebts.push(data.rows.programs[i]);
                this.accounts = data.rows.programs[i].debts;
                this.accounts.sort(function (a, b) {return a.amount - b.amount;});
                for (let p = 0; p < this.calculatorDebts.length; p++) {
                  if (this.period === this.calculatorDebts[p].period) {
                    this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                    this.calculatorDebts.monthlyFee = (Math.ceil(this.calculatorDebts[p].monthlyFee)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    this.calculatorDebts.willPay = (this.calculatorDebts[p].willPay);
                    this.calculatorDebts.commission = this.calculatorDebts[p].commission;
                    this.calculatorDebts.totalDebt = this.calculatorDebts[p].totalDebt;
                    this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                    this.calculatorDebts.realfee = (this.calculatorDebts[p].realfee).toFixed(4);
                  }
                }
              }
              let myArray = this.sliderRange;
              this.maxSlider = Math.max.apply(Math, myArray);
              this.minSlider = Math.min.apply(Math, myArray);    
              this.$forceUpdate();
            } else {
              this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'intenta nuevamente'
                     ));
            }
          }
            } 
        );  
        }
    },
    async m_registerMarketing() {
      let marketing = this.$route.query.marketing;
      this.$store.commit("setMarketing", marketing);
      let url = V.SERVER + V.OPS.INSERT + V.TABLES.MARKETING_LEADS;
      await this.$axios.post(url, { from: marketing });
    },
    m_getCurrentProgress(progress) {
      this.progress += progress;
      return this.progress;
    },
    getDiscountNew() {
      this.calcData = JSON.parse(localStorage.getItem("calcData"));
      this.$axios
        .post(
          V.SERVER + V.SELLER_OPS.GENERATE_PROGRAM_CALCULATION,
          this.calcData
        )
        .then(
          (res) => {
            if (res.data.success) {
              this.discount = res.data.rows;
            }
          },
          (er) => {
            this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'intenta nuevamente'
                     ));
            console.log(er);
          }
        );
    },
    getDiscount() {
      this.calcData = JSON.parse(localStorage.getItem("calcData"));
      this.$axios.post(V.SERVER + V.OPS.CALCULATE, this.calcData).then(
        (res) => {
          if (res.data.success) {
            this.discount = res.data.row;
            let current = 0;
            for (let i = 0; i < this.discount.debts.length; i++) {
              current += Number(
                (this.discount.debts[i].willPay / this.discount.willPay) * 100
              );
              this.discount.debts[i].o_progress = current;
            }
            localStorage.setItem("discountdoc", JSON.stringify(this.discount));
          }
        },
        (er) => {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'intenta nuevamente'
                     ));
          console.log(er);
        }
      );
    },
    saveCalculationNew() {
      this.$showLoader(true);
      let userId = this.$route.query.id;
      this.jsonCalculator = [];
      let url = this.$v.SERVER + this.$v.SELLER_OPS.SET_PROGRAM_CALCULATOR_CR;
      for (let p = 0; p < this.calculatorDebts.length; p++) {
        if (this.period === this.calculatorDebts[p].period) {
          this.jsonCalculator = JSON.parse(
            JSON.stringify(this.calculatorDebts[p])
          );
        }
      }
      this.$axios
        .post(url, { user: userId, calculation: this.jsonCalculator })
        .then((response) => {
          let data = response.data;
          this.$showLoader(false);
          if (data[this.$v.API_SUCCESS]) {
            this.$vs.notification(this.$notification(
                'success',
                 'Calculadora Guardada'
                     ));
            window.location.reload();
          }
        });
    },
    saveCalculationEdit() {
      let userId = this.$route.query.id;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_PROGRAM_CALCULATION;
      this.$showLoader = true;
      this.calculation = JSON.parse(localStorage.getItem("discountdoc"));
      this.$axios
        .post(url, { user: userId, calculation: this.calculation })
        .then((response) => {
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.$showLoader = false;
            this.$vs.notification(this.$notification(
                'success',
                 'Calculadora Guardada'
                     ));
            window.location.reload();
          }
        });
    },
    saveCalculation() {
      let userId = this.$route.query.id;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_PROGRAM_CALCULATION;
      this.$showLoader = true;
      this.calculation = JSON.parse(localStorage.getItem("discountdoc"));
      this.$axios
        .post(url, { user: userId, calculation: this.calculation })
        .then((response) => {
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.$showLoader = false;
            this.$vs.notification(this.$notification(
                'success',
                 'Calculadora Guardada'
                     ));
            window.location.reload();
          }
        });
    },
    formatAmountString(v) {
      return v
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getCreditReport() {
      let userId = this.$route.query.id;
      let url =
        this.$v.SERVER +
        this.$v.SERVICE_OPS.GET_CREDIT_REPORT +
        "?user=" + userId;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.report = data.row.credit_report
            ? data.row.credit_report
            : data.row.accounts;
          this.messageReport = data.row.message
            ? data.row.message
            : data.row.report.status;
          this.existReport = true;
          this.creditReport = data.row;
          let reportCorrectdbm = [];
          let reportCorrectOthers = [];
          for (let i = 0; i < data.row.accounts.dbmAccounts.length; i++) {
              if (data.row.accounts.dbmAccounts[i].MontoPagar > 1499) {
                reportCorrectdbm.push(data.row.accounts.dbmAccounts[i])
              }
            }
          for (let i = 0; i < data.row.accounts.otherAccounts.length; i++) {
              if (data.row.accounts.otherAccounts[i].MontoPagar > 1499) {
                reportCorrectOthers.push(data.row.accounts.otherAccounts[i])
              }
            }
          this.report.dbmAccounts= reportCorrectdbm;
          this.report.otherAccounts= reportCorrectOthers;
          this.typeModification = this.$v.MODIFICATIONS.CREDIT_REPORT;
          this.dateCreatedBC = data.row.report.created
          this.lengthsCreditReports.dbmAccounts = this.report.dbmAccounts.length;
          this.lengthsCreditReports.otherAccounts = this.report.otherAccounts.length;
          if((this.lengthsCreditReports.dbmAccounts > 0)){
            this.notAccounts = false;
            this.sumAccountsBC=false;
            let sum=0;
            for(let i=0; i<this.report.dbmAccounts.length;i++){
                  sum+=this.report.dbmAccounts[i].MontoPagar
                  console.log(sum)
            }
            if(sum>=10000){
              this.sumAccountsBC=true;
            }
            console.log(this.sumAccountsBC)
          }else{
            this.existCalculator = true;
          }
        }else {
          switch (data[this.$v.API_REASON]) {
            default:
              this.notAccounts = true;
              this.existCalculator = true;
              this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'No se encuentra buro de crédito del cliente'
                     ));
              break;
          }
        }
      });
    },
    accountClick(acc) {
      console.log(acc)
      let saldo = acc.SaldoActual;
      if (saldo[saldo.length - 1] === "+" || saldo[saldo.length - 1] === "-")
        saldo = saldo[saldo.length - 1] + saldo.slice(0, -1);
      this.accountInModal = {
        ClaveOtorgante: acc.ClaveOtorgante,
        ClaveUnidadMonetaria: acc.ClaveUnidadMonetaria,
        CreditoMaximo: acc.CreditoMaximo,
        FechaActualizacion: acc.FechaActualizacion,
        FechaAperturaCuenta: acc.FechaAperturaCuenta,
        FechaHistoricaMorosidadMasGrave: acc.FechaHistoricaMorosidadMasGrave,
        FechaMasAntiguaHistoricoPagos: acc.FechaMasAntiguaHistoricoPagos,
        FechaMasRecienteHistoricoPagos: acc.FechaMasRecienteHistoricoPagos,
        FechaReporte: acc.FechaReporte,
        FechaUltimaCompra: acc.FechaUltimaCompra,
        FechaUltimoPago: this.interprateDate(acc.FechaUltimoPago),
        FormaPagoActual: acc.FormaPagoActual,
        HistoricoPagos: acc.HistoricoPagos,
        IdentificadorSociedadInformacionCrediticia:
          acc.IdentificadorSociedadInformacionCrediticia,
        ImporteSaldoMorosidadHistMasGrave:
          acc.ImporteSaldoMorosidadHistMasGrave,
        LimiteCredito: acc.LimiteCredito,
        ModoReportar: acc.ModoReportar,
        MontoPagar: acc.MontoPagar,
        MontoUltimoPago: acc.MontoUltimoPago,
        MopHistoricoMorosidadMasGrave: acc.MopHistoricoMorosidadMasGrave,
        NombreOtorgante: acc.NombreOtorgante,
        NumeroCuentaActual: acc.NumeroCuentaActual?acc.NumeroCuentaActual:acc.CuentaActual,
        NumeroPagos: acc.NumeroPagos,
        NumeroTelefonoOtorgante: acc.NumeroTelefonoOtorgante,
        SaldoActual: saldo,
        SaldoVencido: acc.SaldoVencido,
        accountsTableIndex: acc.accountsTableIndex,
      };
      this.m_appendDebtBURO(acc)
    },
    showCalcClick() {
      let dbmAccounts = this.report["dbmAccounts"];
      let newDebts = new Array();
      let saldo;
      for (let i = 0; i < dbmAccounts.length; i++) {
        saldo = dbmAccounts[i].SaldoActual;
        fechaUltimoPago = dbmAccounts[i].FechaUltimoPago;
        if (
          saldo[saldo.length - 1] === "+" ||
          saldo[saldo.length - 1] === "-"
        ) {
          saldo = saldo[saldo.length - 1] + saldo.slice(0, -1);
        }
        
        newDebts.push({
          bankid: dbmAccounts[i].bank.id,
          bank: dbmAccounts[i].bank.name,
          amount: saldo,
          amount: this.interprateDate(fechaUltimoPago)
        });
      }
      
      /*
      let request = {
        name: this.client.name,
        lastname: this.client.lastname_1,
        period: this.period,
        debts: newDebts,
      };
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.OPS.CALCULATE;
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.dbmAccountsCalculations = data.row;
          this.setCalc(this.dbmAccountsCalculations);
          this.calcModal = true;
        }
      });
      */
    },
    onPaymentSelected(payment) {
      this.selectedPayment = payment;
      this.paymentModal = true;
    },
   interprateDate(cadena) {
      if(cadena){
        let identificador = cadena.indexOf("-");
        if(identificador > 1){
          return (this.$moment(cadena).format('YYYY-MM-DD'))
        } else {
          let fec = cadena + "";
          let day = fec.substr(0,2);
          let month = fec.substr(2,2);
          let year = fec.substr(4,7);
          let date = month+"-"+day+"-"+year;
          let fecha = this.$moment(date).format('YYYY-MM-DD')
          return fecha;
        }
      }
    },
    deleteAccount(acc){
      for (let i=0; i<this.report.dbmAccounts.length; i++) {
        if(acc.NumeroCuentaActual==this.report.dbmAccounts[i].NumeroCuentaActual)
          this.report.dbmAccounts.splice(i,1);
      }
      this.$forceUpdate();
    },
  },
};
</script>
