<template>
  <div>
    <div class="container-fluid mt--1" v-bind:style="{cursor:pointer2}">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col">
            <h5 class="h2 mb-0">Nuevos Clientes</h5>
          </div>
          <div class="col">
            <ul class="nav nav-pills justify-content-end">
              <li class="nav-item mr-2 mr-md-0">
                <div class="col">
                  <div class="row justify-content-center">
                    <base-dropdown style="width: 100%">
                      <base-button
                        slot="title"
                        type="info"
                        outline
                        class="dropdown-toggle"
                        size="sm"
                        style="width: 100%"
                      >{{deviceFilter ? deviceFilter : "Dispositivos"}}</base-button>
                      <a class="dropdown-item" v-on:click="setDeviceFilter(null)">Mostrar todos</a>
                      <a class="dropdown-item" v-on:click="setDeviceFilter('app')">App</a>
                      <a class="dropdown-item" v-on:click="setDeviceFilter('iOS')">iOS</a>
                      <a class="dropdown-item" v-on:click="setDeviceFilter('android')">Android</a>
                      <a class="dropdown-item" v-on:click="setDeviceFilter('web')">Web</a>
                    </base-dropdown>
                    <flat-pickr
                      v-model="period.start"
                      :placeholder="today"
                      class="btn btn-sm"
                      data-vv-as="Fecha"
                      name="startPeriod"
                      :config="config"
                      style="margin-top:1rem; background-color:#fff"
                    />
                    <base-button
                      type="info text-black"
                      size="sm"
                      style="margin-top:1rem;"
                      @click="setPeriodFilterDaily()"
                    >Aplicar</base-button>
                    <!--@on-change="setPeriodFilterDaily()"-->
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <template>
          <div class="row">
            <div class="col-lg-12">
              <div ref="dailyActivities" style="width: 100%; height: 420px" />
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import numeral from "numeral";

export default {
  components: {
    flatPickr
  },
  computed: {
    generalInfo() {
      return this.$store.state.generalInfo;
    },
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    sellers() {
      return this.$store.state.sellers;
    },
    service() {
      return this.$store.state.customerService;
    }
  },
  data() {
    return {
      selected: {
        service: null,
        serviceCount: 0,
        seller: null,
        sellerCount: 0
      },
      isAdmin: false,
      isManagerSeller: false,
      isManagerService: false,
      isSeller: false,
      isService: false,
      dailyActivitiesChart: null,
      sellerDailyActivitiesChart: null,
      /*SERVICE*/
      chartsDataService: {},
      dailyActivitiesChartService: null,
      serviceDailyActivitiesChart: null,
      testData: "1354",
      showSetPeriodActive: false,
      showSetPeriodDaily: false,
      showSetPeriodCommissions: false,
      tableDataGraduates: [],
      tableDataDevice: [],
      tableDataCierres: [],
      tableDataBank: [],
      tableDataGraphicsActive_Mes: [],
      tableDataGraphicsActive_Total: [],
      tableDataGraphicsNewClients_Device: [],
      tableDataGraphicsNewClients_Users: [],
      tableDataGraphicsCommissions_Mes: [],
      tableDataGraphicsCommissions_Total: [],
      tableDataAmount: [],
      tableDataRegister: [],
      tableDataConsultant: [],
      tableDataNewClientStats: [],
      tableDataCommissions: [],
      period: {
        start: null,
        end: null
      },
      periodService: {
        start: null,
        end: null
      },
      //selectedHistory: {},
      eventFilter: null,
      deviceFilter: null,
      consultantFilter: null,
      pointer: "pointer",
      pointer2: "default",
      today: "",
      config: {
        maxDate: "today",
        dateFormat: "Y-m-d "
      },
      dbmFee: ""
    };
  },
  created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE || this.user.role === this.$v.ROLES.AUDITOR;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    this.getDay();
    this.getDailyActivities(this.today, "seller");
    this.setDeviceFilter(null);
  },
  methods: {
    getDay() {
      let f = new Date();
      let month = f.getMonth() + 1;
      if (month < 10) month = "0" + month;
      let today = new Date(f.getFullYear(), f.getMonth(), 1);
      this.today = this.$moment(today).format("YYYY-MM-DD");
      //this.today = f.getFullYear() + "-" + month + "-" + f.getDate();
    },
    async postServer(url, payload) {
      try {
        let r = await this.$axios.post(url, payload);
        if (r.data.success) return r.data.rows;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    getDailyActivitiesByTimeRange(activities, consultant, timeRange = 1000) {
      let count = 0;
      let data = [];

      let sorted = JSON.parse(
        JSON.stringify(
          activities.sort((a, b) =>
            a.firstPaymentDate > b.firstPaymentDate ? 1 : -1
          )
        )
      );
      let startDate = this.$moment(sorted[0].firstPaymentDate);

      startDate.set("minute", 0);
      let diff = this.$moment(sorted[sorted.length - 1].firstPaymentDate).diff(
        startDate,
        "minutes"
      );
      let newClients = 0;
      //let dbmFee = 0;
      let mdi = 0;
      let accountCount = 0;
      let paymentSum = 0;
      let notValidatedPaymentSum = 0;
      let firstPaymentComplete = 0;
      for (let i = 0; i < diff; i += timeRange) {
        let b0 = this.$moment(startDate).add(i, "minutes");
        let b1 = this.$moment(startDate).add(i + timeRange, "minute");
        let a = [];
        for (let n = 0; n < sorted.length; n++) {
          let d = this.$moment(
            sorted[n].firstPaymentDate,
            "YYYY-MM-DD HH:mm:ss"
          );
          if (
            (d.isAfter(b0) && d.isBefore(b1)) ||
            d.isSame(b0) ||
            d.isSame(b1)
          ) {
            a.push(sorted[n]);
            newClients += 1;
            //dbmFee += parseFloat(sorted[n]["dbmFee"]);
            mdi += parseFloat(sorted[n]["accountSum"]);
            accountCount += parseInt(sorted[n]["accountCount"]);
            paymentSum = parseFloat(sorted[n]["paymentSum"]);
            notValidatedPaymentSum = parseInt(
              sorted[n]["notValidatedPaymentSum"]
            );
            firstPaymentComplete += parseInt(sorted[n]["firstPaymentComplete"]);
          }
        }
        if (a.length > 0) {
          data.push({
            date: b0.toDate(),
            items: a,
            newClients: newClients,
            MDI: mdi / 1000000,
            //dbmFee: dbmFee,
            accountCount: accountCount,
            paymentSum: paymentSum,
            notValidatedPaymentSum: notValidatedPaymentSum,
            firstPaymentComplete: firstPaymentComplete
          });
        }
      }
      return {
        data,
        total: count
      };
    },
    setDeviceFilter(value) {
      this.deviceFilter = value;
      this.getDailyActivities(this.today, "seller");
    },
    async getDailyActivities(date, consultant = "seller", retrying = 0) {
      date = this.$moment(date, "YYYY-MM-DD");
      date.set("hour", 0);

      const startDate = this.$moment(date.format()).utc();
      const endDate = this.$moment(date.format())

        .utc()
        .add(1, "month");

      let url;
      if (consultant === "seller") {
        url = `${this.$v.SERVER}${this.$v.CONSULTANT_OPS.GET_NEW_USER_LIST}`;
      }
      let request = {
        limit: 99999,
        firstPaymentDate: {
          gte: startDate.format("YYYY-MM-DD"),
          lt: endDate.format("YYYY-MM-DD")
        }
      };
      if (this.deviceFilter)request = Object.assign(request,JSON.parse('{"device":"' + this.deviceFilter + '"}'));
      let activities = await this.postServer(url, request);
       if (activities.length===0) {
        if (retrying === 4) {
          this.$vs.notification(this.$notification(
                'info',
                 'No existe datos con estos filtros'
                     ));
          return;
        }
        return this.getDailyActivities(date, consultant, (retrying += 1));
      }else if (!activities) {
        if (retrying === 4) {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
          return;
        }
        return this.getDailyActivities(date, consultant, (retrying += 1));
      }
      for (let i = 0; i < activities.length; i++) {
        activities[i].firstPaymentDate = this.$moment
          .utc(activities[i].firstPaymentDate)
          .local()
          .format();
      }

      if (consultant === "seller") {
        let activitiesByDate = this.getDailyActivitiesByTimeRange(activities);
        this.selected.sellerCount = activitiesByDate.total;
        if (this.dailyActivitiesChart)
          this.dailyActivitiesChart.data = activitiesByDate.data;
      }
      this.$forceUpdate();
    },
    createDailyActivitiesChart(consultant = "seller") {
      let chartRef;
      if (consultant === "seller") {
        chartRef = "dailyActivities";
      }
      let chart = am4core.create(this.$refs[chartRef], am4charts.XYChart);
      chart.paddingRight = 20;
      //chart.data = this.getDailyActivitiesByTimeRange(this.chartsData.dailyActivities);

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 50;

      // Create series
      function createAxisAndSeries(field, name, opposite, hidden) {
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (chart.yAxes.indexOf(valueAxis) != 0) {
          valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }

        let series = chart.series.push(new am4charts.LineSeries());
        series.hidden = hidden;
        series.dataFields.valueY = field;
        series.dataFields.dateX = "date";
        series.strokeWidth = 2;
        series.yAxis = valueAxis;
        series.name = name;
        series.tooltipText = "{name}: [bold]{valueY}[/]";
        series.tensionX = 0.8;
        series.showOnInit = true;
        series.events.on("hidden", toggleAxes);
        series.events.on("shown", toggleAxes);

        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = series.stroke;
        valueAxis.renderer.labels.template.fill = series.stroke;
        valueAxis.renderer.opposite = opposite;
      }

      function toggleAxes(ev) {
        let axis = ev.target.yAxis;
        let disabled = true;
        axis.series.each(function(series) {
          if (!series.isHiding && !series.isHidden) {
            disabled = false;
          }
        });
        axis.disabled = disabled;
      }
      createAxisAndSeries("newClients", "Nuevos Clientes", true, false);
      createAxisAndSeries("MDI", "MDI", true, false);
      //createAxisAndSeries("dbmFee", "Comisiones", true, "triangle");
      createAxisAndSeries("accountCount", "Cuentas ingresadas", true, true);
      createAxisAndSeries("paymentSum", "Pagos Validados", true, true);
      createAxisAndSeries("notValidatedPaymentSum","Pagos sin Validar",true,true);
      createAxisAndSeries("firstPaymentComplete","Primer Pago Completado",true,true);

      chart.legend = new am4charts.Legend();
      chart.legend.useDefaultMarker = true;

      let marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      marker.stroke = am4core.color("#ccc");

      chart.cursor = new am4charts.XYCursor();

      if (consultant === "seller") {
        this.dailyActivitiesChart = chart;
      }
    },
    beforeDestroy() {
    if (this.dailyActivitiesChart) {
      this.dailyActivitiesChart.dispose();
    }
  },
    formatCurrency(num) {
      return numeral(num).format("$ 0,0.00");
    },
    formatCurrencyPorcentaje(num) {
      return numeral(num).format("0,0.00");
    },
    setPeriodActive() {
      this.showSetPeriodActive = true;
      this.period.start = null;
      this.period.end = null;
    },
    setPeriodDaily() {
      this.showSetPeriodDaily = true;
      this.period.start = null;
      this.period.end = null;
    },
    setPeriodCommissions() {
      this.showSetPeriodCommissions = true;
      this.period.start = null;
      this.period.end = null;
    },
    setPeriodFilterDaily() {
      this.getDailyActivities(this.period.start + "T12:00:00.000Z", "seller");
    }
  },
  mounted() {
    this.createDailyActivitiesChart("seller");
  }
};
</script>
<style></style>
