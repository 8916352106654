<template>
  <div class="wrapper">
    <!-- Sidebar Holder -->
    <nav v-if="isSeller || isService" id="sidebar">
      <div class="sidebar-header"></div>
      <h4 class="text-muted text-center">{{newMessages?"Tienes nuevos mensajes":""}}</h4>
      <ul class="list-unstyled components" style="height: calc(100% - 85px); overflow: auto">
        <li v-for="contact in contacts" :key="contact.phone">
          <a v-if="session" v-on:click="getMessages(contact)">
            <span>{{contact.user ? contact.name : contact.phone}}</span>
            <h5 class="text-muted">{{contact.data['Body']}}</h5>
          </a>
          <h2 v-else>Sesión inválida, intenta nuevamente</h2>
        </li>
      </ul>
    </nav>
    <nav v-if="isManagerSeller || isManagerService || isAdmin" id="sidebar">
      <div class="sidebar-header"></div>
      <ul class="list-unstyled components" style="height: calc(100% - 85px); overflow: auto">
        <li v-for="chat in chats" :key="chat.phone">
          <a v-if="session" v-on:click="getMessagesAdmin(chat)">
            <span>{{chat.user ? chat.name : chat.phone}}</span>
            <h5 class="text-muted">{{chat.data['Body']}}</h5>
          </a>
          <h2 v-else>Sesión inválida, intenta nuevamente</h2>
        </li>
      </ul>
    </nav>
    <!-- Page Content Holder -->
    <div v-if="isSeller || isService" id="content" style="height: 100%; overflow: hidden">
      <div class="content-header">
        <h3>{{currentContact.user  ? currentContact.name : currentContact.phone}}</h3>
      </div>

      <div id="conversation" v-if="session">
        <div class="row align-content-end p-3">
          <div class="container" style="overflow: auto" v-for="message in messages" :key="message.id">
            <div class="row p-1 font-weight-bold" 
                :class="{'justify-content-start': isIncomingMessage(message), 'justify-content-end': !isIncomingMessage(message), 'text-info': message.data['mediaurl0']}">
              <div :class="{'rounded-right p-1': isIncomingMessage(message), 'rounded-left p-1': !isIncomingMessage(message)}"
                    :style="isIncomingMessage(message) ? 'background-color: #d8defd; box-shadow: 1px 2px 4px; max-width: 80%;': 'background-color: white; box-shadow: -1px 2px 4px; max-width: 80%;'">
                    <div v-if="message.data['mediaurl0']" class="col-lg-6 card-profile-stats d-flex justify-content-center img-center">
                      <a :href="message.data['mediaurl0']" target="_blank">
                        <img v-if="message.data['mediacontenttype0'].indexOf('image')!==-1" :src="message.data['mediaurl0']" style="width: 100%; height: auto;" alt="Archivo" title="Click para ver completo"/>
                        <div v-else title="Click para ver"><i class="fas fa-file text-info" style="font-size: 50px"/>Archivo</div>
                      </a>
                    </div>
                    <div v-else>{{message.data['body']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="session && isSeller || isService" class="row justify-content-start">
        <div class="col-11"> <!--col-10 (Enviar archivos)-->
          <base-input v-if="!sendMMessage"
            placeholder="Escribe un mensaje"
            class="input-group-alternative"
            required
            alternative
            style="margin-right: -16px;"
            v-model="message"
            v-on:keydown.enter.native="sendMessage(message)"
          ></base-input>
          <div v-else class="dropbox" :style="isImage? 'margin-top: -400px;' : 'margin-top:-52px;'">
            <div class="row justify-content-between">
              <div class="col-1" style="margin-left: 10px;"><i v-if="!isImage" class="fas fa-file text-primary" style="font-size: 65px"/></div>
              <div class="col">{{selectedFile.name}}</div>
              <div class="col-auto text-right" style="padding-right: 20px"><i class="fas fa-times text-danger" @click="sendMMessage=false, fileSelected=null"></i></div>
            </div>
            <img v-if="isImage" :src="previewURL" style="width: 100%; height: 400px"/>
          </div>
        </div>
        <!--div class="col-1" style="margin: 0px -10px;">
          <span class="btn btn-primary btn-file">
            <i class="fas fa-paperclip"></i>
            <input type="file" accept="*/*" @change="fileSelected($event.target.name, $event.target.files)">
          </span>
        </div-->
        <div class="col-1" style="margin-left:-14px;"> <!--div class="col-1" (Enviar archivos)--->
          <base-button
            title="Enviar"
            type="success"
            icon="far fa-paper-plane"
            @click="sendMessage(selectedFile?selectedFile:message)"
          ></base-button>
        </div>
      </div>
    </div>
    <div
      v-if="isManagerSeller || isManagerService || isAdmin"
      id="content"
      style="height: 100%; overflow: hidden"
    >
      <div style="height: 75px;" class="content-header">
        <h3 class="display-5">Consultor: <b>{{getConsultantName(currentContact.consultant)}}</b></h3>
        <h3>{{currentContact.user ? currentContact.name : currentContact.phone}}</h3>
      </div>

      <div id="conversation" v-if="session">
        <div class="row align-content-end p-3">
          <div
            class="container"
            style="overflow: auto"
            v-for="mensaje in mensajes"
            :key="mensaje.id"
          >
            <div
              class="row p-1 font-weight-bold"
              :class="{'justify-content-start': isIncomingMessage(mensaje), 'justify-content-end': !isIncomingMessage(mensaje), 'text-info': mensaje.data['mediaurl0']}"
            >
              <div
                :class="{'rounded-right p-1': isIncomingMessage(mensaje), 'rounded-left p-1': !isIncomingMessage(mensaje)}"
                :style="isIncomingMessage(mensaje) ? 'background-color: #d8defd; box-shadow: 1px 2px 4px; max-width: 80%;': 'background-color: white; box-shadow: -1px 2px 4px; max-width: 80%;'">

                <div v-if="mensaje.data['mediaurl0']" class="col-lg-6 card-profile-stats d-flex justify-content-center img-center">
                  <a :href="mensaje.data['mediaurl0']" target="_blank">
                    <img v-if="mensaje.data['mediacontenttype0'].indexOf('image')!==-1" :src="mensaje.data['mediaurl0']" style="width: 100%; height: auto;" alt="Archivo" title="Click para ver completo"/>
                        <div v-else title="Click para ver"><i class="fas fa-file text-info" style="font-size: 50px"/>Archivo</div>
                  </a>
                </div>
                <div v-else>{{mensaje.data['body']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import Dropbox from "../components/Dropbox";
export default {
  components: {
    //Dropbox
  },
  data: function () {
    return {
      message: "",
      mensaje: "",
      isAdmin: false,
      sendMMessage: false,
      isManagerService: false,
      isManagerSeller: false,
      isSeller: false,
      isService: false,
      contacts: [],
      selectedFile: null,
      isImage: false,
      previewURL: null,
      msgSkip: false,
      newMessages: false,
      chats: [],
      rol: "",
    };
  },
  mounted() {
    this.getRole();
    if (this.isManagerService || this.isManagerSeller || this.isAdmin) {
      this.getContactsAsesor();
    }
    if (this.isSeller || this.isService) {
      this.getContacts();
    }
  },
  computed: {
    user() { return this.$getLS(this.$v.STORE_KEYS.USER); },
    session() { return this.$store.state.socket.session; },
    messages() { return this.$store.state.socket.clientMessages; },
    mensajes() { return this.$store.state.socket.clientMessagesAdmin; },
    currentContact() { return this.$store.state.socket.currentContact; },
    sellers() { return this.$store.state.sellers; },
    customerService() { return this.$store.state.customerService; },
  },
  methods: {
    getRole() {
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
      this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
      this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
      this.isSeller = this.user.role === this.$v.ROLES.SELLER;
      this.isService = this.user.role === this.$v.ROLES.SERVICE;
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id)
          return this.sellers[i].name + " " + this.sellers[i].lastname_1;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return (
            this.customerService[i].name +
            " " +
            this.customerService[i].lastname_1
          );
      return "- - -";
    },
    isIncomingMessage(message) {
      try {
        return Boolean(message.incoming)
      } catch (e) {
        if (!this.msgSkip) console.log("Posibles mensajes omitidos");
        this.msgSkip = true;
      }
    },
    sendMessage(message) {
      console.log(message)
      let payload = {
        action: "sendmessage",
        message: message,
        to: this.currentContact.data["from"].split("+")[1],
      };
      message = JSON.stringify(payload);
      this.$connection.send(message);
      this.message = "";
    },
    fileSelected(fieldName, files) {
      this.previewURL=null;
      if (!files.length) {
        this.sendMMessage = false
        return
      }this.sendMMessage = true
      let f = files[0]
      this.selectedFile = f
      this.isImage=this.selectedFile.type.indexOf('image')!==-1;
      if (!f.type.match('image.*'))
          return
      const reader = new FileReader()
      const c = this
      reader.onload = function (e) {
          c.previewURL = e.target.result
      }
      reader.readAsDataURL(f)
    },
    getContactsAsesor() {
      let url="";
      if (this.isManagerService) {
         url =
          this.$v.SERVER +
          this.$v.ADMIN_OPS.GET_V_CONTACTS +
          "?offset=0&limit=10000&asesor=manager_service";
      } else if (this.isManagerSeller) {
         url =
          this.$v.SERVER +
          this.$v.ADMIN_OPS.GET_V_CONTACTS +
          "?offset=0&limit=10000&asesor=manager_seller";
      }else if(this.isAdmin){
       url =
        this.$v.SERVER +
        this.$v.ADMIN_OPS.GET_V_CONTACTS +
        "?offset=0&limit=10000";
        }
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.chats = data[this.$v.API_ROWS];
          this.$forceUpdate();
        }
      });
    },
    async getContacts(/*limit, offset*/) {
      try {
        const url = this.$v.SERVER + this.$v.SELLER_OPS.GET_WHATSAPP_CONTACTS;
        let apiResponse = (await this.$axios.get(url)).data;
        if (apiResponse.success) this.contacts = apiResponse.rows;
        //console.log(apiResponse)
        //console.log(`${this.contacts.length} contactos`)
      } catch (e) {
        console.log(e);
      }
    },
    async getMessages(contact) {
      this.$store.commit("onNewMessage", false);
      this.$store.commit("setCurrentContact", contact);
      this.$store.dispatch("downloadClientChat");
      this.showLastMessage();
    },
    async getMessagesAdmin(contact) {
      this.$store.commit("onNewMessage", false);
      this.$store.commit("setCurrentContact", contact);
      this.$store.dispatch("downloadClientChatAdmin");
      this.showLastMessage();
    },
    showLastMessage() {
      var objDiv = document.getElementById("conversation");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
  },
};
</script>
<style>
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed rgb(170, 11, 11);
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar {
  width: 400px;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: #ffffff;
  color: rgb(0, 0, 0);
}
#sidebar.active {
  margin-left: -400px;
}

#sidebar h4 {
  border-bottom: 1px solid #e9e9e9;
  background: #c3ccff;
}

#sidebar .sidebar-header {
  padding: 43px;
  background: #3953d3;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  border-bottom: 1px solid #e9e9e9;
  display: block;
}
#sidebar ul li a:hover {
  color: rgb(0, 0, 0);
  background: rgb(230, 239, 247);
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: calc(100% - 400px);
  padding: 0px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  background: rgb(234, 239, 255);
  right: 0;
}
#content .content-header {
  padding: 10px;
  height: 50px;
  border: none;
  border-radius: 0;
  width: 100%;
  box-shadow: 0px 3px 5px #b3b3b3;
  background: #617af5;
}
#conversation {
  padding: 3px 3px;
  height: calc(100% - 93px);
  overflow-y: auto;
  overflow-x: hidden;
}
#content.active {
  width: 100%;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  #sidebar {
    margin-left: -400px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
  }
  #content.active {
    width: calc(100% - 400px);
  }
  #sidebarCollapse span {
    display: none;
  }
}

.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;   
    cursor: inherit;
    display: block;
}
</style>
