<template>
    <div>
        <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col" v-bind:style="{cursor:pointer2}">
                    <consultants-table
                            title="Consultores DBMenos"
                            :data="tableData"
                            :pagination="pagination"
                            v-on:pageSelected="getConsultants"
                            v-on:onNewItem="onNewItem"
                            v-on:rowSelected="rowSelected"
                            v-on:insertRow="insertRow"/>
                </div>
            </div>
        </div>

        <modal :show.sync="modal">
            <template slot="header">
                <h1 class="modal-title" id="modal">
          {{isEdit? 'Editar Consultor':'Nuevo Consultor'}}</h1>
            </template>
            <div class="row">
                <div class="col-md-8">
                    <base-input alternative=""
                                label="Usuario"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.username"/>
                </div>
                <div class="col-md-4 form-group">
                    <label class="form-control-label" for="enableControl">Habilitado</label>
                    <select v-model="selectedConsultant.enabled" class="form-control" id="enableControl">
                        <option value="1">Habilitado</option>
                        <option value="0">Deshabilitado</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-input alternative=""
                                label="Nombre"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.name"/>
                </div>
                <div class="col">
                    <base-input alternative=""
                                label="Primer apellido"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.lastname_1"/>
                </div>
                <div class="col">
                    <base-input alternative=""
                                label="Segundo apellido"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.lastname_2"/>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-input alternative=""
                                label="Teléfono"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.phone"/>
                </div>
                <div class="col">
                    <base-input alternative=""
                                label="Teléfono Personal"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.personal_phone"/>
                </div>
                <div class="col">
                    <base-input alternative=""
                                label="Email"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.email"/>
                </div>
            </div>
            
            <div class="row">
                    <div class="col-3">
                      <label class="form-control-label"
                        >Fecha de nacimiento</label
                      >
                      <flat-pickr
                        v-model="selectedConsultant.birthday"
                        :config="{}"
                        class="form-control form-control-alternative"
                        data-vv-as="Fecha"
                        name="date"
                        style="background-color: #ffffff"
                      />
                    </div>
                    
                <div class="col-6">
                    <base-input alternative=""
                                label="Calle"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.addr_street"/>
                </div>
                <div class="col-3">
                    <div class="col-md-12 form-group">
                    <label class="form-control-label" for="enableControl">Género</label>
                    <select v-model="selectedConsultant.gender" class="form-control" id="enableControl">
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                    </select>
                </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <base-input alternative=""
                                label="Número Exterior"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.addr_extnum"/>
                </div>
                <div class="col-3">
                    <base-input alternative=""
                                label="Número Interior"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.addr_intnum"/>
                </div>
                <div class="col-6">
                    <base-input alternative=""
                                label="Colonia"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.addr_sublocality"/>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <base-input alternative=""
                                label="Ciudad y Estado (Ciudad,Estado)"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.addr_city"/>
                </div>
                <div class="col">
                      <label class="form-control-label"
                        >Fecha de Ingreso a DBM</label
                      >
                      <flat-pickr
                        v-model="selectedConsultant.admission_date"
                        :config="{}"
                        class="form-control form-control-alternative"
                        data-vv-as="Fecha"
                        name="date"
                        style="background-color: #ffffff"
                      />
                    </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-control-label" for="roleControl">Rol</label>
                    <select v-model="selectedConsultant.role" class="form-control" id="roleControl">
                        <option v-for="(r, i) in roles" :key="i" v-bind:value="r">{{getRoleName(r)}}</option>
                    </select>
                </div>
                <div class="col" v-if="selectedConsultant.role === 'seller'">
                    <label class="form-control-label" for="roleControl">Team</label>
                    <select v-model="selectedConsultant.leader" class="form-control" id="roleControl">
                        <option v-for="(team, index) in teams" :key="index" v-bind:value="team.id">{{getConsultantName(team.id)}}</option>
                    </select>
                </div>
            <div class="col" v-if="selectedConsultant.role === 'seller' && user.role=='admin' && selectedConsultant.enabled == 1">
                    <label class="form-control-label" for="enableControl">Recepción de Leads</label>
                    <select v-model="selectedConsultant.seller_receiving_leads" class="form-control" id="enableControl">
                        <option value="1">Habilitado</option>
                        <option value="0">Deshabilitado</option>
                    </select>
                </div>
                <div class="col">
                    <base-input alternative=""
                                label="Contraseña"
                                input-classes="form-control-alternative"
                                v-model="selectedConsultant.password"/>
                </div>
            </div>
            <div class="row justify-content-center">
				<div class="col-6">
					<dropbox-visor title="Inserta Foto:" text="No Contiene Foto" :miniURL="miniURL" :files="miniature" v-on:setMiniature="setMiniature"/>
				</div>
            </div>
            <template slot="footer">
                <base-button v-on:click="setConsultant()">Guardar</base-button>
            </template>
        </modal>

    </div>
</template>
<script>
    import ConsultantsTable from './Tables/ConsultantsTable'
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
	import DropboxVisor from '../components/DropboxVisor'
  export default {
      name: 'tables',
      components: {
          ConsultantsTable,
          flatPickr,
          DropboxVisor
      },
      mounted() {
          this.getConsultants(1)
//          console.log("Consultants")
      },
      computed: {
          user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
          teams() {return this.$store.state.TeamsLeaders;},
      },
      data() {
          return {
              modal: false,
              selectedConsultant: {},
              tableData: [],
              pagination: {
                total: 0,
                perPage: 20,
                currentPage: 1
            },
              isEdit:false,
              pointer: 'pointer',
              pointer2: 'default',
              picture: null,
              miniURL:"",
              miniature: [],
              gender: null,
              roles: ['admin', 'seller', 'service', 'finamigo', 'manager_service', 'manager_seller', 'marketing','audit','growth','human_resources']
          };
      },
      methods: {
          setMiniature(obj) {
              if(obj){
				let file = obj.file
				this.selectedConsultant.picture = file.name
                this.picture = file
                }else{
                    this.selectedConsultant.picture = null
                    this.picture = null
                }
			},
          onNewItem() {
              this.selectedConsultant = {}
              this.modal = true
              this.isEdit=false;
              this.miniURL = null
          },
          getConsultantName(id) {
            for (let i = 0; i < this.teams.length; i++)
                if (this.teams[i].id === id)
                    return this.teams[i].lastname_1;
                return "- - -";
         },
          rowSelected(row) {
              this.selectedConsultant = JSON.parse(JSON.stringify(row))   
            this.miniURL = 'https://www.dbmenos.com/img/consultants/' + this.selectedConsultant.picture
              this.modal = true
              this.isEdit = true;
          },
          setGender(gender) {
            this.selectedConsultant.gender = gender.substring(0, 1).toUpperCase();
            this.gender = gender;
            this.$forceUpdate();
            },
          insertRow() {
              this.selectedConsultant = {}
              this.modal = true
          },
          getRoleName(role) {
              switch (role) {
                  case 'admin': return "Administrador"
                  case 'seller': return "Vendedor"
                  case 'service': return "Servicio"
                  case 'finamigo': return "Finamigo"
                  case 'manager_service': return "Gerente de servicio"
                  case 'manager_seller': return "Gerente de ventas"
                  case 'marketing': return "Marketing"
                  case 'audit': return "Auditor"
                  case 'growth': return "Growth"
                  case 'human_resources': return "Recursos Humanos"
              }
          },
          async setConsultant() {
              let url = this.$v.SERVER + this.$v.ADMIN_OPS.SET_CONSULTANT
              this.$showLoader(true)
              let response = await this.$axios.post(url,this.selectedConsultant)
              let data = response.data;
              if (data[this.$v.API_SUCCESS]) {
                  console.log(data)
                if(data[this.$v.API_ROW] == 0){
                    this.modal = false
					this.$vs.notification(this.$notification(
                'success',
                 'Guardado exitosamente'
                     ));
                      await this.getConsultants()
                      this.$forceUpdate()
                    }else{
                    this.modal = false
                    let upImgUrl = data[this.$v.API_ROW]['uploadUrl']
                    let upImgUrlAnalytics = data[this.$v.API_ROW]['uploadAnalytics']
                    console.log(this.picture)
                    this.$axios.put(upImgUrl,this.picture).then(r => console.log(r.status))
                    .catch(e => console.log(e));
                    this.$axios.put(upImgUrlAnalytics,this.picture).then(r => console.log(r.status))
                    .catch(e => console.log(e));
                    console.log('Hola')
					this.$vs.notification(this.$notification(
                'success',
                 'Guardado exitosamente'
                     ));
                      await this.getConsultants()
                      this.$forceUpdate()
                    }
              } else {
                  this.$showLoader(false)
                  this.$validateSession(data)
              }
          },
          async getConsultants(page) {
              let limit = this.pagination.perPage
              let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_CONSULTANTS_TABLE
              this.$showLoader(true)
              let requestS = {
                offset: limit * (page - 1),
                limit: limit,
            }
              let response = await this.$axios.post(url,requestS)
              this.$showLoader(false)
              let data = response.data
              if (data[this.$v.API_SUCCESS]) {
                  this.tableData = data[this.$v.API_ROWS]
                    let p = data[this.$v.API_ROW]
                    this.pagination.total = p['totalItems']
                    this.pagination.currentPage = page
                    this.pagination.perPage = limit
              } else { this.$validateSession(data) }
          },
      }
  };
</script>
<style></style>