<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">

                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">Cliente</th>
                    <th v-bind:style="{cursor:pointer2}">Nombre</th>
                    <th v-bind:style="{cursor:pointer2}">Cuenta</th>
                    <th v-bind:style="{cursor:pointer2}">ID Frente</th>
                    <th v-bind:style="{cursor:pointer2}">ID Reverso</th>
                    <th v-bind:style="{cursor:pointer2}">Comprobante de domicilio</th>
                    <th v-bind:style="{cursor:pointer2}">CURP</th>
                    <th v-bind:style="{cursor:pointer2}">Manifiesto</th>
                    <th v-bind:style="{cursor:pointer2}">Contrato</th>
                    <th v-bind:style="{cursor:pointer2}">Lista Nominal</th>
                </template>

                <template slot-scope="{row}">
                    <td v-bind:style="{cursor:pointer2}">{{row.id}}</td>
                    <td v-bind:style="{cursor:pointer2}">{{row.name}}</td>
                    <td v-bind:style="{cursor:pointer2}">{{row.account}}</td>

                    <td class="text-center">
                        <i @click="getFileUrl('ID', row.account)" class="fas fa-download text-primary"></i>
                    </td>
                    <td class="text-center">
                        <i @click="getFileUrl('ID_B', row.account)" class="fas fa-download text-primary"></i>
                    </td>
                    <td class="text-center">
                        <i @click="getFileUrl('ADDRESS', row.account)" class="fas fa-download text-primary"></i>
                    </td>
                    <td class="text-center">
                        <i @click="getFileUrl('CURP', row.account)" class="fas fa-download text-primary"></i>
                    </td>
                    <td class="text-center">
                        <i @click="getFileUrl('FIN_MANIFEST', row.account)" class="fas fa-download text-primary"></i>
                    </td>
                    <td class="text-center">
                        <i @click="getFileUrl('FIN_CONTRACT', row.account)" class="fas fa-download text-primary"></i>
                    </td>
                    <td class="text-center">
                        <i @click="getFileUrl('NOMINAL_LIST', row.account)" class="fas fa-download text-primary"></i>
                    </td>
                </template>

            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} Cuentas
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'finamigo-view-table',
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
        },
        data() {
            return {
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                account: null,
            }
        },
        methods: {
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getFileUrl(file,account){
                let url = this.$v.SERVER + this.$v.FINAMIGO_OPS.GET_FILE_URL + "?account=" + account + "&file=" + file
                let session = JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.SESSION))
                session = session[this.$v.SESSION]
                if(session){
                    this.$showLoader(true)
                    this.$axios.get(url, {headers: { 'session': session}}).then(response => {
                        this.$showLoader(false)
                        if (response.data.success){
                            this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
                            let urlFile = response.data.url
                            window.open(urlFile, '_blank')
                        } else{
                            this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                        }
                    })
                }
            }
        }
    }
</script>
<style>
</style>
