<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8">
		</base-header>

		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<activity-tables title="Actividad ventas"
							:data="activityTableDataS"
							:pagination="activityPaginationS"
							v-on:pageSelected="getActivityTableDataS"
							v-on:setPeriodFilter="setPeriodFilter"
							v-on:setConsultantFilter="setConsultantFilterAS"
							v-on:setStatusFilter="setStatusFilterAS"/>
					
					<activity-tables title="Actividad servicio al cliente"
							:data="activityTableDataCS"
							:pagination="activityPaginationCS"
							v-on:pageSelected="getActivityTableDataCS"
							v-on:setPeriodFilter="setPeriodFilter"
							v-on:setConsultantFilter="setConsultantFilterACS"
							v-on:setStatusFilter="setStatusFilterACS"
							style="margin-top: 40px"/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import ActivityTables from './Tables/ActivityTables'
	export default {
		name: 'tables',
		components: {
			ActivityTables
		},
		mounted() {
			//this.getActivityTableDataCS(1);
			//this.getActivityTableDataS(1);
			this.$root.$on('searchQuery', query => {
				if (query.length > 0)
					this.searchQuery = query
				else
					this.searchQuery = null
				this.getActivityTableDataCS(1)
				this.getActivityTableDataS(1)
			})
		},
		data() {
			return {
				searchQuery: null,
				activityTableDataS: [],
				activityPaginationS: {
					total: 0,
					perPage: 10,
					currentPage: 1
				},
				consultantFilterAS: null,
				statusFilterAS: null,
				activityTableDataCS: [],
				activityPaginationCS: {
					total: 0,
					perPage: 10,
					currentPage: 1
				},
				consultantFilterACS: null,
				statusFilterACS: null,
				periodFilterS: null,
				periodFilterCS: null,
				pointer: 'pointer',
				pointer2: 'default',
			};
		},
		methods: {
			getActivityTableDataCS(page){
				let limit = this.activityPaginationCS.perPage
				let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_USER_TRACING
				let request = {
					offset: limit*(page - 1),
					limit: limit,
				}
				if (this.consultantFilterACS) Object.assign(request, JSON.parse('{"upload_by": '+this.consultantFilterACS+'}'))
				if (this.statusFilterACS) Object.assign(request, JSON.parse('{"status": '+this.statusFilterACS.id+'}'))
				if (this.periodFilterCS){
					const start = this.$moment(this.periodFilterCS, "YYYY-MM-DD");
					const end = this.$moment(this.periodFilterCS, "YYYY-MM-DD").add(1, "d");
					let created = {
						gte: start.format("YYYY-MM-DD"),
						lt: end.format("YYYY-MM-DD")
					}
					Object.assign(request, {created})
				}
				this.$showLoader(true)
				this.$axios.post(url,request).then((response) => {
					this.$showLoader(false)
					let data = response.data
					if (data[this.$v.API_SUCCESS]) {
						this.activityTableDataCS = data[this.$v.API_ROWS]
						let p = data[this.$v.API_ROW]
						this.activityPaginationCS.total = p['totalItems']
						this.activityPaginationCS.currentPage = page
						this.activityPaginationCS.perPage = limit
					}
				})
			},
			getActivityTableDataS(page){
				let limit = this.activityPaginationS.perPage
				let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_LEAD_TRACING_DASH
				let request = {
					offset: limit*(page - 1),
					limit: limit,
				}
				if (this.consultantFilterAS) Object.assign(request, JSON.parse('{"upload_by": '+this.consultantFilterAS+'}'))
				if (this.statusFilterAS) Object.assign(request, JSON.parse('{"status": "'+this.statusFilterAS.status+'"}'))
				if (this.periodFilterS){
					const start = this.$moment(this.periodFilterS, "YYYY-MM-DD");
					const end = this.$moment(this.periodFilterS, "YYYY-MM-DD").add(1, "d");
					let created = {
						gte: start.format("YYYY-MM-DD"),
						lt: end.format("YYYY-MM-DD")
					}
					Object.assign(request, {created})
				}
				this.$showLoader(true)
				this.$axios.post(url,request).then((response) => {
					this.$showLoader(false)
					let data = response.data
					if (data[this.$v.API_SUCCESS]) {
						this.activityTableDataS = data[this.$v.API_ROWS]
						let p = data[this.$v.API_ROW]
						this.activityPaginationS.total = p['totalItems']
						this.activityPaginationS.currentPage = page
						this.activityPaginationS.perPage = limit
					}
				})
			},
			setConsultantFilterACS(value){
				this.consultantFilterACS = value
				this.getActivityTableDataCS(1)
			},
			setStatusFilterACS(value){
				this.statusFilterACS = value
				this.getActivityTableDataCS(1)
			},
			setConsultantFilterAS(value){
				this.consultantFilterAS = value
				this.getActivityTableDataS(1)
			},
			setStatusFilterAS(value){
				this.statusFilterAS = value
				this.getActivityTableDataS(1)
			},
			setPeriodFilter(periodFS,periodFCS){
				if(periodFS){
					this.periodFilterS=periodFS
					this.getActivityTableDataS(1)
				}
				if(periodFCS){
					this.periodFilterCS=periodFCS
					this.getActivityTableDataCS(1)
				}
			}
		}
	};
</script>
<style></style>