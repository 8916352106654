<template>
    <div>
        <!--<span>{{text}}   <a v-if="hasFileOnServer()" :href="getFileUrl()" target="_blank"><i class="far fa-eye text-primary"></i></a></span>-->
        <div class="dropbox">
            <input type="file" :name="text" accept="*/*" class="input-file" @change="fileSelected($event.target.name, $event.target.files)">
            <p v-if="!selectedFile">{{selectedFile? selectedFile.name : "Arrastra o da click para seleccionar el archivo"}}</p>
            <div v-else-if="type==='csv'" class="row align-items-center" style="margin-top: 22px">
                <div class="col text-center icon"><i class="fas fa-file-csv"></i></div>
            </div>
            <div v-else-if="type==='AGREEMENT' && !isImage" :title="text" class="row align-items-center" style="margin-top: 22px">
                <div class="col text-center icon"><i class="fas fa-file-upload text-success"></i></div>
            </div>
            <img v-else :src="previewURL" :alt="text" style="width: 100%; max-height: 500px"/>
        </div>
        <base-button style="width: 100%; margin-top: 8px" :disabled="!(selectedFile && type)" v-on:click="requestUploadFile()">{{buttonText ? buttonText : 'Subir'}}</base-button>
    </div>
</template>

<script>
    export default {
        name: "Dropbox",
        data() {
            return {
                selectedFile: null,
                previewURL: null,
                serverFile: null,
                isImage: false,
            }
        },
        props: {
            userid: Number,
            text: String,
            type: String,
            files: Array,
            buttonText: String,
        },
        methods: {
            fileSelected(fieldName, files) {
                if (!files.length) return
                let f = files[0]
                this.selectedFile = f
                if (!f.type.match('image.*')){
                    this.isImage = false;
                    return
                }
                this.isImage=true;
                console.log("bp")
                const reader = new FileReader()
                const c = this
                reader.onload = function (e) {
                    c.previewURL = e.target.result
                }
                reader.readAsDataURL(f)
            },
            requestUploadFile() {
                this.$emit('uploadFile', {
                    type: this.type,
                    file: this.selectedFile,
                    text: this.text
                })
            },
            getFileUrl() {
                let session = JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.SESSION))
                if (session) {
                    return this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_FILE + "?user=" + this.userid + "&filename=" +
                        this.serverFile.filename + "&redirect=true" + "&session=" + session[this.$v.SESSION]
                }
            },
            hasFileOnServer() {
               if (this.serverFile) return true
                if (!this.files) return false
               for (let i=0 ; i<this.files.length ; i++) {
                   let file = this.files[i]
                   if (file.type === this.type) {
                       this.serverFile = file
                       return true
                   }
               }
               return false
            }
        },
    }
</script>

<style lang="scss">
    .dropbox {
        outline: 2px dashed blue; /* the dash box */
        outline-offset: -10px;
        background: #FFFFFF;
        border-radius: 10px;
        color: #3953D3;
        padding: 10px 10px;
        min-height: 100px; /* minimum height */
        position: relative;
        cursor: pointer;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100px;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: #f7fafc; /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1em;
        text-align: center;
        padding: 25px;
    }
</style>