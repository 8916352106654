<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getAbonadoFilterText()}}
                        </base-button>
                        <a class="dropdown-item" @click="setAbonadoFilter(null)">Todos</a>
                        <a class="dropdown-item" @click="setAbonadoFilter(1)">Abonado</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="info" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getNoLocalizadoFilterText()}}
                        </base-button>
                        <a class="dropdown-item" @click="setNoLocalizadoFilter(null)">Todos</a>
                        <a class="dropdown-item" @click="setNoLocalizadoFilter(1)">No se localizo depósito</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="success" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getNoComprobanteFilterText()}}
                        </base-button>
                        <a class="dropdown-item" @click="setNoComprobanteFilter(null)">Todos</a>
                        <a class="dropdown-item" @click="setNoComprobanteFilter(1)">No se ve comprobante</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getPeriodFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
                        <div v-if="!showSetPeriod" class="dropdown-divider"></div>
                        <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
                    </base-dropdown>
                </div>                
                <div v-if="showSetPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
                        <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush text-center"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        @click="itemSelected">

                <template slot="columns">
                  <th v-bind:style="{cursor:pointer2}">Cliente</th>
                  <th v-bind:style="{cursor:pointer2}">Nombre</th>
                  <th v-bind:style="{cursor:pointer2}">Cuenta Finamigo</th>
                  <th v-bind:style="{cursor:pointer2}">Fecha de movimiento</th>
                  <th v-bind:style="{cursor:pointer2}">Monto</th>
                  <th v-bind:style="{cursor:pointer2}">Ficha Depósito</th>
                  <th v-bind:style="{cursor:pointer2}">Abonado</th>
                  <!--<th v-bind:style="{cursor:pointer2}">Saldo validado/transferido</th>!-->
                  <th v-bind:style="{cursor:pointer2}">No se localiza depósito</th>
                  <th v-bind:style="{cursor:pointer2}">No se ve concepto/comprobante</th>
                  <th v-bind:style="{cursor:pointer2}">Comentarios</th>
                  <th v-if="!isFinamigo" v-bind:style="{cursor:pointer2}">Respuesta FA</th>
                </template>
                <template slot-scope="{row}">
                  <td class="text-center">{{row.user}}</td>
                  <td class="text-center">{{row.name}}</td>
                  <td>{{row.account}}</td>
                  <td>{{formatDate(row.date_payment)}}</td>
                  <td>{{$formatCurrency(row.amount)}}</td>
                  <td>
                    <a v-if="row.filename" :href="getFileUrl(row.filename, row.user)" target="_blank">
                      <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                    </a>
                  </td>
                  <td class="text-center" @click.stop>
                    <base-checkbox :checked="Boolean(row.transfer_fa)" @input="setValue(row.id_payment, row.user, row.transfer_fa, 1)"
                                   :disabled="Boolean(row.transfer_fa)||Boolean(row.deposit_not_located)||Boolean(row.concept_voucher_error||user.view_only==1)"/>
                  </td>
                  <td class="text-center" @click.stop>
                    <base-checkbox :checked="Boolean(row.deposit_not_located)" @input="setValue(row.id_payment, row.user, row.deposit_not_located, 2)"
                                   :disabled="Boolean(row.transfer_fa)||Boolean(row.deposit_not_located)||Boolean(row.concept_voucher_error||user.view_only==1)"/>
                  </td>
                  <td class="text-center" @click.stop>
                    <base-checkbox :checked="Boolean(row.concept_voucher_error)" @input="setValue(row.id_payment, row.user, row.concept_voucher_error, 3, row.filename)"
                                   :disabled="Boolean(row.transfer_fa)||Boolean(row.deposit_not_located)||Boolean(row.concept_voucher_error||user.view_only==1)"/>
                  </td>
                  <td>{{row.comments? row.comments:'---'}}</td>
                  <td v-if="!isFinamigo">{{row.finamigo_reply_date? $formatDateHrs(row.finamigo_reply_date):'---'}}</td>
                </template>
            </base-table>
        </div>
        <div class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} Pagos
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>
      <modal :show.sync="commentsModal">
        <template slot="header">
          <h1 class="modal-title" id="commentsModal">Comentarios Finamigo</h1>
        </template>
        <div class="row">
          <div class="card-profile-stats d-flex justify-content-around mb-4" style="width: 100%">
            <div class="p-2">
              <span class="heading">{{ rowSelected.name }}</span>
              <span class="description">Cliente</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card-profile-stats d-flex justify-content-around mb-4" style="width: 100%">
            <div class="p-2">
              <span class="heading">{{ rowSelected.account }}</span>
              <span class="description">Cuenta Finamigo</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <base-input alternative input-classes="form-control-alternative"
                label="Comentarios"
                :disabled="user.view_only==1"
                aria-placeholder="Ingresa comentarios"
                v-model="rowSelected.comments"/>
          </div>
          <div v-if="!pdfPayment" class="col-lg-12 text-center" style="margin:1rem;">
            <a v-if="rowSelected.fileurl" :href="rowSelected.fileurl" target="_blank">
              <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
            </a><br>
            <img :src="rowSelected.fileurl? rowSelected.fileurl : getPaymentFile(rowSelected.id)" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
          </div>
          <div v-else class="col-lg-11 text-center" style="margin:2rem;">
            <base-p-d-f-visor :fileurl="rowSelected.fileurl? rowSelected.fileurl : getPaymentFile(rowSelected.id)" :key="forceUpdate"/>
          </div>
        </div>
        <template slot="footer">
          <base-button class="btn-sm" type="danger" v-on:click="commentsModal=false">
            Cancelar
          </base-button>
          <base-button class="btn-sm" type="primary" v-on:click="setValue(rowSelected.id, rowSelected.user,null,4)" v-if="user.view_only==0">
            Guardar comentarios
          </base-button>
        </template>
      </modal>
    </div>
</template>
<script>
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import BasePDFVisor from "@/components/BasePDFVisor";

  export default {
    name: 'finamigo-wrong-reference-table',
    components: {
      flatPickr,
      BasePDFVisor
    },
    props: {
      type: {
        type: String
      },
      title: String,
      data: Array,
      pagination: Object,
      period: {
        start: null,
        end: null
      },
    },
    data() {
      return {
        pdfPayment:false,
        isManagerService: false,
        isAdmin: false,
        isAuditor: false,
        isFinamigo: false,
        tableData: [],
        consultantFilter: null,
        showSetPeriod: false,
        periodFilter: null,
        pointer: 'pointer',
        pointer2: 'default',
        rowSelected: {},
        commentsModal: false,
        textAbonadoFilter: "",
        textNoLocalizadoFilter: "",
        textNoComprobanteFilter: "",
        forceUpdate: 0,
      }
    },
    created() {
      this.isManagerService = this.user.role === this.$v.ROLES.SERVICE;
      this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
      this.isFinamigo = this.user.role === this.$v.ROLES.FINAMIGO;
    },
    computed: {
      user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      customerService() { return this.$store.state.customerService },
      sellers() { return this.$store.state.sellers },
    },
    methods: {
      pageSelected(page) {
        this.$emit("pageSelected", page);
      },
      getPaymentFile(id) {
        if (id) {
          let url = this.$v.SERVER + this.$v.FINAMIGO_OPS.GET_USER_PAYMENT_FILE + "?id=" + id;
          this.$axios.get(url).then((response) => {
            this.rowSelected.fileurl = response.data;
            this.$forceUpdate();
          });
        }
      },
      formatDate(date) {
        if (date <= "2020-11-10") {
          return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
        } else {
          return this.$moment(date).format("DD MMM YYYY, h:mm a");
        }
      },
      setConsultantFilter(value) {
        this.consultantFilter = value
        this.$emit("setConsultantFilter", value);
      },
      getCustomerFilterText() {
        if (this.consultantFilter) return this.getConsultantName(this.consultantFilter).name
        return "Consultores"
      },
      getConsultantName(id) {
        for (let i=0 ; i<this.sellers.length ; i++)
          if (this.sellers[i].id === id){
            return this.sellers[i]
          }
        for (let i=0 ; i<this.customerService.length ; i++)
          if (this.customerService[i].id === id){
            return this.customerService[i]
          }
        return "- - -"
      },
      isServiceConsultant(consultantName) {
        for (let i=0 ; i<this.customerService.length ; i++)
          if (this.customerService[i].name+" "+this.customerService[i].lastname_1+" "+this.customerService[i].lastname_2 === consultantName)
            return true
        return false
      },
      formatCurrency(value) {
        return this.$formatCurrency(value)
      },
      setPeriod(){
        this.showSetPeriod=true
        this.period.start=null
        this.period.end=null
        this.getPeriodFilterText()
      },
      setPeriodFilter(value) {
        if(value!=2){
          this.showSetPeriod = false
          this.periodFilter = value
          this.$emit("setPeriodFilter", value);
        }else if(this.period.start && this.period.end){
          this.periodFilter = value
          this.$emit("setPeriodFilter", value);
        }
      },
      getPeriodFilterText() {
        if(this.showSetPeriod) return "Periodo del: ";
        else if(this.periodFilter === 1) return "Mes actual";
        else return "Fecha";
      },
      itemSelected(row){
        this.rowSelected = {
          id: row.id_payment,
          user: row.user,
          name: row.name,
          comments: row.comments,
          filename: row.filename,
          account: row.account
        }
        if(row.filename){
          let typePayment=[];
          typePayment=row.filename.split(".pdf");
          if(typePayment.length>1){
            this.pdfPayment = true;
          }else this.pdfPayment = false;
        } this.commentsModal=true;
      },
      setValue(id_payment, user, value, type, filename=null){
        this.$showLoader(true);
        let request  = {
          id: id_payment,
          user: user,
        }
        switch (type) {
          case 1: request.transfer_fa = !value;
            break;
          case 2: request.deposit_not_located = !value;
            if(request.deposit_not_located) this.sendPushNotification(user, 2)
            break;
          case 3: request.concept_voucher_error = !value;
            if(request.concept_voucher_error) {
              request.filename=filename;
              this.sendPushNotification(user, 3);
              let url2 = this.$v.SERVER + this.$v.FINAMIGO_OPS.SEND_PAYMENT_TOKEN;
              this.$axios.post(url2, request).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data[this.$v.API_SUCCESS]) {
                  this.$vs.notification(this.$notification(
                      'info',
                      'Link enviado correctamente',
                      'Se ha enviado el link para subir nuevamente el archivo.'
                  ));
                  this.$forceUpdate();
                }
              });
            }
            break;
          case 4: request.finamigo_comments = this.rowSelected.comments;
        }
        let url = this.$v.SERVER + this.$v.FINAMIGO_OPS.FINAMIGO_UPDATE_PAYMENT;

        this.$axios.post(url, request).then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
            this.pageSelected(this.pagination.currentPage);
            this.$forceUpdate();
          } else {
            this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
          }
        });
      },
      sendPushNotification(user, type){
        let url = this.$v.SERVER + this.$v.SELLER_OPS.SEND_PUSH_NOTIFICATION;
        let req = {
          user,
          title: '',
          message: '',
        }
        switch (type){
          case 2:
            req.title='Pago no localizado...';
            req.message= 'No logramos localizar tu pago, deberás solicitar una aclaración con tu banco.';
            break;
          case 3:
            req.title='Comprobante de pago no legible';
            req.message= 'No hemos podido leer tu comprobante de pago, por favor envía nuevamente un comprobante legible.';
            break;
          default: return;
        }
        this.$axios.post(url, req).then((response) => {
          this.$showLoader(false);
          if (response.data.success) {
            this.$vs.notification(this.$notification(
                'info',
                'Notificación enviada correctamente',
                'Se envió una notificación push al cliente'
            ));
          } else {
            this.$vs.notification(this.$notification(
                'warning',
                'Algo salió mal!',
                'No se logró enviar la notificación al cliente'
            ));
          }
        });
      },
      getAbonadoFilterText() {
        if (this.textAbonadoFilter === 1) return "Abonado"
        return "Estatus Abonado"
      },
      getNoLocalizadoFilterText() {
        if (this.textNoLocalizadoFilter === 1) return "No se localizo depósito"
        return "Estatus Depósito No Localizado"
      },
      getNoComprobanteFilterText() {
        if (this.textNoComprobanteFilter === 1) return "No se ve comprobante"
        return "Estatus No se Ve Comprobante"
      },
      setAbonadoFilter(value) {
        this.textAbonadoFilter = value;
        this.$emit("setAbonadoFilter", value);
      },
      setNoLocalizadoFilter(value) {
        this.textNoLocalizadoFilter = value;
        this.$emit("setNoLocalizadoFilter", value);
      },
      setNoComprobanteFilter(value) {
        this.textNoComprobanteFilter = value;
        this.$emit("setNoComprobanteFilter", value);
      },
      getFileUrl(userFile, userId) {
        if(!userFile) return null
        let session = JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.SESSION))
        if (session) {
          return this.$v.SERVER + this.$v.FINAMIGO_OPS.GET_USER_FILE + "?user=" + userId + "&filename=" +
            userFile + "&redirect=true" + "&session=" + session[this.$v.SESSION]
        }
      },
    }
  }
</script>
<style>
</style>