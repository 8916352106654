
<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8" />

    <div v-if="isDevs" class="container-fluid mt--7" @click="uploadSelected = null">
      <div class="row">
        <div class="col" v-bind:style="{ cursor: pointer2 }">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Dev Proyectos</h3>
                </div>
              </div>
              <div class="dropdown-divider" style="margin-top: 20px"></div>
              <div class="row" style="margin-top: 20px">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div
                    class="card card-stats mb-4 mb-xl-2"
                    @click.stop="uploadSelected = 'proyections'"
                  >
                    <card
                      :class="
                        uploadSelected == 'proyections'
                          ? 'border-2 btn-sm'
                          : 'border-2 btn-primary btn-sm'
                      "
                      shadow
                      body-classes="py-4"
                    >
                      <div class="row" v-if="uploadSelected == 'proyections'">
                        <div class="col"></div>
                        <span
                          style="height: 1rem; width: 1rem; margin-top: -1rem"
                          @click.stop="uploadSelected = 'null'"
                          class="icon icon-shape bg-secondary text-black"
                        >
                          <i
                            @click.stop="uploadSelected = 'null'"
                            class="fas fa-times fa-2x"
                          ></i>
                        </span>
                      </div>
                      <br />
                      <div class="row" style="margin-top: -1rem">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Crear</h6>
                          <span class="h3 font-weight-bold mb-0"
                            >Nuevo Proyecto</span
                          >
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-success text-white">
                            <i class="far fa-file-code"></i>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="uploadSelected == 'proyections'"
                        class="row col-lg-12 text-center"
                        style="margin-top: 30px"
                      >
                        <!--
                       <base-input
                          alternative
                          class="col-lg-6"
                          input-classes="form-control-alternative"
                        >
                          <base-dropdown style="width: 100%;" class="col-lg-3">
                            <base-button
                              slot="title"
                              type="primary"
                              outline
                              class="dropdown-toggle"
                              style="width: 100%"
                              size="sm"
                            >{{getCustomerFilterText()}}</base-button>
                            <div class="dropdown-divider"></div>
                            <a
                              class="dropdown-item"
                              v-for="(seller) in sellers"
                              :key="seller.id"
                              v-on:click="setConsultantFilter(seller.id)"
                            >{{seller.name}} {{seller.lastname_1}}</a>
                            <div class="dropdown-divider"></div>
                            <a
                              v-for="(customerS) in customerService"
                              :key="customerS.id"
                              class="dropdown-item"
                              v-on:click="setConsultantFilter(customerS.id)"
                            >{{customerS.name}} {{customerS.lastname_1}}</a>
                          </base-dropdown>
                        </base-input>
                        !-->
                        <base-input
                          v-model="projects.name"
                          alternative
                          class="col-lg-12"
                          placeholder="Nombre Proyecto"
                          input-classes="form-control-alternative"
                        />
                        <base-input
                          v-model="projects.description"
                          alternative
                          class="col-lg-12"
                          placeholder="Descripción"
                          input-classes="form-control-alternative"
                        />

                        <span
                          class="col-lg-4"
                          input-classes="form-control-alternative"
                          style="border: none"
                        />
                        <base-button
                          slot="title"
                          type="success"
                          class="btn col-lg-4"
                          size="sm"
                          v-on:click="sendProject()"
                          >Crear</base-button
                        >
                      </div>
                    </card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="!isDevs?'container-fluid mt--8':'container-fluid mt-4'">
      <div class="row">
        <div class="col-12" v-bind:style="{ cursor: pointer2 }">
          <dev-projects-table
            title="Proyectos DB Menos"
            :data="tableData"
            :pagination="pagination"
            :period="period"
            v-on:pageSelected="getProjects"
            v-on:projectSelected="projectSelected"
            v-on:setPeriodFilter="setPeriodFilter"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";
import DevProjectsTable from "./Tables/DevProjectsTable";
export default {
  name: "projects",
  components: {
    //flatPickr,
    DevProjectsTable,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    data: Array,
    unassigned: Boolean,
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    sellers() {
      return this.$store.state.sellers;
    },
    customerService() {
      return this.$store.state.customerService;
    },
  },
  mounted() {
    this.getProjects(1);
  },
  data() {
    return {
      uploadSelected: null,
      config: {
        minDate: "2020-06-01",
        dateFormat: "Y-m-01",
      },
      pagination: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
      isAdmin: false,
      isManagerService: false,
      isDevs: false,
      tableData: [],
      filter: null,
      consultantFilter: null,
      consultantFilterProjectios: null,
      showSetPeriod: false,
      periodFilter: null,
      consultantValue: "",
      pointer: "pointer",
      pointer2: "default",
      projects: {},
      period: {
        start: null,
        end: null,
      },
    };
  },
  async created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isDevs = this.user.department === this.$v.DEPARTAMENT.DEV;
  },
  methods: {
      projectSelected(project) {
            let query = null
            if (project.hasOwnProperty('id') && project.id > 0) {
                window.open("https://admin.dbmenos.com/devModule?id="+project.id, '_blank')
            }else{
                this.$router.push({
                    name: 'devModule',
                    query: query
                })
            }
        },
    async sendProject() {
      if (this.projects.name == "") {
        this.$vs.notification(this.$notification(
                'info',
                 'Ingresa un nombre'
                     ));
      } else {
        let url = this.$v.SERVER + this.$v.DEVS.SET_PROJECTS;
        let request = {
          name: this.projects.name,
          description: this.projects.description,
        };
        this.$showLoader(true);
        this.$axios.post(url, request).then((response) => {
          this.$showLoader(false);
          if (response.data[this.$v.API_SUCCESS]) {
            this.$vs.notification(this.$notification(
                'success',
                 'Carga exitosamente'
                     ));
            this.getProjects(1);
            this.uploadSelected = null;
          } else {
            this.$showLoader(false);
            this.$validateSession(response.data);
          }
        });
      }
    },
    ConsultantFilter(value) {
      this.consultantFilter = value;
    },
    setConsultantFilter(value) {
      this.consultantFilter = value;
      this.projections.consultant = value;
    },
    setConsultantFilterProjections(value) {
      this.consultantFilterProjectios = value;
      this.getProjects(1);
    },
    getCustomerFilterText() {
      if (this.consultantFilter)
        return this.getConsultantName(this.consultantFilter);
      return "Plataforma";
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id) return this.sellers[i].name;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return this.customerService[i].name;
      return "- - -";
    },

    getProjects(page) {
      let limit = this.pagination.perPage;
      let url = this.$v.SERVER + this.$v.DEVS.GET_VIEW_PROJECTS;
      let requestS = {
        offset: limit * (page - 1),
        limit: limit,
      };
      if (this.periodFilter === 1) {
        let created = { gt: this.period.start };
        Object.assign(requestS, { created });
      }
      if (this.periodFilter === 2) {
        let created = {
          gt: this.period.start + "T12:00:00.000Z",
          lte: this.period.end + "T12:00:00.000Z",
        };
        requestS = Object.assign(requestS, { created });
      }
      this.$showLoader(true);
      this.$axios.post(url, requestS).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.tableData = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.pagination.total = p["totalItems"];
          this.pagination.currentPage = page;
          this.pagination.perPage = limit;
        } else {
          this.$validateSession(data);
        }
      });
    },
    setPeriodFilter(value) {
      if (value === 1) {
        let date = new Date();
        let month = date.getMonth() + 1;
        if (month < 10) month = "0" + month;
        this.period.start =
          date.getFullYear() + "-" + month + "-" + "01" + "T12:00:00.000Z";
      }
      this.periodFilter = value;
      this.getProjects(1);
    },
  },
};
</script>
<style>
</style>