<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">

                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">

                <template slot="columns">
                  <th v-bind:style="{cursor:pointer2}">Consultor</th>
                  <th v-bind:style="{cursor:pointer2}" v-for="(status, index) in leadStatus" :key="index">{{status.status}}</th>
                </template>

                <template slot-scope="{row}">
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.seller_name}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.Prospectos}}</td>
                  <!--td class="text-center" v-bind:style="{cursor:pointer2}">{{row.sum_leads}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.not_events}}</td-->
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.ICSE}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.NoPerfila}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.Seguimiento}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.SiPerfilaNoInteresado}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.SiPerfilaIntersado}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.Documentos}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.Contrato}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.Cerrado}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.ContratoSinDeposito}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.Duplicado}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.DatosIncorrectos}}</td>
                  <td class="text-center" v-bind:style="{cursor:pointer2}">{{row.Reasignado}}</td>

                </template>

            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} Cuentas
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'finamigo-view-table',
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
        },
        data() {
            return {
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                account: null,
            }
        },
        computed: {
          leadStatus() { return this.$store.state.leadStatus },
        },
        methods: {
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
        }
    }
</script>
<style>
</style>
