<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                    <h4 v-if="(showData || showisService)" class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{$formatCurrency(amount)}}
                    </h4>
                </div>
                <div class="col text-right">
                     <base-button v-if="!(showData || showisService) && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="showPayments"></base-button>
                    <base-button v-if="(showData || showisService)" type="primary" size="sm" v-on:click="onNewItem">Nuevo</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        v-on:click="paymentSelected">

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">Depósito</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha</th>
                    <th v-bind:style="{cursor:pointer2}">Cantidad</th>
                    <th v-bind:style="{cursor:pointer2}">Validado</th>
                    <th v-bind:style="{cursor:pointer2}">Creado el</th>
                    <th v-if="isAuditor || isManagerService || isAdmin" class="text-center" v-bind:style="{cursor:pointer2}">Eliminar</th>
                </template>

                <template v-if="(showData || showisService)" slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{row.id}}</span>
                            </div>
                        </div>
                    </th>
                    <td>{{formatDate(row.date)}}</td>
                    <td>{{$formatCurrency(row.amount)}}</td>
                    <td>
                        <div class="d-flex align-items-center">
                            <span v-bind:class="getValidatedClass(row.validated)">{{row.validated ? "Validado": "No validado"}}</span>
                        </div>
                    </td>
                    <td>{{formatDate(row.created)}}</td>
                    <td v-if="isAuditor || isManagerService || isAdmin" class="text-center text-danger"><i class="fas fa-trash" @click.stop="deleteFile(row)"></i></td>
                </template>

            </base-table>
        </div>

        <div v-if="(pagination.total > pagination.perPage) && showData" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'clients-table',
        props: {
            type: {
                type: String
            },
            title: String,
            amount: Number,
            showisService: Boolean,
            data: Array,
            showEye: Boolean,
            pagination: Object
        },
        data() {
            return {
                isAuditor: false,
                isAdmin: false,
                isManagerService:false,
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                showData:false
            }
        },
        computed: {
            banks() { return this.$store.state.banks },
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
        },
        async created() {
          this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
          this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
          this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
          this.isService = this.user.role === this.$v.ROLES.SERVICE
          if(this.isService) this.showData=true;
        },
        methods: {
                        showPayments(){
                this.showData = true;
                this.$emit("showPayments")
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            getBanks(array) {
                let banks = []
                for (let a=0 ; a<=array.length ; a++) {
                    for (let b = 0; b < this.banks.length; b++) {
                        if (this.banks[b].id === array[a]) {
                            banks.push(this.banks[b])
                            break
                        }
                    }
                }
                return banks
            },
            formatDate(date) {
                return this.$moment(date).format("DD MMM, YYYY")
              // return this.$formatDate(date)
            },
            onNewItem() {
                this.$emit("onNewItem")
            },
            paymentSelected(payment) {
                this.$emit("onPaymentSelected", payment)
            },
            //---Modification
            deleteFile(dataM){
                dataM.type = 'PAYMENT';
                this.$emit("newModification", dataM, this.$v.MODIFICATIONS.PAYMENT)
            },
        }
    }
</script>
<style>
</style>
