<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>
		<div class="container-fluid" style="margin-top: 24px">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<vue-editor v-model="entry.post" style="background-color: #FFFFFF"/>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<base-input alternative=""
								label="Título"
								input-classes="form-control-alternative"
								v-model="entry.title"/>
				</div>
			</div>
			<div class="row pb-3">
				<div class="col-lg-7">
					<label class="form-control-label">Descripción corta para redes sociales</label>
					<textarea class="form-control form-control-alternative" rows="5" v-model="entry.description"/>
				</div>
				<div class="col-lg-5">
					<dropbox-visor title="Miniatura para redes sociales" text="Miniatura para redes sociales" :miniURL="miniURL" :files="miniature" v-on:setMiniature="setMiniature"/>
				</div>
			</div>
			<div class="row" style="align-items: center">
				<div class="col-10">
					<div class="URLshare">
						<h5>Enlace para compartir:</h5>
						<h2>{{linkToShare}}</h2>
					</div>
				</div>
				<div class="col-2 text-right" style="margin-top: 24px; margin-bottom: 24px">
					<base-button slot="title" type="primary" class="btn" v-on:click="setBlogEntry()">
						Guardar
					</base-button>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import {VueEditor} from 'vue2-editor'
	import DropboxVisor from '../components/DropboxVisor'
	export default {
		name: 'tables',
		components: {
			VueEditor,
			DropboxVisor
		},
		created() {
			let id = this.$route.query.id
			if (id > 0) {
				this.getBlogEntry(id)
			}
		},
		computed: {
			user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
			linkToShare() { return 'https://dbmenos.com/blog/' + this.entry.title.normalize('NFD')
     .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2").replace(/[¿?]/gi,'')
     .normalize().split(' ').join('-') + '.html' }
		},
		data() {
			return {
				modal: false,
				entry: {
					id: null,
					title: "",
					description: "",
					filename: null,
					post: "",
					url_link: "",
					created: null,
				},
				miniature: [],
				og_picture: null,
				miniURL: null,

				pointer: 'pointer',
				pointer2: 'default',
			};
		},
		methods: {
			async removeBlogEntry() {
				let url = this.$v.SERVER + this.$v.ADMIN_OPS.SET_CONSULTANT
				this.$showLoader(true)
				let response = await this.$axios.post(url, this.selectedConsultant)
				let data = response.data
				if (data[this.$v.API_SUCCESS]) {
					console.log(data)
					setTimeout( async() => {
						this.modal = false
						await this.getConsultants()
						this.$forceUpdate()
					}, 500)
				} else {
					this.$showLoader(false)
					this.$validateSession(data)
				}
			},
			async getBlogEntry(id) {
				let url = this.$v.SERVER + this.$v.MARKETING_OPS.GET_BLOG_ENTRIES + "?id=" + id
				this.$showLoader(true)
				let response = await this.$axios.get(url)
				this.$showLoader(false)
				let data = response.data
				if (data[this.$v.API_SUCCESS]) {
					let blogEntry = data[this.$v.API_ROW]
					if (blogEntry) {
						this.entry = blogEntry
						if (blogEntry.og_picture)
							this.miniURL = 'https://www.dbmenos.com/img/blog/' + blogEntry.og_picture 
						else
							this.miniURL = null
						this.$forceUpdate()
					}
				} else {
					this.$router.push("/blogEntries")
				}
			},
			async setBlogEntry() {
				let url = this.$v.SERVER + this.$v.MARKETING_OPS.SET_BLOG_ENTRY
				this.$showLoader(true)
//				let formData = new FormData();
//				formData.append("file", this.entry.og_picture);
				this.entry.url_link=this.entry.title.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2").replace(/[¿?]/gi,'').normalize().split(' ').join('-');
				let response = await this.$axios.post(url, this.entry)
				this.$showLoader(false)
				let data = response.data
				if (data[this.$v.API_SUCCESS]) {
					let signedUrl = data[this.$v.API_ROW]['uploadUrl']
//					console.log(this.og_picture)
					await this.$axios.put(signedUrl, this.og_picture, {headers: {
							'Content-Type': this.og_picture ? this.og_picture.type : null
						}})
//					console.log(putResponse)
this.$vs.notification(this.$notification(
                'success',
                 'Guardado exitosamente'
                     ));
					this.$router.push('/blogEntries')
				} else {
					this.$vs.notification(this.$notification(
                'danger',
                 'Algó salio mal',
                 'Intenta nuevamente'
                     ));
				}
			},
			setMiniature(obj) {
				let file = obj.file
				this.entry.filename = file.name
				this.og_picture = file
			}
		}
	};
</script>
<style>
	.URLshare {
		align-items: center;
	}
</style>
