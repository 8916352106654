<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<massive-credit-report-table 
							title="Visor de reportes de crédito"
							:data="tableData"
							:pagination="pagination"
							:period="period"
							v-on:setPeriodFilter="setPeriodFilter"
							v-on:setConsultantFilter="setConsultantFilter"
							v-on:pageSelected="getDataList"/>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import MassiveCreditReportTable from './Tables/MassiveCreditReportTable'
	export default {
		name: 'tables',
		components: {
			MassiveCreditReportTable //Massive
		},
		mounted() {
			this.getDataList(1)
		},
		computed: {
			user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
		},
		data() {
			return {
				pagination: {
					total: 0,
					perPage: 10,
					currentPage: 1
				},
				period: {
					start: null,
					end: null
				},
				tableData: [],
				consultantFilter: null,
				periodFilter: null,
				pointer: 'pointer',
				pointer2: 'default'
			};
		},
		methods: {
			getDataList(page) {
				let limit = this.pagination.perPage
				let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_MASSIVE_CREDIT_REPORT
				let request ={
					offset: limit*(page - 1),
					limit: limit
				}
				if (this.consultantFilter) Object.assign(request,{consultant: this.consultantFilter})
				if (this.periodFilter === 1) {
					let created = {gte: this.period.start}
					Object.assign(request, {created})
				}else if (this.periodFilter === 2){
					let created = {
						gt: this.period.start,//+"T12:00:00.000Z",
						lte: this.period.end//+"T12:00:00.000Z"
					}
					Object.assign(request, {created})
				}
				this.$showLoader(true)
				this.$axios.post(url,request).then(response => {
					this.$showLoader(false)
					let data = response.data
					if (data[this.$v.API_SUCCESS]) {
						this.tableData = data[this.$v.API_ROWS]
						let p = data[this.$v.API_ROW]
						this.pagination.total = p['totalItems']
						this.pagination.currentPage = page
						this.pagination.perPage = limit
					} else { this.$validateSession(data) }
				})
			},
			setConsultantFilter(value) {
				this.consultantFilter = value
				this.getDataList(1)
			},
			setPeriodFilter(value) {
				if(value === 1){
					let date = new Date()
					let month = date.getMonth()+1
					if(month<10) month= "0"+month
					this.period.start = date.getFullYear()+"-"+month+"-"+"01"
				}
				this.periodFilter = value
				this.getDataList(1)
			}
		}
	};
</script>
<style></style>