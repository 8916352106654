<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button v-if="!disabledNew" type="primary" size="sm" v-on:click="newLeadTrace">Nuevo</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush custom-text-wrap"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data">
        <template slot="columns">
          <th>Estatus</th>
          <th>Comentarios</th>
          <th>Fecha</th>
          <th v-if="!disabledNew">Asesor</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.status}}</span>
              </div>
            </div>
          </th>
          <td>{{row.comments}}</td>
          <td>{{formatDate(row.created)}}</td>
          <td v-if="!disabledNew">{{getConsultantName(row.upload_by)}}</td>
        </template>

      </base-table>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'leadstracing-table',
    props: {
      type: {
        type: String
      },
      title: String,
      data: Array,
      disabledNew: Boolean,
      pagination: Object
    },
    data() {
      return {
        tableData: [],
      }
    },
    computed: {
      banks() { return this.$store.state.banks },
    teams() {return this.$store.state.TeamsLeaders;},
    managerService() { return this.$store.state.managerService },
    sellers() { return this.$store.state.sellerNotEnable; },
    sellersGood() { return this.$store.state.sellers },
    customerService() { return this.$store.state.customerService; },
    },
    async created() { },
    methods: {
       getConsultantName(id) {
         if(id == '998'){
           return 'Bucket Leads'
         }
         if(id == '999'){
           return 'Bot dbmenos'
         }
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id)
          return this.sellers[i].name + " " + this.sellers[i].lastname_1;
      for (let i = 0; i < this.teams.length; i++)
        if (this.teams[i].id === id)
          return this.teams[i].name + " " + this.teams[i].lastname_1;
      for (let i = 0; i < this.managerService.length; i++)
        if (this.managerService[i].id === id)
          return this.managerService[i].name + " " + this.managerService[i].lastname_1;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return (
            this.customerService[i].name +
            " " +
            this.customerService[i].lastname_1
          );
      return "- - -";
    },
      formatDate(date) {
      if (date <= "2020-11-10") {
        return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
      } else {
        return this.$moment(date).format("DD MMM YYYY, h:mm a");
      }
    },
      newLeadTrace() {
        this.$emit("newLeadTrace")
      }
    }
  }
</script>
<style>
</style>
