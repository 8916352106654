<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <!--style="min-height: 300px; background-image: url(img/theme/profile-cover.jpg); background-size: cover; background-position: center top;">-->
      <!-- Mask -->
      <span class="mask bg-gradient-dbm opacity-8"></span>
    </base-header>

    <div v-if="edit" class="container-fluid mt--7" v-bind:style="{ cursor: pointer2 }" @keydown.esc="escEdit">
      <div class="row">
        <div class="col-lg-12 center">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Información cliente</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button
                    slot="title"
                    type="secondary"
                    class="btn"
                    size="sm"
                    v-on:click="escEdit"
                    >Cancelar</base-button
                  >
                  <base-button
                    slot="title"
                    type="primary"
                    class="btn"
                    size="sm"
                    v-on:click="uploadChanges()"
                    >Guardar</base-button
                  >
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <div>
                  <h6 class="heading-small text-muted mb-4 col-lg-11">
                    Información general
                  </h6>
                </div>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input
                        alternative
                        label="Nombre"
                        input-classes="form-control-alternative"
                        v-model="client.name"
                        v-on:input="calculateRFC_CURP()"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative
                        label="Primer apellido"
                        input-classes="form-control-alternative"
                        v-model="client.lastname_1"
                        v-on:input="calculateRFC_CURP()"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative
                        label="Segundo apellido"
                        input-classes="form-control-alternative"
                        v-model="client.lastname_2"
                        v-on:input="calculateRFC_CURP()"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input
                        alternative
                        :disabled="isSeller && editClientPhone"
                        label="Teléfono"
                        input-classes="form-control-alternative"
                        v-model="client.phone"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative
                        label="Teléfono de casa"
                        input-classes="form-control-alternative"
                        v-model="client.home_phone"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative
                        label="Teléfono de oficina"
                        input-classes="form-control-alternative"
                        v-model="client.office_phone"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative
                        label="Teléfono exterior"
                        input-classes="form-control-alternative"
                        v-model="client.ext_phone"
                      />
                    </div>
                    <div class="col-lg-5" style="alignment: bottom">
                      <base-input
                        alternative
                        label="Correo electrónico"
                        input-classes="form-control-alternative"
                        v-model="client.email"
                      />
                    </div>
                    <div class="col-lg-3">
                      <label class="form-control-label"
                        >Fecha de nacimiento</label
                      >
                      <flat-pickr
                        v-model="client.birthday"
                        :config="configDate"
                        class="form-control form-control-alternative"
                        data-vv-as="Fecha"
                        name="date"
                        style="background-color: #ffffff"
                        v-on:input="calculateRFC_CURP()"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2 text-center">
                      <base-dropdown>
                        <base-button
                          slot="title"
                          type="outline"
                          class="dropdown-toggle"
                          size="sm"
                          style="margin-top: 15px; white-space: normal"
                          :class="gender === null ? 'text-danger' : ''"
                          >{{
                            gender ? "Género " + gender : "Selecciona género"
                          }}</base-button
                        >
                        <a
                          class="dropdown-item"
                          v-on:click="setGender('Masculino')"
                          >Masculino</a
                        >
                        <a
                          class="dropdown-item"
                          v-on:click="setGender('Femenino')"
                          >Femenino</a
                        >
                      </base-dropdown>
                      <base-dropdown>
                        <base-button
                          slot="title"
                          type="outline"
                          class="dropdown-toggle"
                          size="sm"
                          style="margin-top: 10px; white-space: normal"
                          :class="stateOfBirth === null ? 'text-danger' : ''"
                          >{{
                            stateOfBirth
                              ? "Nacido en " + stateOfBirth
                              : "Estado de nacimiento"
                          }}</base-button
                        >
                        <a
                          class="dropdown-item"
                          v-for="(state, index) in mxStates"
                          :key="index"
                          v-on:click="setMxState(state)"
                          >{{ state.state }}</a
                        >
                      </base-dropdown>
                    </div>
                    <div class="col-lg-2 text-center">
                      <base-dropdown>
                        <base-button
                          slot="title"
                          type="outline"
                          class="dropdown-toggle"
                          size="sm"
                          style="margin-top: 15px; white-space: normal"
                          :class="
                            client.civil_status === null ? 'text-danger' : ''
                          "
                          >{{
                            client.civil_status
                              ? client.civil_status
                              : "Estado civil"
                          }}</base-button
                        >
                        <a
                          class="dropdown-item"
                          v-on:click="clientCivilStatusSelected('Casado')"
                          >Casado</a
                        >
                        <a
                          class="dropdown-item"
                          v-on:click="clientCivilStatusSelected('Soltero')"
                          >Soltero</a
                        >
                      </base-dropdown>
                      <base-dropdown>
                        <base-button
                          slot="title"
                          type="outline"
                          class="dropdown-toggle"
                          size="sm"
                          style="margin-top: 10px; white-space: normal"
                          :class="
                            client.economic_activity === null
                              ? 'text-danger'
                              : ''
                          "
                          >{{
                            client.economic_activity
                              ? getEconomicActivity()
                              : "Actividad económica"
                          }}</base-button
                        >
                        <a
                          class="dropdown-item"
                          v-for="(activity, index) in economicActivities"
                          :key="index"
                          v-on:click="setEconomicActivity(activity.id)"
                          >{{ activity.activity }}</a
                        >
                      </base-dropdown>
                    </div>
                    <div class="col-lg-2">
                      <base-input
                        alternative
                        label="RFC"
                        placeholder="13 dígitos"
                        input-classes="form-control-alternative"
                        v-model="client.rfc"
                      />
                    </div>
                    <div class="col-lg-3">
                      <base-input
                        alternative
                        label="CURP"
                        placeholder="18 dígitos"
                        input-classes="form-control-alternative"
                        v-model="client.curp"
                      />
                    </div>
                    <div class="col-lg-3">
                      <base-input
                        alternative
                        label="Identificación oficial"
                        input-classes="form-control-alternative"
                        v-model="client.id_number"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />

                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">Dirección</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-8">
                      <base-input
                        alternative
                        label="Calle"
                        :valid="streetState"
                        input-classes="form-control-alternative"
                        v-model="client.addr_street"
                      />
                    </div>
                    <div class="col-md-2">
                      <base-input
                        alternative
                        label="Número exterior"
                        input-classes="form-control-alternative"
                        v-model="client.addr_extnum"
                      />
                    </div>
                    <div class="col-md-2">
                      <base-input
                        alternative
                        label="Número interior"
                        input-classes="form-control-alternative"
                        v-model="client.addr_intnum"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">
                      <base-input
                        alternative
                        label="Código postal"
                        :valid="codeState"
                        input-classes="form-control-alternative"
                        v-model="client.addr_postal_code"
                        v-on:input="postalCodeUpdated"
                      />
                    </div>
                    <div class="col-lg-1">
                      <base-dropdown v-if="postalCodes.length > 1">
                        <a
                          class="dropdown-item"
                          v-for="(postalCode, index) in postalCodes"
                          :key="index"
                          v-on:click="postalCodeSelected(postalCode)"
                          >{{ postalCode.postal_code }}</a
                        >
                      </base-dropdown>
                    </div>
                    <div class="col-lg-3">
                      <base-input
                        alternative
                        label="Colonia"
                        input-classes="form-control-alternative"
                        v-model="client.addr_sublocality"
                      />
                    </div>
                    <div class="col-lg-1">
                      <base-dropdown v-if="sublocalities.length > 0">
                        <a
                          class="dropdown-item"
                          v-for="(sublocality, index) in sublocalities"
                          :key="index"
                          v-on:click="sublocalitySelected(sublocality)"
                          >{{ sublocality }}</a
                        >
                      </base-dropdown>
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative
                        label="Ciudad"
                        input-classes="form-control-alternative"
                        v-model="locality"
                      />
                    </div>
                  </div>
                </div>

                <!--
                <hr class="my-4" />
                <div>
                  <h6 class="heading-small text-muted mb-4 col-lg-11">
                    Información del beneficiario
                  </h6>
                </div>
                <div class="pl-lg-4">
                   <div class="row">
                    <div class="col-lg-3">
                      <base-input
                        alternative
                        label="Nombre"
                        input-classes="form-control-alternative"
                        v-model="beneficiary.name"
                      />
                    </div>
                    <div class="col-lg-3">
                      <base-input
                        alternative
                        label="Primer apellido"
                        input-classes="form-control-alternative"
                        v-model="beneficiary.lastname_1"
                      />
                    </div>
                    <div class="col-lg-3">
                      <base-input
                        alternative
                        label="Segundo apellido"
                        input-classes="form-control-alternative"
                        v-model="beneficiary.lastname_2"
                      />
                    </div>
                     <div class="col-lg-3 text-center">
                      <base-dropdown style="margin-top: 30px">
                        <base-button
                          slot="title"
                          type="outline"
                          class="dropdown-toggle"
                          large="true"
                          :class="
                            beneficiary.relationship === null
                              ? 'text-danger'
                              : ''
                          "
                          >{{
                            beneficiary.relationship
                              ? getRelationBeneficiary(beneficiary.relationship)
                              : "Parentesco"
                          }}</base-button
                        >
                        <a
                          class="dropdown-item"
                          v-for="(relation, index) in relationBeneficiary"
                          :key="index"
                          v-on:click="beneficiaryRelationSelected(relation.id)"
                          >{{ relation.value }}</a
                        >
                      </base-dropdown>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <label class="form-control-label"
                        >Fecha de nacimiento</label
                      >
                      <flat-pickr
                        v-model="beneficiary.birthday"
                        :config="{}"
                        class="form-control form-control-alternative"
                        data-vv-as="Fecha"
                        name="date"
                        style="background-color: #ffffff"
                      />
                    </div>
                    <div class="col-lg-4 text-center">
                      <base-dropdown style="margin-top: 30px">
                        <base-button
                          slot="title"
                          type="outline"
                          class="dropdown-toggle"
                          :class="beneficiaryGender ? '' : 'text-danger'"
                          >{{
                            beneficiary.gender
                              ? "Género " + beneficiaryGender
                              : "Selecciona género"
                          }}</base-button
                        >
                        <a
                          class="dropdown-item"
                          v-on:click="setBeneficiaryGender('Masculino')"
                          >Masculino</a
                        >
                        <a
                          class="dropdown-item"
                          v-on:click="setBeneficiaryGender('Femenino')"
                          >Femenino</a
                        >
                      </base-dropdown>
                    </div>
                    <div class="col-lg-4 text-center">
                      <base-dropdown style="margin-top: 30px">
                        <base-button
                          slot="title"
                          type="outline"
                          class="dropdown-toggle"
                          large="true"
                          :class="beneficiary.civil_status ? '' : 'text-danger'"
                          >{{
                            beneficiary.civil_status
                              ? beneficiary.civil_status
                              : "Estado civil"
                          }}</base-button
                        >
                        <a
                          class="dropdown-item"
                          v-on:click="beneficiaryCivilStatusSelected('Casado')"
                          >Casado</a
                        >
                        <a
                          class="dropdown-item"
                          v-on:click="beneficiaryCivilStatusSelected('Soltero')"
                          >Soltero</a
                        >
                      </base-dropdown>
                    </div>
                  </div>
                  <div class="row"></div>
                </div>
                 Beneficiary -->
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
    <div v-else class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-11 center">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a>
                    <img
                      src="img/theme/avatar.jpg"
                      class="rounded-circle"
                      style="background-color: aliceblue"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div class="d-flex justify-content-between">
                <base-button v-if="!statusAudit" size="sm" type="default" class="float-right"
                  v-on:click="edit = true">Editar</base-button>
                <br/>
                <base-button
                  size="sm"
                  type="danger"
                  class="float-right"
                  v-on:click="resetPassword()"
                  >Poner contraseña por defecto</base-button
                >
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div class="row card-profile-stats d-flex justify-content-center mt-md-1" v-if="client.contract > 0">
                      <div v-if="editPayday">
                        <div>
                      <base-input
                            alternative
                            label="Día de depósito mensual"
                            input-classes="form-control-alternative"
                            v-model="client.payday"
                          />
                        </div>
                        <div>
                        <base-button
                        id="previousId"
                        type="outline-info"
                        size="sm"
                        title="Guardar Día de Deposito"
                        v-on:click="uploadContract()"
                        >Guardar</base-button
                    >
                      </div>
                      </div>
                    <div v-if="!editPayday">
                    <div>
                      <span class="heading">{{ client.payday }}</span>
                      <span class="description">Día depósito</span>
                    </div>
                      <div>
                        <base-button
                        id="previousId"
                        type="outline-info"
                        size="sm"
                        title="Editar Día de Deposito"
                        v-on:click="editPayday = true"
                        >Editar</base-button
                    >
                      </div>
                      </div>
                    <div>
                      <span class="heading">{{
                        this.$formatCurrency(client.monthly_savings)
                      }}</span>
                      <span class="description">Ahorro Mensual</span>
                    </div>
                    <div>
                      <span class="heading">{{ client.program_duration }}</span>
                      <span class="description">Duración</span>
                    </div>
                    <div>
                      <span class="heading">{{
                        $formatCurrency(client.balance)
                      }}</span>
                      <span class="description">Saldo</span>
                    </div>
                    <div>
                      <span class="heading">{{
                        $formatCurrency(client.total_debt)
                      }}</span>
                      <span class="description">Monto Deuda Ingresada</span>
                    </div>
                    <div>
                      <span class="heading">{{duePaymentCount ? duePaymentCount : "--" }}</span>
                      <span class="description">{{duePaymentCount && duePaymentCount>0? 'Meses Adelantados' : 'Meses Atrasados' }}</span>
                    </div>
                    <div>
                      <span class="heading">{{ this.$formatCurrency(duePaymentSum) }}</span>
                      <span class="description"><span class="description">{{ duePaymentSum && duePaymentSum>0? 'Monto Adelantado' : 'Monto Atrasado' }}</span></span>
                    </div>
                    <div>
                      <span class="heading">{{
                        nextPaymentDate
                          ? nextPaymentDate
                          : "Indefinida"
                      }}</span>
                      <span class="description">Fecha proximo pago</span>
                    </div>
                    <div v-if="!isSeller && client.date_promise">
                      <span class="heading">{{
                        formatDate(client.date_promise)
                      }}</span>
                      <span class="description">Fecha de promesa</span>
                    </div>
                    <!--
                    <div
                      v-if="
                        isAdmin || isManagerService || isService || isAuditor
                      "
                    >
                      <div>
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="clientPaymentResume.covid"
                          v-on:click="setCovid"
                        />
                        <label class="form-control-label">Covid</label>
                      </div>
                    </div>
                    !-->
                  </div>
                  <div
                    v-else
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  ></div>
                </div>
              </div>
              <div v-if="withNomina">
                <div
                  v-if="isAdmin || isManagerService || isService || isAuditor"
                  class="text-center"
                >
                  <h3>
                    Nómina en el mismo banco:
                    <b v-for="nomina in nominas" :key="nomina">
                      <pre>- {{ getBank(nomina.bank).name }}</pre>
                    </b>
                  </h3>
                </div>
              </div>
              <div v-if="!withNomina">
                <div v-if="isAdmin || isManagerService || isService || isAuditor" class="text-center">
                  <h3>Sin nómina en el mismo banco</h3>
                </div>
              </div>

              <div class="text-center">
                <!--divF class="col" :title="titleProgressBar">
                  <div class="progress" style="height: 8px">
                    <div class="progress-bar" role="progressbar" :style="widthProgressBar">
                      <div class="row">
                        <div v-if="client.step_basic_information" title="Información básica" class="col text-center"></div>
                        <div v-if="client.step_basic_information2" title="Información básica 2" class="col text-center"></div>
                        <div v-if="client.step_address_information" title="Domicilio" class="col text-center"></div>
                        <div v-if="client.step_official_id" title="Identificación oficial" class="col text-center"></div>
                        <div v-if="client.step_credit_report_signature" title="Firma reporte de crédito" class="col text-center"></div>
                        <div v-if="client.step_credit_report" title="Reporte de crédito" class="col text-center"></div>
                        <div v-if="client.step_program_calculation" title="Programa de cálculo" class="col text-center"></div>
                        <div v-if="client.step_complete_profile" title="Perfil completo" class="col text-center"></div>
                        <div v-if="client.step_contract" title="Contrato" class="col text-center"></div>
                        <div v-if="client.step_active" title="Activo" class="col text-center"></div>
                      </div>
                    </div>
                  </div>
                </div-->
                <h2>
                  {{ client.name }} {{ client.lastname_1 }}
                  {{ client.lastname_2 }}
                </h2>
                <div v-if="isAdmin || isManagerService || isAuditor" class="text-center">
                  <h4>
                    Canal de adquisición: {{client.device? client.device:'Tradicional'}}
                  </h4>
                </div>
                <div
                  v-if="client.first_payment && checkInactive === false"
                  class="col"
                >
                  <div class="card-profile-stats d-flex justify-content-center" v-if="statusClient">
                    <span class="heading" :class="client.program_status === -1? 'text-danger' : 'text-success'">
                      {{client.program_status === 1? "Adelantado" :
                        client.program_status === 0 ? "Al corriente" : "Atrasado"}}
                    </span>
                  </div>
                </div>
                <div
                  v-if="client.first_payment && !existAddress && (isAdmin || isService || isManagerService)"
                  class="col"
                >
                  <div class="card-profile-stats d-flex justify-content-center">
                    <span class="heading text-danger">
                      {{ existAddress ? "" : "No existe aún comprobante de domicilio del cliente"}}
                    </span>
                  </div>
                </div>
                <div class="card-profile-stats d-flex justify-content-center" v-if="statusCancel.status>0 && (isAdmin)">
                    <span
                      class="heading"
                      :class="
                        statusCancel.status == 1
                          ? 'text-danger'
                          : 'text-info'
                      "
                      >{{
                        statusCancel.status == 1
                          ? "Cliente N3Baja"
                          : "Cliente Recuperado"
                      }}</span
                    >
                  </div>
                <div v-if="checkInactive === true" class="col">
                  <div class="card-profile-stats d-flex justify-content-center">
                    <span
                      class="heading"
                      :class="
                        client.inactive === 1 ? 'text-danger' : 'text-success'
                      "
                      >{{
                        client.inactive === 1
                          ? "Baja del Programa"
                          : client.inactive === 2
                          ? "Graduado"
                          : client.inactive === 3 
                          ? "En proceso de baja del Programa"
                          : ""
                      }}</span>
                  </div>
                </div>
                <div v-if="completeAudit" class="text-center">
                  <h4 class="text-success">
                    AUDITADO COMPLETO
                  </h4>
                  <base-button
                    v-if="isAdmin"
                    style="margin-bottom:20px;"
                    id="authorizationPorfile"
                    type="outline-warning"
                    size="sm"
                    title="Autorizar edición perfil"
                    v-on:click="setStatusAuditManagerSeller()"
                    >Autorizar edición perfil</base-button
                  >
                </div>
                {{ client.email }}
                <div v-if="reactivated" class="col text-center" 
                  style="margin-top:1rem;">
                  <span class="heading text-success"
                    >Reactivado</span>
                    <br>
                  <base-button
                    style="margin-top:10px;"
                    id="previousId"
                    type="outline-warning"
                    size="md"
                    title="Ir a perfil anterior"
                    v-on:click="goToPreviousProfile()"
                    >Ver Plan Anterior</base-button
                  >
                </div>
                <div class="row pt-4">
                  <!--
                  <div v-if="clientCalculations && clientCalculations.debts" class="col">
                    <base-button
                      id="showModal"
                      type="outline-info"
                      class="p-2"
                      size="sm"
                      v-on:click="setCalc(clientCalculations)"
                    >Ver calculadora</base-button>
                  </div>
                  <div class="col">
                    <base-button
                      id="showModal"
                      type="outline-info"
                      class="p-2"
                      size="sm"
                      v-on:click="calculator"
                      >Calculadora</base-button
                    >
                  </div>
                  <div v-if="client.phone || !isService" class="col">
                    <base-button
                      id="showSendMessage"
                      type="outline-info"
                      class="p-2"
                      size="sm"
                      v-on:click="setTypeMessage('SMS')"
                      >Enviar SMS</base-button
                    >
                  </div>
                  !-->
                  <div v-if="client.phone" class="col">
                    <base-button
                      id="showSendPushNotification"
                      type="outline-info"
                      class="p-2"
                      size="sm"
                      v-on:click="setTypeMessage('PN')"
                      >Notificación push</base-button
                    >
                    <base-button
                        v-if="isService || isManagerService"
                        id="chatInit"
                        type="outline-info"
                        class="p-2"
                        size="sm"
                        v-on:click="chatInit"
                    >Iniciar chat</base-button
                    >
                  </div>

                  <!--div v-if="report && calculos && calculos.debts" class="col">
                                        <base-button id="showCreditReport" type="outline-warning" class="p-2" size="sm" v-on:click="showModalReport=true">
                                            Ver reporte de crédito
                                        </base-button>
                  </div-->
                </div>

                <div class="col">
                  <div class="card-profile-stats d-flex justify-content-center">
                    <div>
                      <span class="heading">{{
                        client.birthday ? formatDateOther(client.birthday) : "-"
                      }}</span>
                      <span class="description">Fecha de nacimiento</span>
                    </div>
                    <div
                      v-if="
                        !client.phone &&
                        !client.office_phone &&
                        !client.home_phone &&
                        !client.ext_phone
                      "
                    >
                      <span class="heading">-</span>
                      <span class="description">Teléfono</span>
                    </div>
                    <div
                      v-if="
                        isAdmin || isManagerService || isService || isAuditor "
                    >
                      <span class="heading">{{
                        getConsultantName(client.last_consultant)
                      }}</span>
                      <span class="description">Asesor de Ventas DBM</span>
                    </div>
                    <div v-if="isAdmin || isAuditor || isManagerService">
                      <base-dropdown class="dropdown">
                            <a style="font-size:1rem; margin-top:-0.5rem;" slot="title" class="btn btn-sm" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {{getConsultantName(client.consultant)}}
                            </a>
                            <template v-if="isAdmin || isManagerSeller">
                                <a v-for="(seller) in sellersGood" :key="seller.id" class="dropdown-item" v-on:click="assignToConsultant(client, seller)">{{seller.name}} {{seller.lastname_1}}</a>
                                <div class="dropdown-divider"></div>
                            </template>
                            <template v-if="isAdmin || isManagerService">
                                <a v-for="(customerS) in customerService" :key="customerS.id" class="dropdown-item" v-on:click="assignToConsultant(client, customerS)">{{customerS.name}} {{customerS.lastname_1}}</a>
                            </template>
                        </base-dropdown>
                        <br>
                      <span class="description">Asesor DBM</span>
                    </div>
                  </div>
                </div>
                <div
                  class="col"
                  v-if="
                    client.phone ||
                    client.office_phone ||
                    client.home_phone ||
                    client.ext_phone
                  "
                >
                  <div class="card-profile-stats d-flex justify-content-center">
                    <div v-if="client.phone">
                       <base-button
                      type="outline-success"
                      class="p-2"
                      size="sm"
                      style="margin-bottom:0.5rem;"
                      v-on:click="callUser"
                      ><i class="fas fa-phone text-success"></i> Marcar al Cliente</base-button>
                      <span class="heading">{{ client.phone }}</span>
                      <span class="description">Teléfono</span>
                    </div>
                    <div v-if="client.home_phone">
                      <span class="heading">{{ client.home_phone }}</span>
                      <span class="description">Teléfono casa</span>
                    </div>
                    <div v-if="client.office_phone">
                      <span class="heading">{{ client.office_phone }}</span>
                      <span class="description">Teléfono oficina</span>
                    </div>
                    <div v-if="client.ext_phone">
                      <span class="heading">{{ client.ext_phone }}</span>
                      <span class="description">Teléfono exterior</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card-profile-stats d-flex justify-content-center">
                    <div>
                      <span class="heading">{{
                        client.rfc ? client.rfc : "-"
                      }}</span>
                      <span class="description">RFC</span>
                    </div>
                    <div>
                      <span class="heading">{{
                        client.curp ? client.curp : "-"
                      }}</span>
                      <span class="description">CURP</span>
                    </div>
                    <!--
                    <div>
                      <span class="heading">{{
                        client.id_number ? client.id_number : "-"
                      }}</span>
                      <span class="description">Identificación oficial</span>
                    </div>
                    !-->
                  </div>
                </div>
                <div class="col">
                  <div class="card-profile-stats d-flex justify-content-center">
                    <div>
                      <span class="heading"
                        >{{ client.addr_street }} {{ client.addr_extnum
                        }}{{
                          client.addr_intnum ? " " + client.addr_intnum : ""
                        }}, {{ client.addr_sublocality }}</span
                      >
                      <span class="description">Dirección</span>
                    </div>
                  </div>
                </div>
                <div
                  class="col"
                  v-if="canRequestCreditReport() && client.contract === 0"
                >
                  <div class="card-profile-stats d-flex justify-content-center">
                    <div>
                      <span class="heading">
                        <base-button
                          slot="title"
                          class
                          type="primary"
                          size="lg"
                          style="white-space: normal"
                          v-on:click="requestSignatureToken(false)"
                          >Solicitar</base-button
                        >
                      </span>
                      <span class="description"
                        >Autorización consulta reporte de crédito</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <!-- Create contract -->
                  <div v-if="!isAuditor">
                    <hr class="my-4" />
                    <h6 class="heading-small text-muted mb-4">Contrato {{contract.version == 3 ? ' Tipo 3': contract.version == 1 ? ' Tipo 1':
                  contract.version == 2 ? ' Tipo 2' :' Tipo 4'}}</h6>
                    <div class="pl-lg-4">
                      <div class="row">
                        <div class="col-lg-4 text-center">
                       <span v-if="!editCommision">Porcentaje de comisión</span>
                       <br v-if="!editCommision">
                      <base-input alternative
                            v-if="editCommision"
                            label="Porcentaje de comisión"
                            input-classes="form-control-alternative"
                            v-model="contract.commission_percentage"
                          />
                      <base-dropdown v-else>
                        <base-button slot="title" type="outline" class="dropdown-toggle" large="true"
                          :disabled="!(isAdmin || isManagerService || isManagerSeller)"> 
                          {{contract.commission_percentage}}
                        </base-button>
                        <a class="dropdown-item" v-if="isAdmin" @click="editCommision=true">Otro</a>
                      </base-dropdown>
                    </div>
                     <div class="col-lg-4 text-center">
                       <span v-if="!isEspecialCommision">Porcentaje de comisión efectiva</span>
                       <br v-if="!isEspecialCommision">
                      <base-input alternative
                            v-if="isEspecialCommision"
                            label="Porcentaje de comisión efectiva"
                            input-classes="form-control-alternative"
                            v-model="contract.effective_commission"
                          />
                      <base-dropdown v-else>
                        <base-button slot="title" type="outline" class="dropdown-toggle" large="true"
                          :disabled="!(isAdmin || isManagerService || isManagerSeller)"> 
                          {{contract.effective_commission}}
                        </base-button>
                        <a class="dropdown-item" v-if="isAdmin" @click="isEspecialCommision=true">Otro</a>
                      </base-dropdown>
                    </div>
                        <div class="col-lg-4">
                          <label class="form-control-label"
                            >Fecha de primer pago</label
                          >
                          <flat-pickr
                            v-model="client.first_payment"
                            :config="{}"
                            class="form-control form-control-alternative"
                            data-vv-as="Fecha"
                            name="first_payment"
                            style="background-color: #ffffff"
                          />
                        </div>
                        <!--
                        <div class="col-lg-6">
                          <base-button v-if="!statusAudit" slot="title" class type="primary" size="sm" style="margin-top: 35px; white-space: normal"
                            v-on:click="uploadContract()">
                            {{client.contract > 0 ? "Actualizar" : "Crear" }}
                          </base-button>
                        </div>
                        
                        <div class="col">
                          <base-button
                            slot="title"
                            class
                            type="success"
                            size="sm"
                            style="margin-top: 35px; white-space: normal"
                            v-on:click="uploadContract()"
                            >Actualizar Contrato</base-button
                          >
                        </div>
                        !-->
                      </div>
                    </div>
                  </div>
                  <!-- Create finamigo account -->
                  <div v-if="client.contract > 0">
                    <hr class="my-4" />
                    <div>
                      <h6 class="heading-small text-muted mb-4">
                        Cuenta de ahorro DBMenos
                      </h6>
                      <div class="pl-lg-4">
                        <div class="row">
                          <div class="col">
                            <div class="card-profile-stats d-flex justify-content-center">
                            <div v-if="client.signature_contract == '1' && (finamigo && finamigo.account)" class="card-profile-stats d-flex justify-content-center">
                              <div>
                                <span class="heading">
                                  {{finamigo && finamigo.clabe? finamigo.clabe: "-"}}
                                </span>
                                <span class="description">CLABE</span>
                              </div>
                              <div>
                                <span class="heading">
                                  {{finamigo && finamigo.account? false? "•••• - " + finamigo.account.substring(finamigo.account.length - 5,finamigo.account.length): finamigo.account: "-"}}
                                </span>
                                <span class="description">Cuenta</span>
                              </div>
                              <!--
                              <div>
                                <span class="heading">
                                  {{finamigo && finamigo.banamex? false? "•••• - " + finamigo.banamex.substring(11, finamigo.banamex.length): finamigo.banamex: "-"}}
                                </span>
                                <span class="description">Referencia Banamex</span>
                              </div>
                              !-->
                              <div>
                                <span class="heading">
                                  {{
                                    finamigo && finamigo.bbva
                                      ? false
                                        ? "•••• - " +
                                          finamigo.bbva.substring(
                                            finamigo.bbva.length - 5,
                                            finamigo.bbva.length
                                          )
                                        : finamigo.bbva
                                      : "-"
                                  }}
                                </span>
                                <span class="description">Referencia BBVA</span>
                              </div>
                              <div
                                v-if="
                                  !(isSeller || isManagerSeller) &&
                                  client.contract > 0 &&
                                  client.payment_count > 0
                                "
                              >
                                <!--base-button v-if="!showFinBalance" type="info" v-on:click="getFinamigoBalance" size="lg">Mostrar saldo</base-button-->
                                <span v-if="showFinBalance" class="heading">{{
                                  client.finamigoBalance ? $formatCurrency(client.finamigoBalance) : '$ 0.00'
                                }}</span>
                                <span v-if="showFinBalance" class="description"
                                  >Saldo Finamigo</span
                                >
                              </div>
                              <div class="col-md-0" v-if="isAuditor">
                                <span class="heading">{{
                                  finamigo && finamigo.created
                                    ? formatDate(finamigo.created)
                                    : "---"
                                }}</span>
                                <span class="description">Apertura cuenta</span>
                              </div>
                              </div>
                               <div v-if="!(finamigo && finamigo.account && client.signature_contract)">
                                <div v-if="!mesaggeFA">
                                <base-button v-if="client.signature_contract == '1' && hasCompleteProfile()" id="tokenUrlContractLabel" class="p-2" type="primary" style="margin-right: 12px">
                                  <a style="color: #ffffff" v-on:click="createFinamigoAccount()" target="_blank">
                                    Generar referencias bancarias
                                  </a>
                                </base-button>
                                </div>
                                <div v-if="mesaggeFA">
                                  <div v-if="idRequestFA && (isAdmin || isManagerSeller || isManagerService)">
                                    <span class="font-weight-bold">No. Solicitud</span>
                                    <h4 class="text-primary">{{idRequestFA}}</h4>
                                  </div>
                                  <base-button v-if="client.signature_contract == '1' && hasCompleteProfile()" id="tokenUrlContractLabel" class="p-2" type="outline-info" style="margin-right: 12px">
                                    <a style="font-size:1rem;" target="_blank">
                                      {{messageFAnote}}
                                    </a>
                                  </base-button>
                                </div>
                                 <div v-if="client.signature_contract != '1'">
                                <base-button v-if="hasCompleteProfile()"
                                  slot="title" class type="primary" style="white-space: normal"
                                  v-on:click="requestSignatureContract()">Solicitar firma de contrato
                                </base-button>
                                <base-button v-else slot="title" class type="warning" style="white-space: normal" @click="showIncompleteData()">
                                  ¡Perfil incompleto!
                                </base-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--
                  <div>
                    <hr class="my-4" />
                    <div>
                      <h6 class="heading-small text-muted mb-4">
                        Documentos finamigo
                      </h6>
                      <div class="px-lg-4">
                        <div class="row"> 
                          <div class="col">
                            <div class="card-profile-stats d-flex justify-content-center">
                              <i :class="iconoFinAmigo('far fa-file-image', files.upload_ID)" v-on:click="uploadFinamigoFile('ID')" style="font-size: 40px"/>
                            </div>
                            <span class="description">ID</span>
                          </div>
                          <div class="col">
                            <div class="card-profile-stats d-flex justify-content-center">
                              <i :class="iconoFinAmigo('far fa-file-image', files.upload_ID_B)" v-on:click="uploadFinamigoFile('ID_B')" style="font-size: 40px"/>
                            </div>
                            <span class="description">ID_B</span>
                          </div>
                          <div class="col">
                            <div class="card-profile-stats d-flex justify-content-center">
                              <i :class="iconoFinAmigo('far fa-file-image', files.upload_ADDRESS)" v-on:click="uploadFinamigoFile('ADDRESS')" style="font-size: 40px"/>
                            </div>
                            <span class="description">ADDRESS</span>
                          </div>
                          <div class="col">
                            <div class="card-profile-stats d-flex justify-content-center">
                              <i :class="iconoFinAmigo('far fa-file-pdf', files.upload_CURP)" v-on:click="uploadFinamigoFile('CURP')" style="font-size: 40px"/>
                            </div>
                            <span class="description">CURP</span>
                          </div>
                          <div class="col">
                            <div class="card-profile-stats d-flex justify-content-center">
                              <i :class="iconoFinAmigo('far fa-file-pdf', files.upload_FIN_MANIFEST)" v-on:click="uploadFinamigoFile('FIN_MANIFEST')" style="font-size: 40px"/>
                            </div>
                            <span class="description">MANIFESTO</span>
                          </div>
                          <div class="col">
                            <div class="card-profile-stats d-flex justify-content-center">
                              <i :class="iconoFinAmigo('far fa-file-pdf', files.upload_FIN_CONTRACT)" v-on:click="uploadFinamigoFile('FIN_CONTRACT')" style="font-size: 40px"/>
                            </div>
                            <span class="description">CONTRATO</span>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                  !-->
                </div>
              </div>
            </div>
          </div>

          <!--div v-if="isAuditor" class="card card-profile shadow" style="margin-top: 20px">
            <div class="card-body pt-0 pt-md-4">
              <div class="row justify-content-center">
                <div class="col text-center" v-if="client.id > 0">
                  <h6 class="heading-small text-muted mb-4">Documentos</h6>
                  <div class="col align-self-center">
                    <div class="row justify-content-center">
                      <div
                        v-if="filesURL.id"
                        class="col-lg-6 card-profile-stats d-flex justify-content-center img-center"
                      >
                        <a :href="filesURL.id">
                          <img
                            :src="filesURL.id"
                            style="width: 100%; height: auto"
                            alt="ID"
                            title="click para descargar ID"
                          />
                        </a>
                      </div>
                      <div
                        v-if="filesURL.id_b"
                        class="col-lg-6 card-profile-stats d-flex justify-content-center img-center"
                      >
                        <a :href="filesURL.id_b">
                          <img
                            :src="filesURL.id_b"
                            style="width: 98%; height: auto"
                            alt="ID_B"
                            title="click para descargar ID_B"
                          />
                        </a>
                      </div>

                      <div
                        v-if="filesURL.curp"
                        class="col-lg-6 card-profile-stats d-flex justify-content-center img-center"
                      >
                        <a :href="filesURL.curp">
                          <i
                            v-if="curpPDF"
                            class="fas fa-file-pdf text-primary"
                            style="font-size: 80px"
                            alt="CURP"
                            title="CURP"
                          />
                          <br />
                          <span>Descargar CURP</span>
                          <img
                            v-if="!curpPDF"
                            :src="filesURL.curp"
                            style="width: 98%; height: auto"
                            alt="CURP"
                            title="click para descargar CURP"
                          />
                        </a>
                      </div>
                      <div
                        v-if="filesURL.address"
                        class="col-lg-6 card-profile-stats d-flex justify-content-center img-center"
                      >
                        <a :href="filesURL.address">
                          <img
                            :src="filesURL.address"
                            style="width: 98%; height: auto"
                            alt="ADDRESS"
                            title="click para descargar ADDRESS"
                          />
                        </a>
                      </div>
                      <div
                        v-if="filesURL.fin_manifest"
                        class="col-lg-6 card-profile-stats justify-content-center img-center"
                      >
                        <a :href="filesURL.fin_manifest">
                          <i
                            class="fas fa-file-pdf text-primary"
                            style="font-size: 80px"
                            alt="MANIFESTO"
                            title="MANIFESTO"
                          />
                          <br />
                          <span>Descargar MANIFESTO</span>
                        </a>
                      </div>
                      <div
                        v-if="filesURL.fin_contract"
                        class="col-lg-6 card-profile-stats justify-content-center img-center"
                      >
                        <a :href="filesURL.fin_contract">
                          <i
                            class="fas fa-file-pdf text-primary"
                            style="font-size: 80px"
                            alt="CONTRATO"
                            title="CONTRATO"
                          />
                          <br />
                          <span>Descargar CONTRATO</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            !-->
          <div class="card shadow" v-if="!existReport && !client.step_active" style="margin-top: 20px">
            <div style="margin-top: 20px" v-if="(isAdmin || isManagerSeller) && creditReportBad && hasCompleteProfileForBuro()">
              <div class="card-header border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="heading-small text-muted mb-4">Buro de crédito generado el</h6>
                    <span class="font-weight-bold ml-3 text-success">No Generado</span>
                  </div>
                  <base-button type="success" @click="newModification(creditReport,typeModification)">Solicitar actualización</base-button>
                </div>
              </div>
            </div>
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="heading-small text-muted mb-4">
                    Estado del reporte de crédito:
                  </h6>
                  <span class="font-weight-bold ml-1 text-warning"
                    >No generado</span
                  >
                </div>
                <base-button
                  v-if="hasCompleteProfileForBuro()"
                  type="info"
                  @click="requestSignatureReport"
                  >Solicitar Reporte de Crédito</base-button
                >
                <base-button v-else type="danger"
                  >Datos incompletos</base-button
                >
              </div>
            </div>
          </div>
          <div v-if="report && (lengthsCreditReports.otherAccounts > 0 || lengthsCreditReports.dbmAccounts >0)">
            <div
            class="card shadow"
            style="margin-top: 20px"
            v-if="isAdmin"
          >
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="heading-small text-muted mb-4">
                    Buro de crédito generado el
                  </h6>
                  <span class="font-weight-bold ml-3 text-success">{{dateCreatedBC > 0 ? 'No Generado' : formatDate(dateCreatedBC)}}</span>
                </div>
                <base-button
                  type="success"
                  @click="newModification(creditReport,typeModification)"
                  >Solicitar actualización</base-button
                >
              </div>
            </div>
          </div>
            <credit-report-table
              v-if="lengthsCreditReports.dbmAccounts > 0"
              title="Cuentas que se pueden agregar al programa"
              :amount="Number(client['total_savings'])"
              :data="
                report && report['dbmAccounts'] ? report['dbmAccounts'] : []
              "
              :pagination="paymentPagination"
              :showCalc="true"
              v-on:onNewItem="newPaymentModal = true"
              v-on:pageSelected="getUserPayments"
              v-on:onPaymentSelected="onPaymentSelected"
              v-on:accountClick="accountClick"
              v-on:showCalcClick="showCalcClick"
              style="margin-top: 20px"
            />

            <credit-report-table
              v-if="lengthsCreditReports.otherAccounts > 0"
              title="Otras cuentas"
              :amount="Number(client['total_savings'])"
              :data="
                report && report['otherAccounts'] ? report['otherAccounts'] : []
              "
              :pagination="paymentPagination"
              v-on:onNewItem="newPaymentModal = true"
              v-on:pageSelected="getUserPayments"
              v-on:onPaymentSelected="onPaymentSelected"
              v-on:accountClick="accountClick"
              style="margin-top: 20px"
            />
          </div>

          <div v-if="!report && (lengthsCreditReports.otherAccounts > 0 && lengthsCreditReports.dbmAccounts >0)" class="card shadow" style="margin-top: 20px">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="heading-small text-muted mb-4">
                    Estado del reporte de crédito:
                  </h6>
                  <span class="font-weight-bold ml-1 text-warning">{{
                    getMessageReport(messageReport)
                  }}</span>
                </div>
                <base-button
                  v-if="!report"
                  type="success"
                  @click="updateCreditReport"
                  >Solicitar actualización</base-button
                >
              </div>
            </div>
          </div>
          <div v-if="notAccounts" class="card shadow" style="margin-top: 20px">
            <div
            style="margin-top: 20px"
            v-if="isAdmin"
          >
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="heading-small text-muted mb-4">
                    Buro de crédito generado el
                  </h6>
                  <span class="font-weight-bold ml-3 text-success">{{dateCreatedBC > 0 ? 'No Generado' : formatDate(dateCreatedBC)}}</span>
                </div>
                <base-button
                  type="success"
                  @click="newModification(creditReport,typeModification)"
                  >Solicitar actualización</base-button>
              </div>
            </div>
          </div>
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="heading-small text-muted mb-4">
                    Estado del reporte de crédito:
                  </h6>
                  <span class="font-weight-bold ml-1 text-warning" style="font-size: 23px; margin-top:0.5rem;">Sin Cuentas </span>
                  <span class="font-weight-bold ml-1 text-warning" style="font-size: 21px; margin-top:0.5rem;">(Verifica los datos del Cliente)</span>
                </div>
              </div>
            </div>
          </div>
          <user-files-table
            title="Documentos"
            :data="userFilesTableData"
            :pagination="userFilesPagination"
            :client="client"
            :showCreateContractButton="canCreateDBMContract()"
            :showisService="true"
            :create_contract_button_text="
              client.dbm_contract === 0
                ? 'Generar contrato DBM'
                : 'Generar contrato nuevamente'
            "
            :statusAudit="statusAudit"
            v-on:onNewItem="newUserFileModal = true"
            v-on:onCreateDBMenosContract="createDBMenosContract"
            v-on:pageSelected="getUserFiles"
            @newModification="newModification"
            style="margin-top: 20px"
          />
          <user-files-table-finamigo
            v-if="userFilesFATableData.length > 0"
            title="Documentos Finamigo"
            :data="userFilesFATableData"
            :pagination="userFilesFAPagination"
            :client="client"
            :showEye="showEye"
            :statusAudit="statusAudit"
            v-on:showFiles="getUserFilesFinamigo(1)"
            v-on:pageSelected="getUserFilesFinamigo"
            style="margin-top: 20px"
          />
         <account-summary-table style="margin-top: 20px"
            v-if="isAdmin || isManagerService"
            title="Estado de cuenta FA"
            :data="FAStatementAccountData"
            @getFAStatementAccount="getFAStatementAccount"
          />
          <movements-table
            title="Movimientos"
            :amount="Number(sumMovements)"
            :showisService="true"
            :data="movementsTableData"
            :pagination="movementsPagination"
            v-on:pageSelected="getMovementsTableData"
            v-on:setMovTypeFilter="setMovTypeFilter"
            v-on:onSendAccountStatement="SendAccountStatement"
            v-on:setValidatedPaymentsFilter="setValidatedPaymentsFilter"
            style="margin-top: 20px"
          />
          <payments-table
            title="Depósitos"
            :amount="Number(client.total_savings)"
            :data="paymentTableData"
            :pagination="paymentPagination"
            v-on:onNewItem="newPaymentModal = true"
            :showisService="true"
            v-on:pageSelected="getUserPayments"
            v-on:onPaymentSelected="onPaymentSelected"
            @newModification="newModification"
            style="margin-top: 20px"
          />
          <debts-table
            v-if="isService || isManagerService || isAdmin || isAuditor"
            title="Deudas"
            :data="debtTableData"
            :pagination="debtPagination"
            @onNewItem="newAccountModal"
            :showisService="true"
            :inactive = "this.client.inactive"
            @onSave="updateAccount"
            @pageSelected="getUserDebts"
            @debtSelected="debtSelected"
            @payDebt="payDebt"
            style="margin-top: 20px"
          />
          <money-back-table
            v-if="isAuditor || isManagerService || isAdmin"
            title="Devolución de Dinero"
            :data="moneyBackTableData"
            v-on:showData="getUserMoneyBack(1)"
            :showEye="showEye"
            :pagination="moneyBackPagination"
            v-on:onNewItem="newMoneyBackModal = true"
            style="margin-top: 20px"
          />
          <addendum-table
            v-if="isManagerService || isAdmin || isService"
            title="Addendum"
            :data="addendumTableData"
            :pagination="addendumPagination"
            :showisService="true"
            v-on:addendumSelected="addendumSelected"
            v-on:onNewItem="addNewAddendum()"
            v-on:pageSelected="getAddendum"
            style="margin-top: 20px"
          />
          <!--
          <history-table
            v-if="!isAuditor"
            title="Historial"
            :data="historyTableData"
            :pagination="historyPagination"
            v-on:pageSelected="getHistoryEvent"
            @downloadData="getHistoryEvent"
            :showEye="showEye"
            style="margin-top: 20px"
          />
          !-->
          <user-status-tracing-table
            title="Servicio al cliente"
            :data="statusTracingTableDataCS"
            :showisService="true"
            :existAddress="existAddress"
            :pagination="statusTracingPaginationCS"
            v-on:newUserStatusTrace="typeTracingTable"
            v-on:pageSelected="getTracingTableDataCS"
            style="margin-top: 20px"
          />
          <!--negotiation-table
              title="Actividades de Negociación"
              :data="activityNegotiationTableData"
              :pagination="activityNegotiationPagination"
              v-on:newActivity="newActivity"
              v-on:pageSelected="getActivityNegotiationTableData"
              style="margin-top: 20px"

          <user-status-tracing-table
            title="Perfilamiento"
            :data="statusTracingTableDataB"
            :pagination="statusTracingPaginationB"
            v-on:pageSelected="getTracingTableDataB"
            @downloadData="getTracingTableDataB"
            :showEye="showEye"
            style="margin-top: 20px"
          />

           <users-auditor-table
            title="Auditoria"
            :data="auditTableData"
            :pagination="auditPagination"
            v-on:showAudit="getAuditUser(1)"
            v-on:pageSelected="getAuditUser"
            :showEye="showEye"
            style="margin-top: 20px"
          />

          <user-status-tracing-table
            title="Acciones cliente"
            :data="userActionsTableData"
            :pagination="userActionsPagination"
            v-on:newUserStatusTrace="typeTracingTable"
            v-on:pageSelected="getUserActionsTableData"
            @downloadData="getUserActionsTableData"
            :showEye="showEye"
            style="margin-top: 20px"
          />
          !-->
          <reactivations-table
            title="Reactivaciones"
            :data="reactivationsTableData"
            style="margin-top: 20px"/>

          <user-status-tracing-table
            v-if="isAdmin || isManagerService || isService"
            title="Inactivar programa"
            :showisService="true"
            :data="userInactiveTableData"
            :pagination="userInactivePagination"
            v-on:newUserStatusTrace="typeTracingTable"
            v-on:pageSelected="getUserInactiveTableData"
            style="margin-top: 20px"
          />
        </div>
      </div>
    </div>

    <!-- Modals -->
    <modal :show.sync="requestBuroModal">
      <template slot="header">
        <h1 class="modal-title" id="exampleModalLabel">
          Solicitar Reporte de Crédito
        </h1>
      </template>
      <div class="text-center">
        <div class="row text-center">
          <div class="col-8 text-center">
            <base-input
              alternative
              class="text-center"
              placeholder="Ingresa nip"
              type="number"
              input-classes="form-control-alternative"
              style="width: 100%; margin-top: 4px"
              v-model="requestBuroCredito.pin"
            />
          </div>
          <div class="col-4">
            <base-button
              style="width: 100%; margin-top: 4px"
              slot="title"
              type="success"
              class="btn"
              v-on:click="requestValidatedSignatureReport()"
              >VALIDAR</base-button
            >
          </div>
        </div>
      </div>
    </modal>

    <modal :show.sync="futureAccountModal">
      <template slot="header">
        <h1 class="modal-title" id="exampleModalLabel2">
          Cuenta obtenida en el reporte de crédito
        </h1>
      </template>
      <div class="row">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center mt-md-5">
            <div>
              <span class="heading">{{
                formatDate(futureAccount.FechaActualizacion)
              }}</span>
              <span class="description">Actualizada al</span>
            </div>
            <div>
              <span class="heading">{{
                formatDate(futureAccount.FechaUltimoPago)
              }}</span>
              <span class="description">Fecha último pago</span>
            </div>
            <div>
              <span class="heading"
                >$
                {{
                  formatDecPost(Number(futureAccount.LimiteCredito), 2)
                }}</span
              >
              <span class="description">Límite de crédito</span>
            </div>
            <div>
              <span class="heading"
                >$
                {{ formatDecPost(Number(futureAccount.MontoPagar), 2) }}</span
              >
              <span class="description">Monto a pagar</span>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <!--modal :show.sync="paidAccountModal">
            <template slot="header">
                <h1 class="modal-title" id="paidAccountModal">Liquidar cuenta</h1>
            </template>
            <div class="row">
                <div class="col">
                    <div class="card-profile-stats d-flex justify-content-center">
                        <dropbox text="" type="__" buttonText="Liquidar" :userid="client.id" :files="userFiles" v-on:uploadFile="setPaidAccount"/>
                    </div>
                </div>
            </div>
    </modal-->

    <modal :show.sync="newUserFileModal">
      <template slot="header">
        <h1 class="modal-title" id="newUserFileModal">Nuevo documento</h1>
      </template>
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <base-dropdown>
              <base-button slot="title" class="dropdown-toggle" type="primary" style="margin-top: 15px; white-space: normal">
                {{fileType ? getFileType(fileType) : "Selecciona el tipo de documento" }}
              </base-button>
              <a class="dropdown-item" v-for="type in fileTypes"  :key="type" v-on:click="fileType = type">
                {{ getFileType(type) }}
              </a>
            </base-dropdown>
          </div>
          <div class="card-profile-stats d-flex justify-content-center">
            <dropbox-resize 
              text
              :type="fileType"
              :userid="client.id"
              :files="userFiles"
              v-on:uploadFile="uploadFile"/>
          </div>
        </div>
      </div>
    </modal>

    <modal
      :show.sync="calcModal"
      v-on:close="calcModal = false"
      v-bind:large="true"
    >
      <template slot="header">
        <h2
          class="mb-0"
          id="calculatorModal"
          style="margin-top: 20px; margin-left: 10px"
        >
          Calculadora de descuentos
        </h2>
      </template>
      <div
        v-if="calculos === dbmAccountsCalculations"
        class="row justify-content-center"
        style="margin-bottom: 20px"
      >
        <div class="col-lg-10">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <card class="border-0" shadow body-classes="py-3">
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="text-primary text-center">{{ period }} meses</h3>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col">
                    <base-slider
                      :range="{ min: 6, max: 48 }"
                      :options="{ step: 1 }"
                      v-model.number="period"
                      v-on:input="showCalcClick()"
                    ></base-slider>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Total adeudado
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(calculos.totalDebt)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-red text-white rounded-circle shadow"
                  >
                    <i class="fas fa-money-bill"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Ahorros
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(calculos.savings)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-success text-white rounded-circle shadow"
                  >
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Duración del programa
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >{{ calculos.period }} meses</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-info text-white rounded-circle shadow"
                  >
                    <i class="fas fa-clock"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Depósito mensual
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(
                        calculos.monthlyFee
                          ? calculos.monthlyFee
                          : calculos.montlyFee
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-blue text-white rounded-circle shadow"
                  >
                    <i class="fas fa-coins"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Descuento estimado
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >{{
                      formatDecPost(calculos.discount)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}%</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-warning text-white rounded-circle shadow"
                  >
                    <i class="fa fa-percent"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Deudas
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    calculos && calculos.debts ? calculos.debts.length : "0"
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-indigo text-white rounded-circle shadow"
                  >
                    <i class="fas fa-check"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10 col-md-10">
          <div class="card shadow">
            <div class="card-header border-0">
              <h3 class="mb-0">Deudas a liquidar</h3>
            </div>
            <div class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="text-center" scope="col">Deuda</th>
                    <th class="text-center" scope="col">Acreedor</th>
                    <th class="text-center" scope="col">Deuda sin dbm</th>
                    <th class="text-center" scope="col">
                      Valor a pagar con dbm
                    </th>
                    <th class="text-center" scope="col">Mes a pagar</th>
                    <th class="text-center" scope="col">Avance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(debt, index) in calculos.debts" :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-center">{{ debt.bank }}</td>
                    <td class="text-center">
                      ${{
                        formatDecPost(debt.amount)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </td>
                    <td class="text-center">
                      ${{
                        debt.willPay
                          ? formatDecPost(debt.willPay)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : formatDecPost(debt.will_pay)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        debt.willPayOnMonth
                          ? debt.willPayOnMonth
                          : debt.will_pay_on
                      }}
                    </td>
                    <td class="text-center">
                      {{ formatDecPost(debt.o_progress) }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <template slot="footer">
        <base-button type="primary" v-on:click="calcModal = false"
          >Cerrar</base-button
        >
      </template>
    </modal>
  
    <modal :show.sync="newPaymentModal">
      <template slot="header">
        <h1 class="modal-title" id="newPaymentModal">Nuevo depósito</h1>
      </template>
      <div class="row">
        <div class="col-md-6">
          <label class="form-control-label">Fecha de depósito</label>
          <flat-pickr
            v-model="newPayment.date"
            :config="{}"
            class="form-control form-control-alternative"
            data-vv-as="Fecha"
            name="date"
            style="background-color: #ffffff"
          />
        </div>
        <div class="col-md-6">
          <base-input
            alternative
            label="Cantidad depositada"
            input-classes="form-control-alternative"
            v-model="newPayment.amount"
          />
        </div>
        <div hidden class="col-md-6">
          <label class="form-control-label">Validar</label>
          <base-button
            slot="title"
            style="width: 100%; margin-top: 4px"
            :type="newPayment.validated ? 'primary' : 'danger'"
            class="btn"
            size="sm"
            v-on:click="changeNewPaymentValidation()"
            >{{
              newPayment.validated ? "Pago validado" : "Pago no validado"
            }}</base-button
          >
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="col">
            <div class="card-profile-stats d-flex justify-content-center">
              <dropbox text type="PAYMENT" v-on:uploadFile="uploadPayment" />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal :show.sync="newMoneyBackModal">
      <template slot="header">
        <h1 class="modal-title">Nueva Devolución de Dinero ID: {{client.id}}</h1>
      </template>
      <div class="row">
        <div class="col">
          <base-dropdown>
            <base-button slot="title" type="success" class="dropdown-toggle">
              {{newMoneyBack.type ? newMoneyBack.type.type : "Seleccionar"}}
            </base-button>
            <a class="dropdown-item" v-for="(type, index) in moneyBackCatalog" :key="index"
              @click="newMoneyBack.type=type, $forceUpdate()">
              {{ type.type }}
            </a>
          </base-dropdown>
        </div>
        <div class="col">
          <base-input alternative type="number" label="Cantidad a devolver" input-classes="form-control-alternative"
            v-model="newMoneyBack.amount"/>
        </div>
      </div>
      <template slot="footer">
        <base-button type="danger" v-on:click="newMoneyBackModal = false">Cancelar</base-button>
        <base-button type="success" v-on:click="setMoneyBack()">Guardar</base-button>
      </template>
    </modal>

    <modal :show.sync="newCommissionModal">
      <template slot="header">
        <h1 class="modal-title" id="newCommissionModal">Nueva comisión</h1>
      </template>
      <div class="row">
        <div class="col-md-6">
          <label class="form-control-label">Fecha de cobro de comisión</label>
          <flat-pickr
            v-model="newCommission.date"
            :config="{}"
            class="form-control form-control-alternative"
            data-vv-as="Fecha"
            name="date"
            style="background-color: #ffffff"
          />
        </div>
        <div class="col-md-6">
          <base-input
            alternative
            label="Comisión cobrada"
            input-classes="form-control-alternative"
            v-model="newCommission.amount"
          />
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <base-button
            slot="title"
            style
            type="primary"
            class="btn"
            v-on:click="createCommission()"
            >Subir comisión</base-button
          >
        </div>
      </div>
    </modal>

    <modal :show.sync="paymentModal">
      <template slot="header">
        <h1 class="modal-title" id="paymentModal">Depósito</h1>
      </template>
      <div class="row">
        <div class="card-profile-stats d-flex justify-content-around mb-4" style="width: 100%">
          <div class="p-2">
            <span class="heading">{{ selectedPayment.id }}</span>
            <span class="description">Identificador</span>
          </div>
          <div class="p-2">
            <span class="heading">{{ selectedPayment.method_payment==2? 'SPEI' : 'Depósito'}}</span>
            <span class="description">Método de pago</span>
          </div>
          <div class="p-2">
            <label class="form-control-label">Creada el</label>
            <flat-pickr :config="{}" class="form-control form-control-alternative"
              data-vv-as="Fecha" name="date" style="background-color: #ffffff"
              v-model="selectedPayment.date"/>
          </div>
          <div class="p-2">
            <base-input alternative input-classes="form-control-alternative"
              label="Monto depositado"
              v-model="selectedPayment.amount"/>
          </div>
          <div v-if="(isAdmin || isAuditor) && user.view_only === 0" class="p-2">
            <base-button slot="title" style="width: 100%; margin-top: 4px" :type="selectedPayment.validated ? 'primary' : 'danger'" class="btn" v-on:click="updatePayment()">
              {{selectedPayment.validated ? "Depósito validado" : "Depósito no validado"}}
            </base-button>
            <span class="description">
              {{selectedPayment.validated_on ? formatDate(selectedPayment.validated_on) : "No validado"}}
            </span>
          </div>
        </div>
      </div>
      <div v-if="!pdfPayment" class="align-items-center" style="margin:1rem;">
        <img :src="selectedPayment.fileurl? selectedPayment.fileurl : getPaymentFile(selectedPayment.id)" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
        <br>
        <a v-if="selectedPayment.fileurl" :href="selectedPayment.fileurl" target="_blank">
          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
          </a>
          </div>
      <div v-else class="align-items-center text-center" style="margin:2rem;">
        <base-p-d-f-visor :fileurl="selectedPayment.fileurl? selectedPayment.fileurl : getPaymentFile(selectedPayment.id)" :key="forceUpdate"/>
      </div>
      <template slot="footer">
        <base-button class="btn-sm" type="danger" v-on:click="paymentModal = false">
          Cancelar
        </base-button>
        <base-button class="btn-sm" type="primary" v-on:click="updatePayment(true)">
          Guardar cambios
        </base-button>
      </template>
    </modal>

    <modal :show.sync="tracingModal" v-bind:large="true">
      <template slot="header">
        <h2
          class="mb-0"
          id="TracingLead"
          style="margin-top: 20px; margin-left: 10px"
        >
          {{ client.name }} {{ client.lastname_1 }} {{ client.lastname_2 }}
        </h2>
      </template>
      <div class="row">
        <div class="col-lg-12">
          <div class="col-lg-6">
            <base-dropdown
              v-if="
                tracingStatus === 'Acciones cliente' ||
                tracingStatus === 'Inactivar programa'
              "
            >
              <base-button
                slot="title"
                type="success"
                class="dropdown-toggle"
                >{{
                  newTrace.status ? newTrace.status : "Seleccionar"
                }}</base-button
              >
              <a
                class="dropdown-item"
                v-for="(value, index) in tracingStatus === 'Inactivar programa'
                  ? userInactiveCatalog
                  : userActionsCatalog"
                :key="index"
                v-on:click="setTraceStatus(value)"
                >{{ value.value }}</a
              >
            </base-dropdown>

            <base-dropdown v-else>
              <base-button slot="title" type="success" class="dropdown-toggle">
                {{newTrace.status ? newTrace.status : "Seleccionar"}}
              </base-button>
              <a class="dropdown-item" v-for="(status, index) in tracingStatus === 'Seguimiento de ventas' ? leadStatus : userTracingStatusCatalog" :key="index"
                v-on:click="setTraceStatus(status)">
                {{ status.status }}
              </a>
            </base-dropdown>
          </div>
          <div
            class="row"
            style="margin-top: 2rem"
            v-if="tracingStatus === 'Inactivar programa'"
          >
            <div class="col-lg-6">
              <flat-pickr
                v-model="newTrace.created"
                :config="{}"
                class="form-control form-control-alternative"
                placeholder="Selecciona Fecha de Baja/Graduado"
                data-vv-as="Fecha"
                name="date"
                style="background-color: #ffffff"
              />
            </div>
            <div class="col-lg-6">
              <base-input
                type="number"
                :disable="isService || isSeller"
                alternative
                placeholder="Remanente"
                input-classes="form-control-alternative "
                v-model="newTrace.divisa"
              />
            </div>
          </div>
          <div class="row" style="margin-top: 2rem">
            <div class="col-lg-6" v-if="newTrace.statusId === 16">
              <label class="form-control-label">Fecha promesa</label>
              <flat-pickr
                v-model="newTrace.date_promise"
                :config="{}"
                class="form-control form-control-alternative"
                placeholder="Selecciona fecha de promesa"
                data-vv-as="Fecha"
                name="date"
                style="background-color: #ffffff"
              />
            </div>
          </div>
          <div class="row" style="margin-top: 2rem">
            <div class="col-lg-12" v-if="newTrace.statusId == 25">
              <label class="form-control-label">¿Recibe nomina o ingresos en algún banco que está dentro del programa?</label>
              <b-form-checkbox v-model="sameBankNom" value="1">SI</b-form-checkbox>
              <b-form-checkbox v-model="sameBankNom" value="0">NO</b-form-checkbox>
              <div v-if="sameBankNom  == 1">
                <accounts-service-table
            title="Cuentas"
            :data="accountTableData"
            :pagination="accountPagination"
            @pageSelected="getUserAccounts"
            v-on:accountSelected="accountSelectedService"
            style="margin-top: 20px"
          />
              </div>
            </div>
          </div>
          <base-input
            alternative
            placeholder="Comentarios"
            input-classes="form-control-alternative"
            style="margin-top: 20px"
            v-model="newTrace.comments"
          />
        </div>
      </div>
      <div class="col text-right">
        <base-button slot="title" type="primary" v-on:click="uploadTrace()"
          >Crear</base-button
        >
      </div>
    </modal>

    <modal :show.sync="newNegotiationModal" v-bind:large="true">
      <template slot="header">
        <h2 class="mb-0" style="margin-top: 20px; margin-left: 10px">
          Nueva actividad
        </h2>
      </template>
      <div class="row">
        <div class="col">
          <base-dropdown>
            <base-button slot="title" type="info" class="dropdown-toggle">
              {{
                newActivityNegotiation.describe
                  ? newActivityNegotiation.describe
                  : "Seleccionar"
              }}
            </base-button>
            <a
              class="dropdown-item"
              v-for="(activity, index) in debtActivityCatalog"
              :key="index"
              v-on:click="setNewActivityNegotiation(activity)"
              >{{ activity.describe }}</a
            >
          </base-dropdown>
        </div>
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            label="Saldo actual"
            v-model="newActivityNegotiation.amount"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            label="% Descuento"
            v-model="newActivityNegotiation.discount"
          />
        </div>
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            label="Pago banco"
            v-model="newActivityNegotiation.amount_pay_bank"
          />
        </div>
        <div class="col">
          <label class="form-control-label">Fecha límite</label>
          <flat-pickr
            class="form-control form-control-alternative"
            :config="{}"
            data-vv-as="Fecha"
            name="date"
            style="background-color: #ffffff"
            v-model="newActivityNegotiation.limit_pay_date"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <base-dropdown>
            <base-button slot="title" class="dropdown-toggle">
              {{
                newActivityNegotiation.firm
                  ? newActivityNegotiation.firm
                  : "Seleccionar"
              }}
            </base-button>
            <a
              class="dropdown-item"
              v-for="(firm, index) in debtSCFirms"
              :key="index"
              v-on:click="setNewActivityNegotiationFirm(firm)"
              >{{ firm.name }}</a
            >
          </base-dropdown>
        </div>
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            disabled
            label="Teléfono"
            v-model="newActivityNegotiation.firm_phone"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            disabled
            label="Liquidación esperada"
            v-model="newActivityNegotiation.expected_settlement"
          />
        </div>
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            disabled
            label="Saldo finamigo"
            v-model="newActivityNegotiation.finamigo_balance"
          />
        </div>
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            disabled
            label="Mora"
            v-model="newActivityNegotiation.morosidad"
          />
        </div>
        <div class="col">
          <label class="form-control-label">Fecha último pago al banco</label>
          <flat-pickr
            class="form-control form-control-alternative"
            :config="{}"
            data-vv-as="Fecha"
            name="date"
            style="background-color: #ffffff"
            v-model="newActivityNegotiation.last_bank_payment_date"
          />
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" @click="sendMessage()">Guardar</base-button>
      </template>
    </modal>

    <modal
      :show.sync="sendMessageModal"
      v-on:close="sendMessageModal"
      v-bind:large="true"
    >
      <template slot="header">
        <h2 class="mb-0" style="margin-top: 20px; margin-left: 10px">
          Para: {{ client.name }}
        </h2>
      </template>
      <base-input
        v-if="this.typeTheMessage === 'PN'"
        alternative
        label="Título"
        placeholder="Escribe un titulo..."
        v-model="pushNotTitle"
      />
      <base-input
        alternative
        label="Mensaje SMS"
        placeholder="Escribe un mensaje..."
        v-model="theMessage"
      />
      <template slot="footer">
        <base-button type="primary" @click="sendMessage()">Enviar</base-button>
      </template>
    </modal>

    <modal :show.sync="accountModal" @close="closeAccountModal()" :large="true">
      <template slot="header">
        <div class="row align-items-center">
          <div class="col">
            <h2
              class="mb-0"
              id="acountModal"
              style="margin-top: 20px; margin-left: 10px"
            >
              {{ newAccount ? "Añadir cuenta" : "Editar cuenta" }}
            </h2>
            <h4 class="mb-0" style="margin-left: 10px">
              {{ "Saldo finamigo: " + $formatCurrency(client.finamigoBalance) }}
            </h4>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col" v-bind:style="{ cursor: pointer }">
          <base-dropdown>
            <base-button
              :disabled="accountInModal.paid_file"
              slot="title"
              type="outline"
              class="dropdown-toggle"
              size="lg"
              style="margin-top: 10px; white-space: normal"
              >{{
                accountInModal.bank
                  ? getBank(accountInModal.bank).name
                  : "Selecciona el banco"
              }}</base-button
            >
            <a
              class="dropdown-item"
              v-for="(bank, index) in banks"
              :key="index"
              v-on:click="setBankInAccount(bank)"
              >{{ bank.name }}</a
            >
          </base-dropdown>
        </div>
        <div class="col">
          <base-input
            alternative
            label="Número de cuenta"
            input-classes="form-control-alternative"
            :disabled="accountInModal.paid_file"
            v-model="accountInModal.account"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input
            alternative
            label="Total adeudado"
            input-classes="form-control-alternative"
            :disabled="accountInModal.paid_file"
            v-model="accountInModal.amount"
          />
        </div>
        <div class="col" v-if="newAccount">
          <base-input
            alternative
            label="Descuento actual"
            input-classes="form-control-alternative"
            v-model="discount"
            :disabled="accountInModal.paid_file"
            v-bind:readonly="true"
          />
        </div>
        <div class="col" v-else>
          <base-input
            alternative
            label="Descuento actual"
            input-classes="form-control-alternative"
            :disabled="accountInModal.paid_file"
            v-model="accountInModal.discount"
          />
        </div>
        <div class="col">
           <base-dropdown>
           <base-button
              slot="title"
              type="success"
              class="dropdown-toggle"
              :disabled="accountInModal.paid_file"
              size="lg"
              style="margin-top: 10px; white-space: normal"
              >{{
                accountInModal.product_type
                  ? accountInModal.product_type.type
                  : "Selecciona Tipo de Producto"
              }}</base-button
            >
              <a class="dropdown-item" v-for="(type, index) in productType" :key="index" v-on:click="setTraceProductType(type)">
                  {{type.type}}
               </a>
            </base-dropdown>
        </div>
        <div class="col">
          <label class="form-control-label">Último pago bancario</label>
          <flat-pickr
            v-model="accountInModal.last_bank_payment_date"
            class="form-control form-control-alternative"
            :disabled="accountInModal.paid_file"
            data-vv-as="Fecha"
            name="date"
            style="background-color: #ffffff"
          />
        </div>
      </div>
      <div class="row" v-if="!isSeller">
        <div class="col">
          <label class="form-control-label">Fecha estimada</label>
          <flat-pickr
            v-model="accountInModal.will_pay_on"
            :config="{}"
            :disabled="accountInModal.paid_file"
            class="form-control form-control-alternative"
            data-vv-as="Fecha"
            name="date"
            style="background-color: #ffffff"
          />
        </div>
        <div class="col">
          <base-input
            alternative
            disabled
            label="Liquidación esperada sin comisión"
            input-classes="form-control-alternative"
            v-model="accountInModal.will_pay_wo_fee"
          />
        </div>
        <div class="col">
          <input
            :disabled="accountInModal.paid_file"
            type="checkbox"
            class="form-check-input"
            v-model="accountInModal.payroll_same_bank"
          />
          <label class="form-control-label">Nómina en mismo banco</label>
        </div>
      </div>

      <div
        class="row d-flex align-items-center"
        v-if="!(isSeller || newAccount)"
      >
        <div v-if="!accountInModal.paid_file" class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <dropbox
              text
              type="__"
              buttonText="Liquidar"
              :userid="client.id"
              :files="userFiles"
              v-on:uploadFile="setPaidAccount"
            />
          </div>
        </div>
        <div v-else class="col">
          <div
            class="card-profile-stats d-flex justify-content-center img-center text-center"
          >
            <img v-if="!isPDF" :src="accountInModal.fileurl" style="max-height: 200px" />
             <a v-if="isPDF" :href="accountInModal.fileurl">
               <span style="font-size: 5em;">
                <i class="fas fa-file-pdf text-info"></i>
              </span>
              <br>
              <span style="font-size: 1.5em;">Descargar</span>
            </a>
          </div>
        </div>
        <div class="col">
          <label class="form-control-label">Fecha de liquidación</label>
          <flat-pickr
            :disabled="accountInModal.paid != 0"
            v-model="accountInModal.paid"
            :config="{}"
            class="form-control form-control-alternative"
            data-vv-as="Fecha"
            name="date"
            style="background-color: #ffffff"
          />
        </div>
      </div>

      <template slot="footer">
        <div class="align-self-start mr-auto">
          <!--
          <base-button
            v-if="!newAccount && !prevDel && (isAdmin || isManagerService)"
            type="danger"
            v-on:click="prevDel = true"
            >Eliminar cuenta</base-button
          >
          !-->
          <base-button
            v-if="!newAccount && prevDel"
            type="secondary"
            v-on:click="prevDel = false"
            >Cancelar</base-button
          >
        </div>
        <!--
        <base-button
          v-if="
            Number(client.finamigoBalance) >= Number(accountInModal.discount) ||
            !finamigo && (isAdmin || isManagerService || isService)
          "
          type="success"
          v-on:click="settlementLetter"
          >Solicitar carta de liquidación</base-button
        >!-->
        <base-button
          v-if="!newAccount && prevDel && (isAdmin || isManagerService)"
          type="outline-danger"
          v-on:click="deleteAccount()"
          >Confirmar eliminación</base-button
        >
        <base-button
          v-if="newAccount"
          type="primary"
          v-on:click="updateAccount(accountInModal)"
          >Guardar cuenta</base-button
        >
        <base-button
          v-if="!newAccount && !prevDel && !statusAudit"
          type="primary"
          v-on:click="updateAccount(accountInModal)"
          >Guardar cambios</base-button
        >
      </template>
      <div v-if="isAdmin">
        <account-tracing-table
          title="Historial"
          :data="accountTracingTableData"
          :pagination="accountTracePagination"
          v-on:pageSelected="getUserAccountTracing"
          style="margin-top: 20px"
        />
      </div>
    </modal>

    <modal :show.sync="accountModalService" @close="closeAccountModalService()" :large="true">
      <template slot="header">
        <div class="row align-items-center">
          <div class="col text-center">
            <h2
              class="mb-0"
              id="acountModalService"
              style="margin-top: 20px; margin-left: 10px"
            >
              ¿La cuenta {{accountInModal.account}} del banco {{
                accountInModal.bank
                  ? getBank(accountInModal.bank).name
                  : ""
              }} recibe nómina o ingresos? 
            </h2>
          </div>
        </div>
      </template>
      <div class="text-center">
        <base-button
          type="success"
          v-on:click="setPayrollSB(accountInModal)"
          >Si</base-button
        >
        <base-button
          type="danger"
          v-on:click="closeAccountModalService()"
          >No</base-button
        >
        </div>
    </modal>
    <modal
      :show.sync="calcModal"
      v-on:close="calcModal = false"
      v-bind:large="true"
    >
      <template slot="header">
        <h2
          class="mb-0"
          id="calculatorModal1"
          style="margin-top: 20px; margin-left: 10px"
        >
          Calculadora de descuentos
        </h2>
      </template>
      <div
        v-if="calculos === dbmAccountsCalculations"
        class="row justify-content-center"
        style="margin-bottom: 20px"
      >
        <div class="col-lg-10">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <card class="border-0" shadow body-classes="py-3">
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="text-primary text-center">{{ period }} meses</h3>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col">
                    <base-slider
                      :range="{ min: 6, max: 48 }"
                      :options="{ step: 1 }"
                      v-model.number="period"
                      v-on:input="showCalcClick()"
                    ></base-slider>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Total adeudado
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(calculos.totalDebt)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-red text-white rounded-circle shadow"
                  >
                    <i class="fas fa-money-bill"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Ahorros
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(calculos.savings)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-success text-white rounded-circle shadow"
                  >
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Duración del programa
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >{{ calculos.period }} meses</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-info text-white rounded-circle shadow"
                  >
                    <i class="fas fa-clock"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Depósito mensual
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(
                        calculos.monthlyFee
                          ? calculos.monthlyFee
                          : calculos.montlyFee
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-blue text-white rounded-circle shadow"
                  >
                    <i class="fas fa-coins"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Descuento estimado
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >{{
                      formatDecPost(calculos.discount)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}%</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-warning text-white rounded-circle shadow"
                  >
                    <i class="fa fa-percent"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Deudas
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    calculos && calculos.debts ? calculos.debts.length : "0"
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-indigo text-white rounded-circle shadow"
                  >
                    <i class="fas fa-check"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10 col-md-10">
          <div class="card shadow">
            <div class="card-header border-0">
              <h3 class="mb-0">Deudas a liquidar</h3>
            </div>
            <div class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="text-center" scope="col">Deuda</th>
                    <th class="text-center" scope="col">Acreedor</th>
                    <th class="text-center" scope="col">Deuda sin dbm</th>
                    <th class="text-center" scope="col">
                      Valor a pagar con dbm
                    </th>
                    <th class="text-center" scope="col">Mes a pagar</th>
                    <th class="text-center" scope="col">Avance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(debt, index) in calculos.debts" :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-center">{{ debt.bank }}</td>
                    <td class="text-center">
                      ${{
                        formatDecPost(debt.amount)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </td>
                    <td class="text-center">
                      ${{
                        debt.willPay
                          ? formatDecPost(debt.willPay)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : formatDecPost(debt.will_pay)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        debt.willPayOnMonth
                          ? debt.willPayOnMonth
                          : debt.will_pay_on
                      }}
                    </td>
                    <td class="text-center">
                      {{ formatDecPost(debt.o_progress) }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <template slot="footer">
        <base-button type="primary" v-on:click="calcModal = false"
          >Cerrar</base-button
        >
      </template>
    </modal>

    <modal
      :show.sync="accountDetailsModal"
      v-on:close="accountDetailsModal = false"
      v-bind:large="true"
    >
      <template slot="header">
        <h2
          class="mb-0"
          id="accountDetModal"
          style="margin-top: 20px; margin-left: 10px"
        >
          Detalles de la cuenta
        </h2>
        <!--div class="col text-right"><base-button size="sm" type="primary" v-on:click="moveAccount()">Mover cuenta</base-button></div-->
      </template>
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Fecha de actualización
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    interprateDate(this.accountInModal.FechaActualizacion)
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-blue text-white rounded-circle shadow"
                  >
                    <i class="fas fa-calendar-day"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Teléfono del otorgante
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.NumeroTelefonoOtorgante
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-cyan text-white rounded-circle shadow"
                  >
                    <i class="fas fa-phone"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Clave otorgante
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.ClaveOtorgante
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-yellow text-white rounded-circle shadow"
                  >
                    <i class="fas fa-key"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Nombre otorgante
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.NombreOtorgante
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-gray text-white rounded-circle shadow"
                  >
                    <i class="fas fa-university"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Cuenta actual
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.NumeroCuentaActual
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-teal text-white rounded-circle shadow"
                  >
                    <i class="fas fa-credit-card"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Clave de unidad monetaria
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.ClaveUnidadMonetaria
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-purple text-white rounded-circle shadow"
                  >
                    <i class="fas fa-coins"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Modo reportar
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.ModoReportar
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i class="fas fa-balance-scale"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Número de pagos
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.NumeroPagos
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-green text-white rounded-circle shadow"
                  >
                    <i class="fas fa-file-invoice-dollar"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Identificador Sociedad I. C.
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal
                      .IdentificadorSociedadInformacionCrediticia
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-blue text-white rounded-circle shadow"
                  >
                    <i class="fas fa-key"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Monto a pagar
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(this.accountInModal.MontoPagar)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-red text-white rounded-circle shadow"
                  >
                    <i class="fas fa-money-bill-alt"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Fecha de apertura de cuenta
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    interprateDate(this.accountInModal.FechaAperturaCuenta)
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-cyan text-white rounded-circle shadow"
                  >
                    <i class="fas fa-calendar-day"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Fecha de último pago
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    interprateDate(this.accountInModal.FechaUltimoPago)
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-teal text-white rounded-circle shadow"
                  >
                    <i class="fas fa-calendar-day"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Fecha de última compra
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    interprateDate(this.accountInModal.FechaUltimaCompra)
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-gray text-white rounded-circle shadow"
                  >
                    <i class="fas fa-calendar-day"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Fecha de reporte
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    interprateDate(this.accountInModal.FechaReporte)
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-pink text-white rounded-circle shadow"
                  >
                    <i class="fas fa-calendar-day"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Monto último pago.
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(this.accountInModal.MontoUltimoPago)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-yellow text-white rounded-circle shadow"
                  >
                    <i class="fas fa-money-bill-alt"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Crédito máximo
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(this.accountInModal.CreditoMaximo)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-success text-white rounded-circle shadow"
                  >
                    <i class="fas fa-hand-holding-usd"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Saldo actual
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(this.accountInModal.SaldoActual)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i class="fas fa-wallet"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Límite de crédito
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(this.accountInModal.LimiteCredito)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-red text-white rounded-circle shadow"
                  >
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Saldo vencido
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(this.accountInModal.SaldoVencido)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-purple text-white rounded-circle shadow"
                  >
                    <i class="fas fa-coins"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Número de pagos vencidos
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.NumeroPagosVencidos
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-gray text-white rounded-circle shadow"
                  >
                    <i class="fas fa-calendar-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Forma pago actual
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.FormaPagoActual
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-teal text-white rounded-circle shadow"
                  >
                    <i class="fas fa-file-invoice-dollar"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Histórico pagos
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.HistoricoPagos
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-cyan text-white rounded-circle shadow"
                  >
                    <i class="fas fa-history"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Fecha más reciente de histórico pagos
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    interprateDate(
                      this.accountInModal.FechaMasRecienteHistoricoPagos
                    )
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-success text-white rounded-circle shadow"
                  >
                    <i class="fas fa-calendar-check"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Fecha más antígua de histórico pagos
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    interprateDate(
                      this.accountInModal.FechaMasAntiguaHistoricoPagos
                    )
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-blue text-white rounded-circle shadow"
                  >
                    <i class="fas fa-calendar-check"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Modo reportar
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.ModoReportar
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-pink text-white rounded-circle shadow"
                  >
                    <i class="fas fa-key"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Total de pagos reportados
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.TotalPagosReportados
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-yellow text-white rounded-circle shadow"
                  >
                    <i class="fas fa-plus-square"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div-->

      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Peor atraso
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    this.accountInModal.MopHistoricoMorosidadMasGrave
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i class="fas fa-exclamation"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Fecha del peor atraso
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    interprateDate(
                      this.accountInModal.FechaHistoricaMorosidadMasGrave
                    )
                  }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-teal text-white rounded-circle shadow"
                  >
                    <i class="fas fa-calendar-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">
                    Saldo vencido peor atraso
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      formatDecPost(
                        this.accountInModal.ImporteSaldoMorosidadHistMasGrave
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-red text-white rounded-circle shadow"
                  >
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" v-on:click="accountDetailsModal = false">Cerrar</base-button>
      </template>
    </modal>

    <modal :show.sync="payDebtModal" v-bind:large="true">
      <template slot="header">
        <h2 class="mb-0" style="margin-top: 20px; margin-left: 10px">SPEI_OUT</h2>
      </template>
      <div class="row">
        <div class="col">
          <base-dropdown>
            <base-button slot="title" type="outline" class="dropdown-toggle" style="margin-top: 10px; white-space: normal">
              {{newSpeiOut.Institution? newSpeiOut.Institution.participant_bank : "Selecciona el banco"}}
            </base-button>
            <a class="dropdown-item" :key="index"
              v-for="(bank, index) in finamigoBanks" @click="newSpeiOut.Institution=bank, $forceUpdate()">
              {{ bank.participant_bank }}
            </a>
          </base-dropdown>
        </div>
        <div class="col-auto">
          <base-input
            alternative
            input-classes="form-control-alternative"
            label="Monto"
            v-model="newSpeiOut.Amount"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            label="Beneficiario"
            v-model="newSpeiOut.Beneficiary"
          />
        </div>
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            label="Concepto"
            v-model="newSpeiOut.Concept"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            label="CLABE Beneficiario"
            v-model="newSpeiOut.AccountBeneficiary"
          />
        </div>
        <div class="col">
          <base-input
            alternative
            input-classes="form-control-alternative"
            label="Referencia"
            v-model="newSpeiOut.NumericRef"
          />
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" @click="applySpeiOut()">Aceptar</base-button>
      </template>
    </modal>

    <modal :show.sync="modificationModal" @close="modificationModal" :large="true">
      <template slot="header">
        <h2 class="mb-0" style="margin-top: 20px; margin-left: 10px">
          Modificando {{modificationType === 'Documento' ? `${modificationType}: ${modificationData.filename}`: 
          modificationType === 'Cuenta' ? `${modificationType}: ${modificationData.account}`:
          modificationType === 'Firma Contrato' ? `${modificationType}`:
          modificationType === 'Firma Liquidación' ? `${modificationType}`:
          modificationType === 'Firma Carta Nómina' ? `${modificationType}`:
          modificationType === 'Firma Addendum' ? `${modificationType}`:
          modificationType === 'Reporte de Crédito' ? `${modificationType}`:
           `${modificationType}:${modificationData.last_id}`}}
        </h2>
      </template>
      <base-input alternative
        label="Comentario"
        placeholder="Escribe un comentario..."
        v-model="modificationData.comments"/>
      <template slot="footer">
        <base-button v-if="modificationType != 'Reporte de Crédito'" type="primary" @click="setDeleteHistory()">Aplicar</base-button>
        <base-button v-if="modificationType === 'Reporte de Crédito'" type="primary" @click="setDeleteCreditReport()">Actualizar</base-button>
      </template>
    </modal>
     <modal
        :show.sync="modalINEA"
        @close="modalINEA" :large="true"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-sm"
        class="modal-fluid"
      >
        <card class="border-0">
          <template>
            <div class="text-center text-muted mb-4" v-if="loading">
              <h4 class="display-4" style="margin-top: 1rem; font-size: 2rem">
                Subiendo Identificación Oficial y Analizando datos....
              </h4>
              <br />
              <breeding-rhombus-spinner
              :animation-duration="1000"
              :size="60"
              :color="'#3953D3'"/>
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="!loading && dataUsers"
            >
              <h3 class="display-4 text-primary" >Datos obtenidos:</h3>
              <span style="margin-top: 1rem" v-if="dataUsers.name"
                >Nombre:
                <b>{{ dataUsers.name ? dataUsers.name : "---" }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.lastname_1"
                >Primer Apellido:
                <b>{{
                  dataUsers.lastname_1 ? dataUsers.lastname_1 : "---"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.lastname_2"
                >Segundo Apellido:
                <b>{{
                  dataUsers.lastname_2 ? dataUsers.lastname_2 : "---"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.curp"
                >CURP:
                <b>{{ dataUsers.curp ? dataUsers.curp : "---" }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.rfc"
                >RFC: <b>{{ dataUsers.rfc ? dataUsers.rfc : "---" }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.gender"
                >Género:
                <b>{{
                  dataUsers.gender == "F" ? "Femenino" : "Masculino"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.birthday"
                >Fecha de Nacimiento:
                <b>{{
                  dataUsers.birthday
                    ? this.$moment(dataUsers.birthday).format("DD-MM-YYYY")
                    : "----"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.addr_street"
                >Calle:
                <b>{{
                  dataUsers.addr_street ? dataUsers.addr_street : "----"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.addr_extnum"
                >Número Externo:
                <b>{{
                  dataUsers.addr_extnum ? dataUsers.addr_extnum : "----"
                }}</b></span
              >
              <br v-if="dataUsers.addr_intnum" />
              <span style="margin-top: 1rem" v-if="dataUsers.addr_intnum"
                >Número Interno:
                <b>{{
                  dataUsers.addr_intnum ? dataUsers.addr_intnum : "----"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.addr_postal_code"
                >Código Postal:
                <b>{{
                  dataUsers.addr_postal_code
                    ? dataUsers.addr_postal_code
                    : "----"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="dataUsers.addr_sublocality"
                >Colonia:
                <b>{{
                  dataUsers.addr_sublocality
                    ? dataUsers.addr_sublocality
                    : "----"
                }}</b></span
              >
              <br />
              <span style="margin-top: 1rem" v-if="stateOfBirth"
                >Estado de Nacimiento:
                <b>{{ stateOfBirth ? stateOfBirth : "----" }}</b></span
              >
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalA()"
                >{{ "Continuar" }}</base-button
              >
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="errorOCR && dataError"
            >
              <h4 class="display-4" style="margin-top: 1rem; font-size: 3rem">
                Error al subir la ine
              </h4>
              <span style="margin-top: 1rem; font-size: 2rem; color: red">{{
                dataError
              }}</span>
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalErrorA()"
                >Cerrar</base-button
              >
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="errorOCR && !dataError"
            >
              <h4 class="display-4" style="margin-top: 1rem; font-size: 3rem">
                Error al subir la ine
              </h4>
              <span style="margin-top: 1rem; font-size: 2rem; color: red">Intenta subir nuevamente la INE</span>
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalErrorA()"
                >Cerrar</base-button
              >
            </div>
          </template>
        </card>
      </modal>
       <modal
        :show.sync="modalINEB"
        @close="modalINEB" :large="true"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-sm"
        class="modal-fluid"
      >
        <card class="border-0">
          <template>
            <div class="text-center text-muted mb-4" v-if="loading">
              <h4 class="display-4" style="margin-top: 1rem; font-size: 2rem">
                Subiendo Identificación Oficial y Analizando datos....
              </h4>
              <br />
              <breeding-rhombus-spinner
              :animation-duration="1000"
              :size="60"
              :color="'#3953D3'"/>
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="!loading && dataUsers"
            >
              <h3 class="display-4 text-primary">Datos obtenidos:</h3>
              <span style="margin-top: 1rem" v-if="dataUsers.id_number"
                >Número de Identifiación:
                <b>{{ dataUsers.id_number ? dataUsers.id_number : "---" }}</b></span
              >
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalB()"
                >{{ "Continuar" }}</base-button
              >
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="errorOCR && dataError"
            >
              <h4 class="display-4" style="margin-top: 1rem; font-size: 3rem">
                Error al subir la ine
              </h4>
              <span style="margin-top: 1rem; font-size: 2rem; color: red">{{
                dataError
              }}</span>
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalErrorB()"
                >Cerrar</base-button
              >
            </div>
            <div
              class="text-center text-muted mb-4"
              v-if="errorOCR && !dataError"
            >
              <h4 class="display-4" style="margin-top: 1rem; font-size: 3rem">
                Error al subir la ine
              </h4>
              <span style="margin-top: 1rem; font-size: 2rem; color: red">Intenta subir nuevamente la INE</span>
              <br />
              <base-button
                slot="title"
                type="primary"
                class="btn"
                style="
                  width: 60%;
                  border-radius: 10px;
                  margin-top: 1rem;
                "
                size="xl"
                v-on:click="closeModalErrorB()"
                >Cerrar</base-button
              >
            </div>
          </template>
        </card>
      </modal>
  </div>
</template>

<script>
import BasePDFVisor from "../components/BasePDFVisor";
import AccountsServiceTable from "./Tables/AccountsServiceTable";
import DropboxResize from "../components/DropboxResize";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Dropbox from "../components/Dropbox";
import BaseDropdown from "../components/BaseDropdown";
import BaseSlider from "../components/BaseSlider";
import DebtsTable from "./Tables/DebtsTable";
import AccountTracingTable from "./Tables/AccountTracingTable";
import PaymentsTable from "./Tables/PaymentsTable";
import UserFilesTable from "./Tables/UserFilesTable";
import UserFilesTableFinamigo from "./Tables/UserFilesTableFinamigo";
import UserStatusTracingTable from "./Tables/UserStatusTracingTable";
import CreditReportTable from "./Tables/CreditReportTable";
import AddendumTable from "./Tables/AddendumTable";
import MovementsTable from "./Tables/MovementsTable";
import ReactivationsTable from "./Tables/ReactivationsTable";
import AccountSummaryTable from "./Tables/AccountSummaryTable";
import MoneyBackTable from "./Tables/MoneyBackTable";
import firebase from 'firebase/app';
import "firebase/messaging";
export default {
  name: "user-profile",
  components: {
    ReactivationsTable,
    AccountsServiceTable,
    AccountSummaryTable,
    DropboxResize,
    UserStatusTracingTable,
    AddendumTable,
    AccountTracingTable,
    flatPickr,
    Dropbox,
    BaseDropdown,
    BaseSlider,
    DebtsTable,
    UserFilesTableFinamigo,
    PaymentsTable,
    UserFilesTable,
    CreditReportTable,
    MovementsTable,
    MoneyBackTable,
    BasePDFVisor
  },
  computed: {
    streetState(){return ((this.client.addr_street) ? (this.client.addr_street.length > 2) : false)},
    codeState(){return ((this.client.addr_postal_code) ? (this.client.addr_postal_code.toString().length === 5) : false)},
    user() { return this.$getLS(this.$v.STORE_KEYS.USER); },
    relationBeneficiary() { return this.$store.state.relationBeneficiary; },
    mxStates() { return this.$store.state.mxStates; },
    teams() {return this.$store.state.TeamsLeaders;},
    managerService() { return this.$store.state.managerService },
    sellers() { return this.$store.state.sellerNotEnable; },
    sellersGood() { return this.$store.state.sellers },
    typeAddendums() { return this.$store.state.typeAddendums; },
    customerService() { return this.$store.state.customerService; },
    moneyBackCatalog() { return this.$store.state.moneyBackCatalog; },
    banks() { return this.$store.state.banks; },
    finamigoBanks() { return this.$store.state.finamigoBanks; },
    userInactiveCatalog() { return this.$store.state.userInactiveCatalog; },
    productType() { return this.$store.state.productType },
    leadStatus() { return this.$store.state.leadStatus; },
    userActionsCatalog() { return this.$store.state.userActionsCatalog; },
    CommissionPercentages() { return this.$store.state.CommissionPercentages; },
    debtActivityCatalog() { return this.$store.state.debtActivityCatalog; },
    debtSCFirms() { return this.$store.state.debtSCFirms; },
    auditorCatalog() { return this.$store.state.auditorCatalog },
    discount: {
      get() {
        return this.newAccount
          ? this.accountInModal.amount
          : this.accountInModal.discount;
      },
    },
    economicActivities() {
      let activities = this.$store.state.economicActivities;
      if (!activities) this.$store.dispatch("downloadEconomicActivities");
      return activities;
    },
    userTracingStatusCatalog() {
      let uTS = this.$store.state.userTracingESCStatus;
      let userId = this.$route.query.id;
      let userTracingStatusCatalog = [];

      this.getExistsWelcome(userId);
      if(this.existsWelcome == true){
        uTS.splice(24, 1)
      }
      for (let i = 0; i < uTS.length; i++) {
        if (uTS[i].enabled) userTracingStatusCatalog.push(uTS[i]);
      }
      return userTracingStatusCatalog;
    },
  },
  created() {
    this.pdfPayment = false;
    this.notifyMe(this.tokenFCM);
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE; // ||
    //this.user.role === this.$v.ROLES.AUDITOR;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
    let userId = this.$route.query.id;
    if (userId > 0) {
      if (this.isAdmin || this.isManagerSeller || this.isManagerService || this.isAuditor) this.$router.push({path: "/client" + "?id=" + userId});
      if (this.isSeller) this.$router.push({path: "/clientSeller" + "?id=" + userId});
      this.getClientProfile(userId).then((found) => {
        this.getStatusAudit(userId);
        if (!found) {
          this.$router.push("/clients");
        }
      });
    }

    let year =  new Date().getFullYear()
    this.configDate.minDate = year-100 + "-01-01";
    this.configDate.maxDate = year-18 + "-12-31";
  },
  watch: {
    $route() {
      let userId = this.$route.query.id;
      console.log(userId)
      if (userId > 0) location.reload();
        // this.getClientProfile(userId).then((found) => {
        //   if (!found) {
        //     this.$router.push("/clients");
        //   }
        // });
    },
  },
  data() {
    return {

    //Edit Payday
    editPayday:false,

      //Bajo demanda
      viewBC:false,
      showEye:false,


      sameBankNom:"",
      statusSignature:"",
      editCommision: false,
      isEspecialCommision: false,
      //----Modifications
      modificationData: {},
      modificationModal: false,
      modificationType: '',
      //userTracingStatusCatalog: [],
      newDebts: [],
      isManagerSeller:false,
      tokenFCM: null,
      commissionPercentage: "",
      editClientPhone: null,
      checkInactive: false,
      existReport: false,
      creditReportBad: false,
      notAccounts: false,
      isAdmin: false,
      isSeller: false,
      isService: false,
      isManagerService: false,
      isAuditor: false,
      fileType: null,
      accountInModal: {},
      newActivityNegotiation: {},
      curpPDF: null,
      getCovid: null,
      getPromise: null,
      getLastConsultant: null,
      fileTypes: [
        "ID",
        "ID_B",
        "ADDRESS",
        "CREDIT_INFO",
        "CONTRACT",
        "CURP",
        'QUOTE',
        'ADDENDUM',
        'LETTER_DBM',
        "FILE",
      ],
      dateCreatedBC:"",
      creditReport:{},
      typeModification:"",
      newUserFileModal: false,
      newPayment: {},
      newMoneyBack: {},
      newCommission: {},
      selectedPayment: {},
      selectedAddendum: {},
      requestBuroCredito: {},
      newPaymentModal: false,
      newMoneyBackModal: false,
      newAddendum: false,
      paymentModal: false,
      accountModal: false,
      accountAddendumModal: false,
      accountDetailsModal: false,
      accountTraceTable: false,
      newAccount: false,
      clientPaymentResume: {},
      nextPaymentDate: null,
      duePaymentSum:null,
      duePaymentCount:null,
      statusClient:false,
      newCommissionModal: false,
      futureAccountModal: false,
      requestBuroModal: false,
      calculatorDebts: [],
      accounts: [],
      sliderRange: [],
      periodOptimal: "",
      maxSlider: "",
      minSlider: "",
      //                paidAccountModal: false,
      //                paidAccount: null,
      configDate:
          {
            minDate:'',//-100 años al año actual
            maxDate:''//-18 años al año actual
          },
      futureAccount: {
        MontoPagar: 0,
        LimiteCredito: 0,
        FechaActualizacion: "",
        FechaUltimoPago: "",
      },
      clientValidation: {
        status: 2,
        pin: 0,
      },
      originalClient: {},
      originalBeneficiary: {},
      client: {},
      contract: {},
      effective_commission: false,
      userFiles: [],
      beneficiary: {},
      finamigo: {},
      files: {},
      paymentPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      addendumPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      accountPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      debtPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      accountTracingTableData: [],
      accountTracePagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      commissionPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      historyPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      auditPagination: {
        total: 0,
        perPage: 1,
        currentPage: 1,
      },
      userFilesPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      userFilesFAPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      fID:false,
      fID_B:false,
      fADD:false,
      fCURP:false,
      period: "",
      paymentPlanDebts: [],
      paymentPlans: {},
      paymentPlan: {},
      tracingModal: false,
      newNegotiationModal: false,
      newUserActionModal: false,
      statusTracingTableDataCS: [],
      statusTracingTableDataS: [],
      statusTracingTableDataB: [],
      userActionsTableData: [],
      userInactiveTableData: [],
      statusTracingPaginationCS: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      tracingStatus: "",
      statusTracingPaginationB: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      statusTracingPaginationS: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      userActionsPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      userInactivePagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      movementsPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      feemovementsPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      signaturesPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      moneyBackPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      newTrace: {
        id: 0,
      },
      futureAccountTableData: [],
      movementsTableData: [],
      feemovementsTableData:[],
      signaturesTableData: [],
      validatedPaymentsFilter: null,
      movTypeFilter: null,
      paymentTableData: [],
      moneyBackTableData: [],
      addendumTableData: [],
      accountTableData: [],
      debtTableData: [],
      commissionTableData: [],
      userFilesTableData: [],
      userFilesFATableData: [],
      historyTableData: [],
      auditTableData: [],
      stateOfBirth: null,
      postalCodes: [],
      traceModule: {},
      sublocalities: [],
      locality: "",
      gender: null,
      beneficiaryGender: null,
      edit: false,
      tokenUrl: null,
      prevDel: false,
      showFinBalance: false,
      calcModal: false,
      calcAddModal: false,
      sendMessageModal: false,
      isImage: false,
      dbmAccountsCalculations: {
        totalDebt: 0,
        savings: 0,
        montlyFee: 0,
        discount: 0,
        debts: [],
      },
      AccountsCalcAdd: {},
      clientCalculations: {
        totalDebt: 0,
        savings: 0,
        montlyFee: 0,
        discount: 0,
        debts: [],
      },
      calculos: {
        totalDebt: 0,
        savings: 0,
        montlyFee: 0,
        discount: 0,
        debts: [],
      },
      sumTotalDebt: 0,
      totalDebtsum: 0,
      totalDebtAccounts: "",
      report: {},
      withNomina: false,
      messageReport: "",
      lengthsCreditReports: {
        dbmAccounts: null,
        otherAccounts: null,
        toAddAccounts: null,
        dbmAccountsBuro: null,
      },
      pushNotTitle: "",
      theMessage: "",
      typeTheMessage: "",
      widthProgressBar: "",
      titleProgressBar: "",
      history: null,
      filesURL: {},
      //showModalReport: null,
      pointer: "pointer",
      pointer2: "default",
      nominas: [],
      fechaCorrect: "",
      updateDelete:false,
      sumMovements:"",
      sumMovementsCom:"",
      sumMovementsReal:"",
      sumMovementsCorrect:"",

      //Reactivations
      reactivated: false,
      reactivationsTableData: [],

      //SPEI_OUT
      payDebtModal: false,
      newSpeiOut: {},

      //Estado de cuenta FA
      FAStatementAccountData: [],
      FAStatementAccountPeriod: {
        startDate: null,
        endDate: null
      },
      reactiveClientBaja:false,
      reactiveClientGrad:false,
      isPDF:false,
      statusCancel:[],
      statusAudit:false,
      completeAudit:false,
      accountModalService:false,
      selectedBanksNomina:0,
      //PDFViewer
      forceUpdate: 0,
      pdfPaymente:false,

      clientAsign:false,
     mesaggeFA:false,
      messageFAnote:"",
      idRequestFA: '',
      dataError: null,
      dataUsers: null,
      loading: false,
      errorOCR: false,
      modalINEA:false,
      modalINEB:false,

      //Verification ADDRESS
      existAddress:false,
      existsWelcome:false,
    };
  },
  methods: {

    async chatInit()
    {
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.INIT_CHAT_NERDS

      this.$showLoader(true);

      let response = await this.$axios.post(url, {user: this.client.id});

      this.$showLoader(false);

      if(response.data.success)
      {
        this.$vs.notification(this.$notification('success', 'Enviado'));

      } else
      {
        this.$vs.notification(this.$notification('danger', 'Error'));
      }

    },

    getExistsWelcome(userID){
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_EXISTS_WELCOME
      this.$showLoader(true);
      this.$axios.post(url, {user: userID}).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          if(data.rows[0].welcome == 1){
            this.existsWelcome = true;
          }       
          this.$forceUpdate();
        }
      });
    },
    closeModalA() {
      this.modalINEA =false;
      this.dataError = null;
      this.dataUsers = null;
      this.loading = false;
      this.errorOCR = false;
      this.newUserFileModal=false;
      this.getClientProfile(this.client.id);
    },
    closeModalB() {
      this.modalINEB =false;
      this.dataError = null;
      this.dataUsers = null;
      this.loading = false;
      this.errorOCR = false;
      this.newUserFileModal=false;
      this.getClientProfile(this.client.id);
    },
    closeModalErrorA() {
      this.modalINEA =false;
      this.dataError = null;
      this.dataUsers = null;
      this.loading = false;
      this.errorOCR = false;
    },
    closeModalErrorB() {
      this.modalINEB =false;
      this.dataError = null;
      this.dataUsers = null;
      this.loading = false;
      this.errorOCR = false;
    },
    getExistAddressUser() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.VERIFICATION_ADDRESS;
      let request={
        user: this.client.id,
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.existAddress = data.row
          this.$forceUpdate();
        } else {
          console.log('User no encontrado')
        }
      });
    },
    beneficiaryRelationSelected(relation) {
      console.log(relation)
      this.beneficiary.relationship = relation;
      this.getRelationBeneficiary(relation)
      this.$forceUpdate();
    },
     getRelationBeneficiary(id) {
      for (let i = 0; i < this.relationBeneficiary.length; i++)
        if (this.relationBeneficiary[i].id === id){
          console.log(this.relationBeneficiary[i].value)
          return this.relationBeneficiary[i].value;
        }
      return null;
    },
    async accountSelectedService(acc) {
      this.accountModalService = true;
      this.accountInModal = {
        bank: acc.bank.id,
        account: acc.account,
        amount: acc.amount,
        discount: acc.discount,
        index: acc.accountsTableIndex,
        id: acc.id,
        payroll_same_bank: acc.payroll_same_bank,
        last_bank_payment_date: this.$moment(acc.last_bank_payment_date)._d,
        product_type: acc.product_type,
        will_pay: acc.will_pay,
        will_pay_wo_fee: acc.will_pay_wo_fee,
        will_pay_on: acc.will_pay_on,
        paid_file: acc.paid_file,
        fileurl: null,
        paid: acc.paid,
      };
      if (this.accountInModal.paid_file){
        this.accountInModal.fileurl = await this.getFileUrl(acc.paid_file);
        let type_paid_file = acc.paid_file.split('.');
        if(type_paid_file[type_paid_file.length - 1]=="pdf"){
          this.isPDF = true;
        }
        }
    },
    setPayrollSB(account) {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_PAYROLL_SAME_BANK;
      let request={
        id_debt: account.id,
        payroll_same_bank: 1
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                'Cambio exitoso!'
            ));
          this.accountModalService=false;
          this.selectedBanksNomina=1;
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                'Error al realizar el cambio'
            ));
        }
      });
    },
    closeAccountModalService() {
      this.accountModalService = false;
      this.selectedBanksNomina=0;
    },
    setStatusAuditManagerSeller(){
      let url = this.$v.SERVER + this.$v.MANAGER_OPS.AUTHORIZATION_UPLOAD_AUDIT
      this.$showLoader(true);
      this.$axios.post(url, {user: this.client.id}).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.$vs.notification(this.$notification(
                'success',
                'Autorización existosa!',
                'Se ha autorizado hacer cambios en el perfil ya auditado.'
            ));
          this.$forceUpdate();
        }
      });
    },
    getStatusAudit(userID){
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_VIEW_USER_AUDITOR
      this.$showLoader(true);
      this.$axios.post(url, {user: userID}).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          if(data.rows.complete_docs == "COMPLETO" && this.isSeller){
            this.statusAudit = true;
          }else if(data.rows.complete_docs == "COMPLETO"){
            this.completeAudit = true;      
          }
          this.$forceUpdate();
        }
      });
    },
    getStatusCancel(){
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_STATUS_CANCEL_BY_BALANCE;
      this.$showLoader(true);
      this.$axios.post(url, {user: this.client.id,}).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.statusCancel = data.row;
          console.log(this.statusCancel)
          this.$forceUpdate();
        }
      });
    },
    updateSetReadNotify() {
      let notif = this.$route.query.notif;
      console.log(notif);
      if (notif) {
        let url = this.$v.SERVER + this.$v.SELLER_OPS.SET_READ_FCM_CONSULTANTS;
        this.$axios.post(url, { id: notif }).then((response) => {
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            console.log("Read Updated");
          }
        });
      } else {
        console.log("No");
      }
    },
    notifyMe(tokenFCM) {
      if (this.$route.query.notif) {
        this.updateSetReadNotify();
      }
      this.tokenFCM = tokenFCM;
      var messaging = firebase.messaging();
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          messaging
            .getToken()
            .then((tokenFCM) => {
              if (tokenFCM) {
                this.tokenFCM = tokenFCM;
                let url =
                  this.$v.SERVER + this.$v.SELLER_OPS.SET_FCM_TOKEN_CONSULTANTS;
                this.$axios.post(url, { token: tokenFCM }).then((response) => {
                  let data = response.data;
                  if (data[this.$v.API_SUCCESS]) {
                    console.log("Token Add");
                  }
                });
              } else {
                // Show permission request.
                console.log(
                  "No Instance ID token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
            });
        }
      });
    },
    interprateDate(cadena) {
      if(cadena){
        let identificador = cadena.indexOf("-");
        if(identificador > 1){
          return (this.$moment(cadena).format('DD-MMM-YYYY'))
        } else {
          let fec = cadena + "";
          let day = fec.substr(0,2);
          let month = fec.substr(2,2);
          let year = fec.substr(4,7);
          let date = month+"-"+day+"-"+year;
          let fecha = this.$moment(date).format('DD-MMM-YYYY')
          return fecha;
        }
      }
    },
    updateNomina() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_NOMINAS_BANK;
      let request = {
        user: this.client.id,
        payroll_same_bank: "1",
      };
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.nominas = response.data[this.$v.API_ROWS];
          if (this.nominas.length > 0) {
            this.withNomina = true;
          }
        }
        this.$forceUpdate();
      });
    },
    getPromiseCovid() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_PROMISE_COVID;
      let request = { user: this.client.id };
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data) {
          this.client.date_promise = data.date_promise;
          this.clientPaymentResume.covid = data.covid;
        }
      });
    },
    getOtherConsultant() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_LAST_CONSULTANT;
      let request = { user: this.client.id };
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.service_id > 0){
            this.client.last_consultant = data.seller_id;
        }else{
          this.client.last_consultant = "---";
        }
      });
    },
    setCovid() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_COVID;
      this.$axios
        .post(url, {
          user: this.client.id,
          covid: !this.clientPaymentResume.covid,
        })
        .then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.$vs.notification(this.$notification(
                'success',
                'Cambio exitoso!'
            ));
            this.$forceUpdate();
          } else {
            this.$vs.notification(this.$notification(
                'danger',
                'Error al realizar el cambio!',
                'Intenta nuevamente'
            ));
          }
        });
    },
     async uploadFinamigoFile(type) {
      if (this.files[type] && !this.files["upload_" + type]) {
        this.$showLoader(true);
        let url = this.$v.SERVER + this.$v.SELLER_OPS.UPLOAD_FILE_S3_FINAMIGO;
        this.$axios
          .post(url, {
            user: this.client.id,
            type: type,
          })
          .then((response) => {
            this.$showLoader(false);
            let data = response.data;
            if (data[this.$v.API_SUCCESS]) {
              this.$vs.notification(this.$notification(
                'success',
                'Carga exitosa!',
                'Se ha cargado existosamente el archivo'
            ));
              this.$forceUpdate();
            } else {
              this.$vs.notification(this.$notification(
                'danger',
                'Error al realizar el cambio!',
                'Intenta nuevamente'
            ));
            }
          });
      }else if (!this.files[type] && !this.files["upload_" + type]) {
        this.createDBMenosContract();
      }
    },
    async createFinamigoDocuments(typeDoc) {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.CREATE_FINAMIGO_DOCUMENTS;
      this.$axios.post(url, { user: this.client.id }).then((response) => {
        this.$showLoader(false);

        if (response.data.path) {
           this.$vs.notification(this.$notification(
                'success',
                'Creación exitosa!',
                'Se ha creado existosamente el archivo'
            ));
          this.files[typeDoc] = 1;
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                'Error al realizar la creación!',
                'Intenta nuevamente'
            ));
        }
      });
    },
    async getFinamigoFiles() {
      let url =
        this.$v.SERVER +
        this.$v.OPS.GET_USER_FINAMIGO_FILES +
        "?user=" +
        this.client.id;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.files = response.data[this.$v.API_ROW];
          this.$forceUpdate();
        }
      });
    },
    getProgramCalculation() {
      let url =
        this.$v.SERVER +
        this.$v.SELLER_OPS.GET_PROGRAM_CALCULATION +
        "?user=" +
        this.client.id;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          if (data.row["calculation"]) {
            this.clientCalculations = data.row["calculation"];
            this.$forceUpdate();
          }
        } else this.clientCalculations = null;
      });
    },
    moveAccount() {
      this.accountDetailsModal = false;
      let dbmAccTable = this.report["dbmAccounts"];
      let otherAccTable = this.report["otherAccounts"];
      let i = this.accountInModal.Index;
      //Buscando en dbmAccounts
      if (dbmAccTable[i].CuentaActual === this.accountInModal.CuentaActual) {
        otherAccTable.push(dbmAccTable.splice(i, 1)[0]);
      } else if (
        otherAccTable[i].CuentaActual === this.accountInModal.CuentaActual
      ) {
        dbmAccTable.push(otherAccTable.splice(i, 1)[0]);
      }
    },
    escEdit() {
      this.edit = false;
      this.getClientProfile(this.client.id);
    },
    getClientProfile(clientId) {
      return new Promise((resolve) => {
        let url =
          this.$v.SERVER + this.$v.SERVICE_OPS.GET_FULL_PROFILE_SC + "?id=" + clientId;
        this.$axios.get(url).then((response) => {
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.client = response.data[this.$v.API_ROW];
            if(this.isSeller && this.client.consultant == this.user.id){
              this.clientAsign = true;
            }else if(!this.isSeller){
              this.clientAsign = true;
            }
            this.editClientPhone = this.client.phone;
            if(this.client.email){
              let email = this.client.email.split('_')
                if(email.length >1){
                  if(email[0]=='baja'){
                    this.checkInactive = true;
                  }else if(email[0]=='graduado'){
                    this.checkInactive = true;
                  }
              }
            }
            this.showEye = true;
            this.loadContract();
            //this.loadBeneficiary();
            this.loadClient();
            this.updateNomina();
            this.$forceUpdate();
            this.sumMovements = data.sumMovements.sumCorrectMov;
            this.sumMovementsCom= data.sumMovementsCom.sumCorrectMov;
            this.sumMovementsReal= data.sumMovementsCom.sumRealComMovReal;
            this.sumMovementsCorrect= data.sumMovementsCom.sumCorrectMovReal;
            resolve(true);
          }
          resolve(false);
        });
      });
    },
    loadContract() {
      let url =
        this.$v.SERVER +
        this.$v.SERVICE_OPS.GET_CONTRACT +
        "?id=" +
        this.client.contract;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.contract = data[this.$v.API_ROW];
          this.commissionPercentage =
            data[this.$v.API_ROW].commission_percentage;
          this.effective_commission =
            data[this.$v.API_ROW].effective_commission;
            this.$forceUpdate();
            if(this.client.contract>0){
              this.setStatusClient(this.client.id)
            }
        }
      });
    },
    addNewAddendum(){
      let url =
        this.$v.SERVER +
        this.$v.SERVICE_OPS.ADD_NEW_ADDENDUM_APPROACH;
        let newApproach={
          user:this.client.id
        }
      this.$axios.post(url,newApproach).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.getAddendum(1)
          this.newAddendum = true;
          this.$forceUpdate();
        }
      });
      /*
        this.$router.push({
        path: "/addendum?user="+this.client.id,
        query: null,
      });
      */
    },
    setTraceProductType(type) {
      this.accountInModal.product_type = type;
      this.$forceUpdate();
    },
    loadBeneficiary() {
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_BENEFICIARY + "?user=" + this.client.id;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.client.beneficiary = data.row;
          if (this.client.beneficiary !== null) {
            this.beneficiary = this.client.beneficiary;
            if (this.beneficiary.gender) {
              if (this.beneficiary.gender === "M")
                this.beneficiaryGender = "Masculino";
              else if (this.beneficiary.gender === "F")
                this.beneficiaryGender = "Femenino";
            }
            if (this.beneficiary.civil_status) {
              if (this.beneficiary.civil_status === "M")
                this.beneficiary.civil_status = "Casado";
              else if (this.beneficiary.civil_status === "S")
                this.beneficiary.civil_status = "Soltero";
            }
            this.originalBeneficiary = JSON.parse(
              JSON.stringify(this.beneficiary)
            );
            this.originalClient = JSON.parse(JSON.stringify(this.client));
          }
        }
      });
    },
    loadClient() {
      if (!this.client.hasOwnProperty("id")) this.edit = true;
      else {
        /*this.contract = {
          user: this.client.id,
          payday: this.client.payday,
          program_duration: this.client.program_duration,
          monthly_savings: this.client.monthly_savings,
          commission_percentage: this.client.commission_percentage,
          first_payment: this.client.first_payment
        };*/
        if (this.client && this.client.gender !== undefined) {
          if (this.client.gender === "M") this.gender = "Masculino";
          else if (this.client.gender === "F") this.gender = "Femenino";
        }
        if (this.client.civil_status) {
          if (this.client.civil_status === "M")
            this.client.civil_status = "Casado";
          else if (this.client.civil_status === "S")
            this.client.civil_status = "Soltero";
        }
        if (this.client.state_of_birth) {
          for (let i = 0; i < this.mxStates.length; i++) {
            let st = this.mxStates[i];
            if (this.client.state_of_birth === st.code) {
              this.stateOfBirth = st.state;
              break;
            }
          }
        }
        this.locality = this.client.locality;
        this.getPromiseCovid();
        this.getOtherConsultant();
        this.getStatusCancel();
        //this.getClientPaymentResume();
        this.getFinamigoFiles();
        this.getProgramCalculation();
        //this.getAuditUser(1);
        this.getInfoReactiveUser();
        this.getUserFinamigo(1);
        //this.getHistoryEvent(1);
        //this.getTitleAndWidthProgressBar();
        this.getCreditReport();
        this.getTracingTableDataCS(1);
        //this.getTracingTableDataS(1);
        //this.getTracingTableDataB(1);
        //this.getUserActionsTableData(1);
        this.getUserInactiveTableData(1);
        this.getUserAccounts(1);
        this.getExistAddressUser();
        this.getUserDebts(1);
        this.getMovementsTableData(1);
        //this.getFeeMovementsTableData(1);
        //this.getSignaturesTableData(1);
        this.getUserPayments(1);
        //this.getUserCommissions(1);
        this.getUserFiles(1);
        this.getUserFilesFinamigo(1);
        this.getAddendum(1);
        if (
          !this.isSeller &&
          this.client.contract > 0 &&
          this.client.payment_count > 0
        )
          this.getFinamigoBalance();

        if (
          this.client.inactive_reason === "Baja" ||
          this.client.inactive_reason === "Graduado" ||
          this.client.inactive_reason === "En proceso de baja" ||
          this.client.inactive_reason === "Reactivado" 
        ) {
          this.checkInactive = true;
        }
        if (
          this.user.id == 3
        ) {
          this.getUserMoneyBack(1);
        }
        this.$forceUpdate();
      }
    },
    showAccountInformationModal(acc) {
      this.futureAccount = acc;
      this.futureAccountModal = true;
    },
    assignToConsultant(profile, consultant) {
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.ASSIGN_USER_SERVICE
      this.$showLoader(true)
      this.$axios.post(url, {
        user: profile.id,
        consultant: consultant.id
        }).then(response => {
          this.$showLoader(false)
          profile.consultant = consultant.id
          let data = response.data
          if (data[this.$v.API_SUCCESS]) {
            this.$vs.notification(this.$notification(
                'success',
                'Asignación exitosa!',
                'Se ha asignado correctamente al nuevo asesor.'
            ));
              this.$forceUpdate()
              }
              })
              },
    sendMessage() {
      this.$showLoader(true);
      let message = this.theMessage;
      if (this.typeTheMessage === "SMS") {
        let url = this.$v.SERVER + this.$v.SELLER_OPS.SEND_MESSAGE;
        this.$axios
          .post(url, { user: this.client.id, message: message })
          .then((response) => {
            this.$showLoader(false);
            if (response.data.success) {
              this.$vs.notification(this.$notification(
                'success',
                'SMS envíado!'
            ));
            } else {
              this.$vs.notification(this.$notification(
                'danger',
                'SMS no envíado!',
                'Intenta nuevamente'
            ));
            }
          });
      } else if (this.typeTheMessage === "PN") {
        let url = this.$v.SERVER + this.$v.SELLER_OPS.SEND_PUSH_NOTIFICATION;
        this.$axios
          .post(url, {
            user: this.client.id,
            title: this.pushNotTitle,
            message: message,
          })
          .then((response) => {
            this.$showLoader(false);
            if (response.data.success) {
              this.$vs.notification(this.$notification(
                'success',
                'Notificación Enviada!',
                'Se ha enviado correctamente la notifiación push al cliente'
            ));
            } else {
              this.$vs.notification(this.$notification(
                'danger',
                'Error!',
                'No se ha enviado la notificación, Intenta nuevamente'
            ));
            }
          });
      }
    },
    requestSignatureReport() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.REQUEST_CREDIT_REPORT_PIN;
      this.$axios.post(url, { user: this.client.id }).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.requestBuroModal = true;
          this.$vs.notification(this.$notification(
                'success',
                'NIP enviado!',
                'Se ha enviado el NIP al Cliente'
            ));
        } else {
          switch (data[this.$v.API_REASON]) {
            case 2:
              this.$vs.notification(this.$notification(
                'danger',
                'Error al Envíar el NIP!',
                'El cliente no está completo'
            ));
              break;
            case 5:
              this.$vs.notification(this.$notification(
                'info',
                'NIP ya validado!'
            ));
              this.requestBuroCredito_Generate();
              break;
            default:
              this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Intenta Nuevamente'
            ));
              break;
          }
        }
      });
    },
    requestValidatedSignatureReport() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.VALIDATE_CREDIT_REPORT_PIN;
      this.$axios
        .post(url, { user: this.client.id, pin: this.requestBuroCredito.pin })
        .then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.$vs.notification(this.$notification(
                'success',
                'NIP validado!',
                'Se ha validado correctamente el NIP al Cliente'
            ));
            this.requestBuroModal = false;
            this.requestBuroCredito_Generate();
          } else {
            switch (data[this.$v.API_REASON]) {
              case 2:
                this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'El cliente no esta completo.'
            ));
                break;
              case 7:
                this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'NIP Incorrecto'
            ));
                break;
              case 8:
                this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'El NIP Caduco'
            ));
                break;
              default:
                this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Intenta Nuevamente'
            ));
                break;
            }
          }
        });
    },
    requestBuroCredito_Generate() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.REQUEST_CREDIT_REPORT;
      this.$axios.post(url, { user: this.client.id }).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                'Generando Buro de Crédito!'
                ));
        } else {
          switch (data[this.$v.API_REASON]) {
            case 2:
                this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'El cliente no esta completo.'
            ));
                break;
              case 7:
                this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'NIP Incorrecto'
            ));
                break;
              default:
                this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Intenta Nuevamente'
            ));
                break;
          }
        }
      });
    },
    requestResendSignatureContract() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.REQUEST_RE_SEND_CONTRACT_SIGNATURE;
      this.$axios.post(url, { user: this.client.id }).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                'Enlace enviado correctamente!',
                'Enlace enviado al cliente por WhatsApp'
            ));
          this.getSignaturesTableData(1);
          this.getUserFiles(1);
        } else {
          switch (data[this.$v.API_REASON]) {
            case 2:
              this.$vs.notification(this.$notification(
                'danger',
                'Error al envíar enlace!',
                'El cliente no está completo'
            ));
            break;
            case 5:
              this.$vs.notification(this.$notification(
                'danger',
                'Error al envíar enlace!',
                'El cliente ya firmo'
            ));
              break;
            default:
              this.$vs.notification(this.$notification(
            'danger',
            'Error al crear contrato',
            'Revisar: '+data.row
          ))
              break;
          }
        }
      });
    },
    requestSignatureContract() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.REQUEST_CONTRACT_SIGNATURE;
      this.$axios.post(url, { user: this.client.id }).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                'Enlace enviado correctamente!',
                'Enlace enviado al cliente por WhatsApp'
            ));
          this.getSignaturesTableData(1);
          this.getUserFiles(1);
        } else {
          switch (data[this.$v.API_REASON]) {
             case 2:
              this.$vs.notification(this.$notification(
                'danger',
                'Error al envíar enlace!',
                'El cliente no está completo'
            ));
            break;
            case 5:
              this.$vs.notification(this.$notification(
                'danger',
                'Error al envíar enlace!',
                'El cliente ya firmo'
            ));
              break;
            default:
              this.$vs.notification(this.$notification(
            'danger',
            'Error al crear contrato',
            'Revisar: '+data.row
          ))
              break;
          }
        }
      });
    },
    newFutureAccount() {
      this.futureAccountTableData.push({
        bank: {},
        account: "",
        amount: 0.0,
        editMode: true,
      });
    },
    newAccountModal() {
      this.accountModal = true;
      this.newAccount = true;
    },
    newAccountAddendumModal() {
      this.accountAddendumModal = true;
      this.newAccount = true;
    },
    updateAccount() {
      if (!this.accountInModal.last_bank_payment_date || !this.accountInModal.product_type && this.isSeller) {
        this.$vs.notification(this.$notification(
                'danger',
                'Error al editar Cuenta!',
                'Campos incompletos'
            ));
        return;
      }
      if (this.accountInModal.will_pay_on)
        this.accountInModal.will_pay_on += "T12:00:00.000Z";
      else this.accountInModal.will_pay_on = null;
      if (this.accountInModal.paid)
        this.accountInModal.paid += "T12:00:00.000Z";
      else this.accountInModal.paid = null;
      this.accountInModal.user = this.client.id;
      this.accountInModal.last_bank_payment_date = this.$moment(this.accountInModal.last_bank_payment_date)._d;
      this.accountInModal.contract = this.client.contract;
      this.accountInModal.product_type = this.accountInModal.product_type.id;
      if (this.newAccount) this.accountInModal.discount = this.discount;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_ACCOUNTS;
      this.$showLoader(true);
      this.$axios
        .post(url, { accounts: [this.accountInModal] })
        .then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            if (this.client.contract > 0) {
              this.updateDelete = true;
              this.$forceUpdate();
            }else{
              this.$forceUpdate();
            }
            this.getUserAccounts(1);
            this.getUserDebts(1);
            this.getAuditUser(1);
            this.$vs.notification(this.$notification(
                'success',
                'Actualización exitosamente!'
            ));
          } else {
            this.$vs.notification(this.$notification(
                'danger',
                'Error al editar cuenta!',
                'Algo salió mal, intenta nuevamente'
            ));
          }
        });
      this.accountModal = false;
      this.newAccount = false;
      this.accountInModal = {};
    },
    formatDate(date) {
      return this.$formatDate(date);
    },
    formDate(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },
    formatDateOther(date) {
      return this.$moment(date).format("DD MMM, YYYY");
    },
    tabSelected(tab) {
      if (tab.id === "calculator") {
        this.paymentPlans = {};
        if (this.futureAccountTableData.length > 0) {
          for (let i = 0; i < this.futureAccountTableData.length; i++) {
            let acc = this.futureAccountTableData[i];
            let bank = acc.bank;
            if (
              acc.amount < 1000 ||
              bank === undefined ||
              bank === null ||
              !bank.hasOwnProperty("id") ||
              bank["id"] < 1
            ) {
              this.$vs.notification(this.$notification(
                'danger',
                'Error!',
                'Algunas cuentas tienen errores, revisalas antes de continuar'
            ));
              return;
            }
          }
          this.requestPaymentPlan();
        }
      }
    },
    requestPaymentPlan(period) {
      if (period) this.period = period;
      let request = {
        name: this.client.name,
        lastname: this.client.lastname_1,
        period: this.period,
        debts: this.futureAccountTableData,
      };
      if (this.paymentPlans.hasOwnProperty(String(this.period))) {
        this.paymentPlan = this.paymentPlans[String(this.period)];
        this.client.monthly_savings = this.formatDecPost(
          this.paymentPlan.montlyFee
        );
        this.client.program_duration = this.period;
        return;
      }
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.OPS.CALCULATE;
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.paymentPlans[String(this.period)] = data.row;
          this.paymentPlan = data.row;
          this.client.monthly_savings = this.formatDecPost(
            this.paymentPlan.montlyFee
          );
          this.client.program_duration = this.period;
        }
      });
    },
    sendSignatureAddendum(addendumID){
      this.$showLoader(true);
        let url = this.$v.SERVER + this.$v.SERVICE_OPS.REQUEST_ADDENDUM_SIGN;
        let dataSignature = {
          user: this.client.id,
          addendum: addendumID,
        };
      this.$axios.post(url,dataSignature).then((response) => {
        let data = response.data;
          this.$showLoader(false)
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                'Enlace enviado correctamente!',
                'Enlace enviado al cliente por WhatsApp'
            ));
          this.getSignaturesTableData(1)
          }switch (data[this.$v.API_REASON]) {
              case 5:
                this.$vs.notification(this.$notification(
                'info',
                'El cliente ya firmo!'
            ));
                break;
            }
        });
    },
    sendSignatureNomina(){
      if (this.auditTableData) //???
        this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.REQUEST_SIGNATURE_LETTER_DBM;
      let dataSignature = { user: this.client.id };
      //console.log(dataSignature)
      this.$axios.post(url,dataSignature).then((response) => {
        let data4 = response.data;
        this.$showLoader(false)
        if (data4[this.$v.API_SUCCESS]) {
          this.$showLoader(false);
          this.$vs.notification(this.$notification(
            'success',
            'Enlace de firma enviado exitosamente'
          ));
          this.getSignaturesTableData(1)
        }
        switch (data4[this.$v.API_REASON]) {
          case 1:
          this.$vs.notification(this.$notification(
            'danger',
            'Algo salió mal!',
            '¡Sin consultor asignado!'
          ));
          break;
          case 5:
            this.$vs.notification(this.$notification(
              'danger',
              'Algo salió mal',
              'Enlace ya firmado'
            ));
          break;
        }
      });
    },
    getUserAccounts(page) {
      let limit = this.accountPagination.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_ACCOUNTS;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          let a = data[this.$v.API_ROWS];
          for (let i = 0; i < a.length; i++) {
            for (let j = 0; j < this.banks.length; j++) {
              if (this.banks[j].id === a[i].bank) {
                a[i].bank = this.banks[j];
                break;
              }
            }
          }
          for (let i = 0; i < a.length; i++) {
            for (let j = 0; j < this.productType.length; j++) {
              if (this.productType[j].id === a[i].product_type) {
                a[i].product_type = this.productType[j];
                break;
              }
            }
          }
          this.accountTableData = a;
          this.accountPagination.total = p["totalItems"];
          this.accountPagination.currentPage = page;
          this.accountPagination.perPage = limit;
          this.getUserDebts(1);
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    getUserDebts(page) {
      let limit = this.debtPagination.perPage;
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_SC_DEBT_ACCOUNTS;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.debtTableData = data[this.$v.API_ROWS];
          this.debtPagination.total = p["totalItems"];
          this.debtPagination.currentPage = page;
          this.debtPagination.perPage = limit;
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    getAddendum(page) {
      let limit = this.addendumPagination.perPage;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_ADDENDUMS_APPROACH;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.addendumTableData = data[this.$v.API_ROWS];
          this.addendumPagination.total = p["totalItems"];
          this.addendumPagination.currentPage = page;
          this.addendumPagination.perPage = limit;
          if(this.newAddendum){
            let AddID= this.addendumTableData[0].id;
            this.$router.push({
            path: "/addendum?user="+this.client.id+"&add="+AddID,
            query: null,
          });
          }
          
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    getUserPayments(page) {
      let limit = this.paymentPagination.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_PAYMENTS;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.paymentTableData = data[this.$v.API_ROWS];
          this.paymentPagination.total = p["totalItems"];
          this.paymentPagination.currentPage = page;
          this.paymentPagination.perPage = limit;
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    getUserMoneyBack(page) {
      let limit = this.moneyBackPagination.perPage;
      let url = this.$v.SERVER + this.$v.ADMIN_OPS.GET_USER_MONEY_BACK;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.moneyBackTableData = data[this.$v.API_ROWS];
          this.moneyBackPagination.total = p["totalItems"];
          this.moneyBackPagination.currentPage = page;
          this.moneyBackPagination.perPage = limit;
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    getUserCommissions(page) {
      let limit = this.commissionPagination.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_COMMISSIONS;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.commissionTableData = data[this.$v.API_ROWS];
          this.commissionPagination.total = p["totalItems"];
          this.commissionPagination.currentPage = page;
          this.commissionPagination.perPage = limit;
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    getUserFiles(page) {
      let limit = this.userFilesPagination.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_FILES;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.userFilesTableData = data[this.$v.API_ROWS];
          this.userFilesPagination.total = p["totalItems"];
          this.userFilesPagination.currentPage = page;
          this.userFilesPagination.perPage = limit;
          if (this.isAuditor) this.getFilesURL(this.userFilesTableData);
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    getUserFilesFinamigo(page) {
      let limit = this.userFilesFAPagination.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_FILES_FINAMIGO;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.userFilesFATableData = data[this.$v.API_ROWS];
          this.userFilesFAPagination.total = p["totalItems"];
          this.userFilesFAPagination.currentPage = page;
          this.userFilesFAPagination.perPage = limit;
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
     getUserFinamigo(page) {
      let limit = 1;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_FINAMIGO;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          if(data[this.$v.API_MESSAGE]){
            this.mesaggeFA=true;
            this.messageFAnote = data[this.$v.API_MESSAGE];
            if(data[this.$v.API_ROW])
              this.idRequestFA = data[this.$v.API_ROW];
            console.log(this.messageFAnote)
          }else{
            this.finamigo = data[this.$v.API_ROWS];
          }
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    getUserAccountTracing(page) {
      let limit = this.accountTracePagination.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_ACCOUNT_TRACING;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
        account: this.accountInModal.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.accountTracingTableData = data[this.$v.API_ROWS];
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    calculateRFC_CURP() {
      let params = {
        name: this.client.name,
        lastname_1: this.client.lastname_1,
        lastname_2: this.client.lastname_2,
        state: this.client.state_of_birth,
        birthday: this.client.birthday,
        gender: this.client.gender.substring(0, 1),
      };
      let keys = Object.keys(params);
      let url = this.$v.SERVER + this.$v.OPS.GET_RFC_CURP + "?";
      for (let k = 0; k < keys.length; k++)
        url += keys[k] + "=" + params[keys[k]] + "&";
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data.hasOwnProperty("RFC")) this.client.rfc = data["RFC"];
        if (data.hasOwnProperty("CURP")) this.client.curp = data["CURP"];
        this.$forceUpdate();
      });
    },
    validateAddress()
    {
      return (this.streetState && this.codeState)
    },
    uploadChanges() {
      if (!this.$_.isEqual(this.client, this.originalClient) && this.validateAddress()) {
        let clientToUpload = JSON.parse(JSON.stringify(this.$v.OBJECTS.USER));
        for (let key in clientToUpload)
          if (
            clientToUpload.hasOwnProperty(key) &&
            this.client.hasOwnProperty(key)
          )
            clientToUpload[key] = this.client[key];
        if (this.client.civil_status) {
          clientToUpload.civil_status = this.client.civil_status
            .substring(0, 1)
            .toUpperCase();
          if (clientToUpload.civil_status !== "S")
            clientToUpload.civil_status = "M";
        }
        clientToUpload.home_phone = this.client.home_phone;
        clientToUpload.office_phone = this.client.office_phone;
        clientToUpload.ext_phone = this.client.ext_phone;
        let regex = /^\d{10}$/
        if(!regex.test(clientToUpload.phone)){
          this.$vs.notification(this.$notification(
            'danger',
            'Algo salió mal',
            'Teléfono invalido'
          ));
          return
        }
        /*
        let beneficiary = JSON.parse(JSON.stringify(this.beneficiary));
        if (Object.keys(beneficiary).length > 0) {
          beneficiary.civil_status = this.beneficiary.civil_status
            .substring(0, 1)
            .toUpperCase();
          if (beneficiary.civil_status !== "S") beneficiary.civil_status = "M";
          beneficiary.user = this.client.id;
          beneficiary.gender = this.beneficiaryGender
            .substring(0, 1)
            .toUpperCase();
          if (beneficiary.gender !== "M") beneficiary.gender = "F";
          beneficiary.user = this.client.id;
        } else {
          beneficiary = null;
        }
        */
        let url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_USER;
        this.$showLoader(true);
        this.$axios.post(url, clientToUpload).then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
             this.$vs.notification(this.$notification(
                'success',
                 'Guardado exitosamente'
                     ));

                  this.edit = false;
                  this.getAuditUser(1);
            /*
            if (beneficiary) {
              if (this.client.id > 0) {
                beneficiary.user = this.client.id;
              } else {
                beneficiary.user = data[this.$v.API_ROW].id;
              }
              url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_BENEFICIARY;
              this.$showLoader(true);
              this.$axios.post(url, beneficiary).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (!data[this.$v.API_SUCCESS]) {
                  if (data[this.$v.API_REASON] === 10) {
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Usuario duplicado'
                     ));
                  } else {
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                  }
                  this.getAuditUser(1);
                } else {
                  this.$vs.notification(this.$notification(
                'success',
                 'Guardado exitosamente'
                     ));
                }
              });
            } else {
              this.$showLoader(false);
              let data = response.data;
              if (!data[this.$v.API_SUCCESS]) {
                this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
              } else {
                this.$vs.notification(this.$notification(
                'success',
                 'Guardado exitosamente'
                     ));
                this.edit = false;
              }
            }
            */
          }else if(data.reason==10){
            this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Usuario duplicado'
                     ));
          } else {
            this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
          }
        });
      } else {
        if (!this.validateAddress()) {

          this.$vs.notification(this.$notification(
              'warning',
              'Datos incompletos!',
              'Favor de completar la dirección'
          ));

        } else {
          this.$vs.notification(this.$notification('danger', 'Algo salio mal'));
          this.edit = false;
        }

      }
    },
    uploadFile(obj) {
      let fileType = obj.type;
      let file = obj.file;
      let text = obj.text;
      let formData = new FormData();
      formData.append("file", file);
      let headers = {
        "client-id": this.client.id,
        "file-type": fileType,
      };

      console.log(fileType)
      if(fileType == 'ID'){
        this.modalINEA = true;
        this.loading = true;
      }else if(fileType == 'ID_B'){
        this.modalINEB = true;
        this.loading = true;
      }else{
          this.$showLoader(true);
      }
      this.$axios.post(this.$v.SERVER + this.$v.SELLER_OPS.UPLOAD_FILE, formData, {headers: headers,}).then((response) => {
          if (response.data.success) {
            if(fileType == 'ID'){
              this.modalINEA = true;
              this.loading = false;
              this.dataUsers=response.data.row;

              for (let i = 0; i < this.mxStates.length; i++) {
                let st = this.mxStates[i];
                if (this.dataUsers.state_of_birth === st.code) {
                  this.stateOfBirth = st.state;
                   break;
                }
              }
              this.dataError = null;
              this.newUserFileModal = false;
            }else if(fileType == 'ID_B'){
              this.modalINEB = true;
              this.loading = false;
              this.dataUsers=response.data.row;

              for (let i = 0; i < this.mxStates.length; i++) {
                let st = this.mxStates[i];
                if (this.dataUsers.state_of_birth === st.code) {
                  this.stateOfBirth = st.state;
                   break;
                }
              }
              this.dataError = null;
              this.newUserFileModal = false;
            }else{
              this.$showLoader(false);
            this.newUserFileModal = false;
            this.fileType = null;
            this.getUserFiles(1);
            this.getAuditUser(1);
            if(this.isAdmin || this.isService || this.isManagerService){
            this.getExistAddressUser();
            }
            this.$vs.notification(this.$notification('success',text + ' cargado correctamente!'));
            }
            /*
            if(fileType == 'ID' || fileType == 'ID_B'){
              this.getClientProfile(this.client.id);
            }
            */
          } else {
            if(fileType == 'ID'){
              this.loading = false;
              this.errorOCR = true;
              this.dataUsers = null;
              this.dataError = response.data.row;
              console.log(this.dataError);
            }else if(fileType == 'ID_B'){
              this.loading = false;
              this.errorOCR = true;
              this.dataUsers = null;
              this.dataError = response.data.row;
              console.log(this.dataError);
            }else{
              this.$showLoader(false);
            this.$vs.notification(this.$notification(
                'danger',
                'Documento no cargado exitosamente!'
                     ));
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.$showLoader(false);
         if(fileType == 'ID'){
              this.loading = false;
              this.errorOCR = true;
              this.dataUsers = null;
              this.dataError = response.data.row;
              console.log(this.dataError);
            }else if(fileType == 'ID_B'){
              this.loading = false;
              this.errorOCR = true;
              this.dataUsers = null;
              this.dataError = response.data.row;
              console.log(this.dataError);
            }else{
              this.$showLoader(false);
            this.$vs.notification(this.$notification(
                'danger',
                'Documento no cargado exitosamente!'
                     ));
            }
        });
    },
    setPaidAccount(obj) {
      let file = obj.file;
      console.log("S3File");
      this.$showLoader(true);
      let url =
        this.$v.SERVER +
        this.$v.SELLER_OPS.GET_PRESIGNED_PUT_OBJECT_URL +
        "?user=" +
        this.client.id +
        "&filename=" +
        file.name;
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let signedUrl = data[this.$v.API_ROW];
          this.$showLoader(true);
          this.$axios
            .put(signedUrl, file, { headers: {} })
            .then((putObjectResponse) => {
              this.$showLoader(false);
              if (putObjectResponse.status === 200) {
                let updateUrl =
                  this.$v.SERVER + this.$v.SERVICE_OPS.SET_PAID_ACCOUNT;
                let request = {
                  user: this.client.id,
                  paid_file: file.name,
                  account_id: this.accountInModal.id,
                  paid: Date(),
                };
                //console.log(request);
                this.$axios.post(updateUrl, request).then((response) => {
                  let data = response.data;
                  //console.log(data);
                  if (data[this.$v.API_SUCCESS]) {
                    this.$vs.notification(this.$notification(
                'success',
                'Cuenta Liquidada!'
            ));
                    this.getUserAccountTracing(1);
                    this.accountInModal.paid_file = file.name;
                    //console.log(this.accountInModal.paid_file);
                    this.getUserAccounts(1);
                    this.getUserDebts(1);
                  } else {
                    this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro liquidar la deuda'
            ));
                  }
                });
              } else {
                this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro liquidar la deuda'
            ));
              }
            })
            .catch((err) => {
              console.log(err);
              this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro liquidar la deuda'
            ));
            });
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro marcart como liquidada la deuda'
            ));
        }
      });
    },
    m_ReturnDiscountNew() {
      this.accounts = [];
      this.sliderRange = [];
      this.periodOptimal = this.periodOptimal;
      this.period = this.period;
      this.calculatorDebts = this.calculatorDebts;
      this.reportListo = true;
      for (let i = 0; i < this.calculatorDebts.length; i++) {
        for (let p = 0; p < this.calculatorDebts.length; p++) {
          if (this.period === this.calculatorDebts[p].period) {
            this.calculatorDebts.debts = this.calculatorDebts[p].debts;
            this.calculatorDebts.period = this.calculatorDebts[p].period;
            this.calculatorDebts.monthlyFee = this.calculatorDebts[
              p
            ].monthlyFee;
            this.calculatorDebts.savings = this.calculatorDebts[p].savings;
            this.calculatorDebts.willPay = this.calculatorDebts[p].willPay;
            this.calculatorDebts.totalDebt = this.calculatorDebts[p].totalDebt;
            this.calculatorDebts.discount = this.calculatorDebts[p].discount;
          }
        }
      }
      let myArray = this.sliderRange;
      this.maxSlider = Math.max.apply(Math, myArray);
      this.minSlider = Math.min.apply(Math, myArray);

      this.$forceUpdate();
    },
    uploadPayment(obj) {
      let file = obj.file;
      let formData = new FormData();
      let fileType=file.type;
      let fileExtension =fileType.substring(fileType.indexOf('/') + 1);
      formData.append(
        "file",
        file,
        this.$moment(new Date()).format("YYYY-MM-DD hh:mm:ss") + "." + fileExtension
      );
      let headers = {
        "client-id": this.client.id,
        amount: this.newPayment.amount,
        "payment-date": this.newPayment.date + "T12:00:00.000Z",
      };
      this.$showLoader(true);
      this.$axios.post(this.$v.SERVER + this.$v.SELLER_OPS.UPLOAD_PAYMENT, formData, {headers: headers}).then((response) => {
          this.$showLoader(false);
          if (response.data.success) {
            this.newPaymentModal = false;
            this.newPayment = {};
            this.getUserPayments(1);
            this.$vs.notification(this.$notification(
                'success',
                'Creación exitosamente!'
            ));
            if(response.data.sendNotification && response.data.sendNotification.errorMessage){
              this.$vs.notification(this.$notification(
                'warning',
                'Error al enviar notificación!'
              ));
            }
          } else {
            this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro cargar el pago'
            ));
          }
          this.newPaymentModal = false;
          this.newPayment = {};
          this.getUserPayments(1);
        })
        .catch((e) => {
          console.log(e);
          this.$showLoader(false);
          this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro cargar el pago'
            ));
        });
    },
    uploadContract() {
      let contract = {};
      if (this.contract.effective_commission || this.contract.commission_percentage) {
        contract = {
          user: this.client.id,
          payday: this.client.payday,
          program_duration: this.client.program_duration,
          monthly_savings: this.client.monthly_savings,
          commission_percentage: this.contract.commission_percentage,
          effective_commission: this.contract.effective_commission,
          first_payment: this.client.first_payment,
        };
      } else if (!this.contract.effective_commission || !this.contract.commission_percentage) {
        contract = {
          user: this.client.id,
          payday: this.client.payday,
          program_duration: this.client.program_duration,
          monthly_savings: this.client.monthly_savings,
          commission_percentage: this.commissionPercentage,
          effective_commission: this.commissionPercentage,
          first_payment: this.client.first_payment,
        };
      }
      if (this.client.contract > 0) {
        contract.id = this.client.contract;
      }
      let url = this.$v.SERVER + this.$v.SELLER_OPS.CREATE_CONTRACT;
      this.$axios.post(url, contract).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          //this.contract = contract;
          let _contract = data[this.$v.API_ROW];
          if (this.client.contract === 0) {
            this.client.contract = _contract[0].id;
            this.editCommision = false;
            this.isEspecialCommision = false;
            window.location.reload();
          }
            this.editPayday = false;
          this.$forceUpdate();
          this.$vs.notification(this.$notification(
                'success',
                'Contrato creado exitosamente!'
            ));
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro crear el contrato'
            ));
        }
      });
    },
    formatCurrency(value) {
      return this.$formatCurrency(value);
    },
    setGender(gender) {
      this.client.gender = gender.substring(0, 1).toUpperCase();
      this.gender = gender;
      this.$forceUpdate();
      this.calculateRFC_CURP();
    },
    setBeneficiaryGender(gender) {
      this.beneficiary.gender = gender.substring(0, 1).toUpperCase();
      this.beneficiaryGender = gender;
      this.$forceUpdate();
    },
    setCivilStatus(isUser, status) {
      if (isUser) {
        this.client.civil_status = status;
      }
    },
    setMxState(state) {
      this.client.state_of_birth = state.code;
      this.stateOfBirth = state.state;
      this.$forceUpdate();
      this.calculateRFC_CURP();

      if(state.code === 'NE')
      {
        this.$vs.notification(this.$notification(
            'warning',
            'Importante',
            'Este cliente tiene nacionalidad extranjera, favor de comunicarte con tu Team Leader'
        ));
      }
    },
    setEconomicActivity(activity) {
      this.client.economic_activity = activity;
      this.$forceUpdate();
    },
    getEconomicActivity() {
      for (let i = 0; i < this.economicActivities.length; i++)
        if (this.economicActivities[i].id === this.client.economic_activity)
          return this.economicActivities[i].activity;
      return null;
    },
    postalCodeUpdated(pcode) {
      if (pcode.length > 3) {
        let url = this.$v.SERVER + this.$v.OPS.GET_POSTAL_CODE + "?pc=" + pcode;
        this.$axios.get(url).then((response) => {
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.postalCodes = data[this.$v.API_ROWS];
            if (this.postalCodes.length === 1)
              this.postalCodeSelected(this.postalCodes[0]);
            this.$forceUpdate();
          }
        });
      } else {
        this.postalCodes = [];
        this.locality = "";
        this.sublocalities = [];
        this.client.addr_sublocality = "";
      }
    },
    postalCodeSelected(postalCode) {
      this.client.addr_postal_code = postalCode.postal_code;

      if(postalCode.postal_code.toString().length === 4)
      {
        this.client.addr_postal_code = '0'+postalCode.postal_code
      }
      if(postalCode.postal_code.toString().length === 3)
      {
        this.client.addr_postal_code = '00'+postalCode.postal_code
      }

      this.sublocalities = postalCode["sublocality"].split(";");
      if (this.sublocalities.length === 1)
        this.client.addr_sublocality = this.sublocalities[0];
      this.locality = postalCode["locality"];
    },
    formatDecPost(val, pos) {
      if (!pos) pos = 2;
      if (val && Number(val)) return Number(val).toFixed(pos);
      return "0.00";
    },
    getFileType(type) {
      switch (type) {
        case "FILE":
          return "Otro";
        case "ID":
          return "Identificación frente";
        case "ID_B":
          return "Identificación reverso";
        case "CURP":
          return "CURP";
        case "ADDRESS":
          return "Comprobante domicilio";
        case "CREDIT_INFO":
          return "Reporte de crédito";
        case "CONTRACT":
          return "Contrato firmado";
        case "FIN_CONTRACT":
          return "Contrato apertura de cuenta";
        case "FIN_MANIFEST":
          return "Carátula de apertura de cuenta";
        case 'QUOTE': return "Propuesta por calculadora";
        case 'LETTER_DBM': return "Carta de nómina del mismo banco";
        case 'ADDENDUM': return "Addendum";
        case 'PRE_CONTRACT': return "Pre contrato"
      }
      return "";
    },
    async getFilesURL(files) {
      let filesURL = {
        id: null,
        id_b: null,
        address: null,
        curp: null,
        fin_manifest: null,
        fin_contract: null,
      };
      for (let i = 0; i < files.length; i++) {
        switch (files[i].type) {
          case "ID":
            filesURL.id = await this.getFileUrl(files[i].filename);
            break;
          case "ID_B":
            filesURL.id_b = await this.getFileUrl(files[i].filename);
            break;
          case "CURP":
            filesURL.curp = await this.getFileUrl(files[i].filename);
            if (files[i].filename.indexOf(".pdf") !== -1) this.curpPDF = true;
            else this.curpPDF = false;
            break;
          case "ADDRESS":
            filesURL.address = await this.getFileUrl(files[i].filename);
            break;
          case "FIN_CONTRACT":
            filesURL.fin_contract = await this.getFileUrl(files[i].filename);
            break;
          case "FIN_MANIFEST":
            filesURL.fin_manifest = await this.getFileUrl(files[i].filename);
            break;
        }
        this.filesURL = filesURL;
      }
    },
    async getFileUrl(filename) {
      let session = JSON.parse(
        localStorage.getItem(this.$v.STORE_KEYS.SESSION)
      );
      if (session) {
        let url =
          this.$v.SERVER +
          this.$v.SELLER_OPS.GET_USER_FILE +
          "?user=" +
          this.client.id +
          "&filename=" +
          filename +
          "&session=" +
          session[this.$v.SESSION];
        let imageUrl = null;
        await this.$axios.get(url).then((response) => {
          imageUrl = response.data;
        });
        return imageUrl;
      }
    },
    getPaymentFile(id) {
      if (id) {
        let url =
          this.$v.SERVER +
          this.$v.SELLER_OPS.GET_USER_PAYMENT_FILE +
          "?id=" +
          id;
        this.$axios.get(url).then((response) => {
          this.selectedPayment.fileurl = response.data;
          this.$forceUpdate();
        });
      }
    },
    onPaymentSelected(payment) {
      if(payment.filename){
        let typePayment=[];
        typePayment=payment.filename.split(".pdf");
        if(typePayment.length>1){
          this.pdfPayment = true;
          }
      }
      this.forceUpdate++;
      this.selectedPayment = payment;
      this.paymentModal = true;
    },
    addendumSelected(addendumID) {
      this.$router.push({ path: "/addendum" +"?user="+this.client.id + "&add=" + addendumID.id });
    },
    updatePayment(setPayment) {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_PAYMENT;
      let request;
      if (setPayment) {
        request = {
          id: this.selectedPayment.id,
          user: this.client.id,
          amount: this.selectedPayment.amount,
          date: this.selectedPayment.date,
        };
      } else {
        request = {
          user: this.client.id,
          id: this.selectedPayment.id,
          validated: !this.selectedPayment.validated,
        };
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                'Actualizado exitosamente!'
            ));
          if(!setPayment){
          this.selectedPayment.validated = !this.selectedPayment.validated;
          }
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro actualizar'
            ));
        }
      });
    },
    createFinamigoAccount() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.CREATE_FINAMIGO_ACCOUNT;
      this.$axios.post(url, { user: this.client.id }).then((response) => {
        let data = response.data;
        this.$showLoader(false);
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                'Cuentas creadas exitosamente!'
            ));
          this.getUserFiles(1);
          this.getUserFinamigo(1);
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro crear las cuentas'
            ));
        }
      });
    },
    setMoneyBack() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.ADMIN_OPS.SET_USER_MONEY_BACK;
      let request = {
        user: this.client.id,
        amount: this.newMoneyBack.amount,
        type: this.newMoneyBack.type.id
      };
      if (request.user && request.amount > 0) {
        this.$axios.post(url, request).then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.$showLoader(false);
            this.$vs.notification(this.$notification(
                'success',
                'Creado exitosamente!'
            ));
            this.newMoneyBackModal = false;
            this.newMoneyBack = {};
            this.getUserMoneyBack(1);
          } else {
            this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Intenta nuevamente'
            ));
          }
        });
      } else {
        this.$showLoader(false);
        this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Llena el monto y la fecha para continuar'
            ));
      }
    },
    createCommission() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.CREATE_COMMISSION;
      let request = {
        user: this.client.id,
        date: this.newCommission.date + "T12:00:00.000Z",
        amount: this.newCommission.amount,
      };
      if (request.user && request.date && request.amount > 0) {
        this.$axios.post(url, request).then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.$vs.notification(this.$notification(
                'success',
                'Creado exitosamente!'
            ));
            this.newCommissionModal = false;
            this.newCommission = {};
            this.getUserCommissions(1);
          } else {
            this.$vs.notification(this.$notification(
                'danger',
                'No se logro crear exitosamente!'
            ));
          }
        });
      } else {
        this.$showLoader(false);
         this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Llena el monto y la fecha para continuar'
            ));
      }
    },
    sublocalitySelected(sublocality) {
      this.client.addr_sublocality = sublocality;
      this.$forceUpdate();
    },
    clientCivilStatusSelected(civiStatus) {
      this.client.civil_status = civiStatus;
      this.$forceUpdate();
    },
    beneficiaryCivilStatusSelected(civiStatus) {
      this.beneficiary.civil_status = civiStatus;
      this.$forceUpdate();
    },
    comissionPercentage(commission) {
      this.contract.commission_percentage = commission.commission;
      this.$forceUpdate();
    },
    comissionEfectivePercentage(commissionEfe) {
      console.log('comisión normla');
      this.contract.effective_commission = commissionEfe.commission;
      this.$forceUpdate();
    },
    changeNewPaymentValidation() {
      this.newPayment.validated = !this.newPayment.validated;
      this.$forceUpdate();
    },
    resetPassword() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.RESET_USER_PASSWORD;
      this.$axios.post(url, { user: this.client.id }).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
           this.$vs.notification(this.$notification(
                'success',
                'Actualizada Correctamente!',
                'Se actualizo por default la contraseña del cliento'
            ));
        } else {
           this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se actualizo la contraseña'
            ));
        }
      });
    },
    hasCompleteProfile() {
      let isComplete =
        this.client.id &&
        this.client.lastname_1 &&
        this.client.lastname_2 &&
        this.client.name &&
        this.client.email &&
        this.client.birthday &&
        this.client.civil_status &&
        this.client.gender &&
        this.client.addr_sublocality &&
        this.client.addr_street &&
        this.client.addr_postal_code &&
        this.client.phone &&
        this.client.state_of_birth &&
        this.client.curp &&
        this.client.rfc &&
        this.client.id_number &&
        this.client.economic_activity &&
        this.client.beneficiary &&
        this.accountTableData.length > 0 &&
        //this.debtTableData.length > 0 &&
        /*
        (this.beneficiary.lastname_1 &&
        this.beneficiary.lastname_1.length>2) &&
        (this.beneficiary.lastname_2 &&
        this.beneficiary.lastname_2.length>2) &&
        (this.beneficiary.name &&
        this.beneficiary.name.length>2) &&
        this.beneficiary.name.indexOf('.')===-1 &&
        this.beneficiary.relationship &&
        this.beneficiary.birthday &&
        this.beneficiary.civil_status &&
        this.beneficiary.gender &&
        */
        this.contract.monthly_savings &&
        this.contract.payday >0 &&
        this.contract.first_payment &&
        this.contract.program_duration > 0 &&
        this.finamigo.bank_account != 'En proceso';
      if (isComplete !== undefined && isComplete !== null) {
        switch (typeof isComplete) {
          case "string":
          case "object":
            return isComplete.length > 0;
          case "boolean":
            return isComplete;
          default:
            return false;
        }
      }
      return false;
    },
    hasCompleteProfileForBuro() {
      let isComplete =
        this.client.id &&
        this.client.lastname_1 &&
        this.client.lastname_2 &&
        this.client.name &&
        this.client.birthday &&
        this.client.addr_sublocality &&
        this.client.addr_extnum &&
        this.client.addr_street &&
        this.client.addr_postal_code &&
        this.client.locality &&
        this.client.phone &&
        this.client.rfc;
      if (isComplete !== undefined && isComplete !== null) {
        switch (typeof isComplete) {
          case "string":
          case "object":
            return isComplete.length > 0;
          case "boolean":
            return isComplete;
          default:
            return false;
        }
      }
      return false;
    },
    canRequestCreditReport() {
      if (this.client.has_signature && this.client.for_credit_report)
        return false;
      if (
        this.finamigo &&
        this.finamigo.account &&
        this.finamigo.account.length > 0
      )
        return false;
      let b =
        this.client.id &&
        this.client.lastname_1 &&
        this.client.lastname_2 &&
        this.client.name &&
        this.client.birthday &&
        this.client.addr_sublocality &&
        this.client.addr_street &&
        this.client.addr_postal_code &&
        this.client.phone &&
        this.client.curp &&
        this.client.rfc;
      let id = false;
      let id_b = false;
      for (let i = 0; i < this.userFiles.length; i++) {
        let file = this.userFiles[i];
        if (file.type === "ID") id = true;
        if (file.type === "ID_B") id_b = true;
      }
      return b && id && id_b;
    },
    createDBMenosContract() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.CREATE_DBM_CONTRACT;
        this.$axios.post(url, { user: this.client.id }).then((response) => {
          setTimeout(() => {
        this.$showLoader(false);
        let data = response.data;
        console.log(data)

        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
              'success',
              'Contrato creado',
              'Se ha generado el contrato exitosamente'
          ))
          this.getUserFiles(1);
          this.getClientProfile(this.client.id);
        } else {
          this.$vs.notification(this.$notification(
            'danger',
            'Error al crear contrato',
            'Revisar: '+data.row
            
          ));
          this.getUserFiles(1);
        }
        }, 1000);
      });
      
    },
    canCreateDBMContract() {
      if (this.client && this.client.hasOwnProperty("dbm_contract")) {
        if (this.client.dbm_contract === 1 && !(this.isAdmin)) return false;
        let completeProfile = this.hasCompleteProfile();
        return (
          completeProfile &&
          this.finamigo !== undefined &&
          this.finamigo !== null &&
          this.finamigo.hasOwnProperty("account") &&
          this.finamigo.account.length > 0
        );
      }
      return false;
    },
       uploadTrace() {
      if (
        this.newTrace === undefined ||
        this.newTrace.comments == "" ||
        this.newTrace.status === 0 ||
        (this.newTrace.statusId === 16 && !this.newTrace.date_promise)
         ||
        (this.newTrace.statusId == 25 && this.sameBankNom == "")
        ||
        (this.newTrace.statusId == 25 && this.sameBankNom == 1 && this.selectedBanksNomina == 0)
      ) {
        console.log(this.newTrace.statusId)
        console.log(this.sameBankNom)
        console.log(this.selectedBanksNomina)
        this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Datos incompletos'
            ));
        return;
      }
      console.log(this.newTrace.statusId)
        console.log(this.sameBankNom)
        console.log(this.selectedBanksNomina)
      var json;
      if (this.tracingStatus === "Inactivar programa") {
        json = {
          user: this.client.id,
          comments: this.newTrace.comments,
          divisa: this.newTrace.divisa,
          created: this.newTrace.created,
        };
      } else {
        json = {
          user: this.client.id,
          comments: this.newTrace.comments,
        };
      }
      if (this.tracingStatus === "Inactivar programa")
        Object.assign(json, { value: this.newTrace.statusId });
      else if (this.tracingStatus === "Acciones cliente")
        Object.assign(json, { action: this.newTrace.statusId });
      else {
        if (this.newTrace.statusId === 16)
          Object.assign(json, { date_promise: this.newTrace.date_promise });
        Object.assign(json, { status: this.newTrace.statusId });
      } 
      let setType =
        this.tracingStatus === "Inactivar programa"
          ? this.$v.CONSULTANT_OPS.SET_INACTIVE_USER
          : this.tracingStatus === "Acciones cliente"
          ? this.$v.CONSULTANT_OPS.SET_USER_ACTION
          : this.tracingStatus === "Seguimiento de ventas"
          ? this.$v.SELLER_OPS.SET_SELL_TRACING
          : this.$v.SERVICE_OPS.SET_USER_TRACING;
      let url = this.$v.SERVER + setType;
      this.$showLoader(true);
      this.$axios.post(url, json).then((response) => {
        this.$showLoader(false);
        if (response.data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                'Creado exitosamente!'
            ));
          this.tracingModal = false;
          this.newTrace = {
            id: 0,
          };
          if (this.tracingStatus === "Inactivar programa"){
            if(json.value == 5)
              this.reactiveUser();
            this.getUserInactiveTableData(1);
          }
          else if (this.tracingStatus === "Acciones cliente")
            this.getUserActionsTableData(1);
          else if (this.tracingStatus === "Seguimiento de ventas")
            this.getTracingTableDataS(1);
          else this.getTracingTableDataCS(1);
        } else {
          
          this.$vs.notification(this.$notification(
                'danger',
                'No se pudo crear la tarea!'
            ));
        }
      });
    },
    reactiveUser(){
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.REACTIVE_USER;
      this.$showLoader(true);
      this.$axios.post(url, {user: this.client.id}).then((res) => {
        this.$showLoader(false);
        let data = res.data;
        if (res.data[this.$v.API_SUCCESS]) {

          this.$vs.notification(this.$notification(
                'success',
                'Reactivación exitosamente!'
            ));
          if(data.row.id){
          window.open("https://admin.dbmenos.com/client?id="+ data.row.id, '_blank')
          }
        }
      });
    },
    goToPreviousProfile(){
      this.$router.push({path: "/client" + "?id=" + this.reactivated});
      window.location.reload();
    },
    setTraceStatus(status) {
      this.newTrace.statusId = status.id;
      this.newTrace.status = status.status ? status.status : status.value;
      this.$forceUpdate();
    },
    setTraceType(type) {
      this.newTrace.id = type.id;
      this.newTrace.type = type.type;
      this.$forceUpdate();
    },
    closeAccountModal() {
      this.accountModal = false;
      this.newAccount = false;
      this.accountInModal = {};
      this.prevDel = false;
    },
    getBank(id) {
      for (let i = 0; i < this.banks.length; i++)
        if (this.banks[i].id === id) return this.banks[i];
      return null;
    },
    getCommissionPercentage(commission) {
      for (let i = 0; i < this.CommissionPercentages.length; i++)
        if (this.CommissionPercentages[i].commission === commission) return this.CommissionPercentages[i];
      return null;
    },
    getProductType(id) {
      for (let i=0 ; i<this.productType.length ; i++) {
        if (this.productType[i].id === id) {
          return this.productType[i].type
          }
        }
    },
    getTypeAddendum(id) {
      for (let i = 0; i < this.typeAddendums.length; i++)
        if (this.typeAddendums[i].id === id) return this.typeAddendums[i].type;
      return null;
    },
    setBankInAccount(bank) {
      this.accountInModal.bank = bank.id;
      this.$forceUpdate();
    },
    accountClick(acc) {
      this.accountDetailsModal = true;
      let saldo = acc.SaldoActual;
      if (saldo[saldo.length - 1] === "+" || saldo[saldo.length - 1] === "-")
        saldo = saldo[saldo.length - 1] + saldo.slice(0, -1);
      this.accountInModal = {
        ClaveOtorgante: acc.ClaveOtorgante,
        ClaveUnidadMonetaria: acc.ClaveUnidadMonetaria,
        CreditoMaximo: acc.CreditoMaximo,
        FechaActualizacion: acc.FechaActualizacion,
        FechaAperturaCuenta: acc.FechaAperturaCuenta,
        FechaHistoricaMorosidadMasGrave: acc.FechaHistoricaMorosidadMasGrave,
        FechaMasAntiguaHistoricoPagos: acc.FechaMasAntiguaHistoricoPagos,
        FechaMasRecienteHistoricoPagos: acc.FechaMasRecienteHistoricoPagos,
        FechaReporte: acc.FechaReporte,
        FechaUltimaCompra: acc.FechaUltimaCompra,
        FechaUltimoPago: acc.FechaUltimoPago,
        FormaPagoActual: acc.FormaPagoActual,
        HistoricoPagos: acc.HistoricoPagos,
        IdentificadorSociedadInformacionCrediticia:
          acc.IdentificadorSociedadInformacionCrediticia,
        ImporteSaldoMorosidadHistMasGrave:
          acc.ImporteSaldoMorosidadHistMasGrave,
        //IndicadorTipoResponsabilidad: acc.IndicadorTipoResponsabilidad,
        LimiteCredito: acc.LimiteCredito,
        ModoReportar: acc.ModoReportar,
        MontoPagar: acc.MontoPagar,
        MontoUltimoPago: acc.MontoUltimoPago,
        MopHistoricoMorosidadMasGrave: acc.MopHistoricoMorosidadMasGrave,
        NombreOtorgante: acc.NombreOtorgante,
        NumeroCuentaActual: acc.NumeroCuentaActual?acc.NumeroCuentaActual:acc.CuentaActual,
        NumeroPagos: acc.NumeroPagos,
        NumeroTelefonoOtorgante: acc.NumeroTelefonoOtorgante,
        SaldoActual: saldo,
        SaldoVencido: acc.SaldoVencido,
        //TipoContrato: acc.TipoContrato,
        //TipoCuenta: acc.TipoCuenta,
        accountsTableIndex: acc.accountsTableIndex,
        /*bank: {
          fullname: acc.bank.fullname,
          id: acc.bank.id,
          name: acc.bank.name,
          neg: acc.bank.neg
        }*/
      };
    },
    async accountSelected(acc) {
      this.accountModal = true;
      this.newAccount = false;
      this.accountInModal = {
        bank: acc.bank.id,
        account: acc.account,
        amount: acc.amount,
        discount: acc.discount,
        index: acc.accountsTableIndex,
        id: acc.id,
        payroll_same_bank: acc.payroll_same_bank,
        last_bank_payment_date: this.$moment(acc.last_bank_payment_date)._d,
        product_type: acc.product_type,
        will_pay: acc.will_pay,
        will_pay_wo_fee: acc.will_pay_wo_fee,
        will_pay_on: acc.will_pay_on,
        paid_file: acc.paid_file,
        fileurl: null,
        paid: acc.paid,
      };
      if (this.accountInModal.paid_file){
        this.accountInModal.fileurl = await this.getFileUrl(acc.paid_file);
        let type_paid_file = acc.paid_file.split('.');
        if(type_paid_file[type_paid_file.length - 1]=="pdf"){
          this.isPDF = true;
        }
        }
      this.getUserAccountTracing(1);
    },
    async accountSelectedforAddendum(acc) {
      this.accountAddendumModal = true;
      this.newAccount = false;
      this.accountInModal = {
        bank: acc.bank.id,
        account: acc.account,
        amount: acc.amount,
        discount: acc.discount,
        index: acc.accountsTableIndex,
        id: acc.id,
        payroll_same_bank: acc.payroll_same_bank,
        last_bank_payment_date: this.$moment(acc.last_bank_payment_date)._d,
        will_pay: acc.will_pay,
        will_pay_wo_fee: acc.will_pay_wo_fee,
        will_pay_on: acc.will_pay_on,
        paid_file: acc.paid_file,
        fileurl: null,
        paid: acc.paid,
      };
      if (this.accountInModal.paid_file)
        this.accountInModal.fileurl = await this.getFileUrl(acc.paid_file);
      this.getUserAccountTracing(1);
    },
    deleteAccount() {
      let url = this.$v.SERVER + this.$v.ADMIN_OPS.DELETE_ACCOUNT;
      this.$showLoader(true);
      this.$axios.post(url, { id: this.accountInModal.id }).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.getUserAccounts(1);
          this.getUserDebts(1);

          this.$vs.notification(this.$notification(
                'success',
                'Cuenta eliminada exitosamente!'
            ));
          this.closeAccountModal();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Intenta nuevamente'
            ));
        }
      });
    },
    getFinamigoBalance() {
      let url =
        this.$v.SERVER +
        this.$v.OPS.GET_FINAMIGO_BALANCE +
        "?user=" +
        this.client.id;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.client.finamigoBalance = data.row.balance;
          this.newActivityNegotiation.finamigo_balance = data.row.balance;
          this.$forceUpdate();
        }
      });
      this.showFinBalance = true;
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id)
          return this.sellers[i].name + " " + this.sellers[i].lastname_1;
      for (let i = 0; i < this.teams.length; i++)
        if (this.teams[i].id === id)
          return this.teams[i].name + " " + this.teams[i].lastname_1;
      for (let i = 0; i < this.managerService.length; i++)
        if (this.managerService[i].id === id)
          return this.managerService[i].name + " " + this.managerService[i].lastname_1;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return (
            this.customerService[i].name +
            " " +
            this.customerService[i].lastname_1
          );
      return "- - -";
    },
    settlementLetter(id) {
      let url =
        this.$v.SERVER +
        this.$v.SERVICE_OPS.REQUEST_ACCOUNT_SIGN +
        "?user=" + this.client.id +
        "&account=" + id;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                'Enlace envíado exitosamente!',
                'Firma para liquidación solicitada exitosamente'
            ));
          this.getSignaturesTableData(1); 
          this.closeAccountModal();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro enviar el enlace'
            ));
        }
      });
    },
    iconoFinAmigo(icono, status) {
      let color = null;
      switch (Number(status)) {
        case 1: color = "text-success"; break
        case 2: color = "text-info"; break
        default: color = "text-danger";
      } return icono + " " + color;
    },
    updateCreditReport() {
      this.$showLoader(true);
      let user = this.client;
      let url =
        this.$v.SERVER +
        this.$v.SELLER_OPS.UPDATE_CREDIT_REPORT +
        "?user=" +
        user.id;
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        if (response.data.success) {
          this.getCreditReport();
          this.$vs.notification(this.$notification(
                'success',
                'Actualizado exitosamente!',
                'Buro de crédito actualizado correctamente'
            ));
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                'Error al actualizar el buro!',
                'No se pudo actualizar exitosamente'
            ));
        }
      });
    },
    getCreditReport() {
      let url =
        this.$v.SERVER +
        this.$v.SERVICE_OPS.GET_CREDIT_REPORT +
        "?user=" +
        this.client.id;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.viewBC = true;
          this.report = data.row.credit_report
            ? data.row.credit_report
            : data.row.accounts;
          this.messageReport = data.row.message
            ? data.row.message
            : data.row.report.status;
          this.existReport = true;
          this.creditReport = data.row;
          this.typeModification = this.$v.MODIFICATIONS.CREDIT_REPORT;
          this.dateCreatedBC = data.row.report.created
          this.lengthsCreditReports.dbmAccounts = this.report.dbmAccounts.length;
          this.lengthsCreditReports.otherAccounts = this.report.otherAccounts.length;
          if(this.report.dbmAccounts.length==0 && this.report.otherAccounts.length==0){
            this.notAccounts= true;
          }
        } else {
          switch (data[this.$v.API_REASON]) {
            case 5:
              this.creditReportBad = true;
              this.viewBC = true;
              this.typeModification = this.$v.MODIFICATIONS.CREDIT_REPORT;
              this.existReport = false;
              break;
            default:
              this.existReport = false;
              this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Intenta nuevamente'
            ));
              break;
          }
        }
      });
    },
    setStatusClient(clientId){
      this.$showLoader(true)
         let url =
          this.$v.SERVER + this.$v.SERVICE_OPS.SET_STATUS_CLIENT
        this.$axios.post(url,{ user: clientId }).then((response) => {
           this.$showLoader(false)
           let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.duePaymentSum = response.data["montoAtrasado"]
            this.duePaymentCount = response.data["mesesAtraso"]
            let paymentDate = response.data["nextPayment"].nextPaymentDate;
            if(paymentDate != null ){
              if(paymentDate == ""){
                this.nextPaymentDate='NO DEFINIDA'
              }else{
              this.nextPaymentDate=paymentDate
              console.log(this.nextPaymentDate)
              }
            }else{
              this.nextPaymentDate='NO DEFINIDA'
            }
            this.statusClient = true;
            //this.getClientProfile(clientId)
            this.$forceUpdate();
          }
        });
    },
    getClientPaymentResume() {
      this.$moment.locale('es');
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_CLIENTS_TO_MANAGER;
      this.$axios.post(url, { id: this.client.id }).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
            this.$forceUpdate();
            this.clientPaymentResume = data.rows[0];
        } else {
          this.$validateSession(data);
        }
      });
    },
    getHistoryEvent(page) {
      let limit = this.historyPagination.perPage;
      let url =
        this.$v.SERVER +
        this.$v.SELLER_OPS.GET_USER_HISTORY +
        "?offset=" +
        limit * (page - 1) +
        "&limit=" +
        limit +
        "&user=" +
        this.client.id;
      if (!this.isAdmin) url += "&consultant=" + this.user.id;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.historyTableData = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.historyPagination.total = p["totalItems"];
          this.historyPagination.currentPage = page;
          this.historyPagination.perPage = limit;
        }
      });
    },
    getAuditUser(page) {
      let limit = this.auditPagination.perPage;
      let url =
        this.$v.SERVER +
        this.$v.CONSULTANT_OPS.GET_USER_AUDITOR ;
        let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.auditTableData = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.auditPagination.total = p["totalItems"];
          this.auditPagination.currentPage = page;
          this.auditPagination.perPage = limit;
          this.verifyToSendAlert();
          this.validateDocsMenu();
        }
      });
    },
    validateDocsMenu(){
      if(this.auditTableData[0].if_id == 1||this.auditTableData[0].if_id === 'OK'){
        let index = this.fileTypes.indexOf('ID');
        if(index !== -1) this.fileTypes.splice(index,1)
      }
      if(this.auditTableData[0].ir == 1||this.auditTableData[0].ir === 'OK'){
        let index = this.fileTypes.indexOf('ID_B');
        if(index !== -1) this.fileTypes.splice(index,1)
      }
      if(this.auditTableData[0].curp == 1||this.auditTableData[0].curp === 'OK'){
        let index = this.fileTypes.indexOf('CURP');
        if(index !== -1) this.fileTypes.splice(index,1)
      }
      if(this.auditTableData[0].cd == 1||this.auditTableData[0].cd === 'OK'){
        let index = this.fileTypes.indexOf('ADDRESS');
        if(index !== -1) this.fileTypes.splice(index,1)
      }
    },
    verifyToSendAlert(){
      let a = this.auditTableData[0];
      if(a.if_id == -1 || a.ir == -1 || a.rfc == -1 || a.cd == -1 || a.curp == -1 || a.rc == -1 || a.prog == -1 || a.c1 == -1 || a.c2 == -1 || a.ca == -1) {
        if ((a.if_id == -1 || a.if_id == this.searchOKID(this.auditorCatalog.IF))
            && (a.ir == -1 || a.ir == this.searchOKID(this.auditorCatalog.IR))
            && (a.rfc == -1 || a.rfc == this.searchOKID(this.auditorCatalog.RFC))
            && (a.cd == -1 || a.cd == this.searchOKID(this.auditorCatalog.CD))
            && (a.curp == -1 || a.curp == this.searchOKID(this.auditorCatalog.CURP))
            && (a.rc == -1 || a.rc == this.searchOKID(this.auditorCatalog.RC))
            && (a.prog == -1 || a.prog == this.searchOKID(this.auditorCatalog.PROG))
            && (a.c1 == -1 || a.c1 == this.searchOKID(this.auditorCatalog.C1))
            && (a.c2 == -1 || a.c2 == this.searchOKID(this.auditorCatalog.C2))
            && (a.ca == -1 || a.ca == this.searchOKID(this.auditorCatalog.CA))) {

          let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.SEND_AUDIT_ALERT_FOR_CHANGES;
          this.$axios.post(url, {user: this.client.id}).then((response) => {
            this.$showLoader(false);
            let data = response.data;
            if (data[this.$v.API_SUCCESS]) {
              this.$vs.notification(this.$notification(
                'success',
                'Contacto con auditoría exitoso!'
            ));
            }
          });
        } else console.log('Pendientes de corregir')
      }
    },
    searchOKID(cat){
      for (let i=0; i<cat.length; i++){
        if(cat[i].value==='OK') return cat[i].id
      }
    },
    getTracingTableDataCS(page) {
      let limit = this.statusTracingPaginationCS.perPage;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_USER_TRACING;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.statusTracingTableDataCS = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.statusTracingPaginationCS.total = p["totalItems"];
          this.statusTracingPaginationCS.currentPage = page;
          this.statusTracingPaginationCS.perPage = limit;
        }
      });
    },
    getTracingTableDataS(page) {
      let limit = this.statusTracingPaginationS.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_SELL_TRACING; // + "?offset=" + limit*(page - 1) + "&limit=" + limit + "&user=" + this.client.id
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      if(this.isSeller) request.upload_by = this.user.id
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.statusTracingTableDataS = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.statusTracingPaginationS.total = p["totalItems"];
          this.statusTracingPaginationS.currentPage = page;
          this.statusTracingPaginationS.perPage = limit;
        }
      });
    },
    getTracingTableDataB(page) {
      let limit = this.statusTracingPaginationB.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.SELLER_GET_SELL_TRACING_BOT; // + "?offset=" + limit*(page - 1) + "&limit=" + limit + "&user=" + this.client.id
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.statusTracingTableDataB = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.statusTracingPaginationB.total = p["totalItems"];
          this.statusTracingPaginationB.currentPage = page;
          this.statusTracingPaginationB.perPage = limit;
        }
      });
    },
    getUserActionsTableData(page) {
      let limit = this.userActionsPagination.perPage;
      let url =
        this.$v.SERVER +
        this.$v.CONSULTANT_OPS.GET_USER_ACTIONS +
        "?offset=" +
        limit * (page - 1) +
        "&limit=" +
        limit +
        "&user=" +
        this.client.id;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.userActionsTableData = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.userActionsPagination.total = p["totalItems"];
          this.userActionsPagination.currentPage = page;
          this.userActionsPagination.perPage = limit;
        }
      });
    },
    getInfoReactiveUser() {
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_INFO_REACTIVE_USER;
      this.$showLoader(true);
      this.$axios.post(url, {reactive_id: this.client.id}).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]){
          this.reactivationsTableData.push(data[this.$v.API_ROW]);
          this.reactivated = data[this.$v.API_ROW].id;
        }
      });
    },
    getUserInactiveTableData(page) {
      let limit = this.userInactivePagination.perPage;
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_INACTIVE_USER;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.userInactiveTableData = data[this.$v.API_ROWS];
          //if(this.userInactiveTableData[0].value === 5) this.reactivated = true;
          let p = data[this.$v.API_ROW];
          this.userInactivePagination.total = p["totalItems"];
          this.userInactivePagination.currentPage = page;
          this.userInactivePagination.perPage = limit;
        }
      });
    },
    getMovementsTableData(page) {
      let limit = this.movementsPagination.perPage;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_USER_MOVEMENTS;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        id: this.client.id,
      };
      if (this.movTypeFilter)
        Object.assign(request, { type: this.movTypeFilter });
      if (this.validatedPaymentsFilter !== null)
        request.validated = this.validatedPaymentsFilter;
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.movementsTableData = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.movementsPagination.total = p["totalItems"];
          this.movementsPagination.currentPage = page;
          this.movementsPagination.perPage = limit;
        }
      });
    },
      getFeeMovementsTableData(page) {
      let limit = this.feemovementsPagination.perPage;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_USER_MOVEMENTS;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        id: this.client.id,
        type:'commission'
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.feemovementsTableData = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.feemovementsPagination.total = p["totalItems"];
          this.feemovementsPagination.currentPage = page;
          this.feemovementsPagination.perPage = limit;
        }
      });
    },
    getSignaturesTableData(page) {
      let limit = this.movementsPagination.perPage;
      let url = this.$v.SERVER + this.$v.ADMIN_OPS.GET_USER_SIGNATURE;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.signaturesTableData = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.signaturesPagination.total = p["totalItems"];
          this.signaturesPagination.currentPage = page;
          this.signaturesPagination.perPage = limit;
        }
      });
    },
    SendAccountStatement() {
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.CREATE_ACCOUNT_STATEMENT;
      this.$showLoader(true);
      let request = {
        user: this.client.id,
      };
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                'Estado de cuenta envíado'
            ));
        }
      });
    },
    setMovTypeFilter(type) {
      this.movTypeFilter = type;
      this.getMovementsTableData(1);
    },
    setValidatedPaymentsFilter(value) {
      this.validatedPaymentsFilter = value;
      this.getMovementsTableData(1);
    },
    /*getTitleAndWidthProgressBar() {
      let titleP = "PROGRESO FALTANTE DEL CLIENTE:\n";
      if (!this.client.step_basic_information)
        titleP += "-Información básica.\n";
      else this.widthProgressBar = "width: 10%";
      if (!this.client.step_basic_information2)
        titleP += "-Información básica 2.\n";
      else this.widthProgressBar = "width: 20%";
      if (!this.client.step_address_information) titleP += "-Domicilio.\n";
      else this.widthProgressBar = "width: 30%";
      if (!this.client.step_official_id) titleP += "-Identificación oficial.\n";
      else this.widthProgressBar = "width: 40%";
      if (!this.client.step_credit_report_signature)
        titleP += "-Firma reporte de crédito.\n";
      else this.widthProgressBar = "width: 50%";
      if (!this.client.step_credit_report) titleP += "-Reporte de crédito.\n";
      else this.widthProgressBar = "width: 60%";
      if (!this.client.step_program_calculation)
        titleP += "-Programa de cálculo.\n";
      else this.widthProgressBar = "width: 70%";
      if (!this.client.step_complete_profile) titleP += "-Perfil completo.\n";
      else this.widthProgressBar = "width: 80%";
      if (!this.client.step_contract) titleP += "-Contrato.\n";
      else this.widthProgressBar = "width: 90%";
      if (!this.client.step_active) titleP += "-Activo.";
      else this.widthProgressBar = "width: 100%";
      this.titleProgressBar = titleP;
    },*/
    getMessageReport(message) {
      if (message.length > 50) return "Contactar con soporte";
      return message;
    },
    showCalcClick() {
      let dbmAccounts = this.report["dbmAccounts"];
      let newDebts = new Array();
      let saldo;
      for (let i = 0; i < dbmAccounts.length; i++) {
        saldo = dbmAccounts[i].SaldoActual;
        if (
          saldo[saldo.length - 1] === "+" ||
          saldo[saldo.length - 1] === "-"
        ) {
          saldo = saldo[saldo.length - 1] + saldo.slice(0, -1);
        }
        newDebts.push({
          bankid: dbmAccounts[i].bank.id,
          bank: dbmAccounts[i].bank.name,
          amount: saldo,
        });
      }
      let request = {
        name: this.client.name,
        lastname: this.client.lastname_1,
        period: this.period,
        debts: newDebts,
      };
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.OPS.CALCULATE;
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.dbmAccountsCalculations = data.row;
          this.setCalc(this.dbmAccountsCalculations);
          this.calcModal = true;
        }
      });
    },
    setTypeMessage(type) {
      this.typeTheMessage = type;
      this.sendMessageModal = true;
    },
    setCalc(calculos) {
      this.calculos = calculos;
    },
    calculator() {
      this.$router.push({
        path: "/calculator" + "?id=" + this.client.id,
        query: null,
      });
      this.setCalc(this.clientCalculations);
    },
    callUser(){
      let url = this.$v.SERVER + this.$v.SELLER_OPS.SEND_STARTING_CALL_PUSH_NOTIFICATION;
      this.$showLoader(true);
      let request={
        "user": this.client.id,
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.$vs.notification(this.$notification(
                'success',
                'Llamando al Cliente'
            ));
          }else{
            this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Intenta nuevamente'
            ));
          }
      });
    },
    debtSelected(debt) {
      if(!this.isAuditor) this.$router.push({ path: "/viewDebt" + "?debt=" + debt.id_debt });
    },
    //SPEI_OUT
    payDebt(debt){
      this.newSpeiOut.AccountBeneficiary = debt;
      this.payDebtModal=true;
    },
    applySpeiOut(){
      this.newSpeiOut.user = this.client.id;
      if(!this.newSpeiOut.user || !this.newSpeiOut.AccountBeneficiary || !this.newSpeiOut.Beneficiary
        || !this.newSpeiOut.Amount || !this.newSpeiOut.Institution){
          this.$vs.notification(this.$notification(
                'danger',
                'Datos inválidos!'
            ));
      }
      this.newSpeiOut.Institution = this.newSpeiOut.Institution.key_bank;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SEND_SPEI_OUT;
      this.$showLoader(true);
      this.$axios.post(url, this.newSpeiOut).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          let res = data[this.$v.API_ROW];
          console.log(res)
          this.$vs.notification(this.$notification(
                'success',
                'Cargado exitosamente!'
            ));
          this.$forceUpdate();
        }else {
          this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Intenta nuevamente'
            ));
        }
      });
    },

    typeTracingTable(type) {
      this.newTrace.statusId = null;
      this.tracingModal = true;
      this.tracingStatus = type;
      this.newTrace.status = null;
    },
    setNewActivityNegotiation(activity) {
      this.newActivityNegotiation.activity = activity.id;
      this.newActivityNegotiation.describe = activity.describe;
      this.$forceUpdate();
    },
    setNewActivityNegotiationFirm(firm) {
      this.newActivityNegotiation.firm = firm.name;
      this.newActivityNegotiation.firm_phone = firm.phone_1;
      this.$forceUpdate();
    },
    //---Modifications
    setStatusAccount(accId){
      let url = this.$v.SERVER + this.$v.MANAGER_OPS.SET_STATUS_ACCOUNT;
      this.$showLoader(true);
      this.$axios.post(url, {account: accId}).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.$vs.notification(this.$notification(
                'success',
                'Modificación exitosamente!'
            ));
          this.getUserAccounts(1);
          this.getUserDebts(1);
          this.$forceUpdate();
        }
      });
    },
    newModification(data,typeMod,status){
      this.statusSignature = status;
      if(typeMod != this.$v.MODIFICATIONS.CREDIT_REPORT){
        this.modificationType = typeMod.name
        this.modificationModal = true;
        this.modificationData.last_id = data.id;
        this.modificationData.account = data.account;
        this.modificationData.type = typeMod.id;
        this.modificationData.user = data.user;
        this.modificationData.file_type = data.type;
          if(typeMod.id != 1){
            this.modificationData.filename = data.filename;
          }else{
            this.modificationData.filename = data.paid_file;
          }
          delete data.accountsTableIndex;
          delete data.id;
          delete data.user;
          delete data.type;
          delete data.filename;
          this.modificationData.json_details = JSON.parse(JSON.stringify(data));
      }else{
      this.modificationType = typeMod.name
      this.modificationModal = true;
      this.modificationData.type = typeMod.id;
      this.modificationData.user = this.client.id;
      delete data.accountsTableIndex;
      delete data.id;
      delete data.user;
      delete data.type;
      delete data.filename;
      this.modificationData.json_details = JSON.parse(JSON.stringify(data));
      }
    },
   setDeleteHistory(){
      let url = this.$v.SERVER + this.$v.MANAGER_OPS.SET_DELETE_HISTORY;
      this.$showLoader(true);
      this.$axios.post(url, this.modificationData).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          switch (this.modificationData.type){
            case 1: this.getUserAccounts(1); 
            if (this.client.contract > 0) {
              this.updateDelete = true;
              this.$forceUpdate();
            }else{
              this.$forceUpdate();
            }
            break
            case 2: this.getUserFiles(1); 
            this.hasCompleteProfile();
            this.$forceUpdate();
            break
            case 3: this.getUserPayments(1); break
            case 4: console.log('Contrato'); break
            case 5: 
            if(this.statusSignature==1){
              this.getSignaturesTableData(1);
              break;
            }else if(this.statusSignature==2){
              this.getSignaturesTableData(1);
              this.requestResendSignatureContract();
            }else{
            this.getSignaturesTableData(1);
            this.requestSignatureContract()
            }
            break;
            case 6:
            if(this.statusSignature==1 || this.statusSignature ==2){
              this.getSignaturesTableData(1);
              break;
            }else{
              this.getSignaturesTableData(1); 
              this.settlementLetter(this.modificationData.account)
              break;
            }
            case 7:
            if(this.statusSignature==1 || this.statusSignature ==2){
              this.getSignaturesTableData(1);
              break;
            }else{
              this.getSignaturesTableData(1);
              this.sendSignatureNomina();
              break;
            } 
            case 8: 
            if(this.statusSignature==1 || this.statusSignature ==2){
              this.getSignaturesTableData(1);
              break;
            }else{
            this.getSignaturesTableData(1); 
            this.sendSignatureAddendum(this.modificationData.json_details.addendum)
            break;
            } 
          }
          this.$vs.notification(this.$notification(
                'success',
                'Eliminación exitosamente!'
            ));
            this.$forceUpdate();
        }
      });
      this.modificationModal = false;
      this.modoficationData = null;
    },
    setDeleteCreditReport(){
      let url = this.$v.SERVER + this.$v.MANAGER_OPS.SET_DELETE_CREDIT_REPORT;
      this.$showLoader(true);
      this.$axios.post(url, this.modificationData).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          switch (this.modificationData.type){
            case 10: 
            this.getCreditReport();
            this.$forceUpdate();
          break
          }
          this.$vs.notification(this.$notification(
                'success',
                'Eliminación exitosamente!'
            ));
            this.$forceUpdate();
        }
      });
      this.modificationModal = false;
      this.modoficationData = null;
    },
    getFAStatementAccount(startDate, endDate){
      if(!startDate || !endDate){
        this.$vs.notification(this.$notification(
                'warning',
                'Periodo inválido!',
                'Ingresa un periodo valido para poder generar el estado de cuenta.'
            ));
        return
      }
      let request = {
        user: this.client.id,
        startDate: startDate,
        endDate: endDate
      }
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_FINAMIGO_STATEMENT_ACCOUNT;
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.FAStatementAccountData = data[this.$v.API_ROWS];
          this.$forceUpdate();
        }else {
          this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'Intenta nuevamente'
            ));
        }
      });
    },
  },
};
</script>
<style></style>