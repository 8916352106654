<template>
    <div>
        <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col" v-bind:style="{cursor:pointer2}">
                    <account-editor-table
                            title="Editor de cuentas"
                            :data="tableData"			
							:pagination="pagination"
							v-on:pageSelected="getEditableAccounts"/>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import AccountEditorTable from './Tables/AccountEditorTable'
export default {
    name: 'tables',
    components: {
        AccountEditorTable
    },
    mounted() {
        this.getEditableAccounts(1)
    },
    computed: {
        user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
    },
    data() {
        return {
            pagination: {
                total: 0,
                perPage: 10,
                currentPage: 1
            },
            tableData: [],
            pointer: 'pointer',
            pointer2: 'default'
        };
    },
    methods: {
        getEditableAccounts(page) {
            let limit = this.pagination.perPage
            let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_EDITABLE_ACCOUNTS + "?offset=" + limit*(page - 1) + "&limit=" + limit
            this.$showLoader(true)
            this.$axios.get(url).then(response => {
                this.$showLoader(false)
                let data = response.data
                if (data[this.$v.API_SUCCESS]) {
                    this.tableData = data[this.$v.API_ROWS]
                    let p = 1//data[this.$v.API_ROW]
                    this.pagination.total = p//p['totalItems']
                    this.pagination.currentPage = page
                    this.pagination.perPage = limit
                } else { this.$validateSession(data) }
            })
        },
    }
};
</script>
<style></style>