<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<balance-visor-table
							title="Visor de saldos"
							:data="tableData"
							:pagination="pagination"
							v-on:pageSelected="getDataList"/>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import BalanceVisorTable from './Tables/BalanceVisorTable'
	export default {
		name: 'tables',
		components: {
			BalanceVisorTable
		},
		mounted() {
			this.getDataList(1)
		},
		computed: {
			user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
		},
		data() {
			return {
				pagination: {
					total: 0,
					perPage: 10,
					currentPage: 1
				},
				tableData: [],
				pointer: 'pointer',
				pointer2: 'default'
			};
		},
		methods: {
			getDataList(page) {
				let limit = this.pagination.perPage
				let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_MASSIVE_BALANCES //+ "?offset=" + limit*(page - 1) + "&limit=" + limit
				let request ={
					offset: limit*(page - 1),
					limit: limit
				}
				this.$showLoader(true)
				this.$axios.post(url,request).then(response => {
					this.$showLoader(false)
					let data = response.data
					if (data[this.$v.API_SUCCESS]) {
						this.tableData = data[this.$v.API_ROWS]
						let p = data[this.$v.API_ROW]
						this.pagination.total = p['totalItems']
						this.pagination.currentPage = page
						this.pagination.perPage = limit
					} else { this.$validateSession(data) }
				})
			},
		}
	};
</script>
<style></style>