<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                 <div v-if="(isAdmin || isManagerService) && !unassigned" class="col-lg-1 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getNominaFilterText()}}
                        </base-button>
                        <a class="dropdown-item" @click="setNominaFilter(null)">Todos</a>
                        <a class="dropdown-item" @click="setNominaFilter('1')">Clientes con Nómina</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="info" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{statusFilter ? statusFilter : "Seguimiento"}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setStatusFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setStatusFilter('Sin seguimiento')">Sin seguimiento</a>
                        <a class="dropdown-item" v-on:click="setStatusFilter(status.status)" v-for="(status, index) in leadStatus" :key="index">{{status.status}}</a>
                    </base-dropdown>
                </div>
                <div v-if="!isSeller" class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getTypeClients()}}
                        </base-button>
                        <a class="dropdown-item" @click="typeClients('')">Todos</a>
                        <a class="dropdown-item" @click="typeClients('Prospecto')">Prospectos</a>
                        <a class="dropdown-item" @click="typeClients('Activo')">Activos</a>
                        <a class="dropdown-item" @click="typeClients('Graduado')">Graduados</a>
                        <a class="dropdown-item" @click="typeClients('Baja')">Baja</a>
                        <a v-if="isAdmin" class="dropdown-item" @click="typeClients('Reactivado')">Reactivado</a>
                    </base-dropdown>
                </div>
                <!--div class="col-lg-2 text-center" v-if="isAdmin || isSeller || isManagerSeller">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getTypeClientStep()}}
                        </base-button>
                        <a class="dropdown-item" @click="typeClientsStep('')">Todos</a>
                        <a class="dropdown-item" @click="typeClientsStep('dont_step')">Sin progreso</a>
                        <a class="dropdown-item" @click="typeClientsStep('step_basic_information')">Información Básica</a>
                        <a class="dropdown-item" @click="typeClientsStep('step_basic_information2')">Información Básica 2</a>
                        <a class="dropdown-item" @click="typeClientsStep('step_address_information')">Información de Domicilio</a>
                        <a class="dropdown-item" @click="typeClientsStep('step_official_id')">Credencial Oficial</a>
                        <a class="dropdown-item" @click="typeClientsStep('step_credit_report_signature')">Autorización de Reporte de Crédito</a>
                        <a class="dropdown-item" @click="typeClientsStep('step_credit_report')">Reporte de Crédito Generado </a>
                        <a class="dropdown-item" @click="typeClientsStep('step_program_calculation')">Calculadora del Programa</a>
                        <a class="dropdown-item" @click="typeClientsStep('step_complete_profile')">Perfil Completo</a>
                        <a class="dropdown-item" @click="typeClientsStep('step_contract')">Contrato</a>
                        <a class="dropdown-item" @click="typeClientsStep('step_active')">Activo</a>
                    </base-dropdown>
                </div-->
                <div hidden class="col text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="info" class="dropdown-toggle" outline size="sm" style="width: 100%">
                            {{filter ? filter==='null' ? "Sin contrato" : "Con contrato" : "Todos los clientes"}}
                        </base-button>
                        <a class="dropdown-item" href="#" v-on:click="setFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" href="#" v-on:click="setFilter('notnull')">Clientes con contrato</a>
                        <a class="dropdown-item" href="#" v-on:click="setFilter('null')">Usuarios sin contrato</a>
                    </base-dropdown>
                </div>
                <div v-if="(isAdmin || isManagerService || isManagerSeller) && !unassigned" class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" style="width: 100%" size="sm">
                            {{getCustomerFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setConsultantFilter(null)">Mostrar todos</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" v-for="(seller) in sellers" :key="seller.id" v-on:click="setConsultantFilter(seller.id)">{{seller.name}} {{seller.lastname_1}}</a>
                        <div class="dropdown-divider"></div>
                        <a v-for="(customerS) in customerService" :key="customerS.id" class="dropdown-item" v-on:click="setConsultantFilter(customerS.id)">{{customerS.name}} {{customerS.lastname_1}}</a>
                    </base-dropdown>
                </div>
                <div v-if="(isSeller || isAdmin) && !unassigned" class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getConsultantFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setSelfClientsFilter(true)">Mis clientes</a>
                        <!-- <a class="dropdown-item" v-on:click="setSelfClientsFilter(false)">Clientes sin asingar</a> -->
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getPeriodFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
                        <div v-if="!showSetPeriod" class="dropdown-divider"></div>
                        <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
                    </base-dropdown>
                </div>                
                <div v-if="showSetPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
                        <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
                    </div>
                </div>
                <div class="col-lg-2 text-right">
                    <base-dropdown>
                        <base-button slot="title" type="primary" style="width: 100%" size="sm" @click="newClient()">
                            Nuevo
                        </base-button>
                    </base-dropdown>
                </div>
            </div>
        </div>
       
        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        :period="period">

                <template slot="columns">
                  <th v-bind:style="{cursor:pointer2}">ID</th>
                  <th v-bind:style="{cursor:pointer2}">Nombre</th>
                  <!--<th v-bind:style="{cursor:pointer2}">Email</th>!-->
                  <!--<th v-bind:style="{cursor:pointer2}" class="text-center">Teléfono</th>!-->
                  <th v-bind:style="{cursor:pointer2}" class="text-center">Fecha de registro</th>
                  <th v-bind:style="{cursor:pointer2}">Seguimiento</th>
                  <th v-if="!isSeller" v-bind:style="{cursor:pointer2}">Estatus</th>
                  <th v-bind:style="{cursor:pointer2}">Progreso</th>
                  <!-- <th v-if="isAdmin" class="text-center">Saldo</th>
                  <th v-if="isAdmin" class="text-center">Depósitos</th>
                  <th v-if="isAdmin" class="text-center">Comisiones</th>-->
                  <th v-bind:style="{cursor:pointer2}" v-if="isAdmin || isManagerService || isManagerSeller" class="text-center">Consultor</th>
                </template>

                <template slot-scope="{row}">
                    <th scope="row" >
                        <div class="media align-items-center">
                            <div class="media-body static">
                                <router-link v-if="isSeller" target="_blank" :to="'/clientSeller'+'?id=' + row.id">
                                    <span class="name mb-0 text-sm">{{row.id}}<i class="fas fa-user-edit text-primary"></i></span>
                                </router-link>
                                <router-link v-else target="_blank" :to="'/client'+'?id=' + row.id">
                                    <span class="name mb-0 text-sm">{{row.id}}<i class="fas fa-user-edit text-primary"></i></span>
                                </router-link>
                            </div>
                        </div>
                    </th>
                    <td>{{row.name}}</td>
                    <!--<td>{{row.email}}</td>!-->
                    <!--<td class="text-center"><span>{{row.phone}}</span></td>!-->
                    <td class="text-center">{{formatDate(row.created)}}</td>
                    <td>{{row.trace_status}}</td>
                    <td v-if="!isSeller">{{row.status? row.status:'---'}}</td>
                    <td>{{getUserProgress(row)}}</td>
                    <!--<td v-if="isAdmin" class="text-center">{{$formatCurrency(row.balance)}}</td>
                    <td v-if="isAdmin" class="text-center">{{row.payment_count > 0 ? $formatCurrency(row.total_savings) + " (" + row.payment_count + ")" : "$ 0.00"}}</td>
                    <td v-if="isAdmin" class="text-center">{{row.commision_count > 0 ? $formatCurrency(row.total_commissions) + " (" + row.commision_count + ")": "$ 0.00"}}</td> -->
                    <td v-if="isAdmin || isManagerService" class="text-center" v-on:click.stop="">
                        <base-dropdown class="dropdown">
                            <a slot="title" class="btn btn-sm" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {{row.consultant_name}}
                            </a>
                            <template>
                                <a v-for="(seller) in sellers" :key="seller.id" class="dropdown-item" v-on:click="assignToConsultant(row, seller)">{{seller.name}} {{seller.lastname_1}}</a>
                                <div class="dropdown-divider"></div>
                                <a v-for="(customerS) in customerService" :key="customerS.id" class="dropdown-item" v-on:click="assignToConsultant(row, customerS)">{{customerS.name}} {{customerS.lastname_1}}</a>
                            </template>
                        </base-dropdown>
                    </td>
                    <td v-if="isManagerSeller" class="text-center" v-on:click.stop="">
                                {{row.consultant_name}}
                    </td>

                </template>

            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} clientes
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    export default {
        name: 'clients-table',
        components:{
            flatPickr
        },
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            unassigned: Boolean,
            period: {
                start: null,
                end: null
            },
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService },
            leadStatus() { return this.$store.state.leadStatus },
            userHistoryEvents() { return this.$store.state.userHistoryEvents }
        },
        data() {
            return {
              isSeller: false,
              isAdmin: false,
              tableData: [],
              filter: null,
              consultantFilter: null,
              showSetPeriod:false,
              periodFilter: null,
              statusFilter: null,
              selfClients: true,
              typeUsers: '',
              textMDIFilter: "",
              textNominaFilter: "",
              widthProgressBar: null,
              titleProgressBar: "",
              pointer: 'pointer',
              pointer2: 'default',
              isAuditor: false,
              eventFilter: null,
              typeUserStep:null,
              isManagerSeller:false
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
            this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER
            this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
        },
        methods: {
            formatDate(date) {
            if (date <= "2020-11-10") {
                return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
            } else {
                return this.$moment(date).format("DD MMM YYYY, h:mm a");
            }
            },
            typeClients(typeC){
                this.typeUsers=typeC
                this.$emit("typeClients", typeC);
            },
            typeClientsStep(typeC){
                this.typeUserStep=typeC
                this.$emit("typeClientsStep", typeC);
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            clientSelected(client) {
                this.$emit("clientSelected", client);
            },
            newClient() {
                this.$emit("newClient");
            },
            setFilter(value) {
                this.filter = value
                this.$emit("setFilter", value);
            },
            getNominaFilterText(){
                if(!this.textMDIFilter) return "Nómina"
                else return this.textMDIFilter
            },
            setConsultantFilter(value) {
                this.consultantFilter = value
                this.$emit("setConsultantFilter", value);
            },
            setSelfClientsFilter(value) {
                this.selfClients = value
                this.$emit("setSelfClientFilter", value);
            },
            getCustomerFilterText() {
                if (this.consultantFilter) return this.getConsultantName(this.consultantFilter)
                return "Consultores"
            },
            getConsultantFilterText() {
                if (this.selfClients) return 'Solo mis clientes'
                return "Clientes sin asignar"
            },
            getIconValidatedClass(validated) {
                if (validated) return "fas fa-check text-success"
                return "fas fa-clock text-info"
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            },
            setStatusFilter(value) {
                this.statusFilter = value
                this.$emit("setStatusFilter", value);
            },
            setNominaFilter(value) {
                this.textNominaFilter = value
                this.$emit("setNominaFilter", value);
            },
            assignToConsultant(profile, consultant) {
                let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.ASSIGN_USER_SERVICE
                this.$showLoader(true)
                this.$axios.post(url, {
                    user: profile.id,
                    consultant: consultant.id
                }).then(response => {
                    this.$showLoader(false)
                    profile.consultant = consultant.id
                    let data = response.data
                    if (data[this.$v.API_SUCCESS]) {
                        this.$vs.notification(this.$notification(
                        'success',
                        'Asignación exitosa'
                        ));
                        this.pageSelected(1);
                        this.$forceUpdate()
                    }
                })
            },
             setPeriod(){
                this.showSetPeriod=true
                this.period.start=null
                this.period.end=null
                this.getPeriodFilterText()                
            },
            setPeriodFilter(value) {        
                if(value!=2){
                    this.showSetPeriod = false
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                    
                }else if(this.period.start && this.period.end){
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                }
                
            },
            getPeriodFilterText() {
                if(this.showSetPeriod)
                    return "Periodo del: "
                else if(this.periodFilter === 1)
                    return "Mes actual"
                else return "Fecha"
            },
            getTypeClients(){
                switch (this.typeUsers) {
                    case 'Prospecto': return 'Prospectos'
                    case 'Activo': return 'Activos'
                    case 'Graduado': return 'Graduados'
                    case 'Baja': return 'Bajas'
                    case 'Reactivado': return 'Reactivados'
                    default: return 'Todos'
                }
            },
             /*getTypeClientStep(){
                switch (this.typeUserStep) {
                    case 'dont_step': return 'Sin Progreso'
                    case 'step_basic_information': return 'Información Básica'
                    case 'step_basic_information2': return 'Información Básica 2'
                    case 'step_address_information': return 'Información Domicilio'
                    case 'step_official_id': return 'Credencial Oficial'
                    case 'step_credit_report_signature': return 'Autorización Reporte de Crédito'
                    case 'step_credit_report': return 'Reporte de Crédito Generado'
                    case 'step_program_calculation': return 'Calculadora del Programa'
                    case 'step_complete_profile': return 'Perfil Completo'
                    case 'step_contract': return 'Contrato'
                    case 'step_active': return 'Activo'
                    default: return 'Progreso'
                }
            },*/
            /*getTitleAndWidthProgressBar(row,value){
                let titleP="PROGRESO FALTANTE DEL CLIENTE:\n"
                let widthProgressBar = ""
                if(!row.step_basic_information){
                    widthProgressBar = "width: 0%"
                    titleP+="-Información básica.\n"
                }else widthProgressBar = "width: 10%"
                if(!row.step_basic_information2) titleP+="-Información básica 2.\n"
                else widthProgressBar = "width: 20%"
                if(!row.step_address_information) titleP+="-Domicilio.\n"
                else widthProgressBar = "width: 30%"
                if(!row.step_official_id) titleP+="-Identificación oficial.\n"
                else widthProgressBar = "width: 40%"
                if(!row.step_credit_report_signature) titleP+="-Firma reporte de crédito.\n"
                else widthProgressBar = "width: 50%"
                if(!row.step_credit_report) titleP+="-Reporte de crédito.\n"
                else widthProgressBar = "width: 60%"
                if(!row.step_program_calculation) titleP+="-Programa de cálculo.\n"
                else widthProgressBar = "width: 70%"
                if(!row.step_complete_profile) titleP+="-Perfil completo.\n"
                else widthProgressBar = "width: 80%"
                if(!row.step_contract) titleP+="-Contrato.\n"
                else widthProgressBar = "width: 90%"
                if(!row.step_active) titleP+="-Activo."
                else{
                    widthProgressBar = "width: 100%"
                    titleP="Activo"
                }
                if(value === 1) return titleP
                else return widthProgressBar
            },*/
            getUserProgress(row){
              switch (1) {
                case row.firstPayment: return "Primer pago completado"
                case row.contractSignature: return "Contrato firmado"
                case row.contract: return "Listo para firmar contrato"
                case row.programCalculation: return "Calculadora de programa seleccionado"
                case row.creditReport: return "Reporte credito completo"
                case row.creditReportPin: return "Esperando pin reporte credito"
                case row.information1: return "Información para reporte de crédito completa"
                default: return "Usuario nuevo"
              }
            }
        }
    }
</script>
<style>
</style>