<template>
    <div  class="card-header text-center align-items-center" :class="type === 'dark' ? 'bg-transparent': ''">
        <div class="row">
            <div class="col-lg-12 text-center" style="margin-top:-4rem; padding:6rem; height:10%">
                <h4 class="display-3 text-blue" style="font-size:1.5rem;">MI MES ACTUAL</h4>
                <h4 v-if="checkGoal" class="display-3 text-green" style="font-size:1rem; margin-bottom:1.5rem; text-transform: uppercase;">¡FELICIDADES {{user.name}} LLEGASTE A LA META DE {{tableData.totalExpectedActivity}} LIQUIDACIONES! 🥳🎊</h4>
                <div class="d-flex justify-content-between" 
                data-toggle="tooltip" data-placement="top"
                :title="'Hasta el día de hoy debes tener ' +tableData.expectedActivity +' Liquidaciones ('+tableData.expectedActivityPercent+'%)'"
                style=" width: 100%; height: 2rem; margin-top:-0.4rem; padding: 5px; color: black; text-align: left; border-radius:0.5rem;"
                :style="{ width:totalPercentage + '%'}">
                <img src="img/meta.png" width="50px" height="50px" style="margin-top:-1.2rem; margin-left: 101%;" 
                data-toggle="tooltip" data-placement="top" :title="'Meta: ' +tableData.totalExpectedActivity +' Liquidaciones'"/></div>
                <div 
                data-toggle="tooltip" data-placement="top"
                :title="'Hasta el día de hoy debes tener ' +tableData.expectedActivity +' Liquidaciones ('+tableData.expectedActivityPercent+'%)'" 
                class="d-flex justify-content-between" style=" width: 100%; height: 2rem; padding: 5px;  margin-top:-2rem; background: #F7D917; color: black; text-align: left; border-radius:0.5rem;"
                :style="{width:tableData.expectedActivityPercent + '%'}">
                </div>
                <div class="d-flex justify-content-between" 
                data-toggle="tooltip" data-placement="top"
                :title="'Hasta el día de hoy tienes ' +tableData.sumLiq +' Liquidaciones ('+tableData.ActivityPercent+'%)'"
                style="width: 100%; height: 2rem; padding: 5px; background: #2178F7; color: black; text-align: left; margin-top:-2rem; border-radius:0.5rem;"
                 :style="{ width:tableData.ActivityPercent + '%'}">
                <img :src="pictureConsultant" class="rounded-circle no-avatar" width="50px" height="50px" style="margin-top:-1rem; margin-left: auto; margin-right:-0.5rem;"/></div>
                 
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'clients-table-info-service',
    props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            unassigned: Boolean,
            /*period: {
                start: null,
                end: null
            },*/
        },
    computed: {
        user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
        sellers() { return this.$store.state.sellers },
        customerService() { return this.$store.state.customerService }
    },
    data() {
        return {
            tableData: {},
            pictureConsultant:"",
            totalBar:0,
            totalPercentage:100,
            pointer: 'pointer',
            pointer2: 'default',
            checkGoal:false,
        }
    },
    async created(){
        this.getData()
        this.pictureConsultant='https://www.dbmenos.com/img/consultants/' + this.user.picture
    },
    methods: {
        formatDecPost(val, pos) {
      if (!pos) pos = 2;
      if (val && Number(val)) return Number(val).toFixed(pos);
      return "0.00";
    },
    getIconClass(column) {
                let iconClass = "fas "
                if (column === this.sortedColumn) {
                    iconClass += this.sortingDesc ? "fa-arrow-down" : "fa-arrow-up"
                    iconClass += " text-danger"
                } else {
                    iconClass += "fa-arrows-alt-v"
                }
                return iconClass
            },
        getData() {
            let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_DEBTS_INFO_SERVICE + "?consultant=" + this.user.id;
            this.$showLoader(true)
            this.$axios.get(url).then(response => {
                this.$showLoader(false)
                let data = response.data
                if (data[this.$v.API_SUCCESS]) {
                    this.tableData = data[this.$v.API_ROWS]
                    if(this.tableData.ActivityPercent > 100){
                        this.tableData.ActivityPercent = 100
                        this.checkGoal = true;
                    } else{
                        this.tableData.ActivityPercent = this.tableData.ActivityPercent
                    }
                    if(this.tableData.expectedActivityPercent > 100){
                        this.tableData.expectedActivityPercent = 100
                    }else{
                        this.tableData.expectedActivityPercent = this.tableData.expectedActivityPercent
                    }
                    //this.getProgressBarData()
                    /*
                    expectedActivity: 13
                    expectedActivityPercent: 72.22222222222223
                    negotiatedDebtsCurrentMonth: 35
                    sumCov: 4
                    sumDisMdiB: 2.9031395980327437
                    sumEspLiq: 0
                    sumLE: 0
                    sumLR: 0
                    sumLiq: (...)
                    sumMDI: 2138805.94
                    sumPB: 736721.7000000001
                    totalExpectedActivity: 18
                    */
                } else { this.$validateSession(data) }
            })
        },
    }
}
</script>
<style>

@media (min-width: 992px) {
  #top 
{ position: fixed;  z-index: 999; width: 78%; height:39%; margin-top:-4rem; align-content: center;}
}

@media (min-width: 1200px) {
 #top 
{ position: fixed;  z-index: 999; width: 80%; height:34%; margin-top:-4rem; align-content: center;}
}


 .round_table {
    border-collapse: separate;
    border-spacing: 1;
    border:solid 1px;
    border-color: #d3d3d3;
}

.round_th {
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
}
</style>