<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''"
       v-bind:style="{cursor:pointer2}">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="data">
        <template slot="columns">
          <th class="text-center">Fecha de baja</th>
          <th class="text-center">MDI anterior</th>
          <th class="text-center">Fecha de reactivación</th>
          <!--th>MDI actual</th-->
          <th class="text-center">Periodo de baja</th>
        </template>

        <template slot-scope="{row}">
          <td class="text-center">{{$formatDate(row.unsubscribe_date)}}</td>
          <td class="text-center">{{$formatCurrency(row.previous_mdi)}}</td>
          <td class="text-center">{{$formatDate(row.reactivation_date)}}</td>
          <!--td>{{$formatCurrency(row.current_mdi)}}</td-->
          <td class="text-center">{{row.unsubscribe_period+" Meses"}}</td>
        </template>

      </base-table>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'reactivations-table',
    props: {
      type: {
        type: String
      },
      title: String,
      data: Array,
    },
    data() {
      return {
        tableData: [],
        pointer: 'pointer',
        pointer2: 'default'
      }
    },
  }
</script>
<style>
</style>