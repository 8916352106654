<template>
    <div class="card shadow"
        :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
            :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getNominaFilterText()}}
                        </base-button>
                        <a class="dropdown-item" @click="setNominaFilter(null)">Todos</a>
                        <a class="dropdown-item" @click="setNominaFilter({'eq': '1'}, 'payroll_same_bank === 1')">Clientes con Nómina</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getTypeClientInactive()}}
                        </base-button>
                        <a class="dropdown-item" @click="setTypeClient('empty')">Todos</a>
                        <a class="dropdown-item" @click="setTypeClient('null','Sin Asignar')">Sin Asignar</a>
                        <a class="dropdown-item" @click="setTypeClient('Activo','Activos')">Activos</a>
                        <a class="dropdown-item" @click="setTypeClient('Graduado','Graduados')">Graduados</a>
                        <a class="dropdown-item" @click="setTypeClient('Baja','Baja')">Baja</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="info" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getDueMonthsFilterText()}}
                        </base-button>
                        <a class="dropdown-item" @click="setDueMonthsFilter(null)">Todos</a>
                        <a class="dropdown-item" @click="setDueMonthsFilter({'lt':1}, '0 a 1 mes')">0 a 1 mes</a>
                        <a class="dropdown-item" @click="setDueMonthsFilter({'gte':1, 'lt':3}, '1 a 3 meses')">1 a 3 meses</a>
                        <a class="dropdown-item" @click="setDueMonthsFilter({'gte':3, 'lte':6}, '3 a 6 meses')">3 a 6 meses</a>
                        <a class="dropdown-item" @click="setDueMonthsFilter({'gt':6}, '+6Meses')">Más de 6 mes</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getTraceFilterText()}}
                        </base-button>
                        <a class="dropdown-item" @click="setTraceFilter(null)">Todos</a>
                        <a class="dropdown-item" @click="setTraceFilter({'gte': $moment().subtract(7, 'd')._d}, '0 a 7 días')">0 a 7 días</a>
                        <a class="dropdown-item" @click="setTraceFilter({'lt': $moment().subtract(7, 'd')._d, 'gte': $moment().subtract(14, 'd')._d}, '7 a 14 días')">7 a 14 días</a>
                        <a class="dropdown-item" @click="setTraceFilter({'lt': $moment().subtract(14, 'd')._d, 'gte': $moment().subtract(21, 'd')._d}, '14 a 21 días')">14 a 21 días</a>
                        <a class="dropdown-item" @click="setTraceFilter({'lt': $moment().subtract(21, 'd')._d, 'gte': $moment().subtract(30, 'd')._d}, '21 a 30 días')">21 a 30 días</a>
                        <a class="dropdown-item" @click="setTraceFilter({'lte': $moment().subtract(1, 'm')._d}, '+1Mes')">Más de un mes</a>
                    </base-dropdown>
                </div>
            </div>
        </div>
    
        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                        <!--:period="period"-->

                <template slot="columns">

                    <th v-on:click="selectColumn(0)" v-bind:style="{cursor:pointer2}">ID<i :class="getIconClass(0)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(1)" v-bind:style="{cursor:pointer2}">Nombre<i :class="getIconClass(1)" style="margin-left: 6px"/></th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Fecha de inicio</th>
                    <th v-on:click="selectColumn(2)" v-bind:style="{cursor:pointer2}" class="text-center">Ahorro mensual<i :class="getIconClass(2)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(3)" v-bind:style="{cursor:pointer2}" class="text-center">Total de ahorros<i :class="getIconClass(3)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(4)" v-bind:style="{cursor:pointer2}" class="text-center">Día de ahorro<i :class="getIconClass(4)" style="margin-left: 6px"/></th>
                    <!--Estatus ahorros-->
                    <th v-on:click="selectColumn(5)" v-bind:style="{cursor:pointer2}" class="text-center">Covid<i :class="getIconClass(5)" style="margin-left: 6px"/></th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Último depósito</th>
                    <th class="text-center">Días sin depósito</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Último seguimiento</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Días sin seguimiento</th>
                    <th v-bind:style="{cursor:pointer2}" class="text-center">Tipo de cliente</th>
                    <th class="text-center">Fecha promesa</th>
                    <th class="text-center">Estatus promesa</th>
                    <!--th v-bind:style="{cursor:pointer2}" class="text-center">Status del Cliente</th-->
                    <th class="text-center">Pendiente</th>
                    <!--Baja por saldo-->
                    <th class="text-center">Programa Especial</th>
                    <th v-bind:style="{cursor:pointer2}" v-if="isAdmin || isManagerService" class="text-center">Consultor</th>
                </template>

                <template slot-scope="{row}">
                    <th scope="row" >
                        <div class="media align-items-center">
                        <div class="media-body static">
                        <router-link v-if="isService" target="_blank" :to="'/clientService'+'?id=' + row.id">
                            <span class="name mb-0 text-sm">{{row.id}}<i class="fas fa-user-edit text-primary"></i></span>
                        </router-link>
                        <router-link v-else target="_blank" :to="'/client'+'?id=' + row.id">
                            <span class="name mb-0 text-sm">{{row.id}}<i class="fas fa-user-edit text-primary"></i></span>
                        </router-link>
                        </div>
                        </div>
                    </th>
                    <td>{{row.name}}</td>
                    <td class="text-center">{{$formatDate(row.first_payment)}}</td>
                    <td class="text-center">{{$formatCurrency(row.monthly_savings)}}</td>
                    <td class="text-center">{{$formatCurrency(row.paymentSum)}}</td>
                    <td class="text-center">{{row.payday}}</td>
                    <!--Estatus ahorros-->
                    <td class="text-center">{{row.covid?'Sí':'No'}}</td>
                    <td class="text-center">{{row.last_payment_date? $formatDate(row.last_payment_date): '-'}}</td>
                    <td class="text-center">{{row.daysSincesLastPayment? row.daysSincesLastPayment+" días" : "0 días"}}</td>
                    <td class="text-center">{{row.last_contact_date? $formatDate(row.last_contact_date):'Sin seguimiento'}}</td>
                    <td class="text-center">{{row.daysSinceLastTrace? row.daysSinceLastTrace+" días" : "-"}}</td>
                    <td class="text-center">{{row.clientType}}</td>
                    <td class="text-center">{{row.date_promise? $formatDate(row.date_promise):'-'}}</td>
                    <td class="text-center">{{row.status_promise? row.status_promise:'-'}}</td>
                    <!--td class="text-center">{{(row.inactiveReason)}}</td-->
                    <td v-on:click.stop="">
                        <base-dropdown class="dropdown">
                            <a slot="title" class="btn btn-sm" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {{getPending(row.pending)}}
                            </a>
                            <template>
                                <a v-for="(pending, index) in pendingsSC" :key="index" class="dropdown-item" v-on:click="setPendingValue(row, pending.id)">{{pending.value}}</a>
                                <a class="dropdown-item" @click="setPendingValue(row, 0)">Vacio</a>
                            </template>
                        </base-dropdown>
                    </td>
                    <!--Baja por saldo-->
                    <td class="text-center">
                        <u v-if="row.special_program" class="text-primary"><a @click="showSpecialProgramModal(row.special_program)">Ver</a></u>
                        <span v-else>No</span>
                    </td>
                    <td v-if="isAdmin || isManagerService || isManagerSeller" class="text-center" v-on:click.stop="">
                        <base-dropdown class="dropdown">
                            <a slot="title" class="btn btn-sm" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {{row.consultant_name}}
                            </a>
                            <template>
                                <a v-for="(customerS) in customerService" :key="customerS.id" class="dropdown-item" v-on:click="assignToConsultant(row, customerS)">{{customerS.name}} {{customerS.lastname_1}}</a>
                            </template>
                        </base-dropdown>
                    </td>
                </template>

            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} clientes
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>
    </div>
</template>
<script>
    //import flatPickr from 'vue-flatpickr-component';
    //import 'flatpickr/dist/flatpickr.css';
    export default {
        name: 'clients-table-service',
        /*components:{
            flatPickr
        },*/
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            unassigned: Boolean,
            /*period: {
                start: null,
                end: null
            },*/
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            sellers() { return this.$store.state.sellers },
            pendingsSC() { return this.$store.state.pendingsSC },
            customerService() { return this.$store.state.customerService }
        },
        data() {
            return {
                isManagerService: false,
                isSevice: false,
                tableData: [],
                today: null,
                isAuditor: false,
                //consultantFilter: null,
                //showSetPeriod:false,
                textTraceFilter: "",
                textDueMonthsFilter: "",
                textTypeClientFilter: "",
                textMDIFilter: "",
                textNominaFilter: "",
                currentColumnKey: null,
                sortedColumn: 0,
                sortingDesc: true,
                //widthProgressBar: null,
                //titleProgressBar: "",
                pointer: 'pointer',
                pointer2: 'default'
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
            this.isSevice = this.user.role === this.$v.ROLES.SERVICE
            this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR
            this.today= new Date()
        },
        methods: {
            assignToConsultant(profile, consultant) {
                let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.ASSIGN_USER_SERVICE
                this.$showLoader(true)
                this.$axios.post(url, {
                    user: profile.id,
                    consultant: consultant.id
                }).then(response => {
                    this.$showLoader(false)
                    profile.consultant = consultant.id
                    let data = response.data
                    if (data[this.$v.API_SUCCESS]) {
                        
                        this.$vs.notification(this.$notification(
                'success',
                 'Asignación exitosa'
                     ));
                        this.$forceUpdate()
                    }
                })
            },
            selectColumn(column) {
              if (this.sortedColumn === column) {
                  this.sortingDesc = !this.sortingDesc
              } else {
                  this.sortedColumn = column
                  this.sortingDesc = true
              }
              if (this.sortedColumn != null)
                this.sort()
            },
            sort() {
                let isNumber
                switch (this.sortedColumn) {
                    case 0:
                        this.currentColumnKey = 'user'
                        break
                    case 1:
                        this.currentColumnKey = 'name'
                        break;
                    case 2:
                        this.currentColumnKey = 'monthly_savings'
                        isNumber=true;
                        break
                    case 3:
                        this.currentColumnKey = 'paymentSum'
                        isNumber=true;
                        break
                    case 4:
                        this.currentColumnKey = 'payday'
                        break
                    case 5:
                        this.currentColumnKey = 'covid'
                        break
                }
                this.data.sort((a, b) => {
                    let _a = isNumber? Number(a[this.currentColumnKey]):a[this.currentColumnKey]
                    let _b = isNumber? Number(b[this.currentColumnKey]):b[this.currentColumnKey]
                    if (_a < _b) return this.sortingDesc ? -1 : 1
                    if (_a > _b) return this.sortingDesc ? 1 : -1
                    return 0
                })
            },
            getIconClass(column) {
                let iconClass = "fas "
                if (column === this.sortedColumn) {
                    iconClass += this.sortingDesc ? "fa-arrow-down" : "fa-arrow-up";
                    iconClass += " text-danger";
                } else iconClass += "fa-arrows-alt-v";
                return iconClass;
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            clientSelected(client) {
                this.$emit("clientSelected", client);
            },
            setMDIFilter(obj, text){
                this.textMDIFilter=text;
                let json=obj?JSON.stringify(obj):null;
                this.$emit("setMDIFilter", json);
            },
            setNominaFilter(obj, text){
                this.textNominaFilter=text;
                let json=obj?JSON.stringify(obj):null;
                this.$emit("setNominaFilter", json);
            },
            getMDIFilterText(){
                if(!this.textMDIFilter) return "MDI"
                else return this.textMDIFilter
            },
            getNominaFilterText(){
                if(!this.textMDIFilter) return "Nómina"
                else return this.textMDIFilter
            },
            setDueMonthsFilter(obj,text){
                this.textDueMonthsFilter=text
                let json=obj?JSON.stringify(obj):null
                this.$emit("setDueMonthsFilter", json);
            },
            setTypeClient(obj,text){
                this.textTypeClientFilter=text
                console.log(obj)
                let json=obj?JSON.stringify(obj):null
                this.$emit("setTypeClient", json);
            },
            getTypeClientInactive(){
                if(!this.textTypeClientFilter) return "Estatus Clientes"
                else return this.textTypeClientFilter
                
            },
            getDueMonthsFilterText(){
                if(!this.textDueMonthsFilter) return "Atraso"
                else return this.textDueMonthsFilter
            },
            setTraceFilter(obj, text){
                this.textTraceFilter=text
                let json=obj?JSON.stringify(obj):null
                this.$emit("setTraceFilter", json);
            },
            getTraceFilterText(){
                if(!this.textTraceFilter) return "Sin seguimiento"
                else return this.textTraceFilter
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            },
            setPendingValue(row, pending){
                this.$showLoader(true);
                let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_PENDINGS_SC;
                this.$axios.post(url, {user: row.user, value: pending}).then((response) => {
                    this.$showLoader(false);
                    let data = response.data;
                    if (data[this.$v.API_SUCCESS]) {
                        
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
                        row.pending = pending;
                        this.$forceUpdate();
                    } else {
                        this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    }
                });
            },
            showSpecialProgramModal(sp){
                console.log('mostrar modal',sp);
            },
            getPending(id){
                for (let i=0 ; i<this.pendingsSC.length ; i++)
                    if (this.pendingsSC[i].id == id)
                        return this.pendingsSC[i].value
                return "- - -"
            }
        }
    }
</script>
<style>
</style>