<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col" v-bind:style="{cursor:pointer2}">
          <leads-table title="Posibles clientes DB Menos"
                       :data="tableData"
                       :period="period"
                       :pagination="pagination"
                       v-on:pageSelected="getLeadList"
                       v-on:calcuLeadSelected="calcuLeadSelected"
                       v-on:leadSelected="leadSelected"
                       v-on:toClient="toClient"
                       v-on:setPeriodFilter="setPeriodFilter"
                       v-on:setFollowFilter="setFollowFilter"
                       v-on:setSellerFilter="setSellerFilter"
                       v-on:setOwnClientsFilter="setOwnClientsFilter"
                       v-on:setStatusFilter="setStatusFilter"/>
        </div>
      </div>
    </div>

    <modal :show.sync="tracingModal" v-on:close="closeModal()" v-bind:large="true">
      <template slot="header">
        <h2 class="mb-0" id="TracingLead" style="margin-top: 20px; margin-left: 10px">{{selectedLead.name}}</h2>
      </template>
      <div v-if="newTrace">
        <div class="col-lg-12">
          <base-dropdown>
            <base-button slot="title" type="success" class="dropdown-toggle" size="sm">
              {{trace.status ? trace.status : "Seleccionar"}}
            </base-button>
            <a class="dropdown-item" href="#" v-for="(status, index) in tracingStatus" :key="index" v-on:click="trace.status = status.status">{{status.status}}</a>
          </base-dropdown>
          <base-input alternative=""
                      placeholder="Comentarios"
                      input-classes="form-control-alternative"
                      style="margin-top: 20px"
                      v-model="trace.comments"/>
        </div>
        <div class="col text-right">
          <base-button slot="title" type="primary" v-on:click="uploadTrace()">Crear</base-button>
        </div>
      </div>
      <div v-else>
        <leads-tracing-table title="Seguimiento"
                             :data="TracingTableData"
                             v-on:newLeadTrace="newLeadTrace"/>
      </div>
    </modal>

    <modal :show.sync="modalCreateUser" v-on:close="modalCreateUser=false" v-bind:large="true">
      <template slot="header">
        <h2 class="mb-0" id="TracingLead" style="margin-top: 20px; margin-left: 10px">{{selectedLead.name}}</h2>
      </template>
      <div class="row">
        <div class="col-lg-4">
          <base-input alternative input-classes="form-control-alternative"
            label="Nombre"
            v-model="selectedLead.name"/>
        </div>
        <div class="col-lg-4">
          <base-input alternative input-classes="form-control-alternative"
            label="Email"
            v-model="selectedLead.email"/>
        </div>
        <div class="col-lg-4">
          <base-input alternative input-classes="form-control-alternative"
            label="Teléfono"
            v-model="selectedLead.phone"/>
        </div>
      </div>
      <template slot="footer">
        <base-button class="btn-sm" type="primary" @click="createUser">
          Crear Usario
        </base-button>
      </template>
    </modal>

    <modal :show.sync="calcModal" v-on:close="calcModal=false" v-bind:large="true">
      <template slot="header">
        <h2 class="mb-0" id="acountModal" style="margin-top: 20px; margin-left: 10px">Calculadora de descuentos</h2>
      </template>

      <div class="row justify-content-center" v-if="selectedLead.calculation">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">Total adeudado</h6>
                  <span class="h2 font-weight-bold mb-0">${{Number.parseFloat(selectedLead.calculation.totalDebt).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-red text-white rounded-circle shadow">
                    <i class="fas fa-money-bill"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5" v-if="selectedLead.calculation">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">Ahorros</h6>
                  <span class="h2 font-weight-bold mb-0">${{Number.parseFloat(selectedLead.calculation.savings).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-success text-white rounded-circle shadow">
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center" v-if="selectedLead.calculation">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">Duración del programa</h6>
                  <span class="h2 font-weight-bold mb-0">{{selectedLead.calculation.period}} meses</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                    <i class="fas fa-clock"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5" v-if="selectedLead.calculation">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">Depósito mensual</h6>
                  <span class="h2 font-weight-bold mb-0">${{Number.parseFloat(selectedLead.calculation.montlyFee).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-blue text-white rounded-circle shadow">
                    <i class="fas fa-coins"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center" v-if="selectedLead.calculation">
        <div class="col-xl-5 col-lg-5 col-md-5">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">Descuento estimado</h6>
                  <span class="h2 font-weight-bold mb-0">{{Number.parseFloat(selectedLead.calculation.discount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}%</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                    <i class="fa fa-percent"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5" v-if="selectedLead.calculation">
          <div class="card card-stats mb-4 mb-xl-2">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h6 class="card-title text-uppercase text-muted mb-0">Deudas</h6>
                  <span class="h2 font-weight-bold mb-0">{{selectedLead.calculation.debts.length}}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-indigo text-white rounded-circle shadow">
                    <i class="fas fa-check"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center" v-if="selectedLead.calculation">
        <div class="col-xl-10 col-lg-10 col-md-10">
          <div class="card shadow">
            <div class="card-header border-0">
              <h3 class="mb-0">Deudas a liquidar</h3>
            </div>
            <div class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                <tr>
                  <th class="text-center" scope="col">Deuda</th>
                  <th class="text-center" scope="col">Acreedor</th>
                  <th class="text-center" scope="col">Deuda sin dbm</th>
                  <th class="text-center" scope="col">Valor a pagar con dbm</th>
                  <th class="text-center" scope="col">Mes a pagar</th>
                  <th class="text-center" scope="col">Avance</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(debt, index) in selectedLead.calculation.debts" :key="index">
                  <td class="text-center">
                    {{index + 1}}
                  </td>
                  <td class="text-center">
                    {{debt.bank}}
                  </td>
                  <td class="text-center">
                    ${{Number.parseFloat(debt.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                  </td>
                  <td class="text-center">
                    ${{Number.parseFloat(debt.willPay).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                  </td>
                  <td class="text-center">
                    {{debt.willPayOnMonth}}
                  </td>
                  <td class="text-center">{{Number.parseFloat(debt.o_progress).toFixed(2)}}%
                    <!--<div>
                        <div class="progress">
                            <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="47.7" aria-valuemin="0" aria-valuemax="100" v-bind="{style: debt.willPay / discount.totalDebt * 100}"></div>
                        </div>
                    </div>-->
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <template slot="footer">
        <base-button type="primary" v-on:click="calcModal=false">Cerrar</base-button>
      </template>
    </modal>

  </div>
</template>
<script>
import LeadsTable from './Tables/LeadsTable'
import LeadsTracingTable from './Tables/LeadsTracingTable'
import firebase from 'firebase/app';
import "firebase/messaging";

export default {
  name: 'tables',
  components: {
    LeadsTable,
    LeadsTracingTable
  },
  mounted() {
    this.$root.$on('searchQuery', query => {
      if (query.length > 0)
        this.searchQuery = query
      else
        this.searchQuery = null
      this.getLeadList(1)
    })
  },
  computed: {
    user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
    generalInfo() { return this.$store.state.generalInfo },
    tracingStatus() {
      let r = this.$store.state.tracingStatus
      r.splice(1, 1)
      return r;
    }
  },
  data() {
    return {
      lastRequest: {},
      searchQuery: null,
      pagination: {
        total: 0,
        perPage: 10,
        currentPage: 1
      },
      tableData: [],
      tracingModal: false,
      newTrace: false,
      trace: {
        status: null,
        comments: ""
      },
      TracingTableData: [],
      selectedLead: {},
      periodFilter: null,
      period: {
        start: null,
        end: null
      },
      statusFilter: null,
      followFilter: null,
      sellerFilter: null,
      ownClientsFilter: null,
      pointer: 'pointer',
      pointer2: 'default',
      calcModal: false,
      tokenFCM: "",
      isSeller:false,
      isManagerSeller:false,

      modalCreateUser: false,
    };
  },
  async created() {
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    this.getLeadList(1);
    // await this.notifyMe(this.tokenFCM);
  },
  methods: {
    notifyMe(tokenFCM) {
      this.tokenFCM = tokenFCM;
      let messaging = firebase.messaging();
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          messaging
              .getToken()
              .then(tokenFCM => {
                if (tokenFCM) {
                  this.tokenFCM = tokenFCM;
                  let url = this.$v.SERVER + this.$v.SELLER_OPS.SET_FCM_TOKEN_CONSULTANTS;
                  this.$axios
                      .post(url, { token: tokenFCM })
                      .then(response => {
                        let data = response.data;
                        if (data[this.$v.API_SUCCESS]) {
                          console.log('Token Add')
                        }
                      });
                } else {
                  // Show permission request.
                  console.log(
                      "No Instance ID token available. Request permission to generate one."
                  );
                }
              })
              .catch(err => {
                console.log(
                    "An error occurred while retrieving token. ",
                    err
                );
              });
        }
      });
    },
    compareRequests(r) {
      // if (Object.keys(r) !== Object.keys(this.lastRequest)) {
      //   this.lastRequest = JSON.parse(JSON.stringify(r));
      //   return false;
      // }
      for (let k of Object.keys(r)) {
        if (this.lastRequest[k] !== r[k]) {
          this.lastRequest = JSON.parse(JSON.stringify(r));
          return false;
        }
      }
      return true;
    },
    getLeadList(page) {
      let limit = this.pagination.perPage
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_LEADS
      let request = {
        offset: limit * (page - 1),
        limit: limit
      }
      
      if (this.user.role === this.$v.ROLES.SELLER){
        //request.seller = {ne: 999}
        request.seller = {eq: this.user.id}
        if(this.ownClientsFilter)
          request.seller = this.user.id
        //request.or = [{seller: this.user.id}, {status_code: [1,2,10,17,18]}, {trace_id : null}]
        request.or = [{status_code: [1,2,10,17,18]}, {trace_id : null}]
      }
      if (this.user.role === this.$v.ROLES.MANAGER_SELLER)
        request.leader = this.user.id
      if (this.statusFilter !== null) {
        if (this.statusFilter === 'Sin seguimiento') {
          Object.assign(request, {trace_id: "null"})
        }
        else if(this.statusFilter === 'Reasignado') request.reasigned=true;
        else request.status = this.statusFilter
      } else if (this.followFilter !== null) request.follow = this.followFilter
      if (this.periodFilter === 1) {
        let created = {gte: this.period.start}
        Object.assign(request, {created})
      }
      if (this.periodFilter === 2){
        let created = {
          gte: this.period.start,
          lte: this.period.end
        }
        request = Object.assign(request, {created})
      }
      if (this.searchQuery) request.find = this.searchQuery
      if (this.sellerFilter) request.seller = this.sellerFilter;
      
      if (request.offset === 0) {
        console.log("offset == 0")
      }

      const lr = request
      if (lr === this.lastRequest) return
      this.lastRequest = lr;

      this.$showLoader(true)



      this.$axios.post(url, request).then(response => {
        this.$showLoader(false)
        let data = response.data
        if (data[this.$v.API_SUCCESS]) {
          this.tableData = data[this.$v.API_ROWS]
          let p = data[this.$v.API_ROW]
          this.pagination.total = p['totalItems']
          this.pagination.currentPage = page
          this.pagination.perPage = limit
          this.$forceUpdate();
        } else { this.$validateSession(data) }
      })
    },
    calcuLeadSelected(lead) {
      this.selectedLead = lead
      if(this.selectedLead.calculation)
        this.calcModal = true
    },
    leadSelected(lead) {
      this.TracingTableData = []
      this.selectedLead = lead
      this.tracingModal = true
      this.downloadLeadTracing()
    },
    toClient(lead) {
      this.selectedLead = lead;
      console.log(lead);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_FROM_LEAD + "?lead=" + lead.id
      this.$showLoader(true)
      this.$axios.get(url).then(response => {
        this.$showLoader(false)
        let data = response.data
        if (data[this.$v.API_SUCCESS]) {
          let clientID = data[this.$v.API_ROW]
          if (clientID) {
            window.open("https://admin.dbmenos.com/client?id=" + clientID, "_blank");

          }
        } else {
          this.$vs.notification(this.$notification('info', 'El lead no tiene perfil de cliente'))
          this.modalCreateUser=true;
        }
      })
    },
    async createUser(){
      let newUser = {
        name: this.selectedLead.name,
        email: this.selectedLead.email,
        phone: this.selectedLead.phone
      }
      let url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_USER;
      this.$showLoader(true);
      let r = await this.$axios.post(url, newUser)
      this.$showLoader(false)
      r=r.data;
      if (r.success){
        this.modalCreateUser=false;
        this.$vs.notification(this.$notification('success', 'Usuario creado correctamente'))
        window.open("https://admin.dbmenos.com/client?id=" + r.row.id, "_blank");
        //this.$router.push("/client?id="+r.row.id);
      }
    },
    downloadLeadTracing() {
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_SELL_TRACING //+ "?user=" + this.selectedLead.id
      let request={
        lead: this.selectedLead.id
      }
      //if(this.isSeller) request.upload_by = this.user.id
      this.$showLoader(true)
      this.$axios.post(url,request).then(response => {
        this.$showLoader(false)
        if (response.data[this.$v.API_SUCCESS]) {
          this.TracingTableData = response.data[this.$v.API_ROWS]
        }
      })
    },
    newLeadTrace() {
      this.newTrace = true
    },
    setOwnClientsFilter(value) {
      console.log('xddd');
      this.ownClientsFilter = value
      this.getLeadList(1)
    },
    setPeriodFilter(value) {
      if(value === 1){
        let date = new Date()
        let month = date.getMonth()+1
        if(month<10) month= "0"+month
        this.period.start = date.getFullYear()+"-"+month+"-"+"01T00:00:00.000"
      }
      this.periodFilter = value
      this.getLeadList(1)
    },
    setFollowFilter(value) {
      this.followFilter = value
      this.statusFilter = null
      this.getLeadList(1)
    },
    setSellerFilter(value) {
      this.sellerFilter = value
      this.getLeadList(1)
    },
    setStatusFilter(value) {
      this.statusFilter = value
      this.followFilter = null
      this.getLeadList(1)
    },
    uploadTrace() {
      let id = 0
      for (let t=0 ; t<this.tracingStatus.length ; t++) {
        if (this.tracingStatus[t].status === this.trace.status) {
          id = this.tracingStatus[t].id
          break
        }
      }
      if (id === 0 || this.trace.comments === "") {
        this.$vs.notification(this.$notification(
                'warning',
                 'Datos incompletos'
                     ));
        return
      }
      let json = {
        status: id,
        lead: this.selectedLead.id,
        comments: this.trace.comments
      }
      let url = this.$v.SERVER + this.$v.SELLER_OPS.SET_SELL_TRACING
      this.$showLoader(true)
      this.$axios.post(url, json).then(response => {
        this.$showLoader(false)
        if (response.data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Carga exitosamente'
                     ));
          this.closeModal()
          this.trace.id = 0
          this.trace.status = null
          this.trace.comments = ""
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        }
      })
    },
    closeModal() {
      this.tracingModal = false
      this.newTrace = false
    }
  }
};
</script>
<style></style>