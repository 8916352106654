<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                    <h3 v-if="(!data.length || inactive==6) && (showData || showisService)" class="text-danger">(1er pago incompleto)</h3>
                </div>
                <div class="col text-right">
                    <base-button v-if="!(showData || showisService) && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="showDebts"></base-button>
                </div>
                <!--div class="col text-right">
                    <base-button type="primary" size="sm" v-on:click="onNewItem">Nuevo</base-button>
                </div-->
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor: pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        @click="debtSelected">
                <template slot="columns">
                    <th class="text-center">Deuda</th>
                    <th class="text-center">Estado de deuda</th>
                    <!--th class="text-center" v-bind:style="{cursor: pointer2}">Última actividad</th-->
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Fecha última actividad</th>
                    <th class="text-center">Banco</th>
                    <th v-if="!isSeller" class="text-center">Meses de Mora</th>
                    <th v-if="!isSeller" class="text-center">Número de cuenta</th>
                    <th v-if="!isSeller" class="text-center" v-bind:style="{cursor: pointer2}">Total adeudado</th>
                    <th v-if="!isSeller" class="text-center" v-bind:style="{cursor: pointer2}">Pago a banco</th>
                    <th v-if="!isSeller" class="text-center" v-bind:style="{cursor: pointer2}">Descuento esperado</th>
                    <th v-if="!isSeller" class="text-center" v-bind:style="{cursor: pointer2}">Fecha estimada</th>
                    <th v-if="isAdmin" class="text-center" v-bind:style="{cursor: pointer2}">Pagar</th>
                    <!--th v-if="false" class="text-center">Acciones</th-->
                </template>

                <template v-if="showData || showisService || addendumAccFormat" slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{row.id_debt}}</span>
                            </div>
                        </div>
                    </th>
                    <td class="text-center">{{row.status}}</td>
                    <!--td class="text-center">{{row.last_activity}}</td-->
                    <td class="text-center">{{row.last_activity_date? $formatDate(row.last_activity_date) : '-'}}</td>
                    <td class="text-center">{{row.bank}}</td>
                    <td v-if="!isSeller" class="text-center">{{row.month_mora}}</td>
                    <td class="text-center">{{row.account}}</td>
                    <td class="text-center">{{$formatCurrency(row.amount)}}</td>
                    <td v-if="!isSeller" class="text-center">{{$formatCurrency(row.discount)}}</td>
                    <td v-if="!isSeller" class="text-center">{{$formatCurrency(row.will_pay)}}</td>
                    <td v-if="!isSeller" class="text-center">{{$formatDate(row.will_pay_on)}}</td>
                    <td v-if="isAdmin" class="text-center text-success"><i class="fas fa-piggy-bank" @click.stop="payDebt(row.account)"></i></td>
                </template>

            </base-table>

        </div>

        <div v-if="(pagination.total > pagination.perPage) && showData" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    @pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'clients-table',
        props: {
            type: {
                type: String
            },
            title: String,
            showisService: Boolean,
            showEye: Boolean,
            inactive: Number,
            data: Array,
            pagination: Object
        },
        data() {
            return {
              isSeller: false,
              isService: false,
              isAdmin: false,
              tableData: [],
              pointer: 'pointer',
              pointer2: 'default',
              showData: false
            }
        },
        computed: {
          user() { return this.$getLS(this.$v.STORE_KEYS.USER) }
        },
        async created() {
          this.isSeller = this.user.role === this.$v.ROLES.SELLER
          this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
          this.isService = this.user.role === this.$v.ROLES.SERVICE
          if(this.isService) this.showData=true;
        },
        methods: {
            showDebts(){
                this.showData = true;
                this.$emit("showDebts")
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            onNewItem() {
                this.$emit("onNewItem")
            },
            debtSelected(debt) {
               this.$emit("debtSelected", debt)
            },
            payDebt(debt) {
               this.$emit("payDebt", debt)
            },
        }
    }
</script>
<style>
</style>
