<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8">
      <div v-if="!isSeller && !isService" class="row">
        <!--div id="chartdiv"></div-->
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Usuarios registrados"
            type="gradient-red"
            v-bind:sub-title="String(generalInfo.users)"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> Pendiente
              </span>
              <span class="text-nowrap">En el último mes</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Clientes activos"
            type="gradient-orange"
            v-bind:sub-title="String(tableClientActive.totalItems)"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2">{{
                this.formatCurrencyPorcentaje(
                  (tableClientActive.totalItems / generalInfo.users) * 100
                ) + "%"
              }}</span>
              <span class="text-nowrap">De Usuario Registrados</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Clientes Graduados"
            type="gradient-orange"
            v-bind:sub-title="String(tableDataGraduates.totalItems)"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2">{{
                this.formatCurrencyPorcentaje(
                  (tableDataGraduates.totalItems /
                    tableClientActive.totalItems) *
                    100
                ) + "%"
              }}</span>
              <span class="text-nowrap">De Usuarios Activos</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Deuda ingresada"
            type="gradient-info"
            v-bind:sub-title="this.formatCurrency(generalInfo.totalDebt)"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> Pendiente
              </span>
              <span class="text-nowrap">En el último mes</span>
            </template>
          </stats-card>
        </div>
      </div>
      <br />
      <div v-if="!isSeller && !isService" class="row">
        <div class="col-xl-6 col-lg-6">
          <stats-card
            title="Deudas Promedio"
            type="gradient-green"
            v-bind:sub-title="
              this.formatCurrency(tableDataAmount.average_per_client)
            "
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> Pendiente
              </span>
              <span class="text-nowrap">En el último mes</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-6 col-lg-6">
          <stats-card
            title="Deuda negociada"
            type="gradient-green"
            v-bind:sub-title="this.formatCurrency(generalInfo.negotiated)"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-danger mr-2">
                <i class="fa fa-arrow-down"></i>
                {{
                  this.formatCurrency(
                    generalInfo.totalDebt - generalInfo.negotiated
                  )
                }}
              </span>
              <span class="text-nowrap">Ahorrados</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div
      class="container-fluid mt--7"
      v-bind:style="{ cursor: pointer2 }"
      v-if="!isService && !isManagerService"
    >
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col">
            <h5 class="h2 mb-0">
              {{
                !isSeller ? "Actividades diarias Ventas" : "Mi actividad diaria"
              }}
            </h5>
            <p v-if="isSeller && !isAdmin && !isManagerService">
              {{ isSeller ? this.user.name : "Global" }}
              {{ selected.sellerCount }} eventos
            </p>
            <p v-if="!isSeller || isAdmin || isManagerService">
              {{
                selected.seller != null
                  ? sellers.find((s) => s.id === selected.seller).name
                  : "Global"
              }}
              {{ selected.sellerCount }} eventos
            </p>
          </div>
          <div class="col">
            <ul class="nav nav-pills justify-content-end">
              <li class="nav-item mr-2 mr-md-0">
                <div class="col">
                  <div class="row justify-content-center">
                    <flat-pickr
                      v-model="period.start"
                      :placeholder="today"
                      class="btn btn-sm"
                      data-vv-as="Fecha"
                      name="startPeriod"
                      :config="config"
                      style="margin-top: 1rem; background-color: #fff"
                    />
                    <base-button
                      type="info text-black"
                      size="sm"
                      style="margin-top: 1rem"
                      @click="setPeriodFilterDaily()"
                      >Aplicar</base-button
                    >
                    <!--@on-change="setPeriodFilterDaily()"-->
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <template>
          <div class="row">
            <div :class="!isSeller ? 'col-lg-9' : 'col-lg-12'">
              <div
                ref="sellerLineDailyActivities"
                style="width: 100%; height: 470px"
              />
            </div>
            <div v-if="isAdmin || isManagerSeller" class="col-lg-3">
              <div
                ref="sellersDailyActivities"
                style="width: 100%; height: 470px"
              />
            </div>
            <div :class="!isSeller ? 'col-lg-6' : 'col-lg-6'">
              <div
                ref="sellersBarDailyActivities"
                style="width: 100%; height: 470px"
              />
            </div>
            <div :class="!isSeller ? 'col-lg-6' : 'col-lg-6'">
              <div
                ref="sellerRadialDailyActivities"
                style="width: 100%; height: 470px"
              />
            </div>
          </div>
          <!--
          <div
            :class="!isSeller ? 'col-lg-12' : 'col-lg-12'"
            style="margin-top: 2rem"
          >
            <graphic-leads-seller></graphic-leads-seller>
          </div>
          !-->
        </template>
      </card>
    </div>

    <div
      :class="!isService ? 'container-fluid mt--1' : 'container-fluid mt--7'"
      v-bind:style="{ cursor: pointer2 }"
      v-if="!isSeller && !isManagerSeller"
      style="margin-top: 400px"
    >
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col">
            <h5 class="h2 mb-0">
              {{
                !isService
                  ? "Actividades diarias Servicio al Cliente"
                  : "Mi actividad diaria"
              }}
            </h5>
            <p v-if="isService && !isAdmin && !isManagerService">
              {{ isService ? this.user.name : "Global" }}
              {{ selected.sellerCount }} eventos
            </p>

            <p v-if="!isService || isAdmin || isManagerService">
              {{
                selected.service != null
                  ? service.find((s) => s.id === selected.service).name
                  : "Global"
              }}
              {{ selected.serviceCount }} eventos
            </p>
          </div>
          <div class="col">
            <ul class="nav nav-pills justify-content-end">
              <li class="nav-item mr-2 mr-md-0">
                <div class="col">
                  <div class="row justify-content-center">
                    <flat-pickr
                      v-model="periodService.start"
                      :placeholder="today"
                      class="btn btn-sm"
                      data-vv-as="Fecha"
                      name="startPeriod"
                      :config="config"
                      style="margin-top: 1rem; background-color: #fff"
                    />
                    <base-button
                      type="info text-black"
                      size="sm"
                      style="margin-top: 1rem"
                      @click="setPeriodFilterDailyService()"
                      >Aplicar</base-button
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <template>
          <div class="row">
            <div :class="!isService ? 'col-lg-9' : 'col-lg-12'">
              <div
                ref="serviceLineDailyActivities"
                style="width: 100%; height: 470px"
              />
            </div>
            <div v-if="isAdmin || isManagerService" class="col-lg-3">
              <div
                ref="serviceDailyActivities"
                style="width: 100%; height: 470px"
              />
            </div>
            <div :class="!isService ? 'col-lg-6' : 'col-lg-6'">
              <div
                ref="serviceBarDailyActivities"
                style="width: 100%; height: 470px"
              />
            </div>
            <div :class="!isService ? 'col-lg-6' : 'col-lg-6'">
              <div
                ref="serviceRadialDailyActivities"
                style="width: 100%; height: 470px"
              />
            </div>
          </div>
        </template>
      </card>
    </div>
    <!--
    <div
    class="container-fluid mt--1"
      v-bind:style="{ cursor: pointer2 }"
      v-if="isAdmin || isManagerSeller || isManagerService"
      style="margin-top: 50px">
      <card>
        <div class="row">
            <div class="col-3">
              <base-button class="sm" slot="title" type="info" style="width: 100%" size="sm" @click="graphicCommi=!graphicCommi">
                {{graphicCommi?'Ocultar gráfica Comisiones':'Ver gráfica Comisiones'}}</base-button>
            </div>
            <div class="col-3">
              <base-button class="sm" slot="title" type="success" style="width: 100%" size="sm" @click="graphicGradu=!graphicGradu">
                {{graphicGradu?'Ocultar gráfica Graduados':'Ver gráfica Graduados'}}</base-button>
            </div>
            <div class="col-2">
              <base-button class="sm" slot="title" type="default" style="width: 100%" size="sm" @click="graphicInac=!graphicInac">
                {{graphicInac?'Ocultar gráfica Bajas':'Ver gráfica Bajas'}}</base-button>
            </div>
            <div class="col-2">
              <base-button class="sm" slot="title" type="primary" style="width: 100%" size="sm" @click="graphicCierres=!graphicCierres">
                {{graphicCierres?'Ocultar gráfica Cierres':'Ver gráfica Cierres'}}</base-button>
            </div>
            <div class="col-2">
              <base-button class="sm" slot="title" type="danger" style="width: 100%" size="sm" @click="graphicHistory=!graphicHistory">
                {{graphicHistory?'Ocultar gráfica Historial':'Ver gráfica Historial'}}</base-button>
            </div>
        </div>
      </card>
    </div>
    <div
      class="container-fluid mt--1"
      v-bind:style="{ cursor: pointer2 }"
      v-if="(isAdmin || isManagerSeller || isManagerService) && graphicCommi"
      style="margin-top: 50px"
    >
      <card>
        <template>
          <div class="row">
            <div class="col-12">
              <graphic-commissions></graphic-commissions>
            </div>
          </div>
        </template>
      </card>
    </div>
    <div
      class="container-fluid mt--1"
      v-bind:style="{ cursor: pointer2 }"
      v-if="(isAdmin || isManagerSeller || isManagerService) && graphicGradu"
      style="margin-top: 50px"
    >
      <card>
        <template>
          <div class="row">
            <div class="col-12">
              <graphic-graduates></graphic-graduates>
            </div>
          </div>
        </template>
      </card>
    </div>
    
    <div
      class="container-fluid mt--1"
      v-bind:style="{ cursor: pointer2 }"
      v-if="(isAdmin || isManagerSeller || isManagerService) && graphicInac"
      style="margin-top: 50px"
    >
      <card>
        <template>
          <div class="row">
            <div class="col-12">
              <graphic-inactive-baja></graphic-inactive-baja>
            </div>
          </div>
        </template>
      </card>
    </div>
    <div
      class="container-fluid mt--1"
      v-bind:style="{ cursor: pointer2 }"
      v-if="(isAdmin || isManagerSeller || isManagerService) && graphicCierres"
      style="margin-top: 50px"
    >
      <card>
        <template>
          <div class="row">
            <div class="col-12">
              <graphic-cierres></graphic-cierres>
            </div>
          </div>
        </template>
      </card>
    </div>
    <div
      class="container-fluid mt--1"
      v-bind:style="{ cursor: pointer2 }"
      v-if="(isAdmin || isManagerSeller || isManagerService) && graphicHistory"
      style="margin-top: 50px"
    >
      <card>
        <template>
          <div class="row">
            <div class="col-12">
              <graphic-history-user></graphic-history-user>
            </div>
          </div>
        </template>
      </card>
    </div>
    !-->
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

//import GraphicLeadsSeller from "./Graphics/GraphicLeadsSeller";
/*
import GraphicCommissions from "./Graphics/GraphicCommissions";
import GraphicGraduates from "./Graphics/GraphicGraduates";
import GraphicCierres from "./Graphics/GraphicCierres";
import GraphicInactiveBaja from "./Graphics/GraphicInactiveBaja";
import GraphicHistoryUser from "./Graphics/GraphicHistoryUser";
*/
import numeral from "numeral";
import firebase from 'firebase/app';
import "firebase/messaging";
export default {
  components: {
    flatPickr,
    //GraphicCommissions,
    //GraphicGraduates,
    //GraphicCierres,
    //GraphicInactiveBaja,
    //GraphicHistoryUser,
    //GraphicLeadsSeller,
  },
  computed: {
    generalInfo() {
      return this.$store.state.generalInfo;
    },
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    sellers() {
      return this.$store.state.sellers;
    },
    service() {
      return this.$store.state.customerService;
    },
    userTracingStatus() {
      return this.$store.state.userTraceStatus;
    },
    leadStatus() {
      return this.$store.state.leadStatus;
    },
  },
  data() {
    return {
      selected: {
        service: null,
        serviceCount: 0,
        seller: null,
        sellerCount: 0,
        sellerChartType: "line",
        serviceChartType: "line",
      },
      chartTypes: {
        line: "line",
        radial: "radial",
      },
      graphicCommi:false,
      graphicGradu:false,
      graphicInac:false,
      graphicCierres:false,
      graphicHistory:false,
      isAdmin: false,
      isManagerSeller: false,
      isManagerService: false,
      isSeller: false,
      isService: false,
      chartsData: {
        sellsDailyActivities: [],
        serviceDailyActivities: [],
      },
      charts: {
        radialCharts: {
          seller: null,
          service: null,
        },
        BarCharts: {
          seller: null,
          service: null,
        },
        lineCharts: {
          seller: null,
          service: null,
        },
        consultantBarCharts: {
          seller: null,
          service: null,
        },
      },
      /*SERVICE*/
      tokenFCM: "",
      chartsDataService: {},
      testData: "1354",
      showSetPeriodActive: false,
      showSetPeriodDaily: false,
      showSetPeriodCommissions: false,
      tableDataGraduates: [],
      tableDataDevice: [],
      tableDataCierres: [],
      tableDataBank: [],
      tableDataGraphicsActive_Mes: [],
      tableDataGraphicsActive_Total: [],
      tableDataGraphicsNewClients_Device: [],
      tableDataGraphicsNewClients_Users: [],
      tableDataGraphicsCommissions_Mes: [],
      tableDataGraphicsCommissions_Total: [],
      tableDataAmount: [],
      tableDataRegister: [],
      tableClientActive: [],
      tableDataConsultant: [],
      tableDataNewClientStats: [],
      tableDataCommissions: [],
      accountsBanks: [
        {
          bank: "",
          accounts: 0,
          amount: "",
        },
      ],
      searchQuery: null,
      paginationBanks: {
        total: 0,
        perPage: 3,
        currentPage: 1,
      },
      paginationDevice: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      paginationCierres: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      paginationNewClientsStats: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      paginationCommissions: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      paginationConsultant: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
      paginationActiveGraphics: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
      period: {
        start: null,
        end: null,
      },
      periodService: {
        start: null,
        end: null,
      },
      //selectedHistory: {},
      eventFilter: null,
      deviceFilter: null,
      consultantFilter: null,
      pointer: "pointer",
      pointer2: "default",
      today: "",
      config: {
        maxDate: "today",
        dateFormat: "Y-m-d",
      },
    };
  },
  created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    this.getDay();
    //this.notifyMe(this.tokenFCM);
  },
  methods: {
    notifyMe(tokenFCM) {
      this.tokenFCM = tokenFCM;
      var messaging = firebase.messaging();
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          messaging
            .getToken()
            .then((tokenFCM) => {
              if (tokenFCM) {
                this.tokenFCM = tokenFCM;
                let url =
                  this.$v.SERVER + this.$v.SELLER_OPS.SET_FCM_TOKEN_CONSULTANTS;
                this.$axios.post(url, { token: tokenFCM }).then((response) => {
                  let data = response.data;
                  if (data[this.$v.API_SUCCESS]) {
                    console.log("Token Add");
                  }
                });
              } else {
                // Show permission request.
                console.log(
                  "No Instance ID token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
            });
        }
      });
    },
    getDay() {
      let f = new Date();
      let month = f.getMonth() + 1;
      if (month < 10) month = "0" + month;
      this.today = f.getFullYear() + "-" + month + "-" + f.getDate();
    },
    async postServer(url, payload) {
      try {
        let r = await this.$axios.post(url, payload);
        if (r.data.success) return r.data.rows
      } catch (e) {
        console.log(e);
      }
      return null;
    },

    // region consultant charts
    getDailyActivitiesByEvent(
      a,
      consultantType = "seller",
      selectedConsultant
    ) {
      let activities = JSON.parse(JSON.stringify(a));
      if (selectedConsultant)
        activities = activities.filter(
          (a) => a["upload_by"] === selectedConsultant
        );
      let data = [];
      if (consultantType === "seller")
        data = JSON.parse(JSON.stringify(this.leadStatus));
      else if (consultantType === "service")
        data = JSON.parse(JSON.stringify(this.userTracingStatus));
      let empty = [];
      for (let i = 0; i < data.length; i++) {
        let events = activities.filter((a) => a.status === data[i].status);
        if (events.length > 0) {
          data[i].events = events;
          data[i].eventCount = data[i].events.length;
        } else empty.push(data[i]);
      }
      for (let i = 0; i < empty.length; i++) {
        data.splice(data.indexOf(empty[i]), 1);
        //console.log(empty[i]);
      }
      return {
        data,
        total: activities.length,
      };
    },
    getDailyActivitiesByTimeRange(activities, consultant, timeRange = 10) {
      let count = 0;
      let data = [];
      let sorted = JSON.parse(
        JSON.stringify(
          activities.sort((a, b) => (a.created > b.created ? 1 : -1))
        )
      );
      if (consultant) {
        sorted = sorted.filter((a) => a["upload_by"] === consultant);
      }
      let startDate = this.$moment(sorted[0].created);
      startDate.set("minute", 0);
      let diff = this.$moment(sorted[sorted.length - 1].created).diff(
        startDate,
        "minutes"
      );
      for (let i = 0; i < diff; i += timeRange) {
        let b0 = this.$moment(startDate).add(i, "minutes");
        let b1 = this.$moment(startDate).add(i + timeRange, "minutes");
        let a = [];
        for (let n = 0; n < sorted.length; n++) {
          let d = this.$moment(sorted[n].created);
          if (
            (d.isAfter(b0) && d.isBefore(b1)) ||
            d.isSame(b0) ||
            d.isSame(b1)
          ) {
            a.push(sorted[n]);
          }
        }
        if (a.length > 0 || data.length > 0) {
          for (let n = 0; n < a.length; n++)
            sorted.splice(sorted.indexOf(a[n]), 1);
          count += a.length;
          data.push({
            date: b0.toDate(),
            start: b0.format(),
            end: b1.format(),
            items: a,
            eventCount: a.length,
          });
        }
      }
      return {
        data,
        total: count,
      };
    },
    getDailyActivitiesByConsultant(activities, consultants) {
      let data = [];
      for (let i = 0; i < consultants.length; i++) {
        let a = [];
        let consultant = consultants[i];
        if (consultant.enabled) {
          for (let n = 0; n < activities.length; n++) {
            if (consultant.id === activities[n]["upload_by"])
              a.push(activities[n]);
          }
          data.push({
            consultant: consultant.name,
            picture: `${this.$v.CLIENT_SERVER}${this.$v.CONSULTANT_IMG_PATH}/${consultant.picture}`,
            id: consultant.id,
            items: a,
            eventCount: a.length,
          });
          //console.log(`${this.$v.CLIENT_SERVER}${this.$v.CONSULTANT_IMG_PATH}/${consultant.picture}`);
        }
      }
      return data;
    },
    async getDailyActivities(date, consultant = "seller", retrying = 0) {
      date = this.$moment(date, "YYYY-MM-DD");
      date.set("hour", 0);
      const startDate = this.$moment(date.format()).utc();
      const endDate = this.$moment(date.format())

        .utc()
        .add(1, "days");

      let url;
      if (consultant === "seller") {
        url = `${this.$v.SERVER}${this.$v.SELLER_OPS.GET_LEAD_TRACING_DASH}`;
      } else if (consultant === "service") {
        url = `${this.$v.SERVER}${this.$v.SERVICE_OPS.GET_SERVICE_EVENTS}`;
      }
      let request = {
        limit: 99999,
        created: {
          gte: startDate.utc().format("YYYY-MM-DD"),
          lt: endDate.utc().format("YYYY-MM-DD"),
        },
      };
      if (this.isSeller || this.isService)
        Object.assign(request, { upload_by: this.user.id });
      let activities = await this.postServer(url, request);
      if (activities.length === 0) {
        if (retrying === 4) {
          return;
        }
        return this.getDailyActivities(date, consultant, (retrying += 1));
      } else if (!activities) {
        if (retrying === 4) {
          return;
        }
        return this.getDailyActivities(date, consultant, (retrying += 1));
      }
      for (let i = 0; i < activities.length; i++)
        activities[i].created = this.$moment
          .utc(activities[i].created)
          .local()
          .format();
      if (consultant === "seller")
        this.chartsData.sellsDailyActivities = activities;
      else if (consultant === "service")
        this.chartsData.serviceDailyActivities = activities;
      if (consultant === "seller") {
        let activitiesBySeller = this.getDailyActivitiesByConsultant(
          activities,
          this.sellers
        );
        if (this.charts.consultantBarCharts.seller)
          this.charts.consultantBarCharts.seller.data = activitiesBySeller;

        let activitiesByDate = this.getDailyActivitiesByTimeRange(activities);
        this.selected.sellerCount = activitiesByDate.total;
        if (this.charts.lineCharts.seller)
          this.charts.lineCharts.seller.data = activitiesByDate.data;

        let events = this.getDailyActivitiesByEvent(activities, "seller");
        this.selected.sellerCount = events.total;
        if (this.charts.radialCharts.seller)
          this.charts.radialCharts.seller.data = events.data;
        this.charts.BarCharts.seller.data = events.data;
      } else if (consultant === "service") {
        let activitiesByService = this.getDailyActivitiesByConsultant(
          activities,
          this.service
        );
        if (this.charts.consultantBarCharts.service)
          this.charts.consultantBarCharts.service.data = activitiesByService;

        let activitiesByDateService = this.getDailyActivitiesByTimeRange(
          activities
        );
        this.selected.serviceCount = activitiesByDateService.total;
        if (this.charts.lineCharts.service)
          this.charts.lineCharts.service.data = activitiesByDateService.data;

        let events = this.getDailyActivitiesByEvent(activities, "service");
        this.selected.serviceCount = events.total;
        if (this.charts.radialCharts.service) {
          this.charts.radialCharts.service.data = events.data;
          this.charts.BarCharts.service.data = events.data;
        }
      }
      this.$forceUpdate();
    },
    createDailyActivitiesLineChart(consultant = "seller") {
      let chartRef;
      if (consultant === "seller") {
        chartRef = "sellerLineDailyActivities";
      } else if (consultant === "service") {
        chartRef = "serviceLineDailyActivities";
      }
      let chart = am4core.create(this.$refs[chartRef], am4charts.XYChart);
      chart.paddingRight = 20;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;

      let series = chart.series.push(new am4charts.LineSeries());
      series.tensionX = 0.75;
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "eventCount";

      series.tooltipText = "{valueY.value}";
      chart.cursor = new am4charts.XYCursor();

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;

      if (consultant === "seller") {
        this.charts.lineCharts.seller = chart;
      } else if (consultant === "service") {
        this.charts.lineCharts.service = chart;
      }
    },
    beforeDestroy() {
      if (this.charts.lineCharts) {
        this.charts.lineCharts.dispose();
      }
      if (this.charts.BarCharts) {
        this.charts.BarCharts.dispose();
      }
      if (this.charts.RadarChart) {
        this.charts.RadarChart.dispose();
      }
      if (this.charts.consultantBarCharts) {
        this.charts.consultantBarCharts.dispose();
      }
    },
    createDailyActivitiesChartByConsultant(consultant = "seller") {
      let chartRef;
      if (consultant === "seller") {
        chartRef = "sellersDailyActivities";
      } else if (consultant === "service") {
        chartRef = "serviceDailyActivities";
      }
      let chart = am4core.create(this.$refs[chartRef], am4charts.XYChart);
      chart.paddingRight = 20;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields["category"] = "consultant";
      categoryAxis.renderer.labels.template.adapter.add("text", function () {
        return "";
      });
      categoryAxis.renderer.labels.template.rotation = -45;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = false;
      valueAxis.renderer.minWidth = 35;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields["categoryX"] = "consultant";
      series.dataFields["valueY"] = "eventCount";

      series.tooltipText = "{valueY.value}";
      series.columns.template.events.on(
        "hit",
        (ev) => {
          const cname = ev.target.dataItem["categoryX"];
          if (consultant === "seller") {
            let id = this.sellers.find((s) => s.name === cname).id;
            if (this.selected.seller === id) this.selected.seller = null;
            else this.selected.seller = id;
            let d = this.getDailyActivitiesByTimeRange(
              this.chartsData.sellsDailyActivities,
              this.selected.seller
            );
            this.selected.sellerCount = d.total;
            this.charts.lineCharts.seller.data = d.data;
            d = this.getDailyActivitiesByEvent(
              this.chartsData.sellsDailyActivities,
              "seller",
              this.selected.seller
            );
            this.selected.sellerCount = d.total;
            this.charts.radialCharts.seller.data = d.data;
            this.charts.BarCharts.seller.data = d.data;
          } else if (consultant === "service") {
            let id = this.service.find((s) => s.name === cname).id;
            if (this.selected.service === id) this.selected.service = null;
            else this.selected.service = id;
            let d = this.getDailyActivitiesByTimeRange(
              this.chartsData.serviceDailyActivities,
              this.selected.service
            );
            this.selected.serviceCount = d.total;
            this.charts.lineCharts.service.data = d.data;
            d = this.getDailyActivitiesByEvent(
              this.chartsData.serviceDailyActivities,
              "service",
              this.selected.service
            );
            this.selected.serviceCount = d.total;
            this.charts.radialCharts.service.data = d.data;
            this.charts.BarCharts.service.data = d.data;
          }
          this.$forceUpdate();
        },
        this
      );

      //chart.cursor = new am4charts.XYCursor();

      chart.maskBullets = false;
      chart.paddingBottom = 0;
      /*
      let bullet = series.bullets.push(new am4charts.Bullet());
      
      let image = bullet.createChild(am4core.Image);
      image.horizontalCenter = "middle";
      image.verticalCenter = "bottom";
      
      image.dy = 20;
      image.y = am4core.percent(100);
      image.propertyFields.href = "picture";
      image.tooltipText = series.columns.template.tooltipText;
      image.propertyFields.fill = "color";
      image.filters.push(new am4core.DropShadowFilter());
      */

      let columnTemplate = series.columns.template;
      columnTemplate.width = am4core.percent(60);
      columnTemplate.maxWidth = 60;
      columnTemplate.column.cornerRadius(60, 60, 10, 10);
      columnTemplate.strokeOpacity = 0;

      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        dataField: "valueY",
      });
      series.mainContainer.mask = undefined;
      let bullet = columnTemplate.createChild(am4charts.CircleBullet);
      bullet.circle.radius = 20;
      bullet.valign = "center";
      bullet.align = "center";
      bullet.isMeasured = true;
      bullet.mouseEnabled = false;
      bullet.verticalCenter = "bottom";
      bullet.interactionsEnabled = false;

      //let hoverState = bullet.states.create("hover");
      let outlineCircle = bullet.createChild(am4core.Circle);
      outlineCircle.adapter.add("radius", function (radius, target) {
        let circleBullet = target.parent;
        return circleBullet.circle.pixelRadius;
      });

      let image = bullet.createChild(am4core.Image);
      image.width = 40;
      image.height = 40;
      image.align = "center";
      image.horizontalCenter = "middle";
      image.verticalCenter = "middle";
      image.propertyFields.href = "picture";

      image.adapter.add("mask", function (mask, target) {
        let circleBullet = target.parent;
        return circleBullet.circle;
      });
      /*let bullet = series.bullets.push(new am4charts.LabelBullet);
      bullet.label.text = "{consultant}";
      bullet.label.rotation = -90;
      bullet.label.truncate = false;
      bullet.label.hideOversized = false;*/

      if (consultant === "seller") {
        this.charts.consultantBarCharts.seller = chart;
        this.getDailyActivities(this.today, "seller");
      } else if (consultant === "service") {
        this.charts.consultantBarCharts.service = chart;
        this.getDailyActivities(this.today, "service");
      }
    },

    createBarDailyActivitiesChartByConsultant(consultant = "seller") {
      let chartRef;
      let categoryName;
      if (consultant === "seller") {
        chartRef = "sellersBarDailyActivities";
        categoryName = "st";
      } else if (consultant === "service") {
        chartRef = "serviceBarDailyActivities";
        categoryName = "status";
      }
      let chart = am4core.create(this.$refs[chartRef], am4charts.XYChart);
      chart.paddingRight = 0;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields["category"] = categoryName;
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.renderer.labels.template.adapter.add("text", function () {
        return "";
      });
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = false;
      valueAxis.renderer.minWidth = 35;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields["categoryX"] = categoryName;
      series.dataFields["valueY"] = "eventCount";

      series.tooltipText = "{valueY.value}";

      chart.cursor = new am4charts.XYCursor();
      let bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.label.text = "{categoryX}";
      bullet.label.rotation = -90;
      bullet.label.truncate = false;
      bullet.label.hideOversized = false;

      chart.maskBullets = false;

      chart.paddingBottom = 0;

      if (consultant === "seller") {
        this.charts.BarCharts.seller = chart;
        this.getDailyActivities(this.today, "seller");
      } else if (consultant === "service") {
        this.charts.BarCharts.service = chart;
        this.getDailyActivities(this.today, "service");
      }
    },

    createDailyActivitiesRadialChart(consultant = "seller") {
      let chartRef;
      let categoryName;
      if (consultant === "seller") {
        chartRef = "sellerRadialDailyActivities";
        categoryName = "st";
      } else if (consultant === "service") {
        chartRef = "serviceRadialDailyActivities";
        categoryName = "status";
      }
      let chart = am4core.create(this.$refs[chartRef], am4charts.RadarChart);
      chart.hiddenState.properties.opacity = 0;
      chart.paddingRight = 20;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields["category"] = categoryName;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.axisFills.template.fill = chart.colors.getIndex(2);
      valueAxis.renderer.axisFills.template.fillOpacity = 0.5;

      let series = chart.series.push(new am4charts.RadarSeries());
      series.dataFields["valueY"] = "eventCount";
      series.dataFields["categoryX"] = categoryName;
      series.name = categoryName;
      series.strokeWidth = 3;
      series.tooltipText = "{valueY.value}";

      chart.cursor = new am4charts.RadarCursor();

      if (consultant === "seller") {
        this.charts.radialCharts.seller = chart;
      } else if (consultant === "service") {
        this.charts.radialCharts.service = chart;
      }
    },
    // endregion

    formatCurrency(num) {
      return numeral(num).format("$ 0,0.00");
    },
    formatCurrencyPorcentaje(num) {
      return numeral(num).format("0,0.00");
    },
    setPeriodActive() {
      this.showSetPeriodActive = true;
      this.period.start = null;
      this.period.end = null;
    },
    setPeriodDaily() {
      this.showSetPeriodDaily = true;
      this.period.start = null;
      this.period.end = null;
    },
    setPeriodCommissions() {
      this.showSetPeriodCommissions = true;
      this.period.start = null;
      this.period.end = null;
    },
    setConsultantFilter(value) {
      this.consultantFilter = value;
      this.getCierres(1);
    },
    setPeriodFilterGraphics(value) {
      if (value != 2) {
        this.showSetPeriodActive = false;
        this.periodFilter = value;
        this.setPeriodFilterActive(value);
      }
    },

    MostrarTodosCommission() {
      this.tableDataGraphicsCommissions_Total = [];
      this.tableDataGraphicsCommissions_Mes = [];
      this.period.start = "";
      this.period.end = "";
      this.periodFilter = "";
      this.showSetPeriodCommissions = false;
      this.getGraphicsCommissions();
    },
    setPeriodFilterCommissionsGraphics(value) {
      if (value === 1) {
        let date = new Date();
        let month = date.getMonth() + 1;
        if (month < 10) month = "0" + month;
        this.period.start = date.getFullYear() + "-" + month + "-" + "01";
      }
      this.periodFilter = value;
      this.tableDataGraphicsCommissions_Total = [];
      this.tableDataGraphicsCommissions_Mes = [];
      this.getGraphicsCommissions();
    },
    MostrarTodos() {
      this.tableDataGraphicsActive_Total = [];
      this.tableDataGraphicsActive_Mes = [];
      this.period.start = "";
      this.period.end = "";
      this.periodFilter = "";
      this.showSetPeriodActive = false;
      this.getGraphicsActive();
    },
    setPeriodFilterActive(value) {
      if (value === 1) {
        let date = new Date();
        let month = date.getMonth() + 1;
        if (month < 10) month = "0" + month;
        this.period.start = date.getFullYear() + "-" + month + "-" + "01";
      }
      this.periodFilter = value;
      this.tableDataGraphicsActive_Total = [];
      this.tableDataGraphicsActive_Mes = [];
      this.getGraphicsActive();
    },

    setPeriodFilterDaily() {
      this.getDailyActivities(
        this.$moment(this.period.start).format("YYYY-MM-DD"),
        "seller"
      );
    },
    setPeriodFilterDailyService() {
      this.getDailyActivities(
        this.$moment(this.periodService.start).format("YYYY-MM-DD"),
        "service"
      );
    },

    setPeriodFilter(value) {
      if (value === 1) {
        let date = new Date();
        let month = date.getMonth() + 1;
        if (month < 10) month = "0" + month;
        this.period.start = date.getFullYear() + "-" + month + "-" + "01";
      }
      this.periodFilter = value;
      this.getCierres(1);
    },
    setPeriodFilterCommissions(value) {
      if (value === 1) {
        let date = new Date();
        let month = date.getMonth() + 1;
        if (month < 10) month = "0" + month;
        this.period.start = date.getFullYear() + "-" + month + "-" + "01";
      }
      this.periodFilter = value;
      this.getCommissions(1);
    },
    getAmount() {
      let url = this.$v.SERVER + this.$v.STADISTICS_OPS.GET_V_AMOUNT_ENTERED;
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.tableDataAmount = data[this.$v.API_ROW];
        }
      });
    },
    getRegister() {
      let url = this.$v.SERVER + this.$v.STADISTICS_OPS.GET_V_REGISTER_INFO;
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.tableDataRegister = data[this.$v.API_ROW];
        }
      });
    },
    getClientActive() {
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_INACTIVE_USER;
      let request = {
        value: "4",
      };
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.tableClientActive = data[this.$v.API_ROW];
        }
      });
    },
    getTotalGraduates() {
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_INACTIVE_USER;
      let request = {
        value: "2",
      };
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.tableDataGraduates = data[this.$v.API_ROW];
        }
      });
    },
  },

  mounted() {
    if (this.isAdmin || this.isManagerSeller) {
      this.createDailyActivitiesLineChart("seller");
      this.createDailyActivitiesChartByConsultant("seller");
      this.createBarDailyActivitiesChartByConsultant("seller");
      this.createDailyActivitiesRadialChart("seller");
      this.getTotalGraduates();
      this.getRegister();
      this.getClientActive();
      this.getAmount();
    }
    if (this.isAdmin || this.isManagerService) {
      this.createDailyActivitiesLineChart("service");
      this.createDailyActivitiesChartByConsultant("service");
      this.createBarDailyActivitiesChartByConsultant("service");
      this.createDailyActivitiesRadialChart("service");
      this.getTotalGraduates();
      this.getRegister();
      this.getClientActive();
      this.getAmount();
    }
    if (this.isSeller) {
      this.createDailyActivitiesLineChart("seller");
      //this.createDailyActivitiesChartByConsultant("seller");
      this.createBarDailyActivitiesChartByConsultant("seller");
      this.createDailyActivitiesRadialChart("seller");
    }
    if (this.isService) {
      this.createDailyActivitiesLineChart("service");
      //this.createDailyActivitiesChartByConsultant("service");
      this.createBarDailyActivitiesChartByConsultant("service");
      this.createDailyActivitiesRadialChart("service");
    }
    /*this.getAccountsPerBanks(1);
    this.getHistoryPerDevice(1);
    this.getCierres(1);
    this.getConsultantInfo(1);
    this.getNewClientsStats(1);
    this.getCommissions(1);this.getGraphicsActive();
    this.getGraphicsNewClientsStats();
    this.getGraphicsCommissions();*/
  },
};
</script>
<style></style>
