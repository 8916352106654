<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center text-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{statusFilter ? statusFilter : "Seguimiento"}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setStatusFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setStatusFilter('Sin seguimiento')">Sin seguimiento</a>
                        <a class="dropdown-item" v-on:click="setStatusFilter(status.status)" v-for="(status, index) in leadStatus" :key="index">{{status.status}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="info" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{ownClientsFilter? 'Mis clientes':'Todos'}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setOwnClientsFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setOwnClientsFilter(1)">Mis clientes</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="info" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getFollowFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setFollowFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setFollowFilter(1)">Clientes potenciales</a>
                        <a class="dropdown-item" v-on:click="setFollowFilter(0)">Clientes no potenciales</a>
                    </base-dropdown>
                </div>
                <div v-if="isAdmin || isManagerSeller" class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getSellerFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setSellerFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-for="(seller, index) in sellers" :key="index" v-on:click="setSellerFilter(seller.id)">{{seller.name}} {{seller.lastname_1}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getPeriodFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
                        <div v-if="!showSetPeriod" class="dropdown-divider"></div>
                        <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
                    </base-dropdown>
                </div>                
                <div v-if="showSetPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
                        <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :period="period"
                        :data="data"
                        v-on:click="leadSelected">

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">ID</th>
                    <th v-bind:style="{cursor:pointer2}">Nombre</th>
                    <th v-bind:style="{cursor:pointer2}" v-if="isAdmin || isManagerSeller || isSeller">Vendedor</th>
                    <!--th v-bind:style="{cursor:pointer2}">email</th-->
                    <th v-bind:style="{cursor:pointer2}">Teléfono</th>
                    <th v-bind:style="{cursor:pointer2}" v-if="!isSeller">Whatsapp</th>
                    <th v-bind:style="{cursor:pointer2}">Total adeudado</th>
                    <th v-bind:style="{cursor:pointer2}">Datos bancarios</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha</th>
                    <th v-bind:style="{cursor:pointer2}">NMB</th>
                    <th v-if="isAdmin || isManagerSeller" >Reasignaciones</th>
                    <th v-bind:style="{cursor:pointer2}">Seguimiento</th>
                </template>

                <template slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-primary text-sm" v-on:click.stop v-on:click="toClient(row)">{{row.id}} <i class="fas fa-user-edit text-primary"></i></span>
                            </div>
                        </div>
                    </th>
                    <td>{{row.name}}</td>
                    <td v-if="isAdmin" class="text-center" v-on:click.stop="">
                        <base-dropdown class="dropdown">
                            <a slot="title" class="btn btn-sm" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {{getSellerName(row.seller)}}
                            </a>
                            <template>
                                <a v-for="(seller) in sellers" :key="seller.id" class="dropdown-item" v-on:click="assignToSeller(row, seller)">{{seller.name}} {{seller.lastname_1}}</a>
                                <div class="dropdown-divider"></div>
                                <a v-for="(customerS) in customerService" :key="customerS.id" class="dropdown-item" v-on:click="assignToSeller(row, customerS)">{{customerS.name}} {{customerS.lastname_1}}</a>
                            </template>
                        </base-dropdown>
                    </td>
                    <td v-else-if="isManagerSeller || isSeller" class="text-center" v-on:click.stop="">
                                {{getSellerName(row.seller)}}
                    </td>
                    <!--td>{{row.email}}</td-->
                    <td><span>{{row.phone}} <i :class="getIconValidatedClass(row.validated)"></i> 
                    <base-button
                      id="showModal"
                      type="outline-success"
                      class="p-2"
                      size="sm"
                      style="margin-bottom:0.5rem; margin-left:0.5rem;"
                      v-on:click="callUser(row)"
                      ><i class="fas fa-phone text-success"></i> Llamar </base-button></span></td>
                    <td v-if="!isSeller">
                        <a :href="getWhatsappURL(row)" target="_blank">
                            <img class="avatar avatar-sm" src="img/icons/whatsapp.png" style="background-color: #FFFFFF" alt="whatsapp">
                        </a>
                    </td>
                    <td v-if="row.calculation && row.calculation.totalDebt && row.calculation.totalDebt != 0">$ {{row.calculation.totalDebt.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</td>
                    <td v-else>{{formatCurrency(row.amount)}}</td>
                    <td v-on:click.stop="">
                        <div v-if="row.calculation" class="col p-0">
                            <base-button id="requestSignatureToken" type="outline-success" class="p-2" size="sm" v-on:click="calcuLeadSelected(row)">
                                Ver calculadora
                            </base-button>
                        </div>
                        <div v-else-if="row.banks" class="avatar-group">
                            <a v-for="(bank, index) in getBanks(JSON.parse(row.banks))" :key="index" class="avatar avatar-sm rounded-circle" style="background-color: #FFFFFF" data-toggle="tooltip" :data-original-title="bank.name">
                                <img :alt="bank.name" :src="bank.url">
                            </a>
                        </div>
                        <div v-else class="col p-0">
                            Sin datos
                        </div>                            
                    </td>
                    <td>{{formatDate(row.created)}}</td>
                    <td>
                        <div class="d-flex align-items-center">
                            <span v-bind:class="getValidatedClass(!row.same_bank_debt_paysheet)">{{row.same_bank_debt_paysheet?"SI":"NO"}}</span>
                        </div>
                    </td>
                    <td v-if="isAdmin || isManagerSeller" class="text-center">{{row.reasigned}}</td>
                    <td>{{row.status}}</td>
                </template>

            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} prospectos
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';

    export default {
        name: 'clients-table',
        components: {
            flatPickr
        },
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            period: {
                start: null,
                end: null
            },
        },
        data() {
            return {
              isAdmin: false,
              isManagerSeller: false,
              isSeller: false,
              lead: {},
              tableData: [],
              TracingTableData: [],
              showSetPeriod:false,
              followFilter: null,
              sellerFilter: null,
              periodFilter: null,
              ownClientsFilter: null,
              statusFilter: null,
              pointer: 'pointer',
              pointer2: 'default'
            }
        },
        computed: {
            banks() { return this.$store.state.banks },
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            leadStatus() { return this.$store.state.leadStatus },
            sellers() { 
                let sellerManagerList = [];
                let allSellers = this.$store.state.sellers
                if (this.isManagerSeller){
                    for(let i=0; i<allSellers.length; i++){
                        if(allSellers[i].leader == this.user.id)
                            sellerManagerList.push(allSellers[i])
                    }return sellerManagerList;
                }else return allSellers;
            },
            customerService() { return this.$store.state.customerService; },
        },
        async created() {
          this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
          this.isSeller = this.user.role === this.$v.ROLES.SELLER;
          this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
        },
        methods: {
            toClient(lead) {
                this.$emit("toClient", lead);
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            calcuLeadSelected(lead) {
                this.$emit("calcuLeadSelected", lead);
            },
            leadSelected(lead) {
                this.$emit("leadSelected", lead);
            },
            getIconValidatedClass(validated) {
                if (validated) return "fas fa-check text-success"
                return "fas fa-times text-danger"
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            getBanks(array) {
                let banks = []
                if(array) {
                    for (let a=0 ; a<=array.length ; a++) {
                        for (let b = 0; b < this.banks.length; b++) {
                            if (this.banks[b].id === array[a]) {
                                banks.push(this.banks[b])
                                break
                            }
                        }
                    }
                }
                return banks
            },
            formatDate(date) {
            if (date <= "2020-11-10") {
                return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
            } else {
                return this.$moment(date).format("DD MMM YYYY, h:mm a");
            }
            },
            getWhatsappURL(lead) {
                return 'https://wa.me/' + lead.phone + '?text=Hola, mi nombre es ' + this.user.name + ' 😃 me pongo en contacto contigo porque te registraste en nuestra página solicitando una asesoría financiera totalmente gratuita. ¿Cuéntame con quién tienes tu deuda? Para ver de que forma te podemos ayudar.'
            },
            callUser(lead){
                let url = this.$v.SERVER + this.$v.SELLER_OPS.SEND_STARTING_CALL_PUSH_NOTIFICATION;
                this.$showLoader(true);
                let request={
                    "lead": lead.id,
                }
                this.$axios.post(url, request).then((response) => {
                    this.$showLoader(false);
                    let data = response.data;
                    if (data.success) {
                        this.$vs.notification(this.$notification(
                'success',
                 'Llamando al cliente...... '
                     ));
                    }else{
                        this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    }
            });
            },
            setOwnClientsFilter(value) {
                this.ownClientsFilter = value;
                this.$emit("setOwnClientsFilter", value);
            },
            setStatusFilter(value) {
                this.statusFilter = value
                this.followFilter = null
                this.$emit("setStatusFilter", value);
            },
            setFollowFilter(value) {
                this.followFilter = value
                this.statusFilter = null
                this.$emit("setFollowFilter", value);
            },
            getFollowFilterText() {
                if (this.followFilter === null) return "Tipo de cliente"
                if (this.followFilter === 1) return "Clientes potenciales"
                return "Clientes no potenciales"
            },
            getSellerFilterText() {
                if (this.sellerFilter) return this.getSellerName(this.sellerFilter)
                return "Vendedores"
            },
            setSellerFilter(sellerID) {
                this.sellerFilter = sellerID
                this.$emit("setSellerFilter", sellerID);
            },
            setPeriod(){
                this.showSetPeriod=true
                this.period.start=null
                this.period.end=null
                this.getPeriodFilterText()                
            },
            setPeriodFilter(value) {        
                if(value!=2){
                    this.showSetPeriod = false
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                    
                }else if(this.period.start && this.period.end){
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                }
            },
            getPeriodFilterText() {
                if(this.showSetPeriod)
                    return "Periodo del: "
                else if(this.periodFilter === 1)
                    return "Mes actual"
                else return "Fecha"
            },
            getSellerName(sellerID) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === sellerID) return this.sellers[i].name
                return "- - -"
            },
            assignToSeller(lead, seller) {
                let url = this.$v.SERVER + this.$v.SELLER_OPS.ASSIGN_LEAD
                this.$showLoader(true)
                this.$axios.post(url, {
                    lead: lead.id,
                    seller: seller.id
                }).then(response => {
                    this.$showLoader(false)
                    let data = response.data
                    if (data[this.$v.API_SUCCESS]) {
                        lead.seller = seller.id
                        this.$vs.notification(this.$notification(
                'success',
                 'Asignación realizada exitosamente'
                     ));
                     this.pageSelected(1);
                        this.$forceUpdate()
                    }
                })
            },
        }
    }
</script>
<style>
</style>
