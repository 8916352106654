<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col-lg-2 text-right">
          <base-dropdown style="width: 100%">
            <base-button
              slot="title"
              type="warning"
              outline
              class="dropdown-toggle"
              style="width: 100%"
              size="sm"
            >
              {{ getCustomerFilterText() }}
            </base-button>
            <a class="dropdown-item" v-on:click="setConsultantFilter(null)"
              >Mostrar todos</a
            >
            <div class="dropdown-divider"></div>
            <div v-if="title === 'Actividad ventas'">
              <a
                class="dropdown-item"
                v-for="seller in sellers"
                :key="seller.id"
                v-on:click="setConsultantFilter(seller.id)"
                >{{ seller.name }} {{ seller.lastname_1 }}</a
              >
            </div>
            <div v-else>
              <a
                v-for="customerS in customerService"
                :key="customerS.id"
                class="dropdown-item"
                v-on:click="setConsultantFilter(customerS.id)"
                >{{ customerS.name }} {{ customerS.lastname_1 }}</a
              >
            </div>
          </base-dropdown>
        </div>
        <div class="col-lg-2 text-center">
          <base-dropdown style="width: 100%">
            <base-button
              slot="title"
              type="primary"
              outline
              class="dropdown-toggle"
              size="sm"
              style="width: 100%"
            >
              {{ statusFilter ? statusFilter : "Seguimiento" }}
            </base-button>
            <a class="dropdown-item" v-on:click="setStatusFilter(null)"
              >Mostrar todos</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              v-for="(status, index) in title === 'Actividad ventas'
                ? leadStatus
                : userTracingStatus"
              :key="index"
              v-on:click="setStatusFilter(status)"
              >{{ status.status }}</a
            >
          </base-dropdown>
        </div>
        <div class="col">
          <div class="row justify-content-center">
            <flat-pickr
              v-if="title === 'Actividad ventas'"
              v-model="periodFilterS"
              :placeholder="today"
              class="btn btn-sm"
              data-vv-as="Fecha"
              name="period"
              :config="config"
              style="margin-top: 1rem; background-color: #fff"
            />
            <flat-pickr
              v-else
              v-model="periodFilterCS"
              :placeholder="today"
              class="btn btn-sm"
              data-vv-as="Fecha"
              name="period"
              :config="config"
              style="margin-top: 1rem; background-color: #fff"
            />
            <base-button
              type="info text-black"
              size="sm"
              style="margin-top: 1rem"
              @click="setPeriodFilter()"
              >Aplicar</base-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive" v-bind:style="{ cursor: pointer }">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
      >
        <!--v-on:click="clientSelected"-->

        <template slot="columns">
          <th v-bind:style="{ cursor: pointer }" class="text-center">
            Usuario
          </th>
          <th
            v-if="title === 'Actividad ventas'"
            v-bind:style="{ cursor: pointer }"
            class="text-center"
          >
            Prospecto
          </th>
          <th v-bind:style="{ cursor: pointer }">Estatus</th>
          <th v-bind:style="{ cursor: pointer }" class="text-center">
            Consultor
          </th>
          <th v-bind:style="{ cursor: pointer }">Comentarios</th>
          <th v-bind:style="{ cursor: pointer }" class="text-center">
            Fecha de creación
          </th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <a
                  :href="'https://admin.dbmenos.com/client?id=' + row.user"
                  target="_blank"
                >
                  <span class="name mb-0 text-sm"
                    >{{ row.user }}<i class="fas fa-user-edit text-primary"></i
                  ></span>
                </a>
              </div>
            </div>
          </th>
          <td v-if="title === 'Actividad ventas'" class="text-center">
            {{ row.lead }}
          </td>
          <td>
            {{
              title === "Actividad ventas"
                ? row.status
                : getStatusText(row.status)
            }}
          </td>
          <td class="text-center">{{ getConsultantName(row.upload_by) }}</td>
          <td>{{ row.comments }}</td>
          <td>{{ formatDate(row.created) }}</td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="col">
        <p class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          Página {{ pagination.currentPage }} de
          {{ Math.ceil(pagination.total / pagination.perPage) }}<br />{{
            pagination.total
          }}
          Actividades
        </p>
      </div>
      <base-pagination
        size="sm"
        :total="pagination.total"
        :per-page="pagination.perPage"
        :value="pagination.currentPage"
        v-on:pageSelected="pageSelected"
      />
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "activity-tables",
  components: {
    flatPickr,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    data: Array,
    pagination: Object,
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    sellers() {
      return this.$store.state.sellers;
    },
    leadStatus() {
      return this.$store.state.leadStatus;
    },
    userTracingStatus() {
      return this.$store.state.userTraceStatus;
    },
    customerService() {
      return this.$store.state.customerService;
    },
  },
  data() {
    return {
      isAdmin: false,
      tableData: [],
      statusFilter: null,
      consultantFilter: null,
      pointer: "pointer",
      pointer2: "default",
      periodFilterS: null,
      periodFilterCS: null,
      today: "",
      config: {
        maxDate: "today",
        dateFormat: "Y-m-d",
      },
    };
  },
  created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
  },
  mounted() {
    this.getDay();
  },
  methods: {
    formatDate(date) {
      if (date <= "2020-11-10") {
        return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
      } else {
        return this.$moment(date).format("DD MMM YYYY, h:mm a");
      }
    },
    pageSelected(page) {
      this.$emit("pageSelected", page);
    },
    setConsultantFilter(value) {
      this.consultantFilter = value;
      this.$emit("setConsultantFilter", value);
    },
    getCustomerFilterText() {
      if (this.consultantFilter)
        return this.getConsultantName(this.consultantFilter);
      return "Consultores";
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id) return this.sellers[i].name;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return this.customerService[i].name;
      return "- - -";
    },
    setStatusFilter(value) {
      this.statusFilter = value ? value.status : null;
      this.$emit("setStatusFilter", value);
    },
    getStatusText(id) {
      for (let i = 0; i < this.userTracingStatus.length; i++)
        if (this.userTracingStatus[i].id === id)
          return this.userTracingStatus[i].status;
      return "- - -";
    },
    setPeriodFilter() {
      this.$emit("setPeriodFilter", this.periodFilterS, this.periodFilterCS);
    },
    getDay() {
      let f = new Date();
      let month = f.getMonth() + 1;
      if (month < 10) month = "0" + month;
      this.today = f.getFullYear() + "-" + month + "-" + f.getDate();
      if (!this.periodFilterCS && !this.periodFilterS) {
        this.periodFilterS = this.periodFilterCS = this.today;
        this.setPeriodFilter();
        this.periodFilterS = this.periodFilterCS = null;
      }
    },
  },
};
</script>
<style>
</style>