<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8">
		</base-header>

		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<user-history-table title="Historial de usuarios"
							:data="tableData"
							:period="period"
							:pagination="pagination"
							v-on:pageSelected="getUserHistory"
							v-on:setEventFilter="setEventFilter"
							v-on:setDeviceFilter="setDeviceFilter"
							v-on:setPeriodFilter="setPeriodFilter"
							v-on:setConsultantFilter="setConsultantFilter"/>
				</div>
			</div>
		</div>
		<graphic-history v-if="isAdmin"></graphic-history>
	</div>
</template>
<script>
	import UserHistoryTable from './Tables/UserHistoryTable'
	import GraphicHistory from "./Graphics/GraphicHistory"
	export default {
		name: 'tables',
		components: {
			UserHistoryTable,
			GraphicHistory
		},
		mounted() {
			this.getUserHistory(1)
			this.$root.$on('searchQuery', query => {
				if (query.length > 0)
					this.searchQuery = query
				else
					this.searchQuery = null
				this.getUserHistory(1)
			})
		},
		computed: {
			user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
		},
		data() {
			return {
				isAdmin: false,
				isAuditor: false,
				isManagerSeller: false,
				searchQuery: null,
				pagination: {
					total: 0,
					perPage: 10,
					currentPage: 1
				},
				tableData: [],
				period: {
					start: null,
					end: null
				},
				//selectedHistory: {},
				eventFilter: null,
				deviceFilter: null,
				consultantFilter: null,
				pointer: 'pointer',
				pointer2: 'default',
			};
		},
		async created() {
			this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
			this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR
			this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER
        },
		methods: {
			getUserHistory(page) {
				let limit = this.pagination.perPage
				let queryGetHistory = this.isAdmin ? this.$v.ADMIN_OPS.GET_USER_HISTORY : this.$v.SELLER_OPS.GET_USER_HISTORY
				let url = this.$v.SERVER + queryGetHistory + "?offset=" + limit*(page - 1) + "&limit=" + limit
				if(!(this.isAdmin||this.isManagerSeller||this.isAuditor)) url += "&consultant=" + this.user.id
				if (this.searchQuery) url += "&find=" + this.searchQuery
				if (this.periodFilter === 1) url += "&periodStart="+this.period.start
				if (this.periodFilter === 2) url += "&periodStart="+this.period.start+"&periodEnd="+this.period.end
				if (this.eventFilter) url += "&event=" + this.eventFilter
				if (this.deviceFilter) url += "&device=" + this.deviceFilter
				if (this.consultantFilter) url += "&consultant=" + this.consultantFilter
				this.$showLoader(true)
				this.$axios.get(url).then(response => {
					this.$showLoader(false)
					let data = response.data
					if (data[this.$v.API_SUCCESS]) {
						this.tableData = data[this.$v.API_ROWS]
						let p = data[this.$v.API_ROW]
						this.pagination.total = p['totalItems']
						this.pagination.currentPage = page
						this.pagination.perPage = limit
					} else { this.$validateSession(data) }
				})
			},
			setEventFilter(value) {
				this.eventFilter = value
				this.getUserHistory(1)
			},
			setDeviceFilter(value) {
				this.deviceFilter = value
				this.getUserHistory(1)
			},
			setConsultantFilter(value) {
				this.consultantFilter = value
				this.getUserHistory(1)
			},
			setPeriodFilter(value) {
				if(value === 1){
					let date = new Date()
					let month = date.getMonth()+1
					if(month<10) month= "0"+month
					this.period.start = date.getFullYear()+"-"+month+"-"+"01"
				}
				this.periodFilter = value
				this.getUserHistory(1)
			}
		}
	};
</script>
<style></style>