<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-dbm opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-11 center">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a>
                    <img
                      src="img/theme/avatar.jpg"
                      class="rounded-circle"
                      style="background-color: aliceblue"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div class="d-flex justify-content-between">
                <base-button size="sm" type="danger" class="float-right" icon="fas fa-arrow-left"
                  @click="backWindows()">Regresar</base-button>
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div class="row card-profile-stats justify-content-center d-flex mt-md-1">
                    <div class="col-lg-12">
                      <span class="heading">{{debt.name}}</span>
                      <span class="description">Cliente</span>
                    </div>
                    <div class="col-lg-12">
                      {{ debt.email }}
                    </div>
                    <div>
                      <span class="heading">{{ debt.phone }}</span>
                      <span class="description">Teléfono</span>
                    </div>
                    <div>
                      <span class="heading">{{ $formatNormalDate(debt.birthday) }}</span>
                      <span class="description">Fecha de nacimiento</span>
                    </div>
                    <div>
                      <span class="heading">{{ debt.curp }}</span>
                      <span class="description">CURP</span>
                    </div>
                    <div class="col-lg-12">
                      <span class="heading">{{debt.address+' '+debt.locality+', '+debt.state}}</span>
                      <span class="description">Dirección</span>
                    </div>
                    <div class="col-lg-12">
                      <span class="heading">{{debt.postal_code}}</span>
                      <span class="description">Código Postal</span>
                    </div>
                    <div class="col-lg-12">
                      <hr class="my-4" />
                      <h6 class="heading-small text-muted mb-4">Deuda</h6>
                    </div>
                    <div class="row">
                      <div class='col-auto'>
                        <base-button type="success" size="sm" @click="showModalReport=true">Ver Buró de Crédito</base-button> <br>
                        <span class="description">Documento</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{$formatCurrency(debt.finamigoBalance)}}</span>
                        <span class="description">Saldo finamigo</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{$formatDate(debt.last_pay_bank)}}</span>
                        <span class="description">Último pagó al banco</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{$formatCurrency(debt.total_savings)}}</span>
                        <span class="description">Saldo</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class='col-auto'>
                        <span class="heading">{{debt.bank}}</span>
                        <span class="description">Banco</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{$formatCurrency(debt.total_amount)}}</span>
                        <span class="description">Total adeudado</span>
                      </div>
                      <div class='col-auto'>
                        <span :class="getColorDebtStatus()">{{debt.status}}</span>
                        <span class="description">Estado deuda</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class='col-auto'>
                        <span class="heading">{{debt.account}}</span>
                        <span class="description">Número de cuenta</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{$formatCurrency(debt.amount_no_fee)}}</span>
                        <span class="description">Liquidación esperada sin comisión</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{getProcedureStatus()}}</span>
                        <span class="description">Estado del trámite</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class='col-auto'>
                        <span class="heading">{{lastActivity?$formatDate(lastActivity.created):'-'}}</span>
                        <span class="description">Fecha última actividad</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{lastActivity?lastActivity.activity:'-'}}</span>
                        <span class="description">Tipo última actividad</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{debt.prob_liquidation? debt.prob_liquidation:'-'}}</span>
                        <span class="description">Probabilidad liquidación</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{debt.due_payments_months}}</span>
                        <span class="description">Meses mora</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class='col-auto'>
                        <span class="heading">{{lastActivity?$formatCurrency(lastActivity.amount):'-'}}</span>
                        <span class="description">Saldo actual</span>
                      </div>
                      <div class='col-auto'>
                        <span  v-if="!editPayBank" :class="discountText">{{debt.discount?$formatCurrency(debt.discount):'-'}}</span>
                        <span  v-if="!editPayBank" class="description">Pago banco</span>
                        <div>
                        <base-button
                         v-if="!editPayBank && !(this.isAuditor || isService)"
                        id="previousId"
                        type="outline-info"
                        size="sm"
                        title="Editar Día de Deposito"
                        v-on:click="editPayBank = true"
                        >Editar</base-button
                    >
                      </div>
                      <div v-if="editPayBank">
                        <div>
                      <base-input
                            alternative
                            label="Pago Banco"
                            input-classes="form-control-alternative"
                            v-model="debt.discount"
                          />
                        </div>
                        <div>
                        <base-button
                        id="previousId"
                        type="outline-info"
                        size="sm"
                        title="Guardar Pago Banco"
                        v-on:click="uploadPayBank()"
                        >Actualizar</base-button
                    >
                      </div>
                      </div>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{debt.lastDiscount?(debt.lastDiscount*100).toFixed(2)+'%':'-'}}</span>
                        <span class="description">Último descuento %</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{lastActivity?lastActivity.limit_pay_date:'-'}}</span>
                        <span class="description">Fecha límite de pago</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class='col-auto'>
                        <span class="heading">{{(debt.bank_discount*100).toFixed(2)+'%'}}</span>
                        <span class="description">Descuento por mora %</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{$formatCurrency(debt.due_amount_pay_bank)}}</span>
                        <span class="description">Pago banco por mora</span>
                      </div>
                      <div class="col">
                        <input type="checkbox" class="form-check-input"
                            v-model="debt.payroll_same_bank"
                            v-on:click="setPayrollSB"/>
                        <label class="form-control-label">{{'Nómina en mismo banco'}}</label>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <adress-credit-report-table
                      title="Domicilios (Buro de Crédito)"
                      :data="addressReport"
                      :showisService="true"
                      :pagination="addressPagination"
                      @newModification="newModification"
                      style="margin-top: 20px"
                    />
                    </div>
                    <div class="col-lg-12">
                      <hr class="my-4" />
                      <h6 class="heading-small text-muted mb-4">Datos del pago</h6>
                    </div>
                    <div class="row">
                      <div class='col-auto'>
                        <base-dropdown>
                          <base-button :disabled="debt.status!=='En trámite de liquidación'
                            || (Boolean(paymentData.vobo_TL) && !isAdmin)"
                              slot="title" outline class="dropdown-toggle" style="width: 100%" size="sm">
                              {{getTextMethodPay(debt.method_pay)}}
                          </base-button>
                          <a class="dropdown-item" @click="debt.method_pay=1; $forceUpdate()">Efectivo</a>
                          <a class="dropdown-item" @click="debt.method_pay=2; $forceUpdate()">Transferencia</a>
                        </base-dropdown><br>
                        <span class="description">Método de pago</span>
                      </div>
                      <div class='col-auto'>
                        <base-input alternative input-classes="form-control-alternative"
                          label="Monto recibido de FA"
                          :disabled="debt.status!=='En trámite de liquidación' || isService || (Boolean(paymentData.vobo_TL) && !isAdmin)"
                          v-model="debt.recived_FA_amount"/>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{differencePayment}}</span>
                        <span class="description">Diferencia</span>
                      </div>
                      <div class='col-lg-4'>
                        <input type="checkbox" class="form-check-input"
                            v-model="debt.vobo_TL"
                            :disabled="isService || (Boolean(paymentData.vobo_TL) && !isAdmin)"
                            v-on:click="debt.vobo_TL = !debt.vobo_TL"/>
                        <label class="form-control-label">VoBo TL</label>
                      </div>
                      <div class='col-lg-10'>
                        <base-input alternative input-classes="form-control-alternative"
                          :disabled="debt.status!=='En trámite de liquidación'
                            || (paymentData.payment_instructions && isService) || (Boolean(paymentData.vobo_TL) && !isAdmin)"
                          label="Indicaciones de pago"
                          v-model="debt.payment_instructions"/>
                      </div>
                      <div class='col' v-if="debt.status==='En trámite de liquidación'
                        && ((isService && (!paymentData.method_pay || !paymentData.payment_instructions)) || (isManagerService && !paymentData.vobo_TL) || isAdmin)">
                        <base-button type="primary" size="sm" @click="verificationModal=true">Guardar</base-button>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <hr class="my-4" />
                      <h6 class="heading-small text-muted mb-4">Liquidación</h6>
                    </div>
                    <div class="row">
                      <div class='col-auto'>
                        <span class="heading">{{debt.settlement_send_date? $formatDate(debt.settlement_send_date):'-'}}</span>
                        <span class="description">Fecha de envío</span>
                      </div>
                      <div class='col-auto'>
                        <span :class="debt.paid_email_send? 'heading text-success': 'heading text-danger'">{{debt.paid_email_send? 'Enviado':'No enviado'}}</span>
                        <span class="description">Correo a Finamigo</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{debt.settlement_signed_date? $formatDate(debt.settlement_signed_date):'-'}}</span>
                        <span class="description">Fecha de recepción</span>
                      </div>
                      <div class='col-auto'>
                        <span class="heading">{{debt.settlement_paid_date?$formatDate(debt.settlement_paid_date):'-'}}</span>
                        <span class="description">Fecha de liquidación</span>
                      </div>
                      <div class='col-auto'>
                        <base-dropdown>
                          <base-button slot="title" :disabled="!debt.settlement_signed_date && (isAdmin || isManagerService)" outline class="dropdown-toggle" style="width: 100%" size="sm">
                              {{getTextBalanceConfirmation(debt.confirmation_of_funds)}}
                          </base-button>
                          <a class="dropdown-item" @click="setBalanceConfirmationStatus(1)">Pendiente</a>
                          <a class="dropdown-item" @click="setBalanceConfirmationStatus(2)">Recibido</a>
                          <a class="dropdown-item" @click="setBalanceConfirmationStatus(3)">Validar</a>
                        </base-dropdown><br>
                        <span class="description">Confirmación de fondos</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class='col-auto' v-if="(isAdmin || isManagerService) && debt.st">
                        <a :href="signatureLink" target="_blank">
                          <span class="name mb-0 text-sm">{{signatureLink}}</span>
                        </a><br>
                        <span class="description">Enlace para firma <i title="Click para copiar enlace al portapapeles" v-clipboard:copy="signatureLink" class="fas fa-file-signature text-primary"></i></span>
                      <br>
                      <base-button class="btn-sm" style="margin-top:0.5rem" type="info" @click="resetLink()">Reenvíar link</base-button>
                      <base-button class="btn-sm" style="margin-top:0.5rem" type="danger" @click="deleteLink()">Borrar link</base-button>
                      </div>
                    </div>
                    <div v-if="debt.status==='Liquidada'" class="col-lg-12">
                      <hr class="my-4" />
                      <h6 class="heading-small text-muted mb-4">Trámite post pago</h6>
                    </div>
                    <div v-if="debt.status==='Liquidada'" class="row">
                      <div class='col-auto'>
                        <label class="form-control-label">Fecha del trámite</label>
                        <flat-pickr class="form-control form-control-alternative" :config="{}"
                              data-vv-as="Fecha" name="date" style="background-color: #ffffff"
                              v-model="APaymentProcedure.procedure_date"/>
                      </div>
                      <div class='col-auto'>
                        <base-dropdown>
                          <base-button slot="title" type="info" outline class="dropdown-toggle" style="width: 100%" size="sm">
                              {{APaymentProcedure.voucher_type? APaymentProcedure.voucher_type:"Seleccionar"}}
                          </base-button>
                          <a class="dropdown-item" @click="setVoucherStatus('Carta finiquito')">Carta finiquito</a>
                          <a class="dropdown-item" @click="setVoucherStatus('Estado de cuenta en ceros')">Estado de cuenta en ceros</a>
                          <a class="dropdown-item" @click="setVoucherStatus('Buró de crédito')">Buró de crédito</a>
                          <a class="dropdown-item" @click="setVoucherStatus('Tramitarlo en sucursal')">Tramitarlo en sucursal</a>
                        </base-dropdown>
                        <br>
                        <span class="description">Comprobante de no adeudo</span>
                      </div>
                      <div class='col-auto'>
                        <base-dropdown>
                          <base-button slot="title" type="info" outline class="dropdown-toggle" style="width: 100%" size="sm">
                              {{APaymentProcedure.procedure_status? APaymentProcedure.procedure_status:"Seleccionar"}}
                          </base-button>
                          <a class="dropdown-item" @click="setAfterPaymentStatus('Cuenta cerrada')">Cuenta cerrada</a>
                          <a class="dropdown-item" @click="setAfterPaymentStatus('Pendiente aclaración')">Pendiente aclaración</a>
                          <a class="dropdown-item" @click="setAfterPaymentStatus('Cuenta no cerrada')">Cuenta no cerrada</a>
                        </base-dropdown>
                        <br>
                        <span class="description">Estatus del trámite post pago</span>
                      </div>
                      <div class='col-auto'>
                        <label class="form-control-label">Fecha solicitud finiquito</label>
                        <flat-pickr class="form-control form-control-alternative" :config="{}"
                              data-vv-as="Fecha" name="date" style="background-color: #ffffff"
                              v-model="APaymentProcedure.settlement_request_date"/>
                      </div>
                      <div class="col-lg-10">
                        <base-input alternative input-classes="form-control-alternative"
                          label="Notas"
                          v-model="APaymentProcedure.comments"/>
                      </div>
                      <div class="col align-self-center">
                          <base-button type="primary" size="sm" @click="setAfterPaymentProcedure()">Guardar</base-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <post-payment-procedure-table v-if="debt.status==='Liquidada'"
                  title="Trámites post pago"
                  :data="PPProcedureTableData"
                  :pagination="PPProcedurePagination"
                  @pageSelected="getPPProcedureTableData"
                  style="margin-top: 20px"/>
          <negotiation-table
                  title="Actividades de Negociación"
                  :completeBalance="lastActivity? debt.finamigoBalance>=(Number(lastActivity.amount_pay_bank)+10):false"
                  :buttonSendSettlementLetter="!debt.settlement_send_date"
                  :buttonTeamLeader="TL"
                  :data="activityNegotiationTableData"
                  :pagination="activityNegotiationPagination"
                  @newActivity="newActivity"
                  @sendSettlementLetter="sendSettlementLetter"
                  @createSettlementLetter="createdSettlementLetter"
                  @pageSelected="getActivityNegotiationTableData"
                  style="margin-top: 20px"
                />
          <debt-files-table
                  title="Documentos"
                  :data="debtFilesTableData"
                  :client="debt.user"
                  :pagination="debtFilesPagination"
                  v-on:onNewItem="newDebtFileModal = true"
                  v-on:pageSelected="getDebtFiles"
                  style="margin-top: 20px"
                />
        </div>
      </div>
    </div>
    <modal :show.sync="newNegotiationModal" v-bind:large="true">
      <template v-if="!selectFirm">
        <template slot="header">
          <h2 class="mb-0" style="margin-top: 20px; margin-left: 10px">
            Nueva actividad
          </h2>
        </template>
        <div class="row">
          <div class="col">
            <base-dropdown>
              <base-button slot="title" type="info" class="dropdown-toggle">
                {{newActivityNegotiation.describe ? newActivityNegotiation.describe : "Seleccionar"}}
              </base-button>
              <a class="dropdown-item"
                v-for="(activity, index) in debtActivityCatalog"
                :key="index"
                v-on:click="setNewActivityNegotiation(activity)"
                >{{ activity.describe }}</a
              >
            </base-dropdown>
          </div>
          <div class="col">
            <base-input alternative input-classes="form-control-alternative"
                  label="Saldo actual en banco"
                  v-model="newActivityNegotiation.amount"/>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-input alternative input-classes="form-control-alternative"
                  label="% Descuento"
                  disabled
                  v-model="discount"/>
          </div>
          <div class="col">
            <base-input alternative input-classes="form-control-alternative"
                  label="Pago banco"
                  v-model="newActivityNegotiation.amount_pay_bank"/>
          </div>
          <div class="col">
            <label class="form-control-label">Fecha límite</label>
            <flat-pickr class="form-control form-control-alternative" :config="{}"
                  data-vv-as="Fecha" name="date" style="background-color: #ffffff"
                  v-model="newActivityNegotiation.limit_pay_date"/>
          </div>
        </div>
        <div class="row">
          <div v-if="!addFirm" class="col-auto">
            <base-dropdown>
              <base-button slot="title" class="dropdown-toggle">
                {{newActivityNegotiation.firm_name ? newActivityNegotiation.firm_name : "Seleccionar"}}
              </base-button>
              <a class="dropdown-item" @click="addFirm=true">Agregar</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" @click="selectFirm=true">Ver catálogo</a>
              <!--a class="dropdown-item" v-for="(firm, index) in debtSCFirms" :key="index" @click="setNewActivityNegotiationFirm(firm)"
                >{{ firm.name }}</a-->
            </base-dropdown>
          </div>
          <div v-else class="col">
            <base-input alternative input-classes="form-control-alternative"
              label="Firma"
              v-model="newActivityNegotiation.firm_name"/>
          </div>
          <i v-if="addFirm" class="fas fa-window-close text-warning" @click="addFirm=false"></i>
          <div v-if="addFirm || newActivityNegotiation.firm_phone" class="col">
            <base-input alternative input-classes="form-control-alternative"
              label="Teléfono"
              v-model="newActivityNegotiation.firm_phone"/>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col">
            <base-input alternative input-classes="form-control-alternative"
              disabled
              label="Liquidación esperada"
              v-model="debt.amount_no_fee"/>
          </div>
          <div class="col">
            <base-input alternative input-classes="form-control-alternative"
              disabled
              label="Saldo finamigo"
              v-model="debt.finamigoBalance"/>
          </div>
          <div class="col">
            <base-input alternative input-classes="form-control-alternative"
              disabled
              label="Mora"
              v-model="debt.due_payments_months"/>
          </div>
          <div class="col">
            <label class="form-control-label">Fecha último pago al banco</label>
            <flat-pickr class="form-control form-control-alternative" :config="{}"
                  data-vv-as="Fecha" name="date" style="background-color: #ffffff"
                  v-model="debt.last_pay_bank"/>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-input alternative input-classes="form-control-alternative"
              label="Comentario"
              v-model="newActivityNegotiation.comments"/>
          </div>
        </div>
        <template slot="footer">
          <base-button type="primary" @click="saveActivity()">Guardar</base-button>
        </template>
      </template>
      <template v-else>
        <template slot="header">
          <h2 class="mb-0" style="margin-top: 20px; margin-left: 10px">
            Despachos
          </h2>
        </template>

        <firms-table
            title="Despachos"
            :data="debtSCFirms"
            @itemSelected="setFirm"
            style="margin-top: 20px"
          />
        
        <template slot="footer">
          <base-button type="danger" @click="selectFirm=false">Regresar</base-button>
        </template>
      </template>
    </modal>

    <modal :show.sync="newDebtFileModal">
      <template slot="header">
        <h1 class="modal-title" id="newDebtFileModal">Nuevo documento</h1>
      </template>
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-around">
            <base-dropdown>
              <base-button slot="title" class="dropdown-toggle" type="primary" style="margin-top: 15px; white-space: normal">
                {{fileType? getFileType(fileType) : "Selecciona el tipo de documento" }}
              </base-button>
              <a v-if="debt.settlement_signed" class="dropdown-item" @click="fileType='SETTLEMENT_VOUCHER'">Ficha de Liquidacion</a>
              <a v-if="debt.settlement_signed" class="dropdown-item" @click="fileType='PARTIAL_SETTLEMENT_VOUCHER'">Ficha de Pago Parcial de Liquidación</a>
              <a class="dropdown-item" @click="fileType='AGREEMENT'">Convenio</a>
              <a v-if="isAdmin || isManagerService" class="dropdown-item" @click="fileType='SETTLEMENT_SIGNATURE'">Carta de Liquidación Firmada</a>
              <a class="dropdown-item" @click="fileType='NO_DEBT_VOUCHER'">Comprobante de NO Adeudo</a>
            </base-dropdown>
            <div v-if="fileType==='SETTLEMENT_VOUCHER'">
              <label class="form-control-label">Fecha de liquidación</label>
              <flat-pickr :config="{}" class="form-control form-control-alternative" data-vv-as="Fecha" name="date" style="background-color: #ffffff"
                v-model="newSettlementPaidDate"/>
            </div>
            <div v-if="fileType==='SETTLEMENT_VOUCHER'">
              <base-input alternative input-classes="form-control-alternative"
                label="Monto real pagado"
                v-model="realPaid"/>
            </div>
          </div>
          <div class="col-lg-12" v-if="fileType==='AGREEMENT' || fileType==='NO_DEBT_VOUCHER'">
            <base-input alternative input-classes="form-control-alternative"
                        label="Comentarios"
                        v-model="doc_comments"/>
          </div>
          <div class="card-profile-stats d-flex justify-content-center">
            <dropbox text :type="fileType" :userid="this.debt.user" @uploadFile="uploadFile"/>
          </div>
        </div>
      </div>
    </modal>
    <modal :show.sync="verificationModal">
      <template slot="header">
        <h1 class="modal-title" id="verificationModal">Indicaciones de pago</h1>
      </template>
      <div class="row">
        <div class="col">
          <span class="font-weight-bold ml-1">{{'"'+debt.payment_instructions+'"'}}</span>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" @click="setPaymentData()">Confirmar y guardar</base-button>
      </template>
    </modal>
    <modal :show.sync="showModalAlertDiscount" v-on:close="showModalAlertDiscount=false">
      <template slot="header">
        <h1 class="modal-title text-danger" id="alertDiscountModal">Alerta</h1>
      </template>
      <div class="row">
        <div class="col">
          <span class="font-weight-bold ml-1">El pago al banco actual no coincide con el descuento acordado en la última actividad de negociación</span>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" @click="showModalAlertDiscount=false">Aceptar</base-button>
      </template>
    </modal>
    <modal :show.sync="showModalReport" v-on:close="showModalReport=false" v-bind:large="true">
      <template slot="header">
          <h2 class="mb-0" id="creditReportModal" style="margin-top: 20px; margin-left: 10px">Reporte de crédito</h2>
      </template>
      <div v-if="report">
        <credit-report-table style="margin-top: 20px" v-if="lengthsCreditReports.dbmAccounts>0"
                title = "Cuentas que se pueden agregar al programa"
                :data="report && report['dbmAccounts'] ? report['dbmAccounts'] : []"
                :pagination="CRPagination"/>
        <div v-else class="text-center">
            <div class="card-profile-stats d-flex justify-content-center">
                <h6 class="heading-small text-muted mb-4">No se encontaron cuentas que se puedan agregar al programa</h6>
            </div>
        </div>

        <credit-report-table style="margin-top: 20px" v-if="lengthsCreditReports.otherAccounts>0"
                title = "Otras cuentas"
                :data="report && report['otherAccounts'] ? report['otherAccounts'] : []"
                :pagination="CRPagination"/>
        <div v-else class="text-center">
            <div class="card-profile-stats d-flex justify-content-center">
                <h6 class="heading-small text-muted mb-4">No se encontaron otras cuentas</h6>
            </div>
        </div>
      </div>
      <div v-else class="text-center">
          <div class="card-profile-stats d-flex justify-content-center">
              <div>
                  <h6 class="heading-small text-muted mb-4">No se encontró un reporte de crédito</h6>                            
                  <span class="font-weight-bold ml-1 text-warning">{{getMessageReport(messageReport)}}</span><br>
                  <span class="description">status</span>                            
              </div>
          </div>
      </div>
    </modal>
    <modal :show.sync="modificationModal" @close="modificationModal" :large="true">
      <template slot="header">
        <h2 class="mb-0" style="margin-top: 20px; margin-left: 10px">
          Modificando {{`${modificationType}: Cliente ${modificationData.user}`}}
        </h2>
      </template>
      <base-input alternative
        label="Comentario"
        placeholder="Escribe un comentario..."
        v-model="modificationData.comments"/>
      <template slot="footer">
        <base-button type="primary" @click="setDeleteHistory()">Aplicar</base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import NegotiationTable from "./Tables/NegotiationTable";
import DebtFilesTable from "./Tables/DebtFilesTable";
import FirmsTable from "./Tables/FirmsTable";
import Dropbox from "../components/Dropbox";
import CreditReportTable from './Tables/CreditReportTable';
import PostPaymentProcedureTable from './Tables/PostPaymentProcedureTable';

import AdressCreditReportTable from "./Tables/AdressCreditReportTable";

export default {
  name: "home",
  components: {
    AdressCreditReportTable,
    flatPickr,
    NegotiationTable,
    PostPaymentProcedureTable,
    DebtFilesTable,
    FirmsTable,
    Dropbox,
    CreditReportTable
  },
  created() {
    let debtId = this.$route.query.debt;
    this.getDebtView(debtId)
    this.isLeader = this.user.leader === null;
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
  },
  data() {
    return {
      //Edit Payment Bank
      TL:false,
      editPayBank:false,
      addressReport:{},
      debt: {},
      isAdmin: false,
      isSeller: false,
      isService: false,
      isManagerService: false,
      isAuditor:false,
      isLeader: false,
      APaymentProcedure: {},
      debtFilesTableData: [],
      newActivityNegotiation: {},
      PPProcedureTableData: [],
      PPProcedurePagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      signaturesPagination: {
        total: 0,
        perPage: 1,
        currentPage: 1,
      },
      activityNegotiationTableData: [],
      activityNegotiationPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      newNegotiationModal: false,
      selectFirm: false,
      addFirm: false,
      modificationData: {},
      discountText: 'heading',
      showModalAlertDiscount: false,
      //ddebtStatus: null,
      //procedureStatus: null,
      lastActivity: {},
      debtFilesPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      fileType: null,
      fileTypes: ["SETTLEMENT_VOUCHER", "PARTIAL_SETTLEMENT_VOUCHER","AGREEMENT", "SETTLEMENT_SIGNATURE", "NO_DEBT_VOUCHER"],
      doc_comments: '',
      newDebtFileModal: false,

      //DatosPago
      verificationModal: false,
      paymentData: {},

      //Liquidación
      newSettlementPaidDate: null,
      realPaid: null,
      verificationModalSendFA:false,
      
      //Buró
      showModalReport: false,
      report: {},
      messageReport: '',
      reportByBC: false,
      CRPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      lengthsCreditReports:{
          dbmAccounts: null,
          otherAccounts: null
      },
      signaturesTableData: [],
      modificationType: '',
      modificationModal:false,
      statusType:null,
      addressPagination: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
    };
  },
  computed: {
    differencePayment() {
      let r = this.debt.recived_FA_amount - this.debt.amount_pay_bank ;
      return r? this.$formatCurrency(r):this.$formatCurrency(0);
    },
    discount() {
      let d = ((1-(this.newActivityNegotiation.amount_pay_bank/this.newActivityNegotiation.amount))*100).toFixed(2);
      return d? d:'-'
    },
    signatureLink() {
      return this.$v.CLIENT_SERVER + this.$v.SIGNATURE_PATH + '?t=' + this.debt.st;
    },
    user() {return this.$getLS(this.$v.STORE_KEYS.USER);},
    debtActivityCatalog() { return this.$store.state.debtActivityCatalog },
    debtSCFirms() { return this.$store.state.debtSCFirms },
  },
  watch: {
    $route() {
      let debtId = this.$route.query.debt;
      if (debtId > 0)
        this.getDebtView(debtId).then(found => {
          if (!found) {
            this.$router.push("/clients");
          }
        });
    },
  },
  methods: {
    uploadPayBank() {
      this.$showLoader(true);
      let bankPay = {
          id_debt: this.debt.id_debt,
          discount: this.debt.discount
        };
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_PAY_BANK;
      this.$axios.post(url, bankPay).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
            this.editPayBank = false;
            this.getActivityNegotiationTableData();
          this.$forceUpdate();
          this.$vs.notification(this.$notification(
                'success',
                'Actualizado exitosamente!'
            ));
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                'Algo salió mal!',
                'No se logro actualizar'
            ));
        }
      });
    },
    getSignaturesTableData() {
      let url = this.$v.SERVER + this.$v.ADMIN_OPS.GET_USER_SIGNATURE;
      let request = {
        user: this.debt.user,
        account: this.debt.id_debt
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.signaturesTableData = data[this.$v.API_ROWS];
        }
      });
    },
    resetLink(){
      let data = this.signaturesTableData[0];
      this.newModification(data,{id:6, name:'Firma Liquidación'},0)
    },
    deleteLink(){
      let data = this.signaturesTableData[0];
      this.newModification(data,{id:6, name:'Firma Liquidación'},1)
    },
     newModification(data,typeMod,status){
       this.statusType=status;
      this.modificationType = typeMod.name
      this.modificationModal = true;
      this.modificationData.last_id = data.id;
      this.modificationData.account = data.account;
      this.modificationData.type = typeMod.id;
      this.modificationData.user = data.user;
      this.modificationData.file_type = data.type;
      delete data.accountsTableIndex;
      delete data.id;
      delete data.user;
      delete data.type;
      delete data.filename;
      this.modificationData.json_details = JSON.parse(JSON.stringify(data));

        console.log(this.modificationData)
    },
   setDeleteHistory(){
      let url = this.$v.SERVER + this.$v.MANAGER_OPS.SET_DELETE_HISTORY;
      this.$showLoader(true);
      this.$axios.post(url, this.modificationData).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          if(this.statusType==0){
          switch (this.modificationData.type){
            case 6: 
            this.settlementLetter(this.debt.id_debt);
            this.deleteLetterSettlement(this.debt.id_debt);
            break
          }
          }else{
            switch (this.modificationData.type){
            case 6: this.deleteLetterSettlement(this.debt.id_debt); break
          }
          }
          this.loadViewDebt()
          this.dataCalculation = []
            this.$vs.notification(this.$notification(
                'success',
                 'Actualización exitosamente'
                     ));
            this.$forceUpdate();
        }
      });
      this.modificationModal = false;
      this.modoficationData = null;
    },
    deleteLetterSettlement(idDebt){
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.DELETE_LETTER_SETTLEMENTS;
      let request = {
          id_debt: idDebt,
        };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
            this.$forceUpdate();
        }
      });
    },
    settlementLetter(id) {
      let url =
        this.$v.SERVER +
        this.$v.SERVICE_OPS.REQUEST_ACCOUNT_SIGN +
        "?user=" + this.debt.user +
        "&account=" + id;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Firma de liquidación enviada correctamente'
                     ));
          this.getSignaturesTableData(1); 
          this.closeAccountModal();
        } else {
         this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        }
      });
    },
    getDebtView(debtId) {
      return new Promise((resolve) => {  
        let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_SC_DEBTS_VIEW;
        let request = {
          id_debt: debtId,
        };
        this.$showLoader(true);
        this.$axios.post(url, request).then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.debt = data[this.$v.API_ROW];
            this.loadViewDebt();

    if((this.isAdmin || this.isManagerService) && this.debt.status == 'En trámite de liquidación'){
      this.TL = true;
    }
            resolve(true);
          }
          resolve(false);
        });
      });
    },
    async loadViewDebt(){
      await this.getPPProcedureTableData(1);
      await this.getActivityNegotiationTableData(1);
      await this.getFinamigoBalance();
      this.getCreditReport();
      this.loadDebt();
      this.getDebtFiles(1);
      this.validateDiscount();
    },
    async getPPProcedureTableData(page) {
      let limit = this.PPProcedurePagination.perPage;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_SC_DEBT_AP_PROCEDURE;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        id_debt: this.debt.id_debt,
      };
      this.$showLoader(true);
      await this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.PPProcedureTableData = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.PPProcedurePagination.total = p["totalItems"];
          this.PPProcedurePagination.currentPage = page;
          this.PPProcedurePagination.perPage = limit;
        }
      });
    },
    async getActivityNegotiationTableData(page) {
      let limit = this.activityNegotiationPagination.perPage;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_SC_DEBT_ACTIVITY;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        id_debt: this.debt.id_debt,
      };
      this.$showLoader(true);
      await this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.activityNegotiationTableData = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.activityNegotiationPagination.total = p["totalItems"];
          this.activityNegotiationPagination.currentPage = page;
          this.activityNegotiationPagination.perPage = limit;
          if(page===1) this.lastActivity = data[this.$v.API_ROWS][0]
        }
      });
    },
    loadDebt(){
      this.debt.lastDiscount = this.lastActivity? 1-(this.lastActivity.amount_pay_bank/this.lastActivity.amount):null;
      this.newActivityNegotiation.id_debt = this.debt.id_debt;
      this.newActivityNegotiation.user = this.debt.user;
      this.APaymentProcedure.id_debt = this.debt.id_debt;
      this.APaymentProcedure.user = this.debt.user;
      this.APaymentProcedure.procedure_status = this.debt.procedure_status;
      this.APaymentProcedure.voucher_type = this.debt.voucher_type;
      this.APaymentProcedure.procedure_date = this.debt.procedure_date;
      this.APaymentProcedure.settlement_request_date = this.debt.settlement_request_date;
      this.newSettlementPaidDate = this.debt.settlement_paid_date;
      this.paymentData.payment_instructions = this.debt.payment_instructions;
      this.debt.method_pay = this.debt.method_pay === 'Efectivo'? 1 : this.debt.method_pay? 2 : null;
      this.paymentData.method_pay = this.debt.method_pay
      this.paymentData.vobo_TL = this.debt.vobo_TL;
      this.getSignaturesTableData();
     if((this.isAdmin || this.isManagerService) && this.debt.status == 'En trámite de liquidación'){
      this.TL = true;
    }
      this.$forceUpdate();
    },
    createdSettlementLetter() {
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.CREATE_SETTLEMENT_LETTER_APPROACH;
      this.$showLoader(true);
       let request = {
        id_debt: this.debt.id_debt,
        user:this.debt.user
      }
      this.$axios.post(url,request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Pre carta de liquidación creada correctamente'
                     ));
        this.$forceUpdate();
        window.open(data.row)
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        }
      });
    },
    sendSettlementLetter() {
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.REQUEST_ACCOUNT_SIGN +"?user="+this.debt.user+"&account="+this.debt.id_debt;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Firma de liquidación enviada correctamente'
                     ));
          this.loadViewDebt()
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        }
      });
    },
    backWindows() {
      window.history.back();
    },
    newActivity(){
      this.newNegotiationModal = true;
    },
    setAfterPaymentProcedure(){
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_SC_DEBT_AP_PROCEDURE
      this.$showLoader(true);
      this.$axios.post(url,this.APaymentProcedure).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Actualizado correctamente'
                     ));
          this.APaymentProcedure=null;
          this.loadViewDebt();
          this.$forceUpdate();
        }else{
           this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        }
      });
    },
    async getFinamigoBalance() {
      let url = this.$v.SERVER + this.$v.OPS.GET_FINAMIGO_BALANCE + "?user="+this.debt.user;
      this.$showLoader(true);
      await this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.debt.finamigoBalance = data.row.balance;
          this.$forceUpdate();
        }
      });
      this.showFinBalance = true;
    },
    saveActivity(){
      if(this.addFirm)
        this.addNewFirm(this.newActivityNegotiation.firm_name, this.newActivityNegotiation.firm_phone);
      this.newNegotiationModal = false;
      this.newActivityNegotiation.discount = this.discount;
      this.newActivityNegotiation.last_pay_bank = this.debt.last_pay_bank;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_SC_DEBT_ACTIVITY;
      this.$showLoader(true);
      this.$axios.post(url,this.newActivityNegotiation).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Guardado correctamente'
                     ));
          url=this.$v.SERVER + this.$v.OPS.SEND_NEGOTIATION_PUSH_NOTIFICATION;
          this.$axios.post(url, [this.debt.id_debt]).then((response,) => {
            let data = response.data;
            if (data[this.$v.API_SUCCESS]) {
              this.$vs.notification(this.$notification(
                'success',
                 'Notificación enviado'
                     ));
            }
          });
          this.loadViewDebt();
        }else{
           this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        }
      });
    },
    addNewFirm(firmName, firmPhone){
      if(!firmName || !firmPhone) return
      let request={
        name: firmName,
        phone_1: firmPhone
      }
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_SERVICE_DEBT_FIRMS
      this.$showLoader(true);
      this.$axios.post(url,request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Firma agregada'
                     ));
          this.loadViewDebt();
          this.$forceUpdate();
        }else{
           this.$vs.notification(this.$notification(
                'danger',
                 'Firma no enviada'
                     ));
        }
      });
    },
    getDebtFiles(page) {
      let limit = this.debtFilesPagination.perPage;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_SC_DEBT_DOCUMENTS;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        account: this.debt.id_debt
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.debtFilesTableData = data[this.$v.API_ROWS];
          this.debtFilesPagination.total = p["totalItems"];
          this.debtFilesPagination.currentPage = page;
          this.debtFilesPagination.perPage = limit;
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    uploadFile(obj) {
      let file = obj.file;
      let updateUrl = this.$v.SERVER + this.$v.SERVICE_OPS.UPLOAD_DEBT_FILE;
      let isVoucher = false;
      let fileType=file.type;
      let extension = fileType.substring(fileType.indexOf('/')+1)
      let request = {
        user: this.debt.user,
        account_id: this.debt.id_debt,
        fileType: obj.type,
        filename: file.name,
        fileExtension: extension,
        comments: this.doc_comments
      }
      if(this.fileType==='SETTLEMENT_VOUCHER'){
        if(!this.newSettlementPaidDate || !this.realPaid){
          this.$vs.notification(this.$notification(
                'danger',
                 'Datos de liquidación no validos'
                     ));
          return
        }
        isVoucher = true
        updateUrl = this.$v.SERVER + this.$v.SERVICE_OPS.SET_PAID_ACCOUNT;
        request.paid_file = file.name;
        request.paid = this.newSettlementPaidDate
        request.realPaid = this.realPaid
        delete request.fileType
        delete request.filename
      }
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_PRESIGNED_PUT_OBJECT_URL
          +"?user="+this.debt.user+"&type="+obj.type+"&debt="+this.debt.id_debt+"&ext="+extension;
      console.log("S3File");
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let fileURL = data[this.$v.API_ROW];
          this.$showLoader(true);
          this.$axios.put(fileURL, file, { headers: {} }).then((putObjectResponse) => {
              this.$showLoader(false);
              if (putObjectResponse.status === 200) {
                this.$axios.post(updateUrl, request).then((response) => {
                  let data = response.data;
                  if (data[this.$v.API_SUCCESS]) {
                    if(isVoucher){
                      this.$vs.notification(this.$notification(
                'success',
                 'Cuenta liquidada'
                     ));
                    }else{
                      this.$vs.notification(this.$notification(
                'success',
                 'Archivo gurdado correctamente'
                     ));
                    }
                    window.location.reload();
                  } else {
                    if(isVoucher){
                      this.$vs.notification(this.$notification(
                'danger',
                 'No se logró registrar la liquidación'
                     ));
                    }else{
                      this.$vs.notification(this.$notification(
                'danger',
                 'No se logró cargar'
                     ));
                    }
                  }
                });
              } else {
                this.$vs.notification(this.$notification(
                'danger',
                 'No se logró marcar como liquidada'
                     ));
              }
            }).catch((err) => {
              console.log(err);
              this.$vs.notification(this.$notification(
                'danger',
                 'No se logró marcar como liquidada'
                     ));
            });
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'No se logró marcar como liquidada'
                     ));
        }
      });
    },
    setPayrollSB() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_PAYROLL_SAME_BANK;
      let request={
        id_debt: this.debt.id_debt,
        payroll_same_bank: !this.debt.payroll_same_bank
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Cambio exitoso'
                     ));
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        }
      });
    },
    getFileType(type) {
        switch (type) {
            case 'SETTLEMENT_VOUCHER': return "Ficha de Liquidacion"
            case 'AGREEMENT': return "Convenio"
            case 'PARTIAL_SETTLEMENT_VOUCHER': return "Ficha de Pago Parcial de Liquidación"
            case 'SETTLEMENT_SIGNATURE': return "Carta de Liquidación Firmada"
            case 'NO_DEBT_VOUCHER': return "Comprobante de NO Adeudo"
        }
        return ""
    },
    setNewActivityNegotiation(activity){
      this.newActivityNegotiation.activity=activity.id
      this.newActivityNegotiation.describe=activity.describe
      this.$forceUpdate()
    },
    setNewActivityNegotiationFirm(firm){
      this.newActivityNegotiation.firm=firm.id
      this.newActivityNegotiation.firm_name=firm.name
      this.newActivityNegotiation.firm_phone=firm.phone_1
      this.$forceUpdate()
    },
    getColorDebtStatus(){
      let text = 'heading text-';
      switch (this.debt.status){
        case 'Liquidada': return text+'success';
        case 'En negociación': return text+'warning';
        default: return text+'primary';
      }
    },
    getProcedureStatus(){
      if(this.debt.status === 'Liquidada') return 'Liquidada';
      if(this.debt.settlement_signed_date && this.debt.confirmation_of_funds == 2 && this.debt.agreement_signed)
        return 'Espera de liquidación';
      if(this.debt.agreement_signed) return 'Convenio recibido';
      if(this.debt.settlement_signed_date) return 'Carta recibida';
      if(this.debt.settlement_send_date) return 'Carta enviada';
      return '-';
    },
    getTextBalanceConfirmation(status){
      switch (status) {
        case 1: return 'Pendiente'
        case 2: return 'Recibido'
        case 3: return 'Validar'
        default: return 'Seleccionar' 
      }
    },
    getTextMethodPay(method){
      switch (method) {
        case null: return 'Seleccionar'
        case 1: return 'Efectivo'
        case 2: return 'Transferencia'
        default: return method
      }
    },
    validateDiscount(){
      if(this.debt.discount == this.debt.amount_pay_bank || !this.debt.amount_pay_bank) return
      this.discountText+=' text-danger';
      this.showModalAlertDiscount = true;
    },
    setPaymentData(){
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_DEBTS_SETTLEMENTS_DAY;
      this.paymentData = {
        id_debt: this.debt.id_debt,
        method_pay: this.debt.method_pay,
        payment_instructions: this.debt.payment_instructions,
        recived_FA_amount: this.debt.recived_FA_amount,
        vobo_TL: Boolean(this.debt.vobo_TL)
      }
      this.$axios.post(url, this.paymentData).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Guardado exitosamente'
                     ));
          window.location.reload();
        } else {
         this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        }
      });
    },
    setBalanceConfirmationStatus(status){
      this.debt.confirmation_of_funds = status
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_CONFIRMATION_OF_FUNDS;
      let request={
        id_debt: this.debt.id_debt,
        confirmation_of_funds: status
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Guardado exitosamente'
                     ));
          this.$forceUpdate();
        } else {
         this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        }
      });
    },
    setVoucherStatus(status){
      this.APaymentProcedure.voucher_type = status
      this.$forceUpdate()
    },
    setAfterPaymentStatus(status){
      this.APaymentProcedure.procedure_status = status
      this.$forceUpdate()
    },
    setFirm(firm){
      this.newActivityNegotiation.firm_name = firm.name;
      this.newActivityNegotiation.firm = firm.id;
      this.newActivityNegotiation.firm_phone = firm.phone_1;
      this.selectFirm=false;
    },

    //Buró de crédito
     getCreditReport() {
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_CREDIT_REPORT + "?user=" + this.debt.user
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.report = data.row.credit_report ? data.row.credit_report : data.row.accounts;
          this.messageReport = data.row.message ? data.row.message: data.row.report.status;
          this.lengthsCreditReports.dbmAccounts = this.report.dbmAccounts.length;
          this.lengthsCreditReports.otherAccounts = this.report.otherAccounts.length;
            this.addressReport=data.row.report.response.Domicilios.Domicilio;
        }
      });
    },
    getMessageReport(message){
      if(!message) return "-"
      if(message.length>50) return "Contactar con soporte"
      return message
    },
    onCopy(){
      this.$vs.notification(this.$notification(
                'info',
                 'Enlace copiado en portapapeles'
                     ));
    },
  }
};
</script>
