<template>
    <div>
        <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col" v-bind:style="{cursor:pointer2}">
                    <addendums-history-table
                            title="Histórico de Addendums"
                            :data="tableData"
                            :period="period"
                            :pagination="pagination"
                            v-on:pageSelected="getAddendumsHistory"
                            v-on:addendumSelected="addendumSelected"
                            v-on:setConsultantFilter="setConsultantFilter"
                            v-on:setPeriodFilter="setPeriodFilter"
                            v-on:setTypeFilter="setTypeFilter"
                            v-on:setStatusFilter="setStatusFilter"/>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import AddendumsHistoryTable from './Tables/AddendumsHistoryTable.vue';

export default {
      name: 'tables',
      components: {
          AddendumsHistoryTable
      },
      mounted() {
          this.$root.$on('searchQuery', query => {
            if (query.length > 0)
            this.searchQuery = query
            else
            this.searchQuery = null
        this.getAddendumsHistory(1);
      })
      },
      created(){
          this.getAddendumsHistory(1);
      },
      computed: {
          user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      },
      data() {
          return {
              modal: false,
              selectedConsultant: {},
              searchQuery: null,
              tableData: [],
              tableDataWebinar: [],
              periodFilter : null,
              typeAddFilter:null,
              pagination: {
                  total: 0,
                  perPage: 20,
                  currentPage: 1
              },
              period: {
                start: null,
                end: null
            },
              pointer: 'pointer',
              pointer2: 'default',
              roles: ['admin', 'seller', 'service'],
              consultantFilter:null,
              statusFilter:null
          };
      },
      methods: {
           addendumSelected(addendum) {
            if (addendum.hasOwnProperty('addendum_id') && addendum.addendum_id > 0) {
                window.open("https://admin.dbmenos.com/addendum?user="+addendum.user+"&add="+addendum.addendum_id, '_blank')
            }else{
                this.$router.push("/addendumsHistory")
            }
        },
          setConsultantFilter(value) {
            this.consultantFilter = value
            this.getAddendumsHistory(1)
        },
          setTypeFilter(value) {
            this.typeAddFilter = value
            this.getAddendumsHistory(1)
        },
         setStatusFilter(value) {
            this.statusFilter = value
            this.getAddendumsHistory(1)
        },
          setPeriodFilter(value) {
            if(value === 1){
                let date = new Date()
                let month = date.getMonth()+1
                if(month<10) month= "0"+month
                this.period.start = date.getFullYear()+"-"+month+"-"+"01"+"T12:00:00.000Z"
            }
            this.periodFilter = value
            this.getAddendumsHistory(1)
        },
          async getAddendumsHistory(page) {
              let limit = this.pagination.perPage
              let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_VIEW_ADDENDUMS_HISTORY;
              let request = {
                  offset: limit * (page - 1),
                  limit: limit,
            };
            request.specialOrder = 'created';

            if (this.searchQuery) url += "?find=" + this.searchQuery
            if (this.typeAddFilter) url += "?addDetails=" + this.typeAddFilter

            if (this.statusFilter) Object.assign(request, {status: this.statusFilter})
            if (this.periodFilter === 1) {
                let created = {gte: this.period.start}
                Object.assign(request, {created})
            }
            if (this.periodFilter === 2){
                let created = {
                    gte: this.period.start+"T12:00:00.000Z",
                    lte: this.period.end+"T12:00:00.000Z"
                }
                request = Object.assign(request, {created})
            }
            if (this.consultantFilter) Object.assign(request, JSON.parse('{"consultant_id": '+this.consultantFilter+'}'))
              this.$showLoader(true)
              let response = await this.$axios.post(url,request)
              this.$showLoader(false)
              let data = response.data
              if (data[this.$v.API_SUCCESS]) {
                   this.tableData = data[this.$v.API_ROWS]
                    let p = data[this.$v.API_ROW]
                    this.pagination.total = p['totalItems']
                    this.pagination.currentPage = page
                    this.pagination.perPage = limit
              } else { this.$validateSession(data) }
          },
      }
  };
</script>
<style></style>
