<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button v-if="!showData && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="showSignatures"></base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                        <!--@click="movementSelected"-->

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">ID Firma</th>
                    <th v-bind:style="{cursor:pointer2}">Estatus</th>
                    <th v-bind:style="{cursor:pointer2}">Tipo de Firma</th>
                    <th v-bind:style="{cursor:pointer2}">Link Firma</th>
                    <th v-bind:style="{cursor:pointer2}">Restablecer Firma</th>
                    <th v-bind:style="{cursor:pointer2}">Modificación Contrato</th>
                    <th v-bind:style="{cursor:pointer2}">Borrar Firma</th>
                </template>

                <template v-if="showData" slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{row.id}}</span>
                            </div>
                        </div>
                    </th>
                    <th>{{getStatusSignature(row.signed)}}</th>
                    <td>{{getSigType(row.type)}}</td>
                    <td v-clipboard:copy="getTokenSig(row.token,row.type)"><a :href="getTokenSig(row.token,row.type)" target="_blank">
                          <span class="name mb-0 text-sm">{{getTokenSig(row.token,row.type)}}</span>
                        </a><br></td>
                    <td class="text-center text-info"><i class="fas fa-file-signature fa-lg" @click.stop="deleteSignature(row,row.type,0)"></i></td>
                                        <td class="text-center text-warning"><i class="fas fa-file-upload fa-lg" @click.stop="deleteSignature(row,row.type,2)"></i></td>
                    <td class="text-center text-danger"><i class="fa fa-trash" aria-hidden="true" @click.stop="deleteSignature(row,row.type,1)"></i></td>
                </template>
            </base-table>
        </div>

        <div v-if="(pagination.total > pagination.perPage) && showData" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'movements-table',
        props: {
            type: {
                type: String
            },
            title: String,
            amount: Number,
            showEye: Boolean,
            data: Array,
            pagination: Object
        },
        data() {
            return {
                tableData: [],
                showPayments: false,
                validatedPaymentsFilterText: "",
                movTypeFilterText: "",
                pointer: 'pointer',
                pointer2: 'default',
                isAdmin:false,
                isManagerService:false,
                linkSignature:"",
                showData:false
            }
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
        },
        methods: {
            showSignatures() {
                this.showData = true;
                this.$emit("showSignatures")
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            formatDate(date) {
               return this.$formatDate(date)
            },
            onSendAccountStatement() {
                this.$emit("onSendAccountStatement")
            },
            getSigType(type){
                return type==='contract'? 'Contrato': type==='settlement'? 'Carta Liquidación' : type==='letter'? 'Carta Nómina' : 'Addendum'
            },
            getTokenSig(token,type){
               return type==='contract'? this.$v.CLIENT_SERVER + this.$v.SIGNATURE_PATH + '?token=' + token + '&sign=contract':
                type==='settlement'? this.$v.CLIENT_SERVER + this.$v.SIGNATURE_PATH + '?t=' + token :
                 type==='letter'? this.$v.CLIENT_SERVER + this.$v.SIGNATURE_PATH + '?tkLetter=' + token :
                  this.$v.CLIENT_SERVER + this.$v.SIGNATURE_PATH + '?tkadd=' + token
            },
            getStatusSignature(signed){
                return signed== 0 ? 'No Firmado': 'Firmado'
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            deleteSignature(dataM,type,status){
                if(type === 'contract'){
                    dataM.type = 'SIGNATURE_CONTRACT';
                    this.$emit("newModification", dataM, this.$v.MODIFICATIONS.SIGNATURE_CONTRACT,status)
                }else if(type === 'settlement'){
                    dataM.type = 'SIGNATURE_SETTLEMENT';
                    this.$emit("newModification", dataM, this.$v.MODIFICATIONS.SIGNATURE_SETTLEMENT,status)
                }else if(type === 'letter'){
                    dataM.type = 'SIGNATURE_LETTER';
                    this.$emit("newModification", dataM, this.$v.MODIFICATIONS.SIGNATURE_LETTER,status)
                }else{
                    dataM.type = 'SIGNATURE_ADDENDUM';
                    this.$emit("newModification", dataM, this.$v.MODIFICATIONS.SIGNATURE_ADDENDUM,status)
                }
            },
            
            /*onNewItem() {
                this.$emit("onNewItem")
            },
            movementSelected(payment) {
                this.$emit("onMovementSelected", payment)
            }*/
        }
    }
</script>
<style>
</style>
