<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''" v-bind:style="{cursor:pointer2}">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button v-if="!(showData || showisService) && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="showFiles"></base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">Tipo</th>
                    <th v-bind:style="{cursor:pointer2}">Archivo</th>
                    <th v-bind:style="{cursor:pointer2}">Cargado el</th>
                </template>

                <template slot-scope="{row}" v-if="row.type !== 'PROFILE' && row.type !== 'APPROACH_ADDENDUM' && (showData || showisService)">
                    <th scope="row">
                        <div class="media align-items-center" v-bind:style="{cursor:pointer2}">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{getFileType(row.type)}}</span>
                            </div>
                        </div>
                    </th>
                    <td>
                        <div class="d-flex align-items-center">
                            <span><a :href="getFileUrl(row)" target="_blank">Ver</a></span>
                        </div>
                    </td>
                    <td v-bind:style="{cursor:pointer2}" v-if="row.created">{{$formatDate(row.created)}}</td>
                    <td v-bind:style="{cursor:pointer2}" v-else><span class="text-danger">No cargado</span></td>
                </template>

            </base-table>
        </div>

        <div v-if="(pagination.total > pagination.perPage) && showData" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'clients-table',
        components: {
        },
        props: {
          type: {
              type: String
          },
          title: String,
          data: Array,
          showEye: Boolean,
          showisService: Boolean,
          showCreateContractButton: Boolean,
          showIncompleteProfile:Boolean,
          pagination: Object,
          create_contract_button_text: String,
          client: Object,
          statusAudit: Boolean
        },
        data() {
            return {
                isAuditor: false,
                isAdmin: false,
                isManagerService:false,
                isManagerSeller: false,
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                showData: false
            }
        },
        computed: {
            banks() { return this.$store.state.banks },
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
        },
        async created() {
          this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
          this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
          this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
          this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
          this.isService = this.user.role === this.$v.ROLES.SERVICE
          if(this.isService) this.showData=true;
        },
        methods: {
            showFiles() {
                this.showData = true;
                this.$emit("showFiles")
            },
            getFileType(type) {
                switch (type) {
                    case 'ID': return "Identificación frente"
                    case 'ID_B': return "Identificación reverso"
                    case 'CONTRACT': return "Contrato Finamigo"
                    case 'PRE_CONTRACT': return "Pre Contrato Finamigo"
                    case 'ADDRESS': return "Comprobante de Domicilio"
                }
                return ""
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            getBanks(array) {
                let banks = []
                for (let a=0 ; a<=array.length ; a++) {
                    for (let b = 0; b < this.banks.length; b++) {
                        if (this.banks[b].id === array[a]) {
                            banks.push(this.banks[b])
                            break
                        }
                    }
                }
                return banks
            },
            onCreateDBMenosContract() {
                this.showCreateContractButton = false;
                this.showIncompleteProfile = true;
                this.$emit("onCreateDBMenosContract")
            },
            showIncompleteData() {
                this.$emit("showIncompleteData")
            },
            onNewItem() {
                this.$emit("onNewItem")
            },
            uploadFile(obj) {
                let fileType = obj.type
                let file = obj.file
                let text = obj.text
                let formData = new FormData();
                formData.append("file", file);
                let headers = {
                    "client-id": this.client.id,
                    "file-type": fileType
                }
                this.$showLoader(true)
                this.$axios.post(this.$v.SERVER + this.$v.SELLER_OPS.UPLOAD_FILE, formData, {headers: headers}).then(response => {
                    this.$showLoader(false)
                    if (response.data.success) {
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
                    } else {
                        this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    }
                }).catch(e => {
                    console.log(e)
                    this.$showLoader(false)
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                })
            },
            getFileUrl(userFile) {
                let session = JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.SESSION))
                if (session) {
                    return this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_FILE + "?user=" + this.client.id + "&filename=" +
                        userFile.filename + "&redirect=true" + "&session=" + session[this.$v.SESSION]
                }
            },
            //---Modification
            deleteFile(dataM){
                this.$emit("newModification", dataM, this.$v.MODIFICATIONS.DOCUMENT)
            },
        }
    }
</script>
<style>
</style>