<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col-lg-2 text-right">
          <base-dropdown style="width: 100%">
          <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
            {{getStatusFilter()}}
          </base-button>
            <a class="dropdown-item" v-on:click="setStatusFilter(null)">Mostrar todos</a>
            <a class="dropdown-item" v-on:click="setStatusFilter(1)">Nuevo</a>
            <a class="dropdown-item" v-on:click="setStatusFilter(2)">Cuestionario Envíado</a>
            <a class="dropdown-item" v-on:click="setStatusFilter(3)">Cuestionario Recibido</a>
            <a class="dropdown-item" v-on:click="setStatusFilter(4)">Correo Envíado</a>
            <a class="dropdown-item" v-on:click="setStatusFilter(5)">Firmas Validadas</a>
            <a class="dropdown-item" v-on:click="setStatusFilter(6)">Cliente con guía</a>
            <a class="dropdown-item" v-on:click="setStatusFilter(7)">Documento en transito</a>
            <a class="dropdown-item" v-on:click="setStatusFilter(8)">Documento en finamigo</a>
          </base-dropdown>
          </div>

        <div class="col-lg-2">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getPeriodFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
                        <div v-if="!showSetPeriod" class="dropdown-divider"></div>
                        <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
                    </base-dropdown>
                </div>                
                <div v-if="showSetPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
                        <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
                    </div>
                </div>
      </div>
    </div>

    <div class="table-responsive text-center" v-bind:style="{ cursor: pointer }">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
      >
        <!--v-on:click="clientSelected"-->

        <template slot="columns" class="text-center">
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Usuario">
            ID
          </th>
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Estatus Proceso">Estado</th>
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Fecha de Creación">
            Nombre
          </th>
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Fecha de Creación">
            Email
          </th>
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Creación">
            Creación
          </th>
           <th v-bind:style="{ cursor: pointer }" class="text-center" title="Respuesta">
            Respuesta
          </th>
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Acciones">
            Acc.
          </th>
          <!--
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Correo Enviado">
            CR
          </th>
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Firma Validada">
            FV
          </th>
           <th v-bind:style="{ cursor: pointer }" class="text-center" title="Cliente con guía">
            CG
          </th>
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Documento en transito">
            DT
          </th>
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Documento en finamigo">
            DF
          </th>
          !-->
          <th v-bind:style="{ cursor: pointer }" class="text-center" title="Número de Guía">
            Num. G.
          </th>
        </template>

        <template slot-scope="{ row }" class="text-center"> 
          <th scope="row text-center">
            <div class="media align-items-center">
              <div class="media-body">
                <a
                  :href="'https://admin.dbmenos.com/client?id=' + row.user"
                  target="_blank"
                >
                  <span class="name mb-0 text-sm"
                    >{{ row.user }}<i class="fas fa-user-edit text-primary"></i
                  ></span>
                </a>
              </div>
            </div>
          </th>
          <!--
          <td><base-dropdown style="width: 100%">
          <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
            {{getStatusContract(row.status)}}
          </base-button>
            <a class="dropdown-item" v-on:click="setValue(row, 1)">Nuevo</a>
            <a class="dropdown-item" v-on:click="setValue(row, 2)">Cuestionario Envíado</a>
            <a class="dropdown-item" v-on:click="setValue(row, 3)">Cuestionario Recibido</a>
            <a class="dropdown-item" v-on:click="setValue(row, 4)">Correo Envíado</a>
            <a class="dropdown-item" v-on:click="setValue(row, 5)">Firmas Validadas</a>
            <a class="dropdown-item" v-on:click="setValue(row, 6)">Cliente con guía</a>
            <a class="dropdown-item" v-on:click="setValue(row, 7)">Documento en transito</a>
            <a class="dropdown-item" v-on:click="setValue(row, 8)">Documento en finamigo</a>
          </base-dropdown></td>
          !-->
         <td  v-on:click="showModalStatus(row)" class="text-warning" :title="getStatusContract(row.status)">
            {{ getStatusContract(row.status) }} <i class="fas fa-edit text-warning"></i>
          </td>
          <td :class="getColorName(row)">{{ row.name }}</td>
         <td>{{ row.email ? row.email : '----' }}</td>
          <td>{{ formatDate(row.created) }}</td>
           <td>{{ formatDate(row.upload_date) }}</td>
          <td>
              <base-button
                id="showModal"
                type="outline-primary"
                class="p-2"
                size="sm"
                style="margin-bottom:0.5rem; margin-left:0.5rem;"
                data-toggle="tooltip" data-placement="top"
                title="Envíar Cuestionario"
                v-if="row.status==2  || row.status == 1"
                v-on:click="sendQuestions(row)"
                >
                <i class="fas fa-question text-primary"></i>
                
                </base-button>
                <base-button
                id="showModal"
                type="outline-info"
                class="p-2"
                size="sm"
                v-if="row.status==2"
                style="margin-bottom:0.5rem; margin-left:0.5rem;"
                data-toggle="tooltip" data-placement="top"
                title="Ver Cuestionario"
                v-on:click="showQuestions(row)"
                >
               <i class="fas fa-eye text-info"></i>
                </base-button>
                <base-button
                id="showModal"
                type="outline-warning"
                class="p-2"
                size="sm"
                style="margin-bottom:0.5rem; margin-left:0.5rem;"
                data-toggle="tooltip" data-placement="top"
                title="Ver PDF"
                v-if="row.status>2"
                v-on:href="getFileUrl(row)" 
                >
                <a :href="getFileUrl(row)" target="_blank" style="color:red">
              <span class="name mb-0 text-sm"> <i class="fas fa-file-pdf text-warning"></i></span>
            </a>
                </base-button>
                <base-button
                id="showModal"
                type="outline-primary"
                class="p-2"
                size="sm"
                v-if="row.status>2"
                style="margin-bottom:0.5rem; margin-left:0.5rem;"
                data-toggle="tooltip" data-placement="top"
                title="Actualizar PDF"
                v-on:click="updatePDF(row)"
                >
               <i class="fas fa-sync-alt text-primary"></i>
                </base-button>
                 
          </td>
          <!--
           <td class="text-center" @click.stop>
            <base-checkbox :disabled="Boolean(row.cr)" :checked="Boolean(row.cr)" @input="setValue(row, row.cr, 1)"/>
          </td>
           <td class="text-center" @click.stop>
            <base-checkbox :disabled="Boolean(row.fv)" :checked="Boolean(row.fv)" @input="setValue(row, row.fv, 2)"/>
          </td>
           <td class="text-center" @click.stop>
            <base-checkbox :disabled="Boolean(row.cg)" :checked="Boolean(row.cg)" @input="setValue(row, row.cg, 3)"/>
          </td>
           <td class="text-center" @click.stop>
            <base-checkbox :disabled="Boolean(row.dt)" :checked="Boolean(row.dt)" @input="setValue(row, row.dt, 4)"/>
          </td>
           <td class="text-center" @click.stop>
            <base-checkbox :disabled="Boolean(row.df)" :checked="Boolean(row.df)" @input="setValue(row, row.df, 5)"/>
          </td>
          !-->
          <td>{{ row.no_guia != null ? row.no_guia : '---' }}</td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="col">
        <p class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          Página {{ pagination.currentPage }} de
          {{ Math.ceil(pagination.total / pagination.perPage) }}<br />{{
            pagination.total
          }}
          Clientes
        </p>
      </div>
      <base-pagination
        size="sm"
        :total="pagination.total"
        :per-page="pagination.perPage"
        :value="pagination.currentPage"
        v-on:pageSelected="pageSelected"
      />
    </div>
    <modal :show.sync="guiaModal">
      <template slot="header">
        <h1 class="modal-title" id="paymentModal"> ID Cliente: {{ row.user }} </h1>
      </template>
      <div class="row">
        <div class="card-profile-stats d-flex justify-content-around mb-4" style="width: 100%">
          <div class="p-2">
            <base-input alternative input-classes="form-control-alternative"
                        label="Número de Guía"
                        v-model="row.no_guia"/>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button class="btn-sm" type="danger" v-on:click="guiaModal = false">
          Cancelar
        </base-button>
        <base-button class="btn-sm" type="primary" v-on:click="updateGuia()">
          Guardar cambios
        </base-button>
      </template>
    </modal>
     <modal :show.sync="statusModal">
      <template slot="header">
        <h1 class="modal-title" id="paymentModal"> ID Cliente: {{ row.user }} </h1>
      </template>
      <div class="row">
        <div class="card-profile-stats d-flex justify-content-around mb-4" style="width: 100%">
          <div class="p-2">
          <base-dropdown style="width: 100%">
          <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
            {{getStatusContract(rowStatus)}}
          </base-button>
            <a class="dropdown-item" v-on:click="setValue(row, 1)">Nuevo</a>
            <a class="dropdown-item" v-on:click="setValue(row, 2)">Cuestionario Envíado</a>
            <a class="dropdown-item" v-on:click="setValue(row, 3)">Cuestionario Recibido</a>
            <a class="dropdown-item" v-on:click="setValue(row, 4)">Correo Envíado</a>
            <a class="dropdown-item" v-on:click="setValue(row, 5)">Firmas Validadas</a>
            <a class="dropdown-item" v-on:click="setValue(row, 6)">Cliente con guía</a>
            <a class="dropdown-item" v-on:click="setValue(row, 7)">Documento en transito</a>
            <a class="dropdown-item" v-on:click="setValue(row, 8)">Documento en finamigo</a>
          </base-dropdown>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button class="btn-sm" type="danger" v-on:click="statusModal = false">
          Cerrar
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "activity-tables",
  components: {
    flatPickr
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    data: Array,
    pagination: Object,
    period: {
      start: null,
      end: null
      },
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    sellers() {
      return this.$store.state.sellers;
    },
    leadStatus() {
      return this.$store.state.leadStatus;
    },
    userTracingStatus() {
      return this.$store.state.userTraceStatus;
    },
    customerService() {
      return this.$store.state.customerService;
    },
  },
  data() {
    return {
      statusModal:false,
      guiaModal:false,
      isAdmin: false,
      filesPagination: {
					total: 0,
					perPage: 10,
					currentPage: 1
				},
      filesTableData: [],
      tableData: [],
      statusFilter: null,
      consultantFilter: null,
      showSetPeriod: false,
                periodFilter: null,
      pointer: "pointer",
      pointer2: "default",
      periodFilter: null,
      today: "",
      filesModal:false,
      config: {
        maxDate: "today",
        dateFormat: "Y-m-d",
      },
      rowStatus:"",
      row:{}
    };
  },
  created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
  },
  mounted() {
    this.getDay();
  },
  methods: {
    getPeriodFilterText() {
      if(this.showSetPeriod)
        return "Periodo del: "
        else if(this.periodFilter === 1)
        return "Mes actual"
        else return "Fecha"
            },
    setPeriodFilter(value) {
      console.log(value)
                if(value!=2){
                    this.showSetPeriod = false
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                    
                }else if(this.period.start && this.period.end){
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                }
            },
    setPeriod(){
                this.showSetPeriod = true
                this.period.start=null
                this.period.end=null
                this.getPeriodFilterText()
            },
    showModalStatus(row) {
      this.row = row
      this.rowStatus = null;
      this.rowStatus=row.status;
      this.statusModal=true;
    },
        updateGuia(){
      this.$showLoader(true);
      console.log(this.row)
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SERVICE_UPDATE_STATUS_CONTRACT_FA;
      let request  = {user: this.row.user, no_guia: this.row.no_guia}
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {

                        this.$vs.notification(this.$notification(
                'success',
                 'Numero de guía guardado exitosamente'
                     ));
          this.guiaModal=false;
          this.$emit("getData");
           this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
       
       setValue(row, type){
          this.row={};
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SERVICE_UPDATE_STATUS_CONTRACT_FA;
      let request  = {user: row.user}
      if(type===1){request.idn = true;}
      else if(type===2){request.qe = true;}
      else if(type===3){request.qr = true;}
      else if(type===4){request.cr = true;}
      else if(type===5){request.fv = true;}
      else if(type===6){request.cg = true; this.guiaModal=true; this.row = row}
      else if(type===7){request.dt = true;}
      else if(type===8){request.df = true;}
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
          this.$emit("getData");
          this.statusModal=false;
           this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
getFileUrl(row) {
  let filename = row.user+'_FinamigoContract.pdf' 
                let session = JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.SESSION))
                if (session) {
                    return this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_FILE + "?user=" + row.user + "&filename=" +
                        filename + "&redirect=true" + "&session=" + session[this.$v.SESSION]
                }
            },
getStatusFilter() {
      if (this.statusFilter === null) return "Status del Proceso"
      if (this.statusFilter === 1) return "Nuevo"
      if (this.statusFilter === 2) return "Cuestionario Enviado"
      if (this.statusFilter === 3) return "Cuestionario Recibido"
      if (this.statusFilter === 4) return "Correo Enviado"
      if (this.statusFilter === 5) return "Firmas Validadas"
      if (this.statusFilter === 6) return "Cliente con guía"
      if (this.statusFilter === 7) return "Documento en transito"
      if (this.statusFilter === 8) return "Documento en finamigo" 
      return 'Agradecimientos Finales'
      },
    setStatusFilter(value) {
      this.statusFilter = value
      this.$emit("setStatusFilter", value);
     },
     showQuestions(row){
       window.open("https://dbmenos.com/questionContractFA/" + row.token, "_blank");
     },
      sendQuestions(user){
        console.log(user)
          let url = this.$v.SERVER + this.$v.SERVICE_OPS.SEND_QUESTIONS_CONTRACT_FA;
          this.$showLoader(true);
          let request={
              user: user.user,
            }
            this.$axios.post(url, request).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data.success) {
                    
                        this.$vs.notification(this.$notification(
                'success',
                 'Mensaje enviado exitosamente'
                     ));
                }else{
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                }
            });
            },
             updatePDF(user){
        console.log(user)
          let url = this.$v.SERVER + this.$v.SERVICE_OPS.UPDATE_PDF_CONTRACT_FA;
          this.$showLoader(true);
          let request={
              user: user.user,
            }
            this.$axios.post(url, request).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data.success) {
                    
                        this.$vs.notification(this.$notification(
                'success',
                 'PDF actualizado exitosamente'
                     ));
                }else{
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                }
            });
            },
        sendPFS(user){
          let url = this.$v.SERVER + this.$v.SERVICE_OPS.SEND_PDF_CONTRACT_HIGH_RISK;
          this.$showLoader(true);
          let request={
              user: user.user,
            }
            this.$axios.post(url, request).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data.success) {
                    
                        this.$vs.notification(this.$notification(
                'success',
                 'Mensaje enviado al cliente exitosamente'
                     ));
                    this.$$forceUpdate();
                }else{
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    this.$$forceUpdate();
                }
            });
            },
        sendLookPictures(user){
          let url = this.$v.SERVER + this.$v.SERVICE_OPS.RECEPTIONS_PHOTOS_CONTRACTS;
          this.$showLoader(true);
          let request={
              user: user.user,
            }
            this.$axios.post(url, request).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data.success) {
                    
                        this.$vs.notification(this.$notification(
                'success',
                 'Mensaje enviado exitosamente'
                     ));
                    this.$$forceUpdate();
                }else{
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    this.$$forceUpdate();
                }
            });
            },
        sendResendPhotos(user){
          let url = this.$v.SERVER + this.$v.SERVICE_OPS.RESEND_FILES_CONTRACTS_HIGH_RISK;
          this.$showLoader(true);
          let request={
              user: user.user,
            }
            this.$axios.post(url, request).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data.success) {
                    
                        this.$vs.notification(this.$notification(
                'success',
                 'Mensaje enviado exitosamente'
                     ));
                    this.$$forceUpdate();
                }else{
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    this.$$forceUpdate();
                }
            });
            },
        sendGuide(user){
          let url = this.$v.SERVER + this.$v.SERVICE_OPS.SEND_INFORMATION_DIRECTION;
          this.$showLoader(true);
          let request={
              user: user.user,
            }
            this.$axios.post(url, request).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data.success) {
                    
                        this.$vs.notification(this.$notification(
                'success',
                 'Mensaje enviado exitosamente'
                     ));
                    this.$$forceUpdate();
                }else{
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    this.$$forceUpdate();
                }
            });
            },
        resendGuide(user){
          let url = this.$v.SERVER + this.$v.SERVICE_OPS.RESEND_FILES_GUIDE_HIGH_RISK;
          this.$showLoader(true);
          let request={
              user: user.user,
            }
            this.$axios.post(url, request).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data.success) {
                    
                        this.$vs.notification(this.$notification(
                'success',
                 'Mensaje enviado exitosamente'
                     ));
                    this.$$forceUpdate();
                }else{
                   this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    this.$$forceUpdate();
                }
            });
            },
        sendFinalThanks(user){
          let url = this.$v.SERVER + this.$v.SERVICE_OPS.SEND_THANKS_CONTRACTS_HIGH_RISK;
          this.$showLoader(true);
          let request={
              user: user.user,
            }
            this.$axios.post(url, request).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data.success) {
                    
                        this.$vs.notification(this.$notification(
                'success',
                 'Mensaje enviado exitosamente'
                     ));
                    this.$$forceUpdate();
                }else{
                    this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    this.$$forceUpdate();
                }
            });
            },
            /*
    getStatusContract(status){
    return status == 1 ? 
    'Nuevo.' : status == 2 ?
    'Cuest. Env.' : status == 3 ?
    'Cuest. Res.' : status == 4 ? 
    'Correo Env.' : status == 5 ? 
    'Firm. Val.' :  status == 6 ? 
    'Cliente G.' : status == 7 ?
    'Doc. Transito' : status == 8 ?
    'Doc Finamigo' : 'Sin estatus'
    },
    */
    getStatusContract(status){
    return status == 1 ? 
    'Nuevo' : status == 2 ?
    'Cuestionario Enviado' : status == 3 ?
    'Cuestionario Recibido' : status == 4 ? 
    'Correo Enviado' : status == 5 ? 
    'Firmas Validadas' :  status == 6 ? 
    'Cliente con guía' : status == 7 ?
    'Documento en transito' : status == 8 ?
    'Documento en finamigo' : 'Sin estatus'
    },
    formatDate(date) {
      if (date <= "2020-11-10") {
        return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
      } else {
        return this.$moment(date).format("DD MMM YYYY, h:mm a");
      }
    },
    pageSelected(page) {
      this.$emit("pageSelected", page);
    },
    setConsultantFilter(value) {
      this.consultantFilter = value;
      this.$emit("setConsultantFilter", value);
    },
    getCustomerFilterText() {
      if (this.consultantFilter)
        return this.getConsultantName(this.consultantFilter);
      return "Consultores";},
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id) return this.sellers[i].name;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return this.customerService[i].name;
      return "- - -";
    },
    getStatusText(id) {
      for (let i = 0; i < this.userTracingStatus.length; i++)
        if (this.userTracingStatus[i].id === id)
          return this.userTracingStatus[i].status;
      return "- - -";
    },
    getDay() {
      let f = new Date();
      let month = f.getMonth() + 1;
      if (month < 10) month = "0" + month;
      this.today = f.getFullYear() + "-" + month + "-" + f.getDate();
    },
    getColorName(row){
      if(this.validate(row.beneficiary_name) || this.validate(row.beneficiary_lastname_1) || this.validate(row.beneficiary_lastname_2))
        return 'text-danger';
      return;
    },
    validate(name){
      if(!name || (name.indexOf('.')!==-1) || name.length<3) return true
    }
  },
};
</script>
<style>
</style>