
<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8" />

    <div class="container-fluid mt--7" @click="uploadSelected = null">
      <div class="row">
        <div class="col" v-bind:style="{ cursor: pointer2 }">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div v-if="isAdmin" class="text-right col-lg-12">
                  <div class="btn-group btn-group-sm" role="group">
                    <button
                      type="button"
                      :class="
                        isManagerSeller
                          ? 'btn btn-primary'
                          : 'btn btn-secondary'
                      "
                      @click="serviceMode(false)"
                    >
                      Ventas
                    </button>
                    <button
                      type="button"
                      :class="
                        isManagerService
                          ? 'btn btn-primary'
                          : 'btn btn-secondary'
                      "
                      @click="serviceMode(true)"
                    >
                      Servicio
                    </button>
                  </div>
                </div>
              </div>
              <div class="dropdown-divider" style="margin-top: 20px"></div>
              <div class="row" style="margin-top: 20px">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div
                    class="card card-stats mb-4 mb-xl-2"
                    @click.stop="uploadSelected = 'proyections'"
                  >
                    <card
                      :class="
                        uploadSelected == 'proyections'
                          ? 'border-2 btn-sm'
                          : 'border-2 btn-primary btn-sm'
                      "
                      shadow
                      body-classes="py-4"
                    >
                      <div class="row" v-if="uploadSelected == 'proyections'">
                        <div class="col"></div>
                        <span
                          style="height: 1rem; width: 1rem; margin-top: -1rem"
                          @click.stop="uploadSelected = 'null'"
                          class="icon icon-shape bg-secondary text-black"
                        >
                          <i
                            @click.stop="uploadSelected = 'null'"
                            class="fas fa-times fa-2x"
                          ></i>
                        </span>
                      </div>
                      <br />
                      <div class="row">
                        <div
                          v-if="!isManagerSeller"
                          class="col"
                          style="margin-top: -2rem"
                        >
                          <h6 class="card-title text-uppercase mb-1">Crear</h6>
                          <span class="h3 font-weight-bold mb-0"
                            >Meta DB Menos</span
                          >
                        </div>
                        <div
                          v-if="!isManagerService"
                          class="col"
                          style="margin-top: -2rem"
                        >
                          <h6 class="card-title text-uppercase mb-1">Crear</h6>
                          <span class="h3 font-weight-bold mb-0"
                            >Configuración de Leads</span
                          >
                        </div>
                      </div>
                      <div
                        v-if="uploadSelected == 'proyections'"
                        class="row col-lg-12 text-center"
                        style="margin-top: 30px"
                      >
                        <base-input
                          class="col"
                          alternative
                          :label="
                            isManagerService ? 'Fecha de inicio:' : 'Fecha:'
                          "
                          input-classes="form-control-alternative"
                          v-if="!isManagerSeller"
                        >
                          <flat-pickr
                            v-model="projections.date"
                            placeholder="aaaa-mm-dd"
                            class="btn btn-sm text-center"
                            data-vv-as="Fecha"
                            :config="isManagerSeller ? config : {}"
                            name="startPeriod"
                            style="background-color: #ffffff"
                          />
                        </base-input>
                        <!--base-input v-if="isManagerService" alternative class="col-lg-4" label="Actividad" input-classes="form-control-alternative">
                          <base-dropdown style="width: 100%;" class="col-lg-7">
                            <base-button slot="title" type="warning" outline class="dropdown-toggle" style="width: 100%" size="sm">{{projections.activityType? projections.activityType:'Seleccionar'}}</base-button>
                            <a class="dropdown-item" @click="projections.activityType = 1">Negociación</a>
                          </base-dropdown>
                        </base-input-->
                        <base-input
                          v-if="isManagerService"
                          alternative
                          class="col-lg-4"
                          label="Plantilla"
                          input-classes="form-control-alternative"
                        >
                          <base-dropdown style="width: 100%" class="col-lg-7">
                            <base-button
                              slot="title"
                              type="warning"
                              outline
                              class="dropdown-toggle"
                              style="width: 100%"
                              size="sm"
                              >{{
                                projections.template
                                  ? projections.template.type
                                  : "Seleccionar"
                              }}</base-button
                            >
                            <a
                              class="dropdown-item"
                              v-for="template in templateTypes"
                              :key="template.id"
                              @click="setTemplateText(template)"
                            >
                              {{ template.type }}</a
                            >
                          </base-dropdown>
                        </base-input>
                        <base-dropdown style="width: 100%" class="col-6">
                          <base-button
                            slot="title"
                            type="warning"
                            outline
                            class="dropdown-toggle"
                            style="width: 100%"
                            size="sm"
                            >{{ getCustomerFilterText() }}</base-button
                          >
                          <!--div class="dropdown-divider"></div-->
                          <div v-if="!isManagerService">
                            <a
                              class="dropdown-item"
                              v-for="seller in sellers"
                              :key="seller.id"
                              v-on:click="setConsultantFilter(seller.id)"
                              >{{ seller.name }} {{ seller.lastname_1 }}</a
                            >
                          </div>
                          <!--div class="dropdown-divider"></div-->
                          <div v-if="!isManagerSeller">
                            <a
                              v-for="customerS in customerService"
                              :key="customerS.id"
                              class="dropdown-item"
                              v-on:click="setConsultantFilter(customerS.id)"
                              >{{ customerS.name }}
                              {{ customerS.lastname_1 }}</a
                            >
                          </div>
                        </base-dropdown>
                        <template v-if="!isManagerService">
                          <base-dropdown class="col-6">
                            <base-button
                              slot="title"
                              type="info"
                              outline
                              style="width: 100%"
                              class="dropdown-toggle"
                              v-model="projections.seller_id"
                              size="sm"
                              >{{ getSetupSellers() }}</base-button
                            >
                            <div>
                              <a
                                class="dropdown-item"
                                v-for="option in setupSellerCatalog"
                                :key="option.id"
                                v-on:click="setSetupSeller(option)"
                                >{{ option.type }}</a
                              >
                            </div>
                          </base-dropdown>
                          <base-input
                          class="col-auto"
                          alternative
                          style="margin: 1rem"
                          label="Fecha Inicio:"
                          input-classes="form-control-alternative"
                          v-if="!isManagerService"
                        >
                          <flat-pickr
                            v-model="projections.start_date"
                            placeholder="aaaa-mm-dd"
                            class="btn btn-sm text-center"
                            data-vv-as="Fecha Comienzo"
                            name="startPeriod"
                            style="background-color: #ffffff"
                          />
                          </base-input>
                          <base-input
                          class="col-auto"
                          alternative
                          style="margin: 1rem"
                          label="Fecha Final:"
                          input-classes="form-control-alternative"
                          v-if="!isManagerService"
                        >
                          <flat-pickr
                            v-model="projections.end_date"
                            placeholder="aaaa-mm-dd"
                            class="btn btn-sm text-center"
                            data-vv-as="Fecha Final"
                            name="startPeriod"
                            style="background-color: #ffffff"
                          />
                          </base-input>
                           <base-input
                            v-model="projections.clients"
                            alternative
                            type="number"
                            style="margin: 1rem"
                            class="col"
                            label="Clientes:"
                            input-classes="form-control-alternative"
                          />
                           <base-input
                            v-model="projections.contracts"
                            alternative
                            class="col"
                            type="number"
                            style="margin: 1rem"
                            label="Contratos:"
                            input-classes="form-control-alternative"
                          />
                          <base-input
                            v-if="projections.type_seller == 1"
                            v-model="projections.traditional_leads"
                            alternative
                            style="margin: 1rem"
                            class="col"
                            label="Leads Tradicional:"
                            input-classes="form-control-alternative"
                          />
                          <base-input
                            v-if="projections.type_seller == 1"
                            v-model="projections.automatic_leads"
                            alternative
                            style="margin: 1rem"
                            type="number"
                            class="col"
                            label="Leads Proceso Automatico:"
                            input-classes="form-control-alternative"
                          />
                          <base-input
                            v-if="projections.type_seller == 2"
                            v-model="projections.leads_indistinct"
                            alternative
                            style="margin: 1rem"
                            class="col"
                            type="number"
                            label="Leads:"
                            input-classes="form-control-alternative"
                          />
                        </template>
                        <!--template v-if="!isManagerSeller">
                          <base-input
                            v-model="projections.settlements"
                            alternative
                            class="col-lg-3"
                            label="Liquidaciones:"
                            input-classes="form-control-alternative"
                          />
                          <base-input
                            v-model="projections.negotiations"
                            alternative
                            class="col-lg-3"
                            label="Negociaciones:"
                            input-classes="form-control-alternative"
                          />
                          <base-input
                            v-model="projections.contacts"
                            alternative
                            class="col-lg-3"
                            label="Contactos:"
                            input-classes="form-control-alternative"
                          />
                          <base-input
                            v-model="projections.settlementsTramites"
                            alternative
                            class="col-lg-3"
                            label="TL:"
                            input-classes="form-control-alternative"
                          />
                        </template-->
                        <span
                          class="col-lg-12"
                          input-classes="form-control-alternative"
                          style="border: none; margin: 1rem"
                        />
                        <base-button
                          slot="title"
                          type="success"
                          class="btn col-lg-12"
                          size="sm"
                          v-if="!isManagerService"
                          v-on:click="saveSetupSeller()"
                          >Guardar</base-button
                        >
                        <base-button
                          slot="title"
                          type="success"
                          class="btn col-lg-12"
                          v-if="!isManagerSeller"
                          size="sm"
                          v-on:click="sendProjection()"
                          >Aplicar</base-button
                        >
                      </div>
                    </card>
                  </div>
                </div>

                <div class="container-fluid mt-5" v-if="!isManagerSeller">
                  <div class="row">
                    <div class="col-12" v-bind:style="{ cursor: pointer2 }">
                      <projection-table
                        title="Metas DB Menos"
                        :isManagerService="isManagerService"
                        :isManagerSeller="isManagerSeller"
                        :data="tableData"
                        :pagination="pagination"
                        :period="period"
                        v-on:itemSelected="itemSelected"
                        v-on:setPeriodFilter="setPeriodFilter"
                        v-on:setConsultantFilter="
                          setConsultantFilterProjections
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="container-fluid mt-5" v-if="!isManagerService">
                  <div class="row">
                    <div class="col-12" v-bind:style="{ cursor: pointer2 }">
                      <setup-seller-table
                        title="Configuración Vendedores"
                        :isManagerService="isManagerService"
                        :isManagerSeller="isManagerSeller"
                        :data="tableDataSetup"
                        :pagination="paginationSetup"
                        v-on:setTypeSetupFilter="setTypeSetupFilter"
                        v-on:pageSelected="getSetupSeller"
                      />
                    </div>
                    <div
                      class="col-12"
                      v-bind:style="{ cursor: pointer2 }"
                      style="margin-top: 1rem"
                    >
                      <setup-sellers-goals-table
                        title="Leads Asignados"
                        :isManagerService="isManagerService"
                        :isManagerSeller="isManagerSeller"
                        :data="tableDataSetupView"
                        :pagination="paginationSetupView"
                        v-on:pageSelected="getViewSetupSeller"
                        v-on:setTypeSetupFilter="setTypeSetupFilterGoals"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :show.sync="activityModal"
      v-on:close="activityModal = false"
      v-bind:large="true"
    >
      <template slot="header">
        <h2
          class="mb-0"
          id="activityConsultant"
          style="margin-top: 20px; margin-left: 10px"
        >
          {{ selectedRow.consultant }}
        </h2>
      </template>
      <div class="table-responsive">
        <base-table
          class="table align-items-center table-flush custom-text-wrap"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="selectedRow.data"
        >
          <template slot="columns">
            <th class="text-center">Fecha</th>
            <th class="text-center">Actividades</th>
          </template>

          <template slot-scope="{ row }">
            <th scope="row" class="text-center">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="name mb-0 text-sm">{{
                    $moment(row.date).format("dddd DD MMM, YYYY")
                  }}</span>
                </div>
              </div>
            </th>
            <td class="text-center">{{ row.value }}</td>
          </template>
        </base-table>
      </div>
    </modal>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ProjectionTable from "./Tables/ProjectionTable";
import SetupSellerTable from "./Tables/SetupSellerTable";
import SetupSellersGoalsTable from "./Tables/SetupSellersGoalsTable";
export default {
  name: "projections",
  components: {
    flatPickr,
    ProjectionTable,
    SetupSellerTable,
    SetupSellersGoalsTable,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    data: Array,
    unassigned: Boolean,
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    sellers() {
      return this.$store.state.sellers;
    },
    templateTypes() {
      return this.$store.state.typeGoalsSCCatalog;
    },
    customerService() {
      return this.$store.state.customerService;
    },
    setupSellerCatalog() {
      return this.$store.state.setupSellerCatalog;
    },
  },
  mounted() {
    this.getProjection(1);
    this.getSetupSeller(1);
    this.getViewSetupSeller(1);
  },
  data() {
    return {
      isAdmin: false,
      isManagerService: false,
      isManagerSeller: false,
      uploadSelected: null,
      config: {
        minDate: "2020-06-01",
        dateFormat: "Y-m-01",
      },
      pagination: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
      paginationSetup: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
      paginationSetupView: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
      tableData: [],
      tableDataSetup: [],
      tableDataSetupView: [],
      filter: null,
      consultantFilter: null,
      sellerSetupFilter: null,
      consultantFilterProjections: null,
      showSetPeriod: false,
      periodFilter: null,
      consultantValue: "",
      pointer: "pointer",
      TypeFilter: null,
      selectedRow: {
        data: null,
        consultant: "",
      },
      pointer2: "default",
      projections: {
        date: "",
        consultant: "",
      },
      activityModal: false,
      period: {
        start: null,
        end: null,
      },
    };
  },
  async created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    if (this.isAdmin) this.serviceMode(false);
  },
  methods: {
    setTypeSetupFilter(value) {
      this.TypeFilter = value;
      this.getSetupSeller(1);
    },
    setTypeSetupFilterGoals(value) {
      this.TypeFilter = value;
      this.getViewSetupSeller(1);
    },
    itemSelected(row) {
      console.log(row);
      this.selectedRow.data = row.projections.data;
      this.selectedRow.consultant = row.consultant;
      this.activityModal = true;
    },
    getProjection(page) {
      let limit = this.pagination.perPage;
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_PROJECTIONS;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        consultant_role: this.isManagerService ? "service" : "seller",
      };
      if (this.periodFilter === 1) request.created = { gt: this.period.start };
      if (this.periodFilter === 2)
        request.created = { gt: this.period.start, lte: this.period.end };
      if (this.consultantFilterProjections)
        request.consultant = this.consultantFilterProjections;
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.tableData = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.pagination.total = p["totalItems"];
          this.pagination.currentPage = page;
          this.pagination.perPage = limit;
        } else {
          this.$validateSession(data);
        }
      });
    },
    getSetupSeller(page) {
      let limit = this.paginationSetup.perPage;
      let url = this.$v.SERVER + this.$v.MANAGER_OPS.GET_SELLER_SETUP;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
      };
      request.start_date = {
        gte: this.$moment().startOf("month").format("YYYY-MM-DD"),
      };
      request.end_date = {
        lte: this.$moment().endOf("month").format("YYYY-MM-DD"),
      };
      if (this.TypeFilter)request = Object.assign(request,JSON.parse('{"type_seller": ' + this.TypeFilter + "}"));
      /*
      if (this.periodFilter === 1) request.created = {gt: this.period.start}
      if (this.periodFilter === 2) request.created = {gt: this.period.start, lte: this.period.end}
      if (this.consultantFilterProjections) request.consultant = this.consultantFilterProjections;
      */
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.tableDataSetup = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.paginationSetup.total = p["totalItems"];
          this.paginationSetup.currentPage = page;
          this.paginationSetup.perPage = limit;
        } else {
          this.$validateSession(data);
        }
      });
    },
    getViewSetupSeller(page) {
      let limit = this.paginationSetupView.perPage;
      let url =
        this.$v.SERVER + this.$v.MANAGER_OPS.GET_VIEW_LEADS_SELLER_SETUP;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
      };
      /*
      if (this.periodFilter === 1) request.created = {gt: this.period.start}
      if (this.periodFilter === 2) request.created = {gt: this.period.start, lte: this.period.end}
      if (this.consultantFilterProjections) request.consultant = this.consultantFilterProjections;
      */
      if (this.TypeFilter)request = Object.assign(request,JSON.parse('{"type_seller": ' + this.TypeFilter + "}"));
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.tableDataSetupView = data[this.$v.API_ROWS];
          let p = data[this.$v.API_ROW];
          this.paginationSetupView.total = p["totalItems"];
          this.paginationSetupView.currentPage = page;
          this.paginationSetupView.perPage = limit;
        } else {
          this.$validateSession(data);
        }
      });
    },
    async saveSetupSeller() {
      let url = this.$v.SERVER + this.$v.MANAGER_OPS.SET_SELLER_SETUP;
      let request = {
        id_seller: this.projections.id_seller,
        clients:this.projections.clients,
        contracts:this.projections.contracts,
        traditional_leads: this.projections.traditional_leads,
        automatic_leads: this.projections.automatic_leads,
        leads_indistinct: this.projections.leads_indistinct,
        type_seller: this.projections.type_seller,
        start_date: this.projections.start_date,
        end_date: this.projections.end_date
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        if (response.data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Carga exitosamente'
                     ));
          this.getSetupSeller(1);
          this.projections.seller_id = "";
          this.projections.traditional_leads = null;
          this.projections.automatic_leads = null;
          this.projections.leads_indistinct = null;
          this.sellerSetupFilter = null;
        } else {
          this.$validateSession(response.data);
        }
      });
    },
    async sendProjection() {
      if (this.projections.date == "") {
        this.$vs.notification(this.$notification(
                'info',
                 'Ingresa una fecha'
                     ));
      } else {
        let projectionsData = {};
        if (!this.isManagerService) {
          projectionsData.new_clients = parseInt(this.projections.new_clients);
          projectionsData.contracts = parseInt(this.projections.contracts);
          projectionsData.debt_amount = parseFloat(
            this.projections.debt_amount
          );
        } else {
          projectionsData.templateType = this.projections.template.id;
          //projectionsData.activityType = this.projections.activityType;
          /*projectionsData.negotiations = parseInt(this.projections.negotiations);
          projectionsData.contacts = parseInt(this.projections.contacts);
          projectionsData.settlementsTramites = parseInt(this.projections.settlementsTramites);*/
        }
        let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.SET_PROJECTION;
        let request = {
          date: this.projections.date,
          consultant: this.projections.consultant,
          projections: projectionsData,
        };
        this.$showLoader(true);
        this.$axios.post(url, request).then((response) => {
          this.$showLoader(false);
          if (response.data[this.$v.API_SUCCESS]) {
            this.$vs.notification(this.$notification(
                'success',
                 'Carga exitosamente'
                     ));
            this.getProjection(1);
            this.uploadSelected = null;
          } else {
            this.$validateSession(response.data);
          }
        });
      }
    },
    serviceMode(serviceMode) {
      this.isManagerService = serviceMode;
      this.isManagerSeller = !serviceMode;
      this.getProjection(1);
    },
    ConsultantFilter(value) {
      this.consultantFilter = value;
    },
    setTemplateText(value) {
      this.projections.template = value;
      this.$forceUpdate();
    },
    setConsultantFilter(value) {
      this.consultantFilter = value;
      this.projections.consultant = value;
      this.projections.id_seller = value;
    },
    setSetupSeller(value) {
      this.sellerSetupFilter = value.type;
      this.projections.type_seller = value.id;
    },
    setConsultantFilterProjections(value) {
      this.consultantFilterProjections = value;
      this.getProjection(1);
    },
    getCustomerFilterText() {
      if (this.consultantFilter)
        return this.getConsultantName(this.consultantFilter);
      return "Consultores";
    },
    getSetupSellers() {
      if (this.sellerSetupFilter) return this.sellerSetupFilter;
      return "Tipo de Canal";
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id) return this.sellers[i].name;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return this.customerService[i].name;
      return "- - -";
    },
    setPeriodFilter(value) {
      if (value === 1) {
        let date = new Date();
        let month = date.getMonth() + 1;
        if (month < 10) month = "0" + month;
        this.period.start = date.getFullYear() + "-" + month + "-" + "01";
      }
      this.periodFilter = value;
      this.getProjection(1);
    },
  },
};
</script>
<style>
</style>