<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col-lg-3 text-right">
          <base-dropdown>
            <base-button slot="title" type="primary" v-on:click="insertRow()">
              Nuevo Blog
            </base-button>
          </base-dropdown>
        </div>
      </div>
    </div>

    <div class="table-responsive" v-bind:style="{ cursor: pointer }">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
        v-on:click="rowSelected"
      >
        <template slot="columns">
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">ID</th>
          <th v-bind:style="{ cursor: pointer2 }">Entrada</th>
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">Fecha</th>
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">
            Acciones
          </th>
        </template>

        <template slot-scope="{ row }">
          <th class="text-center" scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.id }}</span>
              </div>
            </div>
          </th>
          <td>{{ row.title }}</td>
          <td class="text-center">
            {{ $moment(row.created).format("DD MMM, YYYY") }}
          </td>
          <td class="text-center">
            <i
              v-on:click.stop
              v-on:click="deleteRow(row)"
              class="fas fa-trash text-danger"
            />
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="col">
        <p class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          Página {{ pagination.currentPage }} de
          {{ Math.ceil(pagination.total / pagination.perPage) }}<br />{{
            pagination.total
          }}
          Blogs
        </p>
      </div>
      <base-pagination
        size="sm"
        :total="pagination.total"
        :per-page="pagination.perPage"
        :value="pagination.currentPage"
        v-on:pageSelected="pageSelected"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "blog-entries-table",
  props: {
    type: {
      type: String,
    },
    title: String,
    data: Array,
    pagination: Object,
  },
  computed: {},
  data() {
    return {
      isAdmin: false,
      pointer: "pointer",
      pointer2: "default",
    };
  },
  async created() {},
  methods: {
    rowSelected(row) {
      this.$emit("rowSelected", row);
    },
    deleteRow(row) {
      this.$emit("deleteRow", row);
    },
    insertRow() {
      this.$emit("insertRow");
    },
    pageSelected(page) {
      this.$emit("pageSelected", page);
    },
  },
};
</script>
<style>
</style>
