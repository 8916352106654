<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    {{title}}
                  </h3>
                  <h4 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Saldo DBM: {{$formatCurrency(amount)}}
                  </h4>
                </div>
                <div class="col text-right">
                  <base-button v-if="!(showData || showisService) && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="fShowData"></base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive text-left" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        v-on:click="clientMoneySelected">

                <template slot="columns">
                  <th class="text-left" v-bind:style="{cursor:pointer2}">Cuenta FA</th>
                  <th v-bind:style="{cursor:pointer2}">Nombre Cliente</th>
                  <th v-bind:style="{cursor:pointer2}">Saldo FA</th>
                  <th v-bind:style="{cursor:pointer2}">Banco</th>
                  <th v-bind:style="{cursor:pointer2}">CLABE</th>
                  <th v-bind:style="{cursor:pointer2}">Nombre Beneficiario</th>
                  <th v-bind:style="{cursor:pointer2}">Carta</th>
                </template>

                <template v-if="showData || showisService" slot-scope="{row}" class="text-left">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="name mb-0 text-sm">{{row.account}}</span>
                      </div>
                    </div>
                  </th>
                  <td>{{row.name_client}}</td>
                  <td>{{$formatCurrency(row.amount)}}</td>
                  <td>{{row.bank ? row.bank : '--'}}</td>
                  <td>{{row.CLABE ? row.CLABE : '--'}}</td>
                  <td>{{row.name_beneficiary}}</td>
                  <td>{{row.letter_money_back}}</td>
                </template>

            </base-table>
        </div>

        <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
  export default {
    name: 'clients-table',
    props: {
      type: {
        type: String
      },
      title: String,
      amount: Number,
      showisService: Boolean,
      showEye: Boolean,
      data: Array,
      pagination: Object
    },
    data() {
      return {
        isAuditor: false,
        isAdmin: false,
        isManagerService:false,
        tableData: [],
        pointer: 'pointer',
        pointer2: 'default',
        showData: false
      }
    },
    computed: {
      banks() { return this.$store.state.banks },
      user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      moneyBackCatalog() { return this.$store.state.moneyBackCatalog; },
    },
    async created() {
      this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
      this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    },
    methods: {
      fShowData(){
        this.showData = true;
        this.$emit("showData")
      },
      clientMoneySelected(payment) {
        this.$emit("clientMoneySelected", payment)
        },
      pageSelected(page) {
        this.$emit("pageSelected", page);
      },
      getValidatedClass(validated) {
        if (validated) return "text-success"
        return "text-danger"
      },
      formatCurrency(value) {
        return this.$formatCurrency(value)
      },
      getBanks(array) {
        let banks = []
        for (let a=0 ; a<=array.length ; a++) {
          for (let b = 0; b < this.banks.length; b++) {
            if (this.banks[b].id === array[a]) {
              banks.push(this.banks[b])
              break
            }
          }
        }
        return banks
      },
      getTypeMoneyBack(id) {
        for (let i=0 ; i<this.moneyBackCatalog.length ; i++)
          if (this.moneyBackCatalog[i].id === id){
            return this.moneyBackCatalog[i].type
          }
      },
      formatDate(date) {
        return this.$moment(date).format("DD MMM, YYYY")
        // return this.$formatDate(date)
      },
      onNewItem() {
        this.$emit("onNewItem")
      },
      onMoneyBackSelected(item) {
        this.$emit("onMoneyBackSelected", item)
      },
      //---Modification
      /*
      deleteFile(dataM){
        dataM.type = 'PAYMENT';
        this.$emit("newModification", dataM, this.$v.MODIFICATIONS.PAYMENT)
      },
      */
    }
  }
</script>
<style>
</style>
