
<template>
  <div>
    <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8" />

    <div v-if="isDevs" class="container-fluid mt--7" @click="uploadSelected = null">
      <div class="row">
        <div class="col" v-bind:style="{ cursor: pointer2 }">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="display-4">Proyecto: {{ project.name }}</h3>
                </div>
              </div>
              <div class="dropdown-divider" style="margin-top: 20px"></div>
              <div class="row" style="margin-top: 20px">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div
                    class="card card-stats mb-4 mb-xl-2"
                    @click.stop="uploadSelected = 'proyections'"
                  >
                    <card
                      :class="
                        uploadSelected == 'proyections'
                          ? 'border-2 btn-sm'
                          : 'border-2 btn-primary btn-sm'
                      "
                      shadow
                      body-classes="py-4"
                    >
                      <div class="row" v-if="uploadSelected == 'proyections'">
                        <div class="col"></div>
                        <span
                          style="height: 1rem; width: 1rem; margin-top: -1rem"
                          @click.stop="uploadSelected = 'null'"
                          class="icon icon-shape bg-secondary text-black"
                        >
                          <i
                            @click.stop="uploadSelected = 'null'"
                            class="fas fa-times fa-2x"
                          ></i>
                        </span>
                      </div>
                      <br />
                      <div class="row" style="margin-top: -1rem">
                        <div class="col">
                          <h6 class="card-title text-uppercase mb-1">Crear</h6>
                          <span class="h3 font-weight-bold mb-0">Módulo</span>
                        </div>
                        <div class="col-auto">
                          <div class="icon icon-shape bg-info text-white">
                            <i class="fas fa-tasks"></i>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="uploadSelected == 'proyections'"
                        class="row col-lg-12 text-center"
                        style="margin-top: 30px"
                      >
                        <!--
                       <base-input
                          alternative
                          class="col-lg-6"
                          input-classes="form-control-alternative"
                        >
                          <base-dropdown style="width: 100%;" class="col-lg-3">
                            <base-button
                              slot="title"
                              type="primary"
                              outline
                              class="dropdown-toggle"
                              style="width: 100%"
                              size="sm"
                            >{{getCustomerFilterText()}}</base-button>
                            <div class="dropdown-divider"></div>
                            <a
                              class="dropdown-item"
                              v-for="(seller) in sellers"
                              :key="seller.id"
                              v-on:click="setConsultantFilter(seller.id)"
                            >{{seller.name}} {{seller.lastname_1}}</a>
                            <div class="dropdown-divider"></div>
                            <a
                              v-for="(customerS) in customerService"
                              :key="customerS.id"
                              class="dropdown-item"
                              v-on:click="setConsultantFilter(customerS.id)"
                            >{{customerS.name}} {{customerS.lastname_1}}</a>
                          </base-dropdown>
                        </base-input>
                        !-->
                        <base-input
                          v-model="module.name"
                          alternative
                          class="col-lg-6"
                          placeholder="Nombre Módulo"
                          input-classes="form-control-alternative"
                        />
                        <base-input
                          class="col-lg-4"
                          alternative
                          label="Fecha Entrega: "
                          input-classes="form-control-alternative"
                        >
                          <flat-pickr
                            v-model="module.delivery_date"
                            placeholder="aaaa-mm-dd"
                            class="btn btn-sm text-center"
                            data-vv-as="Fecha"
                            name="startPeriod"
                            style="background-color: #ffffff"
                          />
                        </base-input>
                        <base-input
                          v-model="module.description"
                          alternative
                          class="col-lg-12"
                          placeholder="Descripción"
                          input-classes="form-control-alternative"
                        />

                        <span
                          class="col-lg-4"
                          input-classes="form-control-alternative"
                          style="border: none"
                        />
                        <base-button
                          slot="title"
                          type="success"
                          class="btn col-lg-4"
                          size="sm"
                          v-on:click="sendModules()"
                          >Crear</base-button
                        >
                      </div>
                    </card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="!isDevs?'container-fluid mt--8':'container-fluid mt-4'">
      <div class="row">
        <div class="col-12" v-bind:style="{ cursor: pointer2 }">
          <dev-modules-table
            title="Módulos DB Menos"
            :data="tableData"
            :pagination="pagination"
            :period="period"
            v-on:pageSelected="getModules"
            v-on:projectSelected="projectSelected"
            v-on:setPeriodFilter="setPeriodFilter"
          />
        </div>
      </div>
    </div>

    <modal
      :show.sync="historyModal"
      v-on:close="closeModal()"
      v-bind:large="true"
    >
      <template slot="header">
        <h2
          class="mb-0"
          id="TracingLead"
          style="margin-top: 20px; margin-left: 10px"
        >
          {{ module.name }}
        </h2>
      </template>
      <div v-if="isDevs" class="p-2 text-center" style="margin-bottom:1rem; margin-top:-2rem">
        <base-button
          slot="title"
          style="width: 100%; margin-bottom: 4px"
          :type="module.date_check?'primary':'danger'"
          class="btn"
          v-on:click="updateModule()"
          >{{
            module.date_check ? "Modúlo Entregado" : "Modúlo No Entregado"
          }}</base-button
        >
        <span class=" description">{{
          module.date_check
            ? formatDate(module.date_check)
            : "No entregado aún"
        }}</span>
      </div>
      <div v-if="newTrace">
        <div class="col-lg-12">
          <base-dropdown>
            <base-button
              slot="title"
              type="success"
              class="dropdown-toggle"
              size="sm"
            >
              {{ traceModule.status ? traceModule.status : "Seleccionar" }}
            </base-button>
            <a
              class="dropdown-item"
              href="#"
              v-for="(status, index) in devEventsHistory"
              :key="index"
              v-on:click="setTraceStatus(status)"
              >{{ status.status }}</a
            >
          </base-dropdown>
          <base-input
            alternative=""
            placeholder="Descripción"
            input-classes="form-control-alternative"
            style="margin-top: 20px"
            v-model="traceModule.description"
          />
        </div>
        <div class="col text-right">
          <base-button slot="title" type="primary" v-on:click="uploadTrace()"
            >Crear</base-button
          >
        </div>
      </div>
      <div v-else>
        <dev-module-history-table
          title="Historial Módulo"
          :data="moduleHistory"
          v-on:newLeadTrace="newLeadTrace"
        />
      </div>
    </modal>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DevModuleHistoryTable from "./Tables/DevModuleHistoryTable";
import DevModulesTable from "./Tables/DevModulesTable";
export default {
  name: "modules",
  components: {
    flatPickr,
    DevModuleHistoryTable,
    DevModulesTable,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    data: Array,
    unassigned: Boolean,
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    sellers() {
      return this.$store.state.sellers;
    },
    customerService() {
      return this.$store.state.customerService;
    },
    devEventsHistory() {
      return this.$store.state.devEventsHistory;
    },
  },
  mounted() {
    this.getModules(1);
    this.getProjects(1);
  },
  data() {
    return {
      uploadSelected: null,
      config: {
        minDate: "2020-06-01",
        dateFormat: "Y-m-01",
      },
      pagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      isAdmin: false,
      isManagerService: false,
      tableData: [],
      filter: null,
      consultantFilter: null,
      consultantFilterProjectios: null,
      showSetPeriod: false,
      periodFilter: null,
      consultantValue: "",
      pointer: "pointer",
      pointer2: "default",
      module: {},
      project: {},
      isDevs: false,
      moduleHistory: [],
      selectedProject: {},
      newTrace: false,
      traceModule: {},
      projectId: null,
      historyModal: false,
      period: {
        start: null,
        end: null,
      },
    };
  },
  async created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.projectId = this.$route.query.id;
    this.isDevs = this.user.department === this.$v.DEPARTAMENT.DEV;
  },
  methods: {
     updateModule() {
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.DEVS.UPLOAD_MODULE_DATE;
      let request;
        request = {
          id: this.module.id,
          date_check: Date()
        }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.closeModal();
          location.reload();
          this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
          this.$forceUpdate();
        } else {
           this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'intenta nuevamente'
                     ));
        }
      });
    },
    formatDate(date) {
      return this.$formatDate(date);
    },
    projectSelected(module) {
      this.moduleHistory = [];
      this.module = module;
      this.historyModal = true;
      this.getHistoryModule();
    },
    newLeadTrace() {
      this.newTrace = true;
    },
    getHistoryModule() {
      let url = this.$v.SERVER + this.$v.DEVS.GET_MODULES_HISTORY; //+ "?user=" + this.selectedLead.id
      let request = {
        module: this.module.id
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        if (response.data[this.$v.API_SUCCESS]) {
          this.moduleHistory = response.data[this.$v.API_ROWS];
        }
      });
    },
    async sendModules() {
      if (this.module.name == "") {
        this.$vs.notification(this.$notification(
                'info',
                 'Ingresa un nombre'
                     ));
      } else {
        let projectId = this.$route.query.id;
        let url = this.$v.SERVER + this.$v.DEVS.SET_MODULES;
        let request = {
          project_id: projectId,
          name: this.module.name,
          description: this.module.description,
          delivery_date: this.module.delivery_date + "T12:00:00.000Z",
        };
        this.$showLoader(true);
        this.$axios.post(url, request).then((response) => {
          this.$showLoader(false);
          if (response.data[this.$v.API_SUCCESS]) {
            location.reload();
            	this.$vs.notification(this.$notification(
                'success',
                 'Cambios exitosamente'
                     ));Modules(1);
            this.uploadSelected = null;
          } else {
            this.$showLoader(false);
            this.$validateSession(response.data);
          }
        });
      }
    },
    getProjects() {
      let url = this.$v.SERVER + this.$v.DEVS.GET_PROJECTS;
      let requestS = {
        id: this.projectId,
      };
      this.$showLoader(true);
      this.$axios.post(url, requestS).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let projects = response.data[this.$v.API_ROWS];
          for (let i = 0; i < projects.length; i++) {
            this.project = projects[i];
          }
        } else {
          this.$validateSession(data);
        }
      });
    },
    ConsultantFilter(value) {
      this.consultantFilter = value;
    },
    setConsultantFilter(value) {
      this.consultantFilter = value;
      this.projections.consultant = value;
    },
    setConsultantFilterProjections(value) {
      this.consultantFilterProjectios = value;
      this.getModules(1);
    },
    getCustomerFilterText() {
      if (this.consultantFilter)
        return this.getConsultantName(this.consultantFilter);
      return "Plataforma";
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id) return this.sellers[i].name;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return this.customerService[i].name;
      return "- - -";
    },

    getModules(page) {
      let projectId = this.$route.query.id;
      let limit = this.pagination.perPage;
      let url = this.$v.SERVER + this.$v.DEVS.GET_MODULES;
      let requestS = {
        offset: limit * (page - 1),
        limit: limit,
        project_id: projectId,
      };
      if (this.periodFilter === 1) {
        let created = { gt: this.period.start };
        Object.assign(requestS, { created });
      }
      if (this.periodFilter === 2) {
        let created = {
          gt: this.period.start + "T12:00:00.000Z",
          lte: this.period.end + "T12:00:00.000Z",
        };
        requestS = Object.assign(requestS, { created });
      }
      this.$showLoader(true);
      this.$axios.post(url, requestS).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.tableData = data[this.$v.API_ROWS];
          this.pagination.total = p["totalItems"];
          this.pagination.currentPage = page;
          this.pagination.perPage = limit;
        } else {
          this.$validateSession(data);
        }
      });
    },
    setTraceStatus(status) {
      this.traceModule.statusId = status.id;
      this.traceModule.status = status.status;
      this.$forceUpdate();
    },
    uploadTrace() {
      if (
        this.traceModule.statusId === 0 ||
        this.traceModule.description === ""
      ) {
       	this.$vs.notification(this.$notification(
                'warning',
                 'Datos incompletos'
                     ));
        return;
      }
      let json = {
        event: this.traceModule.statusId,
        module: this.module.id,
        description: this.traceModule.description,
      };
      let url = this.$v.SERVER + this.$v.DEVS.SET_MODULES_HISTORY;
      this.$showLoader(true);
      this.$axios.post(url, json).then((response) => {
        this.$showLoader(false);
        if (response.data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Carga exitosamente'
                     ));
          this.closeModal();
          this.getHistoryModule();
          this.traceModule.events = 0;
          this.traceModule.status = null;
          this.traceModule.description = "";
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'intenta nuevamente'
                     ));
        
        }
      });
    },
    setPeriodFilter(value) {
      if (value === 1) {
        let date = new Date();
        let month = date.getMonth() + 1;
        if (month < 10) month = "0" + month;
        this.period.start =
          date.getFullYear() + "-" + month + "-" + "01" + "T12:00:00.000Z";
      }
      this.periodFilter = value;
      this.getModules(1);
    },
    closeModal() {
      
      this.historyModal = false;
      this.newTrace = false;
      this.getModules(1);

    },
  },
};
</script>
<style>
</style>