<template>
    <div>
        <base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col" v-bind:style="{cursor:pointer2}">
                    <commission-editor-table
                            title="Editor de comisiones"
                            :data="tableData"/>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import CommissionEditorTable from './Tables/CommissionEditorTable'
  export default {
      name: 'tables',
      components: {
          CommissionEditorTable
      },
      mounted() {
          this.getEditableCommissions()
      },
      computed: {
          user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
      },
      data() {
          return {
              tableData: [],
              pointer: 'pointer',
              pointer2: 'default'
          };
      },
      methods: {
          getEditableCommissions() {
              let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_MASSIVE_COMMISSIONS
              this.$showLoader(true)
              this.$axios.get(url).then(response => {
                  this.$showLoader(false)
                  let data = response.data
                  if (data[this.$v.API_SUCCESS]) {
                      this.tableData = data[this.$v.API_ROWS]
                  } else { this.$validateSession(data) }
              })
          },
      }
  };
</script>
<style></style>