<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="primary" outline class="dropdown-toggle" style="width: 100%" size="sm">
                            {{getEventFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setEventFilter(null)">Mostrar todos</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" v-for="(userHistoryEvent) in userHistoryEvents" :key="userHistoryEvent.id" v-on:click="setEventFilter(userHistoryEvent.id)">{{userHistoryEvent.event}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="info" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{deviceFilter ? deviceFilter : "Dispositivos"}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setDeviceFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setDeviceFilter('app')">App</a>
                        <a class="dropdown-item" v-on:click="setDeviceFilter('iOS')">iOS</a>
                        <a class="dropdown-item" v-on:click="setDeviceFilter('android')">Android</a>
                        <a class="dropdown-item" v-on:click="setDeviceFilter('web')">Web</a>
                    </base-dropdown>
                </div>
                <div v-if="isAdmin || isManagerSeller" class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" style="width: 100%" size="sm">
                            {{getCustomerFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setConsultantFilter(null)">Mostrar todos</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" v-for="(seller) in sellers" :key="seller.id" v-on:click="setConsultantFilter(seller.id)">{{seller.name}} {{seller.lastname_1}}</a>
                        <div class="dropdown-divider"></div>
                        <a v-for="(customerS) in customerService" :key="customerS.id" class="dropdown-item" v-on:click="setConsultantFilter(customerS.id)">{{customerS.name}} {{customerS.lastname_1}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getPeriodFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
                        <div v-if="!showSetPeriod" class="dropdown-divider"></div>
                        <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
                    </base-dropdown>
                </div>                
                <div v-if="showSetPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
                        <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        :period="period">

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">Historia</th>
                    <th v-bind:style="{cursor:pointer2}">Usuario</th>
                    <th v-bind:style="{cursor:pointer2}">Nombre</th>
                    <th v-if="isAdmin" v-bind:style="{cursor:pointer2}">Consultor</th>
                    <th v-bind:style="{cursor:pointer2}">Evento</th>
                    <th v-bind:style="{cursor:pointer2}">Dispositivo</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha</th>
                </template>

                <template slot-scope="{row}">
                    <td>{{row.historyID}}</td>
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <a :href="'https://admin.dbmenos.com/client?id='+row.user" target="_blank">
                                    <span class="name mb-0 text-sm">{{row.user}}<i class="fas fa-user-edit text-primary"></i></span>
                                </a>
                            </div>
                        </div>
                    </th>
                    <td>{{row.name}}</td>
                    <td v-if="isAdmin">{{getConsultantName(row.consultant)}}</td>
                    <td>{{row.eventName}}</td>
                    <td>{{row.device}}</td>
                    <td>{{formatDate(row.created)}}</td>
                </template>
            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} eventos
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    export default {
        name: 'user-history-table',
        components:{
            flatPickr
        },
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            period: {
                start: null,
                end: null
            },
        },
        data() {
            return {
                isAdmin: false,
                showSetPeriod: false,
                tableData: [],
                eventFilter: null,
                deviceFilter: null,
                consultantFilter: null,
                periodFilter: null,
                pointer: 'pointer',
                pointer2: 'default',
                isManagerSeller:false,
            }
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService },
            userHistoryEvents() { return this.$store.state.userHistoryEvents }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
            this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
        },
        methods: {
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            formatDate(date) {
            if (date <= "2020-11-10") {
                return this.$moment(date).subtract(6, "hours").format("DD MMM YYYY, h:mm a");
            } else {
                return this.$moment(date).format("DD MMM YYYY, h:mm a");
            }
            },
            setEventFilter(value) {
                this.eventFilter = value
                this.$emit("setEventFilter", value);
            },
            getEventFilterText() {
                if (this.eventFilter) return this.getEventName(this.eventFilter)
                return "Eventos"
            },
            getEventName(id) {
                for (let i=0 ; i<this.userHistoryEvents.length ; i++)
                    if (this.userHistoryEvents[i].id === id) return this.userHistoryEvents[i].event
                return "- - -"
            },
            setDeviceFilter(value) {
                this.deviceFilter = value
                this.$emit("setDeviceFilter", value);
            },
            setConsultantFilter(value) {
                this.consultantFilter = value
                this.$emit("setConsultantFilter", value);
            },
            getCustomerFilterText() {
                if (this.consultantFilter) return this.getConsultantName(this.consultantFilter)
                return "Consultores"
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            },
            setPeriod(){
                this.showSetPeriod=true
                this.period.start=null
                this.period.end=null
                this.getPeriodFilterText()                
            },
            setPeriodFilter(value) {        
                if(value!=2){
                    this.showSetPeriod = false
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                    
                }else if(this.period.start && this.period.end){
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                }
                
            },
            getPeriodFilterText() {
                if(this.showSetPeriod)
                    return "Periodo del: "
                else if(this.periodFilter === 1)
                    return "Mes actual"
                else return "Fecha"
            },
        }
    }
</script>
<style>
</style>
