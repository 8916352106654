<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                    <!--h4 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{$formatCurrency(amount)}}
                    </h4-->
                </div>
                <div v-if="!disabledFilterPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="startDate" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="endDate" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha"/>
                        <base-button type="none text-green" size="sm" @click="getFAStatementAccount()">Solicitar</base-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list"
                        @click="itemSelected"
                        :data="data">

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">Transferencia</th>
                    <th v-bind:style="{cursor:pointer2}">Monto</th>
                    <th v-bind:style="{cursor:pointer2}">Tipo</th>
                    <th v-bind:style="{cursor:pointer2}">Descripción</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha</th>
                </template>

                <template slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{row.Nrotrans}}</span>
                            </div>
                        </div>
                    </th>
                    <td>{{$formatCurrency(row.Monto)}}</td>
                    <td>{{row.Tipotrans}}</td>
                    <td>{{row.Decripcion}}</td>
                    <td>{{$formatDate($moment(row.Fecha,'DD-MM-YYYY'))}}</td>
                </template>
            </base-table>
        </div>

        <!--div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div-->

    </div>
</template>
<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    export default {
        name: 'account-summary-table',
        components:{
            flatPickr
        },
        props: {
          type: {
            type: String
          },
          title: String,
          data: Array,
          disabledFilterPeriod: Boolean
        },
        data() {
            return {
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                startDate: null,
                endDate: null,
                isAdmin:false,
                isManagerService:false,
            }
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
        },
        methods: {
            getFAStatementAccount() {
                this.$emit("getFAStatementAccount", this.startDate, this.endDate);
            },
            itemSelected(row) {
              this.$emit("itemSelected", row)
            },
        }
    }
</script>
<style>
</style>
