<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>
		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<massive-payments-table 
							title="Pagos no validados"
							:data="tableData"
							:pagination="pagination"
                            :period="period"
							v-on:setPeriodFilter="setPeriodFilter"
							v-on:pageSelected="getPayments"/>
							<!--v-on:setConsultantFilter="setConsultantFilter"-->
							
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import MassivePaymentsTable from './Tables/MassivePaymentsTable';
	export default {
		name: 'tables',
		components: {
			MassivePaymentsTable
		},
		computed: {
			user() {return this.$getLS(this.$v.STORE_KEYS.USER);},
		},
		created() {
			this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
		},
/*			this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
			this.isSeller = this.user.role === this.$v.ROLES.SELLER;
			this.isService = this.user.role === this.$v.ROLES.SERVICE || this.user.role === this.$v.ROLES.AUDITOR;
			this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
			this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
		},*/
		mounted() {
			this.getPayments(1)
			this.$root.$on('searchQuery', query => {
				if (query.length > 0)
					this.searchQuery = query
				else
					this.searchQuery = null
				this.getPayments(1)
			})
		},
		data() {
			return {
				searchQuery: null,
				tableData: [],
				pagination: {
					total: 0,
					perPage: 10,
					currentPage: 1
				},
				periodFilter: null,
				//consultantFilter: null,
				period: {
					start: null,
					end: null
				},
				pointer: 'pointer',
				pointer2: 'default',
				isAuditor: false,
				/*isAdmin: false,
				isManagerSeller: false,
				isManagerService: false,
				isSeller: false,
				isService: false,*/
			};
		},
		methods: {
			getPayments(page) {
				let limit = this.pagination.perPage
				let urlRequest = this.isAuditor?this.$v.SERVICE_OPS.GET_MASSIVE_PAYMENTS:this.$v.ADMIN_OPS.GET_MASSIVE_PAYMENTS;
				let url = this.$v.SERVER + urlRequest
				if (this.searchQuery) url += "?find=" + this.searchQuery
				let request ={
					offset: limit*(page - 1),
					limit: limit,
					validated: 0
				}
				if (this.periodFilter === 1) {
					let created = {gte: this.period.start}
					Object.assign(request, {created})
				}else if (this.periodFilter === 2){
					let created = {
						gt: this.period.start,//+"T12:00:00.000Z",
						lte: this.period.end//+"T12:00:00.000Z"
					}
					Object.assign(request, {created})
				}
				//if (this.completePaymentFilter !== null) Object.assign(request, {firstPaymentComplete: this.completePaymentFilter})
				//if (!table) Object.assign(request, {firstPaymentComplete: 0})
				//if (this.validationFilter === 1) Object.assign(request, {notValidatedPaymentCount: 0})
				//if (this.validationFilter === 0) Object.assign(request, {notValidatedPaymentCount: 'notEmpty'})
				if (this.consultantFilter) Object.assign(request, {consultant_id: this.consultantFilter})
				this.$showLoader(true)
				this.$axios.post(url,request).then(response => {
					this.$showLoader(false)
					let data = response.data
					if (data[this.$v.API_SUCCESS]) {
						let p = data[this.$v.API_ROW]
						this.tableData = data[this.$v.API_ROWS]
						this.pagination.total = p['totalItems']
						this.pagination.currentPage = page
						this.pagination.perPage = limit
					} else { this.$validateSession(data) }
				})
			},
			/*setConsultantFilter(value) {
				this.consultantFilter = value
				this.getPayments(1)
			},*/
			setPeriodFilter(value) {
				if(value === 1){
					let date = new Date()
					let month = date.getMonth()+1
					if(month<10) month= "0"+month
					this.period.start = date.getFullYear()+"-"+month+"-"+"01"
				}
				this.periodFilter = value
				this.getPayments(1)
			},
		}
	};
</script>
<style></style>