<template>
  <div>
    <div class="container-fluid mt--1" v-bind:style="{cursor:pointer2}">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col">
            <h5 class="h2 mb-0">Historial de Eventos</h5>
          </div>
          <!--
          <div class="col">
            <ul class="nav nav-pills justify-content-end">
              <li class="nav-item mr-2 mr-md-0">
                <div class="col">
                  <div class="row justify-content-center">
                    <flat-pickr
                      v-model="period.start"
                      :placeholder="today"
                      class="btn btn-sm"
                      data-vv-as="Fecha"
                      name="startPeriod"
                      :config="config"
                      style="margin-top:1rem; background-color:#fff"
                    />
                    <flat-pickr
                      v-model="period.end"
                      placeholder="Seleccionar Fecha"
                      class="btn btn-sm"
                      data-vv-as="Fecha"
                      name="endPeriod"
                      :config="config"
                      style="margin-top:1rem; background-color:#fff"
                    />
                    <base-button
                      type="info text-black"
                      size="sm"
                      style="margin-top:1rem;"
                      @click="setPeriodFilterDaily()"
                    >Aplicar</base-button>
                    
                  </div>
                </div>
              </li>
            </ul>
          </div>
          !-->
        </div>
        <template>
          <div class="row">
            <div class="col-lg-12">
              <!--<div ref="BarDailyActivities" style="width: 100%; height: 420px" />!-->
              <div ref="sellersBarDailyActivities" style="width: 100%; height: 420px" />
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";


import numeral from "numeral";
export default {
  components: {
   // flatPickr
  },
  computed: {
    generalInfo() {return this.$store.state.generalInfo;},
    user() {return this.$getLS(this.$v.STORE_KEYS.USER);},
    sellers() {return this.$store.state.sellers;},
    service() {return this.$store.state.customerService;},
    userTracingStatus() {return this.$store.state.userTraceStatus},
    leadStatus() {return this.$store.state.leadStatus},
    history() {return this.$store.state.userHistoryEvents}
  },
  data() {
    return {
      events: {
        seller: [
         { id: 1, status: "Prospecto", st:"Prospecto"},
          { id: 2, status: "Intento de contacto sin éxito", st:"ICSE"},
          { id: 3, status: "Contacto, no perfila", st:"No perfila" },
          { id: 4, status: "Seguimiento", st:"Seguimiento" },
          { id: 5, status: "Si perfila, no interesado", st:"No interesado" },
          { id: 6, status: "Si perfila, interesado", st:"Interesado" },
          { id: 7, status: "Documentos" , st:"Documentos" },
          { id: 8, status: "Contrato", st:"Contrato" },
          { id: 9, status: "Cerrado", st:"Cerrado"},
          { id: 10, status: "Contrato sin depósito mes anterior", st:"Sin depósito" },
          { id: 11, status: "Duplicado", st:"Duplicado"},
          { id: 12, status: "Datos incorrectos", st:"Datos incorrectos" },
          { id: 13, status: "Sin Seguimiento", st:"Sin Seguimiento" }
        ],
        service: [
          { id: 1,status: "Referencias de pago", st: "Referencias de pago" },
          { id: 2,status: "Fecha de pago", st: "Fechas de pago" },
          { id: 3,status: "Monto de pago", st: "Monto de pago" },
          { id: 4,status: "Problemas de pago", st: "Problemas de pago" },
          { id: 5,status: "Negociación de descuentos", st: "Negociación de descuentos" },
          { id: 6,status: "Datos incorrectos", st: "Fecha de liquidación" },
          { id: 7,status: "Datos incorrectos", st: "Negociación carta convenio" },
          { id: 8,status: "Datos incorrectos", st: "Procedimiento de cobranza" },
          { id: 9,status: "Datos incorrectos", st: "Llamadas de cobranza" },
          { id: 10,status: "Datos incorrectos", st: "Carta de cobranza" },
          { id: 11,status: "Datos incorrectos", st: "Visita de cobranza" },
          { id: 12,status: "Acceso al portal de clientes", st: "Acceso al portal de clientes" },
          { id: 13,status: "Solicitud de baja / cancelación", st: "Solicitud de baja / cancelación" },
          { id: 14,status: "Otro", st: "Otro" },
          { id: 15,status: "Validado", st: "Validado" },
          { id: 16,status: "Bienvenida", st: "Bienvenida" },
          { id: 17,status: "Nómina mismo banco", st: "Nómina mismo banco" },
          { id: 18,status: "Auditado", st: "Auditado" }
        ]
      },
      selected: {
        service: null,
        serviceCount: 0,
        seller: null,
        sellerCount: 0,
        sellerChartType: "line",
        serviceChartType: "line"
      },
      chartTypes: {
        line: "line",
        radial: "radial"
      },
      isAdmin: false,
      isManagerSeller: false,
      isManagerService: false,
      isSeller: false,
      isService: false,
      chartsData: {
        sellsDailyActivities: [],
        serviceDailyActivities: []
      },
      charts: {
        radialCharts: {
          seller: null,
          service: null
        },
        BarCharts: {
          seller: null,
          service: null
        },
        lineCharts: {
          seller: null,
          service: null
        },
        consultantBarCharts: {
          seller: null,
          service: null
        }
      },
      /*SERVICE*/
      chartsDataService: {},
      testData: "1354",
      showSetPeriodActive: false,
      showSetPeriodDaily: false,
      showSetPeriodCommissions: false,
      tableDataGraduates: [],
      tableDataDevice: [],
      tableDataCierres: [],
      tableDataBank: [],
      tableDataGraphicsActive_Mes: [],
      tableDataGraphicsActive_Total: [],
      tableDataGraphicsNewClients_Device: [],
      tableDataGraphicsNewClients_Users: [],
      tableDataGraphicsCommissions_Mes: [],
      tableDataGraphicsCommissions_Total: [],
      tableDataAmount: [],
      tableDataRegister: [],
      tableDataConsultant: [],
      tableDataNewClientStats: [],
      tableDataCommissions: [],
      accountsBanks: [
        {
          bank: "",
          accounts: 0,
          amount: ""
        }
      ],
      searchQuery: null,
      paginationBanks: {
        total: 0,
        perPage: 3,
        currentPage: 1
      },
      paginationDevice: {
        total: 0,
        perPage: 5,
        currentPage: 1
      },
      paginationCierres: {
        total: 0,
        perPage: 5,
        currentPage: 1
      },
      paginationNewClientsStats: {
        total: 0,
        perPage: 5,
        currentPage: 1
      },
      paginationCommissions: {
        total: 0,
        perPage: 5,
        currentPage: 1
      },
      paginationConsultant: {
        total: 0,
        perPage: 10,
        currentPage: 1
      },
      paginationActiveGraphics: {
        total: 0,
        perPage: 10,
        currentPage: 1
      },
      period: {
        start: null,
        end: null
      },
      periodService: {
        start: null,
        end: null
      },
      //selectedHistory: {},
      eventFilter: null,
      deviceFilter: null,
      consultantFilter: null,
      pointer: "pointer",
      pointer2: "default",
      today: "",
      config: {
        maxDate: "today",
        dateFormat: "Y-m-d"
      }
    };
  },
  created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    this.getDay();
  },
  methods: {
       setPeriodFilterDaily() {
      this.getDailyActivities("seller");
    },
      formatCurrency(num) {
      return numeral(num).format("$ 0,0.00");
    },
    formatCurrencyPorcentaje(num) {
      return numeral(num).format("0,0.00");
    },
    getDay() {
      let f = new Date();
      let month = f.getMonth() + 1;
      if (month < 10) month = "0" + month;
      let today = new Date(f.getFullYear(), f.getMonth(), 1);
      this.today = this.$moment(today).format("YYYY-MM-DD");
      // return wkEnd;
    },
     async postServer(url, payload) {
      try {
        let r = await this.$axios.post(url, payload);
        if (r.data.success) return r.data.rows;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
     // region consultant charts
    getDailyActivitiesByEvent(a,consultantType = "seller") {
      let activities = JSON.parse(JSON.stringify(a));
      let data = [];
      let event = '';
      let total_event = 0;
      consultantType === "seller";
      let sorted = JSON.parse(
        JSON.stringify(
          activities.sort((a, b) => (a.calification > b.calification ? 1 : -1))
        )
      );
      for (let n = 0; n <sorted.length; n++) {
      total_event=(parseInt(sorted[n]["total_event"]));
      event=(sorted[n]["event"]);
      data.push({
        total_event: total_event ,
        event: event 
      });
      }
      return {
        data
      };
    },
      getDailyActivitiesByTimeRange(activities, consultant) {
      let data = [];
      let total_event = [];

      let sorted = JSON.parse(
        JSON.stringify(
          activities.sort((a, b) => (a.calification > b.calification ? 1 : -1))
        )
      );
      
      if (consultant) {
        sorted = sorted.filter(a => a["seller"] === consultant);
      }
      for (let n = 0; n < sorted.length; n++) {
      total_event.push(parseInt(sorted[n]["total_event"]));
      
      }
       data.push({
        total_event: total_event
      });
      return {
        data
      };
      
    },
     createBarDailyActivitiesChartByConsultant(consultant = "seller") {
      let chartRef = "sellersBarDailyActivities";
      let categoryName = "event";
      let chart = am4core.create(this.$refs[chartRef], am4charts.XYChart);
      chart.paddingRight = 0;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields["category"] = categoryName;
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.renderer.labels.template.adapter.add("text", function() {
        return "";
      });
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = false;
      valueAxis.renderer.minWidth = 35;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields["categoryX"] = categoryName;
      series.dataFields["valueY"] = "total_event";

      series.tooltipText = "{valueY.value}";

      chart.cursor = new am4charts.XYCursor();
      let bullet = series.bullets.push(new am4charts.LabelBullet);
      bullet.label.text = "{categoryX}";
      bullet.label.rotation = -90;
      bullet.label.truncate = false;
      bullet.label.hideOversized = false;

      chart.maskBullets = false;

      chart.paddingBottom = 0;

      if (consultant === "seller") {
        this.charts.BarCharts.seller = chart;
        this.getDailyActivities("seller");
        }
    },
     beforeDestroy() {
    if (this.charts.BarCharts) {
      this.charts.BarCharts.dispose();
    }
  },
    getDailyActivitiesByConsultant(activities, consultants) {
      let data = [];
      for (let i = 0; i < consultants.length; i++) {
        let a = [];
        let consultant = consultants[i];
        if (consultant.enabled) {
          for (let n = 0; n < activities.length; n++) {
            if (consultant.id === activities[n]["seller"])
              a.push(activities[n]);
          }
          data.push({
            consultant: consultant.name,
            picture: `${this.$v.CLIENT_SERVER}${this.$v.CONSULTANT_IMG_PATH}/${consultant.picture}`,
            id: consultant.id,
            items: a,
            eventCount: a.length
          });
          //console.log(`${this.$v.CLIENT_SERVER}${this.$v.CONSULTANT_IMG_PATH}/${consultant.picture}`);
        }
      }
      return data;
    },
     async getDailyActivities(consultant = "seller", retrying = 0) {
      let url;
      if (consultant === "seller") {
        url = `${this.$v.SERVER}${this.$v.STADISTICS_OPS.GET_V_USER_EVENT_TOTAL}`;
      }
      
      let activities = await this.postServer(url);
        if (activities.length===0) {
        if (retrying === 4) {
          this.$vs.notification(this.$notification(
                'info',
                 'No existen datos en este periodo'
                     ));
          return;
        }
        return this.getDailyActivities(consultant, (retrying += 1));
      }else if (!activities) {
        if (retrying === 4) {
          this.$vs.notification(this.$notification(
                'danger',
                 'Error al obtener actividades'
                     ));
          return;
        }
        return this.getDailyActivities(consultant, (retrying += 1));
      }
      for (let i = 0; i < activities.length; i++)
        activities[i].created = this.$moment
          .utc(activities[i].created)
          .local()
          .format();
      if (consultant === "seller")
        this.chartsData.sellsDailyActivities = activities;
      else if (consultant === "service")
        this.chartsData.serviceDailyActivities = activities;
      if (consultant === "seller") {
        let activitiesBySeller = this.getDailyActivitiesByConsultant(
          activities,
          this.sellers
        );
        if (this.charts.consultantBarCharts.seller)
          this.charts.consultantBarCharts.seller.data = activitiesBySeller;

        let activitiesByDate = this.getDailyActivitiesByTimeRange(activities);
        if (this.charts.lineCharts.seller)
          this.charts.lineCharts.seller.data = activitiesByDate.data;

        let events = this.getDailyActivitiesByEvent(activities, "seller");
        if (this.charts.radialCharts.seller)
          this.charts.radialCharts.seller.data = events.data;
        this.charts.BarCharts.seller.data = events.data;
      } else if (consultant === "service") {
        let activitiesByService = this.getDailyActivitiesByConsultant(activities,this.service);
        if (this.charts.consultantBarCharts.service)
          this.charts.consultantBarCharts.service.data = activitiesByService;

        let activitiesByDateService = this.getDailyActivitiesByTimeRange(
          activities
        );
        if (this.charts.lineCharts.service)
          this.charts.lineCharts.service.data = activitiesByDateService.data;

        let events = this.getDailyActivitiesByEvent(activities, "service");
        if (this.charts.radialCharts.service) {
          this.charts.radialCharts.service.data = events.data;
          this.charts.BarCharts.service.data = events.data;
        }
      }
      this.$forceUpdate();
    }
  },
  mounted(){
      this.createBarDailyActivitiesChartByConsultant("seller");
      this.getDailyActivities("seller");
  }
};
</script>
<style></style>
