<template>
  <div
    class="card shadow"
    :class="type === 'dark' ? 'bg-default' : ''"
    v-bind:style="{ cursor: pointer2 }"
  >
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button v-if="!showData && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="showAudit"></base-button>
          </div>
      </div>
    </div>

    <div class="table-responsive" v-bind:style="{ cursor: pointer }">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
      >
        <template slot="columns">
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">Ine Frente</th>
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">Ine Trasero</th>
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">RFC</th>
          <!--<th class="text-center" v-bind:style="{ cursor: pointer2 }">Comprobante Domicilio</th>!-->
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">CURP</th>
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">Buro Crédito</th>
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">Calculadora</th>
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">Contrato DBM</th>
         <!-- <th class="text-center" v-bind:style="{ cursor: pointer2 }">Contrato FA</th>
          <th class="text-center" v-bind:style="{ cursor: pointer2 }">Manifesto FA</th>!-->
          <th class="text-center" v-bind:style="{cursor:pointer2}">Inicio Auditoría</th>
          <th class="text-center" v-bind:style="{cursor:pointer2}">Última Actualización</th>
          <th class="text-center" v-bind:style="{cursor:pointer2}">Fin Auditoría</th>
        </template>

        <template v-if="showData" slot-scope="{ row }">
          <td class="text-center">{{row.if_id ? getTypeAuditor('IF',row.if_id) : 'SIN AUDITAR'}}</td>
          <td class="text-center">{{row.ir ? getTypeAuditor('IR',row.ir) : 'SIN AUDITAR'}}</td>
          <td class="text-center">{{row.rfc ? getTypeAuditor('RFC',row.rfc) : 'SIN AUDITAR'}}</td>
          <!--<td class="text-center">{{row.cd ? getTypeAuditor('CD',row.cd) : 'SIN AUDITAR'}}</td>!-->
          <td class="text-center">{{row.curp ? getTypeAuditor('CURP',row.curp) : 'SIN AUDITAR'}}</td>
          <td class="text-center">{{row.rc ? getTypeAuditor('RC',row.rc) : 'SIN AUDITAR'}}</td>
          <td class="text-center">{{row.prog ? getTypeAuditor('PROG',row.prog) : 'SIN AUDITAR'}}</td>
          <td class="text-center">{{row.c1 ? getTypeAuditor('C1',row.c1) : 'SIN AUDITAR'}}</td>
          <!--<td class="text-center">{{row.c2 ? getTypeAuditor('C2',row.c2) : 'SIN AUDITAR'}}</td>
          <td class="text-center">{{row.ca ? getTypeAuditor('CA',row.ca) : 'SIN AUDITAR'}}</td>!-->
          <td class="text-center">{{ $formatDate(row.created) }}</td>
          <td class="text-center">{{ $formatDate(row.upload_date) }}</td>
          <td class="text-center">{{ $formatDate(row.finish_audit_date) }}</td>
        </template>
      </base-table>
    </div>

    <div
      v-if="pagination.total > pagination.perPage"
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <base-pagination
        size="sm"
        :total="pagination.total"
        :per-page="pagination.perPage"
        :value="pagination.currentPage"
        v-on:pageSelected="pageSelected"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "users-auditor-table",
  props: {
    type: {
      type: String,
    },
    title: String,
    showEye: Boolean,
    data: Array,
    pagination: Object,
  },
  data() {
    return {
      isManagerService: false,
      isManagerSeller: false,
      isAdmin: false,
      isAuditor: false,
      isSeller: false,
      isService: false,
      tableData: [],
      consultantFilter: null,
      methodPaymentFilter: null,
      validationPaymentFilter: null,
      showSetPeriod: false,
      periodFilter: null,
      payRollFilter: null,
      statusAuditFilter: null,
      highRiskFilter: null,
      pointer: "pointer",
      pointer2: "default",

      rowEdit: {},
      rowUpdate: {},
      auditModal: false,
      modalType: null,
      buttonText: "",
      fileurl: "",
      catalog: null,

      //Credit Report
      report: {},
      messageReport: "",
      reportByBC: false,
      CRPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      lengthsCreditReports: {
        dbmAccounts: null,
        otherAccounts: null,
      },
      selectedIF: [],
      selectedIR: [],
      selectedRFC: [],
      selectedCD: [],
      selectedCURP: [],
      selectedRC: [],
      selectedPROG: [],
      selectedC1: [],
      selectedC2: [],
      selectedCA: [],
      binaryArray: [
        1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384,
      ],
      showData:false,
    };
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    sellers() {
      return this.$store.state.sellers;
    },
    auditorCatalog() {
      return this.$store.state.auditorCatalog;
    },
  },
  async created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
  },
  methods: {

                        showAudit(){
                this.showData = true;
                this.$emit("showAudit")
            },
    decimal2Binary(decimal, marks) {
      let binary = 1;
      for (let i = 0; i < marks.length; i++) {
        if (decimal & binary) marks[i] = binary;
        binary *= 2;
      }
      return marks;
    },
    pageSelected(page) {
      this.$emit("pageSelected", page);
    },
    formatDate(date) {
      if (date <= "2020-11-10") {
        return this.$moment(date)
          .subtract(6, "hours")
          .format("DD MMM YYYY, h:mm a");
      } else {
        return this.$moment(date).format("DD MMM YYYY, h:mm a");
      }
    },
    getTypeAuditor(type, id) {
    let marksIF= [0, 0, 0, 0, 0, 0, 0, 0, 0]
    let marksIR = [0, 0, 0, 0]
    let marksRFC =[0, 0, 0, 0]
    let marksCD = [0,0,0,0,0,0,0,0,0,0,0]
    let marksRC = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    let marksCURP = [0, 0, 0, 0]
    let marksPROG = [0, 0, 0]
    let marksC1 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    let marksC2 = [0,0,0,0,0,0,0,0,0,0,0]
    let marksCA = [0,0,0,0,0,0,0,0,0,0]
      let response = " ";
      if(id == "-1") {return 'Auditar nuevamente'}
      if(id == "1") {return 'Ok'}
      switch (type) {
        case "IF":
          marksIF = this.decimal2Binary(Number(id), marksIF);
          for (let i = 0; i < marksIF.length; i++) {
            for (let j = 0; j < this.auditorCatalog.IF.length; j++) {
              if (this.auditorCatalog.IF[j].value_int == marksIF[i]) {
                response += this.auditorCatalog.IF[j].value + ", ";
              }
            }
          }
          return response;
        case "IR":
          marksIR = this.decimal2Binary(Number(id), marksIR);
          for (let i = 0; i < marksIR.length; i++) {
            for (let j = 0; j < this.auditorCatalog.IR.length; j++) {
              if (this.auditorCatalog.IR[j].value_int == marksIR[i]) {
                response += this.auditorCatalog.IR[j].value + ", ";
              }
            }
          }
          return response;
        case "RFC":
          marksRFC = this.decimal2Binary(Number(id), marksRFC);
          console.log(marksRFC);
          for (let i = 0; i < marksRFC.length; i++) {
            for (let j = 0; j < this.auditorCatalog.RFC.length; j++) {
              if (this.auditorCatalog.RFC[j].value_int == marksRFC[i]) {
                response += this.auditorCatalog.RFC[j].value + ", ";
              }
            }
          }
          return response;
        case "CD":
          marksCD = this.decimal2Binary(Number(id), marksCD);
          for (let i = 0; i < marksCD.length; i++) {
            for (let j = 0; j < this.auditorCatalog.CD.length; j++) {
              if (this.auditorCatalog.CD[j].value_int == marksCD[i]) {
                response += this.auditorCatalog.CD[j].value + ", ";
              }
            }
          }
          return response;
        case "RC":
          marksRC = this.decimal2Binary(Number(id), marksRC);
          for (let i = 0; i < marksRC.length; i++) {
            for (let j = 0; j < this.auditorCatalog.RC.length; j++) {
              if (this.auditorCatalog.RC[j].value_int == marksRC[i]) {
                response += this.auditorCatalog.RC[j].value + ", ";
              }
            }
          }
          return response;
        case "CURP":
          marksCURP = this.decimal2Binary(Number(id), marksCURP);
          for (let i = 0; i < marksCURP.length; i++) {
            for (let j = 0; j < this.auditorCatalog.CURP.length; j++) {
              if (this.auditorCatalog.CURP[j].value_int == marksCURP[i]) {
                response += this.auditorCatalog.CURP[j].value + ", ";
              }
            }
          }
          return response;
        case "PROG":
          marksPROG = this.decimal2Binary(Number(id), marksPROG);
          for (let i = 0; i < marksPROG.length; i++) {
            for (let j = 0; j < this.auditorCatalog.PROG.length; j++) {
              if (this.auditorCatalog.PROG[j].value_int == marksPROG[i]) {
                response += this.auditorCatalog.PROG[j].value + ", ";
              }
            }
          }
          return response;
        case "C1":
          marksC1 = this.decimal2Binary(Number(id), marksC1);
          for (let i = 0; i < marksC1.length; i++) {
            for (let j = 0; j < this.auditorCatalog.C1.length; j++) {
              if (this.auditorCatalog.C1[j].value_int == marksC1[i]) {
                response += this.auditorCatalog.C1[j].value + ", ";
              }
            }
          }
          return response;
        case "C2":
          marksC2 = this.decimal2Binary(Number(id), marksC2);
          for (let i = 0; i < marksC2.length; i++) {
            for (let j = 0; j < this.auditorCatalog.C2.length; j++) {
              if (this.auditorCatalog.C2[j].value_int == marksC2[i]) {
                response += this.auditorCatalog.C2[j].value + ", ";
              }
            }
          }
          return response;
        case "CA":
          marksCA = this.decimal2Binary(Number(id), marksCA);
          for (let i = 0; i < marksCA.length; i++) {
            for (let j = 0; j < this.auditorCatalog.CA.length; j++) {
              if (this.auditorCatalog.CA[j].value_int == marksCA[i]) {
                response += this.auditorCatalog.CA[j].value + ", ";
              }
            }
          }
          return response;
      }
    },
    getTypeFileAuditor(type, id) {
      switch (type) {
        case "IF":
          for (let i = 0; i < this.auditorCatalog.IF.length; i++)
            if (this.auditorCatalog.IF[i].id === id)
              return "Identificación de Frente";
          break;
        case "IR":
          for (let i = 0; i < this.auditorCatalog.IR.length; i++)
            if (this.auditorCatalog.IR[i].id === id)
              return "Identificación de Trasero";
          break;
        case "RFC":
          for (let i = 0; i < this.auditorCatalog.RFC.length; i++)
            if (this.auditorCatalog.RFC[i].id === id) return "RFC";
          break;
        case "CD":
          for (let i = 0; i < this.auditorCatalog.CD.length; i++)
            if (this.auditorCatalog.CD[i].id === id)
              return "Comprobante de Domicilio";
          break;
        case "RC":
          for (let i = 0; i < this.auditorCatalog.RC.length; i++)
            if (this.auditorCatalog.RC[i].id === id)
              return "Reporte de Crédito";
          break;
        case "CURP":
          for (let i = 0; i < this.auditorCatalog.CURP.length; i++)
            if (this.auditorCatalog.CURP[i].id === id) return "CURP";
          break;
        case "PROG":
          for (let i = 0; i < this.auditorCatalog.PROG.length; i++)
            if (this.auditorCatalog.PROG[i].id === id)
              return "Calculadora del Programa";
          break;
        case "C1":
          for (let i = 0; i < this.auditorCatalog.C1.length; i++)
            if (this.auditorCatalog.C1[i].id === id) return "Contrato DBM";
          break;
        case "C2":
          for (let i = 0; i < this.auditorCatalog.C2.length; i++)
            if (this.auditorCatalog.C2[i].id === id)
              return "Contrato de apertura de cuenta";
          break;
        case "CA":
          for (let i = 0; i < this.auditorCatalog.CA.length; i++)
            if (this.auditorCatalog.CA[i].id === id)
              return "Carátulo de apertura de cuenta";
          break;
        case "HIGH":
          for (let i = 0; i < this.auditorCatalog.HIGH.length; i++)
            if (this.auditorCatalog.HIGH[i].id === id)
              return "Contrato de alto riesgo";
          break;
      }
    },
  },
};
</script>
<style>
</style>