<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button v-if="!showData && !addendumAccFormat && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="showAccounts"></base-button>
                    <base-button v-if="(showData || addendumAccFormat) && addendumType===1" type="primary" size="sm" v-on:click="onNewItem">Nuevo</base-button>
                    <base-button v-if="showData && !addendumAccFormat" type="primary" size="sm" v-on:click="onNewItem">Nuevo</base-button>
                    <base-button v-if="showData && !addendumAccFormat && !disabledSendSignatureNomina" type="info" size="sm" v-on:click="requestSignatureNomina">Solicitar carta de nómina del mismo banco</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor: pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        v-on:click="accountSelected">
                <template slot="columns">
                    <th v-if="addendumType===2" class="text-center" v-bind:style="{cursor: pointer2}">Eliminar</th>
                    <th v-if="addendumType===4 || addendumType===5" class="text-center">Editar</th>
                    <th v-if="addendumType>1 && !isSeller && addendumAccFormat" class="text-center" v-bind:style="{cursor: pointer2}">ID Cuenta</th>
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Banco</th>
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Número de cuenta</th>
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Tipo de producto</th>
                    <th class="text-center" v-bind:style="{cursor: pointer2}">Total adeudado</th>
                    <th v-if="!isSeller && !addendumAccFormat" class="text-center" v-bind:style="{cursor: pointer2}">Descuento actual</th>
                    <th v-if="!isSeller && !addendumAccFormat" class="text-center" v-bind:style="{cursor: pointer2}">Descuento esperado</th>
                    <th v-if="!isSeller && !addendumAccFormat" class="text-center" v-bind:style="{cursor: pointer2}">Fecha estimada</th>
                    <th v-if="isAdmin || isService || isManagerService || isAuditor" class="text-center" v-bind:style="{cursor: pointer2}">Ultimo pago bancario</th>
                    <th v-if="(isAdmin || isManagerService || isManagerSeller) && !addendumAccFormat" class="text-center" v-bind:style="{cursor:pointer2}">Eliminar</th>
                    <!--th v-if="false" class="text-center">Acciones</th-->
                </template>

                <template v-if="showData  || addendumAccFormat" slot-scope="{row}">
                    <td v-if="addendumType===2" class="text-center text-danger"><i class="fas fa-trash" @click.stop="deleteAccount(row)"></i></td>
                    <td v-if="addendumType===4 || addendumType===5" class="text-center text-info"><i class="fas fa-edit"></i></td>
                    <td v-if="addendumType>1 && !isSeller && addendumAccFormat" class="text-center">{{row.id ?row.id:'Nueva'}}</td>
                    <th v-if="!addendumAccFormat" scope="row">
                        <div class="media align-items-center">
                            <i style="margin-right:15px" :class="getIconSettled(row.paid)"></i>
                            <a class="avatar avatar-sm rounded-circle" style="background-color: #FFFFFF" data-toggle="tooltip" :data-original-title="row.bank.name">
                                <img :alt="row.bank.name" :src="row.bank.url">
                            </a>
                        </div>
                    </th>
                    <td v-else class="text-center">{{row.bank.name}}</td>
                    <td class="text-center">{{row.account}}</td>
                    <td class="text-center">{{row.product_type ? getProductType(row.product_type.id):'Cuenta'}}</td>
                    <td class="text-center">{{$formatCurrency(row.amount)}}</td>
                    <td class="text-center" v-if="!isSeller && !addendumAccFormat">{{$formatCurrency(row.discount)}}</td>
                    <td v-if="!isSeller && !addendumAccFormat" class="text-center">{{$formatCurrency(row.will_pay)}}</td>
                    <td v-if="!isSeller && !addendumAccFormat" class="text-center">{{getDateText(row.will_pay_on)}}</td>
                    <td v-if="isAdmin || isService || isManagerService || isAuditor" class="text-center">{{formatDate(row.last_bank_payment_date)}}</td>
                    <td v-if="(isAdmin || isManagerService || isManagerSeller) && !addendumAccFormat" class="text-center text-danger"><i class="fas fa-trash" @click.stop="deleteFile(row.id)"></i></td>
                </template>

            </base-table>

        </div>

        <div v-if="(pagination.total > pagination.perPage) && showData" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'clients-table',
        props: {
          type: {
            type: String
          },
          disabledSendSignatureNomina: Boolean,
          addendumType: Number,
          addendumAccFormat: Boolean,
          showEye: Boolean,
          title: String,
          data: Array,
          pagination: Object
        },
        data() {
            return {
                isSeller: false,
                isManagerSeller:false,
                isAuditor:false,
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                showData:false,
            }
        },
        computed: {
            banks() { return this.$store.state.banks },
            productType() { return this.$store.state.productType },
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) }
        },
        async created() {
            this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isService = this.user.role === this.$v.ROLES.SERVICE
            this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
        },
        methods: {
            showAccounts(){
                this.showData = true;
                this.$emit("showAccounts")
            },
             getProductType(id) {
                for (let i=0 ; i<this.productType.length ; i++) {
                    if (this.productType[i].id === id) {
                        return this.productType[i].type
                    }
                }
            },
            showDetails(acc) {
                this.$emit("showDetails", acc);
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            getBanks(array) {
                let banks = []
                for (let a=0 ; a<=array.length ; a++) {
                    for (let b = 0; b < this.banks.length; b++) {
                        if (this.banks[b].id === array[a]) {
                            banks.push(this.banks[b])
                            break
                        }
                    }
                }
                return banks
            },
            formatDate(date) {
                return this.$moment(date).format("DD MMM YYYY");
            },
            onNewItem() {
                this.$emit("onNewItem")
            },
            requestSignatureNomina(){
                this.$emit("requestSignatureNomina")
            },
            onSave() {
                this.$emit("onSave")
            },
            deleteFile(accId){
                this.$emit("newModification", accId)
            },
            getDateText(acc) {
                return acc ? this.$formatDate(acc) : 'No asignado'
            },
            accountSelected(acc) {
               this.$emit("accountSelected", acc)
            },
            deleteAccount(acc) {
               this.$emit("deleteAccount", acc)
            },
            getIconSettled(settled) {
                if (settled) return "fas fa-medal text-success"
                return "fas fa-piggy-bank text-info"
            }
        }
    }
</script>
<style>
</style>
