<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <!--
        <div v-if="(isAdmin || isManagerService) && !unassigned" class="col-lg-2 text-right">
          <base-dropdown style="width: 100%">
            <base-button
              slot="title"
              type="warning"
              outline
              class="dropdown-toggle"
              style="width: 100%"
              size="sm"
            >{{getCustomerFilterText()}}</base-button>
            <a class="dropdown-item" v-on:click="setConsultantFilter(null)">Mostrar todos</a>
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              v-for="(seller) in sellers"
              :key="seller.id"
              v-on:click="setConsultantFilter(seller.id)"
            >{{seller.name}} {{seller.lastname_1}}</a>
            <div class="dropdown-divider"></div>
            <a
              v-for="(customerS) in customerService"
              :key="customerS.id"
              class="dropdown-item"
              v-on:click="setConsultantFilter(customerS.id)"
            >{{customerS.name}} {{customerS.lastname_1}}</a>
          </base-dropdown>
        </div>
       
        <div v-else-if="isSeller && !unassigned" class="col-lg-2 text-right">
          <base-dropdown style="width: 100%">
            <base-button
              slot="title"
              type="warning"
              outline
              class="dropdown-toggle"
              size="sm"
              style="width: 100%"
            >{{getConsultantFilterText()}}</base-button>
            <a class="dropdown-item" v-on:click="setSelfClientsFilter(true)">Mis clientes</a>
            <a class="dropdown-item" v-on:click="setSelfClientsFilter(false)">Clientes sin asingar</a>
          </base-dropdown>
        </div>
         !-->
        <div class="col-lg-2 text-right">
          <base-dropdown style="width: 100%">
            <base-button
              slot="title"
              type="default"
              outline
              class="dropdown-toggle"
              size="sm"
              style="width: 100%"
              >{{ getPeriodFilterText() }}</base-button
            >
            <a class="dropdown-item" v-on:click="setPeriodFilter(null)"
              >Mostrar todos</a
            >
            <a class="dropdown-item" v-on:click="setPeriodFilter(1)"
              >Mes actual</a
            >
            <div v-if="!showSetPeriod" class="dropdown-divider"></div>
            <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod"
              >Establecer periodo</a
            >
          </base-dropdown>
        </div>
        <div v-if="showSetPeriod" class="col">
          <div class="row justify-content-center">
            <flat-pickr
              v-model="period.start"
              placeholder="aaaa-mm"
              class="btn btn-sm"
              data-vv-as="Fecha"
              name="startPeriod"
              style="background-color: #ffffff"
            />
            <span class="small text-muted">-</span>
            <flat-pickr
              v-model="period.end"
              placeholder="aaaa-mm"
              class="btn btn-sm"
              data-vv-as="Fecha"
              name="endPeriod"
              style="background-color: #ffffff"
            />
            <base-button
              type="none text-green"
              size="sm"
              @click="setPeriodFilter(2)"
              >Aplicar</base-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive" v-bind:style="{ cursor: pointer }">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
        :period="period"
        v-on:click="projectSelected"
      >
        <template slot="columns">
          <th v-bind:style="{ cursor: pointer2 }">ID</th>
          <th v-bind:style="{ cursor: pointer2 }">Nombre</th>
          <th v-bind:style="{ cursor: pointer2 }">Descripción</th>
          <th v-bind:style="{ cursor: pointer2 }">Módulos</th>
          <th v-bind:style="{ cursor: pointer2 }">En progreso</th>
          <th v-bind:style="{ cursor: pointer2 }">Completos</th>
          <th v-bind:style="{ cursor: pointer2 }">Creación</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
          <div class="media align-items-center">
            <div class="media-body">
              <span class="name mb-0 text-primary text-sm"
                >{{ row.id }} <i class="fas fa-list"></i></span
              >
            </div>
          </div>
           </th>
          <td>{{ row.name }}</td>
          <td>{{ row.description }}</td>
          <td>{{ row.moduleCount }}</td>
          <td>{{ row.inProgress }}</td>
          <td>{{ row.completed }}</td>
          <td>{{ $moment(row.created).format("DD MMM YYYY") }}</td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="col">
        <p class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          Página {{ pagination.currentPage }} de
          {{ Math.ceil(pagination.total / pagination.perPage) }}
          <br />
          {{ pagination.total }} Proyectos
        </p>
      </div>
      <base-pagination
        size="sm"
        :total="pagination.total"
        :per-page="pagination.perPage"
        :value="pagination.currentPage"
        v-on:pageSelected="pageSelected"
      />
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "dev-projects-table",
  components: {
    flatPickr,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    data: Array,
    pagination: Object,
    unassigned: Boolean,
    period: {
      start: null,
      end: null,
    },
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    sellers() {
      return this.$store.state.sellers;
    },
    customerService() {
      return this.$store.state.customerService;
    },
  },
  data() {
    return {
      isAdmin: false,
      tableData: [],
      filter: null,
      consultantFilter: null,
      showSetPeriod: false,
      periodFilter: null,
      selfClients: true,
      typeUsers: "",
      widthProgressBar: null,
      titleProgressBar: "",
      pointer: "pointer",
      pointer2: "default",
    };
  },
  async created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
  },
  methods: {
    formatCurrency(value) {
      return this.$formatCurrency(value);
    },
    typeClients(typeC) {
      this.typeUsers = typeC;
      this.$emit("typeClients", typeC);
    },
    pageSelected(page) {
      this.$emit("pageSelected", page);
    },
    projectSelected(project) {
      this.$emit("projectSelected", project);
    },
    newClient() {
      this.$emit("newClient");
    },
    setFilter(value) {
      this.filter = value;
      this.$emit("setFilter", value);
    },
    setConsultantFilter(value) {
      this.consultantFilter = value;
      this.$emit("setConsultantFilter", value);
    },
    setSelfClientsFilter(value) {
      this.selfClients = value;
      this.$emit("setSelfClientFilter", value);
    },
    getCustomerFilterText() {
      if (this.consultantFilter)
        return this.getConsultantName(this.consultantFilter);
      return "Consultores";
    },
    getConsultantFilterText() {
      if (this.selfClients) return "Solo mis clientes";
      return "Clientes sin asignar";
    },
    getIconValidatedClass(validated) {
      if (validated) return "fas fa-check text-success";
      return "fas fa-clock text-info";
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id) return this.sellers[i].name;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return this.customerService[i].name;
      return "- - -";
    },

    setPeriod() {
      this.showSetPeriod = true;
      this.period.start = null;
      this.period.end = null;
      this.getPeriodFilterText();
    },
    setPeriodFilter(value) {
      if (value != 2) {
        this.showSetPeriod = false;
        this.periodFilter = value;
        this.$emit("setPeriodFilter", value);
      } else if (this.period.start && this.period.end) {
        this.periodFilter = value;
        this.$emit("setPeriodFilter", value);
      }
    },
    getPeriodFilterText() {
      if (this.showSetPeriod) return "Periodo del: ";
      else if (this.periodFilter === 1) return "Mes actual";
      else return "Fecha";
    },
  },
};
</script>
<style>
</style>