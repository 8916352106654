<template>
    <div style="width:auto;" class="card-header text-center align-items-center" :class="type === 'dark' ? 'bg-transparent': ''">
        <div class="row" style="margin-left:-2rem">
            <div class="col-lg-12 text-center table-responsive"> 
                <table class="round_table table table-sm align-items-center">
                    <tr>
                        <th class="round_th bg-primary text-white" style="font-size:0.8rem;">Estado de Tramite</th>
                        <th class="round_th bg-primary text-white" style="font-size:0.8rem;"># Deudas</th>
                    </tr>
                    <tr>
                        <td class="table-active text-center" style="font-size:0.8rem;">Carta Enviada</td>
                        <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumLE}}</td>
                    </tr>
                    <tr>
                        <td class="table-active text-center" style="font-size:0.8rem;">Carta Recibida</td>
                        <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumLR}}</td>
                    </tr>
                    <tr>
                        <td class="table-active text-center" style="font-size:0.8rem;">Convenio Recibido</td>
                        <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumCov}}</td>
                    </tr>
                    <tr>
                        <td class="table-active text-center" style="font-size:0.8rem;">Espera de Liquidacion</td>
                        <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumEspLiq}}</td>
                    </tr>
                    <tr>
                        <td class="table-active text-center" style="font-size:0.8rem;">En TL</td>
                        <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumTL}}</td>
                    </tr>
                    <tr>
                        <td class="table-active text-center" style="font-size:0.8rem;">Liquidada</td>
                        <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumLiq}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-lg-12 text-center table-responsive">
                <table class="round_table table table-sm align-items-center">
                    <tr>
                        <th class="round_th bg-primary text-white" style="font-size:0.8rem;">En TL</th>
                        <th class="round_th bg-primary text-white" style="font-size:0.8rem;">Liquidadas</th>
                    </tr>
                    <tr>
                        <td class="table-active" style="font-size:0.8rem;">{{
                          formatDecPost(tableData.percentageTL)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }} %</td>
                        <td class="table-active" style="font-size:0.8rem;">
                        {{ formatDecPost(tableData.percentageLiquidacion)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }} %</td>
                    </tr>
                </table>
                <table class="round_table table table-sm align-items-center">
                    <tr>
                        <th class="round_th bg-primary text-white" style="font-size:0.8rem;">MDI</th>
                        <td class="table-active" style="font-size:0.8rem;">{{$formatCurrency(tableData.sumMDI)}}</td>
                    </tr>
                    <tr>
                        <th class="round_th bg-primary text-white" style="font-size:0.8rem;">Pago Banco</th>
                        <td class="table-active" style="font-size:0.8rem;">{{$formatCurrency(tableData.sumPB)}} </td>
                    </tr>
                    <tr>
                        <th class="round_th bg-primary text-white" style="font-size:0.8rem;">Descuento</th>
                        <td class="table-active" style="font-size:0.8rem;">
                        {{ formatDecPost(tableData.sumDisMdiB)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }} %</td>
                    </tr>
                </table>
                <table class="round_table table table-sm align-items-center">
                    <tr>
                        <th class="round_th bg-primary text-white" style="font-size:0.8rem;">% Efectividad</th>
                        <td class="table-active" style="font-size:0.8rem;">{{ formatDecPost(tableData.percentageEffective)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }} %</td>
                    </tr>
                </table>
            </div>
            <div class="col-lg-12 text-center table-responsive">
                <table class="round_table table table-sm align-items-center">
                    <tr>
                        <th class="round_th bg-primary table-active text-white text-center">Banco</th>
                        <td class="table-active text-center">
                            <base-dropdown>
                                <base-button slot="title" type="btn btn-outline-primary" class="dropdown-toggle" size="sm">
                                    {{selectedBank.name? selectedBank.name : "Selecciona el banco"}}
                                </base-button>
                                <a class="dropdown-item" v-for="(bank, index) in banks" :key="index" v-on:click="selectedBank = bank">
                                    {{bank.name}}
                                </a>
                            </base-dropdown>
                        </td>
                    </tr>
                    <tr>
                        <th class="round_th bg-primary table-active text-white text-center">Mora</th>
                        <td class="table-active text-center">
                            <div class="row align-items-center">
                                <base-input type="number" style="width: 70%; height: 15px; padding-left: 15px;" v-model="selectedDueMonths"/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="round_th bg-primary text-white">Descuento</th>
                        <td class="table-active">{{discount? discount+'%':'-'}}</td>
                    </tr>
                </table>
                <table class="round_table table table-sm align-items-center">
                    <tr>
                        <th class="bg-primary text-white round_th" style="font-size:0.8rem;">Deudas Negociadas Mes en Curso</th>
                    </tr>
                    <tr>
                        <th class="table-active " style="font-size:0.8rem;">{{tableData.negotiatedDebtsCurrentMonth? tableData.negotiatedDebtsCurrentMonth:0}}</th>
                    </tr>
                </table>
            </div>
            <!--
            <div class="col-lg-12 text-center" style="margin-top:-6.5rem; padding:6rem;">
                <h4 class="display-3 text-blue" style="font-size:1rem;">MI MES ACTUAL</h4>
                <div class="d-flex justify-content-between" style=" width: 100%; height: 2rem; margin-top:-0.4rem; background: #F7D919; padding: 5px; color: black; text-align: left; border-radius:1rem;"
                :style="{ width:totalPercentage + '%'}">
                <img src="img/meta.png" width="50px" height="50px" style="margin-top:-1.2rem; margin-left: 101%;"/></div>
                <div class="d-flex justify-content-between" style=" width: 100%; height: 2rem; padding: 5px; margin-top:-2rem; background: #F7D917; color: black; text-align: left; border-radius:1rem;"
                :style="{ width:totalBar + '%'}">
                <img :src="pictureConsultant" class="rounded-circle no-avatar" width="50px" height="50px" style="margin-top:-1rem; margin-left: auto; margin-right:-0.5rem;"/></div>
                <div class="d-flex justify-content-between" v-b-popover.hover.top="" :title="tableData.percentageLiquidacion +'%'"
                style="width: 100%; height: 2rem; padding: 5px; background: #2178F7; color: black; text-align: left; margin-top:-2rem; border-radius:1rem;"
                :style="{width:totalBar + '%'}" >
                <img :src="pictureConsultant" class="rounded-circle no-avatar" width="50px" height="50px" style="margin-top:-1rem; margin-left: auto; margin-right:-0.5rem;"/></div>
            </div>
            !-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'clients-table-info-service',
    props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            unassigned: Boolean,
            /*period: {
                start: null,
                end: null
            },*/
        },
    computed: {
        discount() {
            if(!this.bankDiscounts || !this.selectedBank || !this.selectedDueMonths) return 0
            let d = this.bankDiscounts.find(obj => obj.bank === this.selectedBank.name && obj.month == this.selectedDueMonths);
            return d?d.discount_percent:'0'
        },
        user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
        sellers() { return this.$store.state.sellers },
        customerService() { return this.$store.state.customerService },
        banks() { return this.$store.state.banks; },
    },
    data() {
        return {
            selectedBank: {},
            selectedDueMonths: null,
            tableData: {},
            bankDiscounts: [],
            pictureConsultant:"",
            totalBar:0,
            totalPercentage:100,
            pointer: 'pointer',
            pointer2: 'default'
        }
    },
    async created(){
        this.getData()
        this.getDiscountBank()
        this.pictureConsultant='https://www.dbmenos.com/img/consultants/' + this.user.picture
    },
    methods: {
        formatDecPost(val, pos) {
      if (!pos) pos = 2;
      if (val && Number(val)) return Number(val).toFixed(pos);
      return "0.00";
    },
    getIconClass(column) {
                let iconClass = "fas "
                if (column === this.sortedColumn) {
                    iconClass += this.sortingDesc ? "fa-arrow-down" : "fa-arrow-up"
                    iconClass += " text-danger"
                } else {
                    iconClass += "fa-arrows-alt-v"
                }
                return iconClass
            },
        getData() {
            let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_DEBTS_INFO_SERVICE + "?consultant=" + this.user.id;
            this.$showLoader(true)
            this.$axios.get(url).then(response => {
                this.$showLoader(false)
                let data = response.data
                if (data[this.$v.API_SUCCESS]) {
                    this.tableData = data[this.$v.API_ROWS]
                    //this.getProgressBarData()
                    /*
                    expectedActivity: 13
                    expectedActivityPercent: 72.22222222222223
                    negotiatedDebtsCurrentMonth: 35
                    sumCov: 4
                    sumDisMdiB: 2.9031395980327437
                    sumEspLiq: 0
                    sumLE: 0
                    sumLR: 0
                    sumLiq: (...)
                    sumMDI: 2138805.94
                    sumPB: 736721.7000000001
                    totalExpectedActivity: 18
                    */
                } else { this.$validateSession(data) }
            })
        },
        getDiscountBank(){
            let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_DISCOUNTS_BANK
            this.$showLoader(true);
            this.$axios.post(url).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data[this.$v.API_SUCCESS]) {
                    this.bankDiscounts = data[this.$v.API_ROW]
                    this.$forceUpdate();
                }
            });
        },
    }
}
</script>
<style>

 #top 
{ position: fixed;  z-index: 999; }


.round_table {
    border-collapse: separate;
    border-spacing: 1;
    border:solid 1px;
    border-color: #d3d3d3;
}

.round_th {
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
}
</style>