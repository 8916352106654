<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <span class="mask bg-gradient-dbm opacity-8"></span>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 center">
          <div class="card card-profile shadow">
            <div class="card-header text-center border-0 pt-1 pt-md-4 pb-0 pb-md-4">
              <div class="d-flex justify-content-between">
                <base-button size="sm" type="danger" class="float-right" icon="fas fa-arrow-left"
                  @click="window.history.back()">Regresar</base-button>
              </div>
              <div class="card-body p-0 pb-1">
              <div class="row">
                <div class="col">
                  <div class="row card-profile-stats justify-content-center d-flex mt-md-0">
                    <div class="col-lg-12">
                      <span class="heading">{{client.name}} {{client.lastname_1}} {{client.lastname_2}}</span>
                      <span class="description">Cliente</span>
                    </div>
                    <div class="col-12" v-if="!isManagerSeller">
                    <base-button
                      type="outline-info"
                      class="p-2"
                      size="sm"
                      v-on:click="sendDocComments()"
                      >Enviar Notas</base-button
                    >
                  </div>
                    <div>
                      <span class="heading">{{ auditTableData.consultant ? auditTableData.consultant : '---' }}</span>
                      <span class="description">Vendedor</span>
                    </div>
                    <div>
                      <span class="heading">{{ auditTableData.team ? auditTableData.team : '---' }}</span>
                      <span class="description">Equipo</span>
                    </div>
                    <div>
                      <span class="heading">{{ $formatCurrency(auditTableData.mdi) }}</span>
                      <span class="description">MDI</span>
                    </div>
                    <div>
                      <span class="heading">{{ $formatCurrency(auditTableData.contribution) }}</span>
                      <span class="description">Mensualidad</span>
                    </div>
                    <div>
                      <span class="heading">{{ auditTableData.payment }}</span>
                      <span class="description">Estatus de Primer Pago</span>
                    </div>
                    <div>
                      <span class="heading">{{ auditTableData.audit_date == null ? 'SIN AUDITAR' : $formatDate(auditTableData.audit_date)  }}</span>
                      <span class="description">Fecha Auditoría</span>
                    </div>
                    <div>
                      <span class="heading">{{ auditTableData.resolve_date == 0 ? 'PENDIENTE' : auditTableData.resolve_date == null ? '---' : $formatDate(auditTableData.resolve_date) }}</span>
                      <span class="description">Fecha Solución</span>
                    </div>
                    <div v-if="auditTableData.payroll == 1">
                    <base-checkbox :checked="Boolean(auditTableData.payroll)" disabled/>
                      <span class="description">Nómina</span>
                    </div>
                    <div v-if="auditTableData.payroll == 1">
                      <span class="heading" v-if="auditTableData.letter == 0">NO TIENE LA CARTA DE NOMINA CARGADA EN EL SITE</span>
                      <a v-else :href="getFileUrl(auditTableData.letter, auditTableData.id)" target="_blank">
                      <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                      <br>
                    </a>
                      <span class="description">Carta Nómina</span>
                    </div>
                    <div v-if="auditTableData.high_risk == 1">
                    <base-checkbox :checked="Boolean(auditTableData.high_risk)" disabled/>
                      <span class="description">Contrato Alto Riesgo</span>
                    </div>
                    <div v-if="auditTableData.high_risk == 1">
                      <base-dropdown class="dropdown">
                      <a slot="title" class="btn btn-sm" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {{auditTableData.documents? auditTableData.documents:'------'}}
                      </a>
                      <template>
                      <a v-for="(option) in catalogHIGH" :key="option.id" class="dropdown-item" v-on:click="applyAuditHigh(auditTableData.id,option.value)">{{option.value}}</a>
                      </template>
                      </base-dropdown>
                      <br>
                    <span class="description">Estatus de Contrato Alto Riesgo</span>
                    </div>
                    <div>
                    <span class="heading">{{ auditTableData.welcom_pending }}</span>
                      <span class="description">Bienvenida Pendiente</span>
                    </div>
                    <div>
                    <span class="heading">{{ $formatDate(auditTableData.firstPaymentDate) }}</span>
                      <span class="description">Fecha Pago</span>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;">  
                <div class="row justify-content-center">
                  <div class="col">
                    <h1 class="display-2 text-info text-center">INE Frente</h1>
                      <div class="row justify-content-center" >
                        <div class="col-12" v-if="!auditTableData.filename_IF" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ninguna Identificación Frente</h2>
                        </div>
                        <div v-else class="col-12">
                        <div v-if="!isPdfIF" class="col-12" style="margin:1rem;">
                          <div style="margin:2rem" ><i class="fas fa-redo-alt text-primary" @click="rotate('IF')"></i>
                          <div :style="'transform: rotate('+rotateIF+'deg); margin:10%;'" @click="rotate('IF')">
                            <img :src="fileIF" style="max-height: 100%; max-width: 100%; margin-bottom:2rem;"/>
                          </div>
                          <br>
                          <a v-if="fileIF" :href="fileIF" target="_blank" >
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        </div>
                        <div v-else class="align-items-center col-6" style="margin:5rem;">
                          <base-p-d-f-visor :fileurl="fileIF" :key="forceUpdate"/>
                        </div>
                        </div>
                        <div class="col text-left" style="margin:5rem;">
                           <b-form-group>
                            <b-form-checkbox-group
                              id="if"
                              :disabled="isManagerSeller"
                              v-model="selectedIF"
                              :options="optinosIF"
                              class="ml-4"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                        <div class="col text-center" style="margin:5rem;">
                          <h2 class="display-4 text-primary">{{ client.name }} {{ client.lastname_1 }} {{ client.lastname_2 }}</h2>
                          <label class="form-control-label text-gray">Nombre Completo</label>
                          <h2  class="display-4 text-primary">{{ client.id_number }}</h2>
                          <label class="form-control-label text-gray">Número de Identifación Oficial</label>
                        </div>
                      </div>
                  </div>
                </div>
              </card>
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;">  
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="display-2 text-info text-center">INE Trasero</h3>
                    <div class="row justify-content-center">
                        <div class="col-12" v-if="!auditTableData.filename_IR" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ninguna Identificación Trasera</h2>
                        </div>
                        <div v-else class="col-12">
                        <div v-if="!isPdfIR" class="col-12" style="margin:1rem;">
                         <div style="margin:2rem"><i class="fas fa-redo-alt text-primary" @click="rotate('IR')"></i></div> 
                          <div :style="'transform: rotate('+rotateIR+'deg); margin:10%;'" @click="rotate('IR')">
                            <img :src="fileIR" style="max-height: 100%; max-width: 100%;"/>
                          </div>
                          <br>
                          <a v-if="fileIR" :href="fileIR" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        </div>
                        <div class="col text-left" style="margin:5rem;">
                           <b-form-group>
                            <b-form-checkbox-group
                              id="ir"
                              :disabled="isManagerSeller"
                              v-model="selectedIR"
                              :options="optinosIR"
                              class="ml-4"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                        <div class="col text-center" style="margin:5rem;">
                          <h2 class="display-4 text-primary">{{ client.name }} {{ client.lastname_1 }} {{ client.lastname_2 }}</h2>
                          <label class="form-control-label text-gray">Nombre Completo</label>
                          <h2  class="display-4 text-primary">{{ client.id_number }}</h2>
                          <label class="form-control-label text-gray">Número de Identifación Oficial</label>
                        </div>
                      </div>
                  </div>
                </div>
              </card>
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;">  
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="display-2 text-info text-center">RFC</h3>
                    <div class="row justify-content-center">
                        <div class="col text-left" style="margin:5rem;">
                           <b-form-group>
                            <b-form-checkbox-group
                              id="rfc"
                              :disabled="isManagerSeller"
                              v-model="selectedRFC"
                              :options="optinosRFC"
                              class="ml-4"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                        <div class="col text-center" style="margin:5rem;">
                          <h2 class="display-4 text-primary">{{ client.name }} {{ client.lastname_1 }} {{ client.lastname_2 }}</h2>
                          <label class="form-control-label text-gray">Nombre Completo</label>
                          <h2 class="display-4 text-primary">{{ $formatNormalDate(client.birthday) }}</h2>
                          <label class="form-control-label text-gray">Fecha de Nacimiento</label>
                          <h2  class="display-4 text-primary">{{ client.rfc }}</h2>
                          <label class="form-control-label text-gray">RFC</label>
                        </div>
                      </div>
                  </div>
                </div>
              </card>
              <!--
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;">  
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="display-2 text-info text-center">Comprobante Domicilio</h3>
                    <div class="row justify-content-center">
                        <div class="col-12" v-if="!auditTableData.filename_cd" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ningun Comprobante de Domicilio</h2>
                        </div>
                        <div v-else class="col-12">
                        <div v-if="!isPdfCD" class="col-12" style="margin:1rem;">
                          <div style="margin:2rem"><i class="fas fa-redo-alt text-primary" @click="rotate('CD')"></i></div> 
                          <div :style="'transform: rotate('+rotateCD+'deg); margin:10%;'" @click="rotate('CD')">
                            <img :src="fileCD" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          </div>
                          <br>
                          <a v-if="fileCD" :href="fileCD" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        <div v-else class="align-items-center col-12" >
                          <base-p-d-f-visor :fileurl="fileCD" :key="forceUpdate"/>
                        </div>
                        </div>
                        <div class="col text-left" style="margin:5rem;">
                           <b-form-group>
                            <b-form-checkbox-group
                              id="cd"
                              :disabled="isManagerSeller"
                              v-model="selectedCD"
                              :options="optinosCD"
                              class="ml-4"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                        <div class="col text-center" style="margin:5rem;">
                          <h2 class="display-4 text-primary">{{ client.name }} {{ client.lastname_1 }} {{ client.lastname_2 }}</h2>
                          <label class="form-control-label text-gray">Nombre Completo</label>
                          <h2  class="display-4 text-primary">{{client.addr_street}} {{client.addr_extnum}} {{client.addr_intnum}}</h2>
                          <label class="form-control-label text-gray">Domicilio</label>
                          <h2  class="display-4 text-primary">{{client.addr_sublocality}}</h2>
                          <label class="form-control-label text-gray">Colonia</label>
                          <h2  class="display-4 text-primary">{{client.addr_postal_code}}</h2>
                          <label class="form-control-label text-gray">C.P.</label>
                          <h2  class="display-4 text-primary">{{client.locality}}</h2>
                          <label class="form-control-label text-gray">Ciudad</label>
                        </div>
                      </div>
                  </div>
                </div>
              </card>
              !-->
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;">  
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="display-2 text-info text-center">CURP</h3>
                    <div class="row justify-content-center">
                        <div class="col-12" v-if="!auditTableData.filename_curp" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ningun CURP</h2>
                        </div>
                        <div v-else class="col-12">
                        <div v-if="!isPdfCURP" class="col-12" style="margin:1rem;">
                          <i class="fas fa-redo-alt text-primary" @click="rotate('CURP')"></i>
                          <div :style="'transform: rotate('+rotateCURP+'deg); margin:10%;'">
                            <img :src="fileCURP" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          </div>
                          <br>
                          <a v-if="fileCURP" :href="fileCURP" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        <div v-else class="align-items-center col-12">
                          <base-p-d-f-visor :fileurl="fileCURP" :key="forceUpdate"/>
                        </div>
                        </div>
                        <div class="col text-left" style="margin:5rem;">
                           <b-form-group>
                            <b-form-checkbox-group
                              id="curp"
                              :disabled="isManagerSeller"
                              v-model="selectedCURP"
                              :options="optinosCURP"
                              class="ml-4"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                        <div class="col" style="margin:4rem">
                           <h2 class="display-4 text-primary">{{ client.name }} {{ client.lastname_1 }} {{ client.lastname_2 }}</h2>
                          <label class="form-control-label text-gray">Nombre Completo</label>
                          <h2  class="display-4 text-primary">{{ client.curp }}</h2>
                          <label class="form-control-label text-gray">CURP</label>
                        </div>
                      </div>
                  </div>
                </div>
              </card>
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;">  
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="display-2 text-info text-center">Reporte de Crédito</h3>
                    <div class="row justify-content-center" style="margin:5rem;">

                        <div class="col-12" style="margin:2rem;">
                          <accounts-table
                          title="Cuentas"
                          :data="accountTableData"
                          :addendumAccFormat="true"
                          :pagination="accountPagination"
                          @pageSelected="getUserAccounts"
                        />
                        </div>
                        <div class="col-12" style="margin:1rem;">
                          <div v-if="report">
                          <credit-report-table style="margin-top: 20px" v-if="lengthsCreditReports.dbmAccounts>0"
                                  title = "Cuentas que se pueden agregar al programa"
                                  :data="report && report['dbmAccounts'] ? report['dbmAccounts'] : []"
                                  :pagination="CRPagination"/>
                                    <div v-else class="text-center">
                                        <div class="card-profile-stats d-flex justify-content-center">
                                            <h6 class="heading-small text-muted mb-4">No se encontaron cuentas que se puedan agregar al programa</h6>
                                        </div>
                                    </div>

                                    <credit-report-table style="margin-top: 20px" v-if="lengthsCreditReports.otherAccounts>0"
                                            title = "Otras cuentas"
                                            :data="report && report['otherAccounts'] ? report['otherAccounts'] : []"
                                            :pagination="CRPagination"/>
                                    <div v-else class="text-center">
                                        <div class="card-profile-stats d-flex justify-content-center">
                                            <h6 class="heading-small text-muted mb-4">No se encontaron otras cuentas</h6>
                                        </div>
                                    </div>
                                  </div>
                                  <div v-else class="text-center">
                                      <div class="card-profile-stats d-flex justify-content-center">
                                          <div>
                                              <h6 class="heading-small text-muted mb-4">No se encontró un reporte de crédito</h6>
                                              <span class="font-weight-bold ml-1 text-warning">{{getMessageReport(messageReport)}}</span><br>
                                              <span class="description">status</span>
                                          </div>
                                      </div>
                                  </div>
                        </div>
                        <div class="col text-left" style="margin:5rem;">
                           <b-form-group>
                            <b-form-checkbox-group
                              id="rc"
                              :disabled="isManagerSeller"
                              v-model="selectedRC"
                              :options="optinosRC"
                              class="ml-4"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                      </div>
                  </div>
                </div>
              </card>
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;">  
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="display-2 text-info text-center">Calculadora Programa</h3>
                    <div class="row justify-content-center">

                      <div class="col-12" style="margin:2rem;">
                        <div class="pl-lg-4">
                          <div class="row">
                            <div class="col-md-4">
                              <base-input
                                alternative
                                disabled
                                label="Día de depósito mensual"
                                input-classes="form-control-alternative"
                                v-model="client.payday"
                              />
                            </div>
                            <div class="col-md-4">
                              <base-input
                                alternative
                                disabled
                                label="Porcentaje Comisión"
                                input-classes="form-control-alternative"
                                v-model="client.commission_percentage"
                              />
                            </div>
                            <div class="col-md-4">
                              <base-input
                                alternative
                                disabled
                                label="Comisión Efectiva"
                                input-classes="form-control-alternative"
                                v-model="client.effective_commission"
                              />
                            </div>
                            <div class="col-md-6">
                              <base-input
                                alternative
                                disabled
                                label="Duración del programa (Meses)"
                                input-classes="form-control-alternative"
                                v-model="client.program_duration"
                              />
                            </div>
                            <div class="col-md-6">
                              <base-input
                                alternative
                                disabled
                                label="Pago mensual"
                                input-classes="form-control-alternative"
                                v-model="client.monthly_savings"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <label class="form-control-label">Fecha de primer pago</label>
                              <flat-pickr
                                disabled
                                v-model="client.first_payment"
                                :config="{}"
                                class="form-control form-control-alternative"
                                data-vv-as="Fecha"
                                name="first_payment"
                                style="background-color: #ffffff"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12" v-if="!auditTableData.filename_quote" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ningun PDF de la Calculadora</h2>
                        </div>
                        <div v-else class="col-12">
                        <div class="col-12" style="margin:1rem;">
                          <a v-if="filePROG" :href="filePROG" target="_blank">
                            <span class="name mb-0 text-sm">Descargar PDF Calculadora<i class="fas fa-download text-primary"></i></span>
                          </a>
                        </div>
                        </div>
                      <div class="container text-left" style="margin:1rem;" v-if="auditTableData.filename_quote">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Total adeudado
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(calculos.totalDebt)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-red text-white rounded-circle shadow"
                      >
                        <i class="fas fa-money-bill"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Ahorros
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(calculos.savings)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-success text-white rounded-circle shadow"
                      >
                        <i class="fas fa-dollar-sign"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Duración del programa
                      </h6>
                      <span v-if="calculos.p" class="h2 font-weight-bold mb-0"
                        >{{ calculos.p }} meses</span
                      >
                      <span
                        v-if="calculos.period"
                        class="h2 font-weight-bold mb-0"
                        >{{ calculos.period }} meses</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-info text-white rounded-circle shadow"
                      >
                        <i class="fas fa-clock"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Depósito mensual
                      </h6>
                      <span
                        v-if="calculos.monthlyFee"
                        class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(
                            calculos.monthlyFee
                              ? Math.ceil(calculos.monthlyFee)
                              : Math.ceil(calculos.monthlyFee)
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                      <span
                        v-if="calculos.mfee"
                        class="h2 font-weight-bold mb-0"
                        >${{
                          formatDecPost(
                            calculos.mfee ? calculos.mfee : calculos.mfee
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-blue text-white rounded-circle shadow"
                      >
                        <i class="fas fa-coins"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

<div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Porcentaje de Comisión
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >{{
                          formatDecPostFour(client.effective_commission)
                        }}%</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-success text-white rounded-circle shadow"
                      >
                        <i class="fas fa-hand-holding-usd"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Comisión
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >{{ $formatCurrency(client.total_debt * (client.commission_percentage /100)) }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-danger text-white rounded-circle shadow"
                      >
                        <i class="fas fa-money-bill-wave"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Descuento estimado
                      </h6>
                      <span class="h2 font-weight-bold mb-0"
                        >{{
                          formatDecPost(calculos.discount)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}%</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-warning text-white rounded-circle shadow"
                      >
                        <i class="fa fa-percent"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-5">
              <div class="card card-stats mb-4 mb-xl-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h6 class="card-title text-uppercase text-muted mb-0">
                        Deudas
                      </h6>
                      <span
                        v-if="calculos.debts"
                        class="h2 font-weight-bold mb-0"
                        >{{
                          calculos && calculos.debts
                            ? calculos.debts.length
                            : "0"
                        }}</span
                      >
                      <span
                        v-if="calculos.accounts"
                        class="h2 font-weight-bold mb-0"
                        >{{
                          calculos && calculos.accounts
                            ? calculos.accounts.length
                            : "0"
                        }}</span
                      >
                    </div>
                    <div class="col-auto">
                      <div
                        class="icon icon-shape bg-indigo text-white rounded-circle shadow"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="calculos.calculator_new"
            class="row justify-content-center text-center"
          >
            <div class="col-xl-10 col-lg-10 col-md-10">
              <div class="card shadow">
                <div class="card-header border-0">
                  <h3 class="mb-0">Deudas a liquidar</h3>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center" scope="col">Deuda</th>
                        <th class="text-center" scope="col">Acreedor</th>
                        <th class="text-center" scope="col">Deuda sin dbm</th>
                        <th class="text-center" scope="col">
                          Valor a pagar con dbm
                        </th>
                        <th class="text-center" scope="col">Mes a pagar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(debt, index) in calculos.debts"
                        :key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ debt.bank }}</td>
                        <td class="text-center">
                          ${{
                            formatDecPost(debt.amount)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </td>
                        <td class="text-center">
                          ${{
                            formatDecPost(debt.willPay)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            debt.willPayOnMonth
                              ? debt.willPayOnMonth
                              : debt.willPayOnMonth
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="calculos.debts && !calculos.calculator_new"
            class="row justify-content-center text-center"
          >
            <div class="col-xl-10 col-lg-10 col-md-10">
              <div class="card shadow">
                <div class="card-header border-0">
                  <h3 class="mb-0">Deudas a liquidar</h3>
                </div>
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center" scope="col">Deuda</th>
                        <th class="text-center" scope="col">Acreedor</th>
                        <th class="text-center" scope="col">Deuda sin dbm</th>
                        <th class="text-center" scope="col">
                          Valor a pagar con dbm
                        </th>
                        <th class="text-center" scope="col">Mes a pagar</th>
                        <th class="text-center" scope="col">Avance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(debt, index) in calculos.debts" :key="index">
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ debt.bank }}</td>
                        <td class="text-center">
                          ${{
                            formatDecPost(debt.amount)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </td>
                        <td class="text-center">
                          ${{
                            debt.willPay
                              ? formatDecPost(debt.willPay)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : formatDecPost(debt.will_pay)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            debt.willPayOnMonth
                              ? debt.willPayOnMonth
                              : debt.will_pay_on
                          }}
                        </td>
                        <td class="text-center">
                          {{ formatDecPost(debt.o_progress) }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
                    </div>
                       <div class="col text-left" style="margin:6rem;">
                           <b-form-group>
                            <b-form-checkbox-group
                              id="prog"
                              :disabled="isManagerSeller"
                              v-model="selectedPROG"
                              :options="optinosPROG"
                              class="ml-4"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                    </div>
                  </div>
                </div>
              </card>
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;">  
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="display-2 text-info text-center">Contrato DBM</h3>
                    <div class="row justify-content-center" style="margin:5rem;">
                        <div class="col-6" v-if="!auditTableData.filename_IF" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ninguna Identificación Frente</h2>
                        </div>
                        <div v-else class="col-6">
                        <div v-if="!isPdfIF" class="col-12" style="margin:1rem;">
                          <img :src="fileIF" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          <br>
                          <a v-if="fileIF" :href="fileIF" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        <div v-else class="align-items-center col-6" style="margin:5rem;">
                          <base-p-d-f-visor :fileurl="fileIF" :key="forceUpdate"/>
                        </div>
                        </div>
                        <div class="col-6" v-if="!auditTableData.filename_IR" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ninguna Identificación Trasera</h2>
                        </div>
                        <div v-else class="col-6">
                        <div v-if="!isPdfIR" class="col-12" style="margin:1rem;">
                          <img :src="fileIR" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          <br>
                          <a v-if="fileIR" :href="fileIR" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        </div>
                        <div class="col-12" v-if="!auditTableData.filename_contract" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ningun Contrato DBM</h2>
                        </div>
                        <div v-else>
                        <div v-if="!isPdfC1" class="align-items-center col-12" style="margin:1rem;">
                          <img :src="fileC1" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          <br>
                          <a v-if="fileC1" :href="fileC1" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        <div v-else class="align-items-center col-12" style="margin:5rem;">
                          <base-p-d-f-visor :fileurl="fileC1" :key="forceUpdate"/>
                        </div>
                        </div>

                        <div class="col-6" style="margin:5rem;">
                          <h2 class="display-4 text-primary">{{ client.name }} {{ client.lastname_1 }} {{ client.lastname_2 }}</h2>
                          <label class="form-control-label text-gray">Nombre Completo</label>
                          <h2  class="display-4 text-primary">{{ $formatCurrency(client.monthly_savings) }}</h2>
                          <label class="form-control-label text-gray">Ahorro Mensual</label>
                           <h2  class="display-4 text-primary">{{ client.program_duration }} Meses</h2>
                          <label class="form-control-label text-gray">Duración</label>
                          <h2  class="display-4 text-primary">{{ $formatCurrency(client.total_debt) }}</h2>
                          <label class="form-control-label text-gray">Monto Deuda Ingresada</label>
                          <h2  class="display-4 text-primary" v-if="calculos.commission" >{{ $formatCurrency(calculos.commission) }}</h2>
                          <h2  class="display-4 text-primary" v-if="!calculos.commission" >{{ $formatCurrency(client.total_debt * (client.commission_percentage /100)) }}</h2>
                          <label class="form-control-label text-gray">Monto Comisión</label>
                        </div>
                         <div class="col text-left" style="margin:5rem;">
                           <b-form-group>
                            <b-form-checkbox-group
                              id="c1"
                              v-model="selectedC1"
                              :disabled="isManagerSeller"
                              :options="optinosC1"
                              class="ml-4"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                      </div>
                  </div>
                </div>
              </card>

              <!--
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;">  
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="display-2 text-info text-center">Contrato Fin amigo</h3>
                    <div class="row justify-content-center" style="margin:5rem;">
                        <div class="col-6" v-if="!auditTableData.filename_IF" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ninguna Identificación Frente</h2>
                        </div>
                        <div v-else class="col-6">
                        <div v-if="!isPdfIF" class="col-12" style="margin:1rem;">
                          <img :src="fileIF" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          <br>
                          <a v-if="fileIF" :href="fileIF" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        <div v-else class="align-items-center col-6" style="margin:5rem;">
                          <base-p-d-f-visor :fileurl="fileIF" :key="forceUpdate"/>
                        </div>
                        </div>
                        <div class="col-6" v-if="!auditTableData.filename_IR" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ninguna Identificación Trasera</h2>
                        </div>
                        <div v-else class="col-6">
                        <div v-if="!isPdfIR" class="col-12" style="margin:1rem;">
                          <img :src="fileIR" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          <br>
                          <a v-if="fileIR" :href="fileIR" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        </div>
                        <div class="col-12" v-if="!auditTableData.filename_contract_fa" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ningun Contrato de FA</h2>
                        </div>
                        <div v-else>
                        <div v-if="!isPdfC2" class="col-12" style="margin:1rem;">
                          <img :src="fileC2" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          <br>
                          <a v-if="fileC2" :href="fileC2" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        <div v-else class="align-items-center col-12" style="margin:5rem;">
                          <base-p-d-f-visor :fileurl="fileC2" :key="forceUpdate"/>
                        </div>
                        </div>
                        <div class="col-6"  style="margin:5rem;">
                          <h2 class="display-4 text-primary">{{ client.name }} {{ client.lastname_1 }} {{ client.lastname_2 }}</h2>
                          <label class="form-control-label text-gray">Nombre Completo</label>
                          <h2  class="display-4 text-primary" v-if="calculos.commission" >{{ $formatCurrency(calculos.commission) }}</h2>
                          <h2  class="display-4 text-primary" v-if="!calculos.commission" >{{ $formatCurrency(client.total_debt * (client.commission_percentage /100)) }}</h2>
                          <label class="form-control-label text-gray">Monto Comisión</label>
                           <h2  class="display-4 text-primary">{{ finishProgramUser }}</h2>
                          <label class="form-control-label text-gray">Fecha Fin Programa</label>
                        </div>
                        <div class="col text-left" style="margin:5rem;">
                           <b-form-group>
                            <b-form-checkbox-group
                              id="c2"
                              v-model="selectedC2"
                              :disabled="isManagerSeller"
                              :options="optinosC2"
                              class="ml-4"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                      </div>
                  </div>
                </div>
              </card>
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;">  
                <div class="row justify-content-center">
                  <div class="col">
                    <h3 class="display-2 text-info text-center">Manifesto Fin amigo</h3>
                    <div class="row justify-content-center" style="margin:5rem;">
                        <div class="col-6" v-if="!auditTableData.filename_IF" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ninguna Identificación Frente</h2>
                        </div>
                        <div v-else class="col-6">
                        <div v-if="!isPdfIF" class="col-12" style="margin:1rem;">
                          <img :src="fileIF" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          <br>
                          <a v-if="fileIF" :href="fileIF" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        <div v-else class="align-items-center col-6" style="margin:5rem;">
                          <base-p-d-f-visor :fileurl="fileIF" :key="forceUpdate"/>
                        </div>
                        </div>
                        <div class="col-6" v-if="!auditTableData.filename_IR" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ninguna Identificación Trasera</h2>
                        </div>
                        <div v-else class="col-6">
                        <div v-if="!isPdfIR" class="col-12" style="margin:1rem;">
                          <img :src="fileIR" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          <br>
                          <a v-if="fileIR" :href="fileIR" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        </div>
                        <div class="col-12" v-if="!auditTableData.filename_manifest_fa" style="margin:1rem;">
                            <h2 class="display-4 text-danger"><i class="fas fa-times"></i> No se encontro ningun Manifesto de FA</h2>
                        </div>
                        <div v-else>
                        <div v-if="!isPdfCA" class="col-12" style="margin:1rem;">
                          <img :src="fileCA" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          <br>
                          <a v-if="fileCA" :href="fileCA" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        <div v-else class="align-items-center col-12" style="margin:5rem;">
                          <base-p-d-f-visor :fileurl="fileCA" :key="forceUpdate"/>
                        </div>
                        </div>
                        <div class="col-6" style="margin:5rem;">
                          <h2 class="display-4 text-primary">{{ client.name }} {{ client.lastname_1 }} {{ client.lastname_2 }}</h2>
                          <label class="form-control-label text-gray">Nombre Completo</label>
                        </div>
                        <div class="col text-left" style="margin:5rem;">
                           <b-form-group>
                            <b-form-checkbox-group
                              id="ca"
                              v-model="selectedCA"
                              :options="optinosCA"
                              :disabled="isManagerSeller"
                              class="ml-4"
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </div>
                      </div>
                  </div>
                </div>
              </card>
              !-->
              <br>
              <card class="border-0" shadow body-classes="py-3" style="margin-top:1rem;" v-if="!isManagerSeller">  
                <div class="row justify-content-center">
                  <h3 class="display-2 text-info text-center">Lista Nominal</h3>
              <div class="col-12" v-if="!auditTableData.filename_nominal_list">
                <h4 class="text-muted mb-2">
                    Subir Lista Nominal
                  </h4>
                <div class="d-flex justify-content-center">
                  <base-dropdown>
                    <base-button slot="title" class="dropdown-toggle" type="primary" style="margin-top: 15px; white-space: normal">
                      {{fileType ? getFileType(fileType) : "Selecciona el tipo de documento" }}
                    </base-button>
                    <a class="dropdown-item" v-for="type in fileTypes"  :key="type" v-on:click="fileType = type">
                      {{ getFileType(type) }}
                    </a>
                  </base-dropdown>
                </div>
                <div class="card-profile-stats d-flex justify-content-center">
                  <dropbox-resize 
                    :type="fileType"
                    :userid="client.id"
                    :files="userFiles"
                    v-on:uploadFile="uploadFile"/>
                </div>
              </div>
                  <div class="col-12" v-if="auditTableData.filename_nominal_list">
                    <div class="row justify-content-center">
                        <div v-if="!isPdfNominal" class="col-12" style="margin:1rem;">
                          <img :src="fileNominalList" style="max-height: 100%; max-width: 100%; margin-bottom:10px;"/>
                          <br>
                          <a v-if="fileNominalList" :href="fileNominalList" target="_blank">
                          <span class="name mb-0 text-sm">Descargar<i class="fas fa-download text-primary"></i></span>
                        </a>
                        </div>
                        <div v-else class="align-items-center col-11" style="margin:5rem;">
                          <base-p-d-f-visor :fileurl="fileNominalList" :key="forceUpdate"/>
                        </div>
                        </div>
                  </div>
                </div>
              </card>
              <div v-if="!isManagerSeller">
                <div>
                  <h6 class="heading-small text-muted mb-4">
                    Documentos finamigo
                  </h6>
                  <div class="px-lg-4">
                    <div class="row">
                      <div class="col">
                        <div class="card-profile-stats d-flex justify-content-center">
                          <i :class="iconoFinAmigo('far fa-file-image', files.upload_ID)" v-on:click="uploadFinamigoFile('ID')" style="font-size: 40px"/>
                        </div>
                        <span class="description">ID</span>
                      </div>
                      <div class="col">
                        <div class="card-profile-stats d-flex justify-content-center">
                          <i :class="iconoFinAmigo('far fa-file-image', files.upload_ID_B)" v-on:click="uploadFinamigoFile('ID_B')" style="font-size: 40px"/>
                        </div>
                        <span class="description">ID_B</span>
                      </div>
                      <!--
                      <div class="col">
                        <div class="card-profile-stats d-flex justify-content-center">
                          <i :class="iconoFinAmigo('far fa-file-image', files.upload_ADDRESS)" v-on:click="uploadFinamigoFile('ADDRESS')" style="font-size: 40px"/>
                        </div>
                        <span class="description">ADDRESS</span>
                      </div>
                      !-->
                      <div class="col">
                        <div class="card-profile-stats d-flex justify-content-center">
                          <i :class="iconoFinAmigo('far fa-file-pdf', files.upload_CURP)" v-on:click="uploadFinamigoFile('CURP')" style="font-size: 40px"/>
                        </div>
                        <span class="description">CURP</span>
                      </div>
                      <!--
                      <div class="col">
                        <div class="card-profile-stats d-flex justify-content-center">
                          <i :class="iconoFinAmigo('far fa-file-pdf', files.upload_FIN_MANIFEST)" v-on:click="uploadFinamigoFile('FIN_MANIFEST')" style="font-size: 40px"/>
                        </div>
                        <span class="description">MANIFESTO</span>
                      </div>
                      <div class="col">
                        <div class="card-profile-stats d-flex justify-content-center">
                          <i :class="iconoFinAmigo('far fa-file-pdf', files.upload_FIN_CONTRACT)" v-on:click="uploadFinamigoFile('FIN_CONTRACT')" style="font-size: 40px"/>
                        </div>
                        <span class="description">CONTRATO</span>
                      </div>
                      !-->
                      <div class="col">
                        <div class="card-profile-stats d-flex justify-content-center">
                          <i :class="iconoFinAmigo('far fa-file-pdf', files.upload_NOMINAL_LIST)" v-on:click="uploadFinamigoFile('NOMINAL_LIST')" style="font-size: 40px"/>
                        </div>
                        <span class="description">LISTA NOMINAL</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <base-button type="outline-info" class="p-2" size="sm" v-on:click="sendDocComments()" v-if="!isManagerSeller">
                Enviar Notas
              </base-button>
              <div class="col-12" style="margin-top:1rem;">
                <base-button type="success" class="btn btn-block" size="lg" v-on:click="saveDataAudit()" v-if="!isManagerSeller">
                  Guardar Cambios
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
//import Dropbox from "../components/Dropbox";
import CreditReportTable from "./Tables/CreditReportTable";
import AccountsTable from "./Tables/AccountsTable";
import BasePDFVisor from "../components/BasePDFVisor";
import DropboxResize from "../components/DropboxResize";
export default {
  name: "home",
  components: {
    BasePDFVisor,
    CreditReportTable,
    AccountsTable,
    flatPickr,
    DropboxResize
    /*Dropbox,
    CreditReportTable*/
  },
  async created() {
    let clientId = this.$route.query.client;
    this.$showLoader(true);
    await this.getClientProfile(clientId);
    await this.getAuditData(clientId);
    await this.getProgramCalculation(clientId);
    await this.getFinamigoFiles();
    await this.getCatalogType('IF');
    await this.getCatalogType('IR');
    await this.getCatalogType('RFC');
   // await this.getCatalogType('CD');
    await this.getCatalogType('RC');
    await this.getCatalogType('CURP');
    await this.getCatalogType('PROG');
    await this.getCatalogType('C1');
    //await this.getCatalogType('C2');
    //await this.getCatalogType('CA');
    await this.getCatalogType('HIGH');
    this.isLeader = this.user.leader === null;
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    this.forceUpdate++;
    this.$showLoader(false);
    this.$forceUpdate()
  },
  data() {
    return {
      fileTypes: [
        "NOMINAL_LIST",
      ],
      fileType: null,
      optinosIF: [{ text: 'OK', value: 1 },{ text: 'Falta Identificación Frente', value: 2},
      { text: 'INE Frente no se puede descargar', value: 4},
      { text: 'INE Frente no se puede validar (No es clara la numeración)', value: 8},
      { text: 'INE No esta Vigente o tiene actualización de datos', value: 16},
      { text: 'Nombre capturado no coincide con INE', value: 32},
      { text: 'Fotocopia no es valida', value: 64},
      { text: 'Se ingreso Cod. de identificacion', value: 128},
      { text: 'Faltan 4 esquinas', value: 256}],
      optinosIR: [{ text: 'OK', value: 1 },{ text: 'Falta Identificación Reverso', value: 2},{ text: 'INE Reverso no se puede descargar', value: 4},{ text: 'INE Reverso no se puede validar (No es clara la numeración)', value: 8}],
      optinosRFC: [{ text: 'OK', value: 1 },{ text: 'RFC Incorrecto', value: 2},{ text: 'RFC No registrado en el padrón de contribuyentes', value: 4},{ text: 'RFC no susceptible de recibir facturas', value: 8}],
      optinosCD: [{ text: 'OK', value: 1 },{ text: 'Comprobante de domicilio no esta vigente', value: 2},
      { text: 'Falta Comprobante de domicilio', value: 4},
      { text: 'Imagen de Comprobante de domicilio incompleta (se deben ver las 4 esquinas)', value: 8},
      { text: 'Comprobante de domicilio no es legible (borroso)', value: 16},
      { text: 'Comprobante de domicilio no se puede descargar', value: 32},
      { text: 'Comprobante de domicilio invalido (no es el documento original)', value: 64},
      { text: 'Comprobante de domicilio invalido (estados de cuenta unicamente a nombre de titular)', value: 128},
      { text: 'Comprobante de domicilio invalido', value: 256},
      { text: 'Falta comprobante de domicilio - Otro documento cargado en apartado', value: 512},
      { text: 'Dirección capturada no es la misma que el Comp. de Dom.', value: 1024}],
      optinosCURP: [{ text: 'OK', value: 1 },{ text: 'CURP no corresponde a cliente', value: 2},{ text: 'Falta CURP', value: 4},{ text: 'Falta CURP - Comprobante de domicilio cargado en apartado', value: 8}],
      optinosRC: [
        { text: 'OK', value: 1 },
        { text: 'Cuentas ingresadas no se encuentran en Buro', value: 2},
        { text: 'Falta agregar numero de cuentas', value: 4},
        { text: 'Falta asignar fecha de ultimo pago bancario', value: 8},
        { text: 'Falta asignar fecha de ultimo pago y tipo de producto de las cuentas', value: 16},
        { text: 'Falta asignar tipo de producto de las cuentas', value: 32},
        { text: 'Falta reporte de crédito', value: 64},
        { text: 'Falta respaldo de monto adeudado de la/las cuentas', value: 128},
        { text: 'Falta respaldo de número de cuenta', value: 256},
        { text: 'La/las cuentas no se encuentran en buro', value: 512},
        { text: 'Monto adeudado de la/las cuentas no coinciden con buro', value: 1024},
        { text: 'Número de cuentas no coinciden con Buro', value: 2048},
        { text: 'Falta respaldo de número de cuenta', value: 4096},
        { text: 'Suma de monto de deudas de las cuentas no coinciden con site y contratos', value: 8192},
        { text: 'Total adeudado de la/las cuentas agregadas no coinciden con respaldo', value: 16384},
        { text: 'Fecha de último pago bancario de la/las cuentas no coinciden con buro.', value: 32768},
        
        ],
      optinosPROG: [
        { text: 'OK', value: 1 },
        { text: 'Falta propuesta por calculadora', value: 2},
        { text: 'Propuesta por calculadora no coincide con site', value: 4}
        ],
      optinosC1: [
        { text: 'OK', value: 1 },
        { text: 'Comisión en contrato DBM incorrecta', value: 2},
        { text: 'Contrato DBM sin firma', value: 4},
        { text: 'Falta contrato DBM', value: 8},
        { text: 'Firma de contrato DBM no coincide con INE', value: 16},
        { text: 'Firma de contrato DBM repetida/doble', value: 32},
        { text: 'Firma ilegible en contrato DBM', value: 64},
        { text: 'Modifico apellidos, pendiente actualizar contrato DBM', value: 128},
        { text: 'Modifico CURP, pendiente actualizar contrato DBM', value: 256},
        { text: 'Modifico Fecha de nacimiento, pendiente actualizar contrato DBM', value: 512},
        { text: 'Modifico monto de deuda, pendiente actualizar contrato DBM', value: 1024},
        { text: 'Modifico nombres, pendiente actualizar contrato DBM', value: 2048},
        { text: 'Modifico número de identificación, pendiente actualizar contrato DBM', value: 4096},
        { text: 'Modifico RFC, pendiente actualizar contrato DBM', value: 8192},
        { text: 'No se pueden validar firmas de contrato DBM (Falta INE)', value: 16384},
        { text: 'Informacion en contrato DBM no coincide con site (Mdi/Ahorro)', value: 32768},
        { text: 'Falta firma en contrato  DBM', value: 65536}
        ],
       optinosC2: [
        { text: 'OK', value: 1 },
        { text: 'Comisión en contrato FA incorrecta', value: 2},
        { text: 'Falta Contrato FA', value: 4},
        { text: 'Firma de contrato FA no coincide con INE', value: 8},
        { text: 'Firma de contrato FA repetida/doble', value: 16},
        { text: 'Modifico apellidos, pendiente actualizar contrato FA', value: 32},
        { text: 'Modifico nombres, pendiente actualizar contrato FA', value: 64},
        { text: 'No se pueden validar firmas de contrato FA (Falta INE)', value: 128},
        { text: 'Firma ilegible en contrato FA', value: 256},
        { text: 'Falta firma en contrato FA', value: 512},
        { text: 'Fecha de Terminación de Programa no coincide (Carta Mandato)', value: 1024}
        ],
        optinosCA: [
        { text: 'OK', value: 1 },
        { text: 'Falta Caratula FA', value: 2},
        { text: 'Firma de Caratula FA no coincide con INE', value: 4},
        { text: 'Firma de Caratula FA repetida/doble', value: 8},
        { text: 'Modifico apellidos, pendiente actualizar Caratula FA', value: 16},
        { text: 'Modifico nombres, pendiente actualizar Caratula FA', value: 32},
        { text: 'No se pueden validar firmas de Caratula FA (Falta INE)', value: 64},
        { text: 'Firma ilegible en Caratula FA', value: 128},
        { text: 'Falta firma en Caratula FA', value: 256}
        ],
      userFiles: [],
      selectedIF: [],
      selectedIR: [],
      selectedRFC: [],
      selectedCD: [],
      selectedCURP: [],
      selectedRC: [],
      selectedPROG: [],
      selectedC1: [],
      selectedC2: [],
      selectedCA: [],
      allSelected: false,
      indeterminate: false,
      client: {},
      catalogIF: null,
      catalogIR: null,
      catalogRFC: null,
      catalogCD: null,
      catalogCURP: null,
      catalogRC: null,
      catalogPROG: null,
      catalogC1: null,
      catalogC2: null,
      catalogCA: null,
      catalogHIGH: null,
      fileIF: null,
      fileIR: null,
      fileRFC: null,
      fileCD: null,
      fileCURP: null,
      fileRC: null,
      filePROG: null,
      fileC1: null,
      fileC2: null,
      fileCA: null,
      fileNominalList: null,
      isPdfIF: false,
      isPdfIR: false,
      isPdfRFC: false,
      isPdfCD: false,
      isPdfCURP: false,
      isPdfRC: false,
      isPdfPROG: false,
      isPdfC1: false,
      isPdfC2: false,
      isPdfCA: false,
      isPdfNominal: false,
      isAdmin: false,
      isSeller: false,
      isService: false,
      isManagerService: false,
      isLeader: false,
      isManagerSeller:false,
      auditTableData: [],
      accountTableData: [],
      files: {},

      //Buró
      showModalReport: false,
      report: {},
      contract: {},
      messageReport: '',
      reportByBC: false,
      finishProgramUser:null,
      CRPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      accountPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      lengthsCreditReports:{
          dbmAccounts: null,
          otherAccounts: null
      },
      //Calculator
      dataCalculation: {},
      clientCalculations: {
        totalDebt: 0,
        savings: 0,
        mfee: 0,
        monthlyFee: 0,
        discount: 0,
        debts: [],
      },
      calculos: {
        totalDebt: 0,
        savings: 0,
        mfee: 0,
        monthlyFee: 0,
        discount: 0,
        debts: [],
      },
      createCalculation: false,

      rotateIF: 0,
      rotateIR: 0,
      rotateCD: 0,
      rotateCURP: 0,
      //PDFViewer
      forceUpdate: 0,
    };
  },
  computed: {
    user() {return this.$getLS(this.$v.STORE_KEYS.USER);},
    auditorCatalog() { return this.$store.state.auditorCatalog },
    mxStates() { return this.$store.state.mxStates; },
    banks() { return this.$store.state.banks; },
    productType() { return this.$store.state.productType },
  },
  watch: {
    $route() {
      let clientId = this.$route.query.client;
      if (clientId > 0)
        this.getAuditData(clientId).then(found => {
          if (!found) {
            this.$router.push("/clients");
          }
        });
    },
  },
  methods: {
    getFileType(type) {
      switch (type) {
        case "NOMINAL_LIST":
          return "Lista Nominal";
      }
      return "";
    },
    uploadFile(obj) {
      let fileType = obj.type;
      let file = obj.file;
      let text = obj.text;
      let formData = new FormData();
      formData.append("file", file);
      let headers = {
        "client-id": this.client.id,
        "file-type": fileType,
      };
      this.$showLoader(true);
      this.$axios.post(this.$v.SERVER + this.$v.SELLER_OPS.UPLOAD_FILE, formData, {headers: headers,}).then((response) => {
          this.$showLoader(false);
          if (response.data.success) {
            this.$vs.notification(this.$notification(
                'success',
                 'Lista Nominal cargado exitosamente'
                     ));
            this.uploadFinamigoFile('NOMINAL_LIST');
          } else {
            this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
          }
        })
        .catch((e) => {
          console.log(e);
          this.$showLoader(false);
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal, intenta nuevamente'
                     ));
        });
    },  
    rotate(type){
      switch (type){
        case 'IF': this.rotateIF = this.rotateIF+=90; break;
        case 'IR': this.rotateIR = this.rotateIR+=90; break;
        case 'CD': this.rotateCD = this.rotateCD+=90; break;
        case 'CURP': this.rotateCURP = this.rotateCURP+=90; break;
      }
    },
 ckecksButtons(){
    let marksIF= [0, 0, 0, 0, 0, 0, 0, 0, 0]
    let marksIR = [0, 0, 0, 0]
    let marksRFC =[0, 0, 0, 0]
    //let marksCD = [0,0,0,0,0,0,0,0,0,0,0]
    let marksRC = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    let marksCURP = [0, 0, 0, 0]
    let marksPROG = [0, 0, 0]
    let marksC1 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    //let marksC2 = [0,0,0,0,0,0,0,0,0,0,0]
    //let marksCA = [0,0,0,0,0,0,0,0,0,0]

    let c = this.auditTableData;
    console.log(c)
    if(c.if_id == '-1'){
    this.selectedIF= [0, 0, 0, 0]
    }else{
    this.selectedIF=this.decimal2Binary(Number(c.if_id), marksIF)
    }

    if(c.ir == '-1'){
    this.selectedIR= [0, 0, 0, 0]
    }else{
    this.selectedIR=this.decimal2Binary(Number(c.ir), marksIR)
    }

    if(c.rfc == '-1'){
    this.selectedRFC= [0, 0, 0, 0]
    }else{
    this.selectedRFC=this.decimal2Binary(Number(c.rfc), marksRFC)
    }
    /*
    if(c.cd == '-1'){
    this.selectedCD= [0, 0, 0, 0]
    }else{
    this.selectedCD=this.decimal2Binary(Number(c.cd), marksCD)
    }
    */

    if(c.rc == '-1'){
    this.selectedRC= [0, 0, 0, 0]
    }else{
    this.selectedRC=this.decimal2Binary(Number(c.rc), marksRC)

    }

    if(c.curp == '-1'){
    this.selectedCURP= [0, 0, 0, 0]
    }else{
    this.selectedCURP=this.decimal2Binary(Number(c.curp), marksCURP)
    }

    if(c.prog == '-1'){
    this.selectedPROG= [0, 0, 0, 0]
    }else{
    this.selectedPROG=this.decimal2Binary(Number(c.prog), marksPROG)
    }

    if(c.c1 == '-1'){
    this.selectedC1= [0, 0, 0, 0]
    }else{
    this.selectedC1=this.decimal2Binary(Number(c.c1), marksC1)
    }
    /*
    if(c.c2 == '-1'){
      this.selectedC2 = [0, 0, 0, 0]
    }else{
    this.selectedC2=this.decimal2Binary(Number(c.c2), marksC2)
    }

    if(c.ca == '-1'){
    this.selectedCA= [0, 0, 0, 0]
    }else{
    this.selectedCA=this.decimal2Binary(Number(c.ca), marksCA)
    }
    */
    //console.log(this.selectedCD)
},

decimal2Binary(decimal, marks){
    let binary = 1
    for(let i=0; i<marks.length; i++){
        if(decimal & binary)
            marks[i] = binary
        binary *= 2
    }
    return marks
},
   saveDataAudit(){
     let clientId = this.client.id;
      this.$showLoader(true)
      let totalIF=0;
      let totalIR=0;
      let totalRFC=0;
      let totalCD=0;
      let totalCURP=0;
      let totalRC=0;
      let totalPROG=0;
      let totalC1=0;
      let totalC2=0;
      let totalCA=0;
      for(let i =0;i<this.selectedIF.length;i++){
        totalIF+=this.selectedIF[i]
      }
      for(let i =0;i<this.selectedIR.length;i++){
        totalIR+=this.selectedIR[i]
      }
      for(let i =0;i<this.selectedRFC.length;i++){
        totalRFC+=this.selectedRFC[i]
      }
      for(let i =0;i<this.selectedCD.length;i++){
        totalCD+=this.selectedCD[i]
      }
      for(let i =0;i<this.selectedCURP.length;i++){
        totalCURP+=this.selectedCURP[i]
      }
      for(let i =0;i<this.selectedRC.length;i++){
        totalRC+=this.selectedRC[i]
      }
      for(let i =0;i<this.selectedPROG.length;i++){
        totalPROG+=this.selectedPROG[i]
      }
      for(let i =0;i<this.selectedC1.length;i++){
        totalC1+=this.selectedC1[i]
      }
      for(let i =0;i<this.selectedC2.length;i++){
        totalC2+=this.selectedC2[i]
      }
      for(let i =0;i<this.selectedCA.length;i++){
        totalCA+=this.selectedCA[i]
      }
          if(totalIF == 1){
            this.uploadFinamigoFile('ID');
          }
          if(totalIR == 1){
            this.uploadFinamigoFile('ID_B');
          }
          /*
          if(totalCD == 1){
            this.uploadFinamigoFile('ADDRESS');
          }
          */
          if(totalCURP == 1){
            this.uploadFinamigoFile('CURP');
          }

          /*
          if(totalC2 == 1){
            this.uploadFinamigoFile('FIN_CONTRACT');
          }
          if(totalCA == 1){
            this.uploadFinamigoFile('FIN_MANIFEST');
          }
          */
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.SET_AUDITOR_USERS;
      let request={
        user:clientId,
        if_id:Number(totalIF),
        ir:Number(totalIR),
        rfc:Number(totalRFC),
        cd:Number(totalCD),
        curp:Number(totalCURP),
        rc:Number(totalRC),
        prog:Number(totalPROG),
        c1:Number(totalC1),
        c2:Number(totalC2),
        ca:Number(totalCA)
      }
      this.$axios.post(url, request).then((response) => {
      this.$showLoader(false)
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification('success'))
          this.getFinamigoFiles();
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification('danger'))
        }
      });
    },
     getProgramCalculation(clientId) {
      this.$showLoader(true);
      let url =
        this.$v.SERVER +
        this.$v.SELLER_OPS.GET_PROGRAM_CALCULATION +
        "?user=" +
        clientId;
      this.$axios.get(url).then((response) => {
        let data = response.data;
            this.$showLoader(false);
        if (data[this.$v.API_SUCCESS]) {
          if (data.row["calculation"]) {
            this.dataCalculation = data.row;
            this.clientCalculations = data.row["calculation"];
            this.calculos = this.clientCalculations;
            this.existCalculator = true;
            this.$forceUpdate();
          }
        } else {
            switch (data[this.$v.API_REASON]) {
              case 1:
                this.clientCalculations = null;
                this.createCalculation = true;
                break;
              default:
                this.$vs.notification(this.$notification('danger'))
                break;
            }
          }
      });
    },
    applyAuditHigh(user,option){
      this.$showLoader(true)
      this.auditTableData.documents = option;
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.SET_AUDITOR_USERS;
      let request={
        user:user,
        documents:option
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false)
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification('success'))
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification('danger'))
        }
      });
    },
    sendDocComments(){
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.SEND_COMMENTS_AUDIT_SELLERS;
      let request  = {
        consultant: this.auditTableData.consultant_id,
        user: this.auditTableData.id,
        comments: 0
      }
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification('success'))
        } else {
          this.$vs.notification(this.$notification('danger'))
        }
      });
    },
     getUserAccounts(page) {
      let limit = this.accountPagination.perPage;
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_ACCOUNTS;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          let a = data[this.$v.API_ROWS];
          for (let i = 0; i < a.length; i++) {
            for (let j = 0; j < this.banks.length; j++) {
              if (this.banks[j].id === a[i].bank) {
                a[i].bank = this.banks[j];
                break;
              }
            }
          }
          for (let i = 0; i < a.length; i++) {
            for (let j = 0; j < this.productType.length; j++) {
              if (this.productType[j].id === a[i].product_type) {
                a[i].product_type = this.productType[j];
                break;
              }
            }
          }
          this.accountTableData = a;
          this.accountPagination.total = p["totalItems"];
          this.accountPagination.currentPage = page;
          this.accountPagination.perPage = limit;
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    uploadFinamigoFile(type) {
      console.log(type)
    if (this.files[type] && !this.files["upload_" + type]) {
        this.$showLoader(true);
        let url = this.$v.SERVER + this.$v.SELLER_OPS.UPLOAD_FILE_S3_FINAMIGO;
        this.$axios
          .post(url, {
            user: this.client.id,
            type: type,
          })
          .then((response) => {
            this.$showLoader(false);
            let data = response.data;
            if (data[this.$v.API_SUCCESS]) {
              this.$vs.notification(this.$notification(
                'success',
                 'Actualización exitosamente'
                     ));
              this.$forceUpdate();
              this.getFinamigoFiles();
            } else {
              this.$vs.notification(this.$notification('danger'))
            }
          });
      }else if (!this.files[type] && !this.files["upload_" + type]) {
        console.log(this.files[type])
        //this.createDBMenosContract();
      }
    },
    createDBMenosContract() {
      let clientId = this.$route.query.client;
      this.$showLoader(true);
      let url = this.$v.SERVER + this.$v.SELLER_OPS.CREATE_DBM_CONTRACT;
      this.$axios.post(url, { user: clientId }).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.getUserFiles(1);
          this.getClientProfile(this.client.id);
        }
      });
    },
   iconoFinAmigo(icono, status) {
      let color = null;
      switch (Number(status)) {
        case 1: color = "text-success"; break
        case 2: color = "text-info"; break
        default: color = "text-danger";
      } return icono + " " + color;
    },
    formatDecPost(val, pos) {
      if (!pos) pos = 2;
      if (val && Number(val)) return Number(val).toFixed(2)
      return "0.00";
    },
    formatDecPostFour(val, pos) {
      if (!pos) pos = 4;
      if (val && Number(val)) return Number(val).toFixed(4)
      return "0.0000";
    },
    getFinamigoFiles() {
      let url =
        this.$v.SERVER +
        this.$v.OPS.GET_USER_FINAMIGO_FILES +
        "?user=" +
        this.client.id;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.files = response.data[this.$v.API_ROW];
          this.$forceUpdate();
        }
      });
    },
    getFileUrl(userFile, user) {
      let session = JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.SESSION))
      if (session) {
        return this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_FILE + "?user=" + user + "&filename=" +
            userFile + "&redirect=true" + "&session=" + session[this.$v.SESSION]
      }
    },
    loadClient() {
      if (!this.client.hasOwnProperty("id")) this.edit = true;
      else {
        if (this.client && this.client.gender !== undefined) {
          if (this.client.gender === "M") this.gender = "Masculino";
          else if (this.client.gender === "F") this.gender = "Femenino";
        }
        if (this.client.civil_status) {
          if (this.client.civil_status === "M")
            this.client.civil_status = "Casado";
          else if (this.client.civil_status === "S")
            this.client.civil_status = "Soltero";
        }
        if (this.client.state_of_birth) {
          for (let i = 0; i < this.mxStates.length; i++) {
            let st = this.mxStates[i];
            if (this.client.state_of_birth === st.code) {
              this.stateOfBirth = st.state;
              break;
            }
          }
        }
        this.locality = this.client.locality;
      }
    },
     loadContract() {
      let url =
        this.$v.SERVER +
        this.$v.SERVICE_OPS.GET_CONTRACT +
        "?id=" +
        this.client.contract;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.contract = data[this.$v.API_ROW];
          this.commissionPercentage =
            data[this.$v.API_ROW].commission_percentage;
          this.effective_commission =
            data[this.$v.API_ROW].effective_commission;

        let finishProgram = this.$moment(this.contract.first_payment).format('YYYY-MM-DD')
        let newFinishProgram=this.$moment(finishProgram).add(Number(this.client.program_duration),'M').format('YYYY-MM-DD');
        this.finishProgramUser = this.$moment(newFinishProgram).format('YYYY-MM-DD');
            this.$forceUpdate();
        }
      });
    },
    getClientProfile(clientId) {
      return new Promise((resolve) => {
        let url =
          this.$v.SERVER + this.$v.SELLER_OPS.GET_PROFILE + "?id=" + clientId;
        this.$axios.get(url).then((response) => {
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.client = response.data[this.$v.API_ROW];
            this.loadContract();
            this.loadClient();
            this.getUserAccounts(1);
            this.$forceUpdate();
            resolve(true);
          }
          resolve(false);
        });
      });
    },
   async getAuditData(userID){
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_VIEW_USER_AUDITOR
      this.$showLoader(true);
      await this.$axios.post(url, {user: userID}).then((response) => {
        let data = response.data;
        if (data.success) {
        this.$showLoader(false);
          console.log('Hola')
          this.auditTableData = data[this.$v.API_ROWS];
          //console.log(this.auditTableData)
          this.fileIF = this.getFileUrl(this.auditTableData.filename_IF,userID)
          this.fileIR = this.getFileUrl(this.auditTableData.filename_IR,userID)
          //this.fileCD = this.getFileUrl(this.auditTableData.filename_cd,userID)
          this.fileCURP = this.getFileUrl(this.auditTableData.filename_curp,userID)
          this.filePROG = this.getFileUrl(this.auditTableData.filename_quote,userID)
          this.fileC1 = this.getFileUrl(this.auditTableData.filename_contract,userID)
          //this.fileC2 = this.getFileUrl(this.auditTableData.filename_contract_fa,userID)
          //this.fileCA = this.getFileUrl(this.auditTableData.filename_manifest_fa,userID)
          this.fileNominalList = this.getFileUrl(this.auditTableData.filename_nominal_list,userID)
          this.getCreditReport(userID)
          if(this.fileIF){
            let typeIF=[];
            typeIF=this.fileIF.split(".pdf");
            if(typeIF.length>1){
              this.isPdfIF = true;
            }
          }
          if(this.fileIR){
            let typeIR=[];
            typeIR=this.fileIR.split(".pdf");
            if(typeIR.length>1){
              this.isPdfIR = true;
            }
          }
          /*
          if(this.fileCD){
            let typeCD=[];
            typeCD=this.fileCD.split(".pdf");
            if(typeCD.length>1){
              this.isPdfCD = true;
            }
          }
          */
          if(this.fileCURP){
            let typeCURP=[];
            typeCURP=this.fileCURP.split(".pdf");
            if(typeCURP.length>1){
              this.isPdfCURP = true;
            }
          }
          if(this.filePROG){
            let typePROG=[];
            typePROG=this.filePROG.split(".pdf");
            if(typePROG.length>1){
              this.isPdfPROG = true;
            }
          }
          if(this.fileC1){
            let typeC1=[];
            typeC1=this.fileC1.split(".pdf");
            if(typeC1.length>1){
              this.isPdfC1 = true;
            }
          }
          /*
          if(this.fileC2){
            let typeC2=[];
            typeC2=this.fileC2.split(".pdf");
            if(typeC2.length>1){
              this.isPdfC2 = true;
            }
          }
          if(this.fileCA){
            let typeCA=[];
            typeCA=this.fileCA.split(".pdf");
            if(typeCA.length>1){
              this.isPdfCA = true;
            }
          }
          */
          if(this.fileNominalList){
            let typeNominal=[];
            typeNominal=this.fileNominalList.split(".pdf");
            if(typeNominal.length>1){
              this.isPdfNominal = true;
            }
          }
          this.forceUpdate++;
          this.ckecksButtons();
        }

        this.$showLoader(false);
      });
    },
    getCatalogType(type){
      switch (type){
        case 'IF':
          this.catalogIF = this.auditorCatalog.IF;
          break;
        case 'IR':
          this.catalogIR = this.auditorCatalog.IR;
          break;
        case 'RFC':
          this.catalogRFC = this.auditorCatalog.RFC;
          break;
          /*
        case 'CD':
          this.catalogCD = this.auditorCatalog.CD;
          break;
          */
        case 'RC':
          this.catalogRC = this.auditorCatalog.RC;
          break;
        case 'CURP':
          this.catalogCURP = this.auditorCatalog.CURP;
          break;
        case 'PROG':
          this.catalogPROG = this.auditorCatalog.PROG;
          break;
        case 'C1':
          this.catalogC1 = this.auditorCatalog.C1;
          break;
          /*
        case 'C2':
          this.catalogC2 = this.auditorCatalog.C2;
          break;
        case 'CA':
          this.catalogCA = this.auditorCatalog.CA;
          break;
          */
        case 'HIGH':
          this.catalogHIGH = this.auditorCatalog.HIGH;
          break;
      }
    },
    /*async getFinamigoBalance() {
      let url = this.$v.SERVER + this.$v.OPS.GET_FINAMIGO_BALANCE + "?user="+this.client.user;
      this.$showLoader(true);
      await this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.client.finamigoBalance = data.row.balance;
          this.$forceUpdate();
        }
      });
      this.showFinBalance = true;
    },*/

    //Buró de crédito
    getCreditReport(userId) {
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_CREDIT_REPORT + "?user=" + userId
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.report = data.row.credit_report ? data.row.credit_report : data.row.accounts;
          this.messageReport = data.row.message ? data.row.message: data.row.report.status;
          this.lengthsCreditReports.dbmAccounts = this.report.dbmAccounts.length;
          this.lengthsCreditReports.otherAccounts = this.report.otherAccounts.length;
        }
      });
    },
    getMessageReport(message){
      if(!message) return "-"
      if(message.length>50) return "Contactar con soporte"
      return message
    },

    onCopy(){
      this.$vs.notification(this.$notification(
                'info',
                 'Enlace copiado en portapapeles'
                     ));
    },
  }
};
</script>
