<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''" :style="{cursor:pointer2}">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button type="primary" size="sm" v-on:click="onNewItem">Nuevo</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                <template slot="columns">
                  <th :style="{cursor:pointer2}">Tipo de Documento</th>
                  <th :style="{cursor:pointer2}">Enlace de documento</th>
                  <th :style="{cursor:pointer2}">Comentarios</th>
                  <th :style="{cursor:pointer2}">Fecha de carga</th>
                  <th v-if="isAdmin || isManagerService || isAuditor" :style="{cursor:pointer2}">Creador</th>
                </template>

                <template slot-scope="{row}" v-if="row.type !== 'SETTLEMENT_SIGNATURE'">
                    <th scope="row">
                        <div class="media align-items-center" :style="{cursor:pointer2}">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{getFileType(row.type)}}</span>
                            </div>
                        </div>
                    </th>
                    <td>
                        <div class="d-flex align-items-center">
                            <span><a :href="getFileUrl(row)" target="_blank">{{row.filename}}</a></span>
                        </div>
                    </td>
                    <td :title="row.comments">{{getString(row.comments)}}</td>
                    <td :style="{cursor:pointer2}">{{$formatNormalDate(row.created)}}</td>
                    <td v-if="isAdmin || isManagerService || isAuditor" :style="{cursor:pointer2}">{{getConsultantName(row.upload_by)}}</td>
                </template>

            </base-table>
        </div>

        <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'clients-table',
        components: {
        },
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            client: Number,
            buttonSendSettlementLetterFA: Boolean,
        },
        data() {
            return {
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                isAdmin: false,
                isSeller: false,
                isService: false,
                isAuditor: false,
                isManagerService: false,
            }
        },
        computed: {
            banks() { return this.$store.state.banks },
            user() {return this.$getLS(this.$v.STORE_KEYS.USER);},
            customerService() { return this.$store.state.customerService },
            managerService() { return this.$store.state.managerService },
            admins() {return this.$store.state.admins },
            audits() {return this.$store.state.audits },
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
            this.isSeller = this.user.role === this.$v.ROLES.SELLER;
            this.isService = this.user.role === this.$v.ROLES.SERVICE;
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
            this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
        },
        methods: {
            getFileType(type) {
                switch (type) {
                    case 'FILE': return "Otro"
                    case 'SETTLEMENT_VOUCHER': return "Ficha de Liquidacion"
                    case 'SETTLEMENT_SIGNED': return "Carta de Liquidación Firmada"
                    case 'AGREEMENT': return "Convenio"
                    case 'PARTIAL_SETTLEMENT_VOUCHER': return "Ficha de Pago Parcial de Liquidación"
                    case 'SETTLEMENT_SIGNATURE': return "Firma de Carta de Liquidación"
                    case 'NO_DEBT_VOUCHER': return "Comprobante de NO Adeudo"
                }
                return ""
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            sendSettlementLetterFA(){
            this.$emit("sendSettlementLetterFA")
            },
            onNewItem() {
                this.$emit("onNewItem")
            },
            getFileUrl(userFile) {
                let session = JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.SESSION))
                if (session) {
                    return this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_FILE + "?user=" + this.client + "&filename=" +
                        userFile.filename + "&redirect=true" + "&session=" + session[this.$v.SESSION]
                }
            },
            getConsultantName(cid) {
                if(cid==='SITE') return cid;
                let i = 0;
                for (i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id == cid) return this.customerService[i].name + " " + this.customerService[i].lastname_1
                for (i=0 ; i<this.managerService.length ; i++)
                    if (this.managerService[i].id == cid) return this.managerService[i].name + " " + this.managerService[i].lastname_1
                for (i=0 ; i<this.admins.length ; i++)
                    if (this.admins[i].id == cid) return this.admins[i].name + " " + this.admins[i].lastname_1
                for (i=0 ; i<this.audits.length ; i++)
                    if (this.audits[i].id == cid) return this.audits[i].name + " " + this.audits[i].lastname_1
                return "- - -"
            },
            getString(s){
              if(!s) return '---'
              let comment = s.slice(0, 30)
              if (s.length>30) comment+='...';
              return comment
            },
        }
    }
</script>
<style>
</style>
