<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">

                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button v-on:click="uploadUpdates()">Guardar</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">

                <template slot="columns">
                    <th class="text-center" v-on:click="selectColumn(0)" v-bind:style="{cursor:pointer2}">ID Cuenta<i :class="getIconClass(0)" style="margin-left: 6px"/></th>
                    <th class="text-center" v-on:click="selectColumn(1)" v-bind:style="{cursor:pointer2}">ID Cliente<i :class="getIconClass(1)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(2)" v-bind:style="{cursor:pointer2}">Cliente<i :class="getIconClass(2)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(3)" v-bind:style="{cursor:pointer2}">banco<i :class="getIconClass(3)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(4)" v-bind:style="{cursor:pointer2}">MDI<i :class="getIconClass(4)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(5)" v-bind:style="{cursor:pointer2}">Cuenta<i :class="getIconClass(5)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(6)" v-bind:style="{cursor:pointer2}">Adeudo<i :class="getIconClass(6)" style="margin-left: 6px"/></th>
                    <th v-on:click="selectColumn(7)" v-bind:style="{cursor:pointer2}">Se liquida con<i :class="getIconClass(7)" style="margin-left: 6px"/></th>
                    <th class="text-center" v-bind:style="{cursor:pointer2}">Descuento</th>
                    <th class="text-center" v-bind:style="{cursor:pointer2}">Por subir</th>
                </template>

                <template slot-scope="{row}">
                    <td class="text-center">{{row.account_id}}</td>
                    <th class="text-center" scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <a :href="'https://admin.dbmenos.com/client?id='+row.id" target="_blank">
                                    <span class="name mb-0 text-sm">{{row.id}}<i class="fas fa-user-edit text-primary"></i></span>
                                </a>
                            </div>
                        </div>
                    </th>
                    <td>{{row.name}}</td>
                    <td>{{getBank(row.bank).name}}</td>
                    <td>{{$formatCurrency(row.total_debt)}}</td>
                    <td>{{row.account}}</td>
                    <td>{{$formatCurrency(row.amount)}}</td>
                    <td>
                        <input class="text-center" v-model="row.discount"/>
                    </td>
                    <td class="text-center">{{(100 - (1 / row.amount * row.discount * 100)).toFixed(2)}} %</td>
                    <td class="text-center"><i v-if="isUpdated(row)" class="fas fa-cloud-upload-alt" style="color: #3953D3"/></td>
                    <!--<td class="text-center">{{$moment(row.created).format("DD MMM, YYYY")}}</td>-->

                </template>

            </base-table>
        </div>
        <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} Cuentas
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'clients-table',
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            banks() { return this.$store.state.banks },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService },
        },
        data() {
            return {
                isAdmin: false,
                currentColumnKey: null,
                sortedColumn: 0,
                freezeData: null,
                sortingDesc: true,
                pointer: 'pointer',
                pointer2: 'default'
            }
        },
        updated() {
            if (!this.freezeData && this.data) {
                this.freezeData = JSON.parse(JSON.stringify(this.data))
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
        },
        methods: {
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            uploadUpdates() {
                this.$showLoader(true)
                let toUpload = []
                for (let i=0 ; i<this.data.length ; i++) {
                    let acc = this.data[i]
                    if (this.isUpdated(acc)) toUpload.push(acc)
                }
                console.log(toUpload)
                let url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_ACCOUNTS
                this.$axios.post(url, { accounts: toUpload}).then(response => {
                    this.$showLoader(false)
                    let data = response.data
                    if (data[this.$v.API_SUCCESS]) {
                        this.freezeData = JSON.parse(JSON.stringify(this.data))
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
                    } else {
                        this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    }
                })
            },
            sort() {
                switch (this.sortedColumn) {
                    case 0:
                        this.currentColumnKey = 'account_id'
                        break
                    case 1:
                        this.currentColumnKey = 'id'
                        break
                    case 2:
                        this.currentColumnKey = 'name'
                        break;
                    case 3:
                        this.currentColumnKey = 'bank'
                        break
                    case 4:
                        this.currentColumnKey = 'total_debt'
                        break
                    case 5:
                        this.currentColumnKey = 'account'
                        break
                    case 6:
                        this.currentColumnKey = 'amount'
                        break
                    case 7:
                        this.currentColumnKey = 'discount'
                        break
                    case 8:
                        this.currentColumnKey = ''
                        break

                }
                console.log(this.currentColumnKey)
                this.data.sort((a, b) => {
                    let _a = a[this.currentColumnKey]
                    let _b = b[this.currentColumnKey]
                    if (_a < _b) return this.sortingDesc ? -1 : 1
                    if (_a > _b) return this.sortingDesc ? 1 : -1
                    return 0
                })
                console.log(this.data[0])
            },
            selectColumn(column) {
                console.log(column + " selected")
              if (this.sortedColumn === column) {
                  this.sortingDesc = !this.sortingDesc
              } else {
                  this.sortedColumn = column
                  this.sortingDesc = true
              }
              if (this.sortedColumn != null)
                this.sort()
            },
            isUpdated(acc) {
                if (this.freezeData === null) return false
                let a = this.freezeData.find(a => a['account_id'] === acc['account_id'])
                if (a && a.discount)
                    return a.discount !== acc.discount
                return false
            },
            getIconClass(column) {
                let iconClass = "fas "
                if (column === this.sortedColumn) {
                    iconClass += this.sortingDesc ? "fa-arrow-down" : "fa-arrow-up"
                    iconClass += " text-danger"
                } else {
                    iconClass += "fa-arrows-alt-v"
                }
                return iconClass
            },
            getCustomerFilterText() {
                if (this.consultantFilter) return this.getConsultantName(this.consultantFilter)
                return "Consultores"
            },
            getConsultantFilterText() {
                if (this.selfClients) return 'Solo mis clientes'
                return "Clientes sin asignar"
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            },
            getBank(id) {
                return this.banks.find((b) => b.id === id)
            }
        }
    }
</script>
<style>
</style>
