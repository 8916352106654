<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button v-if="!(showData || showisService) && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="showAddendums"></base-button>
                    <base-button v-if="(showData || showisService)" type="primary" size="sm" v-on:click="onNewItem">Nuevo</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        v-on:click="addendumSelected">

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">ID</th>
                    <th v-bind:style="{cursor:pointer2}">Estatus</th>
                    <!--<th v-bind:style="{cursor:pointer2}">Archivo</th>!-->
                    <th v-bind:style="{cursor:pointer2}">Ahorro mensual</th>
                    <th v-bind:style="{cursor:pointer2}">Comisión mensual</th>
                    <th v-bind:style="{cursor:pointer2}">Fee</th>
                    <th v-bind:style="{cursor:pointer2}">Periodo</th>
                    <th v-bind:style="{cursor:pointer2}">Creado por</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha de creación</th>
                </template>

                <template v-if="(showData || showisService)" slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-primary text-sm">{{row.id}} <!--i v-if="!unassigned" :class="getIconValidatedClass(row.contract > 0)"></i--></span>
                            </div>
                        </div>
                    </th>
                    <td >{{row.status ? getStatusAddendum(row.status):getStatusAddendum(5)}}</td>
                    <!--
                    <td>
                        <div class="d-flex align-items-center">
                            <span><a target="_blank">Ver</a></span>
                        </div>
                    </td>
                    !-->
                    <td>{{$formatCurrency(row.monthly_savings)}}</td>
                    <td>{{$formatCurrency(row. monthly_commission)}}</td>
                    <td>{{formatDecPost(row.fee)}} %</td>
                    <td >{{row.months ? `${row.months} Meses`:'- - -'}}</td>
                    <td>{{getConsultantName(row.upload_by)}}</td>
                    <td>{{$formatDate(row.created)}}</td>

                </template>

            </base-table>
        </div>

        <div v-if="(pagination.total > pagination.perPage) && showData" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'clients-table',
        props: {
            type: {
                type: String
            },
            title: String,
            showisService: Boolean,
            amount: Number,
            data: Array,
            pagination: Object,
            showEye: Boolean,
        },
        data() {
            return {
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                showData:false,
                isService:false
            }
        },
        computed: {
            banks() { return this.$store.state.banks },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService },
            managerService() { return this.$store.state.managerService },
            admins() {return this.$store.state.admins },
            statusAddendums() {return this.$store.state.statusAddendums},
            user() { return this.$getLS(this.$v.STORE_KEYS.USER); },
        },
        created() {
          this.isService = this.user.role === this.$v.ROLES.SERVICE;
          if(this.isService) this.showData=true;
        },
        methods: {
            formatDecPost(val, pos) {
      if (!pos) pos = 2;
      if (val && Number(val)) return Number(val).toFixed(pos);
      return "0.00";
    },
            getConsultantName(cid) {
                for (let i=0 ; i<this.customerService.length ; i++) {
                    if (this.customerService[i].id === cid) {
                        return this.customerService[i].name + " " + this.customerService[i].lastname_1
                    }
                }
                for (let i=0 ; i<this.managerService.length ; i++) {
                    if (this.managerService[i].id === cid) {
                        return this.managerService[i].name + " " + this.managerService[i].lastname_1
                    }
                }
                for (let i=0 ; i<this.admins.length ; i++) {
                    if (this.admins[i].id === cid) {
                        return this.admins[i].name + " " + this.admins[i].lastname_1
                    }
                }
                for (let i=0 ; i<this.sellers.length ; i++) {
                    if (this.sellers[i].id === cid) {
                        return this.sellers[i].name + " " + this.sellers[i].lastname_1
                    }
                }
                return "- - -"
            },
            showAddendums(){
                this.showData = true;
                this.$emit("showAddendums")
            },
            getStatusAddendum(id) {
                for (let i=0 ; i<this.statusAddendums.length ; i++) {
                    if (this.statusAddendums[i].id === id) {
                        return this.statusAddendums[i].status
                    }
                }
            },
            addendumSelected(add) {
                this.$emit("addendumSelected", add);
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            onNewItem() {
                this.$emit("onNewItem")
            }
        }
    }
</script>
<style>
</style>