<template>
  <div>
    <base-header type="gradient-dbm" class="pb- pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--9">
      <div class="row justify-content-center">
        <card style="height: auto">
          <div class="col" :style="{ cursor: pointer2 }">
          <div class="row" style="margin-top: 10px; margin-bottom: 20px">
            <div class="col">
              <div class="btn-wrapper">
                <base-button
                  tag="a"
                  v-on:click="backWindows()"
                  class="mb-3 mb-sm-0 text-white"
                  type="danger"
                  icon="fas fa-arrow-left"
                  >Regresar</base-button
                >
              </div>
            </div>
          </div>
            <div class="row">
              <div class="col-7"></div>
              <div class='col-lg-5 text-center' v-if="(isAdmin || isManagerService) && tokenAddendum">
                <a :href="tokenAddendum" target="_blank">
                  <span class="name mb-0 text-sm">{{tokenAddendum}}</span>
                  </a><br>
                  <span class="description">Enlace para firma <i title="Click para copiar enlace al portapapeles" v-clipboard:copy="tokenAddendum" class="fas fa-file-signature text-primary"></i></span>
                  </div>
              <div class="col-12">
                <addendum-details-table
                    title="Addendums"
                    :data="addendumTableData"
                    :lecturyAdd="lectury"
                    :pagination="addendumPagination"
                    @onNewItem="setTraceType"
                    @pageSelected="getAddendumDetails"
                    style="margin-top: 20px"/>
              </div>
            </div>

            <div class="col-12" v-if="newTrace.id">
              <accounts-table
                    title="Cuentas Addendum"
                    :addendumType="newTrace.id"
                    :addendumAccFormat="true"
                    :data="accountTableData"
                    :pagination="accountPagination"
                    @pageSelected="getUserAccounts"
                    @onNewItem="addNewAccount"
                    @deleteAccount="deleteAccount"
                    @accountSelected="accountSelected"
                    style="margin-top: 20px"/>
            </div>
            <div class="col-12" v-if="newTrace.id">
          <debts-table
            v-if="isService || isManagerService || isAdmin"
            title="Deudas"
            :data="debtTableData"
            :pagination="debtPagination"
            style="margin-top: 20px"
          />
            </div>
            <section
      v-if="lectury"
      style="margin-top:2rem;"
    >
    
                <hr>
      <div class="container">
                  <div class="container shape-container d-flex" style="margin-top: 1rem; margin-bottom:1rem">
                    <div class="col px-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <h1 class="display-3 text-black text-center">
                            Programa DB Menos
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
           <div class="row justify-content-center">
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Total adeudado
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >${{
                                    formatDecPost(calculos.totalDebt)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-red text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-money-bill"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6 class="card-title text-uppercase text-muted mb-0">
                                  Ahorros
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >${{
                                    formatDecPost(calculos.savings)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-success text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-dollar-sign"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

         <div class="row justify-content-center">

                       <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Porcentaje de Comisión
                                </h6>
                                <span class="h2 font-weight-bold mb-0">{{
                                    formatDecPost4(calculos.realfee)
                                  }}%</span>
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-yellow text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-percentage"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Comisión Mensual
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >${{
                                    formatDecPost(calculos.commission)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-pink text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-money-bill-wave"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row justify-content-center">
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Duración del programa
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >{{ calculos.period }} meses</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-info text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Depósito mensual
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >${{
                                    formatDecPost(
                                      calculos.monthlyFee
                                        ? calculos.monthlyFee
                                        : calculos.monthlyFee
                                    )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-blue text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-coins"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Descuento estimado
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >{{
                                    formatDecPost(calculos.discount)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}%</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-warning text-white rounded-circle shadow"
                                >
                                  <i class="fa fa-percent"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Deudas
                                </h6>
                                <span class="h2 font-weight-bold mb-0">{{
                                  calculos && calculos.debts
                                    ? calculos.debts.length
                                    : "0"
                                }}</span>
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-indigo text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-check"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Saldo Actual del Mes
                                </h6>
                                <span class="h2 font-weight-bold mb-0">${{
                                    formatDecPost(
                                      calculos.balanceActual
                                    )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span>
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-success text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-balance-scale"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Primer Pago Addendum
                                </h6>
                                <span class="h2 font-weight-bold mb-0">${{
                                    formatDecPost(
                                      calculos.firstSave
                                      
                                    )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span>
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-info text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-file-invoice-dollar"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <hr />

          <div
            v-if="calculos.calculator_new"
            class="row justify-content-center text-center"
          >
            <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card shadow">
                          <div class="card-header border-0">
                            <h3 class="mb-0">Deudas a liquidar</h3>
                          </div>
                          <div class="table-responsive">
                            <table class="table align-items-center table-flush">
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center" scope="col">Deuda</th>
                                  <th class="text-center" scope="col">
                                    Acreedor
                                  </th>
                                  <th class="text-center" scope="col">
                                    Cuenta
                                  </th>
                                  <th class="text-center" scope="col">
                                    Tipo de Producto
                                  </th>
                                  <th class="text-center" scope="col">
                                    Deuda sin dbm
                                  </th>
                                  <th class="text-center" scope="col">
                                    Descuento
                                  </th>
                                  <th class="text-center" scope="col">
                                    Valor a pagar con dbm
                                  </th>
                                  <th class="text-center" scope="col">
                                   Liq. esperada S/Comisión
                                  </th>
                                  <th class="text-center" scope="col">
                                    Fecha del Último Pago
                                  </th>
                                  <th class="text-center" scope="col">
                                    Mes a pagar
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(debt, index) in calculos.debts"
                                  :key="index"
                                >
                                  <td class="text-center">{{ index + 1 }}</td>
                                  <td class="text-center">{{ debt.bank }}</td>
                                  <td class="text-center">{{ debt.account }}</td>
                                 <td class="text-center">{{ getProductType(debt.product_type.id) }}</td>
                                  <td class="text-center">
                                    ${{
                                      formatDecPost(debt.amount)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                  </td>
                                  <td class="text-center">
                                    ${{
                                      formatDecPost(debt.amount - debt.willPay)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                  </td>
                                  <td class="text-center">
                                    ${{
                                      formatDecPost(debt.willPay)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                  </td>
                                  <td class="text-center">
                                    ${{
                                      formatDecPost(debt.willPayWOFee)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                  </td>
                                  <td class="text-center">
                                    {{ formDate(debt.last_bank_payment_date) }}
                                  </td>
                                  <td class="text-center">
                                    {{
                                      debt.willPayOnMonth
                                        ? debt.willPayOnMonth
                                        : debt.willPayOnMonth
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
          </div>
      </div>
    </section>

            <div style="text-align: center; margin-top: 1rem" v-if="newTrace.id && newTrace.id != 3">
              <base-button v-if="newTrace.id !== 3" type="info" v-on:click="showCalcAddendum()">
                Calculadora
              </base-button>
            </div>
          </div>
          <div v-if="calcAddModal" class="container" style="max-width: 100% !important">
            <card style="margin-top: 1rem; border-radius: 2rem" class="col-12">
              <div class="row">
                <div class="col">
                  <div class="container shape-container d-flex" style="margin-top: 10px">
                    <div class="col px-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <h1 class="display-3 text-black text-center">
                            Programa DB Menos
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container" style="max-width: 80% !important">
                    <div class="row justify-content-center" style="margin-bottom: 0.5rem">
                      <div class="col-lg-12">
                        <div class="row justify-content-center">
                          <div class="col-lg-12">
                            <card class="border-0">
                              <div class="row justify-content-center">
                                <div class="col text-center">
                                  <h1 class="h1 font-weight-bold mb-0 text-success" style="font-size: 2rem">
                                    {{periodOptimal}} meses
                                  </h1>
                                  <h4 class="card-title text-uppercase text-muted mb-0 margin-blue-h6">
                                    Duración del Programa Recomendado
                                  </h4>
                                  <br />
                                  <base-slider
                                    :range="{ min: minSlider, max: maxSlider }"
                                    :options="{ step: 1 }"
                                    v-model.number="period"
                                    v-on:input="m_ReturnDiscountNew()"
                                  ></base-slider>
                                  <h3 class="text-primary text-center">
                                    <h1
                                      class="text-primary"
                                      style="font-size: 1.5rem"
                                    >
                                      {{ period }} meses
                                    </h1>
                                    <h4
                                      class="text-primary card-title text-uppercase text-muted mb-0 margin-blue-h6"
                                    >
                                      Duración del Programa
                                    </h4>
                                  </h3>
                                </div>
                              </div>
                            </card>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Total adeudado
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >${{
                                    formatDecPost(calculatorDebts.totalDebt)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-red text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-money-bill"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6 class="card-title text-uppercase text-muted mb-0">
                                  Ahorros
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >${{
                                    formatDecPost(calculatorDebts.savings)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-success text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-dollar-sign"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                     <div class="row justify-content-center">

                       <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Porcentaje de Comisión
                                </h6>
                                <span class="h2 font-weight-bold mb-0">{{
                                    formatDecPost4(calculatorDebts.realfee)
                                  }}%</span>
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-yellow text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-percentage"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Comisión Mensual
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >${{
                                    formatDecPost(calculatorDebts.commission)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-pink text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-money-bill-wave"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row justify-content-center">
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Duración del programa
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >{{ calculatorDebts.period }} meses</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-info text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Depósito mensual
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >${{
                                    formatDecPost(
                                      calculatorDebts.monthlyFee
                                        ? calculatorDebts.monthlyFee
                                        : calculatorDebts.monthlyFee
                                    )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-blue text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-coins"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Descuento estimado
                                </h6>
                                <span class="h2 font-weight-bold mb-0"
                                  >{{
                                    formatDecPost(calculatorDebts.discount)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}%</span
                                >
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-warning text-white rounded-circle shadow"
                                >
                                  <i class="fa fa-percent"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Deudas
                                </h6>
                                <span class="h2 font-weight-bold mb-0">{{
                                  calculatorDebts && calculatorDebts.debts
                                    ? calculatorDebts.debts.length
                                    : "0"
                                }}</span>
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-indigo text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-check"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Saldo Actual del Mes
                                </h6>
                                <span class="h2 font-weight-bold mb-0">${{
                                    formatDecPost(
                                      calculatorDebts.balanceActual
                                    )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span>
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-success text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-balance-scale"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-5">
                        <div class="card card-stats mb-4 mb-xl-2">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6
                                  class="card-title text-uppercase text-muted mb-0"
                                >
                                  Primer Pago Addendum
                                </h6>
                                <span class="h2 font-weight-bold mb-0">${{
                                    formatDecPost(
                                      calculatorDebts.firstSave
                                      
                                    )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }}</span>
                              </div>
                              <div class="col-auto">
                                <div
                                  class="icon icon-shape bg-info text-white rounded-circle shadow"
                                >
                                  <i class="fas fa-file-invoice-dollar"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <hr />
                    <div
                      class="row justify-content-center text-center"
                      style="margin-bottom: 1rem"
                    >
                      <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card shadow">
                          <div class="card-header border-0">
                            <h3 class="mb-0">Deudas a liquidar</h3>
                          </div>
                          <div class="table-responsive">
                            <table class="table align-items-center table-flush">
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center" scope="col">Deuda</th>
                                  <th class="text-center" scope="col">
                                    Acreedor
                                  </th>
                                  <th class="text-center" scope="col">
                                    Cuenta
                                  </th>
                                  <th class="text-center" scope="col">
                                    Tipo de Producto
                                  </th>
                                  <th class="text-center" scope="col">
                                    Deuda sin dbm
                                  </th>
                                  <th class="text-center" scope="col">
                                    Descuento
                                  </th>
                                  <th class="text-center" scope="col">
                                    Valor a pagar con dbm
                                  </th>
                                  <th class="text-center" scope="col">
                                   Liquidación esperada Sin Comisión
                                  </th>
                                  <th class="text-center" scope="col">
                                    Fecha del Último Pago
                                  </th>
                                  <th class="text-center" scope="col">
                                    Mes a pagar
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(debt, index) in calculatorDebts.debts"
                                  :key="index"
                                >
                                  <td class="text-center">{{ index + 1 }}</td>
                                  <td class="text-center">{{ debt.bank }}</td>
                                  <td class="text-center">{{ debt.account }}</td>
                                  <td class="text-center">{{ getProductType(debt.product_type.id) }}</td>
                                  <td class="text-center">
                                    ${{
                                      formatDecPost(debt.amount)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                  </td>
                                  <td class="text-center">
                                    ${{
                                      formatDecPost(debt.amount - debt.willPay)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                  </td>
                                  <td class="text-center">
                                    ${{
                                      formatDecPost(debt.willPay)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                  </td>
                                  <td class="text-center">
                                    ${{
                                      formatDecPost(debt.willPayWOFee)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }}
                                  </td>
                                  <td class="text-center">
                                    {{ formDate(debt.last_bank_payment_date) }}
                                  </td>
                                  <td class="text-center">
                                    {{
                                      debt.willPayOnMonth
                                        ? debt.willPayOnMonth
                                        : debt.willPayOnMonth
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </card>
          <div class="row justify-content-center text-center">
            <div class="col-4">
            <base-button style="background-color: #ffd527; border-color: #ffd527; color: black;" block class="mt-4" v-on:click="saveProposition()">
              Guardar y Envíar propuesta 
            </base-button>
            </div>
          </div>
          </div>
        </card>
      </div>
    </div>


    <modal :show.sync="accountModal" @close="closeAccountModal()" :large="true">
      <template slot="header">
        <div class="row align-items-center">
          <div class="col">
            <h2 class="mb-0" id="acountModal" style="margin-top: 20px; margin-left: 10px">
              {{ newAccount ? "Añadir cuenta" : "Editar cuenta" }}
            </h2>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-2" :style="{cursor: pointer}" 
          v-if="!newAccount">
          <base-input alternative input-classes="form-control-alternative"
            label="ID Cuenta:"
            disabled="true"
            v-if="!accountInModal.id"
            placeholder="Nueva"
            v-model="accountInModal.id"/>
            <base-input alternative input-classes="form-control-alternative"
            label="ID Cuenta:"
            disabled="true"
            v-if="accountInModal.id"
            v-model="accountInModal.id"/>
        </div>
      </div>
      <div class="row">
        <div class="col" :style="{cursor: pointer}">
          <base-dropdown>
            <base-button :disabled="newTrace.id!=1" slot="title" type="primary outline" class="dropdown-toggle" size="lg" style="margin-top: 10px; white-space: normal">
              {{accountInModal.bank? accountInModal.bank.name : "Selecciona el banco"}}
            </base-button>
            <a class="dropdown-item" v-for="(bank, index) in banks" :key="index" v-on:click="setBankInAccount(bank)">
              {{bank.name}}
            </a>
          </base-dropdown>
        </div>
        <div class="col">
          <base-input alternative input-classes="form-control-alternative"
           :disabled="newTrace.id!=1 && newTrace.id!=4"
            label="Número de cuenta"
            v-model="accountInModal.account"/>
        </div>
        <div class="col">
           <base-dropdown>
            <base-button :disabled="newTrace.id!=1" slot="title" type="success" class="dropdown-toggle">
              {{accountInModal.product_type ? accountInModal.product_type.type : "Selecciona Tipo Producto"}}
              </base-button>
              <a class="dropdown-item" v-for="(type, index) in productType" :key="index" v-on:click="setTraceProductType(type)">
                  {{type.type}}
               </a>
            </base-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input alternative input-classes="form-control-alternative"
           :disabled="newTrace.id!=1 && newTrace.id!=5"
            label="Total adeudado"
            v-model="accountInModal.amount"/>
        </div>
        <div class="col">
          <label class="form-control-label">Último pago bancario</label>
          <flat-pickr class="form-control form-control-alternative" data-vv-as="Fecha" name="date" :config="config" style="background-color: #ffffff"
           :disabled="newTrace.id!=1"
            v-model="accountInModal.last_bank_payment_date"/>
        </div>
        <div class="col">
          <dropbox-resize buttonText="Precargar comprobante" type="accountAddFile" v-on:uploadFile="uploadFile"/>
        </div>
      </div>

      <template slot="footer">
        <base-button type="primary" @click="updateAccount(accountInModal)">
          {{newAccount? 'Agregar':'Guardar'}}
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import AccountsTable from "./Tables/AccountsTable";
import DropboxResize from "../components/DropboxResize";
import AddendumDetailsTable from "./Tables/AddendumDetailsTable";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DebtsTable from "./Tables/DebtsTable";
export default {
  name: "tables",
  components: {
    AccountsTable,
    DropboxResize,
    flatPickr,
    DebtsTable,
    AddendumDetailsTable,
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    generalInfo() {
      return this.$store.state.generalInfo;
    },
    typeAddendums() {
      return this.$store.state.typeAddendums;
    },
    productType() { return this.$store.state.productType },
    banks() {
      return this.$store.state.banks;
    },
  },
  watch: {
    $route() {
      let userId = this.$route.query.user;
      if (userId > 0)
        this.getClientProfile(userId).then((found) => {
          if (!found) {
            this.$router.push("/clients");
          }
        });
    },
  },
  data() {
    return {
      calculos: {
        totalDebt: 0,
        savings: 0,
        mfee: 0,
        monthlyFee: 0,
        discount: 0,
        debts: [],
      },
      lectury:false,
      statusApproach:"",
      isAdmin: false,
      newTrace: {
        id: 0,
      },
      productsType:{
        id:0,
      },
      isService: false,
      isAuditor: false,
      isManagerService: false,
      program: null,
      unassigned: null,
      pagination: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
      accountTracePagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      period: {
        start: null,
        end: null,
      },
      clientCalculations: {
        totalDebt: 0,
        savings: 0,
        mfee: 0,
        monthlyFee: 0,
        discount: 0,
        debts: [],
      },
      client: {},
      tableData: [],
      searchQuery: null,
      clientFilter: null,
      consultantFilter: null,
      selfClients: true,
      MDIFilter: null,
      NominaFilter: null,
      NominaFilterClients: null,
      dueMonthsFilter: null,
      traceFilter: null,
      statusFilter: null,
      pointer: "pointer",
      pointer2: "default",
      programStep: null,
      textTypeClient: null,
      commission_pre:"",
      prevDel: false,
      isSeller: false,
      newActivityNegotiation: {},
      calcAddModal: false,
      debtTableData: [],
      debtPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      sliderRange: [],
      periodOptimal: "",
      maxSlider: "",
      minSlider: "",
      commissionPercentage: "",
      commissionLectury: "",
      monthly_commission:"",
      effective_commission: false,
      contract: {},
      addendumId: "",
      debts: [
        {
          bankid: 0,
          bank: "Selecciona el banco",
          amount: 0,
          edit: true,
          last_bank_payment_date: "",
        },
      ],
      config: {
        maxDate: "today",
        dateFormat: "Y-m-d ",
      },
      today: "",
      discount: {
        debts: [],
        totalDebt: 0,
        savings: 0,
        monthlyFee: 0,
        mfee: 0,
        discount: 0,
        o_progress: 0,
      },
      calculatorDebts: [
        {
          accounts: [
            {
              account: "",
              addProgram: false,
              amount: "",
              bank: "",
              bankid: "",
              discount: "",
              due_months: "",
              last_bank_payment_date: "",
              m: "",
              will_pay_on_month: "",
            },
          ],
          mfee: "",
          p: "",
          savings: "",
          totalDebt: "",
          willPay: "",
        },
      ],
      reportListo: false,
      calcDataPeriod: {
        period: 18,
      },
      notCredit: false,
      addendumTableData: [],
      buttonSignature:false,
      addendumPagination: {
        total: 0,
        perPage: 5,
        currentPage: 1,
      },
      newProgram:{},

      //Accounts
      accounts: [],
      accountTableData: [],
      accountInModal: {},
      accountModal: false,
      newAccount: false,
      accountPagination: {
        total: 0,
        perPage: 10,
        currentPage: 1,
      },
      filenameAccount: '',
      tokenAddendum:"",
    };
  },
  
  async created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isService = this.user.role === this.$v.ROLES.SERVICE || this.user.role === this.$v.ROLES.AUDITOR;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
    let userId = this.$route.query.user;
    if (userId > 0) {
      await this.getClientProfile(userId).then((found) => {
        if (!found) this.$router.push("/clients");
      });
    }
  },
  methods: {
   getUserDebts(page) {
      let limit = this.debtPagination.perPage;
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_SC_DEBT_ACCOUNTS;
      let request = {
        offset: limit * (page - 1),
        limit: limit,
        user: this.client.id,
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.debtTableData = data[this.$v.API_ROWS];
          this.debtPagination.total = p["totalItems"];
          this.debtPagination.currentPage = page;
          this.debtPagination.perPage = limit;
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    backWindows() {
      window.history.back();
    },
    getProgramCalculation(userId,addId) {
      let url =
        this.$v.SERVER +
        this.$v.SELLER_OPS.GET_PROGRAM_CALCULATION_ADD +
        "?user=" +
        userId+ "&type="+ addId;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          if (data.row["calculation"]) {
            this.dataCalculation = data.row;
            this.clientCalculations = data.row["calculation"];
            this.calculos = this.clientCalculations;
            console.log(this.calculos)
            this.createCalculation = false;
            this.$forceUpdate();
          }
        } else {
            switch (data[this.$v.API_REASON]) {
              case 1:
                this.clientCalculations = null;
                this.createCalculation = true;
                console.log(this.createCalculation)
                break;
              default:
                this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                break;
            }
          }
      });
    },
    uploadFile(obj) {
      let file = obj.file;
      this.filenameAccount = file.name;
      /*let updateUrl = this.$v.SERVER + this.$v.SERVICE_OPS.UPLOAD_ADDENDUM_FILE;
      let request = {
        user: this.client.id,
        addendum_id: this.addendumId,
        filename: file.name,
      }*/
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_PRESIGNED_PUT_OBJECT_URL + "?user=" + this.client.id + "&filename=" + file.name;
      console.log("S3File");
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let fileURL = data[this.$v.API_ROW];
          this.$showLoader(true);
          this.$axios.put(fileURL, file, { headers: {} }).then((putObjectResponse) => {
              this.$showLoader(false);
              if (putObjectResponse.status === 200) {
                this.$vs.notification(this.$notification(
                'success',
                 'Archivo precargado'
                     ));
              } else {
                this.$vs.notification(this.$notification(
                'warning',
                 'No se logró precargar',
                 'Intenta nuevamente'
                     ));
              }
            });
        } else {
          this.$vs.notification(this.$notification(
                'warning',
                 'No se logró cargar',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    saveProposition() {
      this.$showLoader(true)
      let userId = this.$route.query.user;
      let addId = this.$route.query.add;
      this.jsonCalculator = [];
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_ADDENDUMS_APPROACH;
      let url3 = this.$v.SERVER + this.$v.SERVICE_OPS.SET_PROGRAM_CALCULATOR_ADDENDUM;
      for (let p = 0; p < this.calculatorDebts.length; p++)
        if (this.period === this.calculatorDebts[p].period)
          this.jsonCalculator = JSON.parse(JSON.stringify(this.calculatorDebts[p]));
      let data = {
        id: addId,
        user: userId,
        monthly_savings: this.jsonCalculator.monthlyFee,
        monthly_commission : this.jsonCalculator.commission,
        fee: this.jsonCalculator.realfee,
        months: this.jsonCalculator.period,
        filename: this.filenameAccount,
        created: new Date()
      };
      //console.log(approachAdd)
      this.$axios.post(url, data).then((response) => {
        let data = response.data;
          this.$showLoader(false)
        if (data[this.$v.API_SUCCESS]) {
          if(this.newTrace.id === 3){
            let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_ADDENDUMS_DETAIL;
            let addDetails ={
              addendum_approach: addId,
              type_addendum: this.newTrace.id,
              new_saving: this.jsonCalculator.monthlyFee,
              total_debt: this.jsonCalculator.totalDebt,
            }
            //console.log(addDetails)
            this.$axios.post(url,addDetails).then((response) => {
              let data = response.data;
              if (data[this.$v.API_SUCCESS]) {
                this.$vs.notification(this.$notification(
                'success',
                 'Detalle de Addendum Añadido'
                     ));
                this.getAddendumDetails(1);
                this.$forceUpdate();
              }
            });
          }
          this.$axios.post(url3, { user: userId, calculation: this.jsonCalculator, type: addId}).then((response) => {
            let data = response.data;
            if (data[this.$v.API_SUCCESS]) {
              this.$vs.notification(this.$notification(
                'success',
                 'Calculadora Guardada'
                     ));
              this.$showLoader(true)
              let url4 = this.$v.SERVER + this.$v.SERVICE_OPS.REQUEST_ADDENDUM_SIGN;
        let dataSignature = {
          user: userId,
          addendum: addId,
        };
      //console.log(dataSignature)
      this.$axios.post(url4,dataSignature).then((response) => {
        let data4 = response.data;
          this.$showLoader(false)
        if (data4[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Enlace envíado para firmar'
                     ));
          
          this.getAddendum();
          this.getTokenAddendum();
          this.newTrace.id = 0;
          this.calcAddModal = false;
          this.newAccount = false;
        }else{
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
            }else{
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
          });
          this.$vs.notification(this.$notification(
                'success',
                 'Propuesta guardada exitosamente'
                     ));
        }
      });
    },
    getAddendum() {
      let userId = this.$route.query.user;
      let addId = this.$route.query.add;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_ADDENDUMS_APPROACH;
      let request = {
        user: userId,
        id: addId
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let add_app=data[this.$v.API_ROWS];
            this.statusApproach=add_app[0].status
          if(this.statusApproach >0){
            this.lectury = true;
            this.getProgramCalculation(userId,addId)
          }
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    getTokenAddendum() {
      let userId = this.$route.query.user;
      let addId = this.$route.query.add;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_TOKEN_ADDENDUM;
      let request = {
        user: userId,
        addendum: addId
      };
      this.$showLoader(true);
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          if(data[this.$v.API_ROW].isFulfilled){
            this.tokenAddendum = false;
          }else{
          this.tokenAddendum = this.$v.CLIENT_SERVER + this.$v.SIGNATURE_PATH + '?tkadd=' + data[this.$v.API_ROW].token;
          console.log(this.tokenAddendum)
          }
          this.$forceUpdate();
        } else {
          this.$validateSession(data)
          }
      });
    },
    m_editDebtNew(index, edit) {
      setTimeout(() => {
        this.debts[index].edit = edit;
      }, 500);

      this.$forceUpdate();
    },
    m_appendDebt() {
      this.debts.push({
        bankid: 0,
        bank: "Selecciona el banco",
        amount: 0,
        edit: true,
      });
    },
    m_spliceDebt(index) {
      if (this.debts.length <= 1) {
        this.$vs.notification(this.$notification(
                'warning',
                 'Se requiere al menos una cuenta para continuar'
                     ));
      } else {
        setTimeout(() => {
          this.debts.splice(index, 1);
        }, 500);
        this.$forceUpdate();
      }
    },
    getDay() {
      let f = new Date();
      let month = f.getMonth() + 1;
      if (month < 10) month = "0" + month;
      let today = new Date(f.getFullYear(), f.getMonth() - 1, f.getDay());
      this.today = this.$moment(today).format("YYYY-MM-DD");
      //this.today = f.getFullYear() + "-" + month + "-" + f.getDate();
    },
    m_bankSelected(debt_index, bank) {
      this.debts[debt_index].bank = bank.name;
      this.debts[debt_index].bankid = bank.id;
    },
    backWindowsNew() {
      this.calcAddModal = true;
      this.reportListo = false;
    },
    closeAddModal() {
      this.calcAddModal = false;
    },
    showCalcAddendum() {
      this.newDebts = [];
          for (let i = 0; i < this.accountTableData.length; i++) {
            this.newDebts.push({
              id: Number(this.accountTableData[i].id),
              bankid: Number(this.accountTableData[i].bank.id),
              amount: Number(this.accountTableData[i].amount),
              last_bank_payment_date: this.formDate(
                this.accountTableData[i].last_bank_payment_date
              ),
              account:this.accountTableData[i].account,
              product_type:this.accountTableData[i].product_type
            });
          }
          let url = this.$v.SERVER + this.$v.SELLER_OPS.GENERATE_PROGRAM_CALCULATION + "?user=" + this.client.id + "&add=" +true;
          this.$axios.post(url, this.newDebts).then((response) => {
            let data2 = response.data;
            if (data2.success) {
              this.dbmAccountsCalculations = [];
              this.calculatorDebts = [];
              this.accounts = [];
              this.sliderRange = [];
              this.period = data2.rows.optimalperiod;
              this.periodOptimal = data2.rows.optimalperiod;
              for (let i = 0; i < data2.rows.programs.length; i++) {
                this.sliderRange.push(data2.rows.programs[i].period);
                this.calculatorDebts.push(data2.rows.programs[i]);
                this.accounts = data2.rows.programs[i].debts;
                this.accounts.sort(function (a, b) {
                  return b.amount - a.amount;
                });
                for (let p = 0; p < this.calculatorDebts.length; p++) {
                  if (this.period === this.calculatorDebts[p].period) {
                    this.monthly_commission= this.calculatorDebts[p].commission;
                    this.calculatorDebts.period = this.calculatorDebts[
                      p
                    ].period;
                    this.calculatorDebts.savings = this.calculatorDebts[
                      p
                    ].savings;
                    this.calculatorDebts.realfee = this.calculatorDebts[
                      p
                    ].realfee;
                    this.calculatorDebts.commission = this.calculatorDebts[p].commission;
                    this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                    this.calculatorDebts.balanceActual = this.calculatorDebts[p].balanceActual;
                    this.calculatorDebts.firstSave = this.calculatorDebts[p].firstSave;
                    this.calculatorDebts.monthlyFee = this.calculatorDebts[
                      p
                    ].monthlyFee;
                    this.calculatorDebts.willPay = this.calculatorDebts[
                      p
                    ].willPay;
                    this.calculatorDebts.totalDebt = this.calculatorDebts[
                      p
                    ].totalDebt;
                    this.calculatorDebts.discount = this.calculatorDebts[
                      p
                    ].discount;
                  }
                }
              }
              let myArray = this.sliderRange;
              this.maxSlider = Math.max.apply(Math, myArray);
              this.minSlider = Math.min.apply(Math, myArray);
              this.calcAddModal = true;
            }
          });
    },
    getAddendumDetails(page) {
      this.addendumId = this.$route.query.add;
      let limit = this.addendumPagination.perPage;
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_ADDENDUMS_DETAIL;
      let request ={
        addendum_approach: this.addendumId,
        offset: limit * (page - 1),
        limit: limit,
      }
      this.$axios.post(url,request).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          this.addendumTableData = data[this.$v.API_ROWS];
          this.addendumPagination.total = p["totalItems"];
          this.addendumPagination.currentPage = page;
          this.addendumPagination.perPage = limit;
          if(this.addendumPagination.total > 0){
            this.buttonSignature=true;
          }
        }
      });
    },
    loadContract() {
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_CONTRACT + "?id=" + this.client.contract;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.contract = data[this.$v.API_ROW];
          this.commission_pre = data[this.$v.API_ROW].commission_percentage;
        }
      });
    },
    formatDecPost(val, pos) {
      if (!pos) pos = 2;
      if (val && Number(val)) return Number(val).toFixed(pos);
      return "0.00";
    },
    formatDecPost4(val, pos) {
      if (!pos) pos = 4;
      if (val && Number(val)) return Number(val).toFixed(pos);
      return "0.00";
    },
    formDate(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },
    getClientProfile(clientId) {      
    this.addendumId = this.$route.query.add;
      return new Promise((resolve) => {
        let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_PROFILE + "?id=" + clientId;
        this.$axios.get(url).then((response) => {
          let data = response.data;
          if (data[this.$v.API_SUCCESS]) {
            this.client = response.data[this.$v.API_ROW];
            this.editClientPhone = this.client.phone;
            this.getFinamigoBalance();
            this.getUserAccounts(1);
            this.getUserDebts(1);
            this.loadContract();
            this.getTokenAddendum();
            if (this.addendumId > 0) {
              this.getAddendumDetails(1);
              this.getAddendum();
            }
            resolve(true);
          }
          resolve(false);
        });
      });
    },
    m_ReturnDiscountNew() {
      this.accounts = [];
      this.sliderRange = [];
      this.periodOptimal = this.periodOptimal;
      this.period = this.period;
      this.calculatorDebts = this.calculatorDebts;
      this.reportListo = true;
      for (let i = 0; i < this.calculatorDebts.length; i++) {
        for (let p = 0; p < this.calculatorDebts.length; p++) {
          if (this.period === this.calculatorDebts[p].period) {
            this.monthly_commission= this.calculatorDebts[p].commission;
            this.calculatorDebts.debts = this.calculatorDebts[p].debts;
            this.calculatorDebts.period = this.calculatorDebts[p].period;
            this.calculatorDebts.monthlyFee = this.calculatorDebts[p].monthlyFee;
            this.calculatorDebts.firstSave = this.calculatorDebts[p].firstSave;
            this.calculatorDebts.balanceActual = this.calculatorDebts[p].balanceActual;
            this.calculatorDebts.savings = this.calculatorDebts[p].savings;
            this.calculatorDebts.commission = this.calculatorDebts[p].commission;
            this.calculatorDebts.realfee = this.calculatorDebts[p].realfee;
            this.calculatorDebts.willPay = this.calculatorDebts[p].willPay;
            this.calculatorDebts.totalDebt = this.calculatorDebts[p].totalDebt;
            this.calculatorDebts.discount = this.calculatorDebts[p].discount;
            this.calculatorDebts.savings = this.calculatorDebts[p].savings;
          }
        }
      }
      let myArray = this.sliderRange;
      this.maxSlider = Math.max.apply(Math, myArray);
      this.minSlider = Math.min.apply(Math, myArray);
      this.$forceUpdate();
    },
    uploadContract() {
      this.$showLoader(true)
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_EFFECTIVE_COMMISSION;
      let request = {
        id: this.contract.id,
        effective_commission: this.contract.effective_commission,
      };
      this.$axios.post(url, request).then((response) => {
      this.$showLoader(false)
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.effective_commission = true;
          this.$vs.notification(this.$notification(
                'success',
                 'Comisión efectiva actualizada'
                     ));
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'No se logro actualizar la comisión'
                     ));
        }
      });
      this.contract.commission_percentage = this.commissionPercentage;
      let contract = {};
      if (this.contract.effective_commission) {
        contract = {
          user: this.client.id,
          payday: this.client.payday,
          program_duration: this.client.program_duration,
          monthly_savings: this.client.monthly_savings,
          commission_percentage: this.commissionPercentage,
          first_payment: this.client.first_payment,
        };
      } else if (!this.contract.effective_commission) {
        contract = {
          user: this.client.id,
          payday: this.client.payday,
          program_duration: this.client.program_duration,
          monthly_savings: this.client.monthly_savings,
          commission_percentage: this.commissionPercentage,
          effective_commission: this.commissionPercentage,
          first_payment: this.client.first_payment,
        };
      }
      if (this.client.contract > 0) {
        contract.id = this.client.contract;
      }
      url = this.$v.SERVER + this.$v.SELLER_OPS.CREATE_CONTRACT;
      this.$axios.post(url, contract).then((response) => {
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          //this.contract = contract;
          let _contract = data[this.$v.API_ROW];
          if (this.client.contract === 0) {
            this.client.contract = _contract[0].id;
          }
          this.$forceUpdate();
          this.$vs.notification(this.$notification(
                'success',
                 'Contrato creado exitosamente'
                     ));
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
    getFinamigoBalance() {
      let url =
        this.$v.SERVER +
        this.$v.OPS.GET_FINAMIGO_BALANCE +
        "?user=" +
        this.client.id;
      this.$showLoader(true)
      this.$axios.get(url).then((response) => {
        this.$showLoader(false)
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.client.finamigoBalance = data.row.balance;
          this.newActivityNegotiation.finamigo_balance = data.row.balance;
          this.$forceUpdate();
        }
      });
      this.showFinBalance = true;
    },
    setTraceType(type) {
      this.newTrace.id = type.id;
      this.newTrace.type = type.type;
      if (type.id === 3) {
        this.showCalcAddendum();
      }
      this.calcAddModal = false;
      this.$forceUpdate();
    },
    setTraceProductType(type) {
      this.accountInModal.product_type = type;
      this.$forceUpdate();
    },
    getProductType(id) {
      for (let i=0 ; i<this.productType.length ; i++)
        if (this.productType[i].id === id)
          return this.productType[i].type
    },
    //----------------Cuentas---------------------
    getUserAccounts(page) {
      let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_USER_ACCOUNTS;
      let request = {
        offset:  (page - 1),
        user: this.client.id
      };
      this.$showLoader(true)
      this.$axios.post(url, request).then((response) => {
       this.$showLoader(false)
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let p = data[this.$v.API_ROW];
          let a = data[this.$v.API_ROWS];
          for (let i = 0; i < a.length; i++) {
            for (let j = 0; j < this.banks.length; j++) {
              if (this.banks[j].id === a[i].bank) {
                a[i].bank = this.banks[j];
                break;
              }
            }
          }
          for (let i = 0; i < a.length; i++) {
            for (let j = 0; j < this.productType.length; j++) {
              if (this.productType[j].id === a[i].product_type) {
                a[i].product_type = this.productType[j];
                break;
              }
            }
          }
          this.accountTableData = a;
          this.accountPagination.total = p["totalItems"];
          this.accountPagination.currentPage = page;
          this.$forceUpdate();
        } else this.$validateSession(data);
      });
    },
    getBank(id) {
      for (let i = 0; i < this.banks.length; i++)
        if (this.banks[i].id === id) return this.banks[i];
      return null;
    },
    addNewAccount(){
      this.newAccount=true;
      this.accountModal=true;
    },
    accountSelected(acc){
      if(this.newTrace.id<4) return
      this.accountModal = true;
      this.newAccount = false;
      this.accountInModal = {
        id:acc.id,
        bank: acc.bank,
        account: acc.account,
        product_type: acc.product_type,
        amount: acc.amount,
        last_bank_payment_date: this.$moment(acc.last_bank_payment_date).add(1,"d")._d,
      };
    },
    updateAccount() {
      let addId = this.$route.query.add;
      let bank = this.accountInModal.bank;
      let id = this.accountInModal.id;
      let account = this.accountInModal.account;
      let product_type = this.accountInModal.product_type.type;
      let amount = this.accountInModal.amount;
      let last_bank_payment_date = this.$moment(this.accountInModal.last_bank_payment_date)._d;
      if(!(bank && account && product_type && amount && last_bank_payment_date)){
        this.$vs.notification(this.$notification(
                'danger',
                 'Datos incompletos'
                     ));
        return;
      }
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_ADDENDUMS_DETAIL;
      let addDetails ={}
      if(this.newTrace.id === 1){
      addDetails ={
        addendum_approach: addId,
        type_addendum: this.newTrace.id,
        bank:this.accountInModal.bank.id,
        number_account:this.accountInModal.account,
        product_type:this.accountInModal.product_type.id,
        total_debt:this.accountInModal.amount,
        last_pay_date:last_bank_payment_date
      }
      }else if(this.newTrace.id === 4){
      addDetails ={
        account:this.accountInModal.id,
        addendum_approach: addId,
        type_addendum: this.newTrace.id,
        bank:this.accountInModal.bank.id,
        number_account:this.accountInModal.account,
        product_type:this.accountInModal.product_type.id,
        total_debt:this.accountInModal.amount,
        last_pay_date:last_bank_payment_date
      }
      }else if(this.newTrace.id === 5){
      addDetails ={
        account:this.accountInModal.id,
        addendum_approach: addId,
        type_addendum: this.newTrace.id,
        bank:this.accountInModal.bank.id,
        number_account:this.accountInModal.account,
        product_type:this.accountInModal.product_type.id,
        total_debt:this.accountInModal.amount,
        last_pay_date:last_bank_payment_date
      }
      }
      this.$showLoader(true)
      this.$axios.post(url,addDetails).then((response) => {
        this.$showLoader(false)
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Detalle de addendum añadido correctamente'
                     ));
          this.getAddendumDetails(1);
          this.$forceUpdate();
        }
      });
      if(this.newTrace.id===1){
        this.accountTableData.push(this.accountInModal)
      }else if(this.newTrace.id===4){
        this.accountTableData.map(function(dato){
          if(dato.id === id)
            dato.account = account;
          return dato;
        });
      } else {
        this.accountTableData.map(function(dato){
          if(dato.id === id)
            dato.amount = amount;
          return dato;
        });
      }
      this.accountModal = false;
      this.newAccount = false;
      this.accountInModal = {};
    },
    deleteAccount(acc){
      let addId = this.$route.query.add;
       let addDetails ={};
      for (let i=0; i<this.accountTableData.length; i++) {
        if(acc.account===this.accountTableData[i].account)
          this.accountTableData.splice(i,1);
      }
      addDetails ={
        account:acc.id,
        addendum_approach: addId,
        type_addendum: this.newTrace.id,
        bank:acc.bank.id,
        number_account:acc.account,
        product_type:acc.product_type,
        total_debt:acc.amount,
        last_pay_date:acc.last_bank_payment_date
      }
      let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_ADDENDUMS_DETAIL;
      this.$showLoader(true)
      this.$axios.post(url,addDetails).then((response) => {
        this.$showLoader(false)
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
           this.$vs.notification(this.$notification(
                'success',
                 'Detalle de addendum añadido correctamente'
                     ));
          this.getAddendumDetails(1);
          this.$forceUpdate();
        }
      });
      this.$forceUpdate();
    },
    closeAccountModal() {
      this.accountModal = false;
      this.newAccount = false;
      this.accountInModal = {};
      this.prevDel = false;
    },
    setBankInAccount(bank) {
      this.accountInModal.bank = bank;
      this.$forceUpdate();
    },
  },
};
</script>
<style></style>