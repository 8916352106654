<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                    <h4 v-if="(showData || showisService)" class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{$formatCurrency(amount)}}
                    </h4>
                    <!--
                    <h4 v-if="amountReal<=-0.1" class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        Saldo Faltante: {{$formatCurrency(amountReal)}}
                    </h4>+
                    !-->
                </div>
                <div class="col-sm-3 text-right" style="width: 100%">
                    <base-button v-if="!(showData || showisService) && showEye" title="Mostrar" size="sm" type="info" :icon="'far fa-eye'" v-on:click="showMovements"></base-button>
                    <base-button v-if="(isAdmin || isManagerService)  && (showData || showisService)" type="success" size="sm" v-on:click="onSendAccountStatement">Enviar RM <i class="fa fa-paper-plane" aria-hidden="true"></i></base-button>
                    <base-dropdown v-if="(showData || showisService)">
                        <base-button slot="title" type="info" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{movTypeFilterText?movTypeFilterText:"Movimientos"}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setMovTypeFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setMovTypeFilter(1)">Pagos</a>
                        <a class="dropdown-item" v-on:click="setMovTypeFilter(2)">Comisiones</a>
                        <a class="dropdown-item" v-on:click="setMovTypeFilter(3)">Liquidaciones</a>
                        <a class="dropdown-item" v-on:click="setMovTypeFilter(4)">Baja</a>
                    </base-dropdown>
                </div>
                <div v-if="showPayments && (showData || showisService)" class="col-sm-2" style="width: 100%; margin-right: 20px">
                    <base-dropdown>
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{validatedPaymentsFilterText? validatedPaymentsFilterText : "Validación"}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setValidatedPaymentsFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setValidatedPaymentsFilter(1)">Validados</a>
                        <a class="dropdown-item" v-on:click="setValidatedPaymentsFilter(0)">No validados</a>
                    </base-dropdown>
                </div>
            </div>
        </div>

        <div class="table-responsive" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                        <!--@click="movementSelected"-->

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">Movimiento</th>
                    <th v-bind:style="{cursor:pointer2}">Tipo</th>
                    <th v-bind:style="{cursor:pointer2}">Cantidad</th>
                    <!--<th v-bind:style="{cursor:pointer2}">Cantidad Comisión</th>!-->
                    <!--<th v-bind:style="{cursor:pointer2}">Cantidad Faltante</th>!-->
                    <th v-if="showPayments" v-bind:style="{cursor:pointer2}">Validación</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha</th>
                </template>

                <template v-if="(showData || showisService)" slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{row.m_id}}</span>
                            </div>
                        </div>
                    </th>
                    <td>{{getMovType(row.type)}}</td>
                    <td>{{$formatCurrency(row.amount)}}</td>
                    <!--<td>{{$formatCurrency(row.amount_correct)}}</td>!-->
                    <!--<td>{{$formatCurrency(row.amount_real)}}</td>!-->
                    <td v-if="showPayments">
                        <div class="d-flex align-items-center">
                            <span v-bind:class="getValidatedClass(row.validated)">{{row.validated ? "Validado": "No validado"}}</span>
                        </div>
                    </td>
                    <td>{{formatDate(row.date)}}</td>
                </template>
            </base-table>
        </div>

        <div v-if="(pagination.total > pagination.perPage) && showData" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'movements-table',
        props: {
            type: {
                type: String
            },
            title: String,
            amount: Number,
            amountReal: Number,
            showisService: Boolean,
            data: Array,
            showEye: Boolean,
            pagination: Object
        },
        data() {
            return {
                tableData: [],
                showPayments: false,
                validatedPaymentsFilterText: "",
                movTypeFilterText: "",
                pointer: 'pointer',
                pointer2: 'default',
                isAdmin:false,
                isManagerService:false,
                showData:false
            }
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
        },
        async created() {
          this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
          this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
          this.isService = this.user.role === this.$v.ROLES.SERVICE
          if(this.isService) this.showData=true;
        },
        methods: {
            showMovements(){
                this.showData = true;
                this.$emit("showMovements")
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            formatDate(date) {
               return this.$formatDate(date)
            },
            onSendAccountStatement() {
                this.$emit("onSendAccountStatement")
            },
            getMovType(type){
                return type==='payment'? 'Ahorro Mensual': type==='commission'? 'Comisión Mensual' : type ==='baja_remate' ? 'Baja' : type ==='money_back' ? 'Devolución al Cliente' : 'Liquidación de Deuda'
            },
            setMovTypeFilter(type){
                switch (type) {
                    case 1:
                        this.movTypeFilterText = 'Pagos'
                        type='payment'
                        break;
                    case 2:
                        this.movTypeFilterText = 'Comisión'
                        type="commission"
                        break;
                    case 3:
                        this.movTypeFilterText = 'Liquidación'
                        type='account_payment'
                        break;
                    case 4:
                        this.movTypeFilterText = 'Baja'
                        type='baja_remate'
                        break;
                    case 5:
                        this.movTypeFilterText = 'Devolución al Cliente'
                        type='money_back'
                        break;
                    default:
                        this.movTypeFilterText = ''
                        type=null
                        break;
                }
                this.showPayments = type === 'payment';
                this.$emit("setMovTypeFilter", type);
            },
            setValidatedPaymentsFilter(value){
                this.validatedPaymentsFilterText = value === 0? 'No validados' : value === 1? 'Validados' : ''
                this.$emit("setValidatedPaymentsFilter", value);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            /*onNewItem() {
                this.$emit("onNewItem")
            },
            movementSelected(payment) {
                this.$emit("onMovementSelected", payment)
            }*/
        }
    }
</script>
<style>
</style>
