<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col-lg-2 text-center">
          <base-dropdown style="width: 100%">
            <base-button slot="title" type="info" outline class="dropdown-toggle" size="sm" style="width: 100%">
              {{contractsUpdateMonthFilter? 'Mes actual' : 'Contratos actualizados'}}
            </base-button>
            <a class="dropdown-item" @click="setContractsUpdateMonthFilter(null)">Mostrar todos</a>
            <a class="dropdown-item" @click="setContractsUpdateMonthFilter(1)">Mes actual</a>
          </base-dropdown>
        </div>
        <div class="col-lg-2">
          <base-dropdown style="width: 100%">
            <base-button slot="title" type="warning" outline class="dropdown-toggle" size="sm" style="width: 100%">
              {{paymentsNotValidatedFilter? 'Pagos no validados' : 'Validación pagos'}}
            </base-button>
            <a class="dropdown-item" @click="setPaymentsNotValidatedFilter(null)">Mostrar todos</a>
            <a class="dropdown-item" @click="setPaymentsNotValidatedFilter(1)">Pendientes de validar pagos</a>
          </base-dropdown>
        </div>
        <!--div class="col-lg-2">
          <base-dropdown style="width: 100%">
            <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
              {{getPeriodFilterText()}}
            </base-button>
            <a class="dropdown-item" v-on:click="setPeriodFilter(null)">Mostrar todos</a>
            <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
            <div v-if="!showSetPeriod" class="dropdown-divider"></div>
            <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
          </base-dropdown>
        </div>
        <div v-if="showSetPeriod" class="col">
          <div class="row justify-content-center">
            <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
            <span class="small text-muted">-</span>
            <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
            <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
          </div>
        </div-->
      </div>
    </div>
    <div class="table-responsive" v-bind:style="{cursor:pointer}">
      <base-table class="table align-items-center table-flush"
          :class="type === 'dark' ? 'table-dark': ''"
          :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
          tbody-classes="list"
          :data="data">

        <template slot="columns">
          <th v-bind:style="{cursor:pointer}">Usuario</th>
          <th v-bind:style="{cursor:pointer}">Nombre</th>
          <th class="text-center">Última actividad</th>
          <th class="text-center">Fecha Última actividad</th>
          <th class="text-center">Comentarios</th>
          <th class="text-center">Pagos pendientes de validar</th>
          <th class="text-center">Fecha de actualización de contrato</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <a :href="'https://admin.dbmenos.com/client?id='+row.user" target="_blank">
                  <span class="name mb-0 text-sm">{{row.user}}<i class="fas fa-user-edit text-primary"></i></span>
                </a>
              </div>
            </div>
          </th>
          <td>{{row.name}}</td>
          <td class="text-center">{{row.action? row.action:'-'}}</td>
          <td class="text-center">{{row.action_created? $formatDate(row.action_created) : '-'}}</td>
          <td class="text-center">{{row.comments? row.comments : '-'}}</td>
          <td class="text-center">{{row.payments_not_validated===null? '-': row.payments_not_validated}}</td>
          <td class="text-center">{{row.contract_update? $formatDate(row.contract_update): '-'}}</td>
        </template>

      </base-table>
    </div>
        
    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="col">
        <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
          Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} Validaciones pendientes
        </p>
      </div>
      <base-pagination
          size="sm"
          :total="pagination.total"
          :per-page="pagination.perPage"
          :value="pagination.currentPage"
          v-on:pageSelected="pageSelected"/>
    </div>

  </div>
</template>
<script>
  //import flatPickr from 'vue-flatpickr-component';
  //import 'flatpickr/dist/flatpickr.css';
  export default {
    name: 'audit-pending-validated-table',
    components:{
      //flatPickr
    },
    props: {
      type: {
        type: String
      },
      period: {
        start: null,
        end: null
      },
      title: String,
      data: Array,
      pagination: Object
    },
    computed: {
      user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
    },
    data() {
      return {
        tableData: [],
        showSetPeriod: false,
        periodFilter: null,
        contractsUpdateMonthFilter: 1,
        paymentsNotValidatedFilter: null,
        pointer: 'pointer',
        pointer2: 'default',
      }
    },
    methods: {
      pageSelected(page) {
        this.$emit("pageSelected", page);
      },
      setPaymentsNotValidatedFilter(value) {
        this.paymentsNotValidatedFilter = value;
        this.$emit("setPaymentsNotValidatedFilter", value);
      },
      setContractsUpdateMonthFilter(value) {
        this.contractsUpdateMonthFilter = value;
        this.$emit("setContractsUpdateMonthFilter", value);
      },
      setPeriod(){
        this.showSetPeriod=true
        this.period.start=null
        this.period.end=null
        this.getPeriodFilterText()
      },
      setPeriodFilter(value) {
        if(value!=2){
          this.showSetPeriod = false
          this.periodFilter = value
          this.$emit("setPeriodFilter", value);
        }else if(this.period.start && this.period.end){
          this.periodFilter = value
          this.$emit("setPeriodFilter", value);
        }
      },
      getPeriodFilterText() {
        if(this.showSetPeriod)
          return "Periodo del: "
        else if(this.periodFilter === 1)
          return "Mes actual"
        else return "Fecha"
      },
    }
  }
</script>
<style>
</style>