<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''"
         v-bind:style="{cursor:pointer2}">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                    <!--h4 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{$formatCurrency(amount)}}
                    </h4-->
                </div>
                <div class="col text-right">
                    <base-button :title="!showData?'Mostrar':'Ocultar'" size="sm" type="info" :icon="!showData? 'far fa-eye':'far fa-eye-slash'" @click="showData=!showData"></base-button>
                    <base-button v-if="showData && showCalc && !creditReportAccept" type="outline-success" class="p-2" size="sm" @click="showCalcClick">Ver calculadora</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data"
                        :showCalc="showCalc"
                        v-on:click="accountClick">

                <template slot="columns" class="text-center">
                    <th v-bind:style="{cursor:pointer2}">Acreedor de la deuda</th>
                    <th v-if="!creditReportAccept" v-bind:style="{cursor:pointer2}">Monto de deuda</th>
                    <th v-bind:style="{cursor:pointer2}">Total adeudado</th>
                    <th v-if="!creditReportAccept" v-bind:style="{cursor:pointer2}">Límite de crédito</th>
                    <th v-bind:style="{cursor:pointer2}">Número de Cuenta</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha de último pago</th>
                    <th v-if="creditReportAccept" class="text-center" v-bind:style="{cursor:pointer2}">Eliminar</th>

                </template>

                <template v-if="showData" slot-scope="{row}" class="text-center">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{row['NombreOtorgante']}}</span>
                            </div>
                        </div>
                    </th>
                    <td v-if="!creditReportAccept" class="text-center">{{$formatCurrency(row.SaldoActual)}}</td>
                    <td class="text-center">{{$formatCurrency(row.MontoPagar)}}</td>
                    <td v-if="!creditReportAccept" class="text-center">{{$formatCurrency(row['LimiteCredito'])}}</td>
                    <td class="text-center">{{row.NumeroCuentaActual}}</td>
                    <td v-if="!row['FechaUltimoPago']" class="text-center">Sin información</td>
                    <td v-else class="text-center">{{interprateDate(row['FechaUltimoPago'])}}</td>
                    <td v-if="creditReportAccept" class="text-center text-danger"><i class="fas fa-trash" @click.stop="deleteAccount(row)"></i></td>

                </template>

            </base-table>
        </div>

        <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'credit-report-table',
        props: {
            type: {
                type: String
            },
            title: String,
            amount: Number,
            data: Array,
            creditReportAccept: Boolean,
            pagination: Object,
            showCalc: Boolean
        },
        data() {
            return {
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default',
                showData: false
            }
        },
        computed: {
            banks() { return this.$store.state.banks }
        },
        methods: {
            interprateDate(cadena){
                if(cadena){
                    let identificador = cadena.indexOf("-");
                    if(identificador > 1){
                        return (this.$moment(cadena).format('DD-MMM-YYYY'))
                    } else {
                        let fec = cadena + "";
                        let day = fec.substr(0,2);
                        let month = fec.substr(2,2);
                        let year = fec.substr(4,7);
                        let date = month+"-"+day+"-"+year;
                        let fecha = this.$moment(date).format('DD-MMM-YYYY')
                        return fecha;
                    }
                }
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            getBanks(array) {
                let banks = []
                for (let a=0 ; a<=array.length ; a++) {
                    for (let b = 0; b < this.banks.length; b++) {
                        if (this.banks[b].id === array[a]) {
                            banks.push(this.banks[b])
                            break
                        }
                    }
                }
                return banks
            },
            deleteAccount(acc) {
               this.$emit("deleteAccount", acc)
            },
            formatDate(date) {
//                return this.$moment(date).format("DD MMM, YYYY")
               return this.$formatDate(date)
            },
            accountClick(acc) {
                this.$emit("accountClick", acc)
            },
            showCalcClick(){
                this.$emit("showCalcClick")
            }
        }
    }
</script>
<style>
</style>