<template>
    <div class="card shadow"
        :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col-lg text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="info" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{statusFilter ? statusFilter : "Estado del trámite"}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setStatusTramit(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setStatusTramit('Espera de liquidación')">Espera de liquidación</a>
                        <a class="dropdown-item" v-on:click="setStatusTramit('Convenio recibido')">Convenio recibido</a>
                        <a class="dropdown-item" v-on:click="setStatusTramit('Carta recibida')">Carta recibida</a>
                        <a class="dropdown-item" v-on:click="setStatusTramit('Carta enviada')">Carta enviada</a>
                    </base-dropdown>
                </div>
                <div class="col-lg text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="success" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{methodPaymentFilter ? methodPaymentFilter : "Forma de pago"}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setMethodPayment(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setMethodPayment('Efectivo')">Efectivo</a>
                        <a class="dropdown-item" v-on:click="setMethodPayment('Transferencia')">Transferencia</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2 text-center">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="primary" outline class="dropdown-toggle" size="sm" style="width: 100%">
                        {{voboTLFilter===0 ? "Sin VoBo TL" : voboTLFilter ? "Con VoBo TL" : "voBo TL"}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setvoboTLFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setvoboTLFilter(0)">Sin VoBo TL</a>
                        <a class="dropdown-item" v-on:click="setvoboTLFilter(1)">Con VoBo TL</a>
                    </base-dropdown>
                </div>            
                <div class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getPeriodFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
                        <div v-if="!showSetPeriod" class="dropdown-divider"></div>
                        <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
                    </base-dropdown>
                </div>  
                <div v-if="showSetPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" 
                        name="startPeriod" style="background-color: #FFFFFF"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" 
                        name="endPeriod" style="background-color: #FFFFFF"/>
                        <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
                    </div>
                </div>  
            </div>
        </div>
    
        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                        <!--:period="period"-->

                <template slot="columns" >
                    <th v-bind:style="{cursor:pointer2}">ID Deuda</th>
                    <th v-bind:style="{cursor:pointer2}">ID Cliente</th>
                    <th v-bind:style="{cursor:pointer2}">Cliente</th>
                    <th v-bind:style="{cursor:pointer2}"># TDC</th>
                    <th v-bind:style="{cursor:pointer2}">Banco</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha limite</th>
                    <th v-bind:style="{cursor:pointer2}">Forma de Pago</th>
                    <th v-bind:style="{cursor:pointer2}">Pago Banco</th>
                    <th v-bind:style="{cursor:pointer2}">Indicaciones de Pago</th>
                  <th v-bind:style="{cursor:pointer2}">Liquidada</th>
                    <th v-bind:style="{cursor:pointer2}">Monto Recibimos de FA</th>
                    <th v-bind:style="{cursor:pointer2}">Diferencia</th>
                    <th v-bind:style="{cursor:pointer2}">Fecha estimada de liquidación</th>
                    <th v-bind:style="{cursor:pointer2}">Vobo TL</th>
                    <th v-bind:style="{cursor:pointer2}">Negociador</th>
                  <th v-bind:style="{cursor:pointer2}">Estado del trámite</th>
                  <th v-bind:style="{cursor:pointer2}">Carta enviada el</th>
                </template>

                <template slot-scope="{row}">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span @click="debtSelected(row.id_debt)" class="name mb-0 text-primary text-sm">{{row.id_debt}}</span>
                      </div>
                    </div>
                  </th>
                  <td class="text-center">{{row.user}}</td>
                  <td class="text-center">{{row.name}}</td>
                  <td class="text-center">{{row.account}}</td>
                  <td class="text-center">{{row.bank}}</td>
                  <td class="text-center">{{row.last_activity_date ? $formatDate(row.limit_pay_date) : '---'}}</td>
                  <td class="text-center">{{row.method_pay ? row.method_pay : '---'}}</td>
                  <td class="text-center">{{$formatCurrency(row.amount_pay_bank)}}</td>
                  <td class="text-center">{{row.payment_instructions ? row.payment_instructions : '---'}}</td>
                  <td class="text-center">
                    <base-checkbox :checked="Boolean(row.settled)" @input="setDebtSettled(row.id_debt, row.settled)" :disabled="Boolean(row.settled)"/>
                  </td>
                  <td class="text-center">{{row.recived_FA_amount ? row.recived_FA_amount : '---'}}</td>
                  <td class="text-center">{{row.difference ? row.difference : '---'}}</td>
                  <td class="text-center">{{row.estimated_settlement_date ? getEstimatedSettlementDate(row.estimated_settlement_date) : '---'}}</td>
                  <td class="text-center">
                    <i v-if="row.vobo_TL" class="fas fa-check text-success"></i>
                    <span v-else>---</span>
                  </td>
                  <td class="text-center">{{row.consultant_sc_name ? row.consultant_sc_name : 'Sin Asesor'}}</td>
                  <td class="text-center">{{row.procedure_status_name ? row.procedure_status_name : '---'}}</td>
                  <td class="text-center">{{row.settlement_send_date ? $formatDate(row.settlement_send_date) : '---'}}</td>
                </template>

            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    {{pagination.total}} deudas
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>
    </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
        name: 'settlements-day-table',
        components:{
            flatPickr
        },
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            unassigned: Boolean,
            period: {
                start: null,
                end: null
            },
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            customerService() { return this.$store.state.customerService }
        },
        data() {
            return {
                isAdmin: false,
                tableData: [],
                today: null,
                //consultantFilter: null,
                //showSetPeriod:false,
                textTraceFilter: "",
                textDueMonthsFilter: "",
                textTypeClientFilter: "",
                textMDIFilter: "",
                textNominaFilter: "",
                currentColumnKey: null,
                sortedColumn: 0,
                sortingDesc: true,
                //widthProgressBar: null,
                //titleProgressBar: "",
                pointer: 'pointer',
                pointer2: 'default',
                showSetPeriod:false,
                periodFilter: null,
                statusFilter: null,
                methodPaymentFilter:null,
                voboTLFilter:null,
                
            }
        },
        async created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
            this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
            this.today= new Date()
        },
        methods: {
            pageSelected(page) {
              let table=1;
              this.$emit("pageSelected", page,table);
            },
            getPeriodFilterText() {
                if(this.showSetPeriod)
                    return "Periodo del: "
                else if(this.periodFilter === 1)
                    return "Mes actual"
                else return "Fecha"
            },
            setPeriod(){
                this.showSetPeriod=true
                this.period.start=null
                this.period.end=null
                this.getPeriodFilterText()                
            },
            debtSelected(client) {
                this.$emit("debtSelected", client);
            },
            setStatusTramit(value) {
                this.statusFilter = value
                this.$emit("setStatusTramit", value);
            },
            setMethodPayment(value) {
                this.methodPaymentFilter = value
                this.$emit("setMethodPayment", value);
            },
            setvoboTLFilter(value) {
                this.voboTLFilter = value
                this.$emit("setvoboTLFilter", value);
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            },
            setPeriodFilter(value) {        
                if(value!=2){
                    this.showSetPeriod = false
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                    
                }else if(this.period.start && this.period.end){
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                }
            },
            getEstimatedSettlementDate(info){
              return info!='Sin convenio' && info!='Sin carta'?this.$formatDate(info):info;
            },
            setDebtSettled(id_debt, value){
              this.$showLoader(true);
              let url = this.$v.SERVER + this.$v.SERVICE_OPS.SET_DEBT_SETTLED_CHECKED;
              let request  = {
                id_debt: id_debt,
                checked: !value
              }
              this.$axios.post(url, request).then((response) => {
                this.$showLoader(false);
                let data = response.data;
                if (data[this.$v.API_SUCCESS]) {
                  this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
                  this.pageSelected(1);
                  this.$forceUpdate();
                } else {
                  this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                }
              });
            }
        }
    }
</script>
<style>
</style>