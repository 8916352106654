<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<finamigo-view-table
            title="Finamigo"
            :data="tableData"
            :pagination="pagination"
						v-on:pageSelected="getDataList"/>

          <br>

          <finamigo-wrong-reference-table
            title="Referencia Erronea"
            :data="tableData2"
            :pagination="pagination2"
            :period="period"
            v-on:setPeriodFilter="setPeriodFilter"
            v-on:setAbonadoFilter="setAbonadoFilter"
            v-on:setNoLocalizadoFilter="setNoLocalizadoFilter"
            v-on:setNoComprobanteFilter="setNoComprobanteFilter"
            v-on:pageSelected="getDataList2"/>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import FinamigoWrongReferenceTable from './Tables/FinamigoWrongReferenceTable'
  import FinamigoViewTable from './Tables/FinamigoViewTable'
	export default {
		name: 'tables',
		components: {
			FinamigoViewTable,
      FinamigoWrongReferenceTable
		},
		mounted() {
      this.getDataList(1)
      this.getDataList2(1)
      this.$root.$on('searchQuery', query => {
				if (query.length > 0)
					this.searchQuery = query
				else
					this.searchQuery = null
				this.getDataList(1)
			})
		},
		data() {
			return {
        isAdmin: false,
				searchQuery: null,
        tableData: [],
        pagination: {
					total: 0,
					perPage: 10,
					currentPage: 1
				},
        periodFilter: null,
      period: {
        start: null,
        end: null
      },
        tableData2: [],
        pagination2: {
          total: 0,
          perPage: 10,
          currentPage: 1
        },
				pointer: 'pointer',
				pointer2: 'default',
        AbonadoFilter: null,
        NoLocalizadoFilter: null,
        NoComprobanteFilter: null,
        lastRequest: {},
			};
		},
    computed: {
      user() { return JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.USER)) }
    },
    created() {
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
    },
    methods: {
      setAbonadoFilter(value) {
      this.AbonadoFilter = value
      this.getDataList2(1)
    },
    setNoLocalizadoFilter(value) {
      this.NoLocalizadoFilter = value
      this.getDataList2(1)
    },
    setNoComprobanteFilter(value) {
      this.NoComprobanteFilter = value
      this.getDataList2(1)
    },
      setPeriodFilter(value) {
      if(value === 1){
        let date = new Date()
        let month = date.getMonth()+1
        if(month<10) month= "0"+month
        this.period.start = date.getFullYear()+"-"+month+"-"+"01T00:00:00.000"
      }
      this.periodFilter = value
      this.getDataList2(1)
    },
			getDataList(page) {
				let limit = this.pagination.perPage
        let url = this.$v.SERVER + this.$v.FINAMIGO_OPS.GET_ACCOUNTS
				if (this.searchQuery) url += "?&find=" + this.searchQuery
				else url+= "?offset=" + limit*(page - 1) + "&limit=" + limit
				this.$showLoader(true)
				this.$axios.get(url).then(response => {
					this.$showLoader(false)
					let data = response.data
					if (data[this.$v.API_SUCCESS]) {
						this.tableData = data[this.$v.API_ROWS]
						let p = data[this.$v.API_ROW]
						this.pagination.total = p['totalItems']
                        this.pagination.currentPage = page
                        this.pagination.perPage = limit
					} else { this.$validateSession(data) }
				})
			},

      async getDataList2(page) {
        let limit = this.pagination2.perPage
        let url = this.$v.SERVER + this.$v.FINAMIGO_OPS.GET_AUDIT_WRONG_REFERENCE_STP
        let request ={
          offset: limit*(page - 1),
          limit: limit
        }
        if (this.periodFilter === 1) {
          let date_payment = {gte: this.period.start}
          Object.assign(request, {date_payment})
        }
        if (this.periodFilter === 2){
          let date_payment = {
            gte: this.period.start,
            lte: this.period.end
          }
          request = Object.assign(request, {date_payment})
        }
        if (this.AbonadoFilter) {
          Object.assign(request, {transfer_fa: "1"})
        }
        if (this.NoLocalizadoFilter) {
          Object.assign(request, {deposit_not_located: "1"})
        }
        if (this.NoComprobanteFilter) {
          Object.assign(request, {concept_voucher_error: "1"})
        }

        const lr = request
      if (lr === this.lastRequest) return
      this.lastRequest = lr;

        this.$showLoader(true)
        await this.$axios.post(url,request).then(response => {
          this.$showLoader(false)
          let data = response.data
          if (data[this.$v.API_SUCCESS]) {
            let p = data[this.$v.API_ROW]
            this.tableData2 = data[this.$v.API_ROWS]
            this.pagination2.total = p['totalItems']
            this.pagination2.currentPage = page
            this.pagination2.perPage = limit
          } else { this.$validateSession(data) }
        })
      },
		}
	};
</script>
<style></style>