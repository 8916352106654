<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                        {{title}}
                    </h3>
                </div>
                <div class="col-lg-2 text-right">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="warning" outline class="dropdown-toggle" style="width: 100%" size="sm">
                            {{getCustomerFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setConsultantFilter(null)">Mostrar todos</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" v-for="(seller) in sellers" :key="seller.id" v-on:click="setConsultantFilter(seller.id)">{{seller.name}} {{seller.lastname_1}}</a>
                        <div class="dropdown-divider"></div>
                        <a v-for="(customerS) in customerService" :key="customerS.id" class="dropdown-item" v-on:click="setConsultantFilter(customerS.id)">{{customerS.name}} {{customerS.lastname_1}}</a>
                    </base-dropdown>
                </div>
                <div class="col-lg-2">
                    <base-dropdown style="width: 100%">
                        <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
                            {{getPeriodFilterText()}}
                        </base-button>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(null)">Mostrar todos</a>
                        <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
                        <div v-if="!showSetPeriod" class="dropdown-divider"></div>
                        <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
                    </base-dropdown>
                </div>                
                <div v-if="showSetPeriod" class="col">
                    <div class="row justify-content-center">
                        <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
                        <span class="small text-muted">-</span>
                        <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
                        <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive text-center" v-bind:style="{cursor:pointer}">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                        <!--v-on:click="clientSelected"-->

                <template slot="columns">
                    <th v-bind:style="{cursor:pointer}" class="text-center">Usuario</th>
                    <th v-bind:style="{cursor:pointer}">Nombre</th>
                    <!--th v-bind:style="{cursor:pointer}">Solicitud</th-->
                    <th v-bind:style="{cursor:pointer}">Reporte de crédito</th>
                    <th v-bind:style="{cursor:pointer}" class="text-center">Fecha de creación</th>
                    <th v-bind:style="{cursor:pointer}">Vendedor</th>
                    <th v-bind:style="{cursor:pointer}" class="text-center">Error</th>
                </template>

                <template slot-scope="{row}">
                    <th scope="row">
                        <div class="media align-items-center">
                            <div class="media-body">
                                <a :href="'https://admin.dbmenos.com/client?id='+row.user" target="_blank">
                                    <span class="name mb-0 text-sm">{{row.user}}<i class="fas fa-user-edit text-primary"></i></span>
                                </a>
                            </div>
                        </div>
                    </th>
                    <td>{{row.name}}</td>
                    <!--td>{{row.request_id}}</td-->
                    <td class="text-center">
                        <u class="text-primary"><a @click="showModal(row)">Ver</a></u>
                    </td>
                    <td>{{$moment(row.created).format("DD MMM YYYY, h:mm a")}}</td>
                    <td>{{getConsultantName(row.consultant)}}</td>
                    <td>{{row.error}}</td>
                </template>

            </base-table>
        </div>
        <modal :show.sync="showModalReport" v-on:close="showModalReport=false" v-bind:large="true">
            <template slot="header">
                <h2 class="mb-0" id="creditReportModal" style="margin-top: 20px; margin-left: 10px">Reporte de crédito</h2>
            </template>
                <div v-if="report">
                    <credit-report-table v-if="lengthsCreditReports.dbmAccounts>0" title = "Cuentas que se pueden agregar al programa"
                            :amount="Number(client['total_savings'])"    
                            :data="report && report['dbmAccounts'] ? report['dbmAccounts'] : []"
                            :pagination="paymentPagination"
                            style="margin-top: 20px"/>
                    <div v-else-if="!reportByBC" class="text-center">
                        <div class="card-profile-stats d-flex justify-content-center">
                            <h6 class="heading-small text-muted mb-4">No se encontaron cuentas que se puedan agregar al programa</h6>
                        </div>
                    </div>


                    <credit-report-table v-if="lengthsCreditReports.otherAccounts>0" title = "Otras cuentas"
                            :amount="Number(client['total_savings'])"
                            :data="report && report['otherAccounts'] ? report['otherAccounts'] : []"
                            :pagination="paymentPagination"
                            style="margin-top: 20px"/>
                    <div v-else-if="!reportByBC" class="text-center">
                        <div class="card-profile-stats d-flex justify-content-center">
                            <h6 class="heading-small text-muted mb-4">No se encontaron otras cuentas</h6>
                        </div>
                    </div>
                </div>
                <div v-else class="text-center">
                    <div class="card-profile-stats d-flex justify-content-center">
                        <div>
                            <h6 class="heading-small text-muted mb-4">No se encontró un reporte de crédito</h6>                            
                            <span class="font-weight-bold ml-1 text-warning">{{getMessageReport(messageReport)}}</span><br>
                            <span class="description">status</span>                            
                        </div>
                    </div>
                </div>
            
            <template slot="footer">
                <div class="align-self-start mr-auto">
                    <base-button type="primary" v-on:click="showModalReport=false">Cerrar</base-button>
                </div>
                <base-button v-if="!report" type="success" @click="updateCreditReport">Solicitar actualización</base-button>
            </template>
        </modal>

        <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="col">
                <p class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                    Página {{pagination.currentPage}} de {{Math.ceil(pagination.total/pagination.perPage)}}<br>{{pagination.total}} reportes
                </p>
            </div>
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import CreditReportTable from './CreditReportTable'
    //import CreditReportBuroTable from './CreditReportBuroTable'
    export default {
        name: 'massive-credit-report-table',
        components:{
            'credit-report-table': CreditReportTable,
            flatPickr
        },
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            pagination: Object,
            period: {
                start: null,
                end: null
            },
        },
        computed: {
            user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
            sellers() { return this.$store.state.sellers },
            customerService() { return this.$store.state.customerService }
        },
        data() {
            return {
                isAdmin: false,
                tableData: [],
                showModalReport: false,                
                paymentPagination: {
                    total: 0,
                    perPage: 5,
                    currentPage: 1
                },
                client: {},
                report: {},
                consultantFilter: null,
                periodFilter: null,
                showSetPeriod: false,
                reportByBC: false,
                messageReport: '',
                lengthsCreditReports:{
                    dbmAccounts: null,
                    otherAccounts: null
                },
                pointer: 'pointer',
                pointer2: 'default',
            }
        },
        created() {
            this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
            this.isSeller = this.user.role === this.$v.ROLES.SELLER
        },
        methods: {
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getSellerName(sellerID) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === sellerID) return this.sellers[i].name
                return "- - -"
            },
            showModal(row){
                this.showModalReport=true;
                this.client = row
                this.getCreditReport(this.client.user)
            },
            updateCreditReport(){
                this.$showLoader(true)
                let user = this.client
                let url = this.$v.SERVER + this.$v.SELLER_OPS.UPDATE_CREDIT_REPORT + "?user=" + user.id
                this.$axios.get(url).then(response => {
                    this.$showLoader(false)
                    if (response.data.success){
                        this.getCreditReport(user.id)
                        this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
                    } else {
                        this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
                    }
                })
            },
            getCreditReport(userId) {
                let url = this.$v.SERVER + this.$v.SERVICE_OPS.GET_CREDIT_REPORT + "?user=" + userId
                this.$showLoader(true);
                this.$axios.get(url).then((response) => {
                    this.$showLoader(false);
                    let data = response.data;
                    if (data[this.$v.API_SUCCESS]) {
                    this.report = data.row.credit_report ? data.row.credit_report : data.row.accounts;
                    this.messageReport = data.row.message ? data.row.message: data.row.report.status;
                    this.existReport = true;
                    this.lengthsCreditReports.dbmAccounts = this.report.dbmAccounts.length;
                    this.lengthsCreditReports.otherAccounts = this.report.otherAccounts.length;
                    } else {
                    this.existReport = false;
                    }
                });
            },
            setConsultantFilter(value) {
                this.consultantFilter = value
                this.$emit("setConsultantFilter", value);
            },
            getCustomerFilterText() {
                if (this.consultantFilter) return this.getConsultantName(this.consultantFilter)
                return "Consultores"
            },
            getConsultantName(id) {
                for (let i=0 ; i<this.sellers.length ; i++)
                    if (this.sellers[i].id === id) return this.sellers[i].name
                for (let i=0 ; i<this.customerService.length ; i++)
                    if (this.customerService[i].id === id) return this.customerService[i].name
                return "- - -"
            },
            getMessageReport(message){
                if(!message) return "-"
                if(message.length>50) return "Contactar con soporte"
                return message
            },
            setPeriod(){
                this.showSetPeriod=true
                this.period.start=null
                this.period.end=null
                this.getPeriodFilterText()                
            },
            setPeriodFilter(value) {        
                if(value!=2){
                    this.showSetPeriod = false
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                    
                }else if(this.period.start && this.period.end){
                    this.periodFilter = value
                    this.$emit("setPeriodFilter", value);
                }
                
            },
            getPeriodFilterText() {
                if(this.showSetPeriod)
                    return "Periodo del: "
                else if(this.periodFilter === 1)
                    return "Mes actual"
                else return "Fecha"
            },
        }
    }
</script>
<style>
</style>