<template>
    <div>
        <label class="form-control-label">
            {{title}}
        </label>
        <div class="dropbox">
            <input type="file" :name="text" accept="*/*" class="input-file" @change="fileSelected($event.target.name, $event.target.files)">
            <p v-if="!selectedFile && !miniURL">Arrastra o da click para seleccionar el archivo</p>
            <img v-if="!selectedFile && miniURL" :src="miniURL" :alt="text" style="width: 100%; max-height: 500px"/>
            <img v-if="selectedFile" :src="previewURL" :alt="text" style="width: 100%; max-height: 500px"/>
        </div>
        <base-button v-if="selectedFile" type="danger" size="sm" style="width: 100%; margin-top: 8px" v-on:click="selectedFile=null">Eliminar</base-button>
    </div>
</template>

<script>
    export default {
        name: "Dropbox-visor",
        data() {
            return {
                selectedFile: null,
                previewURL: null,
                uploaded: null
            }
        },
        props: {
            title: String,
            text: String,
            files: Array,
            miniURL: String
        },
        methods: {
            fileSelected(fieldName, files) {
                if (!files.length) return
                let f = files[0]
                this.selectedFile = f
                if (!f.type.match('image.*'))
                    return
                const reader = new FileReader()
                const c = this
                reader.onload = function (e) {
                    c.previewURL = e.target.result
                }
                reader.readAsDataURL(f)
                this.$emit('setMiniature', {
                    //type: this.type,
                    file: this.selectedFile,
                    //text: this.text
                })
            },
        },
    }
</script>

<style lang="scss">
    .dropbox {
        outline: 2px dashed blue; /* the dash box */
        outline-offset: -10px;
        background: #FFFFFF;
        border-radius: 10px;
        color: #3953D3;
        padding: 10px 10px;
        min-height: 100px; /* minimum height */
        position: relative;
        cursor: pointer;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: #f7fafc; /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1em;
        text-align: center;
        padding: 5px;
        margin: 0;
    }
</style>