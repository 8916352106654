<template>
  <div style="width:auto;" class="card-header text-center align-items-center" :class="type === 'dark' ? 'bg-transparent': ''">
    <div class="row" style="margin-left:-2rem">
      <div class="col-lg-12 text-center table-responsive">
        <base-dropdown style="width: 100%">
          <base-button slot="title" type="default" outline class="dropdown-toggle" size="sm" style="width: 100%">
            {{getPeriodFilterText()}}
          </base-button>
          <a class="dropdown-item" v-on:click="setPeriodFilter(1)">Mes actual</a>
          <div v-if="!showSetPeriod" class="dropdown-divider"></div>
          <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod">Establecer periodo</a>
        </base-dropdown>
        <div class="row justify-content-center">
          <flat-pickr v-model="period.start" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="startPeriod" style="background-color: #FFFFFF"/>
          <span class="small text-muted">-</span>
          <flat-pickr v-model="period.end" placeholder="aaaa-mm-dd" class="btn btn-sm" data-vv-as="Fecha" name="endPeriod" style="background-color: #FFFFFF"/>
          <base-button type="none text-green" size="sm" @click="setPeriodFilter(2)">Aplicar</base-button>
        </div>
        <table class="round_table table table-sm align-items-center">
          <tr>
            <th class="round_th bg-primary text-white" style="font-size:0.8rem;">Descripción</th>
            <th class="round_th bg-primary text-white" style="font-size:0.8rem;">Total</th>
          </tr>
          <tr>
            <td class="table-active text-center" style="font-size:0.8rem;">Clientes Nuevos</td>
            <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumNC}}</td>
          </tr>
          <tr>
            <td class="table-active text-center" style="font-size:0.8rem;">Auditados Completamente </td>
            <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumCP}}</td>
          </tr>
          <tr>
            <td class="table-active text-center" style="font-size:0.8rem;">Por Auditar</td>
            <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumNA}}</td>
          </tr>
          <tr>
            <td class="table-active text-center" style="font-size:0.8rem;">Actualizados</td>
            <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumUpdates}}</td>
          </tr>
          <tr>
            <td class="table-active text-center" style="font-size:0.8rem;">Pendientes</td>
            <td class="table-active text-center" style="font-size:0.8rem;">{{tableData.sumPendings}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: 'audit-info-dashboard-table',
  components: {
    flatPickr
  },
  props: {
    type: {
      type: String
    },
    title: String,
    data: Array,
  },
  computed: {
    user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
    sellers() { return this.$store.state.sellers },
    customerService() { return this.$store.state.customerService },
    banks() { return this.$store.state.banks; },
  },
  data() {
    return {
      tableData: {},
      tableData1: {},
      tableData2: {},
      period: {
        start: null,
        end: null
      },
      pagination: {
        total: 0,
        perPage: 500,
        currentPage: 1
      },
      showSetPeriod: false,
      periodFilter: null,
      pointer: 'pointer',
      pointer2: 'default'
    }
  },
  async created(){
    await this.getData2(1)
  },
  methods: {
    async getData2(page) {
      let limit = this.pagination.perPage
      let url = this.$v.SERVER + this.$v.CONSULTANT_OPS.GET_VIEW_AUDITOR
      let request = {
        firstPaymentDate: {gte: this.$moment().startOf('month').format('YYYY-MM-DD')},
        limit: limit,
        //complete_docs: {ne: 'COMPLETO'}
      }
      if (this.periodFilter === 1) {
        request.firstPaymentDate = {gte: this.$moment().startOf('month').format('YYYY-MM-DD')}
      }else if (this.periodFilter === 2){
        request.firstPaymentDate = {
          gte: this.period.start,
          lte: this.period.end
        }
      }

      const lr = request
      if (lr === this.lastRequest) return
      this.lastRequest = lr;
      this.$showLoader(true)
      await this.$axios.post(url,request).then(response => {
        this.$showLoader(false)
        let data = response.data
        if (data[this.$v.API_SUCCESS]) {
          this.tableData2 = data[this.$v.API_ROWS]
          console.log(this.tableData2)
           let p = data[this.$v.API_ROW]
          this.tableData.sumNC = p['totalItems'];
          this.pagination.currentPage = page
          this.pagination.perPage = limit
          this.calculateData();
        } else { this.$validateSession(data) }
      })
    },
    calculateData(){
      let _data2 = this.tableData2;
      console.log(_data2)
      this.tableData.sumCP=0;
      this.tableData.sumNA=0;
      this.tableData.sumUpdates=0;
      this.tableData.sumPendings=0;
      for (let i=0; i<_data2.length; i++){
        if (_data2[i].complete_docs==='COMPLETO') this.tableData.sumCP+=1;
        if (_data2[i].complete_docs==='SIN AUDITAR') this.tableData.sumNA+=1;
        if (_data2[i].complete_docs==='ACTUALIZADO') this.tableData.sumUpdates+=1;
        if (_data2[i].complete_docs==='PENDIENTE') this.tableData.sumPendings+=1;
      }
      this.$forceUpdate();
    },
    setPeriodFilter(value) {
      if(value!=2){
        this.showSetPeriod = false
        this.periodFilter = value

      }else if(this.period.start && this.period.end){
        this.periodFilter = value
      }
      this.getData2()
    },
    getPeriodFilterText() {
      if(this.showSetPeriod)
        return "Periodo del: "
      else if(this.periodFilter === 1)
        return "Mes actual"
      else return "Fecha"
    },
    setPeriod(){
      this.showSetPeriod = true
      this.period.start=null
      this.period.end=null
      this.getPeriodFilterText()
    },
  }
}
</script>
<style>

#top
{ position: fixed;  z-index: 999; }


.round_table {
  border-collapse: separate;
  border-spacing: 1px;
  border:solid 1px;
  border-color: #d3d3d3;
}

.round_th {
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
}
</style>