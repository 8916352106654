<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col-lg-2" v-if="isAdmin || isManagerService">
          <base-button type="success" size="sm" v-on:click="updloadSheetsSpei"
            ><i class="far fa-file-excel"></i> Ver Reporte de SPEI</base-button
          >
        </div>
        <div class="col-lg-2">
          <base-dropdown style="width: 100%">
            <base-button
              slot="title"
              type="default"
              outline
              class="dropdown-toggle"
              size="sm"
              style="width: 100%"
            >
              {{ getPeriodFilterText() }}
            </base-button>
            <a class="dropdown-item" v-on:click="setPeriodFilter(null)"
              >Mostrar todos</a
            >
            <a class="dropdown-item" v-on:click="setPeriodFilter(1)"
              >Mes actual</a
            >
            <div v-if="!showSetPeriod" class="dropdown-divider"></div>
            <a v-if="!showSetPeriod" class="dropdown-item" @click="setPeriod"
              >Establecer periodo</a
            >
          </base-dropdown>
        </div>
        <div v-if="showSetPeriod" class="col">
          <div class="row justify-content-center">
            <flat-pickr
              v-model="period.start"
              placeholder="aaaa-mm-dd"
              class="btn btn-sm"
              data-vv-as="Fecha"
              name="startPeriod"
              style="background-color: #ffffff"
            />
            <span class="small text-muted">-</span>
            <flat-pickr
              v-model="period.end"
              placeholder="aaaa-mm-dd"
              class="btn btn-sm"
              data-vv-as="Fecha"
              name="endPeriod"
              style="background-color: #ffffff"
            />
            <base-button
              type="none text-green"
              size="sm"
              @click="setPeriodFilter(2)"
              >Aplicar</base-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive" v-bind:style="{ cursor: pointer }">
      <base-table
        class="table align-items-center table-flush text-center"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="data"
      >
        <template slot="columns">
          <th v-bind:style="{ cursor: pointer2 }">Cliente</th>
          <th v-bind:style="{ cursor: pointer2 }">Nombre</th>
          <th v-bind:style="{ cursor: pointer2 }">Monto</th>
          <th v-bind:style="{ cursor: pointer2 }">Fecha</th>
        </template>
        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <a
                  :href="'https://admin.dbmenos.com/client?id=' + row.user"
                  target="_blank"
                >
                  <span class="name mb-0 text-sm"
                    >{{ row.user }}<i class="fas fa-user-edit text-primary"></i
                  ></span>
                </a>
              </div>
            </div>
          </th>
          <td>{{ row.name }}</td>
          <td>{{ $formatCurrency(row.amount) }}</td>
          <td>{{ formatDate(row.created) }}</td>
        </template>
      </base-table>
    </div>
    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="col">
        <p class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          Página {{ pagination.currentPage }} de
          {{ Math.ceil(pagination.total / pagination.perPage) }}<br />{{
            pagination.total
          }}
          Pagos
        </p>
      </div>
      <base-pagination
        size="sm"
        :total="pagination.total"
        :per-page="pagination.perPage"
        :value="pagination.currentPage"
        v-on:pageSelected="pageSelected"
      />
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "massive-spei-in-table",
  components: {
    flatPickr,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    data: Array,
    pagination: Object,
    period: {
      start: null,
      end: null,
    },
  },
  data() {
    return {
      isManagerService: false,
      isAdmin: false,
      tableData: [],
      consultantFilter: null,
      showSetPeriod: false,
      periodFilter: null,
      pointer: "pointer",
      pointer2: "default",
    };
  },
  created() {
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.setPeriodFilter(1);
  },
  computed: {
    user() {
      return this.$getLS(this.$v.STORE_KEYS.USER);
    },
    customerService() {
      return this.$store.state.customerService;
    },
    sellers() {
      return this.$store.state.sellers;
    },
  },
  methods: {
    pageSelected(page) {
      this.$emit("pageSelected", page);
    },
    formatDate(date) {
      if (date <= "2020-11-10") {
        return this.$moment(date)
          .subtract(6, "hours")
          .format("DD MMM YYYY, h:mm a");
      } else {
        return this.$moment(date).format("DD MMM YYYY, h:mm a");
      }
    },
    setConsultantFilter(value) {
      this.consultantFilter = value;
      this.$emit("setConsultantFilter", value);
    },
    getCustomerFilterText() {
      if (this.consultantFilter)
        return this.getConsultantName(this.consultantFilter).name;
      return "Consultores";
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id) {
          return this.sellers[i];
        }
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id) {
          return this.customerService[i];
        }
      return "- - -";
    },
    isServiceConsultant(consultantName) {
      for (let i = 0; i < this.customerService.length; i++)
        if (
          this.customerService[i].name +
            " " +
            this.customerService[i].lastname_1 +
            " " +
            this.customerService[i].lastname_2 ===
          consultantName
        ) {
          return true;
        }
      return false;
    },
    formatCurrency(value) {
      return this.$formatCurrency(value);
    },
    setPeriod() {
      this.showSetPeriod = true;
      this.period.start = null;
      this.period.end = null;
      this.getPeriodFilterText();
    },
    setPeriodFilter(value) {
      if (value != 2) {
        this.showSetPeriod = false;
        this.periodFilter = value;
        this.$emit("setPeriodFilter", value);
      } else if (this.period.start && this.period.end) {
        this.periodFilter = value;
        this.$emit("setPeriodFilter", value);
      }
    },
    getPeriodFilterText() {
      if (this.showSetPeriod) return "Periodo del: ";
      else if (this.periodFilter === 1) return "Mes actual";
      else return "Fecha";
    },
    updloadSheetsSpei() {
      this.$showLoader(true);
      let url =
        this.$v.SERVER + this.$v.REPORTS_SHEETS.CREATE_REPORTS_SPEI_LOG;
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          this.$vs.notification(this.$notification(
                'success',
                 'Actualizado exitosamente'
                     ));
          window.open(
            "https://docs.google.com/spreadsheets/d/" + data.row.sheetsId,
            "_blank"
          );
          this.$forceUpdate();
        } else {
          this.$vs.notification(this.$notification(
                'danger',
                 'Algo salió mal',
                 'Intenta nuevamente'
                     ));
        }
      });
    },
  },
};
</script>
<style>
</style>