<template>
	<div>
		<base-header type="gradient-dbm" class="pb-6 pb-8 pt-5 pt-md-8"/>

		<div class="container-fluid mt--7">
			<div class="row">
				<div class="col" v-bind:style="{cursor:pointer2}">
					<consultants-call-log-table
							title="Llamadas sin evento"
							:data="tableData"
							:pagination="pagination"
							v-on:itemSelected="itemSelected"/>
				</div>
			</div>
		</div>

		<modal :show.sync="tracingModal" v-bind:large="true">
			<template slot="header">
				<h2 class="mb-0" id="TracingLead" style="margin-top: 20px; margin-left: 10px">
					ID del cliente: {{item.user}}
				</h2>
			</template>
			<div class="row">
				<div class="col-lg-12">
					<div class="col-lg-6">
						<base-dropdown >
							<base-button slot="title" type="success" class="dropdown-toggle">
								{{ newTrace.status ? newTrace.status.status : "Seleccionar" }}
							</base-button>
							<a class="dropdown-item" v-for="(status, index) in isService? userTracingStatusCatalog : leadStatus"
								:key="index" @click="newTrace.status = status, newTrace.statusId = status.id, $forceUpdate()">
								{{status.status}}
							</a>
						</base-dropdown>
					</div>
					<div class="row" style="margin-top: 2rem">
						<div class="col-lg-6" v-if="newTrace.statusId === 16">
							<label class="form-control-label">Fecha promesa</label>
							<flat-pickr data-vv-as="Fecha" name="date" style="background-color: #ffffff"
								:config="{}" class="form-control form-control-alternative"
								placeholder="Selecciona fecha de promesa"
								v-model="newTrace.date_promise"
							/>
						</div>
					</div>
					<base-input alternative input-classes="form-control-alternative" style="margin-top: 20px"
						placeholder="Comentarios"
						v-model="newTrace.comments"
					/>
				</div>
			</div>
			<div class="col text-right">
				<base-button slot="title" type="primary" v-on:click="uploadTrace()">Crear</base-button>
			</div>
		</modal>

	</div>
</template>
<script>
	import ConsultantsCallLogTable from './Tables/ConsultantsCallLogTable'
	import flatPickr from "vue-flatpickr-component";
	import "flatpickr/dist/flatpickr.css";
	export default {
		name: 'tables',
		components: {
			ConsultantsCallLogTable,
			flatPickr
		},
		computed: {
			user() { return this.$getLS(this.$v.STORE_KEYS.USER) },
			leadStatus() { return this.$store.state.leadStatus; },
			userTracingStatusCatalog() {
				let uTS = this.$store.state.userTraceStatus;
				let userTracingStatusCatalog = [];
				for (let i = 0; i < uTS.length; i++) {
					if (uTS[i].enabled) userTracingStatusCatalog.push(uTS[i]);
				}
      return userTracingStatusCatalog;
    },
		},
		data() {
			return {
				tracingModal: false,
				pagination: {
					total: 0,
					perPage: 10,
					currentPage: 1
				},
				tableData: [],
				item: {},
				newTrace: {},
				isAuditor: false,
        isService: false,
        isSeller: false,
        isManagerService: false,
				isManagerSeller: false,
        isAdmin: false,
				pointer: 'pointer',
        pointer2: 'default'
			};
		},
		created() {
			this.getTableData(1);
			this.isSeller = this.user.role === this.$v.ROLES.SELLER;
      this.isService = this.user.role === this.$v.ROLES.SERVICE;
      this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
      this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
      this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
			this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
		},
		methods: {
			getTableData(page) {
				let limit = this.pagination.perPage;
				let url = this.$v.SERVER + this.$v.SELLER_OPS.GET_CALL_LOG_WITHOUT_TRACING;
				let request = {
					offset: limit * (page - 1),
					limit: limit,
					consultant: this.user.id,
				}
				this.$showLoader(true);
				this.$axios.post(url, request).then((response) => {
					this.$showLoader(false);
					let data = response.data;
					if (data[this.$v.API_SUCCESS]) {
						let p = data[this.$v.API_ROW];
						this.tableData = data[this.$v.API_ROWS];
						this.pagination.total = p["totalItems"];
						this.pagination.currentPage = page;
						this.pagination.perPage = limit;
						this.$forceUpdate();
					} else this.$validateSession(data);
				});
			},
			itemSelected(item) {
				console.log(item)
				this.item = item;
				this.tracingModal = true
			},
			uploadTrace() {
				if ( this.newTrace===undefined||this.newTrace.comments===""||this.newTrace.statusId===0||(this.newTrace.statusId === 16 && !this.newTrace.date_promise)) {
					
					this.$vs.notification(this.$notification(
                'warning',
                 'Datos incorrectos'
                     ));
					return;
				}
				let json = {
					user: this.item.user,
					comments: this.newTrace.comments,
					call_log: this.item.id
				};
				if (this.newTrace.statusId === 16)
					json.date_promise =this.newTrace.date_promise;
				json.status = this.newTrace.statusId;
				let url = this.$v.SERVER + this.$v.SELLER_OPS.SET_EVENT_BY_ROLE;
				this.$showLoader(true);
				this.$axios.post(url, json).then((response) => {
					this.$showLoader(false);
					if (response.data[this.$v.API_SUCCESS]) {
						
					this.$vs.notification(this.$notification(
                'success',
                 'Cambios exitosamente'
                     ));
						this.tracingModal = false;
						this.newTrace = {id: 0};
						this.getTableData(1);
						//reobtener lista
					} else {
						
					this.$vs.notification(this.$notification(
                'danger',
                 'Fallo al cargar'
                     ));
					}
				});
			},
		}
	};
</script>
<style></style>